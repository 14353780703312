import React, { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import Configs from "../../config";
import Swal from 'sweetalert2';

function LoginGitHub() {
    const [accessToken, setAccessToken] = useState(null);
    const location = useLocation();

    // Function to extract query parameter from URL
    function getCodeFromUrl() {
        const params = new URLSearchParams(location.search);
        console.log('params', params.get('code'));

        return params.get('code');
    }

    // Function to get access token from GitHub
    async function getAccessToken(code) {
        try {
            const response = await axios({
                method: "post",
                url: `https://cors-anywhere.herokuapp.com/https://github.com/login/oauth/access_token`,
                headers: {
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                },
                data: {
                    client_id: 'Ov23liht15bmva4DxSi6',
                    client_secret: '547238f83b1e17e54f43275bb3891de7c4913a90',
                    code: code,
                    redirect_uri: 'http://150.95.25.61:7777/',
                    scope: 'repo,user' // เพิ่มสิทธิ์ที่ต้องการ
                },
            });
            return response.data.access_token;
        } catch (error) {
            console.error('Error getting access token:', error);
            return null;
        }
    }

    // Function to get user repositories from GitHub
    async function getUserRepos(token) {
        try {
            const response = await axios({
                method: "get",
                url: `https://api.github.com/user/repos`,
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });
            return response.data;
        } catch (error) {
            console.error('Error fetching repositories:', error);
            return [];
        }
    }

    // Function to handle the login process
    async function handleLogin() {
        const code = getCodeFromUrl();
        if (!code) {
            console.error('No code found in URL');
            return;
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/pocGit/LoginGit`,
            headers: {
                /*   Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                'Content-Type': 'multipart/form-data',
            }, data: {
                code: code
            }

        }).then((res) => {
            const repo_list = [];
            if (res.data.length > 0) {
                var Show = "";
                for (var e = 0; e < res.data.length; e++) {
                    Show += "<br/>" + res.data[e];
                }
                Swal.fire("success", Show, "success");
            }

        }).catch((error) => {

        })

        /*   const token = await getAccessToken(code);
          console.log('token',token);
          
          if (token) {
              setAccessToken(token);
              const repos = await getUserRepos(token);
              sessionStorage.setItem('repo', JSON.stringify(repos));
              console.log('Fetched Repositories:', repos);
          } */




    }

    useEffect(() => {
        /*  handleLogin(); */
        getCodeFromUrl();
    }, []);

    return (
        <div style={{
            backgroundColor: 'rgba(0,0,0,0.9)',
            width: '100%',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{
                width: '80px',
                height: '40px',
                border: '1px solid rgb(255, 197, 250)',
                borderRadius: '8px',
                color: '#FFFF',
                backgroundColor: 'rgb(255, 197, 250)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                textShadow: '2px 2px #ff0000',
                cursor: 'pointer'
            }}
                onClick={handleLogin}
            >
                Login Git
            </div>
        </div>
    );
}

export default LoginGitHub;
