import { isArray } from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import { ConfigProvider, DatePicker, Select, Tabs, Tooltip, Input, Table,ColorPicker } from 'antd';
import { CalendarOutlined, SearchOutlined, EyeOutlined, EditOutlined, DownloadOutlined, DeleteOutlined, PlusCircleOutlined, UserOutlined, PlusOutlined, CloseOutlined, UserAddOutlined } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import momTabUserImg from './momTabUserImg.jpg';
/* import Select from 'react-select' */
import moment from "moment";
import axios from "axios";
import './tabSrs.css'
import Configs from "../../../../config";
import dayjs, { isDayjs } from 'dayjs';
import styled from 'styled-components';
import TabMomAttendance from './TabMomAttendance';
import ModelAlertConfrim from "../../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../../components/AlertMassge/AlertMassge";
import momreport from '../../Report/momreport'
import Swal from 'sweetalert2';
import { io } from 'socket.io-client';
const { TextArea } = Input;
const { RangePicker } = DatePicker;
const StyledDatePicker = styled(DatePicker)`
.ant-picker-clear {
    padding-Right: 10px; 
}
`;
;



function TabCompanentSRS({ roleOptionData, callBackSrsSave, isGetBySRS, tempSrsSaveFackData, callbackSrsSpecificField, srsSpecificField, userismode, project_id, getMOM, callbackMomSpecificField }) {
    // console.log("SRS:>>", userismode);
    const [test, setTest] = useState({ test: '' });
    const [optionRole, setOptionRole] = useState([])
    const [textAreaId, setTextAreaId] = useState({})

    const [tempSrsSave, setTempSrsSave] = useState(
        {
            prepared_sp_name: '',
            prepared_sp_position_id: '',
            prepared_sp_position: '',
            prepared_sp_operation: 'PREPARED BY',
            prepared_sp_date_sign: '',
            prepared_sp_operation: '',

            verified_sp_name: '',
            verified_sp_position_id: '',
            verified_sp_position: '',
            verified_sp_operation: 'VERIFIED BY',
            verified_sp_date_sign: '',
            verified_sp_operation: '',

            approved_sp_name: '',
            approved_sp_position_id: '',
            approved_sp_position: '',
            approved_sp_operation: 'APPROVED BY',
            approved_sp_date_sign: '',
            approved_sp_operation: '',

            customer_approved_sp_name: '',
            customer_approved_sp_position_id: '',
            customer_approved_sp_position: '',
            customer_approved_sp_operation: 'CUSTOMER APPROVED',
            customer_approved_sp_date_sign: '',
            customer_approved_sp_operation: '',

        },
    )

    const [specificField, setSpecificField] = useState([]);

    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                port: Configs.TTT_Game_Socket_Service_Port
            });
        }
    }, [

    ])


    useEffect(() => {
        const { current: socket } = socketRef;
        const socFnc = (data) => { }
        try {
            socket.open();
            socket.on('locationElement', socFnc)
        } catch (error) {
            console.log(error);
        }

    }, []);

    const [momTabMode, setMomTabMode] = useState('table');
    const [momTabTableSearch, setMomTabTableSearch] = useState('');
    const [momTabEditData, setMomTabEditData] = useState({});
    const [momTabUserSelect, setMomTabUserSelect] = useState([]);
    const [momData, setMomData] = useState([]);
    const [momTabCompanyTabSelect, setMomTabCompanyTabSelect] = useState(null);
    const [momTabCompanyTabSelectId, setMomTabCompanyTabSelectId] = useState('');
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [alertKickConfirmId, setAlertKickConfirmId] = useState('');
    const [alertKickConfirm, setAlertKickConfirm] = useState(false);
    const toggle_modal_alertKickConfirm = () => { setAlertKickConfirm(!alertKickConfirm) };
    const [momTabAddData, setMomTabAddData] = useState({
        mom_tab: [],
        mom_link: []
    });
    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);
    const [momTabAddUserIsOpen, setMomTabAddUserIsOpen] = useState(false);
    const toggleMomTabAddUserIsOpen = () => { setMomTabAddUserIsOpen(!momTabAddUserIsOpen) };
    const [momTabUserSearch, setMomTabUserSearch] = useState('');
    const [momTabUserIsEdit, setMomTabUserIsEdit] = useState(false);
    const toggleMomTabUserIsEdit = () => { setMomTabUserIsEdit(!momTabUserIsEdit) };
    const [momTabUserEditData, setMomTabUserEditData] = useState({});
    const [momTabAddUserMode, setMomTabAddUserMode] = useState('all');
    const [momTabAddUserModeOld, setMomTabAddUserModeOld] = useState('all');
    const [messageError, setMessageError] = useState({
        message: 'Please fill in all information completely.',
        isError: true,
        menuTitle: ''
    })
    const [alertKickConfirmMessage, setAlertKickConfirmMessage] = useState({
        messageMain: 'Do you want to delete data?',
        messageSec: `You won't be able to revert this!`,
        menuTitle: null
    })
    const [alertKickSuccess, setAlertKickSuccess] = useState(false);
    const toggle_modal_alertKickSuccess = () => setAlertKickSuccess(!alertKickSuccess);
    const [alertKickNot, setAlertKickNot] = useState(false);
    const toggle_modal_alertKickNot = () => setAlertKickNot(!alertKickNot);
    const [loading, setLoading] = useState(false);


    const handleOnDragTab = (result) => {
        if (!result.destination) return;

        for (let item of momTabUserSelect) {
            if (result.source.index > result.destination.index) {
                if (item.mmp_sort_company >= result.destination.index && item.mmp_sort_company < result.source.index) {
                    item.mmp_sort_company++;
                }
                if (item.mp_company == result.draggableId) {
                    item.mmp_sort_company = result.destination.index;
                }
            } else {
                if (item.mmp_sort_company > result.source.index && item.mmp_sort_company <= result.destination.index) {
                    item.mmp_sort_company = item.mmp_sort_company - 1;
                }
                if (item.mp_company == result.draggableId) {
                    item.mmp_sort_company = result.destination.index;
                }
            }
        }

        setTest({ ...test, test: '' });
        test.test = '';
    };

    const handleOnDragPersonal = (result) => {
        const { source, destination, draggableId } = result;

        if (!destination) return;

        let filteredList = momTabUserSelect.filter(fil =>
            momTabUserSearch !== ''
                ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company === momTabCompanyTabSelectId
                : fil.mp_company === momTabCompanyTabSelectId
        ).sort((a, b) => a.mmp_sort_personal - b.mmp_sort_personal);

        let draggedItem = filteredList[source.index];
        let draggedOverItem = filteredList[destination.index];

        let draggedItemId = draggedItem?.mp_id;
        let draggedItemSort = draggedItem?.mmp_sort_personal;
        let draggedOverItemSort = draggedOverItem?.mmp_sort_personal || null;

        for (let item of momTabUserSelect) {
            if (draggedItemSort > draggedOverItemSort) {
                if (item.mmp_sort_personal >= draggedOverItemSort && item.mmp_sort_personal < draggedItemSort) {
                    item.mmp_sort_personal++;
                }
                if (item.mp_id == draggedItemId) {
                    item.mmp_sort_personal = draggedOverItemSort;
                }
            } else {
                if (item.mmp_sort_personal > draggedItemSort && item.mmp_sort_personal <= draggedOverItemSort) {
                    item.mmp_sort_personal = item.mmp_sort_personal - 1;
                }
                if (item.mp_id == draggedItemId) {
                    item.mmp_sort_personal = draggedOverItemSort;
                }
            }
        }

        setTest({ ...test, test: '' });
        test.test = '';
    };

    const changeValueInJsonSrs = (key, value) => {
        let temp = momTabAddData;

        if (key == "mom_date") {
            temp.mom_start_date = value[0].$d;
            temp.mom_end_date = value[1].$d;
        } else {
            temp[key] = value;
        }
        setMomTabAddData(temp);
        setTextChange({ ...textChange, text: '' });
        textChange.text = '';
    }

    const changeValueInJsonEditSrs = (key, value) => {
        let temp = momTabEditData;

        if (key == "mom_date") {
            temp.mom_start_date = value[0].$d;
            temp.mom_end_date = value[1].$d;
        } else {
            temp[key] = value;
        }
        setMomTabEditData(temp);
        setTextChange({ ...textChange, text: '' });
        textChange.text = '';
    }

    const handleLinkInputChangeLink = (key, value, index) => {
        // console.log('testlog d', key);
        let temp = momTabAddData;
        temp.mom_link[index][key] = value;

        setMomTabAddData(temp);
        setTest({ ...test, test: '' });
        test.test = '';
    };

    const handleLinkInputChangeEditLink = (key, value, index) => {
        let temp = momTabEditData;
        temp.mom_link[index][key] = value;

        setMomTabEditData(temp);
        setTest({ ...test, test: '' });
        test.test = '';
    };

    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    const getDataForMom = async (srs_value, callback) => {
        try {
            const temp_mom_data = [];
            const id = "738fc3d7-b0ee-4bf3-b9fa-b0f3958f77f8";
            const res_doc = await axios({
                method: "GET",
                url: `${Configs.TTT_Game_Service}/api/documentTemplate/getDocumentTemplateDown/${id}`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    "X-TTT": `${Configs.API_TTT}`,
                    "Content-Type": "application/json",
                },
            })
            const document_specifig = res_doc.data;
            if (document_specifig) {
                for (let item of document_specifig) {
                    for (let item2 of item.dt_input_data.data) {
                        for (let item3 of item2.value_list) {
                            for (let item_ of srs_value) {
                                for (let item2_ of item_.dt_input_data.data) {
                                    for (let item3_ of item2_.value_list) {
                                        if (item3.value_id == item3_.value_id) {
                                            if (item3_.value_input) {
                                                item3.value_input = item3_.value_input || '';
                                            }
                                            if (item3_.data_list) {
                                                item3.data_list = item3_.data_list || [];
                                            }
                                            if (item3_.value_input_header) {
                                                item3.value_input_header = item3_.value_input_header || '';
                                            }

                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                for (let item of document_specifig) {
                    for (let item2 of item.dt_input_data.data) {
                        for (let item3 of item2.value_list) {
                            if (item3.value_type == 'table_column') {
                                let temp_in = {
                                    value_input_table: [],
                                }
                                for (let item of item3.table_column_list) {
                                    temp_in.value_input_table.push('');
                                }
                                if (!item3.data_list) {
                                    item3.data_list = [];
                                }
                                if (item3.data_list && item3.data_list.length && item3.table_column_list.length != item3.data_list[0].value_input_table.length) {
                                    item3.data_list = [];
                                }
                                if (item3.data_list.length == 0 && item3.is_multiple == false) {
                                    item3.data_list.push(temp_in);
                                }

                            }

                            if (item3.value_id == "7f6d4901-319b-4aed-af5b-f0cbea20a942" || item3.value_id == "8ca1ace7-b475-4c19-9cd4-94453d39b3f7") { // ครั้งที่เก็บความต้องการ

                                item3.data_list.forEach((item3Value, _index5) => {
                                    const objEmployees = item3Value.value_input_table[2] && item3Value.value_input_table[2] !== "" ? parseEmployees(item3Value.value_input_table[2]) : null;
                                    const objParseLink = item3Value.value_input_table[5] && item3Value.value_input_table[5] !== "" ? parseLinks(item3Value.value_input_table[5]) : null;
                                    item3Value.value_participants = objEmployees ? objEmployees : [];
                                    item3Value.value_link = objParseLink ? objParseLink : [];

                                    const date = (item3Value.value_input_table[4] && item3Value.value_input_table[4] != "") ?
                                        item3Value.value_input_table[4].split(' - ').map(date => dayjs(date, "DD/MM/YYYY HH:mm:ss")) :
                                        [null, null];
                                    const model = {
                                        index: item3Value.value_input_table[0],
                                        tag: "srs",
                                        mom_id: null,
                                        mom_no: item3Value.value_input_table[0],
                                        mom_location: item3Value.value_input_table[3],
                                        mom_header: item3Value.value_input_table[1],
                                        mom_start_date: isDayjs(date?.[0]) ? date[0].$d : undefined,
                                        mom_end_date: isDayjs(date?.[1]) ? date[1].$d : undefined,
                                        mom_project_id: project_id,
                                        mom_created_date: new Date(),
                                        mom_link: item3Value.value_link,
                                        mom_participants: item3Value.value_participants,
                                        mom_group: []
                                    }
                                    temp_mom_data.push(model);
                                });
                            }
                        }
                    }
                }
            }

            const _momSpecificField = await getMOM();
            const mom_not_null = _momSpecificField.filter(e => e.tag == "mom" && e.mom_start_date && e.mom_id);
            const mom = _momSpecificField.filter(e => e.tag == "mom");

            mom_not_null.forEach(e => temp_mom_data.push(e));
            temp_mom_data.sort((a, b) => {
                return new Date(b.mom_start_date) - new Date(a.mom_start_date);
            });

            temp_mom_data.sort((a, b) => {
                return new Date(b.mom_start_date) - new Date(a.mom_start_date);
            });

            genNumberMOMNumber(temp_mom_data, (async (call2) => {
                if (call2) {

                    callback(call2);
                }
            }))
        } catch (error) {
            console.log('error :>> ', error);
        }
    }

    const genNumberMOMNumber = (data, calback) => {
        data.sort((a, b) => {
            return new Date(a.mom_start_date) - new Date(b.mom_start_date);
        });

        let index = 1;
        data.forEach(e => {
            if (e.tag == "mom") {
                e.index = index;
                index++
            }
        })
        data.sort((a, b) => {
            return new Date(b.mom_start_date) - new Date(a.mom_start_date);
        });

        // setSpecificField(data);
        callbackMomSpecificField(data);
        setTextChange({ ...textChange, text: '' });
        textChange.text = '';
        if (calback) {
            calback(data)
        }
    }

    async function getMomData(tempData) {
        axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getMomData/${project_id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                if (response.data) {
                    let filter = response.data.data.filter((fil) => { return fil.mom_tag == 'srs' });

                    /* let getSrs = tempData.filter((e) => { return e.tag === 'srs' });

                    for (let item of getSrs) {
                        var setFa = {
                            mom_id: null,
                            mom_no: null,
                            mom_location: item.mom_location,
                            mom_header: item.mom_header,
                            mom_start_date: item.mom_start_date,
                            mom_end_date: item.mom_end_date,
                            mom_project_id: item.mom_project_id,
                            mom_is_use: true,
                            mom_created_date: item.mom_created_date,
                            mom_updated_date: null,
                            mom_created_by: null,
                            mom_updated_by: null,
                            mom_record_by: null,
                            mom_description: null,
                            mom_code: null,
                            user_list: [],
                            mom_link: item.mom_link.map((map) => {
                                return {
                                    md_name: map.name,
                                    md_link: map.link
                                }
                            }),
                            mom_tag: "srs"
                        }
                        filter.push(setFa);
                    } */

                    const sortedData = filter.sort((a, b) => {
                        return new Date(b.mom_start_date) - new Date(a.mom_start_date);
                    });
                    setMomData(sortedData);
                    setMomTabAddData({ ...momTabAddData, no: ((response.data.countSrs + 1) || 0) });
                    momTabAddData.no = ((response.data.countSrs + 1) || 0);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function addMomData() {
        let temp = {
            project_id: project_id,
            mom_location: momTabAddData.mom_location,
            mom_header: momTabAddData.mom_header,
            mom_start_date: momTabAddData.mom_start_date,
            mom_end_date: momTabAddData.mom_end_date,
            mom_record_by: momTabAddData.mom_record_by,
            mom_description: momTabAddData.mom_description,
            user_list: momTabUserSelect,
            mom_link: momTabAddData.mom_link,
            mom_tag: 'srs'
        }

        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/addMomData`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(function (response) {
                if (response.data) {
                    setMomTabAddData({
                        mom_tab: [],
                        mom_link: []
                    });
                    setMomTabUserSelect([]);

                    getMomData();

                    setMomTabMode('table');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function editMomData() {
        let temp = {
            project_id: project_id,
            mom_id: momTabEditData.mom_id,
            mom_location: momTabEditData.mom_location,
            mom_header: momTabEditData.mom_header,
            mom_start_date: momTabEditData.mom_start_date,
            mom_end_date: momTabEditData.mom_end_date,
            mom_record_by: momTabEditData.mom_record_by,
            mom_description: momTabEditData.mom_description,
            user_list: momTabUserSelect,
            mom_link: momTabEditData.mom_link
        }

        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/editMomData`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(function (response) {
                if (response.data) {
                    setMomTabAddData({
                        mom_tab: [],
                        mom_link: []
                    });
                    setMomTabUserSelect([]);
                    getMomData();
                    setMomTabMode('table');
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function delMomDataById(id) {
        axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/delMomDataById/${id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                if (response.data) {
                    getMomData();
                    setTest({ ...test, test: '' });
                    test.test = '';
                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL SUCCESS' });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async function generateMomReport(mom_id) {
        socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL' });
        setLoading(true);
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/getMOMdataForReport`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
                mom_id_list: [mom_id],
                project_id: project_id

            }
        })
            .then(function (response) {
                momreport(response.data.minute_of_meeting[0], null, 'version');
            }).finally((res) => {
                setTimeout(function () {
                    setLoading(false);
                    toggle_modal_alertKickSuccess()
                }, 10000);

                setTest({ ...test, test: "" });
                test.test = "";
            }).catch((error) => {
                setLoading(false);
                console.log(error);
            });
        toggle_modal_alertKick();
    }

    async function downloadMOM(mom_id, type) {
        if (type != 'all') {
            socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL' });
            setLoading(true);
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/downLoadMom`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
                mom_id_list: [mom_id],
                project_id: project_id
            }
        })
            .then(function (response) {
                let temp_all_data_mom = response.data.minute_of_meeting[0].mom_pdf;
                if (temp_all_data_mom.length > 0) {
                    let find_lastversion = temp_all_data_mom.sort((a, b) => Number(b.momt_version) - Number(a.momt_version))[0]
                    downloadFile(find_lastversion, "");
                    if (type != 'all') {
                        setLoading(false);
                    }
                } else {
                    toggle_modal_alertKickNot()
                    if (type != 'all') {
                        setLoading(false)
                    }
                }
                socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL SUCCESS' });
                Swal.close();
            }).catch((error) => {
                console.log(error);
            });
    }

    async function generateMomReportAll(params) {
        for (let item of momData) {
            await generateMomReport(item.mom_id);
        };
    }
    async function downloadMOMAll(params) {
        socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL' });
        setLoading(true);
        for (let item of momData) {
            if (item.momt_created_date) {
                await downloadMOM(item.mom_id, 'all');
            };
        };
        setLoading(false);
        socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL SUCCESS' });
        // let temp_mom_id = specificField.filter((el) => {
        //   return el.tag == 'mom' && el.mom_id !== null && el.mom_id !== undefined
        // });
        // if (temp_mom_id.length > 0) {
        //   let reArangeData = temp_mom_id.map((el) => {
        //     return el.mom_id
        //   })

        //   axios({
        //     method: 'post',
        //     url: `${Configs.TTT_Game_Service}/api/report/downLoadMom`,
        //     headers: {
        //       Authorization: sessionStorage.getItem('access_token'),
        //       'X-TTT': `${Configs.API_TTT}`,
        //       'Content-Type': 'application/json',
        //     },
        //     data: {
        //       mom_id_list: reArangeData,
        //       project_id: project_id
        //     }
        //   })
        //     .then(async function (response) {

        //       console.log("response.data.minute_of_meeting", response.data);
        //       if (response.data.minute_of_meeting.length > 0) {

        //         response.data.minute_of_meeting.forEach((url) => {
        //           if (url.mom_pdf.length > 0) {
        //             let find_lastversion = url.mom_pdf.sort((a, b) => Number(b.momt_version) - Number(a.momt_version))[0]
        //             let tempArrLoad = [];
        //             tempArrLoad.push();
        //             downloadFile(find_lastversion, 'downloadAll');

        //           }
        //         });
        //       }
        //     }).finally((res) => {
        //       setLoading(false);
        //       Swal.close();
        //     }).catch((error) => {
        //       console.log(error);
        //       setLoading(false);
        //     });
        // } else {
        //   setLoading(false);
        //   toggle_modal_alertKickNot();
        // }
    }
    const downloadFile = async (fileUrl, fileName) => {
        try {
            if (fileName === 'downloadAll') {
                let response3 = { data: '' }
                response3 = await axios.get(`https://api.box.com/2.0/files/${fileUrl.fd_img_id}/content`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                    },
                    responseType: 'blob',
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(response3.data);

                link.download = fileUrl.momt_filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);


            } else {
                let response2 = { data: '' }
                response2 = await axios.get(`https://api.box.com/2.0/files/${fileUrl.fd_img_id}/content`, {
                    headers: {
                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                    },
                    responseType: 'blob',
                });
                const link = document.createElement('a');
                link.href = URL.createObjectURL(response2.data);

                link.download = fileUrl.momt_filename;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        } catch (error) {
            console.error(`Error downloading ${fileName}:`, error);
        }
    };

    useEffect(async () => {
        GetAccesstokenToken();
        getDataForMom(srsSpecificField, (async (calback) => {
            if (calback) {
                await getMomData(calback);
            }
        }));
    }, []);

    useEffect(() => {
        /*  console.log('isOpen',isOpen); */
        console.log('roleOptionData', tempSrsSaveFackData);
        setOptionRole(roleOptionData);



        setTempSrsSave({
            ...tempSrsSave,
            prepared_sp_name: tempSrsSaveFackData.prepared_sp_name || null,
            prepared_sp_position_id: tempSrsSaveFackData.prepared_sp_position_id || null,
            prepared_sp_position: tempSrsSaveFackData.prepared_sp_position || null,
            prepared_sp_date_sign: tempSrsSaveFackData.prepared_sp_date_sign || null,
            prepared_sp_operation: tempSrsSaveFackData.prepared_sp_operation || null,

            verified_sp_name: tempSrsSaveFackData.verified_sp_name || null,
            verified_sp_position_id: tempSrsSaveFackData.verified_sp_position_id || null,
            verified_sp_position: tempSrsSaveFackData.verified_sp_position || null,
            verified_sp_date_sign: tempSrsSaveFackData.verified_sp_date_sign || null,
            verified_sp_operation: tempSrsSaveFackData.verified_sp_operation || null,

            approved_sp_name: tempSrsSaveFackData.approved_sp_name || null,
            approved_sp_position_id: tempSrsSaveFackData.approved_sp_position_id || null,
            approved_sp_position: tempSrsSaveFackData.approved_sp_position || null,
            approved_sp_date_sign: tempSrsSaveFackData.approved_sp_date_sign || null,
            approved_sp_operation: tempSrsSaveFackData.approved_sp_operation || null,

            customer_approved_sp_name: tempSrsSaveFackData.customer_approved_sp_name || null,
            customer_approved_sp_position_id: tempSrsSaveFackData.customer_approved_sp_position_id || null,
            customer_approved_sp_position: tempSrsSaveFackData.customer_approved_sp_position || null,
            customer_approved_sp_date_sign: tempSrsSaveFackData.customer_approved_sp_date_sign || null,
            customer_approved_sp_operation: tempSrsSaveFackData.customer_approved_sp_operation || null,


        })




    }, [roleOptionData, isGetBySRS])








    const onSearch = (value) => {
        console.log('search:', value);
    };

    // Filter `option.label` match the user type `input`
    const filterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());


    async function getDocumentTemplate(srs_value) {
        let id = "738fc3d7-b0ee-4bf3-b9fa-b0f3958f77f8"; //id srs document specifig
        await axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/documentTemplate/getDocumentTemplateDown/${id}`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },

        })
            .then(async function (response) {

                if (response.data) {
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {
                                /* let check = srs_value.map((e)=>{return e.dt_input_data.data.map((e2)=>{return e2.value_list.map((e3)=>{return e3.value_id == item3.value_id})})})
                                console.log('check',check) */
                                for (let item_ of srs_value) {
                                    for (let item2_ of item_.dt_input_data.data) {
                                        for (let item3_ of item2_.value_list) {
                                            if (item3.value_id == item3_.value_id) {
                                                if (item3_.value_input) {
                                                    item3.value_input = item3_.value_input || '';
                                                }
                                                if (item3_.data_list) {
                                                    item3.data_list = item3_.data_list || [];
                                                }
                                                if (item3_.value_input_header) {
                                                    item3.value_input_header = item3_.value_input_header || '';
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    for (let item of response.data) {
                        for (let item2 of item.dt_input_data.data) {
                            for (let item3 of item2.value_list) {
                                if (item3.value_type == 'table_column') {
                                    let temp_in = {
                                        value_input_table: [],
                                    }
                                    for (let item of item3.table_column_list) {
                                        temp_in.value_input_table.push('');
                                    }
                                    if (!item3.data_list) {
                                        item3.data_list = [];
                                    }
                                    if (item3.data_list && item3.data_list.length && item3.table_column_list.length != item3.data_list[0].value_input_table.length) {
                                        item3.data_list = [];
                                        console.log('หัวไม่เท่ากับ value')
                                    }
                                    if (item3.data_list.length == 0 && item3.is_multiple == false) {
                                        item3.data_list.push(temp_in);
                                    }

                                }

                                if (item3.value_id == "7f6d4901-319b-4aed-af5b-f0cbea20a942" || item3.value_id == "8ca1ace7-b475-4c19-9cd4-94453d39b3f7") { // ครั้งที่เก็บความต้องการ
                                    item3.data_list.forEach(item3Value => {
                                        // console.log('value_input_table :>> ', item3Value.value_input_table[2]);
                                        const objEmployees = item3Value.value_input_table[2] && item3Value.value_input_table[2] !== "" ? parseEmployees(item3Value.value_input_table[2]) : null;
                                        const objParseLink = item3Value.value_input_table[5] && item3Value.value_input_table[5] !== "" ? parseLinks(item3Value.value_input_table[5]) : null;
                                        // console.log('objEmployees :>> ', objEmployees);
                                        item3Value.value_participants = objEmployees ? objEmployees : [];
                                        item3Value.value_link = objParseLink ? objParseLink : [];
                                    });
                                }
                            }
                        }
                    }
                    console.log('response.data dev:>> ', response.data);
                    setSpecificField(response.data);
                    callbackSrsSpecificField(response.data);

                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function parseEmployees(input) {
        // แยกสตริงโดยใช้คอมม่าเป็นตัวแบ่ง
        let employees = input.split(',');

        // แปลงอาร์เรย์ที่ได้เป็นอาร์เรย์ของออบเจกต์
        let result = employees.map(employee => {
            // แยกชื่อและตำแหน่งโดยใช้ขีดเป็นตัวแบ่ง
            let [name, position] = employee.split('-');
            return {
                name: name.trim(),
                position: position ? position.trim() : null
            };
        });

        return result;
    }

    function parseLinks(input) {
        // ใช้ Regular Expression เพื่อจับคู่สตริงที่อยู่ใน <<link>>...<<link>>
        let regex = /<<link>>(.*?)<<link>>/g;
        let matches = input.match(regex);

        // ถ้าไม่มีการจับคู่ใดๆ ให้คืนค่าเป็นอาเรย์ว่าง
        if (!matches) return [];

        // แปลง matches ให้เป็นอาเรย์ของออบเจกต์
        let result = matches.map(match => {
            // ตัด <<link>> ออก
            match = match.replace(/<<link>>/g, '');

            // แยก name และ path โดยใช้คอมม่า
            let [name, path] = match.split(',');

            return {
                name: name.trim(),
                link: path.trim()
            };
        });

        return result;
    }

    function formatLinks(objects) {
        // ใช้ map เพื่อแปลงอาเรย์ของออบเจกต์เป็นอาเรย์ของสตริงในรูปแบบที่ต้องการ
        let formattedLinks = objects.map(obj => {
            return `<<link>>${obj.name},${obj.link}<<link>>`;
        });

        // รวมสตริงทั้งหมดเข้าด้วยกันโดยคั่นด้วยช่องว่าง
        return formattedLinks.join(' ');
    }

    function formatEmployees(objects) {
        // ใช้ map เพื่อแปลงอาเรย์ของออบเจกต์เป็นอาเรย์ของสตริงในรูปแบบที่ต้องการ
        let formattedEmployees = objects.map(obj => {
            return `${obj.name}-${obj.position}`;
        });

        // รวมสตริงทั้งหมดเข้าด้วยกันโดยคั่นด้วยคอมม่า
        return formattedEmployees.join(',');
    }

    useEffect(() => {
        getDocumentTemplate(srsSpecificField);
    }, [srsSpecificField]);


    const addParticipant = (index, index2, index3, index4) => {

        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_participants = dataItem?.value_participants ?? [];
        if ((dataItem?.value_participants)) {
            dataItem.value_participants.push({
                name: "",
                position: ""
            })

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };

    const removeParticipant = (index, index2, index3, index4, index5) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_participants = dataItem?.value_participants ?? [];
        if ((dataItem?.value_participants)) {
            dataItem.value_participants.splice(index5, 1);
            dataItem.value_input_table[2] = dataItem.value_participants.length > 0 ? formatEmployees(dataItem.value_participants) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };

    const changeParticipantValueInJson = (key, value, index, index2, index3, index4, index5) => {
        // console.log('index, index2, index3, index4, index5 :>> ', index, index2, index3, index4, index5);
        // console.log('value :>> ', value);
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_participants = dataItem?.value_participants ?? [];
        if ((dataItem?.value_participants)) {
            dataItem.value_participants[index5][key] = value;
            dataItem.value_input_table[2] = dataItem.value_participants.length > 0 ? formatEmployees(dataItem.value_participants) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }

    const addLinkDoc = (index, index2, index3, index4) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_link = dataItem?.value_link ?? [];
        if ((dataItem?.value_link)) {
            dataItem.value_link.push({
                name: "",
                link: ""
            })

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };
    const removeLinkDoc = (index, index2, index3, index4, index5) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_link = dataItem?.value_link ?? [];
        if ((dataItem?.value_link)) {
            dataItem.value_link.splice(index5, 1);
            dataItem.value_input_table[5] = dataItem.value_link.length > 0 ? formatLinks(dataItem.value_link) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };
    const handleLinkInputChange = (key, value, index, index2, index3, index4, index5) => {
        let _specificField = specificField;
        const dataItem = _specificField?.[index]?.dt_input_data?.data?.[index2]?.value_list?.[index3]?.data_list?.[index4];
        dataItem.value_link = dataItem?.value_link ?? [];
        if ((dataItem?.value_link)) {
            dataItem.value_link[index5][key] = value;
            dataItem.value_input_table[5] = dataItem.value_link.length > 0 ? formatLinks(dataItem.value_link) : '';

            // console.log('dataItem :>> ', dataItem);
            _specificField[index].dt_input_data.data[index2].value_list[index3].data_list[index4] = dataItem;
            setSpecificField(_specificField);
            callbackSrsSpecificField(_specificField);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    };

    // const [linkDoc, setLinkDoc] = useState([]);

    // const addLinkDoc = () => {
    //     setLinkDoc([...linkDoc, { name: '', link: '' }]);
    // };

    // const handleLinkInputChange = (index, field, value) => {
    //     const newLinkDoc = [...linkDoc];
    //     newLinkDoc[index][field] = value;
    //     setLinkDoc(newLinkDoc);
    // };
    // const removeLinkDoc = (index) => {
    //     const newLinkDoc = linkDoc.filter((_, i) => i !== index);
    //     setLinkDoc(newLinkDoc);
    // };

    const [textChange, setTextChange] = useState({ text: '' });

    function addValueMultiple(index, index2, index3, type) {
        console.log(index, index2, index3)
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1) {
            if (type == 'table_column') {
                let temp_in = {
                    value_input_table: [],
                }
                for (let item of temp[index].dt_input_data.data[index2].value_list[index3].table_column_list) {
                    temp_in.value_input_table.push('');
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            } else {
                let temp_in = {
                    value_input: '',
                }
                if (!temp[index].dt_input_data.data[index2].value_list[index3].data_list) {
                    temp[index].dt_input_data.data[index2].value_list[index3].data_list = [];
                }
                temp[index].dt_input_data.data[index2].value_list[index3].data_list.push(temp_in);
            }



            setSpecificField(temp);
            callbackSrsSpecificField(temp);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }

    function removeValueMultiple(index, index2, index3, index4) {
        let temp = specificField || [];
        if (index > -1 && index2 > -1 && index3 > -1 && index4 > -1) {
            temp[index].dt_input_data.data[index2].value_list[index3].data_list.splice(index4, 1);
            setSpecificField(temp);
            callbackSrsSpecificField(temp);
            setTextChange({ ...textChange, text: '' });
            textChange.text = '';
        }
    }


    function changeValueInJson(key, value, index, indexGroup, indexValue, index_, index_column, key_arr, index5) {
        console.log(key, value, index, indexGroup, indexValue, index_, index5)

        let tempData = specificField || [];
        //console.log( tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`])
        setTextChange({ ...textChange, text: value });
        textChange.text = value;
        /* if (key == 'value' || key == 'value_type' || key == 'is_multiple') {
    
          tempData[indexGroup]['value_list'][indexValue][key] = textChange.text;
        } else { */
        if (key == 'value_input_table') {
            console.log(tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_])
            if (index_column === 2) {
                let arrayValue = {
                    name: tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[2][index5]?.name || '',
                    position: tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[2][index5]?.position || ''
                };
                arrayValue[key_arr] = value || ''

                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[index_column][index5] = arrayValue;
            } else {
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_].value_input_table[index_column] = textChange.text;
            }
        } else {
            if (index_ > -1) {
                console.log("in")
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue].data_list[index_][`${key}`] = textChange.text;
            } else {
                console.log("out")
                tempData[index].dt_input_data.data[indexGroup].value_list[indexValue][`${key}`] = textChange.text;
            }
        }


        /*   } */
        setSpecificField(tempData);
        callbackSrsSpecificField(tempData);
        setTest({ ...test, test: '' });
        test.test = '';

    }



    function SetColorInCompany(ColorValue) {
        let oleTabUserSelect = momTabUserSelect;
        let getFilter = oleTabUserSelect.filter((item) => {return item.mp_company === momTabCompanyTabSelectId}) 
        if(getFilter.length > 0){
          for(let item2 of getFilter){
              item2.mmp_color = ColorValue
          }
        }
        setMomTabUserSelect(oleTabUserSelect);
        setTest({...test,test:''});
        test.test = '';
      }



    return (
        <>
            <div className="box-content-evens2">
                <div className="row delpm">
                    <div className="col-12">
                        <div className="box-content-evens1" style={{ height: '550px', marginBottom: "5px" }}>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Prepared by</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            id={textAreaId.prepared_sp_name}
                                            type='text'
                                            value={tempSrsSave.prepared_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {

                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'prepared_sp_name')
                                            }}
                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>
                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            options={optionRole}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.prepared_sp_position_id })}
                                            onSearch={onSearch}
                                            filterOption={filterOption}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                // console.log('ดูข้อมูลในดาว', e, l, l.label);
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_position_id: e,
                                                    prepared_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'prepared_sp_position_id', l.label, 'prepared_sp_position')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.prepared_sp_date_sign ? dayjs(tempSrsSave.prepared_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'prepared_sp_date_sign')
                                            }}

                                        />
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.prepared_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    prepared_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'prepared_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Verified by </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            type='text'
                                            value={tempSrsSave.verified_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'verified_sp_name')
                                            }}

                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            onSearch={onSearch}
                                            filterOption={filterOption}

                                            options={optionRole}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.verified_sp_position_id })}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_position_id: e,
                                                    verified_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'verified_sp_position_id', l.label, 'verified_sp_position')

                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.verified_sp_date_sign ? dayjs(tempSrsSave.verified_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'verified_sp_date_sign')
                                            }}

                                        />
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.verified_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    verified_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'verified_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Approved by</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            type='text'
                                            value={tempSrsSave.approved_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'approved_sp_name')
                                            }}
                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            options={optionRole}
                                            onSearch={onSearch}
                                            filterOption={filterOption}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.approved_sp_position_id })}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_position_id: e,
                                                    approved_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'approved_sp_position_id', l.label, 'approved_sp_position')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.approved_sp_date_sign ? dayjs(tempSrsSave.approved_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'approved_sp_date_sign')
                                            }}

                                        />
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.approved_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    approved_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'approved_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-6">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Customer Approved</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <textarea className="areaTEst rezieznone"
                                            type='text'
                                            value={tempSrsSave.customer_approved_sp_name}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_name: e.target.value
                                                })
                                                callBackSrsSave(e.target.value, 'customer_approved_sp_name')

                                            }}

                                        >
                                        </textarea>

                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Current Role</div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <Select
                                            className='testSelect-01'
                                            showSearch
                                            onSearch={onSearch}
                                            filterOption={filterOption}
                                            options={optionRole}
                                            value={optionRole.filter((e) => { return e.value === tempSrsSave.customer_approved_sp_position_id })}
                                            disabled={userismode}
                                            onChange={(e, l, s) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_position_id: e,
                                                    customer_approved_sp_position: l.label
                                                })
                                                callBackSrsSave(e, 'customer_approved_sp_position_id', l.label, 'customer_approved_sp_position')
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px', marginBottom: '9px' }}>Date </div>
                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                        <StyledDatePicker
                                            className="dateText3"
                                            value={tempSrsSave.customer_approved_sp_date_sign ? dayjs(tempSrsSave.customer_approved_sp_date_sign) : null}
                                            disabled={userismode}
                                            placeholder="วว/ดด/ปปปป"
                                            format={"DD/MM/YYYY"}
                                            onChange={(date, dateString) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_date_sign: date ? date.$d : null
                                                })
                                                callBackSrsSave(date ? date.$d : null, 'customer_approved_sp_date_sign')
                                            }}

                                        />
                                        {/* <input className="dateText"

                                            type='date'
                                            value={moment(tempSrsSave.customer_approved_sp_date_sign).format('YYYY-MM-DD')}
                                            disabled={userismode}
                                            onChange={(e) => {
                                                setTempSrsSave({
                                                    ...tempSrsSave,
                                                    customer_approved_sp_date_sign: new Date(e.target.value)
                                                })
                                                callBackSrsSave(new Date(e.target.value), 'customer_approved_sp_date_sign')
                                            }}
                                        >
                                        </input> */}
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-12">

                                    {specificField && specificField.map((e, index) => {
                                        //console.log(0)
                                        return (<>

                                            <div className="row">
                                                <div className="col-12" style={{ marginTop: "20px" }}>
                                                    <span style={{ fontSize: '24px' }}>{e.dt_name}</span>
                                                </div>

                                            </div>
                                            <div className="boxSpecific" >
                                                <div className="boxSpecificIn" >
                                                    {e.dt_input_data.data.length == 0 && (<><font color="red">No specific</font></>)}
                                                    {e.dt_input_data.data.map((e2, index2) => {

                                                        return (<>
                                                            {index2 > 0 && (<br />)}
                                                            <div className="row mt-2">
                                                                <div className="col-12"><Tooltip color='#051F6C' placement="right" title={e2.suggestion}>
                                                                    <span style={{ fontSize: '18px' }}>{index2 + 1}.{e2.group_name}</span>
                                                                </Tooltip></div>

                                                            </div>
                                                            <br />
                                                            <div className="row">
                                                                {e2.value_list.length == 0 && (<> <div className="col-6"><font color="red">No value</font></div></>)}
                                                                {e2.value_list.map((e3, index3) => {

                                                                    return (<>
                                                                        <div className="col-12">
                                                                            <div className="mb-2 mt-2">
                                                                                {index2 + 1}.{index3 + 1} {e3.value}
                                                                                {/* {userismode === false ? 
                                                                                (<>
                                                                                    {e3.is_multiple && (<button
                                                                                        className="btnAddSpecific ml-2" style={{ height: '32px', paddingTop: '2px' }}
                                                                                        onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                                    ><i class="fa fa-plus" aria-hidden="true"></i> ADD
                                                                                    </button>)
                                                                                    }
                                                                                </>) : <></>} */}
                                                                            </div>

                                                                            {e3.is_multiple ? (<>

                                                                                {(e3.value_type === 'table_column' && momTabMode == 'table') && <div className='momTabBox'>
                                                                                    <div style={{ margin: 0, padding: 0, display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div style={{ width: '25%' }}>
                                                                                            <Input
                                                                                                className='momTabSearch'
                                                                                                placeholder='Search'
                                                                                                prefix={<SearchOutlined />}
                                                                                                onChange={(e) => {
                                                                                                    setMomTabTableSearch(e.target.value)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        <div style={{ display: 'flex' }}>
                                                                                            {!userismode ?
                                                                                                <>
                                                                                                    <button
                                                                                                        className="btnAddSpecific ml-2"
                                                                                                        style={{
                                                                                                            padding: '10px 20px',
                                                                                                            display: 'flex',
                                                                                                            justifyContent: 'center',
                                                                                                            alignItems: 'center',
                                                                                                            margin: '0',
                                                                                                            backgroundColor: '#0047FF',
                                                                                                            borderRadius: '4px'
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            // checkMomCount();
                                                                                                            setMomTabEditData({
                                                                                                                mom_tab: [],
                                                                                                                mom_link: []
                                                                                                            });
                                                                                                            setMomTabUserSelect([]);
                                                                                                            setMomTabMode('add');
                                                                                                        }}
                                                                                                    >
                                                                                                        <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                                                                            <PlusCircleOutlined />
                                                                                                            <span>
                                                                                                                NEW SRS
                                                                                                            </span>
                                                                                                        </div>
                                                                                                    </button>
                                                                                                </> : null}

                                                                                            <button
                                                                                                className="btnAddSpecific ml-2"
                                                                                                disabled={userismode}
                                                                                                style={{
                                                                                                    cursor: !userismode ? "pointer" : "no-drop",
                                                                                                    padding: '10px 20px',
                                                                                                    display: 'flex',
                                                                                                    justifyContent: 'center',
                                                                                                    alignItems: 'center',
                                                                                                    margin: '0',
                                                                                                    backgroundColor: '#0047FF',
                                                                                                    borderRadius: '4px'
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    if (!userismode) {
                                                                                                        downloadMOMAll();
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M11 7.5H16L10 13.5L4 7.5H9V0.5H11V7.5ZM2 16.5H18V9.5H20V17.5C20 18.0523 19.5523 18.5 19 18.5H1C0.44772 18.5 0 18.0523 0 17.5V9.5H2V16.5Z" fill="white" />
                                                                                                    </svg>
                                                                                                    <span>
                                                                                                        Download All
                                                                                                    </span>
                                                                                                </div>

                                                                                            </button>
                                                                                            <button
                                                                                                className="btnAddSpecific ml-2"
                                                                                                disabled={userismode}
                                                                                                style={{
                                                                                                    cursor: !userismode ? "pointer" : "no-drop",
                                                                                                    padding: '10px 20px',
                                                                                                    display: 'flex',
                                                                                                    justifyContent: 'center',
                                                                                                    alignItems: 'center',
                                                                                                    margin: '0',
                                                                                                    backgroundColor: '#0047FF',
                                                                                                    borderRadius: '4px'
                                                                                                }}
                                                                                                onClick={() => {
                                                                                                    if (!userismode) {
                                                                                                        socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL' });
                                                                                                        generateMomReportAll();
                                                                                                    }
                                                                                                }}
                                                                                            >
                                                                                                <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                        <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF414" />
                                                                                                    </svg>

                                                                                                    <span>
                                                                                                        GENERATE ALL
                                                                                                    </span>
                                                                                                </div>
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div style={{ flexGrow: '1', height: '100%' }}>
                                                                                        <table>
                                                                                            <thead className='momTabTableHeader'>
                                                                                                <td className='momTabTableHeaderText'>No.</td>
                                                                                                <td className='momTabTableHeaderText'>ประเภท</td>
                                                                                                <td className='momTabTableHeaderText'>SRS Code</td>
                                                                                                <td className='momTabTableHeaderText' style={{ width: '30%' }}>หัวข้อการประชุม</td>
                                                                                                <td className='momTabTableHeaderText'>วันที่เริ่มต้น - สิ้นสุด</td>
                                                                                                <td className='momTabTableHeaderText'>ผู้เข้าร่วมการประชุม</td>
                                                                                                <td className='momTabTableHeaderText'>Management</td>
                                                                                            </thead>
                                                                                            <tbody className='momTabTableBody'>
                                                                                                {momData?.filter((filter) => {
                                                                                                    return momTabTableSearch != '' ? (filter.mom_header || '').toString().toLocaleLowerCase().includes(momTabTableSearch.toString().toLocaleLowerCase()) || (filter.mom_code || '').toString().toLocaleLowerCase().includes(momTabTableSearch.toString().toLocaleLowerCase()) : filter
                                                                                                }).map((e, i) => {
                                                                                                    return <>
                                                                                                        <tr>
                                                                                                            <td className='momTabTableBodyText'>{i + 1}</td>
                                                                                                            <td className='momTabTableBodyText' style={{ color: `${e.mom_tag != 'srs' ? '#0094FF' : '#26E607'}` }}>{e.mom_tag || 'MOM'}</td>
                                                                                                            <td className='momTabTableBodyText'>{e.mom_code || '-'}</td>
                                                                                                            <td className='momTabTableBodyText' style={{ textAlign: 'start' }}>{e.mom_header}</td>
                                                                                                            <td className='momTabTableBodyText'>{moment(e.mom_start_date).format('DD/MM/YYYY HH.mm')}<br />{moment(e.mom_end_date).format('DD/MM/YYYY HH.mm')}</td>
                                                                                                            <td className='momTabTableBodyText'><UserOutlined /> {e.user_list.length || 0}</td>
                                                                                                            <td className='momTabTableBodyText' style={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
                                                                                                                <Tooltip placement="top" title={'View'} overlayClassName="momTabCustomTooltip">
                                                                                                                    <EyeOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                        setMomTabUserSelect(e.user_list);
                                                                                                                        setMomTabCompanyTabSelectId(null);
                                                                                                                        setMomTabCompanyTabSelect(null);
                                                                                                                        setMomTabEditData(e);
                                                                                                                        setMomTabMode('view');
                                                                                                                    }} />
                                                                                                                </Tooltip>

                                                                                                                <Tooltip placement="top" title={'Edit'} overlayClassName="momTabCustomTooltip">
                                                                                                                    <EditOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                        setMomTabUserSelect(e.user_list);
                                                                                                                        setMomTabCompanyTabSelectId(null);
                                                                                                                        setMomTabCompanyTabSelect(null);
                                                                                                                        setMomTabEditData(e);
                                                                                                                        setMomTabMode('edit');
                                                                                                                    }} />
                                                                                                                </Tooltip>
                                                                                                                <Tooltip placement="top" title={'Download'} overlayClassName="momTabCustomTooltip">
                                                                                                                    <DownloadOutlined style={{ cursor: 'pointer' }} onClick={() => {

                                                                                                                        downloadMOM(e.mom_id);
                                                                                                                        toggle_modal_alertKick();
                                                                                                                    }} />
                                                                                                                </Tooltip>

                                                                                                                <Tooltip placement="top" title={'Generate'} overlayClassName="momTabCustomTooltip">
                                                                                                                    <svg width="20" height="20" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                        socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL' });
                                                                                                                        generateMomReport(e.mom_id);
                                                                                                                        e.momt_created_date = new Date();
                                                                                                                        toggle_modal_alertKick();
                                                                                                                    }}>
                                                                                                                        <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF" />
                                                                                                                    </svg>
                                                                                                                </Tooltip>
                                                                                                                <Tooltip placement="top" title={'Delete'} overlayClassName="momTabCustomTooltip">
                                                                                                                    <DeleteOutlined style={{ cursor: 'pointer' }} onClick={() => {
                                                                                                                        /*  socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'GENERATEALL'}); */
                                                                                                                        setAlertKickConfirmId(e.mom_id);
                                                                                                                        setTest({ ...test, test: '' });
                                                                                                                        test.test = '';
                                                                                                                        toggle_modal_alertKickConfirm();
                                                                                                                    }} />
                                                                                                                </Tooltip>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </>
                                                                                                })}
                                                                                            </tbody>
                                                                                            <tbody>
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>}

                                                                                {(e3.value_type === 'table_column' && momTabMode == 'add') && <div className='momTabBox'>
                                                                                    <div style={{ margin: 0, padding: '0 0 5px 0', display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div className='momTabBoxHeader'>SRS ADD</div>
                                                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                                                            <div className='momTabAddButton' onClick={async () => {
                                                                                                let check = true;
                                                                                                if (momTabAddData.mom_start_date
                                                                                                    && momTabAddData.mom_end_date
                                                                                                    && momTabAddData.mom_record_by?.trim()?.length > 0
                                                                                                    && momTabAddData.mom_header?.trim()?.length > 0
                                                                                                    && momTabAddData.mom_description?.trim()?.length > 0
                                                                                                    && momTabAddData.mom_location?.trim()?.length > 0) {
                                                                                                    if (momTabAddData.mom_link?.length > 0) {
                                                                                                        for (let item of momTabAddData.mom_link) {
                                                                                                            if (!item.name || item.name?.trim()?.length < 1 || !item.link || item.link?.trim()?.length < 1) {
                                                                                                                check = false
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                } else {
                                                                                                    check = false
                                                                                                }

                                                                                                if (check) {
                                                                                                    addMomData();
                                                                                                } else {
                                                                                                    toggle_modal_alertKickMassge();
                                                                                                }
                                                                                            }}>SAVE</div>
                                                                                            <div className='momTabAddButton' onClick={() => {
                                                                                                setMomTabAddData({
                                                                                                    ...momTabAddData,
                                                                                                    mom_tab: [],
                                                                                                    mom_link: []
                                                                                                });
                                                                                                setMomTabUserSelect([]);
                                                                                                setMomTabMode('table');
                                                                                            }}>CANCEL</div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div>
                                                                                        <div className='momTabAddBox'>
                                                                                            <div style={{ margin: 0, padding: '0 0 5px 0', borderBottom: '2px solid #0047FF', width: '100%', display: 'flex', gap: '10px', alignItems: 'center', height: 'fit-content' }}>
                                                                                                <div className='momTabBoxHeader'>บันทึกการประชุม : ครั้งที่</div>
                                                                                                <div className='momTabAddNum'>{momTabAddData.no}</div>
                                                                                            </div>

                                                                                            <div className='momTabAddBody'>
                                                                                                <div className='momTabAddBodyLeft'>
                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_start_date && momTabAddData.mom_end_date ? 'none' : ''}` }}>*</span>เวลา :</div>
                                                                                                        <RangePicker
                                                                                                            className='rangePickerMomTab'
                                                                                                            showTime
                                                                                                            format={"DD/MM/YYYY HH:mm:ss"}
                                                                                                            style={{ borderRadius: '0px', width: '380px' }}
                                                                                                            allowClear={false}
                                                                                                            onChange={(_, v) => {
                                                                                                                changeValueInJsonSrs(`mom_date`, _);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_record_by?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ผู้บันทึกการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonSrs('mom_record_by', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_header?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>หัวข้อการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonSrs('mom_header', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_description?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>รายละเอียดการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonSrs('mom_description', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabAddData.mom_location?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>สถานที่ :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonSrs('mom_location', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className='momTabAddLickDocument'>
                                                                                                        <div>LINK DOCUMENT</div>
                                                                                                        <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                                                                                                            momTabAddData.mom_link = momTabAddData.mom_link || [];
                                                                                                            momTabAddData.mom_link?.push({
                                                                                                                name: '',
                                                                                                                link: ''
                                                                                                            });

                                                                                                            setTest({ ...test, test: '' });
                                                                                                            test.test = '';
                                                                                                        }}>
                                                                                                            <PlusOutlined style={{ fontSize: '12px' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {momTabAddData.mom_link?.map((el, il) => {
                                                                                                        return <>
                                                                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                    <div className='momTabAddLinkIndex'>{il + 1}</div>
                                                                                                                    <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                                                                                                        momTabAddData.mom_link?.splice(il, 1);

                                                                                                                        setTest({ ...test, test: '' });
                                                                                                                        test.test = '';
                                                                                                                    }}>
                                                                                                                        <DeleteOutlined />
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div style={{ border: '2px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                        <div style={{ minWidth: '12.5%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.name?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ชื่อ :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTab'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={el.name}
                                                                                                                            onChange={(e) => handleLinkInputChangeLink('name', e.target.value, il)}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                        <div style={{ minWidth: '12.5%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.link?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>Link :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTab'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={el.link}
                                                                                                                            onChange={(e) => handleLinkInputChangeLink('link', e.target.value, il)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    })}
                                                                                                </div>

                                                                                                <div className='momTabAddBodyRight'>
                                                                                                    <div style={{ overflow: 'auto', width: '650px' }}>
                                                                                                        <DragDropContext onDragEnd={handleOnDragTab}>
                                                                                                            <Droppable droppableId="companies" direction="horizontal">
                                                                                                                {(provided) => (
                                                                                                                    <div
                                                                                                                        style={{ width: '100%', display: 'flex' }}
                                                                                                                        {...provided.droppableProps}
                                                                                                                        ref={provided.innerRef}
                                                                                                                    >
                                                                                                                        {momTabUserSelect?.filter((item, index, self) =>
                                                                                                                            index === self.findIndex(t => t.mp_company === item.mp_company)
                                                                                                                        )?.sort((a, b) => {
                                                                                                                            return (a.mmp_sort_company || 0) - (b.mmp_sort_company || 0)
                                                                                                                        }
                                                                                                                        )?.map((et, it) => (
                                                                                                                            <Draggable key={et.mp_company} draggableId={et.mp_company} index={it}>
                                                                                                                                {(provided) => (
                                                                                                                                    <div
                                                                                                                                        ref={provided.innerRef}
                                                                                                                                        {...provided.draggableProps}
                                                                                                                                        {...provided.dragHandleProps}
                                                                                                                                        style={{
                                                                                                                                            background: `${momTabCompanyTabSelectId == et.mp_company ? '#002B99' : 'linear-gradient(to Left, rgba(0,43,153,0.5) 0%, rgba(0,14,51,0.5) 100%)'}`,
                                                                                                                                            padding: '0 7.5px',
                                                                                                                                            display: 'flex',
                                                                                                                                            justifyContent: 'center',
                                                                                                                                            alignItems: 'center',
                                                                                                                                            gap: '5px',
                                                                                                                                            cursor: 'pointer',
                                                                                                                                            ...provided.draggableProps.style,
                                                                                                                                        }}
                                                                                                                                        onClick={() => {
                                                                                                                                            setMomTabCompanyTabSelectId(et.mp_company);
                                                                                                                                            setMomTabCompanyTabSelect(it);
                                                                                                                                            setTest({ ...test, test: '' });
                                                                                                                                            test.test = '';
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <div style={{ width: 'fit-content', padding: '5px 10px', whiteSpace: 'nowrap' }}>
                                                                                                                                            {et.mp_company}
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            </Draggable>
                                                                                                                        ))}
                                                                                                                        {provided.placeholder}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Droppable>
                                                                                                        </DragDropContext>
                                                                                                    </div>

                                                                                                    <div style={{ border: '2px solid #0047FF', padding: '10px', display: 'flex', alignItems: 'flex-start', gap: '10px', flexDirection: 'column', flexGrow: '1' }}>
                                                                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', width: '100%' }}>
                                                                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                                                                <div>ผู้เข้าร่วมประชุม</div>
                                                                                                                <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                                                                                                                    toggleMomTabAddUserIsOpen();
                                                                                                                }}>
                                                                                                                    <PlusOutlined style={{ fontSize: '12px' }} />
                                                                                                                </div>
                                                                                                                <Input
                                                                                                                    className='momTabAddSearch'
                                                                                                                    value={momTabUserSearch}
                                                                                                                    onChange={(e) => {
                                                                                                                        setMomTabUserSearch(e.target.value);
                                                                                                                    }}
                                                                                                                />
                                                                                                                {momTabUserSelect?.filter((fil) => {
                                                                                                                    return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                }).length > 0 ?

                                                                                                                    <ColorPicker
                                                                                                                        className='momTabAddColor'
                                                                                                                        size="small"
                                                                                                                        defaultFormat="rgb"
                                                                                                                        format="rgb"
                                                                                                                        destroyTooltipOnHide
                                                                                                                        disabledAlpha
                                                                                                                        value={momTabUserSelect?.filter((fil) => {
                                                                                                                            return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                        })[0].mmp_color ?? 'rgb(0,0,0)'}
                                                                                                                        onChange={(c) => {
                                                                                                                            SetColorInCompany(c.toRgbString())
                                                                                                                        }}
                                                                                                                    /> : <>

                                                                                                                    </>}
                                                                                                            </div>

                                                                                                            <div>
                                                                                                                <UserOutlined /> {momTabUserSelect?.filter((fil) => {
                                                                                                                    return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                })?.length || 0}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column' }}>
                                                                                                            {!momTabUserIsEdit ?
                                                                                                                <>
                                                                                                                    <DragDropContext onDragEnd={handleOnDragPersonal}>
                                                                                                                        <Droppable droppableId="momTabUserSelect">
                                                                                                                            {(provided) => (
                                                                                                                                <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column', position: 'relative' }}>
                                                                                                                                    {momTabUserSelect?.filter((fil) => {
                                                                                                                                        return momTabUserSearch !== ''
                                                                                                                                            ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company === momTabCompanyTabSelectId
                                                                                                                                            : fil.mp_company === momTabCompanyTabSelectId;
                                                                                                                                    })?.sort((a, b) => {
                                                                                                                                        return (a.mmp_sort_personal || 0) - (b.mmp_sort_personal || 0)
                                                                                                                                    }
                                                                                                                                    )?.map((e, i) => (
                                                                                                                                        <Draggable key={e.mp_id} draggableId={e.mp_id.toString()} index={i}>
                                                                                                                                            {(provided, snapshot) => {
                                                                                                                                                const isDragging = snapshot.isDragging;
                                                                                                                                                return (
                                                                                                                                                    <div
                                                                                                                                                        ref={provided.innerRef}
                                                                                                                                                        {...provided.draggableProps}
                                                                                                                                                        {...provided.dragHandleProps}
                                                                                                                                                        style={{
                                                                                                                                                            ...provided.draggableProps.style,
                                                                                                                                                            display: 'flex',
                                                                                                                                                            gap: '10px',
                                                                                                                                                            width: '100%',
                                                                                                                                                            flexDirection: 'row',
                                                                                                                                                            transition: 'none',
                                                                                                                                                            boxShadow: isDragging ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none',
                                                                                                                                                            ...provided.draggableProps.style,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                                                            <div className='momTabAddLinkIndex'>{i + 1}</div>
                                                                                                                                                            <div style={{ border: '1px solid #E4DA15', backgroundColor: '#9D9602', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                                                                                                                                                setMomTabUserEditData(e);
                                                                                                                                                                toggleMomTabUserIsEdit();
                                                                                                                                                                setTest({ ...test, test: '' });
                                                                                                                                                                test.test = '';
                                                                                                                                                            }} >
                                                                                                                                                                <EditOutlined />
                                                                                                                                                            </div>
                                                                                                                                                            <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                                                                                                                                                let findIndexUser = momTabUserSelect.findIndex((find) => find.mp_id == e.mp_id);
                                                                                                                                                                if (findIndexUser > -1) {
                                                                                                                                                                    momTabUserSelect.splice(findIndexUser, 1);
                                                                                                                                                                }
                                                                                                                                                                let check = momTabUserSelect?.filter((fil) => fil.mp_company === momTabCompanyTabSelectId);

                                                                                                                                                                if (check?.length === 0) {
                                                                                                                                                                    let findIndex = momTabEditData.mom_tab?.findIndex((find) => find.mp_company === e.mp_company);
                                                                                                                                                                    if (findIndex > -1) {
                                                                                                                                                                        momTabEditData.mom_tab.splice(findIndex, 1);
                                                                                                                                                                    }
                                                                                                                                                                    setMomTabCompanyTabSelect(null);
                                                                                                                                                                    setMomTabCompanyTabSelectId('');
                                                                                                                                                                }
                                                                                                                                                                setTest({ ...test, test: '' });
                                                                                                                                                                test.test = '';
                                                                                                                                                            }}>
                                                                                                                                                                <DeleteOutlined />
                                                                                                                                                            </div>
                                                                                                                                                        </div>

                                                                                                                                                        <div style={{ border: '1px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px' }}>
                                                                                                                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                                                                <div style={{ minWidth: '20%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                                                                                                                                                    <div style={{ width: '50%', height: '50%', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                                                                                                                                                    <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                                                                                                                                                </div>
                                                                                                                                                                <div>
                                                                                                                                                                    <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                                                                                                                                                    <div>{e.mp_position || '-'}</div>
                                                                                                                                                                    <div>{e.mp_email || '-'}</div>
                                                                                                                                                                    <div>{e.mp_tel || '-'}</div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                );
                                                                                                                                            }}
                                                                                                                                        </Draggable>
                                                                                                                                    ))}
                                                                                                                                    {provided.placeholder}
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </Droppable>
                                                                                                                    </DragDropContext>
                                                                                                                </> : <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                                                                                                                    <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                        <div>ชื่อ-นามสกุล :</div>
                                                                                                                        <div style={{ color: '#0094FF' }}>{momTabUserEditData.mp_name}</div>

                                                                                                                        <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_position?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ตำแหน่ง :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_position}
                                                                                                                            onChange={(e) => {
                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_position: e.target.value });
                                                                                                                            }}
                                                                                                                        />

                                                                                                                        <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null ? 'none' : ''}` }}>*</span>Email :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_email}
                                                                                                                            onChange={(e) => {
                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_email: e.target.value });
                                                                                                                            }}
                                                                                                                        />

                                                                                                                        <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null ? 'none' : ''}` }}>*</span>เบอร์โทร :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_tel}
                                                                                                                            onChange={(e) => {
                                                                                                                                let value = e.target.value.replace(/\D/g, '');

                                                                                                                                if (value.length > 3) {
                                                                                                                                    value = `${value.slice(0, 3)}-${value.slice(3)}`;
                                                                                                                                }

                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_tel: value });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                                                                                                        <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                                                                                            if (momTabUserEditData.mp_position?.trim()?.length > 0 &&
                                                                                                                                (momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null) &&
                                                                                                                                (momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null)
                                                                                                                            ) {
                                                                                                                                let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == momTabUserEditData.mp_id);
                                                                                                                                if (findIndex > -1) {
                                                                                                                                    momTabUserSelect[findIndex] = momTabUserEditData;
                                                                                                                                };
                                                                                                                                toggleMomTabUserIsEdit();
                                                                                                                            } else {
                                                                                                                                toggle_modal_alertKickMassge();
                                                                                                                            }
                                                                                                                        }}>SAVE</div>
                                                                                                                        <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                                                                                            toggleMomTabUserIsEdit();
                                                                                                                        }}>CANCEL</div>
                                                                                                                    </div>
                                                                                                                </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}

                                                                                {(e3.value_type === 'table_column' && momTabMode == 'view') && <div className='momTabBox'>
                                                                                    <div style={{ margin: 0, padding: '0 0 5px 0', display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div className='momTabBoxHeader'>SRS VIEW</div>
                                                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                                                            <div className='momTabAddButton' onClick={() => {
                                                                                                setMomTabEditData({
                                                                                                    ...momTabAddData,
                                                                                                    mom_tab: [],
                                                                                                    mom_link: []
                                                                                                });
                                                                                                setMomTabUserSelect([]);
                                                                                                setMomTabMode('table');
                                                                                            }}>CANCEL</div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div>
                                                                                        <div className='momTabAddBox'>
                                                                                            <div style={{ margin: 0, padding: '0 0 5px 0', borderBottom: '2px solid #0047FF', width: '100%', display: 'flex', gap: '10px', alignItems: 'center', height: 'fit-content' }}>
                                                                                                <div className='momTabBoxHeader'>บันทึกการประชุม : ครั้งที่</div>
                                                                                                <div className='momTabAddNum'>{momTabEditData.mom_no}</div>
                                                                                            </div>

                                                                                            <div className='momTabAddBody'>
                                                                                                <div className='momTabAddBodyLeft'>
                                                                                                    <div>
                                                                                                        <div className='momTabAddText'>เวลา :</div>
                                                                                                        <RangePicker
                                                                                                            className='rangePickerMomTab'
                                                                                                            showTime
                                                                                                            disabled={true}
                                                                                                            format={"DD/MM/YYYY HH:mm:ss"}
                                                                                                            style={{ borderRadius: '0px', width: '380px' }}
                                                                                                            allowClear={true}
                                                                                                            value={
                                                                                                                momTabEditData.mom_start_date && momTabEditData.mom_end_date
                                                                                                                    ? [dayjs(momTabEditData.mom_start_date), dayjs(momTabEditData.mom_end_date)]
                                                                                                                    : [null, null]
                                                                                                            }
                                                                                                            onChange={(_, v) => {
                                                                                                                changeValueInJsonEditSrs(`mom_date`, _);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'>ผู้บันทึกการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            disabled={true}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_record_by || '-'}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_record_by', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'>หัวข้อการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            disabled={true}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_header || '-'}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_header', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'>รายละเอียดการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            disabled={true}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_description || '-'}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_description', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'>สถานที่ :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            disabled={true}
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_location || '-'}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_location', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className='momTabAddLickDocument'>
                                                                                                        <div>LINK DOCUMENT</div>
                                                                                                    </div>
                                                                                                    {momTabEditData.mom_link?.map((el, il) => {
                                                                                                        return <>
                                                                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                    <div className='momTabAddLinkIndex' style={{ width: '30px' }}>{il + 1}</div>
                                                                                                                </div>

                                                                                                                <div style={{ border: '2px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                        <div style={{ minWidth: '10%', textAlign: 'end' }}>ชื่อ :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTab'
                                                                                                                            disabled={true}
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={el.md_name || '-'}
                                                                                                                            onChange={(e) => handleLinkInputChangeEditLink('name', e.target.value, il)}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                        <div style={{ minWidth: '10%', textAlign: 'end' }}>Link :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTab'
                                                                                                                            disabled={true}
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={el.md_link || '-'}
                                                                                                                            onChange={(e) => handleLinkInputChangeEditLink('link', e.target.value, il)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    })}
                                                                                                </div>

                                                                                                <div className='momTabAddBodyRight'>
                                                                                                    <div style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', width: '100%' }}>
                                                                                                        <div style={{ fontSize: '12px', color: 'grey' }}>Generate Date {momTabEditData.momt_created_date ? moment(momTabEditData.momt_created_date).format('DD/MM/YYYY') : '-'}</div>
                                                                                                        {momTabMode == 'view' && <div style={{ display: 'flex' }}>
                                                                                                            <button
                                                                                                                className="btnAddSpecific ml-2"
                                                                                                                disabled={userismode}
                                                                                                                style={{
                                                                                                                    cursor: !userismode ? "pointer" : "no-drop",
                                                                                                                    padding: '10px 20px',
                                                                                                                    display: 'flex',
                                                                                                                    justifyContent: 'center',
                                                                                                                    alignItems: 'center',
                                                                                                                    margin: '0',
                                                                                                                    backgroundImage: 'linear-gradient(75deg, rgba(0,71,255,1) 0%, rgba(2,30,111,1) 100%)',
                                                                                                                    borderRadius: '4px'
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    if (!userismode) {
                                                                                                                        downloadMOM(momTabEditData.mom_id);
                                                                                                                    }
                                                                                                                }}
                                                                                                            >
                                                                                                                <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                                                                                    <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <path d="M11 7.5H16L10 13.5L4 7.5H9V0.5H11V7.5ZM2 16.5H18V9.5H20V17.5C20 18.0523 19.5523 18.5 19 18.5H1C0.44772 18.5 0 18.0523 0 17.5V9.5H2V16.5Z" fill="white" />
                                                                                                                    </svg>
                                                                                                                    <span>
                                                                                                                        Download
                                                                                                                    </span>
                                                                                                                </div>

                                                                                                            </button>
                                                                                                            <button
                                                                                                                className="btnAddSpecific ml-2"
                                                                                                                disabled={userismode}
                                                                                                                style={{
                                                                                                                    cursor: !userismode ? "pointer" : "no-drop",
                                                                                                                    padding: '10px 20px',
                                                                                                                    display: 'flex',
                                                                                                                    justifyContent: 'center',
                                                                                                                    alignItems: 'center',
                                                                                                                    margin: '0',
                                                                                                                    backgroundImage: 'linear-gradient(75deg, rgba(0,71,255,1) 0%, rgba(2,30,111,1) 100%)',
                                                                                                                    borderRadius: '4px'
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL' });
                                                                                                                    generateMomReport(momTabEditData.mom_id);
                                                                                                                    momTabEditData.momt_created_date = new Date();
                                                                                                                }}
                                                                                                            >
                                                                                                                <div className='row' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                                                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                                        <path d="M15 5.75C16.7949 5.75 18.25 4.29493 18.25 2.5H19.75C19.75 4.29493 21.2051 5.75 23 5.75V7.25C21.2051 7.25 19.75 8.70507 19.75 10.5H18.25C18.25 8.70507 16.7949 7.25 15 7.25V5.75ZM4 7.5C4 6.39543 4.89543 5.5 6 5.5H13V3.5H6C3.79086 3.5 2 5.29086 2 7.5V17.5C2 19.7091 3.79086 21.5 6 21.5H18C20.2091 21.5 22 19.7091 22 17.5V12.5H20V17.5C20 18.6046 19.1046 19.5 18 19.5H6C4.89543 19.5 4 18.6046 4 17.5V7.5Z" fill="#FFF414" />
                                                                                                                    </svg>

                                                                                                                    <span>
                                                                                                                        GENERATE
                                                                                                                    </span>
                                                                                                                </div>
                                                                                                            </button>
                                                                                                        </div>}
                                                                                                    </div>

                                                                                                    <div style={{ overflow: 'auto', width: '650px', marginTop: '20px ' }}>
                                                                                                        <div style={{ width: '100%', display: 'flex' }}>
                                                                                                            {momTabUserSelect?.filter((item, index, self) =>
                                                                                                                index === self.findIndex(t => t.mp_company === item.mp_company)
                                                                                                            )?.sort((a, b) => {
                                                                                                                return (a.mmp_sort_company || 0) - (b.mmp_sort_company || 0)
                                                                                                            }
                                                                                                            )?.map((et, it) => {
                                                                                                                return (
                                                                                                                    <div
                                                                                                                        style={{
                                                                                                                            background: `${momTabCompanyTabSelect == it ? '#002B99' : 'linear-gradient(to Left, rgba(0,43,153,0.5) 0%, rgba(0,14,51,0.5) 100%)'}`,
                                                                                                                            padding: '0 7.5px',
                                                                                                                            display: 'flex',
                                                                                                                            justifyContent: 'center',
                                                                                                                            alignItems: 'center',
                                                                                                                            gap: '5px',
                                                                                                                            cursor: 'pointer'
                                                                                                                        }}
                                                                                                                        onClick={() => {
                                                                                                                            setMomTabCompanyTabSelectId(et.mp_company);
                                                                                                                            setMomTabCompanyTabSelect(it);
                                                                                                                            setTest({ ...test, test: '' });
                                                                                                                            test.test = '';
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <div style={{ width: 'fit-content', padding: '5px 10px', whiteSpace: 'nowrap' }}>
                                                                                                                            {et.mp_company}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                );
                                                                                                            })}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div style={{ border: '2px solid #0047FF', padding: '10px', display: 'flex', alignItems: 'flex-start', gap: '10px', flexDirection: 'column', flexGrow: '1' }}>
                                                                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', width: '100%' }}>
                                                                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                                                                <div>ผู้เข้าร่วมประชุม </div>
                                                                                                                <Input
                                                                                                                    className='momTabAddSearch'
                                                                                                                    value={momTabUserSearch}
                                                                                                                    onChange={(e) => {
                                                                                                                        setMomTabUserSearch(e.target.value);
                                                                                                                    }}
                                                                                                                />
                                                                                                                 {momTabUserSelect?.filter((fil) => {
                                                                                                                    return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                }).length > 0 ?

                                                                                                                    <ColorPicker
                                                                                                                        className='momTabAddColor'
                                                                                                                        size="small"
                                                                                                                        defaultFormat="rgb"
                                                                                                                        format="rgb"
                                                                                                                        destroyTooltipOnHide
                                                                                                                        disabledAlpha
                                                                                                                        disabled
                                                                                                                        value={momTabUserSelect?.filter((fil) => {
                                                                                                                            return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                        })[0].mmp_color ?? 'rgb(0,0,0)'}
                                                                                                                        onChange={(c) => {
                                                                                                                            SetColorInCompany(c.toRgbString())
                                                                                                                        }}
                                                                                                                    /> : <>

                                                                                                                    </>}
                                                                                                            </div>

                                                                                                            <div>
                                                                                                                <UserOutlined /> {momTabUserSelect?.filter((fil) => {
                                                                                                                    return fil.mpg_id == momTabCompanyTabSelectId
                                                                                                                })?.length || 0}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column' }}>
                                                                                                            {!momTabUserIsEdit ?
                                                                                                                <>
                                                                                                                    {momTabUserSelect?.filter((fil) => {
                                                                                                                        return momTabUserSearch != '' ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company == momTabCompanyTabSelectId : fil.mp_company == momTabCompanyTabSelectId
                                                                                                                    })?.sort((a, b) => {
                                                                                                                        return (a.mmp_sort_personal || 0) - (b.mmp_sort_personal || 0)
                                                                                                                    }
                                                                                                                    )?.map((e, i) => {
                                                                                                                        return <div style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'row' }}>
                                                                                                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                                <div className='momTabAddLinkIndex' style={{ width: '30px' }}>{i + 1}</div>
                                                                                                                            </div>

                                                                                                                            <div style={{ border: '1px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px' }}>
                                                                                                                                <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                                    <div style={{ minWidth: '20%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                                                                                                                        <div style={{ width: '50%', height: '50%', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                                                                                                                        <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                                                                                                                        <div>{e.mp_position || '-'}</div>
                                                                                                                                        <div>{e.mp_email || '-'}</div>
                                                                                                                                        <div>{e.mp_tel || '-'}</div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    })}
                                                                                                                </> : <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                                                                                                                    <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                        <div>ชื่อ-นามสกุล :</div>
                                                                                                                        <div style={{ color: '#0094FF' }}>{momTabUserEditData.mp_name}</div>

                                                                                                                        <div>ตำแหน่ง :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_position}
                                                                                                                            onChange={(e) => {
                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_position: e.target.value });
                                                                                                                            }}
                                                                                                                        />

                                                                                                                        <div>Email :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_email}
                                                                                                                            onChange={(e) => {
                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_email: e.target.value });
                                                                                                                            }}
                                                                                                                        />

                                                                                                                        <div>เบอร์โทร :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_phone}
                                                                                                                            onChange={(e) => {
                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_phone: e.target.value });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                                                                                                        <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                                                                                            let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == momTabUserEditData.mp_id);
                                                                                                                            if (findIndex > -1) {
                                                                                                                                momTabUserSelect[findIndex] = momTabUserEditData;
                                                                                                                            };
                                                                                                                            toggleMomTabUserIsEdit();
                                                                                                                        }}>ADD</div>
                                                                                                                        <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                                                                                            toggleMomTabUserIsEdit();
                                                                                                                        }}>CANCEL</div>
                                                                                                                    </div>
                                                                                                                </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}

                                                                                {(e3.value_type === 'table_column' && momTabMode == 'edit') && <div className='momTabBox'>
                                                                                    <div style={{ margin: 0, padding: '0 0 5px 0', display: 'flex', justifyContent: 'space-between' }}>
                                                                                        <div className='momTabBoxHeader'>SRS EDIT</div>
                                                                                        <div style={{ display: 'flex', gap: '10px' }}>
                                                                                            <div className='momTabAddButton' onClick={async () => {
                                                                                                let check = true;
                                                                                                if (momTabEditData.mom_start_date
                                                                                                    && momTabEditData.mom_end_date
                                                                                                    && momTabEditData.mom_record_by?.trim()?.length > 0
                                                                                                    && momTabEditData.mom_header?.trim()?.length > 0
                                                                                                    && momTabEditData.mom_description?.trim()?.length > 0
                                                                                                    && momTabEditData.mom_location?.trim()?.length > 0) {
                                                                                                    if (momTabEditData.mom_link?.length > 0) {
                                                                                                        for (let item of momTabEditData.mom_link) {
                                                                                                            if (!item.md_name || item.md_name?.trim()?.length < 1 || !item.md_link || item.md_link?.trim()?.length < 1) {
                                                                                                                check = false
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                } else {
                                                                                                    check = false
                                                                                                }

                                                                                                if (check) {
                                                                                                    editMomData();
                                                                                                } else {
                                                                                                    toggle_modal_alertKickMassge();
                                                                                                }
                                                                                            }}>SAVE</div>
                                                                                            <div className='momTabAddButton' onClick={() => {
                                                                                                setMomTabAddData({
                                                                                                    ...momTabAddData,
                                                                                                    mom_tab: [],
                                                                                                    mom_link: []
                                                                                                });
                                                                                                setMomTabUserSelect([]);
                                                                                                setMomTabMode('table');
                                                                                                getMomData();
                                                                                            }}>CANCEL</div>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div>
                                                                                        <div className='momTabAddBox'>
                                                                                            <div style={{ margin: 0, padding: '0 0 5px 0', borderBottom: '2px solid #0047FF', width: '100%', display: 'flex', gap: '10px', alignItems: 'center', height: 'fit-content' }}>
                                                                                                <div className='momTabBoxHeader'>บันทึกการประชุม : ครั้งที่</div>
                                                                                                <div className='momTabAddNum'>{momTabEditData.mom_no}</div>
                                                                                            </div>

                                                                                            <div className='momTabAddBody'>
                                                                                                <div className='momTabAddBodyLeft'>
                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_start_date && momTabEditData.mom_end_date ? 'none' : ''}` }}>*</span>เวลา :</div>
                                                                                                        <RangePicker
                                                                                                            className='rangePickerMomTab'
                                                                                                            showTime
                                                                                                            format={"DD/MM/YYYY HH:mm:ss"}
                                                                                                            style={{ borderRadius: '0px', width: '380px' }}
                                                                                                            allowClear={false}
                                                                                                            value={
                                                                                                                momTabEditData.mom_start_date && momTabEditData.mom_end_date
                                                                                                                    ? [dayjs(momTabEditData.mom_start_date), dayjs(momTabEditData.mom_end_date)]
                                                                                                                    : [null, null]
                                                                                                            }
                                                                                                            onChange={(_, v) => {
                                                                                                                changeValueInJsonEditSrs(`mom_date`, _);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_record_by?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ผู้บันทึกการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_record_by}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_record_by', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_header?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>หัวข้อการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_header}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_header', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_description?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>รายละเอียดการประชุม :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_description}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_description', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div>
                                                                                                        <div className='momTabAddText'><span style={{ color: 'red', display: `${momTabEditData.mom_location?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>สถานที่ :</div>
                                                                                                        <TextArea
                                                                                                            className='textAreaMomTab'
                                                                                                            autoSize={{
                                                                                                                minRows: 1
                                                                                                            }}
                                                                                                            type='text'
                                                                                                            value={momTabEditData.mom_location}
                                                                                                            onChange={(v) => {
                                                                                                                changeValueInJsonEditSrs('mom_location', v.target.value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>

                                                                                                    <div className='momTabAddLickDocument'>
                                                                                                        <div>LINK DOCUMENT</div>
                                                                                                        <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                                                                                                            momTabEditData.mom_link = momTabEditData.mom_link || [];
                                                                                                            momTabEditData.mom_link?.push({
                                                                                                                name: '',
                                                                                                                link: ''
                                                                                                            });

                                                                                                            setTest({ ...test, test: '' });
                                                                                                            test.test = '';
                                                                                                        }}>
                                                                                                            <PlusOutlined style={{ fontSize: '12px' }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {momTabEditData.mom_link?.map((el, il) => {
                                                                                                        return <>
                                                                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                    <div className='momTabAddLinkIndex'>{il + 1}</div>
                                                                                                                    <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                                                                                                        momTabEditData.mom_link?.splice(il, 1);

                                                                                                                        setTest({ ...test, test: '' });
                                                                                                                        test.test = '';
                                                                                                                    }}>
                                                                                                                        <DeleteOutlined />
                                                                                                                    </div>
                                                                                                                </div>

                                                                                                                <div style={{ border: '2px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                                                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                        <div style={{ minWidth: '12.5%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.md_name?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ชื่อ :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTab'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={el.md_name}
                                                                                                                            onChange={(e) => handleLinkInputChangeEditLink('md_name', e.target.value, il)}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                        <div style={{ minWidth: '12.5%', textAlign: 'end' }}><span style={{ color: 'red', display: `${el.md_link?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>Link :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTab'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={el.md_link}
                                                                                                                            onChange={(e) => handleLinkInputChangeEditLink('md_link', e.target.value, il)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    })}
                                                                                                </div>

                                                                                                <div className='momTabAddBodyRight'>
                                                                                                    <div style={{ overflow: 'auto', width: '650px' }}>
                                                                                                        <DragDropContext onDragEnd={handleOnDragTab}>
                                                                                                            <Droppable droppableId="companies" direction="horizontal">
                                                                                                                {(provided) => (
                                                                                                                    <div
                                                                                                                        style={{ width: '100%', display: 'flex' }}
                                                                                                                        {...provided.droppableProps}
                                                                                                                        ref={provided.innerRef}
                                                                                                                    >
                                                                                                                        {momTabUserSelect?.filter((item, index, self) =>
                                                                                                                            index === self.findIndex(t => t.mp_company === item.mp_company)
                                                                                                                        )?.sort((a, b) => {
                                                                                                                            return (a.mmp_sort_company || 0) - (b.mmp_sort_company || 0)
                                                                                                                        }
                                                                                                                        )?.map((et, it) => (
                                                                                                                            <Draggable key={et.mp_company} draggableId={et.mp_company} index={it}>
                                                                                                                                {(provided) => (
                                                                                                                                    <div
                                                                                                                                        ref={provided.innerRef}
                                                                                                                                        {...provided.draggableProps}
                                                                                                                                        {...provided.dragHandleProps}
                                                                                                                                        style={{
                                                                                                                                            background: `${momTabCompanyTabSelectId == et.mp_company ? '#002B99' : 'linear-gradient(to Left, rgba(0,43,153,0.5) 0%, rgba(0,14,51,0.5) 100%)'}`,
                                                                                                                                            padding: '0 7.5px',
                                                                                                                                            display: 'flex',
                                                                                                                                            justifyContent: 'center',
                                                                                                                                            alignItems: 'center',
                                                                                                                                            gap: '5px',
                                                                                                                                            cursor: 'pointer',
                                                                                                                                            ...provided.draggableProps.style,
                                                                                                                                        }}
                                                                                                                                        onClick={() => {
                                                                                                                                            setMomTabCompanyTabSelectId(et.mp_company);
                                                                                                                                            setMomTabCompanyTabSelect(it);
                                                                                                                                            setTest({ ...test, test: '' });
                                                                                                                                            test.test = '';
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <div style={{ width: 'fit-content', padding: '5px 10px', whiteSpace: 'nowrap' }}>
                                                                                                                                            {et.mp_company}
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                )}
                                                                                                                            </Draggable>
                                                                                                                        ))}
                                                                                                                        {provided.placeholder}
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            </Droppable>
                                                                                                        </DragDropContext>
                                                                                                    </div>

                                                                                                    <div style={{ border: '2px solid #0047FF', padding: '10px', display: 'flex', alignItems: 'flex-start', gap: '10px', flexDirection: 'column', flexGrow: '1' }}>
                                                                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'space-between', width: '100%' }}>
                                                                                                            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                                                                                                <div>ผู้เข้าร่วมประชุม</div>
                                                                                                                <div style={{ border: '1px solid #0047FF', backgroundColor: '#0A2268', padding: '5px', cursor: 'pointer', position: 'sticky', right: 0, display: 'flex', alignItems: 'center' }} onClick={() => {
                                                                                                                    toggleMomTabAddUserIsOpen();
                                                                                                                }}>
                                                                                                                    <PlusOutlined style={{ fontSize: '12px' }} />
                                                                                                                </div>
                                                                                                                <Input
                                                                                                                    className='momTabAddSearch'
                                                                                                                    value={momTabUserSearch}
                                                                                                                    onChange={(e) => {
                                                                                                                        setMomTabUserSearch(e.target.value);
                                                                                                                    }}
                                                                                                                />
                                                                                                                 {momTabUserSelect?.filter((fil) => {
                                                                                                                    return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                }).length > 0 ?

                                                                                                                    <ColorPicker
                                                                                                                        className='momTabAddColor'
                                                                                                                        size="small"
                                                                                                                        defaultFormat="rgb"
                                                                                                                        format="rgb"
                                                                                                                        destroyTooltipOnHide
                                                                                                                        disabledAlpha
                                                                                                                        value={momTabUserSelect?.filter((fil) => {
                                                                                                                            return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                        })[0].mmp_color ?? 'rgb(0,0,0)'}
                                                                                                                        onChange={(c) => {
                                                                                                                            SetColorInCompany(c.toRgbString())
                                                                                                                        }}
                                                                                                                    /> : <>

                                                                                                                    </>}
                                                                                                            </div>

                                                                                                            <div>
                                                                                                                <UserOutlined /> {momTabUserSelect?.filter((fil) => {
                                                                                                                    return fil.mp_company == momTabCompanyTabSelectId
                                                                                                                })?.length || 0}
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div style={{ width: '100%' }}>
                                                                                                            {!momTabUserIsEdit ?
                                                                                                                <>
                                                                                                                    <DragDropContext onDragEnd={handleOnDragPersonal}>
                                                                                                                        <Droppable droppableId="momTabUserSelect">
                                                                                                                            {(provided) => (
                                                                                                                                <div {...provided.droppableProps} ref={provided.innerRef} style={{ display: 'flex', gap: '10px', width: '100%', flexDirection: 'column', position: 'relative' }}>
                                                                                                                                    {momTabUserSelect?.filter((fil) => {
                                                                                                                                        return momTabUserSearch !== ''
                                                                                                                                            ? fil.mp_name.toString().toLocaleLowerCase().includes(momTabUserSearch.toString().toLocaleLowerCase()) && fil.mp_company === momTabCompanyTabSelectId
                                                                                                                                            : fil.mp_company === momTabCompanyTabSelectId;
                                                                                                                                    })?.sort((a, b) => {
                                                                                                                                        return (a.mmp_sort_personal || 0) - (b.mmp_sort_personal || 0)
                                                                                                                                    }
                                                                                                                                    )?.map((e, i) => (
                                                                                                                                        <Draggable key={e.mp_id} draggableId={e.mp_id.toString()} index={i}>
                                                                                                                                            {(provided, snapshot) => {
                                                                                                                                                const isDragging = snapshot.isDragging;
                                                                                                                                                return (
                                                                                                                                                    <div
                                                                                                                                                        ref={provided.innerRef}
                                                                                                                                                        {...provided.draggableProps}
                                                                                                                                                        {...provided.dragHandleProps}
                                                                                                                                                        style={{
                                                                                                                                                            ...provided.draggableProps.style,
                                                                                                                                                            display: 'flex',
                                                                                                                                                            gap: '10px',
                                                                                                                                                            width: '100%',
                                                                                                                                                            flexDirection: 'row',
                                                                                                                                                            transition: 'none',
                                                                                                                                                            boxShadow: isDragging ? '0 2px 10px rgba(0, 0, 0, 0.2)' : 'none',
                                                                                                                                                            ...provided.draggableProps.style,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                                                            <div className='momTabAddLinkIndex'>{i + 1}</div>
                                                                                                                                                            <div style={{ border: '1px solid #E4DA15', backgroundColor: '#9D9602', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                                                                                                                                                setMomTabUserEditData(e);
                                                                                                                                                                toggleMomTabUserIsEdit();
                                                                                                                                                                setTest({ ...test, test: '' });
                                                                                                                                                                test.test = '';
                                                                                                                                                            }} >
                                                                                                                                                                <EditOutlined />
                                                                                                                                                            </div>
                                                                                                                                                            <div style={{ border: '1px solid #FF0000', backgroundColor: '#920000', padding: '2.5px 5px', cursor: 'pointer' }} onClick={() => {
                                                                                                                                                                let findIndexUser = momTabUserSelect.findIndex((find) => find.mp_id == e.mp_id);
                                                                                                                                                                if (findIndexUser > -1) {
                                                                                                                                                                    momTabUserSelect.splice(findIndexUser, 1);
                                                                                                                                                                }
                                                                                                                                                                let check = momTabUserSelect?.filter((fil) => fil.mp_company === momTabCompanyTabSelectId);

                                                                                                                                                                if (check?.length === 0) {
                                                                                                                                                                    let findIndex = momTabEditData.mom_tab?.findIndex((find) => find.mp_company === e.mp_company);
                                                                                                                                                                    if (findIndex > -1) {
                                                                                                                                                                        momTabEditData.mom_tab.splice(findIndex, 1);
                                                                                                                                                                    }
                                                                                                                                                                    setMomTabCompanyTabSelect(null);
                                                                                                                                                                    setMomTabCompanyTabSelectId('');
                                                                                                                                                                }
                                                                                                                                                                setTest({ ...test, test: '' });
                                                                                                                                                                test.test = '';
                                                                                                                                                            }}>
                                                                                                                                                                <DeleteOutlined />
                                                                                                                                                            </div>
                                                                                                                                                        </div>

                                                                                                                                                        <div style={{ border: '1px solid #0047FF', padding: '10px 10px', width: '100%', display: 'flex', flexDirection: 'column', gap: '15px', borderRadius: '10px' }}>
                                                                                                                                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                                                                                                                                <div style={{ minWidth: '20%', height: '100%', background: 'rgba(76, 76, 76, 0.1)', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '10px' }}>
                                                                                                                                                                    <div style={{ width: '50%', height: '50%', border: '1px solid #0047FF', borderRadius: '50%', backgroundImage: `url(${momTabUserImg})`, backgroundPosition: 'center', backgroundSize: 'cover' }}></div>
                                                                                                                                                                    <div style={{ fontSize: '12px' }}>{e.mom_code || '-'}</div>
                                                                                                                                                                </div>
                                                                                                                                                                <div>
                                                                                                                                                                    <div style={{ color: '#0094FF' }}>{e.mp_name || '-'}</div>
                                                                                                                                                                    <div>{e.mp_position || '-'}</div>
                                                                                                                                                                    <div>{e.mp_email || '-'}</div>
                                                                                                                                                                    <div>{e.mp_tel || '-'}</div>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                                                                                                                                );
                                                                                                                                            }}
                                                                                                                                        </Draggable>
                                                                                                                                    ))}
                                                                                                                                    {provided.placeholder}
                                                                                                                                </div>
                                                                                                                            )}
                                                                                                                        </Droppable>
                                                                                                                    </DragDropContext>

                                                                                                                </> : <div style={{ display: 'flex', flexDirection: 'column', width: '100%', gap: '10px' }}>
                                                                                                                    <div style={{ border: '1px solid #0047FF', padding: '10px', display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                                                                        <div>ชื่อ-นามสกุล :</div>
                                                                                                                        <div style={{ color: '#0094FF' }}>{momTabUserEditData.mp_name}</div>

                                                                                                                        <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_position?.trim()?.length > 0 ? 'none' : ''}` }}>*</span>ตำแหน่ง :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_position}
                                                                                                                            onChange={(e) => {
                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_position: e.target.value });
                                                                                                                            }}
                                                                                                                        />

                                                                                                                        <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null ? 'none' : ''}` }}>*</span>Email :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_email}
                                                                                                                            onChange={(e) => {
                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_email: e.target.value });
                                                                                                                            }}
                                                                                                                        />

                                                                                                                        <div><span style={{ color: 'red', display: `${momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null ? 'none' : ''}` }}>*</span>เบอร์โทร :</div>
                                                                                                                        <TextArea
                                                                                                                            className='textAreaMomTabAddUser'
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            value={momTabUserEditData.mp_tel}
                                                                                                                            onChange={(e) => {
                                                                                                                                let value = e.target.value.replace(/\D/g, '');

                                                                                                                                if (value.length > 3) {
                                                                                                                                    value = `${value.slice(0, 3)}-${value.slice(3)}`;
                                                                                                                                }

                                                                                                                                setMomTabUserEditData({ ...momTabUserEditData, mp_tel: value });
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                                                                                                        <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                                                                                            if (momTabUserEditData.mp_position?.trim()?.length > 0 &&
                                                                                                                                (momTabUserEditData.mp_email?.trim()?.length < 1 || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(momTabUserEditData.mp_email?.trim()) || momTabUserEditData.mp_email == null) &&
                                                                                                                                (momTabUserEditData.mp_tel?.trim()?.length > 9 || momTabUserEditData.mp_tel?.trim()?.length < 1 || momTabUserEditData.mp_tel == null)
                                                                                                                            ) {
                                                                                                                                let findIndex = momTabUserSelect.findIndex((find) => find.mp_id == momTabUserEditData.mp_id);
                                                                                                                                if (findIndex > -1) {
                                                                                                                                    momTabUserSelect[findIndex] = momTabUserEditData;
                                                                                                                                };
                                                                                                                                toggleMomTabUserIsEdit();
                                                                                                                            } else {
                                                                                                                                toggle_modal_alertKickMassge();
                                                                                                                            }
                                                                                                                        }}>SAVE</div>
                                                                                                                        <div className='momTabAddUserCloseButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                                                                                            toggleMomTabUserIsEdit();
                                                                                                                        }}>CANCEL</div>
                                                                                                                    </div>
                                                                                                                </div>}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}

                                                                                {/* {e3.value_type == 'table' && (<>
                                                                                            {e3.is_border == true ? <table >
                                                                                                <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR HEADER...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input_header}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                </td>
                                                                                                <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            //console.log(v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />


                                                                                                </td>
                                                                                                {userismode === false ? (
                                                                                                    <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                        className='btnRed ml-2'
                                                                                                        onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                    ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                            </table> :

                                                                                                <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>

                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px', width: '30%' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR HEADER...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input_header}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            console.log(v.target.value);
                                                                                                            changeValueInJson('value_input_header', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />



                                                                                                    <TextArea className="areaTEst rezieznone"
                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                        autoSize={{
                                                                                                            minRows: 1
                                                                                                        }}
                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                        type='text'
                                                                                                        value={e4.value_input}
                                                                                                        disabled={userismode}
                                                                                                        onChange={(v) => {
                                                                                                            //console.log(v.target.value);
                                                                                                            changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                        }}
                                                                                                    />
                                                                                                    {userismode === false ? (
                                                                                                        <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                            className='btnRed'
                                                                                                            onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                        ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                </div>
                                                                                            }


                                                                                        </>)} */}
                                                                                {/* {e3.value_type == 'description' && (<>


                                                                                            <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                    value={e4.value_input}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        //console.log("test", v.target.value);
                                                                                                        changeValueInJson('value_input', v.target.value, index, index2, index3, index4);
                                                                                                    }}
                                                                                                />
                                                                                                {userismode === false ? (
                                                                                                    <p style={{ fontSize: '16px', cursor: 'pointer' }}
                                                                                                        className='btnRed'
                                                                                                        onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                    ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                            </div>
                                                                                        </>)} */}



                                                                                {/* {e3.value_type === 'table_column' && (
                                                                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
                                                                                        {e3.data_list && e3.data_list.map((dataItem, index4) => (
                                                                                            <div key={index4} style={{ display: 'flex', flexDirection: 'column', gap: '1rem', border: '2px solid #0349FF', padding: '25px 25px 25px 25px' }}>
                                                                                                <>
                                                                                                    <div className='row'>
                                                                                                        <div style={{ width: '520px' }}>
                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>ครั้งที่ประชุม <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <div style={{ display: 'flex', gap: '5%' }}>
                                                                                                                    <TextArea
                                                                                                                        className="areaTEst rezieznone"
                                                                                                                        style={{ borderRadius: '0px', width: '240px' }}
                                                                                                                        autoSize={{ minRows: 1 }}
                                                                                                                        type='text'
                                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                        value={dataItem.value_input_table && dataItem.value_input_table[0]
                                                                                                                            ? dataItem.value_input_table[0]
                                                                                                                            : ''
                                                                                                                        }
                                                                                                                        disabled={userismode}
                                                                                                                        onChange={(v) => {
                                                                                                                            changeValueInJson('value_input_table', v.target.value, index, index2, index3, index4, 0);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    {userismode === false ? (
                                                                                                                        <>

                                                                                                                            <button style={{ cursor: "pointer", border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                                                                                                                className='btnRed'
                                                                                                                                onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                                            ><i class="fa fa-trash" style={{ paddingBottom: 4 }} aria-hidden="true"></i>
                                                                                                                            </button>
                                                                                                                        </>
                                                                                                                    ) : <></>}
                                                                                                                </div>
                                                                                                            </div>

                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>สถานที่ <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <TextArea
                                                                                                                    className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px', width: '435px' }}
                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={dataItem.value_input_table && dataItem.value_input_table[3]
                                                                                                                        ? dataItem.value_input_table[3]
                                                                                                                        : ''
                                                                                                                    }
                                                                                                                    disabled={userismode}
                                                                                                                    onChange={(v) => {
                                                                                                                        changeValueInJson('value_input_table', v.target.value, index, index2, index3, index4, 3);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>

                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>หัวข้อการประชุม <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <TextArea
                                                                                                                    className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px', width: '380px', marginRight: '10px' }}
                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={dataItem.value_input_table && dataItem.value_input_table[1]
                                                                                                                        ? dataItem.value_input_table[1]
                                                                                                                        : ''
                                                                                                                    }
                                                                                                                    disabled={userismode}
                                                                                                                    onChange={(v) => {
                                                                                                                        changeValueInJson('value_input_table', v.target.value, index, index2, index3, index4, 1);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>

                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                <p style={{ paddingRight: '6px' }}>เวลา <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> : </p>
                                                                                                                <RangePicker
                                                                                                                    showTime
                                                                                                                    format={"DD/MM/YYYY HH:mm:ss"}
                                                                                                                    className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px', width: '450px' }}
                                                                                                                    // placeholder='DD/MM/YYYY HH:MM:ss'
                                                                                                                    suffixIcon={<div style={{ marginRight: '10px' }}><CalendarOutlined /></div>}
                                                                                                                    disabled={userismode}
                                                                                                                    defaultValue={
                                                                                                                        dataItem.value_input_table && dataItem.value_input_table[4]
                                                                                                                            ? dataItem.value_input_table[4].split(' - ').map(date => dayjs(date, "DD/MM/YYYY HH:mm:ss"))
                                                                                                                            : [null, null]
                                                                                                                    }
                                                                                                                    onChange={(_, v) => {
                                                                                                                        // console.log("test : >>>", v);
                                                                                                                        let date = v[0] + " - " + v[1]
                                                                                                                        // console.log("test__ : >>>", date);
                                                                                                                        changeValueInJson(`value_input_table`, date, index, index2, index3, index4, 4);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </div>

                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <div className='row' style={{ width: '700px' }}>
                                                                                                                <p>ผู้เข้าร่วมประชุม </p>
                                                                                                                {!userismode ? <>

                                                                                                                    <button
                                                                                                                        className="btnAddSpecific ml-2"
                                                                                                                        style={{ border: '2px solid #0349FF', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}
                                                                                                                        onClick={() => addParticipant(index, index2, index3, index4)}
                                                                                                                    >
                                                                                                                        <i class="fa fa-plus" aria-hidden="true"></i>
                                                                                                                    </button>
                                                                                                                </> : null}

                                                                                                                <div style={{ flexGrow: 1 }}></div>
                                                                                                                <div className='row'>
                                                                                                                    <div style={{ width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0047FF', borderRadius: '15px', marginRight: '10px' }}>
                                                                                                                        <i class="fa fa-user" aria-hidden="true" style={{ color: 'black' }}></i>
                                                                                                                    </div>
                                                                                                                    <p>{dataItem?.value_participants?.length}</p>
                                                                                                                </div>

                                                                                                            </div>
                                                                                                            <div style={{ maxHeight: '320px', overflowY: 'auto' }}>

                                                                                                                {dataItem?.value_participants?.map((obj, index5) => (
                                                                                                                    <div className='row' style={{ display: 'flex', gap: '2%' }}>
                                                                                                                        <div style={{ padding: '10px 0px 0px 10px' }}>
                                                                                                                            <p style={{ border: '2px solid #0349FF', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}>
                                                                                                                                {index5 + 1}
                                                                                                                            </p>
                                                                                                                            <br />
                                                                                                                            {!userismode ? <>
                                                                                                                                <button style={{ cursor: "pointer", border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                                                                                                                    className='btnRed'
                                                                                                                                    onClick={() => { removeParticipant(index, index2, index3, index4, index5); }}
                                                                                                                                ><i class="fa fa-trash" style={{ paddingBottom: 4 }} aria-hidden="true"></i>
                                                                                                                                </button>
                                                                                                                            </> : null}
                                                                                                                        </div>
                                                                                                                        <div key={index5} style={{ border: '2px solid #0349FF', padding: '25px', marginTop: '10px' }}>
                                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                                <p style={{ paddingRight: '6px' }}>ชื่อ - นามสกุล <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> :</p>
                                                                                                                                <TextArea
                                                                                                                                    className="areaTEst rezieznone"
                                                                                                                                    style={{ borderRadius: '0px', width: '360px' }}
                                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                                    type='text'
                                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                                    value={obj.name ?? ''}
                                                                                                                                    disabled={userismode}
                                                                                                                                    onChange={(e) => changeParticipantValueInJson('name', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                                />
                                                                                                                            </div>

                                                                                                                            <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                                <p style={{ padding: '0px 6px 0px 36px' }}>ตำแหน่ง <span style={{ display: !userismode ? '' : 'none', color: 'red' }}>*</span> :</p>
                                                                                                                                <TextArea
                                                                                                                                    className="areaTEst rezieznone"
                                                                                                                                    style={{ borderRadius: '0px', width: '360px' }}
                                                                                                                                    autoSize={{ minRows: 1 }}
                                                                                                                                    type='text'
                                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                                    value={obj.position}
                                                                                                                                    disabled={userismode}
                                                                                                                                    onChange={(e) => changeParticipantValueInJson('position', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                                />
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                ))}
                                                                                                            </div>

                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div>
                                                                                                        <div className='row' style={{ width: '700px' }}>
                                                                                                            <p>LINK DOCUMENT </p>
                                                                                                            {!userismode ? <>
                                                                                                                <button
                                                                                                                    className="btnAddSpecific ml-2"
                                                                                                                    style={{ border: '2px solid #0349FF', width: '30px', height: '30px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}
                                                                                                                    onClick={() => addLinkDoc(index, index2, index3, index4)}
                                                                                                                >
                                                                                                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                                                                                                </button>
                                                                                                            </> : null}

                                                                                                        </div>

                                                                                                        {dataItem?.value_link?.map((linkDoc, index5) => (
                                                                                                            <div className='row' style={{ display: 'flex', gap: '2%' }}>
                                                                                                                <div style={{ padding: '10px 0px 0px 10px' }}>
                                                                                                                    <p style={{ border: '2px solid #0349FF', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#0A2268' }}>
                                                                                                                        {index5 + 1}
                                                                                                                    </p>
                                                                                                                    <br />

                                                                                                                    {!userismode ? <>
                                                                                                                        <button style={{ border: '2px solid #FF0000', width: '34px', height: '34px', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0', backgroundColor: '#920000' }}
                                                                                                                            className='btnRed'
                                                                                                                            onClick={() => { removeLinkDoc(index, index2, index3, index4, index5); }}
                                                                                                                        ><i class="fa fa-trash" aria-hidden="true" style={{ paddingBottom: 4 }}></i>
                                                                                                                        </button>
                                                                                                                    </> : null}

                                                                                                                </div>
                                                                                                                <div key={index5} style={{ border: '2px solid #0349FF', padding: '25px', marginTop: '10px' }}>
                                                                                                                    <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                        <p style={{ paddingRight: '6px' }}>name :</p>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px', width: '1000px' }}
                                                                                                                            autoSize={{ minRows: 1 }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={linkDoc.name}
                                                                                                                            disabled={userismode}
                                                                                                                            onChange={(e) => handleLinkInputChange('name', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                        />
                                                                                                                    </div>

                                                                                                                    <div className='row' style={{ marginBottom: '1%' }}>
                                                                                                                        <p style={{ padding: '0px 6px 0px 20px' }}>link :</p>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px', width: '1000px' }}
                                                                                                                            autoSize={{ minRows: 1 }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={linkDoc.link}
                                                                                                                            disabled={userismode}
                                                                                                                            onChange={(e) => handleLinkInputChange('link', e.target.value, index, index2, index3, index4, index5)}
                                                                                                                        />
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        ))}

                                                                                                    </div>
                                                                                                </>

                                                                                            </div>
                                                                                        ))}
                                                                                    </div>
                                                                                )} */}

                                                                                {/* <table style={{ width: '100%' }}>
                                                                                            <tr>
                                                                                                {e3.table_column_list.map((e4) => {
                                                                                                    return (<>
                                                                                                        <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                    </>)
                                                                                                })}
                                                                                            </tr>

                                                                                            {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                return (<>
                                                                                                    <tr>
                                                                                                        {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                            return (<>
                                                                                                                <td style={{ border: '1px solid #ddd', padding: '1%', alignContent: 'start' }}>
                                                                                                                    {index5 == 4 ? (<>
                                                                                                                    <DatePicker
                                                                                                                        showTime
                                                                                                                        format={"DD/MM/YYYY HH:mm:ss"}
                                                                                                                        className="areaTEst rezieznone"
                                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                                        // autoSize={{
                                                                                                                        //     minRows: 1
                                                                                                                        // }}
                                                                                                                        // type='date'
                                                                                                                        // value={e5}
                                                                                                                        placeholder='DD/MM/YYYY'
                                                                                                                        disabled={userismode}
                                                                                                                        onChange={(_, v) => {
                                                                                                                            console.log("test : >>>", v);
                                                                                                                            changeValueInJson(`value_input_table`, v, index, index2, index3, index4, index5);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    </>) :
                                                                                                                    <TextArea
                                                                                                                        className="areaTEst rezieznone"
                                                                                                                        style={{ borderRadius: '0px' }}
                                                                                                                        autoSize={{
                                                                                                                            minRows: 1
                                                                                                                        }}
                                                                                                                        type='text'
                                                                                                                        placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                        value={e5}
                                                                                                                        disabled={userismode}
                                                                                                                        onChange={(v) => {
                                                                                                                            //console.log("test", v.target.value);
                                                                                                                            changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                        }}
                                                                                                                    />
                                                                                                                    } 
                                                                                                                </td>
                                                                                                            </>)
                                                                                                        })}
                                                                                                        <td>
                                                                                                            {userismode === false ? (
                                                                                                                <p style={{ fontSize: '16px', cursor: 'pointer', width: '40px' }}
                                                                                                                    className='btnRed'
                                                                                                                    onClick={() => { removeValueMultiple(index, index2, index3, index4); }}
                                                                                                                ><i class="fa fa-trash" aria-hidden="true"></i></p>) : <></>}
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </>)

                                                                                            })}

                                                                                        </table> */}

                                                                                {/* </div>
                                                                                </>)} */}

                                                                            </>) : (<>

                                                                                {e3.value_type == 'table' && (<>

                                                                                    {e3.is_border == true ?
                                                                                        <table >
                                                                                            <td style={{ border: '1px solid #ddd', width: '30%', padding: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px', width: '100%' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR HEADER...'
                                                                                                    value={e3.value_input_header}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                            </td>
                                                                                            <td style={{ border: '1px solid #ddd', width: '100%', padding: '1%' }}>
                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                    autoSize={{
                                                                                                        minRows: 1
                                                                                                    }}
                                                                                                    type='text'
                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                    value={e3.value_input}
                                                                                                    disabled={userismode}
                                                                                                    onChange={(v) => {
                                                                                                        console.log(v.target.value);
                                                                                                        changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                    }}

                                                                                                />
                                                                                            </td>

                                                                                        </table> : <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                            <TextArea className="areaTEst rezieznone"
                                                                                                style={{ borderRadius: '0px', width: '30%' }}
                                                                                                autoSize={{
                                                                                                    minRows: 1
                                                                                                }}
                                                                                                type='text'
                                                                                                placeholder='INPUT YOUR HEADER...'
                                                                                                value={e3.value_input_header}
                                                                                                disabled={userismode}
                                                                                                onChange={(v) => {
                                                                                                    console.log(v.target.value);
                                                                                                    changeValueInJson('value_input_header', v.target.value, index, index2, index3);
                                                                                                }}

                                                                                            />
                                                                                            <TextArea className="areaTEst rezieznone"
                                                                                                style={{ borderRadius: '0px' }}
                                                                                                autoSize={{
                                                                                                    minRows: 1
                                                                                                }}
                                                                                                type='text'
                                                                                                placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                value={e3.value_input}
                                                                                                disabled={userismode}
                                                                                                onChange={(v) => {
                                                                                                    console.log(v.target.value);
                                                                                                    changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                                }}

                                                                                            />
                                                                                        </div>}

                                                                                </>)}

                                                                                {e3.value_type == 'description' && (<>
                                                                                    <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                        <TextArea className="areaTEst rezieznone"
                                                                                            style={{ borderRadius: '0px' }}
                                                                                            autoSize={{
                                                                                                minRows: 1
                                                                                            }}
                                                                                            type='text'
                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                            value={e3.value_input}
                                                                                            disabled={userismode}
                                                                                            onChange={(v) => {
                                                                                                console.log(v.target.value);
                                                                                                changeValueInJson('value_input', v.target.value, index, index2, index3);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                </>)}

                                                                                {e3.value_type == 'table_column' && (<>
                                                                                    <div style={{ display: 'flex', gap: '1%', marginBottom: '1%' }}>
                                                                                        {e3.value_type == 'table_column' && (<>

                                                                                            <table style={{ width: '100%' }}>
                                                                                                <tr>
                                                                                                    {e3.table_column_list.map((e4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', fontSize: '18px' }}>{e4.header}</td>
                                                                                                        </>)
                                                                                                    })}
                                                                                                </tr>

                                                                                                {e3.data_list && e3.data_list.map((e4, index4) => {
                                                                                                    return (<>
                                                                                                        <tr>
                                                                                                            {e4.value_input_table && e4.value_input_table.map((e5, index5) => {
                                                                                                                return (<>
                                                                                                                    <td style={{ border: '1px solid #ddd', padding: '1%', alignContent: 'start' }}>
                                                                                                                        <TextArea
                                                                                                                            className="areaTEst rezieznone"
                                                                                                                            style={{ borderRadius: '0px' }}
                                                                                                                            autoSize={{
                                                                                                                                minRows: 1
                                                                                                                            }}
                                                                                                                            type='text'
                                                                                                                            placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                            value={e5}
                                                                                                                            disabled={userismode}
                                                                                                                            onChange={(v) => {
                                                                                                                                //console.log("test", v.target.value);
                                                                                                                                changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4, index5);
                                                                                                                            }}
                                                                                                                        />
                                                                                                                    </td>
                                                                                                                </>)
                                                                                                            })}

                                                                                                        </tr>
                                                                                                    </>)

                                                                                                })}
                                                                                                {/* {e3.data_list.map((e4, index4) => {
                                                                                                        return (<>
                                                                                                            <td style={{ border: '1px solid #ddd', textAlign: 'center', padding: '1%' }}>
                                                                                                                <TextArea className="areaTEst rezieznone"
                                                                                                                    style={{ borderRadius: '0px' }}
                                                                                                                    autoSize={{
                                                                                                                        minRows: 1
                                                                                                                    }}
                                                                                                                    type='text'
                                                                                                                    placeholder='INPUT YOUR DESCRIPTION...'
                                                                                                                    value={e4}

                                                                                                                    onChange={(v) => {
                                                                                                                        //console.log("test", v.target.value);
                                                                                                                        changeValueInJson(`value_input_table`, v.target.value, index, index2, index3, index4);
                                                                                                                    }}
                                                                                                                />
                                                                                                            </td>
                                                                                                        </>)
                                                                                                    })} */}

                                                                                            </table>


                                                                                        </>)}

                                                                                    </div>
                                                                                </>)}

                                                                            </>)}

                                                                            {/* {!userismode ? e3.is_multiple && (<button
                                                                                className="btnAddSpecific" style={{ height: '32px', paddingTop: '2px', marginBottom: '1.6rem', }}
                                                                                onClick={() => { addValueMultiple(index, index2, index3, e3.value_type); }}
                                                                            ><i class="fa fa-plus" aria-hidden="true"></i> ADD
                                                                            </button>)
                                                                                : null} */}

                                                                        </div >
                                                                    </>)
                                                                })}

                                                            </div >

                                                        </>)
                                                    })}
                                                </div></div >
                                            <hr />
                                        </>);
                                    })}

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div >
            <TabMomAttendance
                isOpen={momTabAddUserIsOpen}
                toggle={toggleMomTabAddUserIsOpen}
                setMomTabAddUserMode={setMomTabAddUserMode}
                momTabAddUserMode={momTabAddUserMode}
                setMomTabAddUserModeOld={setMomTabAddUserModeOld}
                momTabAddUserModeOld={momTabAddUserModeOld}
                momTabUserSelect={momTabUserSelect}
                setMomTabUserSelect={setMomTabUserSelect}
                test={test}
                setTest={setTest}
                mom_tab={momTabAddData.mom_tab}
                mom_tab_edit={momTabEditData.mom_group}
                momTabCompanyTabSelectId={momTabCompanyTabSelectId}
                project_id={project_id}
                momTabMode={momTabMode}
            />
            <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageError}
            />
            <ModelAlertConfrim
                isOpen={alertKickConfirm}
                toggle_alertKick={toggle_modal_alertKickConfirm}
                mainFuc={() => delMomDataById(alertKickConfirmId)}
                message={alertKickConfirmMessage}
            />

            <div className='alertKick' style={{ display: `${alertKickSuccess ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' style={{ height: 'fit-content' }}>
                        <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: `#339900` }}>
                                    SUCCESS
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    Current version saved
                                </div>
                            </div>
                            <div className='row alertKickBoxButton' >
                                <button className='alertKickButton' onClick={() => {
                                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL SUCCESS' });
                                    toggle_modal_alertKickSuccess()
                                }}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='alertKick' style={{ display: `${alertKickNot ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' style={{ height: 'fit-content' }}>
                        <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: `orange` }}>
                                    !WARNING!
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    Data not found!!
                                </div>
                            </div>
                            <div className='row alertKickBoxButton' >
                                <button className='alertKickConfirm mr-2' onClick={() => {
                                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'GENERATEALL SUCCESS' });
                                    toggle_modal_alertKickNot()
                                }}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading && (
                <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: 'orange' }}>
                                        Loading
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning' style={{ border: 'none' }}>
                                        <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default TabCompanentSRS;
