
import { Document, Packer, Paragraph, TextRun, Header, Footer, AlignmentType,ImageRun,HeadingLevel,PageNumber,TableOfContents,StyleLevel, Table, TableCell, TableRow ,BorderStyle,WidthType, VerticalAlign, TextDirection, ShadingType,ExternalHyperlink } from 'docx';
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import { saveAs } from 'file-saver';
import moment from "moment";
import axios from "axios";
import clipPaperIcon from './img/clip paper.png';

async function momdocx(data,callback)  {

  console.log(data);
  let img = {},
  colorbg = 'C2C2C2';
  let colordrop = [
    {
      before: "#676767",
      after: "#DEDEDE",
    },
    {
      before: "#FF00E5",
      after: "#FBDCFF",
    },
    {
      before: "#BA84FF",
      after: "#CCD9FA",
    },
    {
      before: "#00A5FF",
      after: "#CDEEFF",
    },
    {
      before: "#259B24",
      after: "#D3FFCF",
    },
    {
      before: "#FFEB3B",
      after: "#FFFDCF",
    },
    {
      before: "#FF8000",
      after: "#FFE0C1",
    },
    {
      before: "#FF0000",
      after: "#FFD9D9",
    },
  ];
  if (data.project.federation_color !== null) {
    let selectcolor = colordrop.findIndex(
      (e) =>
        e.after == data.project.federation_color ||
        e.before == data.project.federation_color
    );
    // console.log(selectcolor);
    if (selectcolor !== -1) {
      let hex = colordrop[selectcolor].after.replace(/^#/, "");
      colorbg = hex
    }
  }
  if (data.project_image.length > 0) {


    let getpath = await axios.get(`https://api.box.com/2.0/files/${data.project_image[0].fd_img_id}/content`, {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
      },
      responseType: 'blob',
    });

    img["ul_file_path"] = URL.createObjectURL(getpath.data);


    // img = data.project_image[0].fd_img_id;
  }

  async function fetchImageAsBase64(url) {
    try {
      
        const imageUrl = url;

        const response = await fetch(imageUrl);
        const imageBlob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result.split(',')[1]);
            reader.onerror = reject;
            reader.readAsDataURL(imageBlob);
        });
    } catch (error) {
        console.error('Error fetching or converting image:', error);
        return null; // หรือจัดการกับข้อผิดพลาดตามที่ต้องการ
    }
}

async function setboximg(url) {
   const response2 = await axios.get(`https://api.box.com/2.0/files/${url}/content`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
    },
    responseType: 'blob',


  });

  
  const img_url = URL.createObjectURL(response2.data);
let sizeImgS = await compressImageWH(img_url);
let img64 = await fetchImageAsBase64(img_url)
console.log(img64,sizeImgS);
let maxWidthImg = 500;
let maxHeightImg = 350;
let img_final_width = sizeImgS.width, img_final_height = sizeImgS.height, retion_scale = 0;
if (img_final_width > maxWidthImg) {
  let diff = img_final_width - maxWidthImg;
  retion_scale = (diff * 100) / img_final_width;
  img_final_width = maxWidthImg;
  img_final_height = img_final_height - ((img_final_height * retion_scale) / 100)
}
if (img_final_height > maxHeightImg) {
  let diff = img_final_height - maxHeightImg;
  retion_scale = (diff * 100) / img_final_height;
  img_final_height = maxHeightImg;
  img_final_width = img_final_width - ((img_final_width * retion_scale) / 100)
}
return new Paragraph({
  alignment: AlignmentType.CENTER,
  style: "none",
  spacing: {
    before: 600,  
    after: 300,  
  },
  children: [
    new ImageRun({
      data: img64,
      transformation: {
        width: img_final_width,
        height: img_final_height,
      },
     
    }) 
  ],
  
})

}


const compressImageWH = async (imageUrl, maxWidth = 1920, maxHeight = 1080) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve({ width: img.width, height: img.height });
    };
    img.onerror = function () {
      reject('Failed to load image.');
    };
    img.src = imageUrl;
  });
};






const imageBase64 = await fetchImageAsBase64(img.ul_file_path !== undefined ? img.ul_file_path : noimg);
    const pageWidth =  800;
   
    const header = new Header({
        children: [
            new Paragraph({
                children: [
                    new ImageRun({
                        data: imageBase64,
                        transformation: {
                            width: pageWidth,
                            height: 102.7,
                        },
                    }),
                    
                ],
                alignment: AlignmentType.CENTER,
                indent: {
                    left: -700,
                   
                },
        
            }),
        ],
    });


    const footer = new Footer({
        children: [
            new Paragraph({
              alignment: AlignmentType.END,
                children: [
                    new TextRun({
                        children: [
                          `All Rights Reserved, Copyright by TTT Brother Co., Ltd | `,
                          PageNumber.CURRENT
                        ],
                        font: "TH Sarabun",
                    }),
                    
                ],
                margins: {
                  top: 0, 
                  bottom: 0,
                  left: 100,
                  right: 0,
              }
            }),
            new Paragraph({
              alignment: AlignmentType.END,
                children: [
                    new TextRun({
                        children: [
                          `Template Version 1.0`
                        ],
                        font: "TH Sarabun",
                    }),
                    
                ],
                margins: {
                  top: 0, 
                  bottom: 100,
                  left: 100,
                  right: 0,
              }
            }),
        ],
    });

    const setusermeeting = data?.mom_person.map((i) => 
      new Table({
        rows: [
            new TableRow({
                children: [
                    new TableCell({
                      columnSpan: 2,
                        children: [
                            new Paragraph({
                          
                                alignment: AlignmentType.START,
                                style: "none",
                                children: [
                                  new TextRun({
                                      text: "ตัวแทนบริษัท",
                                      bold: true, 
                                      size: setfontsize(16),
                                  }),
                                  new TextRun({
                                    text: " ", 
                                }),
                                  new TextRun({
                                    text: i.mpg_name,
                                    bold: false, 
                                    size: setfontsize(16),
                                }),
                              ],
                            }),
                        ],
                      
                      margins: {
                        top: 100, 
                        bottom: 0,
                        left: 100,
                        right: 100,
                    }
                       
                    }),
                ],
            }),
            new TableRow({
              children: [
                  new TableCell({
                    columnSpan: 2,
                      children: [
                          new Paragraph({
                        
                              alignment: AlignmentType.START,
                              style: "none",
                              children: [
                                new TextRun({
                                    text: "ผู้เข้าร่วมประชุม",
                                    bold: true, 
                                    size: setfontsize(16),
                                }),
                            ],
                          }),
                      ],
                 
                    margins: {
                      top: 100, 
                      bottom: 0,
                      left: 100,
                      right: 100,
                  }
                     
                  }),
              ],
          }),

          new TableRow({
            children: [
                new TableCell({
                  width: {
                    size: setwinth(50), 
                    type: WidthType.DXA,
                },
                    children: [
                        new Paragraph({
                      
                            alignment: AlignmentType.CENTER,
                            style: "none",
                            children: [
                              new TextRun({
                                  text: "ชื่อ",
                                  bold: true, 
                                  size: setfontsize(16),
                              }),
                          ],
                        }),
                    ],
               
                  margins: {
                    top: 100, 
                    bottom: 0,
                    left: 100,
                    right: 100,
                }
                   
                }),

                new TableCell({
                  width: {
                    size: setwinth(50), 
                    type: WidthType.DXA,
                },
                    children: [
                        new Paragraph({
                      
                            alignment: AlignmentType.CENTER,
                            style: "none",
                            children: [
                              new TextRun({
                                  text: "ตำแหน่ง",
                                  bold: true, 
                                  size: setfontsize(16),
                              }),
                          ],
                        }),
                    ],
               
                  margins: {
                    top: 100, 
                    bottom: 0,
                    left: 100,
                    right: 100,
                }
                   
                }),
            ],
        }),
        new TableRow({
          children: [
              new TableCell({
                width: {
                  size: setwinth(50), 
                  type: WidthType.DXA,
              },
                  children: [
                    ...i.mom_person_list.map((x,o) =>
                      new Paragraph({
                    
                        alignment: AlignmentType.START,
                        style: "none",
                        children: [
                          new TextRun({
                            text: `${o+1}.`,
                            
                            size: setfontsize(16),
                        }),
                          new TextRun({
                              text: x.mp_name,
                             
                              size: setfontsize(16),
                          }),
                      ],
                    }),
                    )
                      
                  ],
             
                margins: {
                  top: 50, 
                  bottom: 0,
                  left: 50,
                  right: 50,
              }
                 
              }),

              new TableCell({
                width: {
                  size: setwinth(50), 
                  type: WidthType.DXA,
              },
                  children: [
                    ...i.mom_person_list.map((x,o) =>
                      new Paragraph({
                    
                        alignment: AlignmentType.START,
                        style: "none",
                        children: [
                          new TextRun({
                              text: x.mp_position,
                  
                              size: setfontsize(16),
                          }),
                      ],
                    }),
                    )
                     
                  ],
             
                margins: {
                  top: 50, 
                  bottom: 0,
                  left: 50,
                  right: 50,
              }
                 
              }),
          ],
      }),
        
      
        ],
        width: {
            size: (1440 * 8.27)-(720*2), 
            type: WidthType.DXA,
        },
        borders: {
                   
          bottom: { style: BorderStyle.SINGLE, size: 3, color: "FFFFFF" },
         
        },
       
      }),
  );

  const clip = await fetchImageAsBase64(clipPaperIcon);
  async function createContentList() {
    const contentList = [];
  
    if (data?.mom_srs?.none_follow_up) {
      for (const i of data.mom_srs.none_follow_up) {
        contentList.push(
          new Paragraph({
            alignment: AlignmentType.START,
            style: "none",
            children: [
              new TextRun({
                text: `โมดูล ${i.module_name}`,
                bold: true,
                size: setfontsize(16),
              }),
            ],
          })
        );

        for (const detail of i.menu_data) {

          contentList.push(
            new Paragraph({
              alignment: AlignmentType.START,
              style: "none",
              children: [
                new TextRun({
                  text: `     เมนู ${detail.menu_name}`,
                  size: setfontsize(16),
                  bold: true,
                }),
              ],
            })
          );
  

          for (const srs of detail.srs_data) {
       
            contentList.push(
              new Paragraph({
                alignment: AlignmentType.START,
                style: "none",
                children: [
                  new TextRun({
                    text: `             • ${srs.sm_comment}`,
                    size: setfontsize(16),
                  }),
                  new TextRun({
                    text: ` [${moment(srs.sm_created_date).format("HH:mm")} น. ${
                      srs.sm_follow_name !== null ? srs.sm_follow_name : ""
                    }]`,
                    size: setfontsize(16),
                    color: setcoloruser(srs?.sm_follow_name ? srs?.sm_follow_name : "NoData"),
                  }),
                  srs.srs_file_list.length > 0
                    ? new ImageRun({
                        data: clip,
                        transformation: {
                          width: 15,
                          height: 15,
                        },
                      })
                    : new TextRun({
                        text: ``,
                        size: setfontsize(16),
                      }),
                ],
              })
            );
  
            for (const img of srs.srs_image_list) {
              const imageParagraph = await setboximg(img.fd_img_id);
              contentList.push(imageParagraph);
            }
          }
        }
      }
    }
  
    if (data?.mom_srs?.customer_follow_up.length > 0) {
     
      let listdata = []
      for (let index = 0; index < data.mom_srs?.customer_follow_up.length; index += 1) {
let i =  data.mom_srs?.customer_follow_up[index]
let color = 'F1DCDB'
if ((index + 2) % 2 === 0) {
  color = 'F1DCDB'

}else{
  color = 'FFFFFF'
}
        listdata.push(
         new TableRow({
              children: [
                  new TableCell({
                    width: {
                      size: setwinth(75,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: color, 
                  },
                      children: [
                          new Paragraph({
          
                              style: "none",
                              alignment: AlignmentType.START,
                              children: [
                                new TextRun({
                                    text: `${index+1}.`,
                                    size: setfontsize(16),
                                }),
                                new TextRun({
                                  text: " ", 
                              }),
                                new TextRun({
                                  text: `${i.sm_comment}`,
                                  size: setfontsize(16),
                                
                              }),
                            ],
                          }),
                      ],
                      margins: {
                        top: 50, 
                        bottom: 0,
                        left: 50,
                        right: 50,
                    }
                     
                  }),
                  new TableCell({
                    width: {
                      size: setwinth(25,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: color, 
                  },
                    children: [
                        new Paragraph({
                         
                            style: "none",
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                  text: `${i.lus_status_name}`,
                                  size: setfontsize(16),
                              }),
                            
                          ],
                        }),
                        new Paragraph({
                         
                          style: "none",
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text:  `${moment(i.lus_created_date ? i.lus_created_date : i.sm_created_date).format('DD/MM/YYYY')}`,
                              size: setfontsize(16),
                            
                          }),
                          
                        ],
                      }),
                    ],
                    margins: {
                      top: 50, 
                      bottom: 0,
                      left: 50,
                      right: 50,
                  }
                   
                })
              ],
          }),
        );
      }


      contentList.push(
        new Paragraph({
          
          style: "none",
          alignment: AlignmentType.START,
          children: [
            new TextRun({
              text: "ติดตามงานส่วนของลูกค้า",
              size: setfontsize(16),
              bold: true, 
              underline: {
                type: "double", // เส้นใต้แบบคู่
                color: "000000", // สีแดง
              },
            }),
          ],
      }),
       
        new Table({
          rows: [
            new TableRow({
              children: [
                  new TableCell({
                    width: {
                      size: setwinth(75,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: "C0504D", 
                  },
                      children: [
                          new Paragraph({
          
                              style: "none",
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                    text: "รายละเอียด",
                                    bold: true, 
                                    size: setfontsize(16),
                                    color: `FFFFFF`,
                                }),
                            ],
                          }),
                      ],
                      margins: {
                        top: 50, 
                        bottom: 0,
                        left: 50,
                        right: 50,
                    }
                     
                  }),
                  new TableCell({
                    width: {
                      size: setwinth(25,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: "C0504D", 
                  },
                    children: [
                        new Paragraph({
                         
                            style: "none",
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                  text: "สถานะการดำเนินการ",
                                  bold: true, 
                                  size: setfontsize(16),
                                  color: `FFFFFF`,
                              }),
                             
                          ],
                        }),
                    ],
                    margins: {
                      top: 50, 
                      bottom: 0,
                      left: 50,
                      right: 50,
                  }
                   
                })
              ],
          }),
          ...listdata
          ],
          width: {
              size: ((1440 * 8.27)-(720*2))-200, 
              type: WidthType.DXA,
          },
          borders: {
            top: { style: BorderStyle.SINGLE, size: 3, color: "C0504D" }, // เส้นขอบตารางด้านบน
            bottom: { style: BorderStyle.SINGLE, size: 3, color: "C0504D" }, // เส้นขอบตารางด้านล่าง
            left: { style: BorderStyle.SINGLE, size: 3, color: "C0504D" }, // เส้นขอบตารางด้านซ้าย
            right: { style: BorderStyle.SINGLE, size: 3, color: "C0504D" }, // เส้นขอบตารางด้านขวา
          },
        }),
        new Paragraph({
          text: "",
          spacing: {
              before: 300, 
              after: 0, 
              line: 1, 
          },
      }),
      );
    }

    if (data?.mom_srs?.me_follow_up.length > 0) {
     
      let listdata = []
      for (let index = 0; index < data.mom_srs?.me_follow_up.length; index += 1) {
let i =  data.mom_srs?.me_follow_up[index]
let color = 'DBE5F1'
if ((index + 2) % 2 === 0) {
  color = 'DBE5F1'
}else{
  color = 'FFFFFF'
}
        listdata.push(
         new TableRow({
              children: [
                  new TableCell({
                    width: {
                      size: setwinth(75,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: color, 
                  },
                      children: [
                          new Paragraph({
          
                              style: "none",
                              alignment: AlignmentType.START,
                              children: [
                                new TextRun({
                                    text: `${index+1}.`,
                                    size: setfontsize(16),
                                }),
                                new TextRun({
                                  text: " ", 
                              }),
                                new TextRun({
                                  text: `${i.sm_comment}`,
                                  size: setfontsize(16),
                                
                              }),
                            ],
                          }),
                      ],
                      margins: {
                        top: 50, 
                        bottom: 0,
                        left: 50,
                        right: 50,
                    }
                     
                  }),
                  new TableCell({
                    width: {
                      size: setwinth(25,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: color, 
                  },
                    children: [
                        new Paragraph({
                         
                            style: "none",
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                  text: `${i.lus_status_name}`, 
                                  size: setfontsize(16),
                              }),
                            
                          ],
                        }),
                        new Paragraph({
                         
                          style: "none",
                          alignment: AlignmentType.CENTER,
                          children: [
                            new TextRun({
                              text:  `${moment(i.lus_created_date ? i.lus_created_date : i.sm_created_date).format('DD/MM/YYYY')}`,
                              size: setfontsize(16),
                            
                          }),
                          
                        ],
                      }),
                    ],
                    margins: {
                      top: 50, 
                      bottom: 0,
                      left: 50,
                      right: 50,
                  }
                   
                })
              ],
          }),
        );
      }


      contentList.push(
        new Paragraph({
          
          style: "none",
          alignment: AlignmentType.START,
          children: [
            new TextRun({
              text: "ติดตามงานส่วนของบริษัท",
              size: setfontsize(16),
              bold: true, 
              underline: {
                type: "double", // เส้นใต้แบบคู่
                color: "000000", // สีแดง
              },
            }),
          ],
      }),
       
        new Table({
          rows: [
            new TableRow({
              children: [
                  new TableCell({
                    width: {
                      size: setwinth(75,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: "4F81BC", 
                  },
                      children: [
                          new Paragraph({
          
                              style: "none",
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                    text: "รายละเอียด",
                                    bold: true, 
                                    size: setfontsize(16),
                                    color: `FFFFFF`,
                                }),
                            ],
                          }),
                      ],
                      margins: {
                        top: 50, 
                        bottom: 50,
                        left: 50,
                        right: 50,
                    }
                     
                  }),
                  new TableCell({
                    width: {
                      size: setwinth(25,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: "4F81BC", 
                  },
                    children: [
                        new Paragraph({
                         
                            style: "none",
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                  text: "สถานะการดำเนินการ",
                                  bold: true, 
                                  size: setfontsize(16),
                                  color: `FFFFFF`,
                              }),
                             
                          ],
                        }),
                    ],
                    margins: {
                      top: 50, 
                      bottom: 0,
                      left: 50,
                      right: 50,
                  }
                   
                })
              ],
          }),
          ...listdata
          ],
          width: {
              size: ((1440 * 8.27)-(720*2))-200, 
              type: WidthType.DXA,
          },
          borders: {
            top: { style: BorderStyle.SINGLE, size: 3, color: "4F81BC" }, // เส้นขอบตารางด้านบน
            bottom: { style: BorderStyle.SINGLE, size: 3, color: "4F81BC" }, // เส้นขอบตารางด้านล่าง
            left: { style: BorderStyle.SINGLE, size: 3, color: "4F81BC" }, // เส้นขอบตารางด้านซ้าย
            right: { style: BorderStyle.SINGLE, size: 3, color: "4F81BC" }, // เส้นขอบตารางด้านขวา
          },
        }),
        new Paragraph({
          text: "",
          spacing: {
              before: 300, 
              after: 0, 
              line: 1, 
          },
      }),
      );
    }


    if (data.mom_document.length > 0) {

      let listdata = []
      for (let index = 0; index < data.mom_document.length; index += 1) {
let i =  data.mom_document[index]

        listdata.push(
         new TableRow({
              children: [
                  new TableCell({
                    width: {
                      size: setwinth(75,200), 
                      type: WidthType.DXA,
                  },
                 
                      children: [
                          new Paragraph({
          
                              style: "none",
                              alignment: AlignmentType.START,
                              children: [
                            
                                new TextRun({
                                  text: i.md_name != "" ? i.md_name : "ไม่ระบุ",
                                  size: setfontsize(16),
                                
                              }),
                            ],
                          }),
                      ],
                      margins: {
                        top: 50, 
                        bottom: 0,
                        left: 50,
                        right: 50,
                    }
                     
                  }),
                  new TableCell({
                    width: {
                      size: setwinth(25,200), 
                      type: WidthType.DXA,
                  },
                 
                    children: [
                 
                        new Paragraph({
                         
                            style: "none",
                            alignment: AlignmentType.CENTER,
                            children: [
                              new ExternalHyperlink({
                                link: i.md_link,
                                children: [
                                    new TextRun({
                                        text: "Link Download",
                                        color: "0000FF", 
                                        bold: true,
                                    }),
                                ],
                            })
                            
                          ],
                        }),
                     
                    ],
                    margins: {
                      top: 50, 
                      bottom: 0,
                      left: 50,
                      right: 50,
                  }
                   
                })
              ],
          }),
        );
      }


      contentList.push(
        new Paragraph({
          
          style: "none",
          alignment: AlignmentType.START,
          children: [
            new TextRun({
              text: "เอกสารประกอบการประชุม",
              size: setfontsize(16),
              bold: true, 
              underline: {
                type: "double", // เส้นใต้แบบคู่
                color: "000000", // สีแดง
              },
            }),
          ],
      }),
       
        new Table({
          rows: [
            new TableRow({
              children: [
                  new TableCell({
                    width: {
                      size: setwinth(75,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: colorbg, 
                  },
                      children: [
                          new Paragraph({
          
                              style: "none",
                              alignment: AlignmentType.CENTER,
                              children: [
                                new TextRun({
                                    text: "ชื่อเอกสาร",
                                    bold: true, 
                                    size: setfontsize(16),
                                   
                                }),
                            ],
                          }),
                      ],
                      margins: {
                        top: 50, 
                        bottom: 50,
                        left: 50,
                        right: 50,
                    }
                     
                  }),
                  new TableCell({
                    width: {
                      size: setwinth(25,200), 
                      type: WidthType.DXA,
                  },
                  shading: {
                    fill: colorbg, 
                  },
                    children: [
                        new Paragraph({
                         
                            style: "none",
                            alignment: AlignmentType.CENTER,
                            children: [
                              new TextRun({
                                  text: "Link Download",
                                  bold: true, 
                                  size: setfontsize(16),
                                
                              }),
                             
                          ],
                        }),
                    ],
                    margins: {
                      top: 50, 
                      bottom: 0,
                      left: 50,
                      right: 50,
                  }
                   
                })
              ],
          }),
          ...listdata
          ],
          width: {
              size: ((1440 * 8.27)-(720*2))-200, 
              type: WidthType.DXA,
          },
        }),
       
      );

    }

    return contentList;
  }
  
  // เรียกใช้ฟังก์ชันเพื่อสร้างเนื้อหา
  const setcontentlist = await createContentList();


    const doc = new Document({
        features: {
            updateFields: true,
        },
        styles: {
            paragraphStyles: [
                {
                    id: "MySpectacularStyle",
                 
                    quickFormat: true,
                    run: {
                        color: "990000",
                        font:"TH Sarabun",
                        size:'60',
                        
                    },
                },
                {
                    id: "none",
                    quickFormat: true,
                    run: {
                        color: "000000",
                        font:"TH Sarabun",
                        size:'32',
                        
                    },
                },

            ],
        },
        sections: [
            {
              properties: {
                page: {
                    size: {
                        width: 1440 * 8.27, 
                        height: 1440 * 11.69, 
                    },
                    margin: {
                        top: 0,
                        bottom: 1440,
                        left: 700,
                        right: 700,
                        header: 0,
                        footer: 0,
                    },
                },
            },  
                headers: {
                  default: header,
                  
              },
              footers: {
                  default: footer,
              },
                children: [
                  new Paragraph({
                    text: "", 
                    spacing: {
                        before: 400, 
                    },
                }),
                  new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                  columnSpan: 3,
                                    children: [
                                        new Paragraph({
                                      
                                            alignment: AlignmentType.CENTER,
                                            style: "none",
                                            children: [
                                              new TextRun({
                                                  text: "บันทึกการประชุม",
                                                  bold: true, 
                                                  size: setfontsize(20),
                                              }),
                                          ],
                                        }),
                                    ],
                                    shading: {
                                      fill: colorbg, 
                                  },
                                  margins: {
                                    top: 100, 
                                    bottom: 0,
                                    left: 100,
                                    right: 100,
                                }
                                   
                                }),
                            ],
                        }),

                        new TableRow({
                          children: [
                              new TableCell({
                                columnSpan: 3,
                                  children: [
                                      new Paragraph({
                                     
                                          style: "none",
                                          alignment: AlignmentType.START,
                                          children: [
                                            new TextRun({
                                                text: "หัวเรื่อง",
                                                bold: true, 
                                                size: setfontsize(16),
                                            }),
                                            new TextRun({
                                              text: " ", 
                                          }),
                                            new TextRun({
                                              text: data.mom_header ? data.mom_header: 'ไม่ระบุ',
                                              size: setfontsize(16),
                                            
                                          }),
                                        ],
                                      }),
                                  ],
                                  margins: {
                                    top: 50, 
                                    bottom: 0,
                                    left: 50,
                                    right: 50,
                                }
                                 
                              }),
                          ],
                      }),
                      new TableRow({
                        children: [
                            new TableCell({
                              width: {
                                size: setwinth(58), 
                                type: WidthType.DXA,
                            },
                                children: [
                                    new Paragraph({
                    
                                        style: "none",
                                        alignment: AlignmentType.START,
                                        children: [
                                          new TextRun({
                                              text: "สถานที่ประชุม",
                                              bold: true, 
                                              size: setfontsize(16),
                                          }),
                                          new TextRun({
                                            text: " ", 
                                        }),
                                          new TextRun({
                                            text: data.mom_location ? data.mom_location: 'ไม่ระบุ',
                                            size: setfontsize(16),
                                          
                                        }),
                                      ],
                                    }),
                                ],
                                margins: {
                                  top: 50, 
                                  bottom: 0,
                                  left: 50,
                                  right: 50,
                              }
                               
                            }),
                            new TableCell({
                              width: {
                                size: setwinth(26), 
                                type: WidthType.DXA,
                            },
                              children: [
                                  new Paragraph({
                                   
                                      style: "none",
                                      alignment: AlignmentType.START,
                                      children: [
                                        new TextRun({
                                            text: "วันที่",
                                            bold: true, 
                                            size: setfontsize(16),
                                        }),
                                        new TextRun({
                                          text: " ", 
                                      }),
                                        new TextRun({
                                          text: data.mom_start_date ? moment(data?.mom_start_date).format("DD/MM/YYYY"): 'ไม่ระบุ',
                                          size: setfontsize(16),
                                        
                                      }),
                                    ],
                                  }),
                              ],
                              margins: {
                                top: 50, 
                                bottom: 0,
                                left: 50,
                                right: 50,
                            }
                             
                          }),
                          new TableCell({
                            width: {
                              size: setwinth(31), 
                              type: WidthType.DXA,
                          },
                            children: [
                                new Paragraph({
                               
                                    style: "none",
                                    alignment: AlignmentType.START,
                                    children: [
                                      new TextRun({
                                          text: "เวลา",
                                          bold: true, 
                                          size: setfontsize(16),
                                      }),
                                      new TextRun({
                                        text: " ", 
                                    }),
                                      new TextRun({
                                        text: data.mom_start_date && data.mom_end_date ? `${moment(data?.mom_start_date).format("HH:mm")} - ${moment(data?.mom_end_date).format("HH:mm")} น.`: 'ไม่ระบุ',
                                        size: setfontsize(16),
                                      
                                    }),
                                  ],
                                }),
                            ],
                            margins: {
                              top: 50, 
                              bottom: 0,
                              left: 50,
                              right: 50,
                          }
                           
                        }),
                        ],
                    }),
                    new TableRow({
                      children: [
                          new TableCell({
                            columnSpan: 3,
                              children: [
                                  new Paragraph({
                               
                                      style: "none",
                                      alignment: AlignmentType.START,
                                      children: [
                                        new TextRun({
                                            text: "ผู้บันทึกประชุม",
                                            bold: true, 
                                            size: setfontsize(16),
                                        }),
                                        new TextRun({
                                          text: " ",
                                      }),
                                        new TextRun({
                                          text: data?.mom_record_by ? data?.mom_record_by : `ไม่ระบุ`,
                                          size: setfontsize(16),
                                        
                                      }),
                                    ],
                                  }),
                              ],
                              margins: {
                                top: 50, 
                                bottom: 0,
                                left: 50,
                                right: 50,
                            }
                             
                          }),
                      ],
                  }),
             
                    ],
                    width: {
                        size: (1440 * 8.27)-(720*2), 
                        type: WidthType.DXA,
                    },
                    borders: {
                   
                      bottom: { style: BorderStyle.SINGLE, size: 3, color: "FFFFFF" }, // เส้นขอบตารางด้านล่าง
                     
                    },
                   
                  }),
                  new Paragraph({
                    text: "",
                    spacing: {
                        before: 0, 
                        after: 0, 
                        line: 0, 
                    },
                }),
                    ...setusermeeting,
                    new Paragraph({
                      text: "",
                      spacing: {
                          before: 0, 
                          after: 0, 
                          line: 0, 
                      },
                      
                  }),
                  new Table({
                    rows: [
                        new TableRow({
                            children: [
                                new TableCell({
                                  width: {
                                    size: setwinth(100), 
                                    type: WidthType.DXA,
                                },
                                    children: setcontentlist,
                                  
                                  margins: {
                                    top: 100, 
                                    bottom: 0,
                                    left: 100,
                                    right: 100,
                                }
                                   
                                }),
                            ],
                        }),
                  
                  
                    ],
                    width: {
                        size: (1440 * 8.27)-(720*2), 
                        type: WidthType.DXA,
                    },
                   
                  }),
                  
                ],
            },
          
          
        ],
    });

    function setcoloruser(c){
      console.log(c);
      
      let array = data?.mom_person;
      let getFilter = array?.flatMap((item) =>
          item.mom_person_list.filter((item2) => item2.mp_name == c)
      );
      console.log(getFilter);
      const toHex = (n) => n.toString(16).padStart(2, '0');
      return `#${toHex(getFilter.length > 0 ? getFilter[0].color_r :  0)}${toHex(getFilter.length > 0 ? getFilter[0].color_g :  0)}${toHex(getFilter.length > 0 ? getFilter[0].color_b :  0)}`;
          }



    function setwinth(d,p=0){
return (((1440 * 8.27)-(720*2)) * (d / 100))-p
    }
    function setfontsize(d){
      return d *2
          }
          callback('docx')
    Packer.toBlob(doc).then((blob) => {
        saveAs(blob, `${moment(new Date()).add("year", 543).format("YY_MM_DD_")}${data.project.project_code || 'XX'}_${data.project.project_name
      }_MOM_${data.mom_no}.docx`);
    });
};
   

export default momdocx;
