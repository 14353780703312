import React, { useEffect, useState, useRef, Component } from 'react';
import '../chating/chating.css'
import moment from "moment";
import axios from "axios";
import Configs from "../../../config";
import profileImg from '../../../assets/img/userlogo/defultUser.png';
import { io } from 'socket.io-client';

import Slider from "react-slick";
import { faL } from '@fortawesome/free-solid-svg-icons';



const Chating = ({
    isOpen,
    setChating,
    getProjectId,
    close,
    setProjectActiveTag,
    chatIndexNo,
    setChatIndexNo
}) => {
    const [test, setTest] = useState({ test: '' })
    const [sendText, setSendText] = useState({
        text: '',
        searchText: '',
        onSearch: false,
        onEdit: false,
        editId: '',
        editDate: '',
        editText: '',
        editTextChang: '',
        onOnline: true
    })
    const [imgData, setimgData] = useState([])
    const [chatingData, setChatingData] = useState([])
    const [loadDatachat, setloadDatachat] = useState(false)
    const [onlineData, setOnlineData] = useState([])
    const [page, setPage] = useState(1);
    const [pageSize] = useState(10);
    const [isLoadingChat, setIsLoadingChat] = useState(false);
    const [hasMoreMessages, setHasMoreMessages] = useState(true);
    const [currentIndex, setCurrentIndex] = useState(0);
   
     const chatContainerRef = useRef(null);

    function setChatingClose() {
        setChating({ isOpen: false });
        if (close != undefined) {
            close(false)
        };
        setProjectActiveTag(null);
        setloadDatachat(false);
    }

    let sendtext = document.getElementById("chatingSend")

    function auto_grow() {
        let sendtextint = 0

        sendtext.style.height = "44px";
        sendtext.style.height = (sendText.text.length == 0 ? 44 : sendtext.scrollHeight) + "px";
        // console.log('testlog',  sendText.text.length,sendtext.style.height,sendtext.style.top);

        if (parseInt(sendtext.style.height) >= 125) {
            sendtextint = 610
        } else if (parseInt(sendtext.style.height) <= 50 || sendText.text.length < 1) {
            sendtextint = 685
        } else {
            sendtextint = 735 - (parseInt(sendtext.style.height))
        }

        sendtext.style.top = sendtextint + "px";
    }

    async function auto_area() {
        let sendtext = document.getElementById("editChatText")

        sendtext.style.height = "50px";
        sendtext.style.height = (sendtext.scrollHeight) + "px";

    }

    async function editRefresh() {
        setSendText({ ...sendText, editTextChang: sendText.editText })
        sendText.editTextChang = sendText.editText
    }

   

    // const handleScroll = async (e) => {
    //     const outerDiv = e.target;
    //     const { scrollTop } = outerDiv;

    //     // เช็คว่าเลื่อนถึงบนสุดหรือไม่
    //     if (scrollTop === 0 && hasMoreMessages && !isLoadingChat) {
    //         const prevScrollHeight = outerDiv.scrollHeight; // บันทึกความสูงก่อนโหลดข้อมูลใหม่

    //         setPage((prevPage) => prevPage + 1);
    //         await getChatData(page + 1, pageSize,  getProjectId.pf_project_id);

    //         const newScrollHeight = outerDiv.scrollHeight; // ความสูงใหม่หลังข้อมูลถูกโหลด
    //         outerDiv.scrollTop = newScrollHeight - prevScrollHeight; // ปรับ scroll ให้คงตำแหน่งเดิม
    //     }
    // };

    // console.log("chatingData: ", chatingData);
    
    const handleScroll = async (e) => {
        const outerDiv = e.target;
        const { scrollTop } = outerDiv;
    
        // เช็คว่าเลื่อนถึงบนสุดหรือไม่
        if (scrollTop === 0 && hasMoreMessages && !isLoadingChat) {
            const prevScrollHeight = outerDiv.scrollHeight; // บันทึกความสูงก่อนโหลดข้อมูลใหม่
    
            setIsLoadingChat(true); // ตั้งค่าสถานะการโหลด
            setPage((prevPage) => prevPage + 1); // เพิ่มหน้า
            await getChatData(page + 1, pageSize, getProjectId.pf_project_id); // เรียก API เพื่อโหลดข้อความเก่า
    
            const newScrollHeight = outerDiv.scrollHeight; // ความสูงใหม่หลังจากข้อมูลใหม่ถูกโหลด
            outerDiv.scrollTop = newScrollHeight - prevScrollHeight; // ปรับ scroll ให้คงตำแหน่งเดิม
    
            setIsLoadingChat(false); // เสร็จสิ้นการโหลด
        }
    };

    async function getChatData(page, pageSize, project_id) {
        setIsLoadingChat(true);
        const user_id = sessionStorage.getItem('user_id');
        const temp_data = { page: page || 1, entries: pageSize || 10 };
    
        try {
            const response = await axios.post(
                `${Configs.TTT_Game_Service}/api/chatProject/getChatProject/${project_id || getProjectId.pf_project_id}/${user_id}`,
                temp_data,
                {
                    headers: {
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            // let temp = [];
            // let tempUser = [];
            let checkimg = imgData;
            setOnlineData([]);
            // Process chat messages
            response.data.message_data.forEach(async (item, index) => {
                let img = null;
                if (item.fd_img_id) {
                    const existingImg = checkimg.find(el => el.fd_img_id === item.fd_img_id);
                    if (!existingImg) {
                        const response2 = await axios.get(
                            `https://api.box.com/2.0/files/${item.fd_img_id}/content`,
                            {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            }
                        );
                        img = URL.createObjectURL(response2.data);
                        checkimg.push({ fd_img_id: item.fd_img_id, img });
                    } else {
                        img = existingImg.img;
                    }
                }
    
                const format = {
                    user_id: item.cp_created_by,
                    user_name: item.cp_created_name,
                    create_date: item.cp_updated_date,
                    user_text: item.cp_comment,
                    user_img: img,
                    cp_id: item.cp_id,
                    cp_index: item.cp_index,
                    is_img: true,
                };
    
               
                setChatingData(prevState => {
                    if (!prevState.some(chat => chat.cp_id === format.cp_id)) {
                        const updatedData = [...prevState, format];
                        return updatedData.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));
                    }
                    return prevState;
                });
            });

           for (let index = 0; index < response.data.user_list.length; index++) {
                    let item = response.data.user_list[index],img = null
                    if (item.fd_img_id) {
                        let check = checkimg.findIndex((el)=>{
                             return (el.fd_img_id  === item.fd_img_id)
                         })
                         if (check === -1) {
                             const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                 headers: {
                                     Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                 },
                                 responseType: 'blob',
                             });
             
                             /* console.log(URL.createObjectURL(response2.data)); */
                             checkimg.push({
                                 fd_img_id:item.fd_img_id,
                                 img:URL.createObjectURL(response2.data)
                             })
                             img = URL.createObjectURL(response2.data)
                         }else{
                             img = checkimg[check].img
                         }
                        
                     
                     }
                    let format = {
                        online_name: response.data.user_list[index].usr_name + ' ' + response.data.user_list[index].usr_lastname,
                        online_img: img,
                        online_is: response.data.user_list[index].usr_is_online || false,
                        online_id: response.data.user_list[index].usr_id,
                        online_ttt_id: response.data.user_list[index].usr_ttt_id || '',
                        usr_email: response.data.user_list[index].usr_email || '',
                    }
              
                    setOnlineData(prevState => {
                        if (!prevState.some(online => online.online_id === format.online_id)) {
                            return [...prevState, format];
                        }
                        return prevState;
                    });
                    if(index===8){
                        setloadDatachat(true);
                        setTest({ ...test, test: '' });
                        test.test = '';
                    }
                    if(index === response.data.user_list.length-1){
                        setloadDatachat(true);
                        setTest({ ...test, test: '' });
                        test.test = '';
                    }
                    if(response.data.user_list.length < 9){
                        setloadDatachat(true);
                        setTest({ ...test, test: '' });
                        test.test = '';
                    }
 
                };
            
                setimgData(checkimg)
                setScrollAuto(response);
    
            // Handle when no more messages are available
            if (response.data.message_data.length === 0) {
                setHasMoreMessages(false);
            }
    
            setimgData(checkimg);
            setIsLoadingChat(false);
        } catch (error) {
            console.error('Error loading chat data:', error);
            setIsLoadingChat(false);
        }
    }
    // async function getChatData(page, pageSize, project_id) {
    //     // console.log('testlog', getProjectId, sendText.text)
    //     setIsLoadingChat(true);

    //     // let outerDiv = document.getElementById('srsAreaAutoScroll');
    //     // const prevScrollHeight = outerDiv.scrollHeight; // ความสูงก่อนโหลดข้อมูลใหม่
    //     // const prevScrollTop = outerDiv.scrollTop;

    //     let user_id = sessionStorage.getItem('user_id')
    //     let temp_data = {
    //         page: page || 1,
    //         enties: pageSize || 10,
    //     }
    //     await axios({
    //         method: 'post',
    //         url: `${Configs.TTT_Game_Service}/api/chatProject/getChatProject/${project_id ? project_id : getProjectId.pf_project_id}/${sessionStorage.getItem('user_id')}`,
    //         headers: {
    //             /*  Authorization: getToken(), */
    //             'X-TTT': `${Configs.API_TTT}`,
    //             'Content-Type': 'application/json',
    //         },
    //         data:temp_data
    //     })
    //         .then(async function (response) {
    //             let temp = [];
    //             let tempUser = [];
    //             let checkimg = imgData;
    //             setOnlineData([]);
    //             // setChatingData([]);
    //             for (let index = 0; index < response.data.message_data.length; index++) {
    //                 let item = response.data.message_data[index],img = null
    //                 if (item.fd_img_id) {
    //                    let check = checkimg.findIndex((el)=>{
    //                         return (el.fd_img_id  === item.fd_img_id)
    //                     })
    //                     if (check === -1) {
    //                         const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
    //                             headers: {
    //                                 Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
    //                             },
    //                             responseType: 'blob',
    //                         });
            
    //                        /*  console.log(URL.createObjectURL(response2.data)); */
    //                         checkimg.push({
    //                             fd_img_id:item.fd_img_id,
    //                             img:URL.createObjectURL(response2.data)
    //                         })
    //                         img = URL.createObjectURL(response2.data)
    //                     }else{
    //                         img = checkimg[check].img
    //                     }
                       
                    
    //                 }

    //                 let format = {
    //                     user_id: item.cp_created_by,
    //                     user_name: item.cp_created_name,
    //                     create_date: item.cp_updated_date,
    //                     user_text: item.cp_comment,
    //                     user_img: img,
    //                     edit_id: item.cp_id,
    //                     edit_date: item.cp_created_date,
    //                     cp_id: item.cp_id,
    //                     cp_index: item.cp_index,
    //                     is_img: true
    //                 }

    //                 setChatingData(prevState => {
    //                     if (!prevState.some(chat => chat.cp_id === format.cp_id)) {
    //                         const updatedData = [...prevState, format];
    //                         return updatedData.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));
    //                     }
    //                     return prevState;
    //                 });
        

    //                 if (response.data.message_data.length === 0) {
    //                     setHasMoreMessages(false);
    //                 }

    //                 if (index > 0) {
    //                     if (response.data.message_data[index].cp_created_by == response.data.message_data[index - 1].cp_created_by) {
    //                         format.is_img = false
    //                     }
    //                 }
    //                 // console.log("response.data.message_data ", response.data.message_data);
                    
                    

    // //    if (response.data.message_data.length === 0) {
    // //                 setHasMoreMessages(false);
    // //             } else {
                 
    // //                 setChatingData(prevState => {
    // //                     // ตรวจสอบว่าข้อมูล cp_id ซ้ำกันหรือไม่
    // //                     if (!prevState.some(chat => chat.cp_id === format.cp_id)) {
    // //                         // รวมข้อมูลใหม่เข้าไปใน prevState
    // //                         const updatedData = [...prevState, format];
                    
    // //                         // เรียงข้อมูลตาม cp_created_date
    // //                         const sortedData = updatedData.sort((a, b) => {
    // //                             const dateA = new Date(a.create_date);
    // //                             const dateB = new Date(b.create_date);
    // //                             return dateA - dateB; // เปรียบเทียบวันที่
    // //                         });
                    
    // //                         return sortedData; // ส่งข้อมูลที่เรียงแล้วกลับ
    // //                     }
    // //                     return prevState; // ถ้าไม่ต้องการเพิ่มก็ส่ง prevState กลับ
    // //                 });
                    
    //                 // setChatingData(prevState => {
    //                 //     if (!prevState.some(chat => chat.cp_id === format.cp_id)) {
    //                 //         return [...prevState, format]
    //                 //     }
    //                 //     return prevState;
    //                 // });
                  
                    

    //             // }
                
    //                 // temp.push(format)
    //                 // setChatingData(temp)
    //                 // setimgData(checkimg)
    //             };
           
    //             for (let index = 0; index < response.data.user_list.length; index++) {
    //                 let item = response.data.user_list[index],img = null
    //                 if (item.fd_img_id) {
    //                     let check = checkimg.findIndex((el)=>{
    //                          return (el.fd_img_id  === item.fd_img_id)
    //                      })
    //                      if (check === -1) {
    //                          const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
    //                              headers: {
    //                                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
    //                              },
    //                              responseType: 'blob',
    //                          });
             
    //                          /* console.log(URL.createObjectURL(response2.data)); */
    //                          checkimg.push({
    //                              fd_img_id:item.fd_img_id,
    //                              img:URL.createObjectURL(response2.data)
    //                          })
    //                          img = URL.createObjectURL(response2.data)
    //                      }else{
    //                          img = checkimg[check].img
    //                      }
                        
                     
    //                  }
    //                 let format = {
    //                     online_name: response.data.user_list[index].usr_name + ' ' + response.data.user_list[index].usr_lastname,
    //                     online_img: img,
    //                     online_is: response.data.user_list[index].usr_is_online || false,
    //                     online_id: response.data.user_list[index].usr_id,
    //                     online_ttt_id: response.data.user_list[index].usr_ttt_id || '',
    //                     usr_email: response.data.user_list[index].usr_email || '',
    //                 }
              
    //                 setOnlineData(prevState => {
    //                     if (!prevState.some(online => online.online_id === format.online_id)) {
    //                         return [...prevState, format];
    //                     }
    //                     return prevState;
    //                 });
    //                 if(index===8){
    //                     setloadDatachat(true);
    //                     setTest({ ...test, test: '' });
    //                     test.test = '';
    //                 }
    //                 if(index === response.data.user_list.length-1){
    //                     setloadDatachat(true);
    //                     setTest({ ...test, test: '' });
    //                     test.test = '';
    //                 }
    //                 if(response.data.user_list.length < 9){
    //                     setloadDatachat(true);
    //                     setTest({ ...test, test: '' });
    //                     test.test = '';
    //                 }
    //                 //  if(index === response.data.user_list.length-1 && index < 9){
    //                 //     setloadDatachat(true);
    //                 //     setTest({ ...test, test: '' });
    //                 //     test.test = '';
    //                 // }
    //                 // setloadDatachat(true);

    //                 // setOnlineData(prevState => [...prevState, format]);
    //                 // tempUser.push(format)
    //                 // setChatingData(temp)
    //                 // setOnlineData(tempUser)
    //                 // setimgData(checkimg)
    //                 // setimgData(checkimg)
    //             };
    //             // if(response.data.user_list.length > 9){
    //             //     setloadDatachat(true);
    //             //     setTest({ ...test, test: '' });
    //             //     test.test = '';
    //             // }else{
      
    //             // }
          
    //             // setOnlineData(tempUser)
    //             // setChatingData(temp)
    //             setimgData(checkimg)
    //             setScrollAuto(response);
    //             // const newScrollHeight = outerDiv.scrollHeight;
    //             // if (page > 1) {
    //             //     // ถ้าเป็นการโหลดข้อความเก่า จะคงตำแหน่งเดิมของ scroll
    //             //     outerDiv.scrollTop = newScrollHeight - prevScrollHeight + prevScrollTop;
    //             // } else {
    //             //     // ถ้าเป็นการโหลดครั้งแรก จะเลื่อน scroll ไปที่ล่างสุด
    //             //     outerDiv.scrollTop = outerDiv.scrollHeight;
    //             // }
    //             setIsLoadingChat(false);
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    // }
//   console.log("loadDatachat: ",loadDatachat, onlineData.length);

    function setScrollAuto(response) {
        const interval = setInterval(() => {
            if (chatIndexNo) {
                let outerDiv = document.getElementById('chatAutoScroll');
                let innerDiv = document.getElementById('chatIndex' + chatIndexNo);

                if (innerDiv) {
                    outerDiv.scrollTop = 0
                    innerDiv.style.animation = '';

                    let outerDivRect = outerDiv.getBoundingClientRect();
                    let innerDivRect = innerDiv.getBoundingClientRect();

                    let offsetX = innerDivRect.left - outerDivRect.left;
                    let offsetY = innerDivRect.top - outerDivRect.top;

                   

                    outerDiv.scrollTop = (offsetY - 200)
                    innerDiv.style.animation = "hl 1s";
         
                }
           
            } else {
                document.getElementById('chatAutoScroll').scrollTop += (response.data.message_data.length * 1000)
            }
            setChatIndexNo(null);
            clearInterval(interval);
        }, 150);
    }

    async function sendChatData() {
        // console.log('testlog',getProjectId, sendText.text)
        let checkTag = sendText.text.split('*');
        let tagData = [];
        checkTag.length > 0 && checkTag.map((e, index) => {
            let fil = onlineData.filter((f) => { return e == f.online_name })
            let checkFil = tagData.filter((cf) => { return e == cf.online_name })
            if (fil.length > 0 && checkFil.length == 0) {
                tagData.push(fil[0])
            }

        });

        let temp = {
            project_id: getProjectId.pf_project_id,
            message: sendText.text,
            user_id: sessionStorage.getItem('user_id'),
            user_name: sessionStorage.getItem('user'),
            tagData: tagData,
            index: chatingData.length,
            filelist: []
        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/chatProject/sendData`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                socketRef.current.emit('chat', { message: sendText.text, room: "PROJECT-CHAT:" + getProjectId.pf_project_id, project_id: getProjectId.pf_project_id });
              getChatData()
                sendtext.style.height = "44px";
                sendtext.style.top = 685 + "px";

                setSendText({ ...sendText, text: '' });

                // const interval = setInterval(() => {
                //     document.getElementById('chatAutoScroll').scrollTop += (chatingData.length * 1000)
                //     // console.log('testlog');
                //     clearInterval(interval);
                // }, 150);
            })
            .catch(function (error) {
                console.log(error);
            });

        if (tagData.length > 0) {
            getProjectId.index = chatingData.length;
            for (let item of tagData) {
                let tempNoti = {
                    header: "TAG YOU ON CHAT IN " + getProjectId.project_name,
                    // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
                    // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
                    noti_menu_name: "tag_chat",
                    user_to_id: item.online_id,
                    user_to_name: item.online_name,
                    link_to: JSON.stringify(getProjectId),
                    space_ship_id: null
                }
                await axios({
                    method: 'POST',
                    url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: tempNoti,
                })
                    .then(async function (response) {
                        // setSendData([])
                        socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + item.online_id });
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        }

        if (tagData.length > 0) {
            let tempMail = {
                userby: 'TTT',
                tagData: tagData || [],
                comment: sendText.text,
                userSend: sessionStorage.getItem('user'),
                where: getProjectId.project_name + ' (CHAT)'
            };
            axios({
                method: 'POST',
                url: `${Configs.TTT_Game_Service}/api/mail/sendMailChatTag`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: tempMail
            })
                .then(function (response) {

                })
                .catch(function (error) {
                    console.log(error);


                });
        }
    }

    async function editClose() {
        setSendText({ ...sendText, onEdit: false })
        sendText.onEdit = false
        // if (editData.edit_text != editData.pri_comment) {

        // } else {
        //     setEditData({...editData, edit_open: false})
        //     editData.edit_open = false
        // }
    }



    async function editSubmit() {
        let checkTag = sendText.editTextChang.split('*');
        let tagData = [];
    
        // ตรวจสอบ tag ที่ถูกระบุในข้อความ
        checkTag.length > 0 && checkTag.forEach((e) => {
            let fil = onlineData.filter((f) => e === f.online_name);
            let checkFil = tagData.filter((cf) => e === cf.online_name);
            if (fil.length > 0 && checkFil.length === 0) {
                tagData.push(fil[0]);
            }
        });

        // console.log("sendText",sendText);
        
        let temp = {
            cp_id: sendText.editId,
            message: sendText.editTextChang,
            user_id: sessionStorage.getItem('user_id'),
            user_name: sessionStorage.getItem('user'),
            tagData: tagData,
            filelist: []
        };
    
        try {
            // ส่ง API เพื่อแก้ไขข้อมูลใน server
            const response = await axios({
                method: 'post',
                url: `${Configs.TTT_Game_Service}/api/chatProject/updateData`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: temp
            });
    
            // console.log("Edit response:", response.data); // Debugging
    
            // Emit event ผ่าน socket เพื่อแจ้งผู้ใช้อื่นๆ
            socketRef.current.emit('chat', {
                message: sendText.editTextChang, 
                room: "PROJECT-CHAT:" + getProjectId.pf_project_id, 
                project_id: getProjectId.pf_project_id 
            });
    
            // อัปเดตข้อมูลใน state chatingData เพื่อแสดงข้อความที่ถูกแก้ไข
            setChatingData((prevState) => {
                const updatedState = prevState.map((chat) => {
                    if (chat.cp_id === sendText.editId) {
                        // console.log("Updating message for cp_id:", chat.cp_id); // Debugging
                        return { ...chat, user_text: sendText.editTextChang };
                    }
                    return chat;
                });
    
                // console.log("Updated chatingData:", updatedState); // Debugging
                return updatedState;
            });
    
            // Reset ค่า sendText
            setSendText({ ...sendText, onEdit: false, editTextChang: '' });
            setTest({ ...test, test: '' });
    
        } catch (error) {
            console.log(error);
        }
    
        // ส่งการแจ้งเตือนให้กับผู้ที่ถูกแท็ก
        if (tagData.length > 0) {
            getProjectId.index = sendText.index + 1;
            for (let item of tagData) {
                let tempNoti = {
                    header: "TAG YOU ON CHAT IN " + getProjectId.project_name,
                    noti_menu_name: "tag_chat",
                    user_to_id: item.online_id,
                    user_to_name: item.online_name,
                    link_to: JSON.stringify(getProjectId),
                    space_ship_id: null
                };
    
                await axios({
                    method: 'POST',
                    url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
                    headers: {
                        Authorization: sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: tempNoti,
                })
                .then(() => {
                    socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + item.online_id });
                })
                .catch(function (error) {
                    console.log(error);
                });
            }
        }
    
        // ส่งอีเมลให้กับผู้ที่ถูกแท็ก
        if (tagData.length > 0) {
            let tempMail = {
                userby: 'TTT',
                tagData: tagData || [],
                comment: sendText.editTextChang,
                userSend: sessionStorage.getItem('user'),
                where: getProjectId.project_name + ' (CHAT)'
            };
            
            axios({
                method: 'POST',
                url: `${Configs.TTT_Game_Service}/api/mail/sendMailChatTag`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    'X-TTT': `${Configs.API_TTT}`,
                    'Content-Type': 'application/json',
                },
                data: tempMail
            })
            .catch(function (error) {
                console.log(error);
            });
        }
    }
    


    // async function editSubmit() {
    //     // console.log('testlog',editData.pri_id, editData.edit_text);
    //     let checkTag = sendText.editTextChang.split('*');
    //     let tagData = [];
    //     checkTag.length > 0 && checkTag.map((e, index) => {
    //         let fil = onlineData.filter((f) => { return e == f.online_name })
    //         let checkFil = tagData.filter((cf) => { return e == cf.online_name })
    //         if (fil.length > 0 && checkFil.length == 0) {
    //             tagData.push(fil[0])
    //         }

    //     });

    //     let temp = {
    //         cp_id: sendText.editId,
    //         message: sendText.editTextChang,
    //         user_id: sessionStorage.getItem('user_id'),
    //         user_name: sessionStorage.getItem('user'),
    //         tagData: tagData,
    //         filelist: []
    //     }
    //     await axios({
    //         method: 'post',
    //         url: `${Configs.TTT_Game_Service}/api/chatProject/updateData`,
    //         headers: {
    //             Authorization: sessionStorage.getItem('access_token'),
    //             'X-TTT': `${Configs.API_TTT}`,
    //             'Content-Type': 'application/json',
    //         },
    //         data: temp
    //     })
    //         .then(async function (response) {
    //             socketRef.current.emit('chat', { message: sendText.text, room: "PROJECT-CHAT:" + getProjectId.pf_project_id, project_id: getProjectId.pf_project_id });
    //             /* getChatData() */
    //             setSendText({ ...sendText, onEdit: false })
    //             sendText.onEdit = false
    //             setTest({ ...test, test: '' });
    //             test.test = '';

    //             // console.log(response);

    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });

    //     if (tagData.length > 0) {
    //         getProjectId.index = sendText.index + 1;
    //         for (let item of tagData) {
    //             let tempNoti = {
    //                 header: "TAG YOU ON CHAT IN " + getProjectId.project_name,
    //                 // header:"คุณได้รับคำเชิญเข้าร่วมยาน",
    //                 // body:"คุณได้รับคำเชิญจาก " + sessionStorage.getItem('user') + " เพื่อเข้าร่วมยาน " + spaceShipName + " คุณจะยอมรับคำเชิญหรือไม่ ?",
    //                 noti_menu_name: "tag_chat",
    //                 user_to_id: item.online_id,
    //                 user_to_name: item.online_name,
    //                 link_to: JSON.stringify(getProjectId),
    //                 space_ship_id: null
    //             }
    //             await axios({
    //                 method: 'POST',
    //                 url: `${Configs.TTT_Game_Notification_Service}/api/notification/addNotification`,
    //                 headers: {
    //                     Authorization: sessionStorage.getItem('access_token'),
    //                     'X-TTT': `${Configs.API_TTT}`,
    //                     'Content-Type': 'application/json',
    //                 },
    //                 data: tempNoti,
    //             })
    //                 .then(async function (response) {
    //                     // setSendData([])
    //                     socketRef.current.emit('mailbox', { message: 'new message', room: "mailBox:" + item.online_id });
    //                 })
    //                 .catch(function (error) {
    //                     console.log(error);
    //                 });
    //         }
    //     }

    //     if (tagData.length > 0) {
    //         let tempMail = {
    //             userby: 'TTT',
    //             tagData: tagData || [],
    //             comment: sendText.editTextChang,
    //             userSend: sessionStorage.getItem('user'),
    //             where: getProjectId.project_name + ' (CHAT)'
    //         };
    //         axios({
    //             method: 'POST',
    //             url: `${Configs.TTT_Game_Service}/api/mail/sendMailChatTag`,
    //             headers: {
    //                 Authorization: sessionStorage.getItem('access_token'),
    //                 'X-TTT': `${Configs.API_TTT}`,
    //                 'Content-Type': 'application/json',
    //             },
    //             data: tempMail
    //         })
    //             .then(function (response) {

    //             })
    //             .catch(function (error) {
    //                 console.log(error);


    //             });
    //     }

    // }

    /* const result = chatingData.filter((e) => e.user_text == sendText.searchText); */
    const [searchResult, setSearchResult] = useState({
        isOpen: false,
        data: []
    });
    function searchValue(label) {
        // console.log('testlog', searchResult.data);
        if (label.length >= 1) {
            let result = chatingData.filter((item) => {

                return (item.user_text === undefined || item.user_text === null)
                    ? ''
                    : item.user_text.toLowerCase().indexOf(label.toLowerCase().trim()) > -1;
            })

            setSearchResult({ ...searchResult, isOpen: true, data: result })
            // console.log('testlog',searchResult);
            setTest({ ...test, test: '' });
            test.test = '';
        } else {
            setSearchResult({ ...searchResult, isOpen: false, data: [] })
            searchResult.isOpen = false
            setTest({ ...test, test: '' });
            test.test = '';
            // console.log('testlog', searchResult);
        }
    }

    function searchSetScroll(cp_index) {
        let outerDiv = document.getElementById('chatAutoScroll');
        let innerDiv = document.getElementById('chatIndex' + cp_index);

        outerDiv.scrollTop = 0
        innerDiv.style.animation = '';

        let outerDivRect = outerDiv.getBoundingClientRect();
        let innerDivRect = innerDiv.getBoundingClientRect();

        let offsetX = innerDivRect.left - outerDivRect.left;
        let offsetY = innerDivRect.top - outerDivRect.top;

        outerDiv.scrollTop = (offsetY - 200)
        innerDiv.style.animation = "hl 1s";
    }

    useEffect(() => {
        if (isOpen) {
            // setChatingData([]);
            getChatData()
        }else{
            setChatingData([]);
        }
    }, [isOpen])

    useEffect(() => {
        if (chatIndexNo != null) {
            setTest({ ...test, test: '' });
            test.test = '';
        }
    }, [chatIndexNo])


    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port: Configs.TTT_Game_Socket_Service_Port
            });
            //io(Configs.TTT_Game_Socket_Service);
        }
    }, [

    ])

    useEffect(() => {
        // console.log('onRoom', "PROJECT-CHAT:" + getProjectId.pf_project_id);
        if (isOpen === true) {

            socketRef.current.emit('leave', "PROJECT-CHAT:" + getProjectId.pf_project_id)
            socketRef.current.emit('join', "PROJECT-CHAT:" + getProjectId.pf_project_id)


        } else {
            socketRef.current.emit('leave', 'PROJECT-CHAT:' + getProjectId.pf_project_id)
            socketRef.current.emit('leave', 'PROJECT-CHAT:' + getProjectId.pf_project_id)
        }
    }, [isOpen])
    /* if (socketRef.current) {
    
    
    } */
    /* socket.emit('join','93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'); */

    /* console.log('onRoom',getProjectId) */
    const [activeRoom, setActiveRoom] = useState({ room: getProjectId });

    function SampleNextArrow(props) {
        const { className, style, onClick , onMouseDown, onMouseUp } = props;
        return (
            <div
                className={`${className} arrowss`}
                style={{ ...style, display: "block", background: '#2E2E2E', width: '25px', height: '146px', position: 'absolute', top: '0', left: '100%', zIndex: '1', borderRadius: '0 17.5px 17.5px 0', borderLeft: '2px solid rgb(0, 132, 255)', textAlign: 'center', fontSize: '25px', fontWeight: '1000', paddingTop: '52.5px' }}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >{'>'}</div>
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick , onMouseDown, onMouseUp } = props;
        return (
            <div
                className={`${className} arrowss`}
                style={{ ...style, display: "block", background: '#2E2E2E', width: '25px', height: '146px', position: 'absolute', left: '-2.75%', zIndex: '1', borderRadius: '17.5px 0 0 17.5px', borderRight: '2px solid rgb(0, 132, 255)', textAlign: 'center', fontSize: '25px', fontWeight: '1000', paddingTop: '52.5px' }}
                onClick={onClick}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
            >{'<'}</div>
        );
    }
    const sliderRef = useRef(null);
    const intervalRef = useRef(null);

    const startScroll = (direction) => {
        // Clear any existing interval
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
        }

        intervalRef.current = setInterval(() => {
            const slick = sliderRef.current.innerSlider;

            if (direction === 'next') {
                if (slick.state.currentSlide > 0) {
                    sliderRef.current.slickNext();
                } else {
                    clearInterval(intervalRef.current);
                }
            } else if (direction === 'prev') {
                if (slick.state.currentSlide > 0) {
                    sliderRef.current.slickPrev();
                } else {
                    clearInterval(intervalRef.current);
                }
            }
        }, 200); // Adjust speed as needed
    };

    const stopScroll = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null; // Reset the interval reference
        }
    };

    const settings = {
        dots: false,
        infinite: true,
        rtl: true,
        slidesToShow: Math.min(loadDatachat ? 9 : onlineData.length, onlineData.length),
        slidesToScroll: 1,
        autoplay: false,
        speed: 200,
        swipeToSlide: true,
        autoplaySpeed: 500,
        cssEase: "linear",

        nextArrow: <SampleNextArrow onMouseDown={() => startScroll('next')} onMouseUp={stopScroll} />,
        prevArrow: <SamplePrevArrow onMouseDown={() => startScroll('prev')} onMouseUp={stopScroll} />

      };

      
    // const settings = {
    //     dots: false,
    //     infinite: false,
    //     rtl: true,
      //  // slidesToShow: Math.min(loadDatachat ? 9 : onlineData.length, onlineData.length),
    //     slidesToShow: Math.min(onlineData.length, 3),
    //     slidesToScroll: 1,
    //     autoplay: false,
    //     speed: 300,
    //     swipeToSlide: true,
    //     autoplaySpeed: 500,
    //     cssEase: "linear",

    //     nextArrow: <SampleNextArrow onMouseDown={() => startScroll('next')} onMouseUp={stopScroll} />,
    //     prevArrow: <SamplePrevArrow onMouseDown={() => startScroll('prev')} onMouseUp={stopScroll} />
    // };
   
    

    // useEffect(() => {
    //     const socket = socketRef.current;

    //     const socFnc = (data) => {
    //         if (hasMoreMessages) {
    //             // setPage((prevPage) => prevPage + 1);
    //             getChatData(page + 1, pageSize, data.project_id);
    //         }
    //     };

    //     try {
    //         socket.open();
    //         socket.on('online_update', socFnc);
    //         socket.on('chat', socFnc);
    //     } catch (error) {
    //         console.error('Socket connection error:', error);
    //     }

    //     // Cleanup function
    //     return () => {
    //         socket.off("chat", socFnc);
    //         socket.off("online_update", socFnc);
    //         socket.close();
    //     };
    // }, [hasMoreMessages, page]);


    useEffect(() => {
        const socket = socketRef.current;
        
        const socFnc = (data) => {
            // console.log("data socket: ", data);
            getChatData(1, pageSize, data.project_id);
            // Add the new message from the socket in real-time
            // const newMessage = {
            //     user_id: data.cp_created_by,
            //     user_name: data.cp_created_name,
            //     create_date: data.cp_updated_date,
            //     user_text: data.cp_comment,
            //     cp_id: data.cp_id,
            //     cp_index: data.cp_index,
            //     is_img: true, // Add logic for images if needed
            // };
    
            // setChatingData(prevState => {
            //     if (!prevState.some(chat => chat.cp_id === newMessage.cp_id)) {
            //         const updatedData = [...prevState, newMessage];
    
            //         // Sort messages by the creation date
            //         return updatedData.sort((a, b) => new Date(a.create_date) - new Date(b.create_date));
            //     }
            //     return prevState;
            // });
        };
    
        try {
            socket.open();
            socket.on('online_update', socFnc);
            socket.on('chat', socFnc);
        } catch (error) {
            console.error('Socket connection error:', error);
        }
    
        // Cleanup on unmount
        return () => {
            socket.off("chat", socFnc);
            socket.off("online_update", socFnc);
            socket.close();
        };
    }, [hasMoreMessages, page]);


    // useEffect(() => {


    //     /*     if(getProjectId !== '' ){ */



    //     const { current: socket } = socketRef;
    //     const socFnc = (data) => {
    //         // console.log('onRoom', data)
    //        if(hasMoreMessages){
    //         setPage((prevPage) => prevPage + 1);
    //         getChatData(page + 1, pageSize,  data.project_id);
    //        }
  
    //         // getChatData(1,10,data.project_id);

    //         /*     socket.emit('leave',data.room) */
    //     }
    //     try {
    //         socket.open();
    //         //console.log('onMenu', 'เข้าEffectแล้ว', activeRoom.room, 'RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad')
    //         /* socket.emit('join','RISK93bf93f2-eb48-4bf7-b1bc-1305cdc13fad') */

    //         socket.on('online_update', socFnc)
    //         socket.on('chat', socFnc)



    //         /*  setActiveRoom({...activeRoom,room:'93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'});
    //             activeRoom.room = '93bf93f2-eb48-4bf7-b1bc-1305cdc13fad'; */
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     // Return a callback to be run before unmount-ing.

    //     return () => {
    //         /*   console.log('onRoom','out') */
    //         socket.off("chat", socFnc);
    //         socket.off("online_update", socFnc);

    //         socket.close();
    //         setActiveRoom({ ...activeRoom, room: '' });
    //         activeRoom.room = '';
    //     };
    //     /* } */



    // }, []);

    const renderNames = () => {
        let split = (sendText.text || '').toString().split(' ');
        let index = 0;
        if (split.length > 0) {
            for (let item of split) {
                if (item.includes('@') && split.length == index + 1) {
                    if (item.split('@')[1].split(' ')[0] == '' && item.split('@')[1].split(' ')[0] != ' ') {
                        const check = onlineData.filter((e) => { return e.online_id != sessionStorage.getItem('user_id') });
                        return check.length > 0 && check.map((e) => {
                            return (<>
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (sendText.text || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + e.online_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setSendText({ ...sendText, text: stringParts });
                                    sendText.text = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '15%', height: '40px', backgroundImage: `url(${e.online_img != null ?  e.online_img : profileImg})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '30%' }}>{e.online_ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '60%' }}>{e.online_name}</div>
                                </div>
                            </>)
                        });
                    } else if (item.split('@')[1].split(' ')[0] != ' ') {
                        const names = item.split('@')[1].split(' ');
                        const check = onlineData.filter((e) => { return e.online_id != sessionStorage.getItem('user_id') && e.online_name.toLowerCase().startsWith(names[0].toLowerCase()) });
                        return <>
                            {check.length > 0 ?
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (sendText.text || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + check[0].online_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setSendText({ ...sendText, text: stringParts });
                                    sendText.text = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '15%', height: '40px', backgroundImage: `url(${check[0].online_img != null ?  check[0].online_img : profileImg})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '30%' }}>{check[0].online_ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '60%' }}>{check[0].online_name}</div>
                                </div>
                                :
                                <div className='srsAreaTagUser' style={{ display: 'none' }}></div>
                            }
                        </>;
                    }
                }
                index++
            }
        }
        return null;
    };
    const renderNamesEdit = () => {
        let split = (sendText.editTextChang || '').toString().split(' ');
        let index = 0;
        if (split.length > 0) {
            for (let item of split) {
                if (item.includes('@') && split.length == index + 1) {
                    if (item.split('@')[1].split(' ')[0] == '' && item.split('@')[1].split(' ')[0] != ' ') {
                        const check = onlineData.filter((e) => { return e.online_id != sessionStorage.getItem('user_id') });
                        return check.length > 0 && check.map((e) => {
                            return (<>
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (sendText.editTextChang || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + e.online_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setSendText({ ...sendText, editTextChang: stringParts });
                                    sendText.editTextChang = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '15%', height: '40px', backgroundImage: `url(${e.online_img != null ?  e.online_img : profileImg})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '30%' }}>{e.online_ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '60%' }}>{e.online_name}</div>
                                </div>
                            </>)
                        });
                    } else if (item.split('@')[1].split(' ')[0] != ' ') {
                        const names = item.split('@')[1].split(' ');
                        const check = onlineData.filter((e) => { return e.online_id != sessionStorage.getItem('user_id') && e.online_name.toLowerCase().startsWith(names[0].toLowerCase()) });
                        return <>
                            {check.length > 0 ?
                                <div className='srsAreaTagUser' onClick={() => {
                                    let parts = (sendText.editTextChang || '').toString().split(' ');
                                    let indexParts = 0;
                                    let stringParts = '';
                                    for (let item of parts) {
                                        if (item.includes('@') && parts.length == indexParts + 1) {
                                            stringParts += '*' + check[0].online_name + '* ';
                                        } else {
                                            stringParts += item + ' ';
                                        }
                                        indexParts++
                                    }
                                    setSendText({ ...sendText, editTextChang: stringParts });
                                    sendText.editTextChang = stringParts;
                                }}>
                                    <div className='setTagNameBoxImg' style={{ width: '15%', height: '40px', backgroundImage: `url(${check[0].online_img != null ?  check[0].online_img : profileImg})` }}></div>
                                    <div className='setTagNameBox' style={{ width: '30%' }}>{check[0].online_ttt_id}</div>
                                    <div className='setTagNameBox' style={{ width: '60%' }}>{check[0].online_name}</div>
                                </div>
                                :
                                <div className='srsAreaTagUser' style={{ display: 'none' }}></div>
                            }
                        </>;
                    }
                }
                index++
            }
        }
        return null;
    };


    return (
        <>
            <div className='chatBox' style={{ display: `${isOpen ? '' : 'none'}` }}>
                <i class="fa fa-search iconsearch" aria-hidden="true"
                    style={{ borderBottom: `${sendText.onSearch ? '2px solid white' : 'none'}` }}
                    onClick={() => {
                        setSendText({ ...sendText, onSearch: !sendText.onSearch })
                        sendText.onSearch = !sendText.onSearch
                    }}></i>
                <input className="chatBoxSearch"
                    style={{ display: `${sendText.onSearch ? '' : 'none'}` }}
                    placeholder='Search'
                    value={sendText.searchText}
                    onChange={(e) => {
                        setSendText({ ...sendText, searchText: e.target.value })
                        sendText.searchText = e.target.value
                        searchValue(sendText.searchText)
                    }}>
                </input>

                <div className="chatBoxClose" onClick={() => {
                    setChatingClose()
                }}><i class="fa fa-times" aria-hidden="true"></i></div>
                <div className='chatBoxHeader'>
                    <div className='chatTextHeader'>
                        {getProjectId.project_name}
                    </div>
                </div>
             
                <div className='chatBoxBody' 
                id='chatAutoScroll' 
                onScroll={handleScroll}
                >
                  
                    {chatingData.map((e, index) => {
                        const commentLines = String(e.user_text).split('\n');
                       
                        return (<>
                               
                            <img className='chatTextImg'
                                src={`${e.user_img !== null ? `${e.user_img}` : profileImg}`}
                                style={{ visibility: `${e.user_id == sessionStorage.getItem('user_id') != '' || e.is_img == false ? 'hidden' : 'visible'}`, right: '100px', border: '2px solid  white',  marginTop: `${index == 0 ? `${sendText.onOnline ? '170px' : '70px'}` : ''}` }}>

                            </img>

                            <div className='chatTextBox' id={/* e.cp_id */'chatIndex' + index}
                                style={{
                                    border: `${e.user_id == sessionStorage.getItem('user_id') ? '1px solid #7BD7FF' : '1px solid rgba(255, 255, 255, 1)'}`,
                                    backgroundColor: `${e.user_id == sessionStorage.getItem('user_id') ? 'rgba(0, 76, 121, 0.9)' : 'black'}`,
                                    color: `${e.user_id == sessionStorage.getItem('user_id') ? 'black' : 'white'}`,
                                   /*  marginTop: `${index == 0 ? `${sendText.onOnline ? '110px' : '10px'}` : ''}` */
                                }}>
                                <div className='chatTextName' style={{/* display: `${e.is_img == false ? 'none' : ''}`, */ placeSelf: `${e.user_id == sessionStorage.getItem('user_id') ? 'start' : 'start'}`, border: `${e.user_id == sessionStorage.getItem('user_id') ? '2px solid rgb(0, 132, 255)' : '2px solid rgb(255, 255, 255)'}` }}>
                                    {e.user_name}
                                </div>
                                <div className='chatTextText'>
                                    {commentLines.map((line, i) => {

                                        let temp = line.split('*')
                                        let data = [];
                                        if (line.includes('*')) {
                                            temp.length > 0 && temp.map((e, index) => {
                                                let check = onlineData.filter((f) => { return e == f.online_name })
                                                if (check.length > 0) {
                                                    data.push('@' + check[0].online_name);
                                                } else {
                                                    data.push(' ' + e + ' ')
                                                }
                                            })
                                        } else {
                                            data.push(' ' + line + ' ')
                                        }

                                        return (<>
                                            <span key={i}>
                                                {data.map((word, j) => {

                                                    return (<>
                                                        <span key={j} style={{ color: word.trim() === data[j] ? '#67EDFF' : 'white', textDecoration: word.trim() === data[j] ? 'underline' : 'none' }}>
                                                            {word}
                                                            {j < data.length - 1 && ' '}
                                                        </span>
                                                    </>)
                                                })}
                                                {i < commentLines.length - 1 && <br />}
                                            </span>
                                        </>)
                                    })}

                                </div>
                                <div className='chatTextDate'>
                                    {`${moment(e.create_date).format("D-MMM HH:mm")}`}
                                    <i class="fa fa-edit editChat"
                                        onClick={() => {
                                            // console.log("check E",e);
                                            
                                            setSendText({ ...sendText, onEdit: true, editId: e.cp_id, editName: e.user_name, editDate: e.edit_date, updateDate: e.create_date, editText: e.user_text, editTextChang: e.user_text })
                                            sendText.onEdit = true
                                            sendText.editId = e.cp_id
                                            sendText.editName = e.user_name
                                            sendText.editDate = e.edit_date
                                            sendText.updateDate = e.create_date
                                            sendtext.editText = e.user_text
                                            sendtext.editTextChang = e.user_text
                                            sendText.Index = index
                                        }}
                                        style={{ display: `${e.user_id == sessionStorage.getItem('user_id') ? '' : 'none'}` }}></i>
                                </div>
                            </div>
                        </>
                        )
                    })}
     
                </div>
                {/* {isLoadingChat && <div style={{}}><span style={{}}>Loading more data...</span></div>} */}
                <div className='chatBoxFooter'>
                    <textarea type='text'
                        id="chatingSend"
                        onKeyDown={(e) => {
                            if (e.shiftKey && e.keyCode === 13) {

                            } else {
                                if (e.keyCode == 13 || e.keyCode == '13') {
                                    e.preventDefault();
                                    if (sendText.text != null && sendText.text != undefined && sendText.text != '' && sendText.text.trim() != '') {
                                        sendChatData()
                                    }
                                }
                            }

                        }}
                        className='chatBoxSendText'
                        placeholder="Input text...."
                        value={sendText.text}

                        onChange={(e) => {
                            setSendText({ ...sendText, text: e.target.value })
                            sendText.text = e.target.value
                            auto_grow()
                        }}>
                    </textarea>
                    <div className='chatBoxSendBox' onClick={() => {
                        if (sendText.text != null && sendText.text != undefined && sendText.text != '' && sendText.text.trim() != '') {
                            sendChatData()
                        }
                    }}>Send Promt</div>
                </div>

            </div>
            <div className='editChatBox' style={{ display: `${sendText.onEdit ? '' : 'none'}` }}>
                <div className='riskIssueEditBox'>
                    <div className='riskIssueEditCreateName'>Create by : {sendText.editName}</div>
                    <div className='riskIssueEditCreateDate'>Create date : {moment(sendText.editDate).format("DD-MM-yyyy HH:mm:ss")}</div>
                    <div className='riskIssueEditCreateDate'>Last update : {sendText.updateDate === sendText.editDate ? '-' : `${moment(sendText.updateDate).format("DD-MM-yyyy HH:mm:ss")}`}</div>
                    <textarea type='text'
                        id='editChatText'
                        className='riskIssueEditComment'
                        // placeholder={editData.pri_comment}
                        value={sendText.editTextChang}
                        onChange={(e) => {
                            setSendText({ ...sendText, editTextChang: e.target.value })
                            sendText.editTextChang = e.target.value
                            auto_area()
                        }}
                    >

                    </textarea>
                    <div className='row riskIssueEditMenu'>
                        <div className='col-6' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditRefresh" onClick={() => {
                                editRefresh()
                            }}>refresh</div>
                        </div>
                        <div className='col-3' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditSubmit" onClick={() => {
                                editSubmit()
                            }}>SUBMIT</div>
                        </div>
                        <div className='col-3' style={{ margin: '0', padding: '0' }}>
                            <div className="riskIssueEditSubmit" onClick={() => {
                                editClose()
                            }}>CLOSE</div>
                        </div>

                    </div>
                </div>
            </div>
            <div className={sendText.onOnline ? 'onlineBoxCenter' : 'onlineBox'} style={{ display: `${isOpen ? '' : 'none'}`, height: `${sendText.onOnline ? '150px' : '50px'}`, paddingTop: `${onlineData.length > 9 ? '3px' : ''}` }}>
                <div className='setSlider' style={{ display: `${sendText.onOnline ? '' : 'none'}` }}>
                    <Slider ref={sliderRef} {...settings}>
                        {onlineData.map((e) => {
                            if (sendText.onOnline) {
                                return (
                                    <>
                                        <div className='onlineUserBox'>
                                            <div className='onlineUserName'>{e.online_name}</div>
                                            <div className='onlineUserImgSet' style={{ backgroundImage: `url(${e.online_img !== null ? `${e.online_img}` : profileImg})`, filter: `${e.online_is ? '' : 'grayscale(100%)'}` }}></div>


                                        </div>

                                    </>
                                )
                            } else {
                                return (
                                    <></>
                                )
                            }
                        })}
                    </Slider>
                </div>
                <div className='onlineCircle' style={{ display: `${sendText.onOnline ? 'none' : ''}` }}></div>
                <div className='onlineText' style={{ display: `${sendText.onOnline ? 'none' : ''}` }}>Online Member ({
                    onlineData.filter((e) => {
                        return e.online_is === true
                    }).length
                }/{onlineData.length})</div>
            </div>
            <div className='onlineSetTab' style={{ display: `${isOpen ? '' : 'none'}`, top: `${sendText.onOnline ? '305px' : '205px'}` }}
                onClick={() => {
                    setSendText({ ...sendText, onOnline: !sendText.onOnline })
                    sendText.onOnline = !sendText.onOnline
                }}>{sendText.onOnline ? '-' : '+'}</div>
            <div className='searchListBox' style={{ display: `${searchResult.isOpen && isOpen && sendText.onSearch ? '' : 'none'}` }} >
                {searchResult.data.map((e) => {

                    return (
                        <div className='searchListText' onClick={() => {
                            if (searchResult.isOpen && isOpen && sendText.onSearch) {

                                let temp = chatingData.filter((f) => { return f.cp_id == e.cp_id })
                                searchSetScroll(temp[0].cp_index)
                            }
                        }}>{e.user_text}</div>
                    )

                })}
            </div>
            {sendText.text != undefined ?
                renderNames() && isOpen && !sendText.onEdit && <div className='chatTagBox'>
                    {renderNames()}
                </div>
                : <></>
            }
            {sendText.editTextChang != undefined ?
                renderNamesEdit() && isOpen && sendText.onEdit && <div className='chatTagBoxEdit'>
                    {renderNamesEdit()}
                </div>
                : <></>
            }
        </>
    )
}

export default Chating;
