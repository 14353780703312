import React, { useEffect, useState, useRef } from "react";
import {
  Input,
  Space,
  Tag,
  theme,
  Tooltip,
  ConfigProvider,
  DatePicker,
  Modal,
  Select,
  Checkbox,
} from "antd";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import moment from "moment";
import dayjs from "dayjs";
import { ExclamationCircleFilled } from "@ant-design/icons";
import Configs from "../../../../config";
import IconReport from "../../../../assets/img/IconStage4/reportSandMail.svg";
import './MomAttendant/ModalProcessSrs.css'
// import '..//processMockup.css'
import reportmock from "../../../component/Report/reportmock";
import { generateUUID } from "three/src/math/MathUtils.js";
import styled from "styled-components";
import { label } from "yet-another-react-lightbox";
import { uniqueSort, valHooks } from "jquery";
import { dataURLFileLoader } from "react-doc-viewer";
const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { TextArea } = Input;
function ModalProcessMockup({
  isOpen,
  toggle_modal_process_srs,
  toggle_modal_process_mockup_view_version,
  open_descpription_image,
  close_descpription_image,
  project_id,
  menu_id,
  ModuleDetails,
  status,
  CalPosition,
  setID,
  toggle_modal_status,
  actual_progress,
  callbackDataMockupVersion,
  callbackDataMockupDescription,
}) {
  const [stageMonitoringView, setStageMonitoringView] = useState([]);
  const [filter, setFilter] = useState({
    mennu_id: [],
    element_id: [],
  });
  //   const [stageMonitoringDataReport, setStageMonitoringDataReport] = useState([])
  const [menuDropdownList, setMenuDropdownList] = useState([]);
  const [elementDropdownList, setElementDropdownList] = useState([
    { label: "Function", value: "ef1f06e3-f358-40ca-abcb-bca3469b2d92" },
    { label: "Non - Function", value: "12270801-2393-46a0-9f3b-1ec67bc9c54a" },
  ]);
  const [userInProjectOption, setUserInProjectOption] = useState([]);
  const [grandTotal, setGandTotal] = useState({
    grandTotal: 0,
    checked: 0,
  });
  function handleChangeElement(value) {
    setFilter({ ...filter, element_id: value });
    filter.element_id = value;
    getDataElementSRS();
    // console.log(`selected element`,value);
  }
  function handleChangeMenu(value) {
    setFilter({ ...filter, mennu_id: [value] });
    filter.mennu_id = [value];
    getDataElementSRS();
    // console.log(`selected Menu`,value);
  }
  const { Option } = Select;
  const [openModalSendMail, setOpenModalSendMail] = useState(false);
  const togle_modal_send_mail = () => setOpenModalSendMail(!openModalSendMail);
  let [projectDetail, setProjectDetail] = useState([]);
  function clearFilter() {
    setFilter({
      mennu_id: [],
      element_id: [],
    });
    filter.mennu_id = [];
    filter.element_id = [];
    console.log("filter Cllear", filter);
  }
  const [setHeightDiv, setSetHeightDiv] = useState({
    textAreaHeight: 50,
  });
  const [processStage, setProcessStage] = useState([
    "f00e63f2-ab27-4828-9d7a-f9316c28703e",
    "4ded34bc-1d60-4512-b0ad-8c8daf16c0d8",
    "6e6f2408-6a06-4f93-8f0a-aa385cfc4188",
  ]);
  const iconStyle = {
    color: "rgb(36, 191, 31)", // กำหนดสี
    fontSize: "22px", // กำหนดขนาด
    textShadow: "-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff",
    filter: "drop-shadow(0px 0px 1.5px #fff)",
    marginTop: "2px",
    // เพิ่ม style อื่น ๆ ตามต้องการ
  };

  const iconStyleNo = {
    color: "rgb(255, 0, 0)", // กำหนดสี
    fontSize: "22px", // กำหนดขนาด
    textShadow: "-1px 0 #fff, 0 1px #fff, 1px 0 #fff, 0 -1px #fff",
    filter: "drop-shadow(0px 0px 1.5px #fff)",
    marginTop: "2px",
    // เพิ่ม style อื่น ๆ ตามต้องการ
  };

  const MyP = styled.p`
    font-size: 15px;
    font-weight: 500;
    color: rgb(203, 203, 203);
    margin-bottom: 0px;
  `;

  function getUserInProject(project_id_) {
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/userprofile/getUserInProject/${project_id_}`,
      headers: {
        //Authorization: getToken(),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(function (response) {
        if (response.data) {
          let option = [];
          for (let item of response.data) {
            let temp = {
              label: item.userTTT.usr_name + " " + item.userTTT.usr_lastname,
              value: item.userTTT.usr_name + " " + item.userTTT.usr_lastname,
            };
            option.push(temp);
          }

          setUserInProjectOption(option);
        }
      })
      .catch(function (error) {
        console.log(error);
        // setErrorMessage(error.response.data.error[0].errorDis);
      });
  }

  async function getProjectDetail(id) {
    await axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/project/GetProjectDetail/${id}`,
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        setProjectDetail(response.data);
        projectDetail = response.data;
        settest({ ...test, test: "" });
        test.test = "";
        // console.log("response.data", response.data);
      })
      .catch(function (error) {
        console.log(error);
      });

    // console.log("projectDetail---<>", projectDetail);
    // console.log(JSON.parse(tempData.request?.response) );

    // setProjectDetail(JSON.parse(tempData.request?.response))
    // settest({ ...test, test: "" });
    // test.test = "";
    // return JSON.parse(tempData.request?.response);

    // tempData.then(async function (response) {

    //     setProjectDetail(response.data)

    //     console.log("response.data",response.data);
    //   })
    // .catch(function (error) {
    //   console.log(error);
    // });
  }
  //   const [statusName, setStatusName] = useState('')
  //   const [statusID, setStatusID] = useState('')
  //   const [displayProgress, setDisplayProgress] = useState({
  //     actual: 0,
  //     plan: 0,
  //   })
  //   const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });
  //   const [alertKick, setAlertKick] = useState(false);
  //   const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  useEffect(async () => {
    // console.log("open");
    /*  setErrorMessage(''); */
    /*    setModuleDetail({...ModuleDetail,module_name:''});
         ModuleDetail.module_name= ''; */
    /*    console.log('ModuleDetails',ModuleDetails)
         const temp =ModuleDetails || {}
         setModuleDetail(temp); */
    if (isOpen == true) {
      //   console.log('projectDetail', projectDetail)
      //   let status_id = '6e6f2408-6a06-4f93-8f0a-aa385cfc4188'
      //   setStatusID(status_id)
      //   setStatusName('DONE')
      getUserInProject(project_id);
      await getProjectDetail(project_id);

      // console.log("projectDetail.moduleList", projectDetail.moduleList);
      let dataTemp = [];
      for (let item of projectDetail.moduleList) {
        for (let item2 of item.module_menu_list) {
          let check = item2.process_list.filter((e) => {
            return (
              (e.process_id == "23b99e02-cfeb-4808-a5ed-99540ae2d806" &&
                e.ps_id == "f00e63f2-ab27-4828-9d7a-f9316c28703e") ||
              e.ps_id == "4ded34bc-1d60-4512-b0ad-8c8daf16c0d8" ||
              e.ps_id == "6e6f2408-6a06-4f93-8f0a-aa385cfc4188"
            );
          });
          if (check.length > 0) {
            if (menu_id == item2.menu_id) {
              dataTemp.push({
                /*        project_id: item.project_id,
                                   project_spaceship_id: item.project_spaceship_id, */
                module_name: item.module_name,
                module_id: item.module_id,
                menu_name: item2.menu_name,
                menu_id: item2.menu_id,
                process_list: item2.process_list || [],
                mockup_color: check[0].ps_color,
              });
            }

          }
        }
      }

      // console.log("projectDetail", dataTemp);
      let menu_tempfilter = dataTemp.map((item) => item.menu_id);
      let element_tempfilter = elementDropdownList.map((item) => item.value);

      console.log(menu_tempfilter);
      setFilter({
        ...filter,
        mennu_id: menu_tempfilter,
        element_id: element_tempfilter,
      });
      filter.mennu_id = menu_tempfilter;
      filter.element_id = element_tempfilter;
      getDataElementSRS();

      let menu_temp = dataTemp.map((item) => ({
        value: item.menu_id,
        label: item.menu_name,
      }));
      setMenuDropdownList(menu_temp);
    } else {
      // document.removeEventListener("mousemove", onMouseUpdate, false);
      //   close_descpription_image();
    }
  }, [isOpen]);

  const [test, settest] = useState({ test: "" });

  function changeProcessStage(status_id) {
    console.log(status_id);
    let temp = processStage || [];
    let check = temp.filter((e) => {
      return e == status_id;
    });
    if (check.length > 0) {
      let index = temp.findIndex((e) => e == status_id);
      if (index > -1) {
        temp.splice(index, 1);
      }
    } else if (check.length == 0) {
      temp.push(status_id);
    }

    let dataTemp = [];
    let checkYellow = temp.filter((e) => {
      return e == "f00e63f2-ab27-4828-9d7a-f9316c28703e";
    });
    let checkRed = temp.filter((e) => {
      return e == "4ded34bc-1d60-4512-b0ad-8c8daf16c0d8";
    });
    let checkGreen = temp.filter((e) => {
      return e == "6e6f2408-6a06-4f93-8f0a-aa385cfc4188";
    });
    for (let item of projectDetail.moduleList) {
      for (let item2 of item.module_menu_list) {
        let check = item2.process_list.filter((e) => {
          return (
            e.process_id == "23b99e02-cfeb-4808-a5ed-99540ae2d806" &&
            ((checkYellow.length > 0 &&
              e.ps_id == "f00e63f2-ab27-4828-9d7a-f9316c28703e") ||
              (checkRed.length > 0 &&
                e.ps_id == "4ded34bc-1d60-4512-b0ad-8c8daf16c0d8") ||
              (checkGreen.length > 0 &&
                e.ps_id == "6e6f2408-6a06-4f93-8f0a-aa385cfc4188"))
          );
        });
        if (check.length > 0) {
          if (menu_id == item2.menu_id) {
            dataTemp.push({
              module_name: item.module_name,
              module_id: item.module_id,
              menu_name: item2.menu_name,
              menu_id: item2.menu_id,
              process_list: item2.process_list || [],
              mockup_color: check[0].ps_color,
            });
          }
        }
      }
    }
    // console.log("projectDetail", dataTemp);
    setFilter({ ...filter, mennu_id: dataTemp });
    filter.mennu_id = dataTemp;
    getDataElementSRS();
    //setStageMonitoringView(dataTemp)

    console.log(temp);
    setProcessStage(temp);
    settest({ ...test, test: "" });
    test.test = "";
  }

  function getDataElementSRS(mode) {
    let dataTemp = [];
    let dataTemp2 = [];
    for (let item of projectDetail.moduleList) {
      for (let item2 of item.module_menu_list) {
        let check = item2.process_list.filter((e) => {
          return (
            e.process_id == "23b99e02-cfeb-4808-a5ed-99540ae2d806" &&
            (e.ps_id == "f00e63f2-ab27-4828-9d7a-f9316c28703e" ||
              e.ps_id == "4ded34bc-1d60-4512-b0ad-8c8daf16c0d8" ||
              e.ps_id == "6e6f2408-6a06-4f93-8f0a-aa385cfc4188")
          );
        });
        if (check.length > 0) {
          if (menu_id == item2.menu_id) {
            dataTemp.push({
              /*        project_id: item.project_id,
                                 project_spaceship_id: item.project_spaceship_id, */
              module_name: item.module_name,
              module_id: item.module_id,
              menu_name: item2.menu_name,
              menu_id: item2.menu_id,
              process_list: item2.process_list || [],
              mockup_color: check[0].ps_color,
            });
          }
        }
      }
    }

    let menu_tempfilter = dataTemp.map((item) => item.menu_id);
    // console.log("menu_tempfilter => ",menu_tempfilter,"filter.mennu_id.length",filter.mennu_id.length,"dataTemp",dataTemp);
    let temp = {
      project_id: project_id,
      menu_list: !(
        (filter.mennu_id.length == 1 && filter.mennu_id[0] == undefined) ||
        filter.mennu_id.length == 0
      )
        ? filter.mennu_id
        : menu_tempfilter,
      process_id: "23b99e02-cfeb-4808-a5ed-99540ae2d806",
      element_list: filter.element_id,
    };

    // console.log("temp==>", temp);
    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/element/getDataElementSRS`,
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(function (response) {
        if (response.data) {
          let checked = response.data.filter((el) => {
            return el.sm_is_customer == true;
          });
          // let calGrantotal = {
          //   Total: response.data.length,
          //   checked: checked.length ,
          // }
          setGandTotal({
            ...grandTotal,
            grandTotal: response.data.length,
            checked: checked.length,
          });
          grandTotal.grandTotal = response.data.length;
          grandTotal.checked = checked.length;

          // console.log("calGrantotal",calGrantotal);
          let filterBymenu = dataTemp.filter((el) => {
            if (filter.mennu_id.includes(el.menu_id)) {
              return el;
            }
          });
          let array_fordata = null;
          // console.log("filterBymenu",filterBymenu);
          if (
            !(
              (filter.mennu_id.length == 1 &&
                filter.mennu_id[0] == undefined) ||
              filter.mennu_id.length == 0
            )
          ) {
            for (let item of filterBymenu) {
              let check = response.data.filter((e) => {
                return item.menu_id == e.menu_id;
              });

              item.grandTotal = {
                grandTotal: check.length,
                checked: check.filter((el) => {
                  return el.sm_is_customer == true;
                }).length,
              };
              item.srs_data = check.length > 0 ? check : [];
              item.assignTo = [];
            }
            array_fordata = true;
          } else {
            for (let item of dataTemp) {
              let check = response.data.filter((e) => {
                return item.menu_id == e.menu_id;
              });

              item.grandTotal = {
                grandTotal: check.length,
                checked: check.filter((el) => {
                  return el.sm_is_customer == true;
                }).length,
              };

              item.srs_data = check.length > 0 ? check : [];
              item.assignTo = [];
            }
            array_fordata = false;
          }

          console.log("dataTemp", filterBymenu);
          setStageMonitoringView(
            array_fordata != null && array_fordata ? filterBymenu : dataTemp
          );
          settest({ ...test, test: "" });
          test.test = "";
          /*  setErrorMessage('');
            setModuleDetail({});

            toggle_modal_config_module();
            getProjectDetail(project_id) */
        }
      })
      .catch(function (error) {
        console.log(error);
        /*  setErrorMessage(error.response.data.error[0].errorDis) */
      });
  }

  function setDatatoSave(value, sm_id, menu_id, textAreaHeight) {
    // console.log("setDatatoSave",value);
    let temOnchange = stageMonitoringView;

    // setStageMonitoringView([]);

    for (const item of temOnchange) {
      if (menu_id == item.menu_id) {
        console.log("item", item);
        for (const item2 of item.srs_data) {
          if (item2.sm_id === sm_id) {
            let countValue = textSprit(value, 50);
            let countLen = textSprit(item2.sm_srs_remark || '', 50);

            item2.sm_cus_remark = value;
            // if ((value.match(/\n/g) || '').length >0) {
            //   // console.log("test");
            //   item2.textAreaHeight = 32+ ((value.match(/\n/g) || '').length *22)
            // }else{
            //   item2.textAreaHeight = 32;
            // }
            // if (item2.textAreaHeight < textAreaHeight) {
            //   item2.textAreaHeight = 32 + textAreaHeight;
            // } else if ((countValue[1] * 22) > textAreaHeight) {
            //   item2.textAreaHeight = 22 + textAreaHeight;
            // } 
              item2.textAreaHeight = textAreaHeight;
          }
        }
      }
    }
    setStageMonitoringView(temOnchange);

    settest({ ...test, test: "" });
    test.test = "";
    // console.log("after onch", temOnchange);
  }
  // const onChange: CheckboxProps['onChange'] = (e) => {
  //   console.log(e.target.checked);
  // };

  // function onChangecheck(value, sm_id, menu_id) {
  //   let temOnchange = stageMonitoringView;

  //   // setStageMonitoringView([]);
  //   let countCheckAll = 0;
  //   for (const item of temOnchange) {
  //     if (menu_id == item.menu_id) {
  //       console.log("onChangecheck item", item);
  //       for (const item2 of item.srs_data) {
  //         if (item2.sm_id === sm_id) {
  //           item2.sm_is_customer = value;
  //         }
  //       }

  //       item.grandTotal = {
  //         grandTotal: item.srs_data.length,
  //         checked: item.srs_data.filter((el) => {
  //           return el.sm_is_customer == true;
  //         }).length,
  //       };

  //     }
  //     countCheckAll += item.srs_data.filter((el) => {
  //       return el.sm_is_check == true;
  //     }).length
  //   }

  //   setGandTotal({
  //     ...grandTotal,
  //     checked: countCheckAll,
  //   });
  //   grandTotal.checked = countCheckAll;
  //   setStageMonitoringView(temOnchange);

  //   settest({ ...test, test: "" });
  //   test.test = "";
  //   console.log(" onChangecheck after onch", temOnchange);
  // }


  const allSrsData = stageMonitoringView.reduce((acc, curr) => {
    return acc.concat(curr.srs_data);
  }, []);

  const isAllCustomer = allSrsData.every((el) => el.sm_is_customer);

  const CheckAll =(value,data) => {
    let temOnchange = [...stageMonitoringView];

    let _filter = temOnchange.filter((el) => el.menu_id === data.menu_id && el.module_id === data.module_id)
    if(_filter.length > 0){
      let count = 0
      for(let item of _filter){
        for(let item2 of item.srs_data){
          item2.sm_is_customer = value
        };

        item.grandTotal = {
          grandTotal: item.srs_data.length,
          checked: item.srs_data.filter((el) => {
            return el.sm_is_customer === true;
          }).length,
        };
        
        count += item.srs_data.filter((el) => {
          return el.sm_is_customer === true;
        }).length
      };


      setGandTotal({
        ...grandTotal,
        checked: count,
      });
      grandTotal.checked = count;
      
      setStageMonitoringView(_filter);
  
      settest({ ...test, test: "" });
      test.test = "";
    };
  };


  function onChangecheck(value, sm_id, menu_id) {
    let temOnchange = stageMonitoringView;
    // setStageMonitoringView([]);
    let countCheckAll = 0;
    for (const item of temOnchange) {
      if (menu_id == item.menu_id) {
        // console.log("onChangecheck item", item);
        for (const item2 of item.srs_data) {
          if (item2.sm_id === sm_id) {
            item2.sm_is_customer = value;
          }
        }

        // let checked = item.srs_data.filter((el) => {
        //   return el.sm_is_check == true;
        // });
        // console.log(checked);
        // console.log(item.srs_data.filter((el) => {
        //   return el.sm_is_check == true;
        // }).length);
        item.grandTotal = {
          grandTotal: item.srs_data.length,
          checked: item.srs_data.filter((el) => {
            return el.sm_is_customer == true;
          }).length,
        };

        // console.log("grandTotal",grandTotal);
      }
      countCheckAll += item.srs_data.filter((el) => {
        return el.sm_is_customer == true;
      }).length
    }
    // console.log("countCheckAll",countCheckAll);
    setGandTotal({
      ...grandTotal,
      checked: countCheckAll,
    });
    grandTotal.checked = countCheckAll;

    // console.log('temOnchange', temOnchange)
    setStageMonitoringView(temOnchange);

    settest({ ...test, test: "" });
    test.test = "";
    // console.log(" onChangecheck after onch", temOnchange);
  }

  const [position, setPosition] = useState({
    x: null,
    y: null,
  });
  // /* var x = null;
  // var y = null; */

  // function onMouseUpdate(e) {
  //   setPosition({ ...position, x: e.pageX, y: e.pageY });

  //   position.x = e.pageX;
  //   position.y = e.pageY;
  //   CalPosition(e.pageX + 10, e.pageY + 10);
  //   //console.log(x, y);
  // }

  //   function reportMockup(){
  //     let temp = {
  //       project_id:projectDetail.project_id,
  //     }
  //     // axios({
  //     //   method: 'post',
  //     //   url: `${Configs.TTT_Game_Service}/api/report/reportMockup`,
  //     //   headers: {
  //     //     Authorization: sessionStorage.getItem('access_token'),
  //     //     'X-TTT': `${Configs.API_TTT}`,
  //     //     'Content-Type': 'application/json',
  //     //   },
  //     //   data: temp

  //     // })
  //     //   .then(function (response) {
  //     //     if (response.data) {
  //     //       let callback = reportmock(stageMonitoringView,response.data)

  //     //       toggle_modal_alertKick()
  //     //       setAlertKick(false)
  //     //     }

  //     //   })
  //     //   .catch(function (error) {
  //     //     console.log(error);
  //     //    /*  setErrorMessage(error.response.data.error[0].errorDis) */

  //     //   });
  //   }
  const [dataAssignOpen, setDataAssignOpen] = useState({
    dataOpen: {},
    dataOnchanged: [],
  });
  function OpenAssignTo(sm_id, menu_id) {
    let findData = stageMonitoringView.filter((el) => {
      return el.menu_id == menu_id;
    });
    if (findData.length > 0) {
      let fineSm = findData[0].srs_data.filter((ele) => {
        return ele.sm_id === sm_id;
      });

      let setData = fineSm[0].assignto.map((el) => {
        return el.pf_usr_id;
      });
      console.log("setData", setData);
      setDataAssignOpen({
        ...dataAssignOpen,
        dataOpen: fineSm[0],
        dataOnchanged: setData,
      });
      dataAssignOpen.dataOnchanged = setData;
      dataAssignOpen.dataOpen = fineSm[0];
      // console.log(fineSm);
    }

    // dataAssignOpen
    checkTeamView();
    toggle_modal_assignToPopup();
  }
  function updateDataToSave(id) {
    console.log("stageMonitoringViewTosave", stageMonitoringView);
    for (const item of stageMonitoringView) {
      if (item.srs_data.length > 0) {
        for (const i of item.srs_data) {
          if (i.sm_cus_remark != null && i.sm_cus_remark != '') {
            const trimText = i.sm_cus_remark.trim();
            i.sm_cus_remark = trimText;
          }
        }
      }
    }

    axios({
      method: "post",
      url: `${Configs.TTT_Game_Service}/api/element/getDataElementSRSSaveCus/${id}`,
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: stageMonitoringView,
    })
      .then(function (response) {
        toggle_modal_process_srs();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [assignToPopup, setAssignToPopup] = useState(false);
  const toggle_modal_assignToPopup = () => {
    setAssignToPopup(!assignToPopup);
  };
  const [assignToSend, setAssignToSend] = useState({
    text: null,
  });

  const [teamViewData, setTeamViewData] = useState([]);
  async function checkTeamView() {
    await axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/project/GetTeamView/${project_id}`,
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      // data: temp,
    })
      .then(async function (response) {
        // console.log("testlog res", response.data);
        if (response.data.check === true) {
          let temp = response.data?.users.map((el) => {
            return {
              label: `${el.usr_name} ${el.usr_lastname} `,
              value: el.pf_usr_id,
            };
          });
          // console.log(temp);
          setTeamViewData(temp);
          // toggle_teamViewPopUp()
          // console.log('testlog', teamViewData);
        }
        // else if (response.data.check === false) {
        //   toggle_teamViewPopUpEr()
        // }
      })
      .catch(function (error) {
        console.log(error);
      });
  }
  function sendAssignData() {
    let temOnchange = stageMonitoringView;

    // setStageMonitoringView([]);
    // console.log("dataAssignOpen save",dataAssignOpen);
    for (const item of temOnchange) {
      // console.log("log0");
      if (dataAssignOpen.dataOpen.menu_id == item.menu_id) {
        // console.log("log1");
        for (const item2 of item.srs_data) {
          if (item2.sm_id === dataAssignOpen.dataOpen.sm_id) {
            // console.log("log2");
            item2.assignto = dataAssignOpen.dataOnchanged;
          }
        }
      }
    }
    setStageMonitoringView(temOnchange);

    settest({ ...test, test: "" });
    test.test = "";
    // console.log("after onch", temOnchange);
    // toggle_modal_assignToPopup();
    toggle_modal_assignToPopup();
  }
  function textSprit(text, cut) {
    let _commentLines = [];
    let count = 0;
    for (let index = 0; index < text.length; index += cut) {
      _commentLines.push(text.substring(index, index + cut));
      count++
    }
    return [_commentLines, count]
  }
  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            /*     colorPrimary: '#85BFFF', */
            colorInfo: "#85BFFF",
            colorBorder: "#85BFFF",
            colorText: "#FFF",

            colorTextDescription: "#000",
            // colorBgContainer: "#000",
            colorBgContainer: "#185AA3",

            zIndexPopupBase: 9999999,
            colorPrimaryBg: "blue", //สีตอน Active ใน Select
            colorBgBase: "#778899", //พื้นหลัง select ตอนกด down ลงมา

            colorTextQuaternary: "#FFFFFF", // สีตัวหนังสือของ placeholder

            colorFillTertiary: "#000", // สีตอน hover ใน Select

            colorTextBase: "blue", // ลูกศรใน datepicker & rangepicker

            colorPrimaryBg: "#85BFFF",
            /*       
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */
          },
        }}
      >
        <div
          className={`backdropModuleMenu ${isOpen ? "opened" : "closed"}`}
          onClick={() => {
            // if (openModalSendMail !== true) {
            toggle_modal_process_srs();
            clearFilter();
            // }
            // console.log("Click");
          }}
          style={{ zIndex: "99999" }}
        ></div>
        <div
          className={`detailBoxModuleMenu ${isOpen ? "opened" : "closed"}`}
          style={{
            top: `30%`,
            left: `50%`,
            position: "fixed",
            transform: "translate(-50%, -22%)",
            width: "80%",
            height: "80%",
            zIndex: "999999",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              margin: 0,
              padding: "20px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              className="row"
              style={{ width: "100%", margin: "0px 0px 10px 0px", padding: 0 }}
            >
              <div style={{display: "flex",alignItems: "end"}} className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-2">
                <h4>TESTING & DEPLOY</h4>{" "}
              </div>

              <div style={{display: "flex",alignItems: "end"}} className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-1">
                <h4>{`"UAT"`}</h4>{" "}
              </div>

              <div
                className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-6"
                style={{ width: "100%" }}
              >
                <h4 style={{ width: "100%", textAlign: "start",fontSize: "14px" }}>
                  FILTER :
                </h4>
                <div style={{display: "flex"}}>
                  <Select
                    mode="multiple"
                    value={filter.element_id}
                    allowClear
                    placeholder="Search to Select Element..."
                    onChange={handleChangeElement}
                    options={elementDropdownList}
                    style={{
                      backgroundColor: "#000",
                      // width: "95%",
                      width: "35%",
                      control: (baseStyles, state) => ({
                        ...baseStyles,

                        "&:hover": {
                          borderColor: "#0047FF",
                          color: "red",
                        },
                        fontSize: "16px",
                      }),
                      option: (
                        styles,
                        { data, isDisabled, isFocused, isSelected }
                      ) => ({
                        ...styles,
                        color: isSelected ? "#fff" : "black", // ตั้งค่าสีตัวหนังสือที่เลือกและไม่เลือก
                        backgroundColor: isSelected ? "#0047FF" : "#fff",
                        textAlign: "start",
                        border: "none !important", // ลบเส้นขีดขั้น
                        "&:hover": {
                          border: "none !important", // ลบเส้นขีดขั้น
                          backgroundColor: isFocused ? "#0047FF" : "#0047FF",
                          color: "#fff",
                        },
                      }),
                    }}
                  />

                </div>
              </div>

              {/* <div
                className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                style={{ width: "100%" }}
              >
                <Select
                  allowClear
                  showSearch
                  // value={filter.mennu_id}
                  onChange={handleChangeMenu}
                  options={menuDropdownList}
                  style={{
                    backgroundColor: "#000",
                    width: "95%",
                    control: (baseStyles, state) => ({
                      ...baseStyles,

                      "&:hover": {
                        borderColor: "#0047FF",
                        color: "red",
                      },
                      fontSize: "16px",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => ({
                      ...styles,
                      color: isSelected ? "#fff" : "black", // ตั้งค่าสีตัวหนังสือที่เลือกและไม่เลือก
                      backgroundColor: isSelected ? "#0047FF" : "#fff",
                      textAlign: "start",
                      border: "none !important", // ลบเส้นขีดขั้น
                      "&:hover": {
                        border: "none !important", // ลบเส้นขีดขั้น
                        backgroundColor: isFocused ? "#0047FF" : "#0047FF",
                        color: "#fff",
                      },
                    }),
                  }}
                  placeholder="Search to Select Menu..."
                />
              </div> */}
              {/* <div
                className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                style={{ width: "100%" }}
              >
                <Select
                  mode="multiple"
                  value={filter.element_id}
                  allowClear
                  placeholder="Search to Select Element..."
                  onChange={handleChangeElement}
                  options={elementDropdownList}
                  style={{
                    backgroundColor: "#000",
                    width: "95%",
                    control: (baseStyles, state) => ({
                      ...baseStyles,

                      "&:hover": {
                        borderColor: "#0047FF",
                        color: "red",
                      },
                      fontSize: "16px",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => ({
                      ...styles,
                      color: isSelected ? "#fff" : "black", // ตั้งค่าสีตัวหนังสือที่เลือกและไม่เลือก
                      backgroundColor: isSelected ? "#0047FF" : "#fff",
                      textAlign: "start",
                      border: "none !important", // ลบเส้นขีดขั้น
                      "&:hover": {
                        border: "none !important", // ลบเส้นขีดขั้น
                        backgroundColor: isFocused ? "#0047FF" : "#0047FF",
                        color: "#fff",
                      },
                    }),
                  }}
                />
              </div> */}


              <div
                className="col-2 col-sm-2 col-md-2 col-lg-2 col-xl-2"
                style={{ width: "100%" ,display: "flex",alignItems: "end", padding: 0 , margin: 0}}
              >
                <h4 style={{ width: "130%", textAlign: "end", padding: 0 , margin: 0}}>
                  GRAND TOTAL :{" "}
                  {`${grandTotal.checked} / ${grandTotal.grandTotal}`}
                </h4>
              </div>
              <div
                style={{
                  position: "relative",
                  top: "-6px",
                  cursor: "pointer",
                  right: "-103px",
                  width: "20px",
                  height: "20px",
                  textAlign: "center",
                  fontSize: "20px",
                  fontWeight: "bolder"
                }}
                onClick={() => {
                  toggle_modal_process_srs();
                  clearFilter();
                }}
              >
                X
              </div>

            </div>
            <div style={{ height: "20px", borderTop: "1px solid #FFF" }}></div>
            <div
              className="table-responsive"
              style={
                {
                  // border: '1px solid red'
                  /*  height: '35px' */
                }
              }
            >

              {stageMonitoringView.length > 0 &&
                stageMonitoringView.map((el, index) => {
                  // console.log(`ELLLLLLLLLLLLLLLLLLLLLL [${index}] : `, el);
                  return (
                    <>
                      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ border: '0px solid blue', display: 'flex', maxWidth: '360px', justifyContent: 'space-between', width: '100%' }}>
                          <div 
                            style={{
                              marginTop: "5px"
                            }}
                          >
                            <MyP>{`[MODULE] >> [MENU]`}</MyP>
                            <MyP style={{ textTransform: 'uppercase', color: 'white', paddingTop: "5%", }}>{`${el.module_name} >> ${el.menu_name}`}</MyP>
                            <MyP>{`TOTAL : ${el.grandTotal.checked} / ${el.grandTotal.grandTotal}`}</MyP>
                          </div>
                          <div style={{ width: '105px', border: '0px solid green',marginTop: "5px"}}>
                            {/* <MyP>SRS</MyP> */}
                            <div style={{ padding: "1px", width: '50%', /* background: "#FFEB3B",color: "black",borderRadius: "6px", */textAlign: "center",fontWeight: 700}}>SRS</div>
                            <div
                              className="processColor"
                              style={{
                                verticalAlign: "top",
                                paddingTop: "25%",
                              }}
                            >
                              <button
                                style={{
                                  clipPath:
                                    "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)",
                                  width: "50px",
                                  height: "10px",
                                  background: el.mockup_color,
                                  border: "0px",
                                }}
                              >
                                <button
                                  onClick={(ee) => { }}
                                  style={{
                                    clipPath:
                                      "polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%)",
                                    width: "50px",
                                    height: "10px",
                                    background: el.mockup_color,
                                    border: "0px",
                                  }}
                                ></button>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="table-container">
                          <table className="table_de">
                            <thead>
                              <tr 
                                style={{ 
                                  background: 'linear-gradient(90deg, #00289C 25%, #0047FF 50%, #00289C 75%)',
                                  height: "40px", 
                                  position: 'sticky', 
                                  top: 0, 
                                  zIndex: 1,
                                  borderRadius: "5px"
                                }}
                              >
                                <td 
                                  style={{ 
                                    width: '37px',
                                    height:"40px", 
                                    border: "none",
                                    // background: '#00289C'
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-around",
                                      height: "100%",
                                      alignItems: "center",
                                    }}
                                  >
                                    <ConfigProvider 
                                      theme={{
                                        token: {
                                          colorBorder: '#0047FF',
                                          colorBgContainer: 'none',
                                        }
                                      }}
                                    >
                                      <Checkbox
                                        disabled={el.srs_data.length === 0}
                                        style={{ height: "27.25px" }}
                                        value={el.srs_data.length > 0 ? el.srs_data.every((els) => els.sm_is_customer) : false}
                                        checked={el.srs_data.length > 0 ? el.srs_data.every((els) => els.sm_is_customer) : false}
                                        onChange={(e) => {
                                          CheckAll(e.target.checked , el)
                                        }}
                                      />
                                    </ConfigProvider>
                                  </div>
                                </td>
                                <td 
                                  style={{ 
                                    // maxWidth: '645px', 
                                    // width: '645px', 
                                    paddingLeft: '5px', 
                                    height: "40px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "21px",
                                    fontWeight: 700,
                                    width: "100%",
                                    // background: 'linear-gradient(90deg, rgb(0, 40, 156) 41%, rgb(0, 71, 255) 88%, rgb(0, 40, 156) 300%)'
                                  }}
                                >
                                  DETAIL                          
                                </td>
                                <td 
                                  style={{ 
                                    maxWidth: '350px', 
                                    width: '350px',
                                    height: "40px",
                                    textAlign: "center",
                                    fontSize: "21px",
                                    fontWeight: 700,
                                    // background: 'linear-gradient(90deg, rgb(0, 40, 156) 0%, rgb(0, 71, 255) 0%, rgb(0, 40, 156) 38%)'
                                  }}
                                >
                                  REMARK
                                </td>
                                <td style={{width: "20px"}}></td>
                              </tr>
                            </thead>
                            <br/>
                            <tbody 
                              style={{
                                width: '100%',
                                // overflowY: "auto", 
                                // marginTop: "16px"
                              }}
                              className="fream-2-open-srs"
                            >
                              {el.srs_data.length > 0 &&
                                el.srs_data.map((ele, i) => {
                                  const commentLines = textSprit(ele.sm_comment, 150);
                                  let isFile = [];
                                  let isImage = [];
                                  if (ele.files.length > 0) {
                                    isFile = ele.files.filter((item) => item.ul_type === 'FILE');
                                    isImage = ele.files.filter((item) => item.ul_type === 'IMAGE');
                                  }
                     
                                  return (
                                    <tr 
                                      key={i}
                                      style={{
                                        height: "56px",
                                        // marginTop: "5px"
                                      }}
                                    >
                                      <td style={{
                                        // border: '1px dashed white', 
                                        height: `${ele.sm_cus_remark == '' ? ele.textAreaHeightComment : ele.textAreaHeight }px`,
                                        minHeight: `${ele.textAreaHeightComment}px`,
                                        verticalAlign: "top",
                                        paddingTop: "8px",
                                        width: "37px"
                                      }}>
                                        <div
                                          style={{
                                            display: "flex",
                                            justifyContent: "space-around",
                                            height: "55px",
                                            alignItems: "center",
                                          }}
                                        >
                                          <ConfigProvider 
                                            theme={{
                                              token: {
                                                colorBorder: '#0047FF',
                                                colorBgContainer: 'none',
                                              }
                                            }}
                                          >
                                            <Checkbox
                                              styles={{ height: "27.25px" }}
                                              checked={
                                                ele.sm_is_customer == null
                                                  ? false
                                                  : ele.sm_is_customer
                                              }
                                              onChange={(e) => {
                                                onChangecheck(e.target.checked, ele.sm_id, el.menu_id);
                                              }}
                                            />
                                          </ConfigProvider>
                                        </div>
                                      </td>

                                      <td style={{
                                        paddingLeft: '5px', 
                                        height: `${ele.sm_cus_remark == '' ? ele.textAreaHeightComment : ele.textAreaHeight }px`,
                                        minHeight: `${ele.textAreaHeightComment}px`,
                                        // display: "inline",
                                        // verticalAlign: 'top', 
                                        paddingTop: "8px",
                                        marginTop: "12px",
                                        width: "100%",
                                        display: "flex"
                                      }}>
                                        <div 
                                          style={{ 
                                            display: 'flex',
                                            alignItems: 'flex-start' ,
                                            justifyContent: "start",
                                            height: "40px",
                                            width: "95%",
                                            marginTop: "6px"
                                          }}
                                        >
                                          <MyP style={{ wordWrap: 'break-word', wordBreak: 'break-all', whiteSpace: 'normal', lineHeight: '20px', }}>
                                            {ele.sm_comment}
                                          </MyP>
                                        </div>
                                      </td>
                                          
                                      <td 
                                        style={{
                                          verticalAlign: 'top', 
                                          paddingTop: "8px",
                                          // border: '1px dashed white',
                                          // height: `${ele.sm_cus_remark == '' ? ele.textAreaHeightComment : ele.textAreaHeight }px`,
                                          minHeight: `${ele.textAreaHeightComment}px`,
                                          width: "32%"
                                        }}
                                      >
                                        <div 
                                          style={{ 
                                            // height: '95%',
                                            height: '90%',
                                            width: "92%",
                                            border: '3px solid #0047FF'
                                          }}
                                        >
                                          <ConfigProvider 
                                            theme={{
                                              token: {
                                                colorBorder: '#0047FF',
                                                colorBgContainer: 'none',
                                              }
                                            }}
                                          >
                                            <TextArea
                                              style={{
                                                borderRadius: "0px",
                                                width: '100%',
                                                boxSizing: 'border-box',
                                                border: '0px',
                                                boxShadow: '0 0 0 0',
                                                backgroundColor: '#0A2268',
                                                minHeight: "100%",
                                                // minHeight: `${ele.textAreaHeightComment}px`,
                                                // height: `${ele.sm_cus_remark == '' ? ele.textAreaHeightComment : ele.textAreaHeight }px`,
                                                // zIndex: "9",
                                                // resize: 'none',
                                                // flexGrow: 2,
                                              }}
                                              autoSize={true}
                                              type="text"
                                              // placeholder="PLEASE INPUT YOUR REMARK..."
                                              value={ele.sm_cus_remark}
                                              onChange={(e) => {
                                                setDatatoSave(e.target.value, ele.sm_id, el.menu_id, e.target.clientHeight);
                                              }}
                                            />
                                          </ConfigProvider>
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })
                              }
                            </tbody>
                          </table>
                        </div>
                      </div>
                    <hr />
                  </>
                );
              })}
            </div>
            <div style={{ flexGrow: 1, overflowY: 'auto' }}>
              <div
                className="table-responsive"
                style={{ height: '97%', marginTop: '1%' }}
              >
                <div className="col-12 table-responsive">
                  <table>

                  </table>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                paddingTop: "5px",
              }}
            >
              <button
                className=" btn-outline-primary mr-2"
                style={{ padding: "5px", fontSize: "20px", cursor: "pointer" }}
                onClick={() => {
                  updateDataToSave(project_id);
                  // let checkError = stakeholderList.filter((e)=>{return e.errorMessage && e.errorMessage.trim() !== ''});
                  // if(checkError.length == 0 ){
                  //   updateStakeholder(stakeholderList);
                  // }
                }}
              >
                {" "}
                SAVE{" "}
              </button>
              <button
                className=" btn-outline-danger"
                style={{ padding: "5px", cursor: "pointer" }}
                onClick={() => {
                  toggle_modal_process_srs();
                  clearFilter();
                }}
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
            <div
              className="srsAreaAssignToBg"
              style={{ display: `${assignToPopup ? "" : "none"}` }}
            >
              <div className="srsAreaAssignToBox">
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <button
                    style={{
                      width: "10px",
                      border: "none",
                      background: "none",
                    }}
                    onClick={() => toggle_modal_assignToPopup()}
                  >
                    x
                  </button>
                </div>
                <div className="srsAreaAssignToHeader">ASSIGN TO</div>

                <Select
                  mode="multiple"
                  className="selectcss2"
                  disabled
                  options={teamViewData}
                  placeholder={"Select member to assign..."}
                  value={dataAssignOpen.dataOnchanged}
                  allowClear
                  style={{
                    backgroundColor: "#000",
                    width: "100%",
                    height: "25%",
                    borderColor: "#0047FF !important",
                    control: (baseStyles, state) => ({
                      ...baseStyles,
                      "&:hover": {
                        borderColor: "#0047FF",
                        color: "red",
                      },
                      fontSize: "16px",
                    }),
                    option: (
                      styles,
                      { data, isDisabled, isFocused, isSelected }
                    ) => ({
                      ...styles,
                      color: isSelected ? "#fff" : "black", // ตั้งค่าสีตัวหนังสือที่เลือกและไม่เลือก
                      backgroundColor: isSelected ? "#0047FF" : "#fff",
                      textAlign: "start",
                      border: "none !important", // ลบเส้นขีดขั้น
                      "&:hover": {
                        border: "none !important", // ลบเส้นขีดขั้น
                        backgroundColor: isFocused ? "#0047FF" : "#0047FF",
                        color: "#fff",
                      },
                    }),
                  }}
                  // value={teamViewData.filter((e) => { return e.value === menu })}
                  onChange={(e) => {
                    setDataAssignOpen({ ...dataAssignOpen, dataOnchanged: e });
                  }}
                />
                {/* <input className='srsAreaAssignToBody'
                        placeholder='Please input your Manday (number only)'
                        type="number"
                        value={assignToSend.text}
                        // disabled = {displayMode == true || displayMode == 'true'}
                        onChange={(e) => {
                            if (/^\d*$/.test(e.target.value)) {
                                setAssignToSend({ ...assignToSend, text: e.target.value });
                                assignToSend.text = e.target.value;
                            }
                        }}
                        onKeyDown={(e) => {
                            if (e.shiftKey && e.keyCode === 13) {

                            }
                        }}
                    ></input> */}
                <div className="srsAreaAssignToFooter">
                  {/* <div
                    className="srsAreaAssignToSend"
                    onClick={() => {
                      sendAssignData();
                    }}
                  >
                    SAVE
                  </div> */}
                  <div
                    className="srsAreaAssignToCancel"
                    onClick={() => toggle_modal_assignToPopup()}
                  >
                    CANCEL
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    </>
  );
}
export default ModalProcessMockup;
