import React, { useState, useEffect, useRef } from 'react';
import '../spaceship/detailSpaceShip.css'
import moment from "moment";
import spaceIcon from '../../../../assets/img/icon/Group11.png'
import spaceimg from '../../../../assets/img/iconSpaceShip/Space_Size-S2.png'
import spaceImg1 from '../../../../assets/img/images/Rectangle 151.png'
import spaceImg2 from '../../../../assets/img/images/Rectangle 152.png'
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from "axios";
import Configs from "../../../../config";
import poin from '../../../../assets/img/iconSpaceShip/plus.png';
import Searchicon from '../../../../assets/img/icon/Icon Search.png';
import Swal from "sweetalert2";

const DetailSpaceShip = ({ isOpen, check, callback,tempValue }) => {

    const [spaceData, setSpaceData] = useState([])
    const [hasMore, setHasMore] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [page, setPage] = useState(1);
    const [test, settest] = useState({test:''});
    
    const [filter, setFilter] = useState({
        keySearch: ''
    });
    const [onSearch, setOnSearch] = useState({
        show: 6,
        current: 1,
    });
    // console.log("spaceData: ", spaceData);
    
    // function fetchMoreData(page, enties, onSearch){
    //     console.log('tempValue',tempValue);
    //     let aslist = {
    //         id: tempValue.user_id,
    //         page: page || 1,
    //         enties: enties || 7,
    //         keySearch: filter.keySearch || ''
    //     }
    //     axios({
    //         method: "POST",
    //         url: `${Configs.TTT_Game_Permission_Service}/api/userprofile/getSpaceshipByUser`,
    //         headers: {
    //             Authorization: sessionStorage.getItem('access_token'),
    //             "X-TTT": `${Configs.API_TTT}`,
    //             "Content-Type": "application/json",
    //         },
    //         data: aslist,
    //     })
    //         .then(async function (response) {

    //             let datalist = response.data || []
       

    //             setOnSearch({
    //                 ...onSearch,
    //                 current: page || 1,
    //                 show: enties || 10,
    //             });

    //             datalist.data.forEach(async (item, index) => {
    //                 if (item.sps_model_id) {
    //                     const response2 = await axios.get(
    //                         `https://api.box.com/2.0/files/${item.sps_model_id}/content`,
    //                         {
    //                             headers: {
    //                                 Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
    //                             },
    //                             responseType: 'blob',
    //                         }
    //                     );
    //                     item.sps_model_id = URL.createObjectURL(response2.data);
    //                 } 
    //                 else {
    //                     item.sps_model_id = spaceimg;
    //                 }
               
    //         })

    //             setHasMore(datalist.isstop)
    //             // console.log(onSearch);
    //             if (onSearch === undefined) {
    //                 setSpaceData((prevItems) => [...prevItems, ...datalist.data]);
    //             } else {
    //                 setSpaceData(datalist.data);
    //             }



    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //         });
    //     /*     setTimeout(() => {
          
    //           const newData = [
    //               {
    //                   spaceImg: spaceImg1,
    //                   spaceName: "TTT Spaceship",
    //                   spaceDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
    //                   spaceProject: "TTT Spaceship",
    //                   spacePosition: "DEV : Full stack dev",
    //                   spaceOnOff: true,
    //                   spaceRank: "01",
    //                   spacePeople: "8",
    //                   spaceMaxPeople: "8"
    //               },
    //               {
    //                   spaceImg: spaceImg2,
    //                   spaceName: "TTT Spaceship  NO.02",
    //                   spaceDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
    //                   spaceProject: "",
    //                   spacePosition: "DEV : Full stack dev",
    //                   spaceOnOff: false,
    //                   spaceRank: "02",
    //                   spacePeople: "7",
    //                   spaceMaxPeople: "8"
    //               },
    //               {
    //                   spaceImg: spaceImg1,
    //                   spaceName: "TTT Spaceship",
    //                   spaceDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
    //                   spaceProject: "TTT Spaceship",
    //                   spacePosition: "DEV : Full stack dev",
    //                   spaceOnOff: true,
    //                   spaceRank: "01",
    //                   spacePeople: "8",
    //                   spaceMaxPeople: "8"
    //               },
    //               {
    //                   spaceImg: spaceImg2,
    //                   spaceName: "TTT Spaceship  NO.02",
    //                   spaceDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
    //                   spaceProject: "",
    //                   spacePosition: "DEV : Full stack dev",
    //                   spaceOnOff: false,
    //                   spaceRank: "02",
    //                   spacePeople: "7",
    //                   spaceMaxPeople: "8"
    //               },
    //               {
    //                   spaceImg: spaceImg1,
    //                   spaceName: "TTT Spaceship",
    //                   spaceDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
    //                   spaceProject: "TTT Spaceship",
    //                   spacePosition: "DEV : Full stack dev",
    //                   spaceOnOff: true,
    //                   spaceRank: "01",
    //                   spacePeople: "8",
    //                   spaceMaxPeople: "8"
    //               },
    //               {
    //                   spaceImg: spaceImg2,
    //                   spaceName: "TTT Spaceship  NO.02",
    //                   spaceDate: "Tue May 21 2023 18:49:19 GMT+0700 (Indochina Time)",
    //                   spaceProject: "",
    //                   spacePosition: "DEV : Full stack dev",
    //                   spaceOnOff: false,
    //                   spaceRank: "02",
    //                   spacePeople: "7",
    //                   spaceMaxPeople: "8"
    //               },
    //               ]
          
                 
           
    //         }, 1000); */
    // };


    function fetchMoreData(page, enties, onSearch) {
        console.log('tempValue', tempValue);
        let aslist = {
            id: tempValue.user_id,
            page: page || 1,
            enties: enties || 7,
            keySearch: filter.keySearch || ''
        };
    
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Permission_Service}/api/userprofile/getSpaceshipByUser`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: aslist,
        })
        .then(function (response) {
            let datalist = response.data || [];
    
            setOnSearch({
                ...onSearch,
                current: page || 1,
                show: enties || 10,
            });
    
       
            if (onSearch === undefined) {
                setSpaceData((prevItems) => [...prevItems, ...datalist.data]);
            } else {
                setSpaceData(datalist.data);
            }
    
          
            datalist.data.forEach(async (item, index) => {
                if (item.sps_model_id) {
                    try {
                        const response2 = await axios.get(
                            `https://api.box.com/2.0/files/${item.sps_model_id}/content`,
                            {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            }
                        );
                 
                        const imageUrl = URL.createObjectURL(response2.data);
    
                        setSpaceData((prevItems) =>
                            prevItems.map((prevItem, idx) =>
                                idx === index ? { ...prevItem, sps_model_id: imageUrl } : prevItem
                            )
                        );
                    } catch (error) {
                        console.error("Error fetching image:", error);
                    }
                } else {
           
                    setSpaceData((prevItems) =>
                        prevItems.map((prevItem, idx) =>
                            idx === index ? { ...prevItem, sps_model_id: spaceimg } : prevItem
                        )
                    );
                }
            });
    
            setHasMore(datalist.isstop);
        })
        .catch(function (error) {
            console.log(error);
        });
    }
    
  






    useEffect(() => {
        // Fetch initial data when the component mounts
     

        // console.log('เช็คหน่อยว่ายังไง',check);
        if(check == 'SPACESHIP'){
            console.log('in spaceship modal')
            fetchMoreData(onSearch.current, onSearch.show);
          
        }else{
            setSpaceData([])
            settest({...test,test:''})
            test.test = ''
        }
    }, [check]);

    return (
        (check === 'SPACESHIP' ? (<>
            <div className="detailSpaceShip" style={{ visibility: `${isOpen === true && check === 'SPACESHIP' ? 'visible' : 'hidden'}` }}>
                <div className="row">
                    <div className="col-6">
                        <div className="ds-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px  '#5D9BE1'`, marginBottom: '20px' }}>
                            <div className="ds-infoBoxText">
                                <div className="ds-infoBoxTextCen">
                                    <input className="ds-infoBoxTextIn"
                                        type='text'
                                        value={filter.keySearch}
                                        onChange={(e) => { setFilter({ ...filter, keySearch: e.target.value }); }}
                                        
                                        placeholder='search spaceship...'
                                    >


                                    </input>
                                    <div style={{ position: 'absolute', marginTop: '-27px', left: '34%', cursor: 'pointer' }}
                                        onClick={() => {
                                            fetchMoreData(onSearch.current, onSearch.show, filter.keySearch);
                                        }}
                                    >
                                        <img src={Searchicon} style={{ width: "25px", height: "25px" }}></img>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-6" style={{ display: 'flex', justifyContent: 'flex-end', padding: '5px' }}>
                    {/*     <div className="ds-infoBoxTextAddbox"
                    >
                            ADD NEW SPACESHIP
                        </div> */}
                    </div>


                </div>


                <div className='spaceNewBoxList'>
                    <div className='spaceNewBox'>
                    {spaceData.map((e, index) => (
                        <div className='spaceNewBoxIn' key={index}>
                            <div className='spaceNewBoxBgBorder'></div>
                            <img className='spaceNewImg' style={{ objectFit: 'contain' }} src={e.sps_model_id} alt={e.spaceship_name} />
                            <div className='spaceNewBoxBg' onClick={() => callback(e.spaceship_id, true, e.spaceship_name)}>
                                <div className='spaceNewBoxText' style={{ fontSize: '20px' }}>RANK : {e.spaceship_rank}</div>
                                <div className='spaceNewBoxText' style={{ fontSize: '25px', marginTop: '330px' }}>SPACESHIP :</div>
                                <div className='spaceNewBoxText' style={{ fontSize: '40px' }}>{e.spaceship_name}</div>
                                <div className='spaceNewBoxText' style={{ fontSize: '18px' }}>CREATE : {moment(e.spaceship_created_date).format("DD MMM YYYY")}</div>
                                <div className='spaceNewBoxText' style={{ fontSize: '18px' }}>PROJECT : {e.projname}</div>
                                <div className='spaceNewBoxText' style={{ fontSize: '18px' }}>STATUS : {e.ss_name}</div>
                                <div className='spaceNewBoxText' style={{ fontSize: '18px' }}>PEOPLE : {e.munberonspace}/{e.spaceship_size}</div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>


                {/* <div id="scrollableindiv" style={{ height: 600, overflow: "auto" }}>
                    <InfiniteScroll
                        dataLength={spaceData.length}
                        next={() => {
                            fetchMoreData(onSearch.current + 1, onSearch.show);
                        }}
                        hasMore={hasMore}
                        loader={<h4>Loading...</h4>}
                        endMessage={<p>No more items</p>}
                        scrollThreshold={0.8}
                        scrollableTarget="scrollableindiv"

                    >
                        {spaceData.map((e, index) => {
                            return (
                                <div className="spaceBox" key={index} >
                                    <div className="row setSpaceRow">
                                        <div className='col-2 setSpaceCol'>
                                            <img className='spaceImg' src={`${e.imgpath !== null ? `${Configs.TTT_Game_Service_IMG}${e.imgpath}` : spaceimg}`} ></img>
                                        </div>
                                        <div className='col-7 setSpaceCol'>
                                            <div className='spaceHead'>SPACESHIP</div>
                                            <div className='spaceNameBox'>
                                                <div className='spaceIcon' style={{ backgroundImage: `url(${spaceIcon})` }}></div>
                                                <div className='spaceName'>{e.spaceship_name} </div>
                                                <div style={{ textAlign: 'center', width: '30px', marginLeft: '10px' }} ><i class="far fa-edit" style={{ cursor: 'pointer' }}
                                                    onClick={() => {

                                                        callback(e.spaceship_id, true, e.spaceship_name)
                                                    }}></i></div>
                                                {e.projname === null ?
                                                    <div style={{ textAlign: 'center', width: '30px' }}><i class="far fa-trash-alt" style={{ cursor: 'pointer' }} onClick={() => { delSpaceShip(e.spaceship_id) }} aria-hidden="true"></i></div>
                                                    : <></>}


                                            </div>
                                            <div className='spaceDateBox'>
                                                <div className='spaceDate'>CREATE DATE : {moment(e.spaceship_created_date).format("DD MMM YYYY")} </div>
                                                <div className='spacezoneposition'>PROJECT : <span style={{ color: `${e.projname !== '' ? '#0047FF' : 'white'}` }}>{e.projname !== null ? e.projname : "-"}</span> </div>
                                                <div className='spacezoneposition'>POSITION : {e.userrol} </div>
                                            </div>
                                        </div>
                                        <div className='col-3 setSpaceCol'>
                                            <div className='spaceRightBoxtop'>

                                                <div className='spaceOnOffbefor' style={{ color: '#ffffff' }}> Status :</div> <div className='spaceOnOff' style={{ color: '#0047FF' }}>{e.ss_name}</div>

                                            </div>
                                            <div className='spaceRightBox'>
                                                <div className='spaceRank'>RANK : </div>
                                                <div className='spaceRankText'>{e.spaceship_rank}</div>
                                            </div>

                                            <div className='spacePeopleBox'>
                                                <div className='spacePeople'>PEOPLE :</div>
                                                <div className='spacePeopleText'>{e.munberonspace}/{e.spaceship_size}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </InfiniteScroll>
                </div> */}

            </div>
        </>) : (<></>))
    )
}

export default DetailSpaceShip