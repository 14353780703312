
import React, { useEffect, useState, useRef } from 'react'

import "./cardstageStyle.css";
import moment from "moment";
import axios from 'axios';
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Configs from "../../config";
import Projectcard from './cardproject'
// import Cardpercent from "./cardpercent";
import CardHalfCircle from './cardhalfcircle.jsx'
import Cardmodule from "./cardmodule";
import iconback from "../../assets/img/icon/iconback.png";
import iconback_door from "../../assets/img/icon/iconback_door.png"
import iconback_doorStage from "../../assets/img/icon/IconBack_stage.png"

import InfiniteScroll from 'react-infinite-scroll-component';
import styled, { keyframes } from 'styled-components';
import poin from '../../assets/img/iconSpaceShip/plus.png';
import uploadicon from '../../assets/img/icon/upload.png';

import IMGfinished from '../../assets/img/IconStage4/finished.png';
import IMGMySpace from '../../assets/img/IconStage4/mySpace.png';
import IMGtopVector from '../../assets/img/icon/Vector 913.png';
import IMGonDue from '../../assets/img/IconStage4/onDue.png';
import IMGonSpace from '../../assets/img/IconStage4/onSpace.png';
import iconskip from '../../assets/img/icon/Group 237831.png';
import imgcustomer from '../../assets/img/icon/Frame 237834.png';
import imgAcceptDoneBefore from '../../assets/img/icon/AcceptDoneBefore.png';
import imgAcceptDoneAfter from '../../assets/img/icon/AcceptDoneAfter.png';
import imgheadteam from '../../assets/img/icon/Group 237844 2.png';
import imgheadteamcheck from '../../assets/img/icon/Frame 237845check.png';
import imgcustomercheck from '../../assets/img/icon/Group 238002check.png';
import IMGtopVectorleft from '../../assets/img/icon/Vector 909.png';
import IMGtopVectorright from '../../assets/img/icon/Vector 910.png';
import './statusModal.css'

import { PlusOutlined } from '@ant-design/icons';
import { Input, Select, Space, Tag, theme, Tooltip, ConfigProvider, message, Upload, Switch } from 'antd';
/* import img from '../../assets/img/slider/banner_magazine_thumb02.jpg' */
import ModalConfigModule from './Module&Menu/ModalConfigModule.jsx';
import ModalStakeholder from './Module&Menu/ModalStakeholder.jsx';
import ModalFilterDisplayStage from './Module&Menu/ModalFilterDisplayStage.jsx';
import ModalProcessMockup from './Process/ModalProcessMockup.jsx';
import ModalProcessMockupViewVersion from './Process/ModalProcessMockupViewVersion.jsx';
import ModalProcessMockupViewDescription from './Process/ModalProcessMockupViewDescription.jsx';
import * as XLSX from 'xlsx-js-style';
import { io } from 'socket.io-client';
import IconMenu from './Image/IconMenu.png'
import IconFeature from './Image/IconFeature.png'
import IconQuick from './Image/IconQuick.png'
import Iconexport from './Image/Iconexport.png'
import IconQuickFilled from "../../assets/img/icon/QuickIconFilled.png"
import IconElementDBA from "./Image/IconDBA.png"
import IconOpenApi from '../../assets/img/icon/openApi-icon.png'

import StageElement from '../component/stageElement/index.jsx';

import galaxyBg from '../../assets/gif/galaxy.gif';
import { data, param } from 'jquery';
import ModalProcessSrs from "./Process/ModalProcesSrs/ModalProcessSrs.jsx";
import PostmanCollection from '../component/openAPI/OpenApi.jsx';
import ElementDBA from '../component/elementDBA/elementDBA.jsx';
import { Col, Row,Checkbox  } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import IconSuperDetail from '../../assets/img/icon/Superdetail.png'
import DatabaseGenModel from '../../view/component/DatabaseConfigAndGenerateCodeSQLFile/databaseGen.jsx'

const { Dragger } = Upload;
function Cardstage(params) {

  const location = useLocation();
  const history = useHistory();

  const clearQueryParams = () => {
    history.push(location.pathname);
  };

  const [switModeShowDisplay, setSwitModeShowDisplay] = useState(false)
  const togle_siwmode = () => setSwitModeShowDisplay(!switModeShowDisplay)

  const [mode, setmode] = useState('all');
  const [showBox, setShowBox] = useState(false);
  const [clearStatus, setClearStatus] = useState(false);
  const boxRef = useRef(null);
  const [fillStatus, setFillStatus] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [buttonBgColor, setButtonBgColor] = useState('');
  const [arr, setarr] = useState([]);
  const [drtaitprojcet, setdrtaitprojcet] = useState({
    data: {},

  });

  const handleFillStatusUpdate = (newFillStatus) => {
    fillStatus.push(newFillStatus);
  };

  const [fileDoc, setFileDoc] = useState([])
  const [userismode, setuserismode] = useState(true)
  function callback(value) {
    params.callBackModalSrs(value)
  }
  const [databesModel, setdatabesModel] = useState(false);
  const toggledatabesModel = () => setdatabesModel(!databesModel);

  useEffect(() => {
    
    
    if (params.onElement == '725aa7e3-2337-4f01-88b7-a0114d1fc67e') {
        // Time Sheet Day Report
        toggledatabesModel();
    }
    console.log('Check one',params);
    
}, [params.onElement]);


  function callBackMonitoring(params) {
    console.log('param :>>', params);
    setCallBackMonitor(params)
  }
  const [callBackMonitor, setCallBackMonitor] = useState(false);
  const [stageViewStatus, setStageViewStatus] = useState('');
  const [projectList, setProjectList] = useState([]);
  const [projectListResult, setProjectListResult] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [projectDetail, setProjectDetail] = useState({});
  const [projectDetailFeet, setProjectDetailFeet] = useState([]);
  let [acceptClass, setAcceptClass] = useState('AcceptMissionDoneActive');
  let [getCountMenu, setGetCountMenu] = useState(0);
  let [getCountFeature, setGetCountFeature] = useState(0);

  const [checkCount, setCheckCount] = useState(true)
  let [getCountProcess, setGetCountProcess] = useState([]);
  let [getCountMenuList, setGetCountMenuList] = useState([]);
  const [isHideModule, setIsHideModule] = useState(false);


  const [filterMenu, setFilterMenu] = useState({
    menu: true,
    feature: true
  })

  useEffect(() => {
    function handleClickOutside(event) {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        setShowBox(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [boxRef]);

  useEffect(() => {
    console.log('paramsTest',params);
    
    setProjectDetail(params.projectDetail);

    setIsHideModule(false);
  }, [params.projectDetail])

 /*  useEffect(() => {
    console.log('เข้าเเล้ว');
    
    chcekusermode();
  }, []) */
  function switchmode(id) {
    let hook = arr.filter((item) => item.id === id)
    drtaitprojcet.data = hook[0]
    setmode('detait')
  }

  useEffect(() => {
    if (params.toCardStage != null) {
      let toCardStage = params.toCardStage;
      getProjectDetail(toCardStage.projectKey);
      if (modalStatus == false) {
        toggle_modal_status();
      }

      setMonitoringTarget({
        ...monitoringTarget,
        process_id: toCardStage.processKey,
        menu_id: toCardStage.menuKey
      });
      monitoringTarget.process_id = toCardStage.processKey;
      monitoringTarget.menu_id = toCardStage.menuKey;
      setProjectDetail({
        ...projectDetail,
        project_id: toCardStage.projectKey
      });
      projectDetail.project_id = toCardStage.projectKey;
      setIsHideModule(false);
    }
  }, [params.toCardStage])



  function getProjectDetail(id) {
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/project/GetProjectDetail/${id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
    })
      .then(async function (response) {
        // console.log('เข้ามาตรงนี้แหละ', response.data);
        setIsHideModule(false);
        setuserismode(response.data.isuserspace);
        setFilterMenu({ ...filterMenu, menu: true, feature: true });
        setProjectDetailFeet(JSON.parse(JSON.stringify(response.data)));
        setProjectDetail(JSON.parse(JSON.stringify(response.data)));
        if (response.data.isownerprojectcheck == true) {
          setAcceptClass('AcceptMissionDoneActived');
        } else {
          setAcceptClass('AcceptMissionDoneActive');
        }
        setmode('detait')
        const setData = response.data.moduleList.filter((e) => e.module_menu_list.length != 0);
        if (response.data.moduleList.length > 0) {
          const combineData = [];
          for (let item of setData) {
            combineData.push(...item.module_menu_list)
          }
          let typeMenu = 'c7fe79cf-d110-46c1-b7be-2f70761e8d03';
          let typeFeature = 'fb609df7-2e46-4a3b-83d8-ef6e3e78f35f';
          let menu_count = combineData.filter((e) => { return e.menu_type == typeMenu });
          let feature_count = combineData.filter((e) => { return e.menu_type == typeFeature });
          setGetCountMenu(menu_count.length);
          setGetCountFeature(feature_count.length);
        } else {
          setGetCountMenu(0);
          setGetCountFeature(0);
        }
        const { menuIds, processIds } = response.data.moduleList.reduce((acc, module) => {
          module.module_menu_list.forEach(menu => {
            acc.menuIds.push(menu.menu_id);
            menu.process_list.forEach(process => {
              acc.processIds.push(process.process_id);
            });
          });
          return acc;
        }, { menuIds: [], processIds: [] });

        setGetCountMenuList(menuIds);
        setGetCountProcess(processIds);
        getCountMenuList = menuIds;
        getCountProcess = processIds;
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  useEffect(() => {
    console.log('paramsTest',params);
    if (params.stageProject != '') {
      params.allStageMonitoring(true);
      getProjectDetail(params.stageProject);
    }
  }, [params.stageProject])

  function checkProjectCompletion(callback) {
    let temp = {
      project_id: projectDetail.project_id,
      menu_id: getCountMenuList,
      process_id: getCountProcess
    };

    axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/project/checkProjectCompletion`,
      headers: {
        Authorization: sessionStorage.getItem("access_token"),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data) {
          setCheckCount(response.data.projectCompleted);
          if (callback) {
            callback();
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  function saveFillProcessStage() {
    setAlertKick2(true);
    setErrorMessage2({ status: true, message: '' });
    let project_id = projectDetail.project_id;
    let temp = fillStatus.map(item => ({
      ...item,
      project_id: project_id
    }));

    axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/project/saveFillProcessStage`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        if (response.data) {
          setAlertKick3(true)
          await getProjectDetail(projectDetail.project_id)
          await new Promise(resolve => setTimeout(resolve, 1000));
          handleCancel()
          setErrorMessage({ status: false, message: 'Operation Successful' });
          setAlertKick2(false);
          setClearStatus(false)
        }
      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage({ status: false, message: 'Operation Failed' });
      });
  }



  function getRndInteger(min, max) {
    return Math.random() * (max - min + 1) + min;
  }

  const handleColorClick = (color) => {
    setButtonBgColor(color);
    setShowBox(false);
    setEditMode(true);
  };

  const handleCancel = () => {
    setEditMode(false);
    setShowBox(false);
    setButtonBgColor('');
    setFillStatus([])
  };

  function switctisuse(id) {
    let temp = projectDetail;
    let index = temp.moduleList.findIndex((e) => e.module_id === id);
    if (index > -1) {
      temp.moduleList[index].isuse = !temp.moduleList[index].isuse;
      console.log(temp.moduleList)

      let check_show = temp.moduleList.filter((e) => { return e.isuse == true });
      let check_hide = temp.moduleList.filter((e) => { return e.isuse == false });
      if (check_show.length == temp.moduleList.length) {
        console.log('check_show.length', check_show.length)
        setIsHideModule(false);
      } else if (check_hide.length == temp.moduleList.length) {
        console.log('check_hide.length', check_hide.length)
        setIsHideModule(true);
      }
      setProjectDetail(temp);

    }
    settest({ ...test, test, test: '' });
    test.test = '';
  }

  function hexToRgb(hex) {

    hex = hex.replace(/^#/, '');

    let bigint = parseInt(hex, 16);
    let r = (bigint >> 16) & 255;
    let g = (bigint >> 8) & 255;
    let b = bigint & 255;

    return { r, g, b };
  }

  function lightenColor(rgb, factor = 0.2) {
    let r = Math.floor((1 - factor) * rgb.r + factor * 255);
    let g = Math.floor((1 - factor) * rgb.g + factor * 255);
    let b = Math.floor((1 - factor) * rgb.b + factor * 255);
    return `rgb(${r}, ${g}, ${b})`;
  }

  const [test, settest] = useState({
    test: ''
  })
  const [filterProjectData, setFilterProjectData] = useState({
    onDue: true,
    onSpace: true,
    onFinish: false
  });
  function filterProject(page, enties) {
    let temp = {
      user_id: sessionStorage.getItem('user_id'),
      fed_id: sessionStorage.getItem('fed_id'),
      page: page || 1,
      enties: enties || 10,
      onDue: filterProjectData.onDue,
      onSpace: filterProjectData.onSpace,
      onFinish: filterProjectData.onFinish,
    };
    axios({
      method: "POST",
      url: `${Configs.TTT_Game_Service}/api/project/filterProjectByUser`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      data: temp,
    })
      .then(async function (response) {
        let array = [];
        console.log("response.data.data", response.data.data);
        for (let item of response.data.data) {
          let colorHex = item.defcon_color || '#ffffff'; // ตั้งค่าสีเริ่มต้นเป็นสีขาวหากไม่มีข้อมูลสี
          let rgbColor = hexToRgb(colorHex);

          let lightenedColor = lightenColor(rgbColor, 0.3);
          console.log('color', lightenedColor);
          let temp = {
            id: item.project_id,
            nameproject: item.project_name,
            description: item.project_description || 'No description',
            startdate: item.pp_start_date,
            enddate: item.pp_finish_date,
            percent: parseFloat(item.project_progress || 0),
            color: lightenedColor,
            doing: getRndInteger(0, 300),
            done: getRndInteger(0, 300),
            all: getRndInteger(0, 300),
            todo: getRndInteger(0, 300),
            spaceshipID: item.project_spaceship_id,
            pros_id: item.project_pros_id,
          }
          array.push(temp)

        }
        setProjectListResult(array);
        setProjectList(array)
        setloopcard(array);
        setProjectCount(response.data.count)
      })
      .catch(function (error) {
        console.log(error);
      });
  }




  const socketRef = useRef(null);

  useEffect(() => {

    if (socketRef.current == null) {
      socketRef.current = io(Configs.TTT_Game_Socket_Service, {
        port: Configs.TTT_Game_Socket_Service_Port
      });
    }
  }, [

  ])


  useEffect(() => {

    const { current: socket } = socketRef;

    const socFncBan = (data) => {
      console.log('onRoomBan', data)
      filterProject(1, 999);

    }

    const socFnc = (data) => {
      console.log('dataMode', data)
      if (data.message === 'banmode') {
        console.log('dataMode', data)
        filterProject(1, 999);
      }

    }
    const socFnc2 = (data) => {}

    try {
      socket.open();

      socket.emit('join', "BanMember" + sessionStorage.getItem('user_id'));
      socket.emit('online', sessionStorage.getItem('user_id'));
      socket.on('mailbox', socFnc)
      socket.on('locationElement', socFnc2)

      console.log('เช็คห้อง', socket);
    } catch (error) {
      console.log('ตรงเเบนมา', error);
    }
  }, [])




  useEffect(() => {
    console.log('paramsTest',params);
    filterProject(1, 9999);
    setIsHideModule(false);
  }, [params.fed_id])

  const mouseMoveHandler = (event) => {
    console.log(event)
    setPosition({
      posX: event.clientX,
      posY: event.clientY
    });
  }

  const [position, setPosition] = useState({
    posX: 0,
    posY: 0
  })
  const [modalStatus, setModalStatus] = useState(false);
  const toggle_modal_status = () => setModalStatus(!modalStatus);



  function updateStateMornitoring(status_id) {
    let temp = {
      process_id: monitoringTarget.process_id,
      menu_id: monitoringTarget.menu_id,
      status_id: status_id,
      project_id: projectDetail.project_id
    }
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/updateStateMonitoring`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(function (response) {
        if (response.data) {
          toggle_modal_status();
          getProjectDetail(projectDetail.project_id)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  function updateStakeholder(data, qdc) {
    let temp = {

      menu_id: stakeholder.menu_id,
      project_id: projectDetail.project_id,
      stakeholderList: data || [],
      quality: qdc.quality || null,
      delivery: qdc.delivery || null,
      cost: qdc.cost || null,
    }
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/updateStakeholder`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(function (response) {
        if (response.data) {
          toggle_modal_stakeholder();
          getProjectDetail(projectDetail.project_id)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }






  const [monitoringTarget, setMonitoringTarget] = useState({
    process_id: '',
    menu_id: '',

  })

  const [stakeholder, setStakeholder] = useState({
    module_id: '',
    menu_id: '',

  })

  const { token } = theme.useToken();
  const [tags, setTags] = useState([]);
  const [QDC, setQDC] = useState({});
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef(null);
  const editInputRef = useRef(null);
  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);
  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);
  const handleClose = (removedTag) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    console.log(newTags);
    setTags(newTags);
  };
  const showInput = () => {
    setInputVisible(true);
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const handleInputConfirm = () => {
    console.log("tags.includes(inputValue)", tags.includes(inputValue))
    let temp = tags;
    let check = temp.filter((e) => { return e.toString().toLowerCase() === inputValue.toLowerCase() });
    if (inputValue && check.length > 0/* tags.includes(inputValue) */) {
      console.log('เจออ')

      let index = temp.findIndex((e) => e.toString().toLowerCase() === inputValue.toLowerCase());
      if (index > -1) {
        temp.splice(index, 1);
        setTags(temp);
      }

    }
    if (inputValue) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue('');
  };
  const handleEditInputChange = (e) => {
    setEditInputValue(e.target.value);
  };
  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue('');
  };
  const tagInputStyle = {
    width: 64,
    height: 22,
    marginInlineEnd: 8,
    verticalAlign: 'top',
    backgroundColor: '#000'


  };
  const tagPlusStyle = {
    height: 22,
    borderStyle: 'dashed',
    backgroundColor: '#000'
  };


  const [openModalStakeholder, setOpenModalStakeholder] = useState(false);
  const toggle_modal_stakeholder = () => setOpenModalStakeholder(!openModalStakeholder);

  const [openModalConfigModule, setOpenModalConfigModule] = useState(false);
  const toggle_modal_config_module = () => setOpenModalConfigModule(!openModalConfigModule);


  const [openModalFilterStage, setOpenModalFilterStage] = useState(false);
  const toggle_modal_filter_stage = () => setOpenModalFilterStage(!openModalFilterStage);

  const [openModalProcessMockup, setOpenModalProcessMockup] = useState(false);
  const toggle_modal_process_mockup = () => setOpenModalProcessMockup(!openModalProcessMockup);


  const [openModalProcessMockupViewVersion, setOpenModalProcessMockupViewVersion] = useState(false);
  const toggle_modal_process_mockup_view_version = () => setOpenModalProcessMockupViewVersion(!openModalProcessMockupViewVersion);

  const [openModalProcessMockupViewDescription, setOpenModalProcessMockupViewDescription] = useState(false);
  const toggle_modal_process_mockup_view_description = () => setOpenModalProcessMockupViewDescription(!openModalProcessMockupViewDescription);

  const [dataMockupVersion, setDataMockupVersion] = useState([]);
  const [dataMockupDescription, setDataMockupDescription] = useState('');
  const [ModuleDetail, setModuleDetail] = useState({});

  function callbackDataMockupVersion(data) {

    setDataMockupVersion(data);
  }
  function callbackDataMockupDescription(data) {

    setDataMockupDescription(data);
  }


  function GetModuleDeatil(module_id) {
    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/moduleMenu/getModuleDetail/${module_id}`,
      headers: {
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        setModuleDetail(response.data)
      })
      .catch(function (error) {
        console.log(error);

      });
  }

  const exportToExcel = () => {

    let countprocessList = projectDetail.processList.length


    const excelData = [
      [{ v: `${projectDetail.project_name} : ${projectDetail.project_progress} %`, t: "s", s: { font: { sz: 36, bold: true }, alignment: { horizontal: 'center' }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } }],
    ];
    const wscols = [
      { wpx: 250 },
    ];
    const merges = [
      { s: { r: 0, c: 0 }, e: { r: 0, c: (4 + countprocessList) - 1 } }, // Merged cells for Project Information

    ];


    let zonea2 = [{ v: `Module`, t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } }]

    for (let a2 of projectDetail.processList) {
      zonea2.push({ v: a2.process_name, t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', wrapText: true, vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } }, id: a2.process_id },)
      wscols.push({ wpx: 90 })
    }
    zonea2.push(
      { v: 'Remark', t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
      { v: 'Due Date', t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },
      { v: 'Actual Date', t: "s", s: { font: { sz: 16, bold: true }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: "c9daf8" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } })
    wscols.push({ wpx: 160 }, { wpx: 100 }, { wpx: 100 })
    excelData.push(zonea2)


    let zonea3 = []
    let ismergesonrow = 2
    for (let a3 of projectDetail.moduleList) {
      let inrow = [{ v: a3.module_name, t: "s", s: { font: { sz: 14, bold: true }, alignment: { horizontal: 'left' }, fill: { fgColor: { rgb: "B0E0E6" } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } }]
      for (let c = 0; c < countprocessList; c++) {
        if (c === 0) {
          inrow.push({ v: '', t: "s", s: { border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" } }, fill: { fgColor: { rgb: "B0E0E6" } } } })
        } else {
          if (c === countprocessList - 1) {
            inrow.push({ v: '', t: "s", s: { border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, right: { rgb: "000000" } }, fill: { fgColor: { rgb: "B0E0E6" } } } })

          } else {
            inrow.push({ v: '', t: "s", s: { border: { top: { rgb: "000000" }, bottom: { rgb: "000000" } }, fill: { fgColor: { rgb: "B0E0E6" } } } })
          }
        }



      }
      inrow.push({ v: '', t: "s", s: { font: { sz: 12 }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },)
      inrow.push({ v: a3.module_end_date !== null ? moment(a3.module_end_date).format('DD/MM/YYYY').toString() : '', t: "s", s: { font: { sz: 12 }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } })
      inrow.push({ v: '', t: "s", s: { font: { sz: 12 }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },)
      merges.push({ s: { r: ismergesonrow, c: 1 }, e: { r: ismergesonrow, c: countprocessList } })
      excelData.push(inrow)
      ismergesonrow += 1
      for (let sub of a3.module_menu_list) {
        inrow = []
        inrow.push({ v: sub.menu_name, t: "s", s: { font: { sz: 12 }, alignment: { vertical: 'top', wrapText: true }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },)

        for (let pc of sub.process_list) {
          let key_status = '-';
          if (pc.ps_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188') {
            //done green
            key_status = 'o';
          } else if (pc.ps_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8') {
            //waiting red
            key_status = 'x';
          } else if (pc.ps_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e') {
            //inProcess yellow
            key_status = 'i';
          }

          inrow.push({ v: key_status, t: "s", s: { font: { sz: 12 }, alignment: { horizontal: 'center', vertical: 'center' }, fill: { fgColor: { rgb: pc.ps_color.replace('#', '') } }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } } } },)


        }
        inrow.push({ v: sub.menu_remark || '', t: "s", s: { font: { sz: 12 }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } }, alignment: { wrapText: true, vertical: 'top' } } },)
        inrow.push({ v: sub.menu_end_date !== null ? moment(sub.menu_end_date).format('DD/MM/YYYY').toString() : '', t: "s", s: { font: { sz: 12 }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } }, alignment: { wrapText: true, vertical: 'top' } } })
        inrow.push({ v: sub.menu_actual_date !== null ? moment(sub.menu_actual_date).format('DD/MM/YYYY').toString() : '', t: "s", s: { font: { sz: 12 }, border: { top: { rgb: "000000" }, bottom: { rgb: "000000" }, left: { rgb: "000000" }, right: { rgb: "000000" } }, alignment: { wrapText: true, vertical: 'top' } } },)
        excelData.push(inrow)
        ismergesonrow += 1
      }

      zonea3 = []


    }


    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();

    ws['!cols'] = wscols;

    merges.forEach(merge => {
      ws['!merges'] = ws['!merges'] || [];
      ws['!merges'].push(merge);
    });

    XLSX.utils.book_append_sheet(wb, ws, 'Project Data');

    XLSX.writeFile(wb, `Stage Monitoring (${projectDetail.project_name}) ${moment(new Date()).format("DD-MM-YYYY")}.xlsx`);
  };



  function missiondonesstapone() {
    let temp = {
      project_id: projectDetail.project_id
    }
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/missiondonesstapone`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(function (response) {
        let countdown = 2000;
        var x = setInterval(function () {
          countdown = countdown - 1000;
          if (countdown == 0) {
            clearInterval(x);
            getProjectDetail(projectDetail.project_id);
          }
        }, 1000);


      })
      .catch(function (error) {
        console.log(error);

      });
  }


  function getimguser() {

    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/getimguser`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        project_id: projectDetail.project_id
      }

    })
      .then(async function (response) {
        let imguser = ''
        let remark = ''
        let remarktwo = ''
        let list = []
        let fileUploadImg = [];
        if (response.data.list.length > 0) {
          let data = response.data.list[0]
          remark = data.md_description
          remarktwo = data.md_detail_sent_to
        }

        setFileImport({
          ...fileImport,
          file: list,
          remark: remark,
          remarktwo: remarktwo,

        })
        fileImport.file = list
        fileImport.remark = remark
        fileImport.remarktwo = remarktwo


        if (response.data.imglist && response.data.imglist.length > 0) {
          let tempOtherFile = [];
          let tempOldFile = [];
          for (let item of response.data.imglist) {
            if (item.fd_keyfile != 'PROJECT FINISH') {
              tempOtherFile.push(item.fd_img_id);
            } else {
              tempOldFile.push(item.fd_img_id);

              let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                headers: {
                  Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                },
                responseType: 'blob',
              });

              let temp = {
                ul_file_name: item.fd_origin_name,
                fd_img_id: item.fd_img_id,
                url: URL.createObjectURL(response2.data)
              }
              fileUploadImg.push(temp)
            }
            setFileDoc(fileUploadImg);
            settest({ ...test, test: '' });
            test.test = '';
          }
          setOtherFileList(tempOtherFile);
          setOldFileList(tempOldFile);
        }

        if (response.data.spaceshipImg && response.data.spaceshipImg.length > 0) {
          let response2 = '';
          if (response.data.spaceshipImg[0].fd_img_id_spaceship) {
            response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.spaceshipImg[0].fd_img_id_spaceship}/content`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
              },
              responseType: 'blob',
            });
          } else {
            response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.spaceshipImg[0].fd_img_id_model}/content`, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
              },
              responseType: 'blob',
            });
          }

          setbystep({
            ...bystep,
            imguser: URL.createObjectURL(response2.data)
          });
          settest({ ...test, test: '' });
          test.test = '';
        }

      })
      .catch(function (error) {
        console.log(error);

      });
  }

  const [errorMessage, setErrorMessage] = useState({ status: '', message: '' });
  const [errorMessage2, setErrorMessage2] = useState({ status: '', message: '' });
  const [errorMessage3, setErrorMessage3] = useState({ status: '', message: '' });
  const [alertKick, setAlertKick] = useState(false);
  const [alertKick2, setAlertKick2] = useState(false);
  const [alertKick3, setAlertKick3] = useState(false);
  const [alertKick4, setAlertKick4] = useState(false);
  const [alertKick5, setAlertKick5] = useState(false);
  const [alertKick6, setAlertKick6] = useState(false);
  const toggle_modal_alertKick4 = () => setAlertKick4(!alertKick4);
  const toggle_modal_alertKick5 = () => setAlertKick5(!alertKick5);
  const toggle_modal_alertKick6 = () => setAlertKick6(!alertKick6);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);
  let [checkAuto, setCheckAuto] = useState(true);

  let [checkAlert, setCheckAlert] = useState(false);

  const [oldFileList, setOldFileList] = useState([]);
  const [otherFileList, setOtherFileList] = useState([]);

  const handleClickAccept = () => {
    if (projectDetail.project_pros_id !== "7f577a42-41fe-4434-aa4f-b92c3c83e76e" &&
      (projectDetail.project_pros_id === "4d45f176-a656-49d9-89dd-69ccb7422274" ||
        projectDetail.project_pros_id === "fb902c5e-d5c1-46b9-91a5-91aa8172925c") &&
      projectDetail.isownerproject) {

      if (parseInt(projectDetail.project_progress) === 100 && checkCount === true) {

        if (projectDetail.isownerprojectcheck == false) {
          setAcceptClass("AcceptMissionDoneActive_");
          setCheckAuto(true)
          checkAuto = true
          setTimeout(() => {
            setAlertKick5(true);
          }, 2000);
          ProductionAutoCheck(projectDetail.project_id)
        } else if (projectDetail.isownerprojectcheck == true) {
          setAcceptClass("AcceptMissionDoneDown_");
          setCheckAuto(false)
          checkAuto = false
          setTimeout(() => {
            setAlertKick5(true);
          }, 2000);
          ProductionAutoCheck(projectDetail.project_id)
        }
        missiondonesstapone();
      } else if (parseInt(projectDetail.project_progress) !== 100 && checkCount === false) {

        setAcceptClass("AcceptMissionDoneActive_");
        setTimeout(() => {
          setAlertKick4(true);
        }, 2000);
        if (projectDetail.isownerprojectcheck === true) {
          missiondonesstapone();
        }
      }
    } else {
      setAlertKick6(true);
    }
  };

  async function GetAccesstokenToken() {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
      })
      .catch(function (error) {
        console.log(error);
      });

  }

  async function CheckAndCreateFolderBox(id) {
    let temp = {
      folderLis: ["PROJECT", "PROJECT_" + id, 'PROJECT VIDEO', 'PROJECT FINISH']
    }
    await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
      },
      data: temp
    })
      .then(async function (response) {
        if (response.data) {
          UploadFiles(id, response.data.folder_id);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function UploadFiles(id, folder_id) {
    let oldFileList_ = oldFileList.filter((e) => { return e != null });
    let setOldFile = [...oldFileList_, ...otherFileList];
    const dataImg = new FormData();
    for (let item of fileDoc) {
      if (item.originFileObj) {
        dataImg.append('File', item.originFileObj);
      }
    }
    dataImg.append('folder_id', folder_id);
    dataImg.append('key', 'PROJECT FINISH');
    dataImg.append('image_old_list', JSON.stringify(setOldFile));
    dataImg.append('ref_id', id);
    await axios({
      method: 'post',
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'multipart/form-data'
      },
      data: dataImg
    })
      .then(async function (response) {
        if (response.data) {
          setErrorMessage({ ...errorMessage, message: 'Success', status: false });
          toggle_modal_alertKick();
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function savestepone() {
    let ole_file = [];
    let byidchack = false
    let temp = {
      description: fileImport.remark,
      file: ole_file,
      project_id: projectDetail.project_id
    }
    if (byidchack === true) {
      temp.byid = true
    }
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/savestepone`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(function (response) {
        if (response.data) {
          GetAccesstokenToken();
          CheckAndCreateFolderBox(projectDetail.project_id);
        }

      })
      .catch(function (error) {
        console.log(error);
        setErrorMessage({ ...errorMessage, message: error.response.data.error[0].errorDis, status: true });

      });


  }

  async function ProductionAutoCheck(id) {
    let temp = {
      checkAuto: checkAuto
    }
    axios({
      method: 'put',
      url: `${Configs.TTT_Game_Service}/api/project/ProductionAutoCheck/${id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    }).then(function (res) {

    }).catch(function (error) {
      console.log(error);
      setErrorMessage({ ...errorMessage, message: error.response.data.error[0].errorDis, status: true });

    })
  }



  async function savesteptwo() {
    console.log('fileImport', fileImport, fileDoc);

    let fileList = fileDoc || []

    let ole_file = []

    if (fileList.length > 0) {
      ole_file = fileList.filter((e) => e.byid !== undefined && e.byid == true)
      let newFile = fileList.filter((e) => e.byid == undefined || e.byid == false)
      if (newFile.length > 0) {
        const dataImg = new FormData();
        for (let i = 0; i < newFile.length; i++) {
          dataImg.append('Profile', newFile[i].originFileObj);
        }
        dataImg.append('typeFile', "ProojectCombackEart");
        dataImg.append('projectKey', "Comback");

        await axios({
          method: 'post',
          url: `${Configs.TTT_Game_Service}/api/upload/document2`,
          headers: {
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'multipart/form-data',
          },
          data: dataImg,
        })
          .then(async function (response) {
            if (response.data.status == true) {
              response.data.data.forEach((e) => {
                const tmp = {
                  name: e.orgin_name,
                  path: e.path,
                  is_save: true,
                  ul_is_use: true,
                  ul_file_size: e.size,
                  url: e.path,
                  ul_type: e.mimetype || 'vde',
                  ul_group_name: "comback",
                  ul_file_name: e.orgin_name,
                  ul_file_path: e.path,
                  ul_menu_name: "comback",
                  ul_is_profile: false,
                  status: 'done'

                }
                ole_file.push(tmp);
              })
            }

          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }






    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/project/savesteptwo`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: {
        md_description: fileImport.remark,
        description: fileImport.remarktwo,
        project_id: projectDetail.project_id,
        ole_file: ole_file || []
      }

    })
      .then(function (response) {
        if (response.data) {
          setErrorMessage({ ...errorMessage, message: 'Success', status: false });
          toggle_modal_alertKick();
        }
      })
      .catch(function (error) {
        console.log(error);

      });
  }





  async function BackToEart() {

    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/project/updateStageBacktoEarth/${projectDetail.project_id}`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        if (response.data) {
          toggle_modal_modelvideo();
          setErrorMessage({ ...errorMessage, message: 'Success', status: false });
          toggle_modal_alertKick();
          calExp();
        }
      })
      .catch(function (error) {
        console.log(error);

      });
  }

  async function calExp() {

    axios({
      method: 'get',
      url: `${Configs.TTT_Game_Service}/api/level/setRealExp/${projectDetail.project_id}`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
    })
      .then(function (response) {
        if (response.data) {

        }
      })
      .catch(function (error) {
        console.log(error);

      });
  }

  const [modelvideo, setmodelvideo] = useState(false)
  const toggle_modal_modelvideo = () => setmodelvideo(!modelvideo);

  const props = {

    maxCount: 1,
    name: 'file',
    accept: ".mp4,image/png, image/jpeg",
    multiple: false,
    method: 'get',
    headers: {
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
    },

    action: Configs.TTT_Game_Service + '/api/upload/fackFile',
    onChange(info) {
      console.log('status', info)

      const { status } = info.file;
      const { file } = info;
      console.log(' TESTTTT', status, file);
      if (status !== 'uploading') {

      }
      if (status === "uploading") {
        console.log('infFile', file);
        let ole_file = fileDoc;
        let count = ole_file.length + 1;
        var list = {
          uid: count,
          ul_file_path: file,
          ul_file_name: file.name,
          name: file.name,
          path: URL.createObjectURL(file.originFileObj),
          url: URL.createObjectURL(file.originFileObj),
          is_save: true,
          ul_is_use: true,
          ul_file_size: 0,
          ul_type: file.type,
          ul_group_name: "kickoff",
          ul_menu_name: "kickoff",
          ul_is_profile: false,
          status: 'done',
          image_file: file,
          is_img_upload: true,
          originFileObj: file.originFileObj
        }
        ole_file.push(list)

        console.log('EEDS', ole_file);


        setFileDoc(ole_file)
        settest({ test: '' });
        test.test = '';
      } else if (status === 'error') {
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };

  const onRemoveFile = (info, index) => {
    console.log("onRemove info = " + info);
    setFileImportList([])

    setFileImport({
      ...fileImport, file: []
    })
  };


  const [fileImportList, setFileImportList] = useState([]);


  function autoResize(value) {
    const textarea = document.getElementById(value);
    if (textarea != null || textarea != undefined) {

      if (textarea.scrollHeight + 5 < 100) {
        textarea.style.height = '35px'; // รีเซ็ตความสูงก่อนที่จะปรับขนาด
        textarea.style.height = textarea.scrollHeight + 5 + 'px'; // ปรับความสูงใหม่ตามข้อความที่ป้อนเข้าไป
      }

    }
  }


  const [fileImport, setFileImport] = useState({
    file: [],
    remark: null,
    remarktwo: null,
    newfile: null
  });
  const [bystep, setbystep] = useState({
    on: 1,
    imguser: null,
    step: [
      { step: 1 },
      { step: 2 }
    ]
  });




  function DelFileList(fd_img_id, index) {
    let tempOldFile = oldFileList;
    let tempFileDoc = fileDoc;

    let checkIndex = tempOldFile.findIndex((e) => e == fd_img_id);
    if (checkIndex > -1) {
      tempOldFile.splice(checkIndex, 1);
    };

    if (index > -1) {
      tempFileDoc.splice(index, 1);
    }

    setOldFileList(tempOldFile);
    setFileDoc(tempFileDoc);
    settest({ ...test, test, test: '' });
    test.test = '';
  }

  const [templateListshow, setTemplateListshow] = useState([])
  const [templateList, setTemplateList] = useState({ data: [] })
  const [windowSize, setWindowSize] = useState({
    size: 0,
    height: 0
  });

  const handleWindowResize = () => {
    console.log("templateList.data", templateList.data)
    setloopcard(templateList.data)
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [templateList.data]);

  function setloopcard(data) {
    try {
      setTemplateList({
        ...templateList,
        data: data
      })

      const looptemplate = data.map((e, index) => {
        const Cardsetcolorin = styled.div`
        position: absolute;
        width: 350px;`;

        return (
          <>
            <Cardsetcolorin className='tagproject' >
              <div style={{ flexGrow: '1', display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignContent: 'flex-start' }}>
                <Projectcard nameproject={e.nameproject} description={e.description} startdate={e.startdate} enddate={e.enddate} percent={e.percent} id={e.id} returnfuc={getProjectDetail} cardcolor={e.color} reloadSlectProject={() => params.reloadSlectProject(true)} spaceship={e.spaceshipID} pros={e.pros_id} />
              </div>
            </Cardsetcolorin>
          </>
        )
      })

      setTemplateListshow(looptemplate)

      let allhigthproject = document.getElementsByClassName("tagproject")
      let maxhightdiv = [];

      for (let index = 0; index < allhigthproject.length; index++) {
        maxhightdiv.push(allhigthproject[index].offsetHeight)
        document.getElementsByClassName('tagproject')[index].style.display = 'none';
      }

      let widthwindthdiv = document.getElementById("scrollStageMonitoringDiv").offsetWidth;
      let maxwindow = widthwindthdiv;
      let x = 10;
      let y = 10;
      let maxlength = 0
      let arrmax = []


      const setproion = data.map((e, index) => {
        if (y > 10) {
          let minindex = arrmax.findIndex(obj => obj.count === Math.min(...arrmax.map(m => m.count)));
          x = ((350 + 10) * arrmax[minindex].id) + 10
          y = arrmax[minindex].count + 10
          arrmax[minindex].count += maxhightdiv[index] + 10
        }
        if (maxwindow <= (x + 350 + 10) && y === 10) {
          let minindex = arrmax.findIndex(obj => obj.count === Math.min(...arrmax.map(m => m.count)));
          x = ((350 + 10) * arrmax[minindex].id) + 10
          y = arrmax[minindex].count + 10
          arrmax[minindex].count += maxhightdiv[index] + 10
        }

        const Cardsetcolorin = styled.div`
            position: absolute;
            left:${x}px;
            top:${y}px;
            width: 350px;
            border-radius: 20px;
            margin: 0;
          `;

        if (y === 10) {
          arrmax.push({
            id: maxlength,
            count: maxhightdiv[index] + 10
          })
          maxlength += 1
          x += 350 + 10
        }

        return (
          <>
            <Cardsetcolorin style={{ zIndex: '0' }}>
              <Projectcard callBackMonitoring={callBackMonitoring} allStageMonitoring={params.allStageMonitoring} nameproject={e.nameproject} description={e.description} startdate={e.startdate} enddate={e.enddate} percent={e.percent} id={e.id} returnfuc={getProjectDetail} cardcolor={e.color} reloadSlectProject={() => params.reloadSlectProject(true)} spaceship={e.spaceshipID} pros={e.pros_id} />
            </Cardsetcolorin>
          </>
        )
      })

      const temp = {
        ...windowSize,
        size: ((maxwindow - (350 + 10) * maxlength) / 2),
        height: Math.max(...arrmax.map(m => m.count)) + 10
      }
      setWindowSize(temp)
      setTemplateListshow(setproion)

    } catch (error) {
      console.log('error 112 :>> ', error);
    }
  }


  const [openModalProcessSrs, setOpenModalProcessSrs] = useState(false);
  const toggle_modal_process_srs = () => setOpenModalProcessSrs(!openModalProcessSrs);

  const [modalOpenApi, setModalOpenApi] = useState(false);
  const toggleModalOpenApi = () => setModalOpenApi(!modalOpenApi);

  const [openModalDBA, setOpenModalDBA] = useState(false);
  const toggle_modal_dba = () => setOpenModalDBA(!openModalDBA);
  const [allToDoChecked,setAllToDoChecked] = useState(false)


  async function filterMenuFeature() {
    let temp = projectDetailFeet || [];
    let temp2 = projectDetail || [];
    let typeMenu = 'c7fe79cf-d110-46c1-b7be-2f70761e8d03';
    let typeFeature = 'fb609df7-2e46-4a3b-83d8-ef6e3e78f35f';
    if (filterMenu.menu == false) {
      typeMenu = '';
    }

    if (filterMenu.feature == false) {
      typeFeature = '';
    }
    let index = 0;
    for (let item of temp.moduleList) {

      let result = item.module_menu_list.filter((e) => { return e.menu_type == typeMenu || e.menu_type == typeFeature });
      temp2.moduleList[index].module_menu_list = result;
      index++;
    }




  }

  async function filterAllToDo(checked) {
    setAllToDoChecked(checked);
    let temp = JSON.stringify(projectDetailFeet || []);
  
 /*    console.log('temp',temp) */
    let temp2 = JSON.stringify(projectDetail || []);

     temp = JSON.parse(temp);
     temp2 = JSON.parse(temp2);
 /*    let typeMenu = 'c7fe79cf-d110-46c1-b7be-2f70761e8d03';
    let typeFeature = 'fb609df7-2e46-4a3b-83d8-ef6e3e78f35f'; */
    if(checked == true){
      let index = 0;
      for (let item of temp.moduleList) {
      
        let index2 = 0;
        for(let item2 of item.module_menu_list){
          let result = item2.process_list.filter((e)=>{return e.ps_id == '02e2fbdb-1350-48aa-8bc6-e86c07f9035b'});
          if(item2.process_list.length == result.length){
            item2.isAllCancel = true;
          }
          index2++;
        }
        let result2 = item.module_menu_list.filter((e)=>{return !e.isAllCancel});
        temp2.moduleList[index].module_menu_list = result2;
        index++;
      }
    }else{
      temp2 = projectDetailFeet;
    }


    setProjectDetail(temp2);
   




  }
  const [getModeCustomer,setGetModeCustomer] = useState(false)
 
  function chcekusermode(project_id) {
    console.log('CallBaclIsnow',params);
    
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/project/chcekusermode/${project_id}`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        // console.log(response.data);
        sessionStorage.setItem("getUserMode", response.data.mode);
        /* return response.data.mode */
        setGetModeCustomer(response.data.mode);
        settest({...test,test:''});
        test.test = '';
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  return (
    <>

      <ConfigProvider
        theme={{
          token: {

            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',

            colorTextDescription: '#000',
            colorBgContainer: '#000',
            zIndexPopupBase: 999999,
            colorPrimaryBg: 'blue',
            colorBgBase: '#778899',

            colorTextQuaternary: '#FFFFFF',

            colorFillTertiary: '#000',

            colorTextBase: 'blue',

            colorPrimaryBg: '#85BFFF',
          }
        }}
      >

        {modelvideo === true ?
          switModeShowDisplay === false ? <>
            <div className={`backdropModuleMenu ${modelvideo ? 'opened' : 'closed'}`} style={{ zIndex: 99999 }}>
              <div style={{ backgroundImage: `url(${galaxyBg})`, backgroundSize: '120%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', width: '80%', height: '90%', left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', marginTop: '12%' }}></div>
              <svg height="90%" width="80%" style={{ left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', marginTop: '12%', display: `${bystep.on === 1 ? '' : 'none'}` }}>
                <line x1="35%" y1="0" x2="40%" y2="10%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="40%" y1="10%" x2="60%" y2="10%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="60%" y1="10%" x2="65%" y2="0%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                <line x1="0%" y1="15%" x2="25%" y2="15%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="25%" y1="15%" x2="27.5%" y2="20%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                <line x1="72.5%" y1="20%" x2="75%" y2="15%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="75%" y1="15%" x2="100%" y2="15%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

              </svg>
              <div className='setCardVideoTextComeBack' style={{ width: '80%', height: '90%', left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', marginTop: '12%', display: `${bystep.on === 1 ? '' : 'none'}` }}>
                COME BACK
              </div>
              <div className='cardvideo' style={{ width: '80%', height: '90%', left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', marginTop: '12%', backgroundColor: 'transparent' }}>
                <div style={{ width: '100%', height: 'fit-content', position: 'absolute', top: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'start', padding: '5px' }}>
                  <i class="fa fa-times" aria-hidden="true" style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => {
                    toggle_modal_modelvideo();
                  }}></i>
                </div>
                {bystep.on === 1 && <>
                  <div className='row' style={{ margin: 0, height: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', zIndex: '9999' }}>
                    <img src={iconskip} style={{ width: '60px', height: '25px', marginLeft: '20px', cursor: 'pointer', marginTop: '35px' }}
                      onClick={(e) => {


                        setbystep({ ...bystep, on: 2 })


                      }}

                    />
                  </div>

                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10%', color: '#0047FF', fontSize: '34px', fontFamily: 'Oxanium', fontWeight: '700', marginBottom: '10px', }}> Upload Photos or VDO impressions of this flight </div>
                  <div style={{ width: '100%', height: '40%', display: 'flex', justifyContent: 'center' }}>

                    <Dragger {...props}
                      onRemove={e => onRemoveFile(e)}
                      fileList={fileDoc}
                      showUploadList={false}
                      multiple
                      style={{ width: '700px' }}
                      disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                      beforeUpload={(file, fileList) => {
                        console.log(file, fileList)
                      }}

                    >
                      <p className="ant-upload-drag-icon">
                        <img src={uploadicon} style={{ width: '10%' }} />
                      </p>
                      <p className="ant-upload-text" style={{
                        color: '#FFF',
                        fontFamily: 'Oxanium',
                        fontSize: '30px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                      }} >DRAG & DROP FILES HERE</p>
                      <p className="ant-upload-text" style={{
                        color: '#FFF',
                        fontFamily: 'Oxanium',
                        fontSize: '30px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                      }} >OR</p>
                      <p className="ant-upload-text" style={{
                        color: '#FFF',
                        fontFamily: 'Oxanium',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        fill: 'linear-gradient(90deg, #051F6C 0%, #16285C 78.79%, #222E51 162.66%)',
                        strokeWidth: '2px',
                        stroke: '#7BD7FF',
                        filter: 'drop-shadow(0px 0px 20px #7BD7FF)',
                      }} >BROWSE FILES</p>

                    </Dragger>




                  </div>
                  <div className='showListFileTTxl mt-2' style={{ height: '10%', overflowY: 'auto', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
                    {fileDoc.map((e, index) => {
                      return (
                        <>
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '45%' }}>
                            <a target="_blank" href={e.url ? `${e.url}` : e.path} style={{ color: '#ffffff', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.ul_file_name}</a>
                            <i class="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => { if (projectDetail.project_pros_id != '7f577a42-41fe-4434-aa4f-b92c3c83e76e') { DelFileList(e.fd_img_id, index) } }}></i>
                          </div>

                        </>
                      )
                    })}
                  </div>
                  <div style={{ width: '100%', height: '40%', display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>

                    <div style={{ textAlign: '-webkit-center', width: '55%', marginTop: '30px' }} >
                      <div style={{ marginLeft: '-73%' }}>Write down your impressions :</div>

                      <textarea className="cardvideo-infoBoxTextIn-arex"
                        id={'descriptionvideo'}
                        type='text'
                        disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                        value={fileImport.remark || ''}
                        onChange={(e) => {
                          autoResize('descriptionvideo')
                          setFileImport({
                            ...fileImport, remark: e.target.value
                          })
                        }}
                      >
                      </textarea>
                      <div style={{ width: '100%', height: '36px', display: 'flex', justifyContent: 'end' }}>
                        <button className=' btn-outline-primary ' style={{ padding: '5px' }}
                          disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                          onClick={() => { savestepone() }}> Save </button>
                      </div>
                    </div>
                  </div>
                </>}

                {bystep.on === 2 && <>


                  <div className='row' style={{ width: '100%', height: '100%', padding: '0', margin: '0' }}>

                    <div className='col-5' style={{ width: '100%', height: '100%', padding: '0', margin: '0' }}>
                      <img src={bystep.imguser} style={{ width: '100%', height: '100%', objectFit: 'cover', opacity: '50%' }} />
                    </div>
                    <div className='col-7' style={{ width: '100%', height: '100%', padding: '0', margin: '3% 0', display: 'flex', justifyContent: 'center' }}>
                      <div className='row' style={{ display: 'flex', justifyContent: 'center', position: 'absolute', marginTop: '6%', width: '75%' }}>
                        <img src={IMGtopVector} style={{ width: '85%', height: '28px' }} />
                      </div>
                      <div className='setCardVideoTextComeBack' style={{ position: 'absolute', top: '13%', fontSize: '47.5px' }}>WRITE A BLESS TO THE BOSS.</div>
                      <div className='row' style={{ display: 'flex', justifyContent: 'center', marginTop: '23%', width: '55%', height: '33%', position: 'absolute' }}>
                        <textarea className="cardvideo-infoBoxTextIn-arex"
                          id={'descriptionvideo'}
                          type='text'
                          style={{ height: '100%' }}
                          value={fileImport.remarktwo || ''}
                          disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                          onChange={(e) => {
                            setFileImport({
                              ...fileImport, remarktwo: e.target.value
                            })
                          }}
                        >
                        </textarea>
                      </div>
                      <div className='row' style={{ width: '100%', height: '36px', display: 'flex', justifyContent: 'center', marginTop: '57.5%', position: 'absolute', zIndex: '9999' }}>
                        <button className=' btn-outline-primary ' style={{ padding: '5px' }}
                          disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                          onClick={() => { savesteptwo() }}> Save </button> <button className=' btn-outline-success '
                            disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                            style={{ padding: '5px', marginLeft: '10px' }} onClick={() => {
                              BackToEart();
                            }}> Back to Earth </button>
                      </div>
                      <div className='row' style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '75%', marginTop: '9%' }}>
                        <img src={IMGtopVectorleft} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(0deg)' }} />
                        <img src={IMGtopVectorright} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(0deg)' }} />
                      </div>
                      <div className='row' style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '75%', marginTop: '55%' }}>
                        <img src={IMGtopVectorright} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(180deg)' }} />
                        <img src={IMGtopVectorleft} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(180deg)' }} />
                      </div>
                    </div>
                  </div>
                </>}

                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', left: '50%', bottom: '15px' }}>
                  {bystep.step.map((e, index) => {
                    return (
                      <>
                        <div style={{ height: '15px', width: '15px', borderRadius: '50px', background: `${e.step === bystep.on ? 'rgb(0,71,255)' : 'rgb(255,255,255,0.5)'}`, margin: '3px', cursor: 'pointer' }}

                          onClick={(x) => {
                            console.log('เปลี่ยนหน้าแบบข้างล่าง');
                            setbystep({ ...bystep, on: e.step })
                          }}
                        >

                        </div>
                      </>
                    )
                  })}


                </div>
              </div>

            </div>
          </> : <>
            <div className={`backdropModuleMenu ${modelvideo ? 'opened' : 'closed'}`} style={{ zIndex: 99999 }}>
              <div style={{
                backgroundImage: `url(${galaxyBg})`, backgroundSize: '120%', backgroundRepeat: 'no-repeat',

                backgroundPosition: 'center', width: '100%', height: '83vh', left: `0%`, top: '1%', position: 'absolute' /* , transform: 'translate(-50%, -22%)' */, marginTop: '12%'
              }}></div>
              <svg height="90%" width="80%" style={{ left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', marginTop: '12%', display: `${bystep.on === 1 ? '' : 'none'}` }}>
                <line x1="35%" y1="0" x2="40%" y2="10%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="40%" y1="10%" x2="60%" y2="10%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="60%" y1="10%" x2="65%" y2="0%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                <line x1="0%" y1="15%" x2="25%" y2="15%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="25%" y1="15%" x2="27.5%" y2="20%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

                <line x1="72.5%" y1="20%" x2="75%" y2="15%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                <line x1="75%" y1="15%" x2="100%" y2="15%" style={{ stroke: '#0047FF', strokeWidth: '2' }} />

              </svg>
              <div className='setCardVideoTextComeBack' style={{ width: '80%', height: '90%', left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', marginTop: '12%', display: `${bystep.on === 1 ? '' : 'none'}` }}>
                COME BACK
              </div>
              <div className='cardvideo' style={{ width: '80%', height: '90%', left: `50%`, position: 'fixed', transform: 'translate(-50%, -22%)', marginTop: '12%', backgroundColor: 'transparent' }}>
                <div style={{ width: '100%', height: 'fit-content', position: 'absolute', top: 0, display: 'flex', justifyContent: 'flex-end', alignItems: 'start', padding: '5px' }}>
                  <i class="fa fa-times" aria-hidden="true" style={{ fontSize: '18px', cursor: 'pointer' }} onClick={() => {
                    toggle_modal_modelvideo();
                  }}></i>
                </div>
                {bystep.on === 1 && <>
                  <div className='row' style={{ margin: 0, height: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', zIndex: '9999' }}>
                    <img src={iconskip} style={{ width: '60px', height: '25px', marginLeft: '20px', cursor: 'pointer', marginTop: '35px' }}
                      onClick={(e) => {


                        setbystep({ ...bystep, on: 2 })


                      }}

                    />
                  </div>

                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '10%', color: '#0047FF', fontSize: '34px', fontFamily: 'Oxanium', fontWeight: '700', marginBottom: '10px', }}> Upload Photos or VDO impressions of this flight </div>
                  <div style={{ width: '100%', height: '40%', display: 'flex', justifyContent: 'center' }}>

                    <Dragger {...props}
                      onRemove={e => onRemoveFile(e)}
                      fileList={fileDoc}
                      showUploadList={false}
                      multiple
                      style={{ width: '700px' }}
                      disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                      beforeUpload={(file, fileList) => {
                        console.log(file, fileList)
                      }}

                    >
                      <p className="ant-upload-drag-icon">
                        <img src={uploadicon} style={{ width: '10%' }} />
                      </p>
                      <p className="ant-upload-text" style={{
                        color: '#FFF',
                        fontFamily: 'Oxanium',
                        fontSize: '30px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                      }} >DRAG & DROP FILES HERE</p>
                      <p className="ant-upload-text" style={{
                        color: '#FFF',
                        fontFamily: 'Oxanium',
                        fontSize: '30px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                      }} >OR</p>
                      <p className="ant-upload-text" style={{
                        color: '#FFF',
                        fontFamily: 'Oxanium',
                        fontSize: '24px',
                        fontStyle: 'normal',
                        fontWeight: '700',
                        lineHeight: 'normal',
                        fill: 'linear-gradient(90deg, #051F6C 0%, #16285C 78.79%, #222E51 162.66%)',
                        strokeWidth: '2px',
                        stroke: '#7BD7FF',
                        filter: 'drop-shadow(0px 0px 20px #7BD7FF)',
                      }} >BROWSE FILES</p>

                    </Dragger>




                  </div>
                  <div className='showListFileTTxl mt-2' style={{ height: '10%', overflowY: 'auto', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flexDirection: 'column' }}>
                    {fileDoc.map((e, index) => {
                      return (
                        <>
                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '45%' }}>
                            <a target="_blank" href={e.url ? `${e.url}` : e.path} style={{ color: '#ffffff', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.ul_file_name}</a>
                            <i class="fa fa-trash" aria-hidden="true" style={{ cursor: 'pointer' }} onClick={() => { if (projectDetail.project_pros_id != '7f577a42-41fe-4434-aa4f-b92c3c83e76e') { DelFileList(e.fd_img_id, index) } }}></i>
                          </div>

                        </>
                      )
                    })}
                  </div>
                  <div style={{ width: '100%', height: '40%', display: 'flex', justifyContent: 'center', marginTop: '-10px' }}>

                    <div style={{ textAlign: '-webkit-center', width: '55%', marginTop: '30px' }} >
                      <div style={{ marginLeft: '-73%' }}>Write down your impressions :</div>

                      <textarea className="cardvideo-infoBoxTextIn-arex"
                        id={'descriptionvideo'}
                        type='text'
                        disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                        value={fileImport.remark || ''}
                        onChange={(e) => {
                          autoResize('descriptionvideo')
                          setFileImport({
                            ...fileImport, remark: e.target.value
                          })
                        }}
                      >
                      </textarea>
                      <div style={{ width: '100%', height: '36px', display: 'flex', justifyContent: 'end' }}>
                        <button className=' btn-outline-primary ' style={{ padding: '5px' }}
                          disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                          onClick={() => { savestepone() }}> Save </button>
                      </div>
                    </div>
                  </div>
                </>}

                {bystep.on === 2 && <>


                  <div className='row' style={{ width: '100%', height: '100%', padding: '0', margin: '0' }}>

                    <div className='col-5' style={{ width: '100%', height: '100%', padding: '0', margin: '0' }}>
                      <img src={bystep.imguser} style={{ width: '100%', height: '100%', objectFit: 'cover', opacity: '50%' }} />
                    </div>
                    <div className='col-7' style={{ width: '100%', height: '100%', padding: '0', margin: '3% 0', display: 'flex', justifyContent: 'center' }}>
                      <div className='row' style={{ display: 'flex', justifyContent: 'center', position: 'absolute', marginTop: '6%', width: '75%' }}>
                        <img src={IMGtopVector} style={{ width: '85%', height: '28px' }} />
                      </div>
                      <div className='setCardVideoTextComeBack' style={{ position: 'absolute', top: '13%', fontSize: '47.5px' }}>WRITE A BLESS TO THE BOSS.</div>
                      <div className='row' style={{ display: 'flex', justifyContent: 'center', marginTop: '23%', width: '55%', height: '33%', position: 'absolute' }}>
                        <textarea className="cardvideo-infoBoxTextIn-arex"
                          id={'descriptionvideo'}
                          type='text'
                          style={{ height: '100%' }}
                          value={fileImport.remarktwo || ''}
                          disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                          onChange={(e) => {
                            setFileImport({
                              ...fileImport, remarktwo: e.target.value
                            })
                          }}
                        >
                        </textarea>
                      </div>
                      <div className='row' style={{ width: '100%', height: '36px', display: 'flex', justifyContent: 'center', marginTop: '57.5%', position: 'absolute', zIndex: '9999' }}>
                        <button className=' btn-outline-primary ' style={{ padding: '5px' }}
                          disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                          onClick={() => { savesteptwo() }}> Save </button> <button className=' btn-outline-success '
                            disabled={projectDetail.project_pros_id == '7f577a42-41fe-4434-aa4f-b92c3c83e76e' ? true : false}
                            style={{ padding: '5px', marginLeft: '10px' }} onClick={() => {
                              BackToEart();
                            }}> Back to Earth </button>
                      </div>
                      <div className='row' style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '75%', marginTop: '9%' }}>
                        <img src={IMGtopVectorleft} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(0deg)' }} />
                        <img src={IMGtopVectorright} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(0deg)' }} />
                      </div>
                      <div className='row' style={{ display: 'flex', justifyContent: 'space-between', position: 'absolute', width: '75%', marginTop: '55%' }}>
                        <img src={IMGtopVectorright} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(180deg)' }} />
                        <img src={IMGtopVectorleft} style={{ width: '8%', height: '8%', objectFit: 'contain', transform: 'rotate(180deg)' }} />
                      </div>
                    </div>
                  </div>
                </>}

                <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', left: '50%', bottom: '15px' }}>
                  {bystep.step.map((e, index) => {
                    return (
                      <>
                        <div style={{ height: '15px', width: '15px', borderRadius: '50px', background: `${e.step === bystep.on ? 'rgb(0,71,255)' : 'rgb(255,255,255,0.5)'}`, margin: '3px', cursor: 'pointer' }}

                          onClick={(x) => {
                            console.log('เปลี่ยนหน้าแบบข้างล่าง');
                            setbystep({ ...bystep, on: e.step })
                          }}
                        >

                        </div>
                      </>
                    )
                  })}


                </div>
              </div>

            </div>
          </>


          :
          <></>
        }

        {mode === 'all' ? (<>
          <div className="col-12 cardstage1" style={{ height: '100%', display: 'flex', flexDirection: 'column' }} >
            <></>
            <div className='row' style={{ display: `${callBackMonitor == false ? '' : 'none'}`, height: '60px' }}>
              <div className='col-7 fontOxygen' style={{ fontSize: '34px', padding: '0px', display: 'flex', gap: '1%', paddingLeft: '6%', paddingTop: '3.5%', zIndex: '0' }}>
                STAGE MONITORING ({projectListResult.length || 0} Project)
                <div className={filterProjectData.onDue == true ? 'filterDueFrameActive' : 'filterDueFrame'}
                  onClick={() => {
                    setFilterProjectData({ ...filterProjectData, onDue: !filterProjectData.onDue });
                    filterProjectData.onDue = !filterProjectData.onDue;
                    filterProject(1, 9999);
                  }}
                >
                  <div className="filterDue">
                  </div>
                </div>
                <div className={filterProjectData.onSpace == true ? 'filterOnSpaceFrameActive' : 'filterOnSpaceFrame'}
                  onClick={() => {
                    setFilterProjectData({ ...filterProjectData, onSpace: !filterProjectData.onSpace });
                    filterProjectData.onSpace = !filterProjectData.onSpace;
                    filterProject(1, 9999);
                  }}
                >
                  <div className="filterOnSpace">
                  </div>
                </div>
                <div className={filterProjectData.onFinish == true ? 'filterFinishFrameActive' : 'filterFinishFrame'}
                  onClick={() => {
                    setFilterProjectData({ ...filterProjectData, onFinish: !filterProjectData.onFinish });
                    filterProjectData.onFinish = !filterProjectData.onFinish;
                    filterProject(1, 9999);
                  }}
                >
                  <div className="filterFinish">
                  </div>
                </div>

                <div className={'mgtSuperDetailClass'}
                  onClick={() => {
                    window.location.href = `/SuperDetailMode`
                  }}
                ><img 
                style={{width:"40px", height:"40px"}}
                src={IconSuperDetail}/>
                </div>

              </div>

              <div className='col-5 fontOxygen' style={{
                fontSize: '34px', padding: '0px', display: 'flex', gap: '1%', paddingLeft: '6%', paddingTop: '3.5%', zIndex: '0',

                alignItems: 'center',
                flexDirection: 'row-reverse',
                paddingRight: '5%'

              }}>
                <input
                  className='inputSearch'
                  placeholder='search'
                  type='text'
                  onChange={(e) => {
                    let temp = projectList;
                    let result = temp.filter((f) => { return (f.nameproject || '').toString().toLowerCase().includes(e.target.value.toLowerCase()) });
                    console.log('result', result)
                    setProjectListResult(result)
                    settest({ ...test, test: '' });
                    test.test = '';
                  }}
                />
              </div>
            </div>


            <div className='fontOxygen' style={{ display: `${callBackMonitor == false ? '' : 'none'}`, fontSize: '24px', height: '40px', paddingLeft: '6%', paddingTop: '3.15%' }}>Recent Projects</div>

            <div style={{ position: 'fixed', border: '1px red', width: '85%', height: '41%', left: '50%', top: '55%', transform: 'translate(-50%,-50%)' }}>
              <Row style={{ display: 'flex', flexWrap: 'wrap', overflowY: 'scroll', gap: '6px', width: '100%', height: '100%', position: 'relative', alignContent: 'flex-start' }}>
                {projectListResult.map((e) => (

                  <div key={e.id} style={{ display: `${callBackMonitor == false ? '' : 'none'}`, margin: 0, padding: 0, }}>
                    <Projectcard
                      callBackMonitoring={callBackMonitoring}
                      allStageMonitoring={params.allStageMonitoring}
                      nameproject={e.nameproject}
                      description={e.description}
                      startdate={e.startdate}
                      enddate={e.enddate}
                      percent={e.percent}
                      id={e.id}
                      returnfuc={getProjectDetail}
                      cardcolor={e.color}
                      reloadSlectProject={() => params.reloadSlectProject(true)}
                      spaceship={e.spaceshipID} pros={e.pros_id}
                      setmode={params.setmode}
                      amode={params.amode}
                      chcekusermode = {chcekusermode}
                    />
                  </div>
                ))}
              </Row>
            </div>

          </div>
          <div style={{ display: `${callBackMonitor == false ? '' : 'none'}`, border: '1px red', width: '100%', height: '100%', position: 'absolute', top: '0', zIndex: '-1' }}>
            <svg height="100%" width="100%">
              <line x1="4%" y1="11%" x2="6%" y2="5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="6%" y1="5%" x2="14%" y2="5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="94%" y1="5%" x2="96%" y2="11%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="94%" y1="5%" x2="86%" y2="5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="5%" y1="0" x2="23%" y2="0" style={{ stroke: '#1C5CFF', strokeWidth: '5' }} />
              <line x1="23%" y1="0" x2="25%" y2="6.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="25%" y1="6.5%" x2="73%" y2="6.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="75%" y1="0" x2="73%" y2="6.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="75%" y1="0" x2="95%" y2="0" style={{ stroke: '#1C5CFF', strokeWidth: '5' }} />
              <line x1="95%" y1="0" x2="100%" y2="15%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="100%" y1="15%" x2="100%" y2="85%" style={{ stroke: '#1C5CFF', strokeWidth: '5' }} />
              <line x1="100%" y1="85%" x2="95%" y2="100%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="95%" y1="100%" x2="77%" y2="100%" style={{ stroke: '#1C5CFF', strokeWidth: '5' }} />
              <line x1="77%" y1="100%" x2="75%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="79%" y1="100%" x2="77%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="81%" y1="100%" x2="79%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="83%" y1="100%" x2="81%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="85%" y1="100%" x2="83%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="87%" y1="100%" x2="85%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="89%" y1="100%" x2="87%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="91%" y1="100%" x2="89%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="89%" y1="93.5%" x2="27%" y2="93.5%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="27%" y1="93.5%" x2="25%" y2="100%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="25%" y1="100%" x2="5%" y2="100%" style={{ stroke: '#1C5CFF', strokeWidth: '5' }} />
              <line x1="5%" y1="100%" x2="0" y2="85%" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
              <line x1="0%" y1="85%" x2="0" y2="15%" style={{ stroke: '#1C5CFF', strokeWidth: '5' }} />
              <line x1="0%" y1="15%" x2="5%" y2="0" style={{ stroke: '#1C5CFF', strokeWidth: '3' }} />
            </svg>
          </div>
        </>)
          :
          (<>
            {switModeShowDisplay ? <>
              <div className='leftBody'>
                <div className='row mainbody-left' style={{ margin: 0 }}>
                  <div className='col-12 col-xl-9' style={{ display: 'flex', padding: 0 }}>
                    <div className='' style={{ height: '100%', /* width: '2.5%',minWidth:'48px'   */ }}>
                      <div className='box-left-out'>
                        <img style={{ cursor: 'pointer', width: "24px", height: "24px" }}
                          src={iconback_doorStage}
                          onClick={() => {
                            sessionStorage.setItem('pageNow','StageMonitoring')
                            socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'StageMonitoring'});
                            togle_siwmode();
                            clearQueryParams()
                            params.toggle_clear_classname();
                            params.callBackModalSrs({ isOpen: false, onMenu: '', nameMenu: '' })
                            params.allStageMonitoring(false)
                            callBackMonitoring(false)
                            setmode('all')
                            filterProject(1, 9999)
                          }} />
                      </div>
                    </div>
                    <div className='' style={{ position: 'relative'/* , width: '28%'  */, flexGrow: 1 }}>
                      <div className='fontOxygen' style={{ fontSize: '16px', position: 'relative', top: '0px', left: '1%', zIndex: 21 }} >{projectDetail.project_name || ''}</div>
                      <div className='fontOxygen' style={{ fontSize: '24px', fontWeight: '700', position: 'relative', top: '0px', left: '1%', zIndex: 20 }}>PROJECT PROGRESS</div>
                      <div className='fontOxygen' style={{ color: '#57ceef', fontSize: '34px', position: 'relative', top: '-2px', left: '1%', zIndex: 20 }}>{parseFloat(projectDetail.project_progress || 0).toFixed(2)} %</div>
                    </div>
                    <div className='' style={{ position: 'relative', height: '100%', display: 'flex', alignItems: 'center' /*  width: '13%', padding: '5px'  */ }}>
                      <div className='row' style={{ padding: '0', margin: '0', height: 'fit-content', width: '200px' }}>
                        <div className={`${acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? 'col-3' : 'col-4'}`}>
                          <div className="AcceptMissionDone" style={{}}
                            onClick={() => {
                              checkProjectCompletion(handleClickAccept);
                            }}
                          >
                            <div className={acceptClass}></div>
                            <span className='fontOxygen' style={{ fontSize: "9px" }}>ACCEPT</span>
                          </div>
                        </div>
                        {acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ?
                          <div className={`${acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? 'col-3' : 'col-4'}`} style={{ padding: '0', margin: '0' }}>

                            <div className='backToEarth' style={{
                              cursor: 'pointer', visibility: `${projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? '' : ''}`,
                              display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '25%'
                            }}>
                              <div className='backToEarthIn' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                onClick={() => {
                                  GetAccesstokenToken();
                                  getimguser();
                                  toggle_modal_modelvideo();
                                }}>
                                <span className='fontOxygen' style={{ fontSize: "9px", whiteSpace: 'nowrap', marginTop: '55px' }}>
                                  BACK
                                </span>
                              </div>

                            </div>
                          </div>
                          : <></>}
                        <div className={`${acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? 'col-3' : 'col-4'}`} style={{ padding: '0', margin: '0' }}>

                          <div className='BackgroundMenu' style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '7px' }}>
                            <div className={filterMenu.menu == true ? 'MenuActive' : 'Menu'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px'/* , marginLeft: '25%' */ }}
                              onClick={() => {
                                setFilterMenu({ ...filterMenu, menu: !filterMenu.menu });
                                filterMenu.menu = !filterMenu.menu;
                                filterMenuFeature();
                              }}
                            >
                              <img style={{ width: '18px', height: "18px" }} src={IconMenu}></img>
                            </div>
                            <span className='fontOxygen' style={{ fontSize: "9px"/* , marginLeft: '27%' */ }}>MENU</span>
                          </div>
                        </div>
                        <div className={`${acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? 'col-3' : 'col-4'}`}>
                          <div className='BackgroundFeature'>
                            <div className={filterMenu.feature == true ? 'MenuActive' : 'Menu'}
                              onClick={() => {
                                setFilterMenu({ ...filterMenu, feature: !filterMenu.feature });
                                filterMenu.feature = !filterMenu.feature;
                                filterMenuFeature();
                              }}
                              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px' /* , marginLeft: '25%' */ }}>
                              <img style={{ width: '18px', height: "18px" }} src={IconFeature}></img>
                            </div>
                            <span className='fontOxygen' style={{ fontSize: "9px" }}>FEATURE</span>
                          </div>
                        </div>
                        <div className={`${acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? 'col-3' : 'col-4'}`}>
                          <div className='BackgroundQuick' style={{ cursor: `${getModeCustomer  != true ? 'pointer' : 'no-drop'} ` }}>
                            <div className='Menu' style={{cursor: `${getModeCustomer  != true ? 'pointer' : 'no-drop'} `, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0', marginLeft: '0', backgroundColor: buttonBgColor }}
                              onClick={() => {
                                if(getModeCustomer != 'true'){
                                  if (showBox) {
                                    setShowBox(false)
                                  } else {
                                    setShowBox(true)
                                  }
                                }
                                

                              }}
                            >

                              <img
                                style={{ width: '18px', height: '18px' }}
                                src={buttonBgColor === '#FFFF00' ? IconQuickFilled : IconQuick}
                                alt="icon"
                              />
                            </div>
                            <span className='fontOxygen' style={{ fontSize: "10px", marginLeft: '0' }}>QUICK</span>
                            {showBox && (

<div ref={boxRef} style={{
  position: 'absolute',
  top: 'calc(60% + 0px)',
  left: 'calc(44.5% + 17px)',
  width: '135px',
  height: '50px',
  border: '1px solid #007bff',
  backgroundColor: 'black',
  zIndex: '999',
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  padding: '5px'
}}>
  {/* อันนี้ */}
  <div className='colorProcessBody_' >
    <div className={`colorProcessBoxClick1 ${buttonBgColor == '#808080' ? 'active' : ''}`} style={{ width: "30%", padding: "2px" }}
      onClick={() => {
        handleColorClick('#808080')
      }}
    >

      <div className='colorProcessBoxClick1Box' style={{ border: '1px solid white' }}>
        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: '#676767' }}></div>
        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: '#676767' }}></div>
      </div>
      <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: 'white', strokeWidth: '1px' }} />
        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: 'white', strokeWidth: '1px' }} />
        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: 'white', strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
      </svg>
    </div>

    <div className={`colorProcessBoxClick2 ${buttonBgColor == '#F00' ? 'active' : ''}`} style={{ width: "30%" }}
      onClick={() => {
        handleColorClick('#F00')

      }}
    >
      <div className='colorProcessBoxClick1Box' style={{ border: '1px solid white' }}>
        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}></div>
        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}></div>
      </div>
      <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: 'white', strokeWidth: '1px' }} />
        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: 'white', strokeWidth: '1px' }} />
        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: 'white', strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
      </svg>
    </div>

    <div className={`colorProcessBoxClick3 ${buttonBgColor == '#FFFF00' ? 'active' : ''}`} style={{ width: "30%" }}
      onClick={() => {
        handleColorClick('#FFFF00')
      }}
    >
      <div className='colorProcessBoxClick1Box' style={{ border: '1px solid white' }}>
        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}></div>
        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}></div>
      </div>
      <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: 'white', strokeWidth: '1px' }} />
        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: 'white', strokeWidth: '1px' }} />
        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: 'white', strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
      </svg>
    </div>
  </div>
</div>
)}
                          </div>
                        </div>
                        <div className={`${acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? 'col-3' : 'col-4'}`} style={{}}>
                          <div onClick={toggleModalOpenApi}>
                            <div className='Menu' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                              <img style={{ width: '18px', height: "18px" }} src={IconOpenApi}></img>
                            </div>
                            <span className='fontOxygen' style={{ fontSize: "9px", position: 'relative', left: '5px' }}>API.</span>
                          </div>
                        </div>
                        <div className={`${acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? 'col-3' : 'col-4'}`}>
                          <div onClick={toggle_modal_dba}>
                            <div className='Menu' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                              <img style={{ width: '18px', height: "18px" }} src={IconElementDBA}></img>
                            </div>
                            <span className='fontOxygen' style={{ fontSize: "9px", position: 'relative', left: '5px' }}>DBA.</span>
                          </div>
                        </div>

                      </div>

                    </div>
                    <div className='' style={{ position: 'relative', /* width: '37%',  */ }}>
                      <div className='row' style={{ height: '100%', width: '100%', padding: '0px', margin: '0', gap: '0px' }}>
                        <div className='col-3' style={{ padding: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }}>
                          <div className='' style={{ border: '1px solid #011923', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', borderRadius: '8px', backgroundColor: '#011923', color: '#FFFF', boxShadow: '0px 0px 8px 0px #011923' }}>
                            <div className='' style={{
                              border: '2px solid #1893b3', height: '70px', width: '70px', borderRadius: '60px', fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', cursor: 'pointer',
                              display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 19px 0px #0047FFBF'
                            }}
                            >
                              {projectDetail.all_todo_count || 0}
                            </div>
                            <div className='fontOxygen mt-1' style={{ fontSize: '15px', marginLeft: "0px", display: 'flex', textAlign: 'center', whiteSpace: 'nowrap' }} >ALL TO DO</div>
                          </div>
                        </div>
                        <div className='col-3' style={{ padding: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }}>
                          <div className='' style={{ border: '1px solid #011923', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', borderRadius: '8px', backgroundColor: '#011923', color: '#FFFF', boxShadow: '0px 0px 8px 0px #011923' }}>
                            <div className='' style={{
                              border: '2px solid #1893b3', height: '70px', width: '70px', borderRadius: '60px', fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 1px 1px 8px 1px #0047FFBF', cursor: 'pointer',
                              display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 19px 0px #0047FFBF'
                            }}
                              onClick={() => {
                                setStageViewStatus('Waiting');
                                toggle_modal_filter_stage();
                              }}
                            >
                              {projectDetail.todo_count || 0}
                            </div>
                            <div className='fontOxygen mt-1' style={{ fontSize: '15px', marginLeft: "0px", display: 'flex', textAlign: 'center' }} >TO DO</div>
                          </div>
                        </div>
                        <div className='col-3' style={{ padding: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }}>
                          <div className='' style={{ border: '1px solid #011923', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', borderRadius: '8px', backgroundColor: '#011923', color: '#FFFF', boxShadow: '0px 0px 8px 0px #011923' }}>
                            <div className='' style={{
                              border: '2px solid #1893b3', height: '70px', width: '70px', borderRadius: '60px', fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', cursor: 'pointer',
                              display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 19px 0px #0047FFBF'
                            }}
                              onClick={() => {
                                setStageViewStatus('Done');
                                toggle_modal_filter_stage();
                              }}
                            >
                              {projectDetail.done_count || 0}
                            </div>
                            <div className='fontOxygen mt-1' style={{ fontSize: '15px', marginLeft: "0px", display: 'flex', textAlign: 'center' }} >DONE</div>
                          </div>
                        </div>
                        <div className='col-3' style={{ padding: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'flex-start', position: 'relative' }}>
                          <div className='' style={{ border: '1px solid #011923', display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center', height: '100%', width: '100%', borderRadius: '8px', backgroundColor: '#011923', color: '#FFFF', boxShadow: '0px 0px 8px 0px #011923' }}>
                            <div className='' style={{
                              border: '2px solid #1893b3', height: '70px', width: '70px', borderRadius: '60px', fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', cursor: 'pointer',
                              display: 'flex', alignItems: 'center', justifyContent: 'center', boxShadow: '0px 0px 19px 0px #0047FFBF'
                            }}
                              onClick={() => {
                                setStageViewStatus('In process');
                                toggle_modal_filter_stage();
                              }}
                            >
                              {projectDetail.doing_count || 0}
                            </div>
                            <div className='fontOxygen mt-1' style={{ fontSize: '15px', marginLeft: "0px", display: 'flex', textAlign: 'center' }} >DOING</div>
                          </div>
                        </div>
                      </div>
                    </div>
                   
                  </div>

                  <div className='col-12 col-xl-3' style={{ padding: 0 }}>
                    <div className='' style={{ position: 'relative', /* width: '20%', */padding: '0', margin: '0', height: '100%' }}>
                      {projectDetail.processList.reduce((rows, e, i) => {
                        if (i % 8 === 0) rows.push([]);
                        rows[rows.length - 1].push(e);
                        return rows;
                      }, []).map((row, rowIndex) => (
                        <div className='row' style={{ height: '50%', minHeight: '70px', width: '100%', padding: '0px', margin: '0', gap: '0px' }}>
                          {row.map((e, i) => (
                            <div className='col col-xl-3' key={i}
                              style={{ cursor: e.process_id == 'e9ae4d57-077f-414f-9eb2-22f4c4a109c7' || e.process_id == "23b99e02-cfeb-4808-a5ed-99540ae2d806" ? 'pointer' : '', /* height: '100%', */ width: '100%', padding: '3px', margin: '0', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                              onClick={() => {
                                if (e.process_id == 'e9ae4d57-077f-414f-9eb2-22f4c4a109c7') {
                                  toggle_modal_process_mockup();
                                } else if (e.process_id == "23b99e02-cfeb-4808-a5ed-99540ae2d806") {
                                  toggle_modal_process_srs();
                                }
                              }}>
                              <div style={{ border: '2px solid #3a7595', height: '100%', minHeight: '70px', width: '100%', borderRadius: '8px', backgroundColor: '#020d2b', boxShadow: '0px 0px 8px 0px #0047FFBF', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                <div className={`fontOxygen ${i + 1 === row.length}`} style={{ fontSize: '10px', textAlign: "center" }}>{e.process_name}</div>
                                <div className='fontOxygen' style={{ fontSize: '14px', color: '#0047FF', filter: 'box-shadow: 0px 0px 4px 0px #0047FFBF', marginTop: "0px" }}>{e.percent || 0}%</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      ))
                      }
                    </div>
                  </div>
                </div>

              </div>
            </> : <>
              <div className='cardboxstagemonitoring col-3'>
                <div className="col-12 cardstage " >

                  <img style={{ cursor: 'pointer', position: 'absolute', width: "36px", height: "36px", marginTop: "8px" }} src={iconback_doorStage} onClick={() => {
                    sessionStorage.setItem('pageNow','StageMonitoring')
                    sessionStorage.removeItem('project_id');
                    socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'StageMonitoring'});
                    clearQueryParams()
                    params.callBackModalSrs({ isOpen: false, onMenu: '', nameMenu: '' })
                    params.allStageMonitoring(false)
                    callBackMonitoring(false)
                    setmode('all')
                    filterProject(1, 9999)
                  }} />

                  <div className='fontOxygen scroll' style={{ fontSize: '16px', marginLeft: '17%', zIndex: 21 }}>{projectDetail.project_name}</div>
                  <div className='fontOxygen' style={{ fontSize: '26px', marginTop: '-5px', zIndex: 20, marginLeft: '17%' }}>PROJECT PROGRESS</div>


                </div>
                <div style={{ position: "relative", justifyContent: "center", display: "grid" }}>
                  <CardHalfCircle percent={parseFloat(projectDetail.project_progress || 0).toFixed(2)} />
                  <div className='fontOxygen' style={{ fontSize: '24px', marginTop: '-39px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', position: "relative", zIndex: "2", textAlign: "center" }}>{parseFloat(projectDetail.project_progress !== undefined && projectDetail.project_progress !== null && projectDetail.project_progress !== '' && projectDetail.project_progress !== 'NaN' ? projectDetail.project_progress : 0).toFixed(2).toString()}%</div>

                </div>
                <div className='d-flex justify-content-around mt-4'>
                  <div className='blueCircle fontOxygen ' style={{ fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF' }} >{projectDetail.all_todo_count}</div>
                  <div className='blueCircle fontOxygen '
                    style={{ fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', cursor: 'pointer' }}
                    onClick={() => {
                      setStageViewStatus('Waiting');
                      toggle_modal_filter_stage();
                    }}

                  >{projectDetail.todo_count}</div>
                  <div className='blueCircle fontOxygen '
                    style={{ fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', cursor: 'pointer' }}
                    onClick={() => {
                      setStageViewStatus('Done');
                      toggle_modal_filter_stage();
                    }}

                  >{projectDetail.done_count}</div>
                  <div className='blueCircle fontOxygen '
                    style={{ fontSize: '23px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', cursor: 'pointer' }}
                    onClick={() => {
                      setStageViewStatus('In process');
                      toggle_modal_filter_stage();
                    }}

                  >{projectDetail.doing_count}</div>
                </div>

                <div className='col-12 d-flex p-0 mt-2 justify-content-around'>
                  <div className='fontOxygen ' style={{ fontSize: '15px', marginLeft: "-15px" }} >ALL TO DO</div>
                  <div className='fontOxygen ' style={{ fontSize: '15px', marginLeft: "-12px" }} >TO DO</div>
                  <div className='fontOxygen ' style={{ fontSize: '15px' }} >DONE</div>
                  <div className='fontOxygen ' style={{ fontSize: '15px' }} >DOING</div>
                </div>
              </div>
              <div className='col-4' style={{ top: '5%', left: '26.5%' }}>
                <div className='pentagon ' style={{}}>
                  <div className="AcceptMissionDone" style={{ marginTop: '23px', marginLeft: '25%' }}
                    onClick={() => {
                      checkProjectCompletion(handleClickAccept);
                    }}
                  >
                    <div className={acceptClass}></div>
                    <span className='fontOxygen' style={{ fontSize: "10px", marginLeft: '-3px' }}>ACCEPT</span>
                  </div>
                  {acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? <div className='backToEarth' style={{ cursor: 'pointer', visibility: `${projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ? '' : 'hidden'}`, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '27px', marginLeft: '25%' }}>
                    <div className='backToEarthIn' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                      GetAccesstokenToken();
                      getimguser();
                      toggle_modal_modelvideo();
                    }}>
                      <span className='fontOxygen' style={{ fontSize: "10px", whiteSpace: 'nowrap', marginTop: '55px' }}>
                        BACK EARTH
                      </span>
                    </div>

                  </div> : <></>}

                  <div className='BackgroundMenu'>
                    <div className={filterMenu.menu == true ? 'MenuActive' : 'Menu'} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '30px', marginLeft: '25%' }}
                      onClick={() => {
                        setFilterMenu({ ...filterMenu, menu: !filterMenu.menu });
                        filterMenu.menu = !filterMenu.menu;
                        console.log('projectDetailFeet.moduleList', projectDetailFeet)
                        filterMenuFeature();
                      }}
                    >
                      <img style={{ width: '18px', height: "18px" }} src={IconMenu}></img>
                    </div>
                    <span className='fontOxygen' style={{ fontSize: "10px", marginLeft: '27%' }}>MENU</span>
                  </div>
                  <div className='BackgroundFeature'>
                    <div className={filterMenu.feature == true ? 'MenuActive' : 'Menu'}
                      onClick={() => {
                        setFilterMenu({ ...filterMenu, feature: !filterMenu.feature });
                        filterMenu.feature = !filterMenu.feature;
                        filterMenuFeature();



                      }}
                      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px', marginLeft: '25%' }}>
                      <img style={{ width: '18px', height: "18px" }} src={IconFeature}></img>
                    </div>
                    <span className='fontOxygen' style={{ fontSize: "10px", marginLeft: '15%' }}>FEATURE</span>
                  </div>
                  <div className='BackgroundQuick'  style={{ cursor: `${getModeCustomer  != true ? 'pointer' : 'no-drop'} ` }}>
                    <div className='Menu' style={{cursor: `${getModeCustomer  != true ? 'pointer' : 'no-drop'} `, display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px', marginLeft: '25%', backgroundColor: buttonBgColor }}
                      onClick={() => {
                        if(getModeCustomer != true){
                          if (showBox) {
                            setShowBox(false)
                          } else {
                            setShowBox(true)
                          }
                        }
                        

                      }}
                    >

                      <img
                        style={{ width: '18px', height: '18px' }}
                        src={buttonBgColor === '#FFFF00' ? IconQuickFilled : IconQuick}
                        alt="icon"
                      />
                    </div>
                    <span className='fontOxygen' style={{ fontSize: "10px", marginLeft: '26.5%' }}>QUICK</span>
                  </div>

                  <div onClick={toggleModalOpenApi}>
                    <div className='Menu' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px', marginLeft: '25%' }}>
                      <img style={{ width: '18px', height: "18px" }} src={IconOpenApi}></img>
                    </div>
                    <span className='fontOxygen' style={{ fontSize: "10px", marginLeft: '17%' }}>OPEN API</span>
                  </div>

                  <div onClick={toggle_modal_dba}>
                    <div className='Menu' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '12px', marginLeft: '25%' }}>
                      <img style={{ width: '18px', height: "18px" }} src={IconElementDBA}></img>
                    </div>
                    <span className='fontOxygen' style={{ fontSize: "10px", marginLeft: '17%' }}>DBA SYNC</span>
                  </div>
                  <svg height="calc(100% + 2.5px)" width="calc(100% + 2.5px)" style={{ position: 'absolute', top: '-1px', right: '-37px', zIndex: '-1' }}>
                    {acceptClass == 'AcceptMissionDoneActived' && projectDetail.isleader == true && projectDetail.isownerprojectcheck == true ?
                      <line x1="0" y1="0" x2="29.5px" y2="26.5px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                      :
                      <line x1="0" y1="0" x2="30.5px" y2="26px" style={{ stroke: '#0047FF', strokeWidth: '2' }} />
                    }
                  </svg>
                </div>
              </div>

              {showBox && (

                <div ref={boxRef} style={{
                  position: 'absolute',
                  top: 'calc(5% + 180px)',
                  left: 'calc(26.5% + 67px)',
                  width: '135px',
                  height: '50px',
                  border: '1px solid #007bff',
                  backgroundColor: 'black',
                  zIndex: '999',
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  padding: '5px'
                }}>
                  <div className='colorProcessBody_' >
                    <div className={`colorProcessBoxClick1 ${buttonBgColor == '#808080' ? 'active' : ''}`} style={{ width: "30%", padding: "2px" }}
                      onClick={() => {
                        handleColorClick('#808080')
                      }}
                    >

                      <div className='colorProcessBoxClick1Box' style={{ border: '1px solid white' }}>
                        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: '#676767' }}></div>
                        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: '#676767' }}></div>
                      </div>
                      <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: 'white', strokeWidth: '1px' }} />
                        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: 'white', strokeWidth: '1px' }} />
                        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: 'white', strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                      </svg>
                    </div>

                    <div className={`colorProcessBoxClick2 ${buttonBgColor == '#F00' ? 'active' : ''}`} style={{ width: "30%" }}
                      onClick={() => {
                        handleColorClick('#F00')

                      }}
                    >
                      <div className='colorProcessBoxClick1Box' style={{ border: '1px solid white' }}>
                        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}></div>
                        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: 'rgba(255, 0, 0, 1)' }}></div>
                      </div>
                      <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: 'white', strokeWidth: '1px' }} />
                        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: 'white', strokeWidth: '1px' }} />
                        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: 'white', strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                      </svg>
                    </div>

                    <div className={`colorProcessBoxClick3 ${buttonBgColor == '#FFFF00' ? 'active' : ''}`} style={{ width: "30%" }}
                      onClick={() => {
                        handleColorClick('#FFFF00')
                      }}
                    >
                      <div className='colorProcessBoxClick1Box' style={{ border: '1px solid white' }}>
                        <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}></div>
                        <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: 'rgba(255, 235, 59, 1)' }}></div>
                      </div>
                      <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                        <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: 'white', strokeWidth: '1px' }} />
                        <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: 'white', strokeWidth: '1px' }} />
                        <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: 'white', strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                      </svg>
                    </div>
                  </div>
                </div>
              )}
              <div className='carddetailbottom col-3 d-flex flex-wrap'>
                {
                  projectDetail.processList.reduce((rows, e, i) => {
                    if (i % 4 === 0) rows.push([]);
                    rows[rows.length - 1].push(e);
                    return rows;
                  }, []).map((row, rowIndex) => (
                    <div className='row col-12 align-items-center' key={rowIndex} style={{ textAlign: "-webkit-center" }}>
                      {row.map((e, i) => (
                        <div className='col-3' key={i}
                          style={{ cursor: e.process_id == 'e9ae4d57-077f-414f-9eb2-22f4c4a109c7' || e.process_id == "23b99e02-cfeb-4808-a5ed-99540ae2d806" ? 'pointer' : '' }}
                          onClick={() => {
                            if (e.process_id == 'e9ae4d57-077f-414f-9eb2-22f4c4a109c7') {
                              toggle_modal_process_mockup();
                            } else if (e.process_id == "23b99e02-cfeb-4808-a5ed-99540ae2d806") {
                              toggle_modal_process_srs();
                            }
                          }}>
                          <div className='carddetaitstagemonitoring'>
                            <div className={`fontOxygen ${i + 1 === row.length}`} style={{ fontSize: '12px', textAlign: "center" }}>{e.process_name}</div>
                            <div className='fontOxygen' style={{ fontSize: '16px', color: '#0047FF', filter: 'box-shadow: 0px 0px 8px 0px #0047FFBF', marginTop: "-9px" }}>{e.percent || 0}%</div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ))
                }
              </div>
            </>}

            {switModeShowDisplay ? <>
              <div className='betweenBody'>
                <div className='mainbody-between'>
                  <div className='tapcardModule_new1 col-12' style={{ position: 'relative', top: '8px' }} id='moduleMenuDiv'>

                    <div className='row'>

                      <div className='d-flex col-12 col-xl-12' style={{ padding: 0 }}>
                       
                        {<div className='detialmodule' style={{ /* marginLeft: '38px' */ }}> MODULE : {projectDetail != "" ? projectDetail.moduleList.length : 0}</div>}
                        {<div className='detialmodule' style={{ /* marginLeft: '10px' */ }}> MENU : {getCountMenu || 0}</div>}
                        {<div className='detialmodule' style={{ /* marginLeft: '10px' */ }}> FEATURE : {getCountFeature || 0}</div>}
                        {userismode === false ? (
                          <a className='fontOxygen' onClick={() => {
                            params.setProject(projectDetail.project_id); params.toggle_modal_import();

                          }} style={{ cursor: 'pointer', fontSize: '16px', display: 'block', marginTop: '4px' }}  >

                            <img style={{ width: '20px', marginLeft: '20px', marginRight: '10px', marginTop: '', transform: 'rotate(0.5turn)' }} src={Iconexport}></img>

                            IMPORT</a>) : <></>}
                        <a className='fontOxygen' onClick={() => {
                          exportToExcel()

                        }} style={{ cursor: 'pointer', fontSize: '16px', display: 'block', marginTop: '4px' }}  >
                          <img style={{ width: '20px', marginLeft: '20px', marginRight: '10px', marginTop: '-6px' }} src={Iconexport}></img>
                          <div style={{ display: 'none' }}>
                            <ReactHTMLTableToExcel
                              id="exportStageMonitoringBtn"

                              table="exportStageMonitoring"
                              filename={
                                "Stage Monitoring (" + projectDetail.project_name + ") " +
                                moment(new Date()).format("DD-MM-YYYY")
                              }
                              sheet="stage_monitoring"
                              buttonText="Download as XLS"
                            />
                          </div>
                          EXPORT</a>
                        {userismode === false ? (<>
                          <a onClick={() => { params.setProject(projectDetail.project_id); params.toggle_modal_add_module(); }} style={{ cursor: 'pointer', fontSize: '16px', display: 'block', marginTop: '5px' }}  >

                            <img style={{ width: '16px', marginLeft: '20px', marginBottom: '2px', marginRight: '10px' }} src={poin} /> ADD MODULE</a>
                        </>
                        ) : <></>}
                        {editMode && (
                          <div style={{ position: 'relative', left: '1%', top: '3px', display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <button className='btn-outline-primary mr-2' style={{ fontSize: '15px', padding: '2px' }} onClick={() => { saveFillProcessStage() }}>
                              Save
                            </button>
                            <button className='btn-outline-danger' style={{ fontSize: '15px', padding: '2px' }} onClick={handleCancel}>
                              Cancel
                            </button>
                          </div>
                        )}
                      </div>


                     {/*  <div className='col-12 col-xl-2' style={{ display: 'flex', justifyContent: 'flex-end' }}> */}
                      {/*   <div className='row'>

                          <ConfigProvider
                            theme={{
                              token: {

                                colorInfo: '#85BFFF',
                                colorBorder: '#85BFFF',
                                colorText: '#007389',
                                colorTextDescription: '#007389',
                                colorBgContainer: '#000',
                                colorPrimaryBg: 'blue',
                                colorBgBase: '#778899',
                                colorTextQuaternary: '#8c8c8c',
                                colorFillTertiary: '#000',
                                colorTextBase: 'blue',
                                colorPrimaryBg: '#85BFFF',
                              }
                            }}
                          >
                            <Switch style={{ marginTop: '5px' }} defaultChecked checked={switModeShowDisplay} onChange={() => {
                              params.toggle_clear_classname();
                              togle_siwmode();

                            }} /> &nbsp;{' '}<span className='fontOxygen' style={{ color: '#57ceef', fontSize: '24px', }}> {switModeShowDisplay ? ' Horizontal' : 'Vertical '} </span>
                          </ConfigProvider>
                          <div className='btn-hide-module ' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                            let temp = projectDetail.moduleList || [];
                            for (let item of temp) {
                              item.isuse = !isHideModule == true ? false : true;
                            }
                            setIsHideModule(!isHideModule);
                            setProjectDetail({ ...projectDetail, moduleList: temp });
                            projectDetail.moduleList = temp;
                          }}><span className='fontOxygen' style={{ fontSize: '15px', paddingTop: '2px' }}>{isHideModule ? 'Show' : 'Hide'}</span></div>
                        </div> */}


                    {/*   </div> */}
                    </div>
                      <div className='row' style={{padding:0,margin:0,display:'flex',justifyContent:'flex-end',alignItems:'center'}}>

                          <ConfigProvider
                            theme={{
                              token: {

                                colorInfo: '#85BFFF',
                                colorBorder: '#85BFFF',
                                colorText: '#007389',
                                colorTextDescription: '#007389',
                                colorBgContainer: '#000',
                                colorPrimaryBg: 'blue',
                                colorBgBase: '#778899',
                                colorTextQuaternary: '#8c8c8c',
                                colorFillTertiary: '#000',
                                colorTextBase: 'blue',
                                colorPrimaryBg: '#85BFFF',
                              }
                            }}
                          >
                            <Switch style={{ marginTop: '1px' }} defaultChecked checked={switModeShowDisplay} onChange={() => {
                              params.toggle_clear_classname();
                              togle_siwmode();

                            }} /> &nbsp;{' '}<span className='fontOxygen' style={{ color: '#57ceef', fontSize: '24px', }}> {switModeShowDisplay ? ' Horizontal ' : 'Vertical '} </span>
                          
                          <Checkbox style={{marginLeft:'10px',color: '#57ceef', fontSize: '22px'}}checked={allToDoChecked} onChange={(value)=>{
                   
                   filterAllToDo(value.target.checked);
                  }}>
        All To Do
       </Checkbox>
                          </ConfigProvider>
                          <div className='btn-hide-module ' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginLeft:'8px' }} onClick={() => {
                            let temp = projectDetail.moduleList || [];
                            for (let item of temp) {
                              item.isuse = !isHideModule == true ? false : true;
                            }
                            setIsHideModule(!isHideModule);
                            setProjectDetail({ ...projectDetail, moduleList: temp });
                            projectDetail.moduleList = temp;
                          }}><span className='fontOxygen' style={{ fontSize: '15px', paddingTop: '2px' }}>{isHideModule ? 'Show' : 'Hide'}</span></div>
                        </div>

                  </div>

                </div>
              </div>
            </> : <></>}


            {switModeShowDisplay ? <>
              <div className='rightBody'>
                <div className='mainbody-right'>
                  <div className='tapcardModule_new1_body col-12 mt-4' style={{ position: 'relative', top: '13%' }} id='moduleMenuDiv'>

                    {projectDetail.moduleList && projectDetail.moduleList.map((e, i) => {
                      return <Cardmodule
                        clearStatus={clearStatus}
                        selectedColor={buttonBgColor}
                        mode={editMode}
                        callback={callback}
                        filterMenu={filterMenu}

                        CalPosition={(xx, yy, key) => {
                          if (key === 'stage') {
                            toggle_modal_status();
                          } else if (key === 'stakeholder') {
                            toggle_modal_stakeholder();
                          }

                          setPosition({ ...position, posX: xx, posY: yy })
                          position.posX = xx;
                          position.posY = yy;
                        }}

                        setID={(process_id, menu_id) => {
                          setMonitoringTarget({ ...monitoringTarget, process_id: process_id, menu_id: menu_id })

                        }}

                        setID2={(menu_id, stakeholder_list, qdc, module_id) => {
                          setTags(stakeholder_list || []);
                          setQDC(qdc);
                          setStakeholder({ ...stakeholder, menu_id: menu_id, module_id: module_id });


                        }}
                        GetModuleDeatil={GetModuleDeatil}
                        toggle_modal_config_module={toggle_modal_config_module}

                        onFillStatusUpdate={handleFillStatusUpdate}

                        data={e} processList={projectDetail.processList || []} setisusemodule={switctisuse} userismode={userismode} />
                    })}

                    <div style={{ display: 'none' }}>
                      <table style={{ border: '1px solid #000' }} id='exportStageMonitoring'>
                        <tr>
                          <td colspan={projectDetail.processList.length + 4} style={{ textAlign: 'center', border: '1px solid red' }}><font size="20">{projectDetail.project_name} Stage Monitoring : </font><font color="blue" size="20">Progress {projectDetail.project_progress}% </font></td>
                        </tr>

                        <tr>
                          <td colspan={1} style={{ border: '1px solid #000', height: '50px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Module</td>
                          {projectDetail.processList && projectDetail.processList.map((precess, ip) => {
                            return (<>
                              <td colspan={1} style={{ border: '1px solid #000', width: '100px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>{precess.process_name}</td>
                            </>)

                          })}

                          <td colspan={1} style={{ border: '1px solid #000', width: '120px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Remark</td>
                          <td colspan={1} style={{ border: '1px solid #000', width: '120px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Due Date</td>
                          <td colspan={1} style={{ border: '1px solid #000', width: '120px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Actual Date </td>

                        </tr>
                        {projectDetail.moduleList && projectDetail.moduleList.map((module, i) => {
                          return (<>
                            <tr>

                              <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px", backgroundColor: '#B0E0E6' }}>{module.module_name}</td>

                              <td colspan={projectDetail.processList.length} style={{ border: '1px solid #000', fontSize: "18px", backgroundColor: '#B0E0E6' }}></td>

                              <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px" }}>{module.module_remark || ''}</td>
                              <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px" }}>{module.module_end_date ? moment(module.module_end_date).format('DD/MM/YYYY') : ''}</td>
                              <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px" }}>{module.module_actual_date ? moment(module.module_actual_date).format('DD/MM/YYYY') : ''}</td>

                            </tr>
                            {module.module_menu_list.map((menu, i2) => {

                              return (<>
                                <tr>

                                  <td colspan={1} style={{ border: '1px solid #000', fontSize: "16px" }}>{menu.menu_name}</td>
                                  {menu.process_list.map((e) => {

                                    let key_status = '-';
                                    if (e.ps_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188') {
                                      key_status = 'o';
                                    } else if (e.ps_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8') {
                                      key_status = 'x';
                                    } else if (e.ps_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e') {
                                      key_status = 'i';
                                    }
                                    return (<>
                                      <td colspan={1} style={{ border: '1px solid #000', backgroundColor: e.ps_color, textAlign: 'center' }}>{key_status}</td>
                                    </>)
                                  })}<td colspan={1} style={{ border: '1px solid #000' }}>{menu.menu_remark}</td>
                                  <td colspan={1} style={{ border: '1px solid #000' }}>{menu.menu_end_date ? moment(menu.menu_end_date).format('DD/MM/YYYY') : ''}</td>
                                  <td colspan={1} style={{ border: '1px solid #000' }}>{menu.menu_actual_date ? moment(menu.menu_actual_date).format('DD/MM/YYYY') : ''}</td>

                                </tr>
                              </>)
                            })}
                          </>
                          )


                        })}

                      </table>
                    </div>
                  </div>


                </div>
              </div>
            </> : <>
              <div className='tapcardModule_new col-7' style={{ position: 'absolute', top: '8px' }} id='moduleMenuDiv'>
                <div style={{ position: "fixed", width: "54%", marginTop: '20px' }}>
                  <div className='row mb-2'>
                    <div className='d-flex col-xl-11' >
                      {<div className='detialmodule' style={{ marginLeft: '38px' }}> MODULE : {projectDetail != "" ? projectDetail.moduleList.length : 0}</div>}
                      {<div className='detialmodule' style={{ marginLeft: '10px' }}> MENU : {getCountMenu || 0}</div>}
                      {<div className='detialmodule' style={{ marginLeft: '10px' }}> FEATURE : {getCountFeature || 0}</div>}
                      {userismode === false ? (
                        <a className='fontOxygen' onClick={() => {
                          params.setProject(projectDetail.project_id); params.toggle_modal_import();

                        }} style={{ cursor: 'pointer', fontSize: '16px', display: 'block', marginTop: '4px' }}  >

                          <img style={{ width: '20px', marginLeft: '20px', marginRight: '10px', marginTop: '', transform: 'rotate(0.5turn)' }} src={Iconexport}></img>

                          IMPORT</a>) : <></>}
                      <a className='fontOxygen' onClick={() => {
                        exportToExcel()

                      }} style={{ cursor: 'pointer', fontSize: '16px', display: 'block', marginTop: '4px' }}  >
                        <img style={{ width: '20px', marginLeft: '20px', marginRight: '10px', marginTop: '-6px' }} src={Iconexport}></img>
                        <div style={{ display: 'none' }}>
                          <ReactHTMLTableToExcel
                            id="exportStageMonitoringBtn"

                            table="exportStageMonitoring"
                            filename={
                              "Stage Monitoring (" + projectDetail.project_name + ") " +
                              moment(new Date()).format("DD-MM-YYYY")
                            }
                            sheet="stage_monitoring"
                            buttonText="Download as XLS"
                          />
                        </div>
                        EXPORT</a>
                      {userismode === false ? (<>
                        <a onClick={() => { params.setProject(projectDetail.project_id); params.toggle_modal_add_module(); }} style={{ cursor: 'pointer', fontSize: '16px', display: 'block', marginTop: '5px' }}  >

                          <img style={{ width: '16px', marginLeft: '20px', marginBottom: '2px', marginRight: '10px' }} src={poin} /> ADD MODULE</a>


                      </>

                      ) : <></>}
                    </div>
                    {editMode && (
                      <div style={{ position: 'absolute', left: '82%', display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <button className='btn-outline-primary mr-2' style={{ fontSize: '15px', padding: '2px' }} onClick={() => { saveFillProcessStage() }}>
                          Save
                        </button>
                        <button className='btn-outline-danger' style={{ fontSize: '15px', padding: '2px' }} onClick={handleCancel}>
                          Cancel
                        </button>
                      </div>
                    )}

                    <div className='col-xl-1' style={{ display: 'flex', justifyContent: 'flex-end' }}>

                      <div className='btn-hide-module ' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                        let temp = projectDetail.moduleList || [];
                        for (let item of temp) {
                          item.isuse = !isHideModule == true ? false : true;
                        }
                        setIsHideModule(!isHideModule);
                        setProjectDetail({ ...projectDetail, moduleList: temp });
                        projectDetail.moduleList = temp;
                      }}><span className='fontOxygen' style={{ fontSize: '15px', paddingTop: '2px' }}>{isHideModule ? 'Show' : 'Hide'}</span></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'
                style={{
                  minWidth: '59.4%',
                  position: 'absolute',
                  right: '1%',
                  top: '11%',
                  height: '26px',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center'
                }}>
                <ConfigProvider
                  theme={{
                    token: {

                      colorInfo: '#85BFFF',
                      colorBorder: '#85BFFF',
                      colorText: '#007389',

                      colorTextDescription: '#007389',
                      colorBgContainer: '#000',
                      zIndexPopupBase: 999999,
                      colorPrimaryBg: 'blue',
                      colorBgBase: '#778899',
                      colorTextQuaternary: '#8c8c8c',
                      colorFillTertiary: '#000',
                      colorTextBase: 'blue',
                      colorPrimaryBg: '#85BFFF',
                    }
                  }}
                >
                  <Switch defaultChecked checked={switModeShowDisplay} onChange={() => {
                    params.toggle_clear_classname();
                    togle_siwmode();

                  }} /> &nbsp; <span className='fontOxygen' style={{ color: '#57ceef', fontSize: '24px', }}>{switModeShowDisplay ? ' Horizontal ' : ' Vertical'} </span>
                   <Checkbox style={{marginLeft:'10px',color: '#57ceef', fontSize: '22px'}}checked={allToDoChecked} onChange={(value)=>{
                   
                    filterAllToDo(value.target.checked);
                   }}>
         All To Do
        </Checkbox>
                
                </ConfigProvider>


              </div>

              <div className='tapcardModule_new_body col-7 mt-4' style={{ position: 'absolute', top: '13%' }} id='moduleMenuDiv'>

                {projectDetail.moduleList && projectDetail.moduleList.map((e, i) => {
                  return <Cardmodule
                    project_id={projectDetail.project_id}
                    clearStatus={clearStatus}
                    selectedColor={buttonBgColor}
                    mode={editMode}
                    callback={callback}
                    filterMenu={filterMenu}

                    CalPosition={(xx, yy, key) => {
                      if (key === 'stage') {
                        toggle_modal_status();
                      } else if (key === 'stakeholder') {
                        toggle_modal_stakeholder();
                      }

                      setPosition({ ...position, posX: xx, posY: yy })
                      position.posX = xx;
                      position.posY = yy;
                    }}

                    setID={(process_id, menu_id) => {
                      setMonitoringTarget({ ...monitoringTarget, process_id: process_id, menu_id: menu_id })

                    }}

                    setID2={(menu_id, stakeholder_list, qdc, module_id) => {
                      setTags(stakeholder_list || []);
                      setQDC(qdc);
                      setStakeholder({ ...stakeholder, menu_id: menu_id, module_id: module_id });


                    }}
                    GetModuleDeatil={GetModuleDeatil}
                    toggle_modal_config_module={toggle_modal_config_module}

                    onFillStatusUpdate={handleFillStatusUpdate}

                    data={e} processList={projectDetail.processList || []} setisusemodule={switctisuse} userismode={userismode} />
                })}

                <div style={{ display: 'none' }}>
                  <table style={{ border: '1px solid #000' }} id='exportStageMonitoring'>
                    <tr>
                      <td colspan={projectDetail.processList.length + 4} style={{ textAlign: 'center', border: '1px solid red' }}><font size="20">{projectDetail.project_name} Stage Monitoring : </font><font color="blue" size="20">Progress {projectDetail.project_progress}% </font></td>
                    </tr>

                    <tr>
                      <td colspan={1} style={{ border: '1px solid #000', height: '50px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Module</td>
                      {projectDetail.processList && projectDetail.processList.map((precess, ip) => {
                        return (<>
                          <td colspan={1} style={{ border: '1px solid #000', width: '100px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>{precess.process_name}</td>
                        </>)

                      })}

                      <td colspan={1} style={{ border: '1px solid #000', width: '120px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Remark</td>
                      <td colspan={1} style={{ border: '1px solid #000', width: '120px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Due Date</td>
                      <td colspan={1} style={{ border: '1px solid #000', width: '120px', textAlign: 'center', verticalAlign: 'middle', backgroundColor: 'rgba(201, 218, 248)', fontSize: "20px" }}>Actual Date </td>

                    </tr>
                    {projectDetail.moduleList && projectDetail.moduleList.map((module, i) => {
                      return (<>
                        <tr>

                          <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px", backgroundColor: '#B0E0E6' }}>{module.module_name}</td>

                          <td colspan={projectDetail.processList.length} style={{ border: '1px solid #000', fontSize: "18px", backgroundColor: '#B0E0E6' }}></td>

                          <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px" }}>{module.module_remark || ''}</td>
                          <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px" }}>{module.module_end_date ? moment(module.module_end_date).format('DD/MM/YYYY') : ''}</td>
                          <td colspan={1} style={{ border: '1px solid #000', fontSize: "18px" }}>{module.module_actual_date ? moment(module.module_actual_date).format('DD/MM/YYYY') : ''}</td>

                        </tr>
                        {module.module_menu_list.map((menu, i2) => {

                          return (<>
                            <tr>

                              <td colspan={1} style={{ border: '1px solid #000', fontSize: "16px" }}>{menu.menu_name}</td>
                              {menu.process_list.map((e) => {

                                let key_status = '-';
                                if (e.ps_id == '6e6f2408-6a06-4f93-8f0a-aa385cfc4188') {
                                  key_status = 'o';
                                } else if (e.ps_id == '4ded34bc-1d60-4512-b0ad-8c8daf16c0d8') {
                                  key_status = 'x';
                                } else if (e.ps_id == 'f00e63f2-ab27-4828-9d7a-f9316c28703e') {
                                  key_status = 'i';
                                }
                                return (<>
                                  <td colspan={1} style={{ border: '1px solid #000', backgroundColor: e.ps_color, textAlign: 'center' }}>{key_status}</td>
                                </>)
                              })}<td colspan={1} style={{ border: '1px solid #000' }}>{menu.menu_remark}</td>
                              <td colspan={1} style={{ border: '1px solid #000' }}>{menu.menu_end_date ? moment(menu.menu_end_date).format('DD/MM/YYYY') : ''}</td>
                              <td colspan={1} style={{ border: '1px solid #000' }}>{menu.menu_actual_date ? moment(menu.menu_actual_date).format('DD/MM/YYYY') : ''}</td>

                            </tr>
                          </>)
                        })}
                      </>
                      )


                    })}

                  </table>
                </div>
              </div>
            </>}






            <ModalStakeholder isOpen={openModalStakeholder} toggle_modal_stakeholder={toggle_modal_stakeholder} project_id={projectDetail.project_id} updateStakeholder={updateStakeholder} Tags={tags} QDC={QDC} menu_id={stakeholder.menu_id} module_id={stakeholder.module_id} switModeShowDisplay={switModeShowDisplay} />
            <ModalConfigModule isOpen={openModalConfigModule} toggle_modal_config_module={toggle_modal_config_module} ModuleDetails={ModuleDetail} project_id={projectDetail.project_id} getProjectDetail={getProjectDetail} resetmodule={GetModuleDeatil} switModeShowDisplay={switModeShowDisplay} />

            <ModalFilterDisplayStage
              toggle_modal_status={toggle_modal_status}
              setID={(process_id, menu_id) => {
                setMonitoringTarget({ ...monitoringTarget, process_id: process_id, menu_id: menu_id })

              }}
              CalPosition={(xx, yy, key) => {
                if (key === 'stage') {
                  toggle_modal_status();
                } else if (key === 'stakeholder') {
                  toggle_modal_stakeholder();
                }
                setPosition({ ...position, posX: xx, posY: yy })
                position.posX = xx;
                position.posY = yy;
              }}
              isOpen={openModalFilterStage} toggle_modal_filter_stage={toggle_modal_filter_stage} ModuleDetails={ModuleDetail} project_id={projectDetail.project_id} projectDetail={projectDetail} getProjectDetail={getProjectDetail} status={stageViewStatus}
              switModeShowDisplay={switModeShowDisplay}
            />


            <ModalProcessMockup
              toggle_modal_process_mockup={toggle_modal_process_mockup}
              toggle_modal_process_mockup_view_version={toggle_modal_process_mockup_view_version}
              toggle_modal_process_mockup_view_description={toggle_modal_process_mockup_view_description}
              open_descpription_image={() => { setOpenModalProcessMockupViewDescription(true); }}
              close_descpription_image={() => { setOpenModalProcessMockupViewDescription(false); }}
              isOpen={openModalProcessMockup}
              projectDetail={projectDetail}
              callbackDataMockupVersion={callbackDataMockupVersion}
              CalPosition={(xx, yy, key) => {

                if (500 + parseFloat(xx) > window.screen.availWidth) {
                  xx = xx - 500;
                }
                if (300 + parseFloat(yy) > window.screen.availHeight) {
                  yy = yy - 300;
                }
                setPosition({ ...position, posX: xx, posY: yy })
                position.posX = xx;
                position.posY = yy;
              }}
              callbackDataMockupDescription={callbackDataMockupDescription}
              switModeShowDisplay={switModeShowDisplay}
            />
            <ModalProcessMockupViewVersion
              toggle_modal_process_mockup_view_version={toggle_modal_process_mockup_view_version}
              isOpen={openModalProcessMockupViewVersion}
              data_mockup_version={dataMockupVersion}
            />

            <ModalProcessMockupViewDescription
              toggle_modal_process_mockup_view_description={toggle_modal_process_mockup_view_description}
              isOpen={openModalProcessMockupViewDescription}
              dataMockupDescription={dataMockupDescription}
              position={position}
            />


            <ModalProcessSrs
              toggle_modal_process_srs={toggle_modal_process_srs}
              isOpen={openModalProcessSrs}
              project_id={projectDetail.project_id}
              projectDetail={projectDetail}
              getProjectDetail={getProjectDetail}
            />


          </>)}


      </ConfigProvider>
      <StageElement
        isOpen={modalStatus}
        toggle_modal_status={toggle_modal_status}
        project_id={projectDetail.project_id}
        process_id={monitoringTarget.process_id}
        menu_id={monitoringTarget.menu_id}
        getProjectDetail={getProjectDetail}
        setIndexChat={params.toCardStage}
        setToCardStage={params.setToCardStage}
      />

      {alertKick4 === true ? (
        <div className="alertKick" style={{ zIndex: "9999999" }}>
          <div className="alertKickBoxShadow">
            <div className="alertKickBox">
              <div className="alertKickBoxIn">
                <div className="row" style={{ height: "25%" }}>
                  <div className="alertKickHead">! ERROR !</div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    error
                  </div>
                </div>
                <div className="row" style={{ height: "12.5%" }}>
                  <div className="alertKickText">
                    Status must be 100% before approved
                  </div>
                </div>
                <div className="row" style={{ height: "25%" }}></div>
                <div className="row alertKickBoxButton">
                  <button
                    className="alertKickButton"
                    onClick={() => {
                      toggle_modal_alertKick4();
                      setAcceptClass('AcceptMissionDoneActive')
                      acceptClass = 'AcceptMissionDoneActive'
                    }}
                  >
                    OK
                  </button>
                </div>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage.message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {alertKick5 === true ? (
        <div className="alertKick" style={{ zIndex: "9999999" }}>
          <div className="alertKickBoxShadow">
            <div className="alertKickBox">
              <div className="alertKickBoxIn">
                <div className="row" style={{ height: "25%" }}>
                  <div className="alertKickHead" style={{ color: "green" }}>SUCCESS</div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    {errorMessage.status ? 'WARNING' : 'SUCCESS'}
                  </div>
                </div>
                <div className="row" style={{ height: "12.5%" }}>
                  <div className="alertKickText">
                    {checkAuto === true ? (
                      <p>Successfully accepted</p>
                    ) : (
                      <p>Cancellation successful</p>
                    )}
                  </div>
                </div>
                <div className="row" style={{ height: "25%" }}></div>
                <div className="row alertKickBoxButton">
                  <button
                    className="alertKickButton"
                    onClick={() => {
                      toggle_modal_alertKick5();
                      if (projectDetail.isownerprojectcheck === true) {
                        setAcceptClass('AcceptMissionDoneActived')
                        acceptClass = 'AcceptMissionDoneActived'
                      } else {
                        setAcceptClass('AcceptMissionDoneActive')
                        acceptClass = 'AcceptMissionDoneActive'
                      }
                    }}
                  >
                    OK
                  </button>
                </div>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage.message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {alertKick6 === true ? (
        <div className="alertKick" style={{ zIndex: "9999999" }}>
          <div className="alertKickBoxShadow">
            <div className="alertKickBox">
              <div className="alertKickBoxIn">
                <div className="row" style={{ height: "25%" }}>
                  <div className="alertKickHead" style={{ color: 'orange' }}>! WARNING !</div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning'>
                    WARNING
                  </div>
                </div>
                <div className="row" style={{ height: "12.5%" }}>
                  <div className="alertKickText">
                    You do not have the right to accept
                  </div>
                </div>
                <div className="row" style={{ height: "25%" }}></div>
                <div className="row alertKickBoxButton">
                  <button
                    className="alertKickButton"
                    onClick={() => {
                      toggle_modal_alertKick6();
                    }}
                  >
                    OK
                  </button>
                </div>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage.message}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}


      <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `${errorMessage.status ? 'red' : 'green'}` }}>
                  {errorMessage.status ? '! ERROR !' : 'SUCCESS'}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning'>
                  {errorMessage.status ? 'WARNING' : 'SUCCESS'}
                </div>
              </div>
              <div className='row' style={{ height: '12.5%' }}>
                <div className='alertKickText'>
                  {errorMessage.message}

                </div>
              </div>


              <div className='row alertKickBoxButton'>
                <button className='alertKickButton' onClick={() => {
                  toggle_modal_alertKick()
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='alertKick' style={{ display: `${alertKick2 ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
        <div className='alertKickBoxShadow' >
          <div className='alertKickBox' style={{ height: 'fit-content' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `${errorMessage2.status ? 'ffffff' : 'green'}` }}>
                  {errorMessage2.status ? 'Loading' : 'SUCCESS'}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning' style={{ border: 'none' }}>
                  {errorMessage2.status ? <><div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div></> : <></>}
                </div>
              </div>
              {errorMessage2.status ? <></> : <>
                <div className='row' style={{ height: '12.5%' }}>
                  <div className='alertKickText'>
                    {errorMessage2.message}

                  </div>
                </div>


                <div className='row alertKickBoxButton'>
                  <button className='alertKickButton' onClick={() => {
                    toggle_modal_alertKick()
                  }}>OK</button>

                </div>
              </>}


            </div>
          </div>
        </div>
      </div>
      <div className='alertKick' style={{ display: `${alertKick3 ? '' : 'none'}` }}>
        <div className='alertKickBoxShadow'>
          <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickHead' style={{ color: `${errorMessage3.isError ? 'red' : 'green'}` }}>
                  {errorMessage3.isError ? '! ERROR !' : 'SUCCESS'}
                </div>
              </div>
              <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                <div className='alertKickWarning'>
                  {errorMessage3.isError ? 'WARNING' : 'SUCCESS'}
                </div>
              </div>

              <div className='row' style={{ height: '25%' }}>
                <div className='alertKickTextSec'>
                  <div> SUCCESS </div>
                </div>
              </div>
              <div className='row alertKickBoxButton mt-4'>
                <button className='alertKickButton' onClick={() => {
                  setAlertKick3(false)
                }}>OK</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        modalOpenApi ?
          <PostmanCollection toggleModalOpenApi={toggleModalOpenApi} /> : ''
      }
      {
        openModalDBA ?
          <ElementDBA isOpen={openModalDBA} toggle_modal_dba={toggle_modal_dba} projectDetail={projectDetail} /> : ''
      }
      <DatabaseGenModel 
      isOpen = {databesModel}
      toggle_modal_databaseMgtConfig = {toggledatabesModel}
      openElement_p = {params.openElement}
      onElement_p = {params.onElement}
      closeElement = {params.closeElement}
      />
    </>
  );
}

export default Cardstage;
