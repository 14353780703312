import React, { useEffect, useState, useRef } from 'react';

import "./elementSelectPopup.css";

import axios from "axios";
import Configs from "../../../../config";

import { ConfigProvider, Select } from 'antd';

import AlertMassage from "../../../../components/AlertMassge/AlertMassge"

function ElementSelectPopup({ isOpen, mode, elementSelectPopup, setElementSelectPopup, elementShopOnCart }) {

    const [select, setSelect] = useState({
        value: '',
        label: ''
    })
    const [optionSelect, setOptionSelect] = useState([]);

    const [alertMassageIsOpen, setAlertMassageIsOpen] = useState(false);
    const toggleAlertMassageIsOpen = () => { setAlertMassageIsOpen(!alertMassageIsOpen) };
    const [messageBox, setMessageBox] = useState({
        message: 'Please fill in all information completely.',
        isError: true,
        // menuTitle: 'ERROR'
    })

    async function getSpaceshipLeaderByUser() {
        let temp = {
            usr_id: sessionStorage.getItem('user_id'),
            elm_id: elementSelectPopup.elm.elm_id
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/element/getSpaceshipLeaderByUser`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setOptionSelect(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getFederationOwnerByUser() {
        let temp = {
            usr_id: sessionStorage.getItem('user_id'),
            elm_id: elementSelectPopup.elm.elm_id
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/element/getFederationOwnerByUser`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setOptionSelect(response.data)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (isOpen) {
            if (mode == 'Spaceship') {
                getSpaceshipLeaderByUser();
            } else {
                getFederationOwnerByUser();
            }
        } else {
            setSelect({
                value: '',
                label: ''
            });
            setOptionSelect([]);
        }
    }, [isOpen])

    return (<>
        {isOpen && <div style={{ background: 'rgba(0, 0, 0, 0.8', width: '100%', height: '100%', position: 'fixed', top: 0, left: 0, zIndex: 999, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ background: '#002C7A', borderRadius: '10px', padding: '10px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div className='elmSelectPopupHeader'>{mode}</div>
                </div>
                <ConfigProvider
                    theme={{
                        token: {
                            colorPrimary: '#0047FF',
                            colorInfo: '#0047FF',
                            colorBorder: '#0047FF',
                            colorText: '#FFF',

                            colorTextDescription: '#FFFFFF',
                            colorBgContainer: '#000',

                            zIndexPopupBase: 999999,
                            colorPrimaryBg: '#0047FF',//สีตอน Active ใน Select
                            colorBgBase: '#0047FF',//พื้นหลัง select ตอนกด down ลงมา

                            colorTextQuaternary: '#B0C4DE',// สีตัวหนังสือของ placeholder
                            colorBorder: '#0047FF',

                            colorFillTertiary: '#3354aa',// สีตอน hover ใน Select
                        },
                    }}
                >
                    <Select
                        showSearch
                        dropdownClassName='addElementSelectCustom'
                        style={{ minWidth: '250px' }}
                        value={optionSelect.filter((e) => { return e.value === select.value })}
                        onChange={(e, f) => {
                            if (!f.is_have) {  // ตรวจสอบว่า is_have เป็น false ก่อนอัพเดท
                                setSelect({
                                    ...select,
                                    value: f.value,
                                    label: f.label
                                });
                            }
                        }}
                        placeholder={'Select ' + mode}
                        options={optionSelect.map(option => ({
                            ...option,
                            label: option.is_have ? `${option.label} (already)` : option.label, // แสดง (already) ต่อท้าย label ถ้า is_have เป็น true
                            disabled: option.is_have === true // ปิดการใช้งาน option ถ้า is_have เป็น true
                        }))}
                        filterOption={(input, option) =>
                            option?.label?.toLowerCase().includes(input.toLowerCase())
                        }
                    />
                </ConfigProvider>
                <div style={{ display: 'flex', justifyContent: 'space-between', gap: '15px' }}>
                    <div className='elmSelectPopupButton' style={{ width: '50%', height: 'fit-content', background: '#0047FF', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                        if (select.value.trim() != '' || select.label.trim() != '') {
                            elementSelectPopup.elm.select_value = select.value;
                            elementSelectPopup.elm.select_label = select.label;

                            elementShopOnCart.push(elementSelectPopup.elm);

                            setElementSelectPopup({ ...elementSelectPopup, isOpen: false });
                        } else {
                            toggleAlertMassageIsOpen();
                        }
                    }}>BUY</div>
                    <div className='elmSelectPopupButton' style={{ width: '50%', height: 'fit-content', background: '#FF0000', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }} onClick={() => {
                        setElementSelectPopup({ ...elementSelectPopup, isOpen: false });
                    }}>CANCEL</div>
                </div>
            </div>
        </div>}

        <AlertMassage
            isOpen={alertMassageIsOpen}
            toggle_alertKick={toggleAlertMassageIsOpen}
            // mainFuc={buyElement}
            message={messageBox}
        />
    </>);
}

export default ElementSelectPopup;