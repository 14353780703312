import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

const TooltipPortal = ({ children, positionTooltip,elementDetail }) => {
  if (!children) {
    return null;
  }

  return ReactDOM.createPortal(
    <div>
        <div style={{
        borderTop:'1px solid #0047FF',
        width:'15px',
        position: 'fixed',
        transform:' rotate(-45deg)',
        top: `${positionTooltip.top-12}px`,
        left: `${positionTooltip.left+3}px`,
        zIndex: 9999,}}>
      </div>
      <div style={{
        borderTop:'2px solid #0047FF',
        width:'30px',
        position: 'fixed',
        top: `${positionTooltip.top-18}px`,
        left: `${positionTooltip.left+15}px`,
        zIndex: 9999}}>
      </div>
      <div style={{
        width:'7px',
        height: '7px',
        position: 'fixed',
        background: '#0047FF',
        border:'1px solid #0047FF',
        top: `${positionTooltip.top-20}px`,
        left: `${positionTooltip.left+38}px`,
        zIndex: 9999,
        borderRadius: '100px'}}>
      </div>
       <div
        style={{
          height: 'fit-content',
          width: '255px',
          position: 'fixed',
          top: `${positionTooltip.top-35}px`,
          left: `${positionTooltip.left+50}px`,
          zIndex: 9999,
          padding: '10px',
          borderRadius: '6px',
          backgroundColor: 'rgba(0, 0, 0, 0.2)',
          opacity: '0.3'
        }}
       >
     <div style={{
        fontSize:'20px',
        color: '#fff',
        fontWeight: '10'
        }}>
        {children}
      </div>
      <div style={{
        fontSize:'13px',
        color: '#fff',
        fontWeight: '10'
        }}>
        Detail : {elementDetail }
      </div>

  </div>
    </div>
   ,
    document.body
  );
};

export default TooltipPortal;

