import React, { useEffect, useState, useRef } from 'react';
import '../../assets/css/landingPageGame.css';
import LandingPagestage from '../LandingPagestage';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { gsap } from 'gsap'; // นำเข้า GSAP
import towerJSON from '../3dModalTEST/towerSpaceShipCenter.json';

function LandingPageGame() {
  const [filterTower, setFilterTower] = useState({ b: true, r: true, y: true });
  const [jsonArea, setJsonArea] = useState([]);
  const [openModal, setOpenModal] = useState('close');
  const [onCategory, setOnCategory] = useState(false);
  const [modeldefcon, setModelDefcon] = useState({ isOpen: false });
  const [checkOnModal, setCheckOnModal] = useState({ isOpen: false });
  const mountRef = useRef(null);
  const clickedCubes = new Set();
  let hoveredCube = null;
  const cubes = useRef([]);

  function generateRedData(numCubes) {
      const dataArray = [];
      for (let i = 1; i <= numCubes; i++) {
          const cubeInfo = {
              id: i,
              info: `Red Cube ${i}`,
              color: 'red',
              list: Array.from({ length: Math.floor(Math.random() * 100) + 1 }, () => ({ pro: Math.floor(Math.random() * 4).toString() })),
              x: Math.floor(Math.random() * 50) + 1,
              z: Math.floor(Math.random() * 50) + 1
          };
          dataArray.push(cubeInfo);
      }
      return dataArray;
  }

  function generateBlueData(numCubes) {
      const dataArray = [];
      for (let i = 1; i <= numCubes; i++) {
          const cubeInfo = {
              id: i,
              info: `Blue Cube ${i}`,
              color: 'blue',
              list: Array.from({ length: Math.floor(Math.random() * 100) + 1 }, () => ({ pro: Math.floor(Math.random() * 4).toString() })),
              x: Math.floor(Math.random() * 50) + 1,
              z: Math.floor(Math.random() * 50) + 1
          };
          dataArray.push(cubeInfo);
      }
      return dataArray;
  }
  function generateYellowData(numCubes) {
    const dataArray = [];
    for (let i = 1; i <= numCubes; i++) {
        const cubeInfo = {
            id: i,
            info: `Blue Cube ${i}`,
            color: 'yellow',
            list: Array.from({ length: Math.floor(Math.random() * 100) + 1 }, () => ({ pro: Math.floor(Math.random() * 4).toString() })),
            x: Math.floor(Math.random() * 50) + 1,
            z: Math.floor(Math.random() * 50) + 1
        };
        dataArray.push(cubeInfo);
    }
    return dataArray;
}

  const dataArray = [...generateRedData(1000), ...generateBlueData(1000),...generateYellowData(1000)]; // Combine red and blue data

  const initializeScene = () => {
    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 10000000);
    const renderer = new THREE.WebGLRenderer();
    renderer.setSize(window.innerWidth, window.innerHeight);
    mountRef.current.appendChild(renderer.domElement);

    let width = 200; 
    let height = 200; 
   /*  const planeGeometry = new THREE.PlaneGeometry(width, height);  
    const planeMaterial = new THREE.MeshBasicMaterial({ color: 0xaaaaaa, side: THREE.DoubleSide });
    let plane = new THREE.Mesh(planeGeometry, planeMaterial);
    plane.rotation.x = -Math.PI / 2;  */
   /*  scene.add(plane); */
    // ความยาวและจำนวนเส้น
   
    let radius = Math.max(width, height) / 4;  // คำนวณรัศมีเพื่อสุ่มตำแหน่ง

    camera.position.set(30, 45, 30);  // ตั้งตำแหน่งกล้อง
    const controls = new OrbitControls(camera, renderer.domElement);
    controls.enableDamping = true;  // เปิดใช้งานการหน่วง (damping)
    controls.dampingFactor = 0.05;
    controls.screenSpacePanning = false;
    controls.maxPolarAngle = Math.PI / 2;  // จำกัดมุมกล้อง

    const raycaster = new THREE.Raycaster();  // ตัวช่วยตรวจสอบการชน (raycasting)
    const mouse = new THREE.Vector2();  // สร้างตัวแปรเก็บตำแหน่งเมาส์

    // ฟังก์ชันเพื่ออัปเดตขนาดของพื้น
  /*   const updateGridSize = (newWidth) => {
        scene.remove(gridHelper); 
        const newGridHelper = new THREE.GridHelper(newWidth, newWidth); 
        scene.add(newGridHelper); 
    }; */

    const placedPositions = new Set();  // Set เพื่อเก็บตำแหน่งที่ได้วางไว้แล้ว
    let batchSize = 100;  // จำนวนของข้อมูลที่โหลดในแต่ละชุด
    let currentIndex = 0;  // ดัชนีข้อมูลปัจจุบันใน dataArray
    let loop = 0,maxloop=1
    const cubeDataArray = []; 
    // ฟังก์ชันโหลดข้อมูลเป็นชุดๆ
    const loadBatch = () => {
        if (currentIndex >= dataArray.length) {
            console.log("โหลดข้อมูลทั้งหมดเสร็จสิ้น");
            return;  // ออกจากฟังก์ชันถ้าโหลดข้อมูลครบแล้ว
        }
       /*  batchSize = batchSize ; */
        const endIndex = Math.min(currentIndex + batchSize, dataArray.length);  // คำนวณดัชนีสิ้นสุด
        const batchData = dataArray.slice(currentIndex, endIndex);  // ดึงข้อมูลชุดย่อย

        batchData.forEach(async (data) => {
            const cubeHeight = data.list.length;
            const geometry = new THREE.BoxGeometry(data.x, cubeHeight / 2, data.z);  // สร้างรูปทรงของ cube
            const material = new THREE.MeshBasicMaterial({ color: data.color, transparent: true, opacity: 0.5 });
            const cube = new THREE.Mesh(geometry, material);  // สร้าง cube ใหม่

            let placed = false;  // ตัวแปรเช็คว่าวาง cube แล้วหรือยัง
            let randomX, randomZ;  
            let positionKey;
            const gridSize = 10; 
            
            while (!placed) {
                randomX = Math.floor((Math.random() - 0.5) * (radius / gridSize)) + (0.5 * (data.x));  
                randomZ = Math.floor((Math.random() - 0.5) * (radius / gridSize)) + (0.5 * (data.z));
console.log(randomX,randomZ);

                positionKey = `${randomX},${(cubeHeight / 2) / 2},${randomZ}`;  // สร้างคีย์ตำแหน่ง

                if (!placedPositions.has(positionKey) && isPositionAvailable(randomX, randomZ, cube.geometry.parameters.width, cube.geometry.parameters.depth)) {
                    placed = true;
                    cube.position.set(randomX, (cubeHeight / 2) / 2, randomZ);
                    cube.userData = data;
        
                    scene.add(cube);
                    cubes.current.push(cube);
                    placedPositions.add(positionKey);
                    const cubeInfo = {
                        position: {
                            x: cube.position.x,
                            y: cube.position.y,
                            z: cube.position.z,
                        },
                        size: {
                            width: data.x / 2,
                            height: cubeHeight / 2,
                            depth: data.z / 2,
                        }
                    };
                    cubeDataArray.push(cubeInfo);
                } else {
                   
                    loop+=1
                    if (loop === maxloop) {
                        radius += 10; 
                        if (maxloop <= 10 + Math.floor((radius / 100))) {
                            maxloop+=1  
                        }
                    
                        loop = 0
                    }
                   /*  if (radius < 500) { 
                        radius += 4;  
                    } else {
                        radius += 10; 
                    }  */
                    /* updateGridSize(radius); */
                
                }
            }
        });
      
        currentIndex += batchData.length; // อัปเดตดัชนีปัจจุบัน
       /*  requestAnimationFrame(loadBatch); */
        setTimeout(loadBatch, 1 * maxloop);  // เรียกใช้งานฟังก์ชันอีกครั้งเพื่อโหลดชุดถัดไป
    };

  
    const isPositionAvailable = (x, z, width, depth) => {
        const margin = 1;  
        return !cubes.current.some(cube => {
            const cubePosition = cube.position;
            const cubeHalfWidth = cube.geometry.parameters.width / 2;
            const cubeHalfDepth = cube.geometry.parameters.depth / 2;
    
         
            return (
                (x + width / 2 + margin) > (cubePosition.x - cubeHalfWidth) &&
                (x - width / 2 - margin) < (cubePosition.x + cubeHalfWidth) &&
                (z + depth / 2 + margin) > (cubePosition.z - cubeHalfDepth) &&
                (z - depth / 2 - margin) < (cubePosition.z + cubeHalfDepth)
            );
        });
    };

    // เริ่มต้นการโหลดข้อมูล
    loadBatch();

    
        const gridHelper = new THREE.GridHelper(radius, radius); // สร้าง GridHelper โดยกำหนดให้มีความกว้างของแต่ละช่องเป็น 1
        gridHelper.position.set(0, 0, 0);
    scene.add(gridHelper);

    // ฟังก์ชันสำหรับเรนเดอร์วัตถุที่อยู่ในระยะกล้อง
    const renderWithinDistance = (camera, objects, maxDistance) => {
        objects.forEach(object => {
            const distance = camera.position.distanceTo(object.position);
            if (distance <= maxDistance) {
                object.visible = true;  // ทำให้วัตถุมองเห็นได้
            } else {
                object.visible = false;  // ซ่อนวัตถุ
            }
        });
    };

    const animate = () => {
        requestAnimationFrame(animate);
        // เพิ่มการหมุนให้กับกล้องหรือวัตถุ
        camera.rotation.y += 0.01; // เพิ่มการหมุนเล็กน้อย
        controls.update();
        renderWithinDistance(camera, cubes.current, 500);
        renderer.render(scene, camera);
    };
    animate();

    const handleMouseMove = (event) => {
        mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
        mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(cubes.current);  // ตรวจสอบว่ามี cube ถูกเมาส์ชี้อยู่หรือไม่

        if (hoveredCube && !clickedCubes.has(hoveredCube)) {
            gsap.to(hoveredCube.material, { opacity: 0.5, duration: 0.5 });
        }

        if (intersects.length > 0) {
            const intersectedCube = intersects[0].object;
            if (!clickedCubes.has(intersectedCube)) {
                gsap.to(intersectedCube.material, { opacity: 0.8, duration: 0.5 });
            }
            hoveredCube = intersectedCube;
        } else {
            hoveredCube = null;
        }
    };

    const handleClick = (event) => {
        clickedCubes.clear();
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(cubes.current);

        if (intersects.length > 0) {
            const clickedCube = intersects[0].object;
            gsap.to(clickedCube.material, { opacity: 1, duration: 0.5 });
            clickedCubes.add(clickedCube);
        }
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("click", handleClick);
};



  useEffect(() => {
      initializeScene();
  }, []);

  const toggleFilter = (color) => {
      setFilterTower((prev) => {
          const newState = {
              ...prev,
              [color]: !prev[color] // Toggle the filter state
          };
          updateCubeVisibility(newState); // Pass the new state
          return newState;
      });
  };

  const updateCubeVisibility = (currentFilterTower) => {
      console.log('inx', currentFilterTower);
      
      cubes.current.forEach(cube => {
          if (cube.userData.color === 'red') {
              cube.visible = currentFilterTower.r; // Show/hide based on filter
          } else if (cube.userData.color === 'blue') {
              cube.visible = currentFilterTower.b; // Show/hide based on filter
          } else if (cube.userData.color === 'yellow') {
            cube.visible = currentFilterTower.y; // Show/hide based on filter
        }
      });
  };
    return (
        <>
            <div ref={mountRef} />
            <div className='frame-filter' style={{display: `${checkOnModal.isOpen ? 'none' : ''}`}}>
        <div className='filter-my-build'>
          <button className='check-box' onClick={() => { toggleFilter('b') }} style={{ backgroundColor: `${filterTower.b == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>blue</span>
          
        </div>
        <div className='filter-my-build'>
          <button className='check-box' onClick={() => { toggleFilter('r') }} style={{ backgroundColor: `${filterTower.r == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>red</span>
          
        </div>
        <div className='filter-my-build'>
          <button className='check-box' onClick={() => { toggleFilter('y') }} style={{ backgroundColor: `${filterTower.y == true ? 'blue' : 'transparent'}` }}></button>
          <span className='my-build'>yellow</span>
          
        </div>
      
      </div>
            <LandingPagestage
                display={'tower'}
                setMenuValue={(value) => sessionStorage.setItem('isMenu', value)}
                setCheckOnModal={setCheckOnModal}
            />
        </>
    );
}

export default LandingPageGame;
