
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, Link } from "react-router-dom";
import {
    CheckOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import axios from "axios";
import Configs from "../../../config";
import "./slot.css"
import { Space, Tooltip } from 'antd';
import { io } from 'socket.io-client';

function SlotLocation({ isOpen, getKeyItem, openElement_p, onElement_p }) {
    const location = useLocation();
    const [slotElement, setSlotElement] = useState([])
    const [currenPathOpen, setCurrenPathOpen] = useState(location.pathname)
    const keyStage = new URLSearchParams(document.location.search).get('id');
    const backToStage = new URLSearchParams(document.location.search).get('backToStage');
    /*     const [getKeyItem, setGetKeyItem] = useState({
            key: '',
            id: '',
            user: sessionStorage.getItem('user_id')
        }) */
    const [test, settest] = useState({
        test: ''
    })
    const [genIndexAll,setGenIndexAll] = useState(false);
    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                //path: '/Socket-service/socket.io'
                port: Configs.TTT_Game_Socket_Service_Port
            });
        }
    }, [])

   
    let onElement = sessionStorage.getItem('onElement');
    useEffect(() => {
        console.log('Check Stage',keyStage);
        console.log('Check backToStage',backToStage);
        
        const { current: socket } = socketRef;


        const slotElFnc = async (data) => {
                console.log('data',data)
            let getPagenow = sessionStorage.getItem('pageNow');
           
            if (getPagenow && getPagenow == 'StageMonitoring' && data.message === 'StageMonitoring') {
                await getElementByLocationAndUser('StageMonitoring', data.id);
                setGenIndexAll(false);
            } else if (getPagenow && getPagenow == 'ProjectMonitoring' && data.message === 'StageMonitoring') {
                await getElementByLocationAndUser('ProjectMonitoring', data.id);
                setGenIndexAll(false);
            }else if(data.message === 'GENERATEALL'){
                setGenIndexAll(true);
            }else if(data.message === 'GENERATEALL SUCCESS'){
                setGenIndexAll(false);
            } /* else if(keyStage){
                
            } */else{
                setGenIndexAll(false);
                await getElementByLocationAndUser(data.message, data.id,data.project_id,data.fed_id);
            }

        }
        if(onElement === '1bb56e40-d679-456d-b2da-34356d0c3089'){
            window.location.href = `/SuperDetailMode`;
       }
       if(sessionStorage.getItem('project_id') && onElement === '98ad53de-4e24-4cb3-924e-3e9c311efa03'){
         window.location.href = `/ProjectTimeline?id=${sessionStorage.getItem('project_id')}`
        // console.log("checkIdProject: ", sessionStorage.getItem('project_id'));
        
        }
        if(sessionStorage.getItem('project_id') && onElement === '44253040-8d63-4ec1-a333-dd96fb35d76e'){
            window.location.href = `/RiskManagement?id=${sessionStorage.getItem('project_id')}`
           // console.log("checkIdProject: ", sessionStorage.getItem('project_id'));
           
           }

        try {
            socket.open();

            socket.emit('join', "locationElement" + sessionStorage.getItem('user_id'));
            socket.on('locationElement', slotElFnc)


        } catch (error) {
            console.log(error);
        }
        return () => {

            socket.close();
        };



    }, [onElement]);

    const [isOpenObj, setIsopenObj] = useState(false);
    const [switcHummberger, setSwitcHummberger] = useState(false);
    const toglg_switc_humberger = () => { setSwitcHummberger(!switcHummberger) };


    async function getElementByLocationAndUser(key, valueId,project_id,fed_id) {
        setSlotElement([]);
        var temp = {
            key: key,
            id: valueId,
            user: sessionStorage.getItem('user_id'),
            project_id: key == 'ProjectMonitoring' ? sessionStorage.getItem('project_id') : project_id,
            fed_id:fed_id
        };
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/slotLocationElement/slotLocationElement`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }, data: temp
        }).then(async function (response) {
            //ul_file_path
            let checkData = response.data.data;
            setSwitcHummberger(true)
            setSlotElement(checkData);

          /*   axios({
                method: "post",
                url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
                headers: {
                    "X-TTT": `${Configs.API_TTT}`,
                    "Content-Type": "application/json",
                },
            })
                .then(async function (response1) {
                    sessionStorage.setItem('tokenBox', response1.data.tokenBox)
                    let checkData = response.data.data;
                    setSlotElement(checkData);
                    if (checkData.length > 0) {
                        setSwitcHummberger(true)
                        for (let item of checkData) {
                            item.ul_file_path = null
                            if (item.fd_img_id) {
                                const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });

                                item.ul_file_path = URL.createObjectURL(response2.data);
                            }
                            console.log('key list', checkData);

                            setSlotElement(checkData);
                            settest({ ...test, test: '' });
                            test.test = '';
                        }
                    } else {
                        setSwitcHummberger(false)
                        setSlotElement([]);
                    }


                })
                .catch(function (error) {
                    console.log(error);
                }); */


            /* await GetAccesstokenToken(response.data); */


        }).catch(function (error) {
            console.log('key Error', error);

        });
    }


    useEffect(async () => {
        setSlotElement([]);

        /* await getElementByLocationAndUser(getKeyItem.key, getKeyItem.id) */

        settest({ ...test, test: '' });
        test.test = '';
    }, [getKeyItem])

    return (<>

        <div className={`modalOpenIsStage${switcHummberger ? '-true' : '-false'}`} style={{ display: `${currenPathOpen == '/StageMonitoring' ? '' : 'none'}`, zIndex:`${genIndexAll ? '12' : '1001'}` }}>
            <div className='bodyElementStage'>
                {slotElement.length > 0 ? slotElement.map((item) => {
                    return (<>
                        <Tooltip color='#051F6C' placement="right" title={<>{item.elm_name} <br /> {item.elm_detail} </>}>
                            <div className='borderElementStage' style={{cursor:'pointer'}} onClick={()=>{
                                openElement_p(item.eu_elm_id)
                            }}>
                                {item.ul_file_path ? <img style={{ height: '-webkit-fill-available', padding: '4px' }} src={`${Configs.TTT_Game_Service_IMG} ${item.ul_file_path}`} /> : <></>}
                            </div>
                        </Tooltip>

                    </>)
                }
               
                
            
            ) :
                    <>
                    </>}
                    <div className='borderElementStage' style={{ cursor: 'pointer' }} onClick={()=>{
                    window.location = "/SpaceshipConsole2.0"
                }}>
                    <span style={{ font: '90px' }}> + </span>
                </div>
            </div>
            <div className={`hummbergarswit${switcHummberger ? '' : ''}`} onClick={() => { toglg_switc_humberger() }}>
                {switcHummberger ?
                    <span style={{ fontSize: '27px', position: 'relative', left: '20px', top: '-12px' }}> <div style={{ position: 'fixed', transform: 'rotate(91deg)' }}> {`<`} </div> </span> :
                    <span style={{ fontSize: '27px', position: 'relative', left: '20px', top: '-13px' }}> <div style={{ position: 'fixed', transform: 'rotate(91deg)' }}> {`>`} </div> </span>}
            </div>
        </div>


    </>)
}

export default SlotLocation;