import React, { useEffect, useState, useRef } from 'react'
import "./style.css"

import fedImgDefault from './img/defultUser.png';
import fedHeaderImgDefault from './img/Header-No Image.png';
import { Tabs } from 'antd';
import FederationWorkload from './component/federationWorkload';
import FederationTimesheetMonth from './component/federationTimesheetMonth';
import FederationFollowUp from './component/federationFollowUp';
import FederationSummaryEffort from './component/federationSummaryEffort';
import FederationSummaryTimeSheetMonth from './component/federationSummaryTimeSheetMonth';
import { NumericFormat, PatternFormat } from 'react-number-format';
import dayjs from "dayjs";
import Select from 'react-select'
import Configs from "../../../config";
import user_img from "./img/defultUser.png";
import axios from "axios";
import styled from 'styled-components';
import { io } from 'socket.io-client';
import fed_time_sheet_month_report from './img/fed_timesheet_work_month_report_element.png';
import fed_workload from './img/fed_workload_element.png';
import fed_follow from './img/fed_follow.png';
import fed_summary_effort from './img/Summary_Effort_Use_Report.png';
import fed_summary_timesheet from './img/Summary_Timesheet_Month_Report.png';
import { Checkbox, Input, DatePicker, TimePicker, ConfigProvider,Tooltip } from 'antd';
const { TextArea } = Input;




const StyledDatePicker = styled(DatePicker)`
.ant-picker-clear {
    padding-Right: 10px; 
        display:none;
}
    .ant-picker-input > input {
    color: #FFF; /* เปลี่ยนสีฟอนต์ที่นี่ */
  }
:where(.css-dev-only-do-not-override-qgg3xn).ant-picker-dropdown .ant-picker-date-panel .ant-picker-body{
 background:  #16285C   
    
    }
`;
const StyledTimePicker = styled(TimePicker)`
.ant-picker-clear {
    padding-Right: 10px; 
    display:none;
       color: #FFF;
}
.ant-picker-input > input {
    color: #FFF; /* เปลี่ยนสีฟอนต์ที่นี่ */
  }
.ant-picker-now {
  display: none;
}
`;

const ellipsisStyle = {
    width: "300px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    textAlign: "left",
    fontSize: "13px",
    textDecoration: "underline"

};

function FederationMgt({ isOpen, toggle_modal_fedMgtConfig, getFederationDetailByOwner, CallbackImg,onElement,closeElement }) {
    const uploadRef = useRef(null);
    const uploadRef_fed = useRef(null);
    const id = sessionStorage.getItem('fed_id');
    const [fedColor, setFedColor] = useState([
        {
            color: '#676767',
            filter: 'drop-shadow(0px 0px 10px #676767)'
        },
        {
            color: '#FF0000',
            filter: 'drop-shadow(0px 0px 10px #FF0000)'
        },
        {
            color: '#FFEB3B',
            filter: 'drop-shadow(0px 0px 10px #FFEB3B)'
        },
        {
            color: '#259B24',
            filter: 'drop-shadow(0px 0px 10px #259B24)'
        },
        {
            color: '#BA84FF',
            filter: 'drop-shadow(0px 0px 10px #BA84FF)'
        },
        {
            color: '#00A5FF',
            filter: 'drop-shadow(0px 0px 10px #00A5FF)'
        },
        {
            color: '#FF8000',
            filter: 'drop-shadow(0px 0px 10px #FF8000)'
        },
        {
            color: '#FF00E5',
            filter: 'drop-shadow(0px 0px 10px #FF00E5)'
        },
    ]);

    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                port:Configs.TTT_Game_Socket_Service_Port
              });
        }
    }, [

    ])

    useEffect(() => {
      const { current: socket } = socketRef;
      const socFnc = (data) => {}
        try {
          socket.open();
          socket.on('locationElement', socFnc)
        } catch (error) {
          console.log(error);
        }
        
      }, []);

    const [activeKTabs, setActiveKTabs] = useState('INFORMATION');
    const [workdayData, setWorkDayData] = useState([]);
    const [text_showerror, setText_showerror] = useState('');
    const [alertKick_imge, setAlertKick_imge] = useState(false);
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const toggle_modal_alertKick_image = () => setAlertKick_imge(!alertKick_imge);
    const [alertKickSuccess, setAlertKickSuccess] = useState(false);
    const toggle_modal_alertKickSuccess = () => setAlertKickSuccess(!alertKickSuccess);
    const [errorMasgerList, setErrorMasgerList] = useState({
        isError: false,
        massger: []
    })

    const [test, settest] = useState({
        test: ''
    })
    const [spaceshipSelectList, setSpaceshipSelectList] = useState([]); // ยานที่เลือกเเล้ว
    const [userSelectList, setUserSelectList] = useState([]); // USerที่เลือกเเล้ว
    const [spaceshipList, setSpaceshipList] = useState([]);
    const [countData, setCountData] = useState(0);
    const [userList, setUserList] = useState([]);
    const [countData2, setCountData2] = useState(0);
    const [roleOption, setRoleOption] = useState([])
    const [federationDetail, setFederationDetail] = useState({
        size: '',
        fed_name: '',
        organize_id: '',
        nature_business: '',
        fed_email: '',
        fed_tel: '',
        fed_detail: '',
        fed_address: '',
        fed_color: '#DEDEDE',
        image_name: '',
        image_path: '',
        image_file: '',
        is_save: false,
        image_header_name: '',
        image_header_path: '',
        image_header_file: '',
        is_header_save: false,
        federation_owner_id: ''
    });

    // console.log('federationDetail', federationDetail)

    const [modalSpaceship, setModalSpaceship] = useState(false);
    const toggle_select_spaceship = () => setModalSpaceship(!modalSpaceship);
    const [onSearch, setOnSearch] = useState({
        show: 10,
        current: 1,
    });
    const [onSearch2, setOnSearch2] = useState({
        show: 10,
        current: 1,
    });
    const [filter, setFilter] = useState({
        keySearch: ''
    });
    const [filter2, setFilter2] = useState({
        keySearch: ''
    });


    const [alertKickLoading, setAlertKickLoading] = useState(false);
    const toggleLoading = () => setAlertKickLoading(!alertKickLoading);

    const [federationWorkloadIsOpen, setFederationWorkloadIsOpen] = useState(false);
    const toggleFederationWorkloadIsOpen = () => setFederationWorkloadIsOpen(!federationWorkloadIsOpen);

    const [federationTimeSheetMonthReportIsOpen, setFederationTimeSheetMonthReportIsOpen] = useState(false);
    const toggleFederationTimeSheetMonthReportIsOpen = () => setFederationTimeSheetMonthReportIsOpen(!federationTimeSheetMonthReportIsOpen);


    const [federationFollowUpIsOpen, setFederationFollowUpIsOpen] = useState(false);
    const toggleFederationFollowUpIsOpen = () => setFederationFollowUpIsOpen(!federationFollowUpIsOpen);

    const [federationSummaryEffortIsOpen, setFederationSummaryEffortIsOpen] = useState(false);
    const toggleFederationSummaryEffortIsOpen = () => setFederationSummaryEffortIsOpen(!federationSummaryEffortIsOpen);

    const [federationSummaryTimeSheetMonthIsOpen, setFederationSummaryTimeSheetMonthIsOpen] = useState(false);
    const toggleFederationSummaryTimeSheetMonthIsOpen = () => setFederationSummaryTimeSheetMonthIsOpen(!federationSummaryTimeSheetMonthIsOpen);
    
    function pushSpaceship(space_id) {
        let tempSpaceSelect = spaceshipSelectList || [];
        let tempSpace = spaceshipList || [];
        let dataSpace = tempSpace.filter((e) => { return e.spaceship_id === space_id });
        console.log(dataSpace)
        if (dataSpace.length > 0) {
            let index = tempSpace.findIndex((i) => i.spaceship_id === space_id);
            console.log(index)
            if (index > -1) {
                tempSpace[index].isSelect = true;
                console.log('IN')
            }
            tempSpaceSelect.push(dataSpace[0]);
        }

        setSpaceshipList(tempSpace);
        setSpaceshipSelectList(tempSpaceSelect);
        settest({ ...test, test: '' });
        test.test = '';


    }



    function removeSpaceship(space_id) {
        let tempSpaceSelect = spaceshipSelectList || [];
        let tempSpace = spaceshipList || [];

        let index_select = tempSpaceSelect.findIndex((e) => { return e.spaceship_id === space_id })
        if (index_select > -1) {
            let spaceDetail = tempSpaceSelect[index_select];

            let index2 = tempSpace.findIndex((i) => i.spaceship_id === spaceDetail.spaceship_id);
            if (index2 > -1) {
                tempSpace[index2].isSelect = false;
            }
            tempSpaceSelect.splice(index_select, 1);
        }
        setSpaceshipList(tempSpace);
        setSpaceshipSelectList(tempSpaceSelect);

        settest({ ...test, test: '' });
        test.test = "";


    }

    async function DeleteUpload(index) {
        try {
            let temp = [...spaceshipSelectList]

            let indexID = temp[index].file_id_list.findIndex((e2) => e2 == temp[index].spaceship_cover_file_id);
            console.log('indexID', indexID)
            if (indexID > -1) {

                temp[index].file_id_list.splice(indexID, 1);
            }

            temp[index].image_path = null;
            temp[index].image_name = null;
            temp[index].image_file = null;
            temp[index].is_active = false;
            temp[index].is_change = true;
            temp[index].is_new = false;

            temp[index].spaceship_cover_filename = null;
            temp[index].spaceship_cover_file_id = null;
            temp[index].spaceship_cover_file = null;
            setSpaceshipSelectList(temp)

        } catch (error) {
            console.log("DeleteUpload", error);
        };
    };
    const [allFileDataDetail, setAllFileDataDetail] = useState({
        image_cover: '',
        image_profile: '',
        image_profile_id: '',
        image_cover_id: ''
    });

    const [fileUploadList, setFileUploadList] = useState([]);

    async function GetAccesstokenToken(callback) {
        await axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                /* Authorization: getToken(), */
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                var temp = {
                    tokenBox: response.data.tokenBox
                }
                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
                /*  console.log('ViewToken', response.data); */
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function getFileBoxByIdImage(image_id, callback) {
        await axios({
            method: "get",
            url: `https://api.box.com/2.0/files/${image_id}/content`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',

        })
            .then(async function (response) {

                if (callback) {
                    let tmp = {
                        fileImg: URL.createObjectURL(response.data),
                        file_id: image_id,

                    }
                    callback(tmp);
                }

            }).catch((err) => {

                if (callback) {
                    let tmp = {
                        fileImg: null,
                        file_id: null,

                    }
                    callback(tmp);
                }
            })

    }

    function getFederationByID() {
        setAllFileDataDetail({
            ...allFileDataDetail,
            image_cover: '',
            image_profile: '',
            image_profile_id: '',
            image_cover_id: ''
        });
        axios({
            method: "get",
            url: `${Configs.TTT_Game_Service}/api/federation/getFederation/${sessionStorage.getItem('fed_id')}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                let temp = [];




                setFederationDetail({
                    ...federationDetail,

                    fed_name: response.data.federation_name || '',
                    organize_id: response.data.federation_organization_id || '',
                    nature_business: response.data.federation_nb_id || '',
                    fed_email: response.data.federation_email || '',
                    fed_tel: response.data.federation_phone_number || '',
                    fed_detail: response.data.federation_detail || '',
                    fed_address: response.data.federation_address || '',
                    fed_color: response.data.federation_color || '#DEDEDE',
                    image_name: response.data.image_name || '',
                    image_path: response.data.image_path || '',
                    federation_owner_id: response.data.federation_owner_id || '',
                    image_header_name: response.data.image_header_name || '',
                    image_header_path: response.data.image_header_path || '',



                });
                setFileUploadList(response.data.file_id_list);
                for (let item of response.data.spaceShip_list) {
                    temp.push({
                        is_active: item.spaceship_is_active_cover || false,
                        image_name: item.spaceship_cover_filename || '',
                        image_path: item.spaceship_cover_filepath || '',
                        image_file: '',
                        is_chacge: false,
                        ...item
                    })
                }

                setSpaceshipSelectList(temp);

                setUserSelectList(response.data.listOwner);
                await GetAccesstokenToken(async (result_) => {
                    await getFileBoxByIdImage(response.data.image_profile_id, (result) => {
                        if (result) {
                            console.log('result', result)
                            setAllFileDataDetail({
                                ...allFileDataDetail, image_profile: result.fileImg, image_profile_id: result.file_id
                            });
                            allFileDataDetail.image_profile = result.fileImg;
                            allFileDataDetail.image_profile_id = result.file_id;
                            getFileBoxByIdImage(response.data.image_cover_id, (result2) => {
                                if (result2) {
                                    setAllFileDataDetail({
                                        ...allFileDataDetail, image_cover: result2.fileImg, image_cover_id: result2.file_id
                                    });
                                    allFileDataDetail.image_cover = result2.fileImg;
                                    allFileDataDetail.image_cover_id = result2.file_id;

                                }
                            })
                        }
                    })
                })

                let index = 0;
                for (let item of temp) {
                    let boxFile_id = item.image_profile_id || null;
                    if (item.image_profile_id == null && item.imgobject) {
                        boxFile_id = item.imgobject.fd_img_id
                    }
                    if (boxFile_id) {
                        await getFileBoxByIdImage(boxFile_id, (result) => {
                            if (result) {
                                console.log('result2', result)
                                item.image_profile = result.fileImg;



                            }
                        })
                    }


                    await getFileBoxByIdImage(item.spaceship_cover_file_id, (result) => {
                        if (result) {
                            console.log('result', result)
                            item.spaceship_cover_file = result.fileImg;

                            /*   if(index +1 == response.data.spaceShip_list.length){
                             
                                setSpaceshipSelectList(response.data.spaceShip_list);
                              } */

                        }
                    })

                    index++;
                }

                for (let item of response.data.listOwner) {
                    if (item.image_profile_id) {
                        await getFileBoxByIdImage(item.image_profile_id, (result) => {
                            if (result) {
                                item.image_profile = result.fileImg;
                            }
                        })
                    }
                }

                setUserSelectList(response.data.listOwner);
                setSpaceshipSelectList(temp);
                settest({ ...test, test: '' });
                test.test = '';

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function filterSpaceship(page, enties, searchKeyList, sortList) {

        let temp = {
            keySearch: filter.keySearch.trim() || '',
            page: page || 1,
            enties: enties || 10,
            sortList: sortList || {},
            fed_id: sessionStorage.getItem('fed_id')
        };
        /* console.log(temp) */
        await axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/spaceship/filterSpaceShipWithoutFederation`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp,
        })
            .then(async function (response) {
                setOnSearch({
                    ...onSearch,
                    current: page || 1,
                    show: enties || 10,
                });
                setSpaceshipList(response.data.data);
                setCountData(parseInt(response.data.count || 0))



            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function BusinessTypeOption() {

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/BusinessTypeOption`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.nb_id,
                        label: e.nb_name
                    }
                    tem.push(setT)
                })

                setRoleOption(tem);


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function getOrgaizerFed(params) {
        await axios({
            method: "get",
            url: `${Configs.TTT_Game_Service}/api/federation/getOrgaizer/${id}`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            /* data: temp, */
        })
            .then(async function (response) {
                for (let item of response.data) {
                    var start = dayjs(item.start_time_text, 'HH:mm');
                    var end = dayjs(item.end_time_text, 'HH:mm');
                    var differenceInMinutes = end.diff(start, 'minute');
                    var sumDate = differenceInMinutes;
                    var hours = Math.floor(sumDate / 60);
                    let sumTime = hours;
                    /*  console.log('sumDate',sumDate); */


                    var minutes = differenceInMinutes % 60;
                    let sumTimeMinit = minutes;
                    item.end_time = dayjs(item.end_time_text, 'HH:mm');
                    item.start_time = dayjs(item.start_time_text, 'HH:mm');

                    item.man_hour = `${isNaN(sumTime) ? 0 : sumTime}H ${isNaN(sumTimeMinit) ? 0 : sumTimeMinit}M`;
                }
                setWorkDayData(response.data)

            })
            .catch(function (error) {
                setAlertKickLoading(false);
                console.log(error);
            });
    }

    useEffect(() => {
        socketRef.current.emit('join', "locationElement" + sessionStorage.getItem('user_id'));
        if (isOpen == true) {
            socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'FederationInfo',fed_id:id});
            getFederationByID();
            filterSpaceship();
            BusinessTypeOption();
            setWorkDayData([]);
            getOrgaizerFed();
            setActiveKTabs("INFORMATION");
        }else{
            socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'StageMonitoring'});
        }

    }, [isOpen])


    async function CheckBefoSave(data) {
        const error_list = [];
        console.log('activeKTabs', activeKTabs);

        if (activeKTabs === "INFORMATION") {

            if (federationDetail.fed_name.trim() == "") {
                let temp_err = {
                    message: "Please enter information in the fields >>> [Federation Name]."
                }
                error_list.push(temp_err);
            }
            if (federationDetail.nature_business.trim() == "") {
                let temp_err = {
                    message: "Please enter information in the fields >>> [Bussiness Type]."
                }
                error_list.push(temp_err);
            }
            if (federationDetail.fed_tel == "") {
                let temp_err = {
                    message: "Please enter information in the fields >>> [Phone Number]."
                }
                error_list.push(temp_err);
            } else {
                if (federationDetail.fed_tel.toString().trim().length !== 12) {
                    let temp_err = {
                        message: "Please enter a complete 10 digit phone number."
                    }
                    error_list.push(temp_err);
                }
            }
        } else if (activeKTabs === "ORGANIZER") {
            if (workdayData.length > 0) {

                for (let item of workdayData) {
                    if (item.date == "" || item.date == null) {
                        let temp_err = {
                            message: "Please enter information in the fields >>> [Date]."
                        }
                        error_list.push(temp_err);
                    }
                    if (item.start_time == "" || item.start_time == null) {
                        let temp_err = {
                            message: "Please enter information in the fields >>> [Start Time]."
                        }
                        error_list.push(temp_err);
                    }
                    if (item.end_time == "" || item.end_time == null) {
                        let temp_err = {
                            message: "Please enter information in the fields >>> [End Time]."
                        }
                        error_list.push(temp_err);
                    }
                }
            }

        }


        if (error_list.length > 0) {
            var err_message = "";
            for (var e = 0; e < error_list.length; e++) {
                err_message += "<br/>" + error_list[e].message;
            }
            setErrorMasgerList({
                ...errorMasgerList,
                isError: true,
                massger: error_list
            })
            errorMasgerList.isError = true;
            toggle_modal_alertKickSuccess();

            console.log('Error', true);

            /* Swal.fire("Error", err_message, "error"); */
        } else {


            if (activeKTabs === "ORGANIZER") {
                setAlertKickLoading(true);
                let temp = {
                    fed_id: id,
                    body: workdayData || []
                };
                await axios({
                    method: "POST",
                    url: `${Configs.TTT_Game_Service}/api/federation/SaveOrgaizerFed`,
                    headers: {
                        'Authorization': sessionStorage.getItem('access_token'),
                        'X-TTT': `${Configs.API_TTT}`,
                        'Content-Type': 'application/json',
                    },
                    data: temp,
                })
                    .then(async function (response) {

                        getOrgaizerFed();
                        setActiveKTabs('ORGANIZER');
                        let trop = []
                        let temp_succ = {
                            message: "success"
                        }
                        trop.push(temp_succ);
                        setErrorMasgerList({
                            ...errorMasgerList,
                            isError: false,
                            massger: trop
                        })
                        toggle_modal_alertKickSuccess();
                        setAlertKickLoading(false);
                        settest({ ...test, test: '' });
                        test.test = ''

                    })
                    .catch(function (error) {
                        setAlertKickLoading(false);
                        console.log(error);
                    });


            } else {
                setAlertKickLoading(true);
                let temp = {
                    fed_id: id,
                    fed_name: federationDetail.fed_name.trim() || '',
                    organize_id: federationDetail.organize_id.trim() || '',
                    nature_business: federationDetail.nature_business.trim() || '',
                    fed_email: federationDetail.fed_email.trim() || '',
                    fed_tel: federationDetail.fed_tel,
                    fed_detail: federationDetail.fed_detail.trim() || '',
                    fed_address: federationDetail.fed_address.trim() || '',
                    fed_color: federationDetail.fed_color,
                    image_path: federationDetail.image_path || '',
                    image_name: federationDetail.image_name || '',
                    image_header_path: federationDetail.image_header_path || '',
                    image_header_name: federationDetail.image_header_name || '',
                    spaceship_list: spaceshipSelectList || [],
                    is_header_save: federationDetail.is_header_save,
                    is_save: federationDetail.is_save,
                    owner: userSelectList || [],
                    federation_owner_id: federationDetail.federation_owner_id
                }

                const file = federationDetail.image_file;
                const file2 = federationDetail.image_header_file;
                const dataImg = new FormData();
                const dataImg_list = new FormData();

                dataImg.append('Profile', file);
                dataImg.append('Profile', file2);
                dataImg.append('typeFile', "Federation");
                dataImg.append('projectKey', "TTTSPACESHIP");

                //-------------------------List-------------------------------
                spaceshipSelectList.forEach((item, index) => {
                    if (item.is_chacge) {
                        if (item.image_file) {
                            dataImg_list.append('Profile', item.image_file);
                        }
                    }
                });

                dataImg_list.append('typeFile', "SpaceShipCover");
                dataImg_list.append('projectKey', "TTTSPACESHIP");

                /*           const configUploadProfile_list = {
                              method: 'post',
                              url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                              headers: {
                                  'Authorization': sessionStorage.getItem('access_token'),
                                  'X-TTT': `${Configs.API_TTT}`,
                                  'Content-Type': 'application/octet-stream',
                              },
                              data: dataImg_list,
                          }; */
                /*   axios(configUploadProfile_list).then(async function (responseImg) {
                      let temp_data = [];
                      let temp_space = [...spaceshipSelectList];
      
                      if (responseImg.data.data && responseImg.data.data.length > 0) {
      
                          for(let item of temp_space){
                              if(item.is_chacge){
                                  const _find_data = responseImg.data.data.find((el) => el.orgin_name === item.image_name);
          
                                  if(_find_data){
                                      item.image_path = _find_data.path
                                      temp_data.push({ 
                                          ...item
                                      });
                                  }else{
                                      temp_data.push({ 
                                          ...item
                                      });
                                  }
                              }
                              else{
                                  temp_data.push({ 
                                      ...item
                                  });
                              }
                          }
                      }
                      // console.log('temp_data', temp_data);
                      temp.spaceship_list = temp_data.length > 0 ? temp_data : spaceshipSelectList */


                let getFileSpaceHeadImgSave = spaceshipSelectList.filter((e) => { return e.is_new === true });
                console.log('getFileSpaceHeadImgSave', getFileSpaceHeadImgSave)
                /*    const configUploadProfile = {
                       method: 'post',
                       url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                       headers: {
                           'Authorization': sessionStorage.getItem('access_token'),
                           'X-TTT': `${Configs.API_TTT}`,
                           'Content-Type': 'application/octet-stream',
                       },
                       data: dataImg,
                   }; 
                   axios(configUploadProfile).then(async function (responseImg) {
                       if (responseImg.data.data && responseImg.data.data.length > 0) {
                           for (let item of responseImg.data.data) {
                               if (item.orgin_name == temp.image_name) {
                                   temp.image_path = item.path;
                               } else
                                   if (item.orgin_name == temp.image_header_name) {
                                       temp.image_header_path = item.path;
                                   }
                           }
                       } */
                axios({
                    method: "post",
                    url: `${Configs.TTT_Game_Service}/api/federation/updateFederation`,
                    headers: {
                        'Authorization': sessionStorage.getItem('access_token'),
                        "X-TTT": `${Configs.API_TTT}`,
                        "Content-Type": "application/json",
                    },
                    data: temp,
                })
                    .then(async function (response) {
                        if (response.data) {



                            await CheckAndCreateFolderBox(response.data.id, "FEDERATION PROFILE", async (result) => {
                                if (result) {
                                    await UploadFiles(result.folder_id, result.folder_name, result.ref_id, file, async (result2) => {
                                        if (result2 == true) {
                                            await CheckAndCreateFolderBox(response.data.id, "FEDERATION IMAGE COVER", async (result3) => {
                                                if (result3) {
                                                    await UploadFiles(result3.folder_id, result3.folder_name, result3.ref_id, file2, async (result4) => {
                                                        if (result4 == true) {
                                                            for (let item of spaceshipSelectList) {

                                                                await CheckAndCreateFolderBoxSpaceship(item.spaceship_id, "SPACESHIP COVER", async (result5) => {
                                                                    if (result5) {
                                                                        await UploadFilesSpaceship(result5.folder_id, result5.folder_name, result5.ref_id, item.image_file, item.file_id_list || [], async (result6) => {
                                                                            if (result6 == true) {

                                                                                /*    Swal.close(); */
                                                                            }
                                                                        })
                                                                    }
                                                                })
                                                            }
                                                            setAlertKickLoading(false);
                                                            let trop = []
                                                            let temp_succ = {
                                                                message: "success"
                                                            }
                                                            trop.push(temp_succ);
                                                            setErrorMasgerList({
                                                                ...errorMasgerList,
                                                                isError: false,
                                                                massger: trop
                                                            })
                                                            toggle_modal_alertKickSuccess();
                                                            getFederationByID();
                                                            /*  Swal.fire({
                                                               icon: "success",
                                                               title: "Save",
                                                               showConfirmButton: false,
                                                               timer: 1500,
                                                             }).then((result_) => {
                                                               window.location.href =
                                                                 "/M-20240220109";
                                                             }); */
                                                        }
                                                    })
                                                }
                                            });
                                        }
                                    })
                                }

                            }
                            );



                            /*  Swal.fire({
                                    icon: "success",
                                    title: "Save",
                                    showConfirmButton: false,
                                    timer: 1500,
                                }).then((result) => {
                                    window.location.href =
                                        "/M-20240220109";
                                }); */




                            getFederationDetailByOwner();
                            /* CallbackImg(federationDetail.image_path, federationDetail.fed_name) */


                            setFederationDetail({
                                ...federationDetail,
                                size: '',
                                fed_name: '',
                                organize_id: '',
                                nature_business: '',
                                fed_email: '',
                                fed_tel: '',
                                fed_detail: '',
                                fed_address: '',
                                fed_color: '#DEDEDE',
                                image_name: '',
                                image_path: '',
                                image_file: '',
                                is_save: false,
                                image_header_name: '',
                                image_header_path: '',
                                image_header_file: '',
                                is_header_save: false,
                                federation_owner_id: ''

                            })
                          
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                        setAlertKickLoading(false);
                        /* Swal.fire(
                            "Error",
                            error.response.data.error[0].errorDis,
                            "error"
                        ); */
                    });



                // const configUploadProfile = {
                //     method: 'post',
                //     url: `${Configs.TTT_Game_Service}/api/upload/document2`,
                //     headers: {
                //         'Authorization': sessionStorage.getItem('access_token'),
                //         'X-TTT': `${Configs.API_TTT}`,
                //         'Content-Type': 'application/octet-stream',
                //     },
                //     data: dataImg,
                // }; 
                // axios(configUploadProfile).then(async function (responseImg) {
                //     if (responseImg.data.data && responseImg.data.data.length > 0) {
                //         for (let item of responseImg.data.data) {
                //             if (item.orgin_name == temp.image_name) {
                //                 temp.image_path = item.path;
                //             } else
                //                 if (item.orgin_name == temp.image_header_name) {
                //                     temp.image_header_path = item.path;
                //                 }
                //         }
                //     }
                //     axios({
                //         method: "post",
                //         url: `${Configs.TTT_Game_Service}/api/federation/updateFederation`,
                //         headers: {
                //             'Authorization': sessionStorage.getItem('access_token'),
                //             "X-TTT": `${Configs.API_TTT}`,
                //             "Content-Type": "application/json",
                //         },
                //         data: temp,
                //     })
                //     .then(function (response) {
                //         if (response.data) {
                //             /*  Swal.fire({
                //                     icon: "success",
                //                     title: "Save",
                //                     showConfirmButton: false,
                //                     timer: 1500,
                //                 }).then((result) => {
                //                     window.location.href =
                //                         "/M-20240220109";
                //                 }); */

                //             let trop = []
                //             let temp_succ = {
                //                 message: "success"
                //             }
                //             trop.push(temp_succ);
                //             setErrorMasgerList({
                //                 ...errorMasgerList,
                //                 isError: false,
                //                 massger: trop
                //             })

                //             toggle_modal_alertKickSuccess();
                //             getFederationDetailByOwner();
                //             CallbackImg(federationDetail.image_path, federationDetail.fed_name)
                //             // toggle_modal_fedMgtConfig();

                //             setFederationDetail({
                //                 ...federationDetail,
                //                 size: '',
                //                 fed_name: '',
                //                 organize_id: '',
                //                 nature_business: '',
                //                 fed_email: '',
                //                 fed_tel: '',
                //                 fed_detail: '',
                //                 fed_address: '',
                //                 fed_color: '#DEDEDE',
                //                 image_name: '',
                //                 image_path: '',
                //                 image_file: '',
                //                 is_save: false,
                //                 image_header_name: '',
                //                 image_header_path: '',
                //                 image_header_file: '',
                //                 is_header_save: false,
                //                 federation_owner_id: ''

                //             })

                //         }
                //     })
                //     .catch(function (error) {
                //         console.log(error);
                //         /* Swal.fire(
                //             "Error",
                //             error.response.data.error[0].errorDis,
                //             "error"
                //         ); */
                //     });
                // });

                /*Swal.fire({
                       title: "Saving",
                       allowEscapeKey: false,
                       allowOutsideClick: false,
                       timer: 1000,
                       onOpen: () => {
                           Swal.showLoading();
                       },
                }).then((result) => {
                     
                }) */
            }

        }
    }


    async function CheckAndCreateFolderBox(fed_id, folder_name, callback) {
        let temp = {
            folderLis: ["FEDERATION", `FEDERATION_${fed_id}`, folder_name]
        }
        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }, data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let folder_id = response.data.folder_id;
                    if (callback) {
                        let res = {
                            ref_id: fed_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                        callback(res)
                    }

                    /* UploadFiles(folder_id,folder_name,fed_id,file); */
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function CheckAndCreateFolderBoxSpaceship(space_id, folder_name, callback) {
        let temp = {
            folderLis: ["SPACESHIP", `SPACESHIP_${space_id}`, folder_name]
        }
        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }, data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let folder_id = response.data.folder_id;
                    if (callback) {
                        let res = {
                            ref_id: space_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                        callback(res)
                    }

                    /* UploadFiles(folder_id,folder_name,fed_id,file); */
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function UploadFiles(folder_id, key, ref_id, file, callback) {
        let image_id_list = fileUploadList || [];
        let data_ = new FormData();
        data_.append('File', file);
        data_.append('folder_id', folder_id);
        data_.append('key', key);
        data_.append('image_old_list', JSON.stringify(image_id_list));
        data_.append('ref_id', ref_id);

        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: data_,
        })
            .then(async function (response) {
                let temp = fileUploadList || [];
                for (let item of response.data.image_id_list) {
                    temp.push(item);
                }
                setFileUploadList(temp);
                settest({ ...test, test: '' });
                test.test = '';
                if (callback) {

                    callback(true)
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function UploadFilesSpaceship(folder_id, key, ref_id, file, file_old_list, callback) {
        let image_id_list = file_old_list || [];
        let data_ = new FormData();
        data_.append('File', file);
        data_.append('folder_id', folder_id);
        data_.append('key', key);
        data_.append('image_old_list', JSON.stringify(image_id_list));
        data_.append('ref_id', ref_id);

        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: data_,
        })
            .then(async function (response) {
                let temp = fileUploadList || [];
                for (let item of response.data.image_id_list) {
                    temp.push(item);
                }
                setFileUploadList(temp);
                settest({ ...test, test: '' });
                test.test = '';
                if (callback) {

                    callback(true)
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }

    const styaTab = {
        color: '#FFFF',
        backgroundColor: '#1e2026 !important'
    }



    function AddWorkDay(index) {
        let ole = workdayData;
        var temp = {
            date: null,
            start_time: null,
            end_time: null,
            man_hour: 0,
            description: '',
            start_time_text: '',
            end_time_text: ''
        }
        ole.push(temp);
        setWorkDayData(ole);
        settest({ ...test, test: '' });
        test.test = '';

    }


    function setValueInArrayWorkdays(value, key, index) {
        console.log('valueTest', value);

        let ole = workdayData;
        let sumMan = 0;
        let getStartTime = ole[index].start_time;
        let getEndTime = ole[index].end_time;
        if (key == "start_time") {
            var start = dayjs(value, 'HH:mm');
            var end = dayjs(getEndTime, 'HH:mm');
            var differenceInMinutes = end.diff(start, 'minute');
            const hours = Math.floor(differenceInMinutes / 60);
            const minutes = differenceInMinutes % 60;

            ole[index].start_time = dayjs(value, 'HH:mm');
            ole[index].start_time_text = value;
            ole[index].man_hour = `${isNaN(hours) ? 0 : hours}H ${isNaN(minutes) ? 0 : minutes}M`;

        } else if (key == "end_time") {
            var start = dayjs(getStartTime, 'HH:mm');
            var end = dayjs(value, 'HH:mm');
            var differenceInMinutes = end.diff(start, 'minute');
            const hours = Math.floor(differenceInMinutes / 60);
            const minutes = differenceInMinutes % 60;

            console.log('DATEND', dayjs(value, 'HH:mm'));


            ole[index].end_time = dayjs(value, 'HH:mm');
            ole[index].end_time_text = value;
            ole[index].man_hour = `${isNaN(hours) ? 0 : hours}H ${isNaN(minutes) ? 0 : minutes}M`;
        } else {
            ole[index][key] = value;
        }
        setWorkDayData(ole);
        settest({ ...test, test: '' });
        test.test = '';

    }


    function DelWorkDay(index) {
        let ole = workdayData;
        ole.splice(index, 1)
        setWorkDayData(ole);
        settest({ ...test, test: '' });
        test.test = '';
    }


    const disabledStartHours = (index) => {
        const endTime = workdayData[index].end_time ? dayjs(workdayData[index].end_time, 'HH:mm') : null;
        if (!endTime) return [];
        const disabledHours = [];
        for (let i = 0; i < 24; i++) {
            if (dayjs().hour(i).isAfter(endTime)) {
                disabledHours.push(i);
            }
        }
        return disabledHours;
    };

    const disabledEndHours = (index) => {
        const startTime = workdayData[index].start_time ? dayjs(workdayData[index].start_time, 'HH:mm') : null;
        if (!startTime) return [];
        const disabledHours = [];
        for (let i = 0; i < 24; i++) {
            if (dayjs().hour(i).isBefore(startTime)) {
                disabledHours.push(i);
            }
        }
        return disabledHours;
    };



    const disabledStartMinutes = (index, selectedHour) => {
        const endTime = workdayData[index].end_time ? dayjs(workdayData[index].end_time, 'HH:mm') : null;
        if (!endTime || selectedHour !== endTime.hour()) return [];
        const disabledMinutes = [];
        for (let i = 0; i < 60; i++) {
            if (dayjs().minute(i).isAfter(endTime.minute())) {
                disabledMinutes.push(i);
            }
        }
        return disabledMinutes;
    };

    const disabledEndMinutes = (index, selectedHour) => {
        const startTime = workdayData[index].start_time_text ? dayjs(workdayData[index].start_time_text, 'HH:mm') : null;
        if (!startTime || selectedHour !== startTime.hour()) return [];
        const disabledMinutes = [];
        for (let i = 0; i < 60; i++) {
            if (i < startTime.minute()) {
                disabledMinutes.push(i);
            }
        }
        return disabledMinutes;
    };


    useEffect(()=>{
   
        console.log('onElement111',onElement)
        if(onElement == '51e77d0d-12cf-4aa7-b036-ee23e4ec987e'){
            // Follow Up
            toggleFederationFollowUpIsOpen();
        }
        if(onElement == '447d06a5-4da3-42c9-bad4-0648ba651b47'){
            // Federation Workload
            toggleFederationWorkloadIsOpen();
        }
        if(onElement == '559b47fa-45e4-48e9-82d6-59df2bc9fddd'){
            // Federation Work Month report
            toggleFederationTimeSheetMonthReportIsOpen();
        }
        if(onElement == '5e21f127-b7eb-4f9d-bebc-ec5fdd8fc0bb'){
            // Summary Effort Use
            toggleFederationSummaryEffortIsOpen();
        }
        if(onElement == '3f5f49d4-d3dd-4548-8ca4-1389e8e690ae'){
            // Federation Work Month report
            toggleFederationSummaryTimeSheetMonthIsOpen();
        }
    },[onElement]);



    return (
        <>
            <div className='fedMgtBgSet' style={{ display: `${isOpen ? '' : 'none'}` }}>
                <div className='fedMgtBg'>
                    <div className='row fedMgtClose'>
                        <div className='fedMgtCloseButton' onClick={() => {
                            setActiveKTabs('INFORMATION');
                            toggle_modal_fedMgtConfig()
                        }}>X</div>
                    </div>

                    <div className='mb-4 row fedMgtHeader'>
                        FEDERATION MANAGEMENT
                    </div>
                    {console.log('เช็คหน่อย', activeKTabs)
                    }
                    <ConfigProvider theme={{
                        token: {


                            colorInfo: '#85BFFF',
                            colorBorder: '#85BFFF',
                            colorText: '#FFF',
                            colorTextQuaternary: 'White',//สีตัวอักษร
                            colorTextDescription: 'White',
                            colorBgContainer: '#16285C',

                            zIndexPopupBase: 999999,
                            colorPrimaryBg: '#0047FF',
                            colorBgBase: '#343a40',

                            colorTextQuaternary: '#FFFFFF',


                            colorFillTertiary: 'blue',


                            colorTextBase: 'White',




                            colorPrimaryBg: '#85BFFF',


                        }
                    }} >
                        <Tabs className='stalyTabs1'
                            defaultActiveKey={"INFORMATION"} popupClassName="stalyTabs1"
                            tabBarStyle={styaTab}
                            activeKey={activeKTabs} onChange={(e) => {

                                if (e === "ORGANIZER") {
                                    getOrgaizerFed();
                                }
                                setActiveKTabs(e);

                                settest({ ...test, test: '' });
                                test.test = '';
                            }} items={
                                [
                                    {
                                        key: 'INFORMATION',
                                        label: 'FEDERATION INFORMATION ',
                                        children: (<div className='row fedMgtBody mt-3'>
                                            <div className='col-8'>
                                                <div className='row'>
                                                    {/*      <div className='col-4'>
                                                    <div style={{ filter: 'drop-shadow(0px 0px 2px #676767)', width: '100%' }}>
                                                        <label type="button" className="pi-infoBoxTextCen" style={{ width: '100%', cursor: 'pointer', backgroundColor: '#0047FF', clipPath: 'polygon(7.5% 0px, 100% 0px, 100% 68%, 93.3% 100%, 0px 100%, 0px 31%)' }} onClick={() => {
                                                            if (!federationWorkloadIsOpen) {
                                                                toggleFederationWorkloadIsOpen();
                                                            }
                                                        }}>
                                                            <div className="pi-infoBoxTextIn" style={{ width: '100%', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', clipPath: 'polygon(7.5% 0px, 100% 0px, 100% 68%, 93.3% 100%, 0px 100%, 0px 31%)' }} >FEDERATION WORKLOAD</div>
                                                        </label>
                                                    </div>
                                                </div> */}

                                                </div>
                                                <div style={{ display: 'flex', gap: '10px' }}>
                                                <ConfigProvider theme={{
                    token: {

                        colorInfo: 'red',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#001D5F',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '001D5F',
                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                        colorTextQuaternary: '#007bff',//สีตัวอักษร

                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                        colorTextBase: '#007bff', // สี scroll bar

                        colorPrimaryBg: '#0047FF',// สีตอนเลือก
                        /*       
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }} >                            <Tooltip title="Federation Workload">
                                                    <div className='elementFrame'
                                                        onClick={() => {
                                                            if (!federationWorkloadIsOpen) {
                                                                toggleFederationWorkloadIsOpen();
                                                            }
                                                        }}
                                                    >
                                                        <img src={fed_workload} />
                                                    </div>
                                                    </Tooltip>
                                                    <Tooltip title="Timesheet Month Report">
                                                    <div className='elementFrame'
                                                        onClick={() => {
                                                            if (!federationTimeSheetMonthReportIsOpen) {
                                                                toggleFederationTimeSheetMonthReportIsOpen();
                                                            }

                                                        }}
                                                    >
                                                        <img src={fed_time_sheet_month_report} />
                                                    </div>
                                                    </Tooltip>
                                                    <Tooltip title="Follow Up">
                                                    <div className='elementFrame'
                                                        onClick={() => {
                                                            if (!federationFollowUpIsOpen) {
                                                                toggleFederationFollowUpIsOpen();
                                                            }

                                                        }}
                                                    >
                                                        <img width='40px' height='40px' src={fed_follow} />
                                                    </div>
                                                    </Tooltip>
                                                    <Tooltip title="Summary Effort Use Report">
                                                    <div className='elementFrame'
                                                        onClick={() => {
                                                            if (!federationSummaryEffortIsOpen) {
                                                                toggleFederationSummaryEffortIsOpen();
                                                            }

                                                        }}
                                                    >
                                                        <img width='40px' height='40px' src={fed_summary_effort} />
                                                    </div>
                                                    </Tooltip>
                                                    <Tooltip title="Summary Timesheet Month Report">
                                                    <div className='elementFrame'
                                                        onClick={() => {
                                                            if (!federationSummaryTimeSheetMonthIsOpen) {
                                                                toggleFederationSummaryTimeSheetMonthIsOpen();
                                                            }

                                                        }}
                                                    >
                                                        <img width='40px' height='40px' src={fed_summary_timesheet} />
                                                    </div>
                                                   </Tooltip>
                                                   </ConfigProvider>
                                                </div>
                                                <br />
                                                <div className='row fedMgtInput' style={{ gap: '15px 0' }}>
                                                    <div className='col-6'>
                                                        <div>FEDERATION NAME</div>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                            <div className="pi-infoBoxTextCen" style={{ width: '100%', backgroundColor: '#0047FF' }}>
                                                                <input className="pi-infoBoxTextIn" style={{ width: '100%' }}
                                                                    type='text'
                                                                    value={federationDetail.fed_name}
                                                                    onChange={(e) => { setFederationDetail({ ...federationDetail, fed_name: e.target.value }) }}
                                                                >
                                                                </input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-6'>
                                                        <div>ORGANIZATION ID</div>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                            <div className="pi-infoBoxTextCen" style={{ width: '100%', backgroundColor: '#0047FF' }}>
                                                                <input className="pi-infoBoxTextIn" style={{ width: '100%' }}
                                                                    type='text'
                                                                    value={federationDetail.organize_id}
                                                                    onChange={(e) => { setFederationDetail({ ...federationDetail, organize_id: e.target.value }) }}
                                                                >
                                                                </input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-6'>
                                                        <div>BUSINESS TYPE</div>

                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>

                                                            <div className="pi-infoBoxTextCen" style={{ width: '100%', backgroundColor: '#0047FF' }}>
                                                                <input className="pi-infoBoxTextIn" style={{ width: '100%' }}
                                                                    type='text' >
                                                                </input>
                                                            </div>
                                                        </div>
                                                        {isOpen && (

                                                            <Select
                                                                options={roleOption}
                                                                className='selectNewSpace'
                                                                value={roleOption.filter((e) => { return e.value === federationDetail.nature_business })}
                                                                onChange={(e) => {
                                                                    setFederationDetail({ ...federationDetail, nature_business: e.value })
                                                                }}
                                                            />

                                                        )}

                                                    </div>

                                                    <div className='col-6'>
                                                        <div>EMAIL</div>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                            <div className="pi-infoBoxTextCen" style={{ width: '100%', backgroundColor: '#0047FF' }}>
                                                                <input className="pi-infoBoxTextIn" style={{ width: '100%' }}
                                                                    type='text'
                                                                    value={federationDetail.fed_email}
                                                                    onChange={(e) => { setFederationDetail({ ...federationDetail, fed_email: e.target.value }) }}
                                                                >
                                                                </input>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-6'>
                                                        <div>PHONE NUMBER</div>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                            <div className="pi-infoBoxTextCen" style={{ width: '100%', backgroundColor: '#0047FF' }}>

                                                                <PatternFormat
                                                                    type="text"
                                                                    format={"###-###-####"}
                                                                    required="false"
                                                                    className="pi-infoBoxTextIn" style={{ width: '100%' }}
                                                                    value={federationDetail.fed_tel}
                                                                    onChange={(e) => {
                                                                        setFederationDetail({ ...federationDetail, fed_tel: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div>FEDERATION DESCRIPTION</div>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                            <div className="pi-infoBoxTextShadow-3" style={{ width: '100%', textAlign: '-webkit-center', clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 73%, 98.3% 100%, 0px 100%, 0px 25%)', }}>
                                                                <TextArea
                                                                    className="pi-infoBoxTextIn"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '95px',
                                                                        borderRadius: '0px',
                                                                        boxShadow: 'none',
                                                                        backgroundImage: 'linear-gradient(87deg, #021E6F -7.97%, #222E51 81.68%)',
                                                                        fontSize: '17px',
                                                                        fontWeight: '700',
                                                                        padding: '8px 20px',
                                                                        clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 73%, 98.3% 100%, 0px 100%, 0px 25%)',
                                                                    }}
                                                                    autoSize={{
                                                                        minRows: 1
                                                                    }}
                                                                    type='text'
                                                                    value={federationDetail.fed_detail}
                                                                    // disabled={userismode}
                                                                    onChange={(e) => {
                                                                        //autoResize(textAreaId.textAreaSco)
                                                                        setFederationDetail({ ...federationDetail, fed_detail: e.target.value })
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div>FEDERATION ADDRESS</div>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                            <div className="pi-infoBoxTextShadow-3" style={{ width: '100%', textAlign: '-webkit-center', clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 73%, 98.3% 100%, 0px 100%, 0px 25%)' }}>
                                                                <TextArea
                                                                    className="pi-infoBoxTextIn"
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '95px',
                                                                        borderRadius: '0px',
                                                                        boxShadow: 'none',
                                                                        backgroundImage: 'linear-gradient(87deg, #021E6F -7.97%, #222E51 81.68%)',
                                                                        fontSize: '17px',
                                                                        fontWeight: '700',
                                                                        padding: '8px 20px',
                                                                        clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 73%, 98.3% 100%, 0px 100%, 0px 25%)',
                                                                    }}
                                                                    autoSize={{
                                                                        minRows: 1
                                                                    }}
                                                                    type='text'
                                                                    value={federationDetail.fed_address}
                                                                    onChange={(e) => {
                                                                        setFederationDetail({ ...federationDetail, fed_address: e.target.value });
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <div>FEDERATION COLOR</div>
                                                        <div className='fedMgtColorSet'>
                                                            {fedColor.map((e) => {
                                                                return (<>
                                                                    <div style={{ width: '60px', height: '60px', cursor: 'pointer' }} onClick={() => {
                                                                        setFederationDetail({ ...federationDetail, fed_color: e.color })
                                                                    }}>
                                                                        <div
                                                                            className={`colorProcessBoxClick1 ${federationDetail.fed_color === e.color ? 'active' : ''}`}
                                                                            style={{
                                                                                width: '100%',
                                                                                filter: federationDetail.fed_color === e.color ? e.filter : ''
                                                                            }}
                                                                        >

                                                                            <div className='colorProcessBoxClick1Box' style={{ border: `1px solid ${federationDetail.fed_color == e.color ? e.color : 'white'}` }}>
                                                                                <div className='colorProcessBoxClick1BoxInMain' style={{ backgroundColor: `${e.color}` }}></div>
                                                                                <div className='colorProcessBoxClick1BoxInSec' style={{ backgroundColor: `${e.color}` }}></div>
                                                                            </div>
                                                                            <svg height="100%" width="100%" style={{ position: 'relative', top: '-100%' }}>
                                                                                <line x1="0%" y1="15%" x2="15%" y2="0%" style={{ stroke: `${federationDetail.fed_color == e.color ? e.color : 'white'}`, strokeWidth: '1px' }} />
                                                                                <line x1="80%" y1="100%" x2="100%" y2="80%" style={{ stroke: `${federationDetail.fed_color == e.color ? e.color : 'white'}`, strokeWidth: '1px' }} />
                                                                                <line x1="20%" y1="1%" x2="100%" y2="1%" style={{ stroke: `${federationDetail.fed_color == e.color ? e.color : 'white'}`, strokeWidth: '7px', clipPath: 'polygon(0 0, 100% 0, 100% 100%, 30% 100%)' }} />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </>)
                                                            })}
                                                        </div>
                                                    </div>

                                                    <div className='col-12' style={{ margin: 0, padding: 0 }}>
                                                        <div className='row' style={{ margin: 0, padding: 0, gap: '10px 0' }}>
                                                            <div className='col-6'>
                                                                SPACESHIP
                                                            </div>
                                                            <div className='col-6' style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                                                <div>COUNT</div>
                                                                <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                                    <div className="pi-infoBoxTextShadow-3" style={{ textAlign: '-webkit-center', width: '60px', clipPath: ' polygon(15% 0px, 100% 0px, 100% 67%, 85% 100%, 0px 100%, 0px 35%)', padding: '0' }}>
                                                                        <input
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', boxShadow: 'none', backgroundImage: 'linear-gradient(87deg, #021E6F -7.97%, #222E51 81.68%)', textAlign: 'center', padding: '0 15px', clipPath: 'polygon(20% 0px, 100% 0px, 100% 55%, 80% 100%, 0px 100%, 0px 45%)' }}
                                                                            disabled={true}
                                                                            value={spaceshipSelectList.length || 0}
                                                                        >
                                                                        </input>
                                                                    </div>
                                                                </div>
                                                                {/* <div style={{ border: '1px solid red', width: 'fit-content', padding: '0 15px' }}>ADD SPACESHIP</div> */}
                                                            </div>
                                                            <div className='col-12' style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                                {spaceshipSelectList.map((e, index) => {
                                                                    console.log('SSS', e.spaceship_cover_file, index)
                                                                    return (<>
                                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                                            <div className="pi-infoBoxTextCen" style={{ width: '100%', height: '110px', backgroundColor: '#0047FF', clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 90%, 98.3% 100%, 0px 100%, 0px 11%)' }}>
                                                                                <div className="row pi-infoBoxTextIn" style={{ width: '100%', height: '106px', margin: 0, padding: '8px 0px', clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 90%, 98.3% 100%, 0px 100%, 0px 11%)' }}>
                                                                                    <div className='col-3' style={{ filter: 'drop-shadow(0px 0px 4px #0047FF)' }}>
                                                                                        <div className='col-12' style={{ backgroundColor: '#0047ff', clipPath: ' polygon(8.5% 0px, 100% 0px, 100% 84%, 92.3% 100%, 0px 100%, 0px 16%)', padding: '2px', width: '100%', height: '100%' }}>
                                                                                            <div className='col-12 fedMgtSpaceshipListImg' style={{
                                                                                                backgroundImage: `url(${e.image_profile ? e.image_profile : fedImgDefault})`,
                                                                                                width: '100%',
                                                                                                height: '100%',
                                                                                                clipPath: 'polygon(8.5% 0px, 100% 0px, 100% 84%, 92.3% 100%, 0px 100%, 0px 16%)'
                                                                                            }}>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-4' style={{ placeSelf: 'center' }}>{e.spaceship_name}</div>
                                                                                    <div className='col-5' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                                                                        <div className='clo-12'>
                                                                                            <div className='row p-0'>
                                                                                                <div className={e.spaceship_cover_filename ? 'col-8' : 'col-4 p-0'} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                                    <a
                                                                                                        href={e.spaceship_cover_file}
                                                                                                        download={e.spaceship_cover_filename}
                                                                                                        style={ellipsisStyle}
                                                                                                    >
                                                                                                        {e.spaceship_cover_filename}
                                                                                                    </a>

                                                                                                </div>
                                                                                                <div className='col-6' style={{ display: e.spaceship_cover_filename ? "none" : "flex", justifyContent: "center", alignItems: "center" }}>
                                                                                                    <input
                                                                                                        ref={uploadRef}
                                                                                                        id={`upload-header-btn_${index}`}
                                                                                                        hidden
                                                                                                        type="file"
                                                                                                        accept=".jpg,.jpeg,.png,.svg"
                                                                                                        onChange={(value) => {
                                                                                                            let temp = [...spaceshipSelectList];
                                                                                                            const _findIndex = temp.findIndex((el) => el.spaceship_id === e.spaceship_id);

                                                                                                            if (uploadRef.current) {
                                                                                                                let filesArray = Array.from(uploadRef.current.files);
                                                                                                                filesArray.splice(0, filesArray.length);
                                                                                                            };

                                                                                                            const newFileName = value.target.files[0].name;
                                                                                                            const fileExtension = newFileName.split('.').pop().toLowerCase();
                                                                                                            const allowedExtensions = ['jpg', 'jpeg', 'png', 'svg'];
                                                                                                            // console.log('fileExtension', fileExtension)

                                                                                                            if (!allowedExtensions.includes(fileExtension)) {
                                                                                                                setText_showerror('Please select a file with the extension .jpg, .jpeg, .png, or .svg');

                                                                                                                toggle_modal_alertKick_image();
                                                                                                                value.target.value = null;
                                                                                                                return;
                                                                                                            }

                                                                                                            const isDuplicate = spaceshipSelectList.some((item, idx) => item.image_name === newFileName && idx !== index);
                                                                                                            if (isDuplicate) {
                                                                                                                setText_showerror('Do not upload duplicate image!');

                                                                                                                toggle_modal_alertKick_image();
                                                                                                                value.target.value = null;
                                                                                                                return;
                                                                                                            };


                                                                                                            if (value.target.files.length > 0) {

                                                                                                                const regex = /([\u0E00-\u0E7F]+)/gmu;
                                                                                                                const str = value.target.files[0].name;
                                                                                                                const m = regex.exec(str);

                                                                                                                // console.log('e', URL.createObjectURL(e.target.files[0]) , e.target.files[0].name ,e.target.files[0])
                                                                                                                let indexID = temp[index].file_id_list.findIndex((e2) => e2 == temp[index].spaceship_cover_file_id);
                                                                                                                if (indexID > -1) {
                                                                                                                    temp[index].file_id_list.splice(indexID, 1);
                                                                                                                }
                                                                                                                temp[_findIndex].image_path = URL.createObjectURL(value.target.files[0]);
                                                                                                                temp[_findIndex].image_name = value.target.files[0].name;
                                                                                                                temp[_findIndex].image_file = value.target.files[0];

                                                                                                                temp[_findIndex].is_chacge = true;
                                                                                                                temp[_findIndex].is_new = true;

                                                                                                                temp[_findIndex].spaceship_cover_filename = value.target.files[0].name;
                                                                                                                temp[_findIndex].spaceship_cover_file_id = '';
                                                                                                                temp[_findIndex].spaceship_cover_file = URL.createObjectURL(value.target.files[0]);
                                                                                                                temp[_findIndex].is_active = false;
                                                                                                                // console.log('temp', temp)


                                                                                                                setSpaceshipSelectList(temp);

                                                                                                                value.target.value = null;

                                                                                                            };

                                                                                                        }}
                                                                                                    />
                                                                                                    <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)', width: '100%' }}>
                                                                                                        <label for={`upload-header-btn_${index}`} type="button" className="pi-infoBoxTextCen" style={{ width: '100%', cursor: 'pointer', backgroundColor: '#0047FF', clipPath: 'polygon(5.5% 0px, 100% 0px, 100% 81%, 95.3% 100%, 0px 100%, 0px 23%)' }}>
                                                                                                            <div className="pi-infoBoxTextIn" style={{ width: '100%', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', clipPath: 'polygon(5.5% 0px, 100% 0px, 100% 81%, 95.3% 100%, 0px 100%, 0px 23%)' }} >
                                                                                                                + UPLOAD IMAGE
                                                                                                            </div>
                                                                                                        </label>
                                                                                                    </div>

                                                                                                </div>
                                                                                                <div className='col-1 pl-0' style={{ display: e.spaceship_cover_filename === '' || !e.spaceship_cover_filename ? "none" : "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                                                    <div className='mr-3'>
                                                                                                        <Checkbox
                                                                                                            value={e.is_active}
                                                                                                            checked={e.is_active}
                                                                                                            onChange={(e) => {
                                                                                                                let temp = [...spaceshipSelectList];
                                                                                                                temp[index].is_active = e.target.checked;
                                                                                                                // console.log('temp', temp)
                                                                                                                setSpaceshipSelectList(temp);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                    <div className="btn-closePersonalInfo-New" style={{ textAlign: 'center', }}
                                                                                                        onClick={async () => {
                                                                                                            DeleteUpload(index)
                                                                                                        }}
                                                                                                    >
                                                                                                        <i class="fa fa-trash detailIcon" aria-hidden="true" style={{ fontSize: "20px",/*  marginTop: '10px'  */ }}></i>
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/* <div className='col-1' style={{display:"flex", justifyContent: "center",alignItems: "center"}}>
                                                                                        <div className="btn-closePersonalInfo-New" style={{textAlign: 'center', }}
                                                                                            onClick={async () => {
                                                                                                DeleteUpload()
                                                                                            }}
                                                                                        > 
                                                                                            <i class="fa fa-trash detailIcon" aria-hidden="true" style={{ fontSize: "20px"}}></i>
                                                                                        </div>
                                                                                    </div> */}
                                                                                            </div>
                                                                                        </div>


                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>)
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className='col-12'>
                                                        <input
                                                            ref={uploadRef_fed}
                                                            id="upload-header-btn"
                                                            hidden type="file"
                                                            accept="image/*"
                                                            onChange={(e) => {
                                                                if (uploadRef_fed.current) {
                                                                    let filesArray = Array.from(uploadRef_fed.current.files);
                                                                    filesArray.splice(0, filesArray.length);
                                                                };

                                                                if (e.target.files.length > 0) {
                                                                    const regex = /([\u0E00-\u0E7F]+)/gmu;
                                                                    const str = e.target.files[0].name;
                                                                    const m = regex.exec(str);
                                                                    let temp = fileUploadList || [];
                                                                    let index = temp.findIndex((i) => i == allFileDataDetail.image_cover_id);
                                                                    if (index > -1) {
                                                                        temp.splice(index, 1)
                                                                        setFileUploadList(temp);
                                                                    }

                                                                    setAllFileDataDetail({ ...allFileDataDetail, image_cover: URL.createObjectURL(e.target.files[0]) });
                                                                    setFederationDetail({ ...federationDetail, image_header_path: URL.createObjectURL(e.target.files[0]), image_header_name: e.target.files[0].name, image_header_file: e.target.files[0], is_header_save: true })
                                                                    e.target.value = '';
                                                                }


                                                            }}
                                                        />
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)', width: '100%', display: "inline" }}>
                                                            <label for="upload-header-btn" type="button" className="mt-4 pi-infoBoxTextCen" style={{ width: '30%', cursor: 'pointer', backgroundColor: '#0047FF', clipPath: 'polygon(5.5% 0px, 100% 0px, 100% 81%, 95.3% 100%, 0px 100%, 0px 23%)' }}>
                                                                <div className="pi-infoBoxTextIn" style={{ width: '100%', fontSize: '14px', display: 'flex', alignItems: 'center', justifyContent: 'center', clipPath: 'polygon(5.5% 0px, 100% 0px, 100% 81%, 95.3% 100%, 0px 100%, 0px 23%)' }} >
                                                                    + UPLOAD HEADER IMAGE
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <span style={{ color: "red", fontSize: '13px', paddingLeft: "14px" }}>*HEADER SIZE REQUIRE 1036 x 120 px</span>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                            <div className="mt-2 pi-infoBoxTextCen" style={{ width: '100%', height: '100%', backgroundColor: '#0047FF', clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 85%, 98.3% 100%, 0px 100%, 0px 11%)' }}>
                                                                <div className="pi-infoBoxTextIn" style={{ width: '100%', height: '96%', margin: 0, padding: 0, clipPath: 'polygon(1.5% 0px, 100% 0px, 100% 85%, 98.3% 100%, 0px 100%, 0px 11%)' }}>
                                                                    <img
                                                                        src={`${allFileDataDetail.image_cover ? allFileDataDetail.image_cover : fedHeaderImgDefault
                                                                            }`}
                                                                        className='fedMgtHeaderImg'
                                                                    ></img>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>
                                            {/* {console.log('federationDetail', federationDetail)} */}
                                            <div className='col-4' style={{ margin: 0, padding: 0 }}>
                                                <div className='row' style={{ margin: 0, padding: 0 }} >
                                                    <div className='col-12'>
                                                        <img src={`${allFileDataDetail.image_profile ? allFileDataDetail.image_profile : fedImgDefault
                                                            }`} className='fedMgtImg'></img>

                                                    </div>
                                                    <div className='mt-2 col-12' style={{ display: 'flex', gap: '5px' }}>

                                                        <input id="upload-btn-main" hidden type="file"
                                                            accept="image/*"
                                                            onChange={(e) => {
                                                                console.log('CheckIMG', e.target);
                                                                if (e.target.files.length > 0) {
                                                                    const regex = /([\u0E00-\u0E7F]+)/gmu;
                                                                    const str = e.target.files[0].name;
                                                                    const m = regex.exec(str);
                                                                    let temp = fileUploadList || [];
                                                                    let index = temp.findIndex((i) => i == allFileDataDetail.image_profile_id);
                                                                    if (index > -1) {
                                                                        temp.splice(index, 1)
                                                                        setFileUploadList(temp);
                                                                    }

                                                                    setAllFileDataDetail({ ...allFileDataDetail, image_profile: URL.createObjectURL(e.target.files[0]) });
                                                                    setFederationDetail({ ...federationDetail, image_path: URL.createObjectURL(e.target.files[0]), image_name: e.target.files[0].name, image_file: e.target.files[0], is_save: true })
                                                                }


                                                            }}
                                                        />
                                                        <div style={{ filter: 'drop-shadow(0px 0px 2px #676767)', width: '100%' }}>
                                                            <label for="upload-btn-main" type="button" className="pi-infoBoxTextCen" style={{ width: '100%', cursor: 'pointer', backgroundColor: '#0047FF', clipPath: 'polygon(7.5% 0px, 100% 0px, 100% 68%, 93.3% 100%, 0px 100%, 0px 31%)' }}>
                                                                <div className="pi-infoBoxTextIn" style={{ width: '100%', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', clipPath: 'polygon(7.5% 0px, 100% 0px, 100% 68%, 93.3% 100%, 0px 100%, 0px 31%)' }} >SEND PICTURE</div>
                                                            </label>
                                                        </div>
                                                        <div style={{ filter: 'drop-shadow(0px 0px 2px #676767)', width: '100%' }}>
                                                            <label type="button" className="pi-infoBoxTextCen" style={{ width: '100%', cursor: 'no-drop', backgroundColor: '#0047FF', clipPath: 'polygon(7.5% 0px, 100% 0px, 100% 68%, 93.3% 100%, 0px 100%, 0px 31%)' }}>
                                                                <div className="pi-infoBoxTextIn" style={{ width: '100%', fontSize: '13px', display: 'flex', alignItems: 'center', justifyContent: 'center', clipPath: 'polygon(7.5% 0px, 100% 0px, 100% 68%, 93.3% 100%, 0px 100%, 0px 31%)' }} >AI GENERATE HISTORY</div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='mt-4 col-12'>
                                                    <div>FEDERATION OWNER</div>
                                                    <div className='col-12' style={{ display: 'flex', flexDirection: 'column', gap: '10px', margin: 0, padding: 0 }}>
                                                        {userSelectList.map((e) => {
                                                            return (<>
                                                                <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)', width: '100%' }}>
                                                                    <div className="pi-infoBoxTextCen" style={{ width: '100%', height: '110px', backgroundColor: '#0047FF', clipPath: 'polygon(3.5% 0px, 100% 0px, 100% 90%, 96.3% 100%, 0px 100%, 0px 11%)' }}>
                                                                        <div className="row pi-infoBoxTextIn" style={{ width: '100%', height: '106px', margin: 0, padding: '8px 0px', clipPath: 'polygon(3.5% 0px, 100% 0px, 100% 90%, 96.3% 100%, 0px 100%, 0px 11%)' }}>
                                                                            <div className='col-3' style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                                                <div className='col-12' style={{ backgroundColor: '#0047ff', clipPath: 'polygon(22.5% 0px, 100% 0px, 100% 86%, 85.3% 100%, 0px 100%, 0px 13%)', padding: '2px', width: '100%', height: '100%' }}>
                                                                                    <div className='col-12 fedMgtSpaceshipListImg'
                                                                                        style={{
                                                                                            backgroundImage: `url(${e.image_profile ? e.image_profile : fedImgDefault})`,
                                                                                            // border: '2px solid #0047FF',
                                                                                            clipPath: 'polygon(22.5% 0px, 100% 0px, 100% 86%, 85.3% 100%, 0px 100%, 0px 13%)',
                                                                                            width: '100%',
                                                                                            height: '100%',
                                                                                        }}></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-9' style={{ placeSelf: 'center' }}>{e.usr_name + ' ' + e.usr_lastname}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>)
                                                        })}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='mt-4 col-12'>
                                                <div className='fedMgtAcm'>
                                                    ACHIEVEMENT
                                                </div>
                                                <div className='mt-2 fedMgtAcmList'>
                                                    CONTENT OF TAB ACHIEVEMENT
                                                </div>
                                            </div>

                                            <div className='mt-4 col-12' style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                                <div className='fedMgtButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                    toggle_modal_alertKick();
                                                }}
                                                >
                                                    SAVE
                                                </div>
                                                <div
                                                    className='fedMgtButton'
                                                    style={{ backgroundColor: '#FF0000' }}
                                                    onClick={() => {
                                                        if (uploadRef.current) {
                                                            let filesArray = Array.from(uploadRef.current.files);
                                                            filesArray.splice(0, filesArray.length);
                                                        };

                                                        if (uploadRef_fed.current) {
                                                            let filesArray = Array.from(uploadRef_fed.current.files);
                                                            filesArray.splice(0, filesArray.length);
                                                        };

                                                        setFederationDetail({
                                                            ...federationDetail,
                                                            size: '',
                                                            fed_name: '',
                                                            organize_id: '',
                                                            nature_business: '',
                                                            fed_email: '',
                                                            fed_tel: '',
                                                            fed_detail: '',
                                                            fed_address: '',
                                                            fed_color: '#DEDEDE',
                                                            image_name: '',
                                                            image_path: '',
                                                            image_file: '',
                                                            is_save: false,
                                                            image_header_name: '',
                                                            image_header_path: '',
                                                            image_header_file: '',
                                                            is_header_save: false,
                                                            federation_owner_id: ''
                                                        });
                                                        federationDetail.size = '';
                                                        federationDetail.fed_name = '';
                                                        federationDetail.organize_id = '';
                                                        federationDetail.nature_business = '';
                                                        federationDetail.fed_email = '';
                                                        federationDetail.fed_tel = '';
                                                        federationDetail.fed_detail = '';
                                                        federationDetail.fed_address = '';
                                                        federationDetail.fed_color = '#DEDEDE';
                                                        federationDetail.image_name = '';
                                                        federationDetail.image_path = '';
                                                        federationDetail.image_file = '';
                                                        federationDetail.is_save = false;
                                                        federationDetail.image_header_name = '';
                                                        federationDetail.image_header_path = '';
                                                        federationDetail.image_header_file = '';
                                                        federationDetail.is_header_save = false;
                                                        federationDetail.federation_owner_id = '';
                                                        socketRef.current.emit("locationElement",{room: 'locationElement' + sessionStorage.getItem('user_id'),message:'StageMonitoring'});
                                                        toggle_modal_fedMgtConfig();

                                                        // setFederationDetail({
                                                        //     ...federationDetail,
                                                        //     size: '',
                                                        //     fed_name: '',
                                                        //     organize_id: '',
                                                        //     nature_business: '',
                                                        //     fed_email: '',
                                                        //     fed_tel: '',
                                                        //     fed_detail: '',
                                                        //     fed_address: '',
                                                        //     fed_color: '#DEDEDE',
                                                        //     image_name: '',
                                                        //     image_path: '',
                                                        //     image_file: '',
                                                        //     is_save: false,
                                                        //     image_header_name: '',
                                                        //     image_header_path: '',
                                                        //     image_header_file: '',
                                                        //     is_header_save: false,
                                                        //     federation_owner_id: ''

                                                        // })
                                                    }}
                                                >
                                                    CANCEL
                                                </div>
                                            </div>

                                        </div>)
                                    },
                                    {
                                        key: 'ORGANIZER',
                                        label: 'FEDERATION ORGANIZER',
                                        children: (
                                            <div className='row fedMgtBody mt-3'>
                                                <div className='row col-12'>
                                                    <div className='col-6'><span> ชั่วโมงการทำงาน (Work day)</span></div>
                                                    <div className='col-6' style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }} >
                                                        <div className='fedMgtButton' style={{ backgroundColor: '#0047FF', width: '100px', textAlign: 'center' }} onClick={() => {
                                                            AddWorkDay();
                                                        }}
                                                        >
                                                            + Add
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='row col-12 mt-3' style={{ width: '100%'/* ,minHeight:'150vh' */, padding: '0', margin: '0' }}>
                                                    {/*  <div className='' style={{display:'flex',gap:'1%',width:'100%'}}>  */}
                                                    <div className='row' style={{ width: '100%', textAlign: 'center', padding: '0', margin: '0' /* ,position:'sticky', top:'0' */ }}>
                                                        <div className='col-2' style={{ border: '1px solid blue', width: '100%', height: '33px', position: 'sticky', background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)', borderRadius: '1px' }} > Date </div>
                                                        <div className='col-2' style={{ border: '1px solid blue', width: '100%', height: '33px', position: 'sticky', background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)', borderRadius: '1px' }} > Start Time </div>
                                                        <div className='col-2' style={{ border: '1px solid blue', width: '100%', height: '33px', position: 'sticky', background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)', borderRadius: '1px' }} > End Time </div>
                                                        <div className='col-2' style={{ border: '1px solid blue', width: '100%', height: '33px', position: 'sticky', background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)', borderRadius: '1px' }} > man-hour </div>
                                                        <div className='col-2' style={{ border: '1px solid blue', width: '100%', height: '33px', position: 'sticky', background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)', borderRadius: '1px' }} > Description </div>
                                                        <div className='col-2' style={{ border: '1px solid blue', width: '100%', height: '33px', position: 'sticky', background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)', borderRadius: '1px' }} > Management </div>
                                                    </div>
                                                    <div className='row col-12' style={{ padding: '0', margin: '0'/* ,height:'450px' */ }}>
                                                        {workdayData && workdayData.map((item, index) => {

                                                            return (
                                                                <>
                                                                    <div className='row col-12 mt-3' style={{ zIndex: 9, padding: '0', margin: '0' }}>
                                                                        <div className='col-2' style={{ width: '100%', borderRadius: '15px' }}>

                                                                            <StyledDatePicker
                                                                                className="dateText2"
                                                                                value={item.date ? dayjs(item.date) : null}
                                                                                placeholder="วว/ดด/ปปปป"
                                                                                format={"DD/MM/YYYY"}
                                                                                onChange={(date, dateString) => {
                                                                                    const dateValue = date ? date.$d : null;
                                                                                    setValueInArrayWorkdays(dateValue, 'date', index);
                                                                                }}
                                                                            />


                                                                        </div>
                                                                        <div className='col-2' style={{ width: '100%', textAlign: 'center' }} >

                                                                            <StyledTimePicker
                                                                                className="dateText2"
                                                                                style={{ color: '#FFF' }}
                                                                                value={item.start_time}
                                                                                format="HH:mm"
                                                                                hideNow={true}
                                                                                showSecond={false}
                                                                                defaultValue={dayjs('00:00', 'HH:mm')}
                                                                                disabledHours={() => disabledStartHours(index)}
                                                                                /* disabledMinutes={(selectedHour) => disabledStartMinutes(index, selectedHour)} */
                                                                                onChange={(timeValue) => {
                                                                                    const formattedTime = timeValue ? timeValue.format('HH:mm') : null;
                                                                                    setValueInArrayWorkdays(formattedTime, 'start_time', index)
                                                                                }}

                                                                            />

                                                                        </div>
                                                                        <div className='col-2' style={{ width: '100%', textAlign: 'center' }} >

                                                                            <StyledTimePicker
                                                                                className="dateText2"
                                                                                style={{ color: '#FFF' }}
                                                                                value={item.end_time}
                                                                                format="HH:mm"
                                                                                showSecond={false}
                                                                                defaultValue={dayjs('00:00', 'HH:mm')}
                                                                                hideNow={true}
                                                                                onChange={(timeValue) => {
                                                                                    const formattedTime = timeValue ? timeValue.format('HH:mm') : null;
                                                                                    setValueInArrayWorkdays(formattedTime, 'end_time', index)
                                                                                }}
                                                                                disabledHours={() => disabledEndHours(index)}
                                                                                disabledMinutes={(selectedHour) => disabledEndMinutes(index, selectedHour)}

                                                                            />

                                                                        </div>
                                                                        <div className='col-2' style={{ width: '100%', textAlign: 'center' }} >
                                                                            <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>

                                                                            </div>
                                                                            <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                                                <TextArea
                                                                                    className="dateText2"
                                                                                    style={{ borderRadius: '0px', cursor: 'no-drop', height: '36px', minHeight: '36 !important' }}
                                                                                    autoSize={{
                                                                                        minRows: 1.2
                                                                                    }}
                                                                                    type='text'
                                                                                    value={item.man_hour}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-2' style={{ width: '100%', textAlign: 'center' }} >
                                                                            <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                                                <TextArea
                                                                                    className="areaTEst rezieznone"
                                                                                    style={{ borderRadius: '0px' }}
                                                                                    autoSize={{
                                                                                        minRows: 1.2
                                                                                    }}
                                                                                    type='text'
                                                                                    value={item.description}
                                                                                    onChange={(e) => {

                                                                                        setValueInArrayWorkdays(e.target.value, 'description', index)
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className='col-2' style={{ width: '100%', textAlign: 'center', display: 'flex', justifyContent: 'center' }} >
                                                                            <div className="btn-closePersonalInfo-New" style={{ textAlign: 'center', position: 'relative', top: '0', left: '0' }}
                                                                                onClick={async () => {
                                                                                    DelWorkDay(index)
                                                                                }}
                                                                            >
                                                                                <i class="fa fa-trash detailIcon" aria-hidden="true" style={{ fontSize: "20px",/*  marginTop: '10px'  */ }}></i>
                                                                            </div>
                                                                        </div>

                                                                    </div>

                                                                </>


                                                            )
                                                        })}
                                                    </div>
                                                    <div className='row col-12 mt-4' style={{ padding: '0', margin: '0', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                                                        <div className='fedMgtButton' style={{ backgroundColor: '#0047FF' }} onClick={() => {
                                                            toggle_modal_alertKick();
                                                        }}
                                                        >
                                                            SAVE
                                                        </div>

                                                    </div>
                                                    {/*    <table style={{ border: '1px solid red', width: '100%', gap: '10px',gap:'12px',overflow:'auto' }}>
                                                <tr style={{ border: '1px solid blue', width: '100%', height: '33px',position:'sticky' , background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)', borderRadius: '15px' }}>
                                                    <th style={{ background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)',zIndex:'390', width: '50px', textAlign: 'center' }}>Date</th>
                                                    <th style={{ background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)',zIndex:'390', width: '50px', textAlign: 'center' }}>Start Time</th>
                                                    <th style={{ background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)',zIndex:'390', width: '50px', textAlign: 'center' }}>End Time</th>
                                                    <th style={{ background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)',zIndex:'390', width: '50px', textAlign: 'center' }}>man-hour</th>
                                                    <th style={{ background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)',zIndex:'390', width: '140px', textAlign: 'center' }}>Description</th>
                                                    <th style={{ background: 'linear-gradient(to left, #0029a1 40%, #007db3 20%, #0029a1 10%)',zIndex:'390', width: '50px', textAlign: 'center' }}>Management</th>
                                                </tr>
                                                {workdayData && workdayData.map((item, index) => {

                                                    return (
                                                        <tr className='' style={{ width: '100%',borderRadius: '15px' }}>
                                                            <td style={{ width: '50px', textAlign: 'center' }} >
                                                                <StyledDatePicker
                                                                    className="dateText4"
                                                                    value={item.date ? dayjs(item.date) : null}
                                                                    placeholder="วว/ดด/ปปปป"
                                                                    format={"DD/MM/YYYY"}
                                                                  onChange={(date, dateString) => {
                                                                      onChangeSchedule('ps_start', date ? date.$d : null, index_4)
                                                                  }}
                                                                  disabledDate={(endValue) => {
                                                                      if (!item.ps_finish) {
                                                                          return false;
                                                                      }
                                                                      return endValue > (item.ps_finish);
                                                                  }}
                                                                /></td>
                                                            <td style={{ width: '50px', textAlign: 'center' }} >


                                                            </td>
                                                            <td style={{ width: '50px', textAlign: 'center' }} >Alfreds Futterkiste</td>
                                                            <td style={{ width: '50px', textAlign: 'center' }} >Alfreds Futterkiste</td>
                                                            <td style={{ width: '140px', textAlign: 'center' }} >Alfreds Futterkiste</td>
                                                            <td style={{ width: '50px', textAlign: 'center' }} >Alfreds Futterkiste</td>
                                                        </tr>
                                                    )
                                                })}


                                            </table> */}

                                                    {/*  </div> */}


                                                </div>
                                                {/*  <div className='col-6'>
                                    <div>ORGANIZATION ID</div>
                                                <div style={{ filter: 'drop-shadow(0px 0px 3px #676767)' }}>
                                                    <div className="pi-infoBoxTextCen" style={{ width: '100%', backgroundColor: '#0047FF' }}>
                                                        <input className="pi-infoBoxTextIn" style={{ width: '100%' }}
                                                            type='text'
                                                            value={federationDetail.organize_id}
                                                            onChange={(e) => { setFederationDetail({ ...federationDetail, organize_id: e.target.value }) }}
                                                        >
                                                        </input>
                                                    </div>
                                                </div>
                                    </div> */}

                                            </div>),
                                    }
                                ]
                            } />
                    </ConfigProvider>


                </div>
            </div >
            <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow'>
                    <div className='alertKickBox'>
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: 'orange' }}>
                                    ! warning !
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    WARNING
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    Do you want to save data?
                                </div>
                            </div>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickTextSec'>
                                    You won't be able to revert this!
                                </div>
                            </div>
                            <div className='row alertKickBoxButton' >
                                <button className='mr-2 alertKickConfirm' onClick={() => {
                                    toggle_modal_alertKick();
                                    CheckBefoSave();
                                }}>YES</button>

                                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                    toggle_modal_alertKick();
                                }}>NO</button>


                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='alertKick' style={{ display: `${alertKickSuccess ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow'>
                    <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
                        <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: `${errorMasgerList.isError ? 'red' : 'green'}` }}>
                                    {errorMasgerList.isError ? '! ERROR !' : 'SUCCESS'}
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    {errorMasgerList.isError ? 'WARNING' : 'SUCCESS'}
                                </div>
                            </div>

                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickTextSec'>
                                    {errorMasgerList.massger.map((e) => {

                                        return <div> {e.message} </div>

                                    })}
                                </div>
                            </div>
                            <div className='mt-4 row alertKickBoxButton'>
                                <button className='alertKickButton' onClick={() => {
                                    toggle_modal_alertKickSuccess();
                                    setErrorMasgerList({
                                        ...errorMasgerList,
                                        isError: false,
                                        massger: []
                                    });
                                    /* toggle_modal_fedMgtConfig(); */
                                }}>OK</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='alertKick' style={{ display: `${alertKick_imge ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow'>
                    <div className='alertKickBox'>
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: 'orange' }}>
                                    ! warning !
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    WARNING
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    {/* Do not upload duplicate image! */}
                                    {text_showerror}
                                </div>
                            </div>
                            <div className='row' style={{ height: '25%' }}>
                                {/* <div className='alertKickTextSec'>
                                    You won't be able to revert this!
                                </div> */}
                            </div>
                            <div className='row alertKickBoxButton' >
                                {/* <button className='mr-2 alertKickConfirm' onClick={() => {
                                    toggle_modal_alertKick();
                                }}>YES</button> */}

                                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                    toggle_modal_alertKick_image();
                                }}>NO</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>






            <div className='alertKick' style={{ display: `${alertKickLoading ? '' : 'none'}`, position: 'fixed', zIndex: 9999999999 }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' style={{ height: 'fit-content' }}>
                        <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: '#ffffff' }}>
                                    Loading
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning' style={{ border: 'none' }}>
                                    {/* {errorMessage.status ? <> */}<div class="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>{/* </> : <></>} */}
                                </div>
                            </div>
                            {/*       {errorMessage.status ? <></> : <>
                                <div className='row' style={{ height: '12.5%' }}>
                                    <div className='alertKickText'>
                                        {errorMessage.message}

                                    </div>
                                </div>


                                <div className='row alertKickBoxButton'>
                                    <button className='alertKickButton' onClick={() => {
                                        toggle_modal_alertKick()
                                    }}>OK</button>

                                </div>
                            </>} */}


                        </div>
                    </div>
                </div>
            </div>




            <FederationWorkload
                federationWorkloadIsOpen={federationWorkloadIsOpen}
                toggleFederationWorkloadIsOpen={toggleFederationWorkloadIsOpen}
                federation_id={id}
                onElement={onElement}
                closeElement={closeElement}
            />

            <FederationTimesheetMonth
                federationTimeSheetMonthReportIsOpen={federationTimeSheetMonthReportIsOpen}
                toggleFederationTimeSheetMonthReportIsOpen={toggleFederationTimeSheetMonthReportIsOpen}
                federation_id={id}
                federation_name={federationDetail.fed_name}
                onElement={onElement}
                closeElement={closeElement}
            />

            <FederationFollowUp
                federationFollowUpIsOpen={federationFollowUpIsOpen}
                toggleFederationFollowUpIsOpen={toggleFederationFollowUpIsOpen}
                federation_id={id}
                federation_name={federationDetail.fed_name}
                onElement={onElement}
                closeElement={closeElement}
            />

            <FederationSummaryEffort
                federationSummaryEffortIsOpen={federationSummaryEffortIsOpen}
                toggleFederationSummaryEffortIsOpen={toggleFederationSummaryEffortIsOpen}
                federation_id={id}
                federation_name={federationDetail.fed_name}
                onElement={onElement}
                closeElement={closeElement}
            />

            <FederationSummaryTimeSheetMonth
                federationSummaryTimeSheetMonthIsOpen={federationSummaryTimeSheetMonthIsOpen}
                toggleFederationSummaryTimeSheetMonthIsOpen={toggleFederationSummaryTimeSheetMonthIsOpen}
                federation_id={id}
                federation_name={federationDetail.fed_name}
                onElement={onElement}
                closeElement={closeElement}
            />






            
        </>
    )
}

export default FederationMgt;
