import React, { useEffect, useState, useRef } from 'react'
import { Input, Space, Tag, theme, Tooltip, ConfigProvider, DatePicker, Modal, Upload, Checkbox } from 'antd';

import axios from 'axios';
import moment from 'moment';
import dayjs from "dayjs";
import { ExclamationCircleFilled } from '@ant-design/icons';
import Configs from "../../../config";
import './modalSpaceShip.css'
import ModalConfirm from './ModalConfirm.jsx'
import { generateUUID } from 'three/src/math/MathUtils.js';
import { InboxOutlined } from '@ant-design/icons';

import docicon from '../../../assets/img/icon/doc icon.png';
import imageicon from '../../../assets/img/icon/image icon.png';
import pdficon from '../../../assets/img/icon/pdf icon.png';
import ppticon from '../../../assets/img/icon/ppt icon.png';
import xlsxicon from '../../../assets/img/icon/xlsx icon.png';
import svgicon from '../../../assets/img/icon/svg icon.png';
import uploadicon from '../../../assets/img/icon/upload.png';
import Maskin from '../Image/Mask_in.png';
import Maskout from '../Image/Mask_out.png';
import { logDOM } from '@testing-library/react';
import ModalSdd from './component/ModalSdd.jsx';
import featureicon from '../Image/featureicon.png';
import menuicon from '../Image/menuicon.png';

const { RangePicker } = DatePicker;
const { confirm } = Modal;
const { Dragger } = Upload;
const { TextArea } = Input;

function ModalConfigModule({
  isOpen,
  toggle_modal_config_module,
  project_id,
  getProjectDetail,
  ModuleDetails,
  displayMode = false,
  switModeShowDisplay

}) {
  const [fileUploadList, setFileUploadList] = useState([]);
  const [modalConfirm, setModalConfirm] = useState(false);
  const toggle_modal_confirm = () => setModalConfirm(!modalConfirm);
  const [modalConfirm2, setModalConfirm2] = useState(false);
  const toggle_modal_confirm2 = () => setModalConfirm2(!modalConfirm2);
  const [menuIndex, setMenuIndex] = useState(null);
  const [test, settest] = useState({
    test: ''
  })
  const [loading, setLoading] = useState(false);
  const [modelversion, setmodelversion] = useState(false);
  const toggle_modal_modelversion = () => setmodelversion(!modelversion);

  const [modalSdd, setModalSdd] = useState(false);
  const toggle_modalSdd = () => setModalSdd(!modalSdd)


  const [ModuleDetail, setModuleDetail] = useState({})

  const [MenutypeList, seMenutypeList] = useState([
  ])

  const [MenuchcekList, setMenuchcekList] = useState({
    dataselect: []
  })
  const [errorMessage, setErrorMessage] = useState('')

  function saveConfigModule() {

    console.log(ModuleDetail)
    let temp = ModuleDetail;


    temp.removeMenuList = removeMenuList || [];
    let countErr = 0;


    if (temp.module_name.trim() == '') {
      countErr++;
      setErrorMessage('Please Enter Your Module Name !!!')
    }




    for (var i = 0; i < temp.menuList.length; i++) {
      if (temp.menuList[i].errorMessage || temp.menuList[i].errorMessageDate || temp.menuList[i].errorMessagetype) {
        countErr++;
      }

      if (temp.menuList[i].menu_name.trim() == '') {
        countErr++;
        temp.menuList[i].errorMessage = 'Please Enter Your Menu Name !!!';
      }
    }

    setModuleDetail({ ...ModuleDetail, menuList: temp.menuList });
    ModuleDetail.menuList = temp.menuList;
    /* if(temp.module_name.trim() == ''){
      setErrorMessage('Please Enter Your Module Name !!!')
    }else */ if (countErr == 0) {
      //console.log("HERERE")
      axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/moduleMenu/saveConfigModule`,
        headers: {
          Authorization: sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
        },
        data: temp

      })
        .then(function (response) {
          if (response.data) {
            setErrorMessage('');
            setModuleDetail({});

            toggle_modal_config_module();
            getProjectDetail(project_id)
          }



        })
        .catch(function (error) {
          console.log(error);
          setErrorMessage(error.response.data.error[0].errorDis)

        });

    }
  }


  function sortMenu(data) {
    const map = new Map();

    data.forEach(item => {
      if (!map.has(item.menu_parent_id)) {
        map.set(item.menu_parent_id, []);
      }
      map.get(item.menu_parent_id).push(item);
    });

    function recursiveSort(parentId) {
      let items = map.get(parentId) || [];

      items.sort((a, b) => {
        let levelA = parseInt(a.menu_level);
        let levelB = parseInt(b.menu_level);
        let sortA = parseInt(a.menu_sort);
        let sortB = parseInt(b.menu_sort);

        if (levelA !== levelB) {
          return levelA - levelB;
        } else {
          return sortA - sortB;
        }
      });

      let result = [];
      items.forEach(item => {
        result.push(item);
        result = result.concat(recursiveSort(item.menu_id));
      });

      return result;
    }

    return recursiveSort(null);
  }


  async function GetAccesstokenToken(callback) {
    axios({
      method: "post",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
      headers: {
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
    })
      .then(async function (response) {

        /*   if (callback) {
              callback(response.data.tokenBox);
          } */
        sessionStorage.setItem('tokenBox', response.data.tokenBox)
      })
      .catch(function (error) {
        console.log(error);
      });

  }
  useEffect(() => {
  /*   console.log('CheckSWit',switModeShowDisplay); */
    setMenuchcekList({ ...MenuchcekList, dataselect: [] });
    setErrorMessage('');
    /*    setModuleDetail({...ModuleDetail,module_name:''});
       ModuleDetail.module_name= ''; */

    const temp = ModuleDetails || {}
    if (temp.menuList !== undefined) {
      let sortedData = sortMenu(temp.menuList);
      temp.menuList = sortedData
      for (let i of temp.menuList) {
        if (i.menu_level === null) {
          i.menu_level = 1
        } else {
          i.menu_level = parseInt(i.menu_level)
        }
        i.menu_sort = parseInt(i.menu_sort)

      }
      console.log('ModuleDetails', sortedData)
    }

    if (isOpen) {
      GetAccesstokenToken()
    }
    setModuleDetail(temp);
    getMenuType();
  }, [isOpen && ModuleDetails])



  function addMenu() {
    let tempData = ModuleDetail.menuList || [];
    let sort = tempData.filter((e) => { return e.menu_parent_id === null })
    if (sort.length > 0) {
      sort.sort((a, b) => b.menu_sort - a.menu_sort)
      sort = parseFloat(sort[0].menu_sort) + 1
    } else {
      sort = 1
    }
    let temp = {

      menu_id: generateUUID(),
      menu_module_id: null,
      menu_name: "",
      menu_created_by: null,
      menu_updated_by: null,
      menu_created_name: null,
      menu_updated_name: null,
      menu_sort: sort,
      menu_start_date: null,
      menu_end_date: null,
      menu_actual_date: null,
      menu_remark: null,
      is_new: true,
      menu_mt_id: 'c7fe79cf-d110-46c1-b7be-2f70761e8d03',
      menu_parent_id: null,
      menu_level: 1

    }
    tempData.push(temp)

    setModuleDetail({ ...ModuleDetail, menuList: tempData });
    ModuleDetail.menuList = tempData;
  }

  /*   useEffect(()=>{
  
  
   },[]) */
  const [textChange, setTextChange] = useState({ textvalue: '' });

  function changeMenuValue(key, value, menu_id) {
    let tempData = ModuleDetail;
    let index = tempData.menuList.findIndex((e) => e.menu_id === menu_id);
    if (index > -1) {
      if (key == 'menu_start_stop_date') {
        console.log('HERE')
        let start = parseInt(moment(tempData.module_start_date).format("YYYYMMDD") || 0);
        let end = parseInt(moment(tempData.module_end_date).format("YYYYMMDD") || 0);
        if (value) {
          setTextChange({ ...textChange, textvalue: new Date(value[0]) });
          textChange.textvalue = new Date(value[0]);
          tempData.menuList[index]['menu_start_date'] = textChange.textvalue;
          setTextChange({ ...textChange, textvalue: new Date(value[1]) });
          textChange.textvalue = new Date(value[1]);
          tempData.menuList[index]['menu_end_date'] = textChange.textvalue;

          for (var i = 0; i < tempData.menuList.length; i++) {
            console.log((parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0), start))
            if (parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0) < start || parseInt(moment(tempData.menuList[i].menu_end_date).format('YYYYMMDD') || 0) > end) {
              tempData.menuList[i].errorMessageDate = 'Date Must In Range Module Period !!';
              console.log("error DAte")
            } else {
              tempData.menuList[i].errorMessageDate = '';
            }
          }
        } else {
          setTextChange({ ...textChange, textvalue: null });
          textChange.textvalue = null;
          tempData.menuList[index]['menu_start_date'] = textChange.textvalue;
          setTextChange({ ...textChange, textvalue: null });
          textChange.textvalue = null;
          tempData.menuList[index]['menu_end_date'] = textChange.textvalue;
          for (var i = 0; i < tempData.menuList.length; i++) {
            console.log((parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0), start))
            if (parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0) < start || parseInt(moment(tempData.menuList[i].menu_end_date).format('YYYYMMDD') || 0) > end) {
              tempData.menuList[i].errorMessageDate = 'Date Must In Range Module Period !!';
              console.log("error DAte")
            } else {
              tempData.menuList[i].errorMessageDate = '';
            }
          }
        }

        /*  console.log(tempData.menuList[index][key]) */
      } else {

        setTextChange({ ...textChange, textvalue: value });
        textChange.textvalue = value;
        tempData.menuList[index][key] = textChange.textvalue;




        /* if(tempData[index].errorMessage && tempData[index].errorMessage !== ''){ */

        let b = [];
        let c = [];
        for (var i = 0; i < tempData.menuList.length; i++) {

          if (b.indexOf((tempData.menuList[i].menu_name).toString().toLowerCase().trim()) < 0) {
            b.push(tempData.menuList[i].menu_name.toString().toLowerCase().trim());
          } else {
            c.push(tempData.menuList[i].menu_name.toString().toLowerCase().trim());
          }


        }

        if (c.length > 0) {
          for (let name of c) {
            let check = tempData.menuList.filter((e) => { return (e.menu_name).toString().toLowerCase().trim() == name.toString().toLowerCase() });
            for (let item of check) {
              let index1 = tempData.menuList.findIndex((e) => e.menu_id == item.menu_id);
              if (index1 > -1) {
                tempData.menuList[index1].errorMessage = "Menu Name has Aleady !!"
              }
            }

            let indexB = b.findIndex((bb) => bb.toString().toLowerCase() == name.toString().toLowerCase());
            if (indexB > -1) {
              b.splice(indexB, 1);
            }
          }

        }

        if (b.length > 0) {
          for (let name of b) {
            let check = tempData.menuList.filter((e) => { return (e.menu_name).toString().toLowerCase().trim() == name.toString().toLowerCase() });
            for (let item of check) {
              let index1 = tempData.menuList.findIndex((e) => e.menu_id == item.menu_id);
              if (index1 > -1) {
                tempData.menuList[index1].errorMessage = ""
              }
            }


          }



        }

        //console.log(b,c)

        /*       temp[index].errorMessage = ''; */
        /*         setModuleDetail({...ModuleDetail,menuList:temp}) */
        /*      } */
        /*    console.log(tempData.menuList[index][key]) */

      }
      setModuleDetail(tempData);
      console.log("tempData", tempData)
    }
  }


  function SelectTypeMenu(mt_id, index) {
    let tempData = ModuleDetail;
    tempData.menuList[index].menu_mt_id = mt_id;
    if (mt_id === "fb609df7-2e46-4a3b-83d8-ef6e3e78f35f") {
      let datacheck = tempData.menuList.filter((x) => { return x.menu_parent_id === tempData.menuList[index].menu_id })
      if (datacheck.length > 0) {
        tempData.menuList[index].errorMessagetype = 'Features cannot have submenus !!';
      } else {
        tempData.menuList[index].errorMessagetype = '';
      }
    } else {
      tempData.menuList[index].errorMessagetype = '';
    }

    let sortedData = sortMenu(tempData.menuList);
    tempData.menuList = sortedData
    /*     let temp_sort = tempData.menuList.sort((a, b) => a.menu_sort - b.menu_sort) */
    setModuleDetail({ ...ModuleDetail, menuList: tempData.menuList });
    ModuleDetail.menuList = tempData.menuList || [];

    /*     tempData.menu_mt_id = mt_id || null
        let tempChang = tempData
    
        setModuleDetail({ ...ModuleDetail, menuList: tempChang });
        ModuleDetail.menuList = tempChang || []; */
    /*   console.log(tempData) */
  }







  const [removeMenuList, setRemoveMenuList] = useState([]);

  function removeMenu(index) {
    if (index > -1) {
      let tempMenu = ModuleDetail.menuList || [];
      let tempRm = removeMenuList || [];
      tempRm.push(tempMenu[index]);
      let check = tempMenu.filter((x) => { return x.menu_parent_id === tempMenu[index].menu_parent_id })
      let count = tempMenu[index].menu_sort;
      for (let item of tempMenu) {
        if (check.filter((x) => { return x.menu_id === item.menu_id }).length > 0 && count < item.menu_sort) {
          item.menu_sort -= 1
        }

      }
      tempMenu.splice(index, 1);
      console.log(tempMenu);
      setModuleDetail({ ...ModuleDetail, menuList: tempMenu });
      ModuleDetail.menuList = tempMenu;
      setRemoveMenuList(tempRm);
    }

    console.log('index', index)


    toggle_modal_confirm();
  }





  const props = {
    maxCount: 1,
    showUploadList: false,
    name: 'file',
    accept: ".png, .jpg, .jpeg, .pdf, .doc, .xls, .xlsx, .pptm, .svg",
    multiple: true,
    method: 'get',
    headers: {
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
    },

    action: Configs.TTT_Game_Service + '/api/upload/fackFile',
    onChange(info) {
      const { status } = info.file;
      info.status = 'done';
      console.log('status', status)
      if (status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        /*  setFileImport(info.file); */

        /* message.success(`${info.file.name} file uploaded successfully.`); */
      } else if (status === 'error') {
        /* message.error(`${info.file.name} file upload failed.`); */
      }
    },
    onDrop(e) {
      console.log('Dropped files', e.dataTransfer.files);
    },
  };


  const [fileImport, setFileImport] = useState({
    file: [],
    remark: null,
    mode: 'view'
  });
  const [fileImportList, setFileImportList] = useState([]);

  const [uploadfilemodel, setuploadfilemodel] = useState({
    head: [{
      label: "Number",
      field: "no",
    },
    {
      label: "File Name",
      field: "filename",
    },
    {
      label: "Upload Date",
      field: "date",
    },
    {
      label: "Uploader",
      field: "uploadby",
    },
    {
      label: "Management",
      field: "mgt",
    },],
    row: [],

  })


  /*  async function delFileOnbox(fd_img_id) {
    console.log('delFileOnbox',fileUploadList,fd_img_id);
   } */
  /*  console.log('0000000',fileUploadList); */


  function setmgt(count, fd_img_id, ole_fs) {

    return (<>
      <i class='fa fa-trash' style={{ cursor: 'pointer' }} onClick={() => {
        let listall = uploadfilemodel.row
        let oleList = ole_fs;
        /* console.log('WorkFlow',fileUploadList,fd_img_id); */

        /*   delFileOnbox(fd_img_id); */

        let checkSpild = oleList.findIndex((ele) => ele == fd_img_id);

        if (checkSpild > -1) {
          oleList.splice(checkSpild, 1)
          /*  console.log('checkSpild', oleList.splice(checkSpild, 1)); */
        }
        /*      setFileUploadList(oleList) */



        listall.splice(count - 1, 1)
        let newcount = 1
        for (let i of listall) {
          i.no = newcount
          i.mgt = setmgt(newcount, i.fd_img_id, oleList)
          newcount += 1
        }
        setFileUploadList(oleList)
        setuploadfilemodel({ ...uploadfilemodel, row: listall })
        settest({ ...test, test: '' });
        test.test = '';
      }}></i>
    </>)
  }



  async function CheckAndCreateFolderBox(project_id, folder_name, ref_id, callback) {
    let temp = {
      folderLis: ["PROJECT", `PROJECT_${project_id}`, "PROJECT DOCUMENTS", folder_name, `MODULE_${ref_id}`]
    }
    await axios({
      method: "POST",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      }, data: temp,
    })
      .then(async function (response) {
        if (response.data) {
          let folder_id = response.data.folder_id;
          if (callback) {
            let res = {
              ref_id: ref_id,
              folder_id: folder_id,
              folder_name: folder_name,
            }
            callback(res)
          }
        }

      })
      .catch(function (error) {
        console.log(error);
      });
  }


  async function UploadFiles(folder_id, key, ref_id, file, callback) {
    let image_id_list = fileUploadList;
    let data_ = new FormData();

    file.forEach((item, index) => {
      if (item.is_chacge) {
        if (item.file) {
          data_.append('File', item.file);
        }
      }
    });
    data_.append('key', key);
    data_.append('image_old_list', JSON.stringify(image_id_list));
    data_.append('ref_id', ref_id);
    data_.append('folder_id', folder_id);

    await axios({
      method: "POST",
      url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "multipart/form-data",
      }, data: data_,
    })
      .then(async function (response) {
        let temp = fileUploadList || [];
        for (let item of response.data.image_id_list) {
          temp.push(item);
        }
        setFileUploadList(temp);
        settest({ ...test, test: '' });
        test.test = '';
        if (callback) {
          callback(true)
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  async function saveupload() {

    setLoading(true)
    let datafile = uploadfilemodel.row || []

    await CheckAndCreateFolderBox(project_id, 'WORK FLOW', ModuleDetails.module_id, async (result) => {
      if (result) {
        await UploadFiles(result.folder_id, result.folder_name, result.ref_id, datafile, async (result2) => {
          await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/moduleMenu/saveuploadfileworkflow`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              'X-TTT': `${Configs.API_TTT}`,
              'Content-Type': 'application/json',
            }, data: {
              ref_id: ModuleDetails.module_id,
              project_id: project_id,
              file: []
            }

          })
            .then(async function (response) {
              setLoading(false)
              if (response.data) {
                setuploadfilemodel({ ...uploadfilemodel, row: [] })
                toggle_modal_modelversion()
              }
            })
            .catch(function (error) {
              console.log(error);
              setLoading(false)

            });
        })
      }
    })

    /*   if (uploadfilemodel.row.length > 0) {
  
        for (let i of uploadfilemodel.row) {
  
          if (i.byid !== undefined && i.byid === true) {
            datafile.push(
              {
                ul_file_path: i.file.ul_file_path,
                ul_file_name: i.file.ul_file_name,
                name: i.file.ul_file_name,
                path: i.file.ul_file_path,
                url: i.file.ul_file_path,
                is_save: false,
                ul_is_use: true,
                ul_file_size: 0,
                ul_type: "workflow",
                ul_group_name: "module",
                ul_menu_name: "config module",
                ul_is_profile: true,
                status: 'done',
                byid: true
              }
            )
          } else {
            datafile.push(
              {
                ul_file_path: URL.createObjectURL(i.file),
                ul_file_name: i.file.name,
                name: i.file.name,
                path: URL.createObjectURL(i.file),
                url: URL.createObjectURL(i.file),
                is_save: true,
                ul_is_use: true,
                ul_file_size: 0,
                ul_type: "workflow",
                ul_group_name: "module",
                ul_menu_name: "config module",
                ul_is_profile: true,
                status: 'done',
                file: i.file,
                is_img_upload: true
              }
            )
          }
  
        }
  
      } */

    /*    let ole_file = []
       if (datafile.length > 0) {
         ole_file = datafile.filter((e) => e.byid !== undefined && e.byid == true)
         let newFile = datafile.filter((e) => e.byid == undefined || e.byid == false)
         if (newFile.length > 0) {
           const dataImg = new FormData();
           for (let i = 0; i < newFile.length; i++) {
             dataImg.append('Profile', newFile[i].file);
           }
           dataImg.append('typeFile', "workflow");
           dataImg.append('projectKey', "TTTSPACESHIP");
           await axios({
             method: 'post',
             url: `${Configs.TTT_Game_Service}/api/upload/document2`,
             headers: {
               'X-TTT': `${Configs.API_TTT}`,
               'Content-Type': 'application/octet-stream',
             },
             data: dataImg,
           })
             .then(async function (response) {
               if (response.data.status == true) {
                 response.data.data.forEach((e) => {
   
   
                   const tmp = {
                     name: e.orgin_name,
                     path: e.path,
                     is_save: true,
                     ul_is_use: true,
                     ul_file_size: e.size,
                     url: e.path,
                     ul_type: "workflow",
                     ul_group_name: "module",
                     ul_file_name: e.orgin_name,
                     ul_file_path: e.path,
                     ul_menu_name: "config module",
                     status: 'done'
   
                   }
                   ole_file.push(tmp);
                 })
               }
   
             })
             .catch(function (error) {
               console.log(error);
             });
         }
       } */
    /* 
        await axios({
          method: 'post',
          url: `${Configs.TTT_Game_Service}/api/moduleMenu/saveuploadfileworkflow`,
          headers: {
            Authorization: sessionStorage.getItem('access_token'),
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
          }, data: {
            ref_id: ModuleDetails.module_id,
            project_id: project_id,
            file: []
          }
    
        })
          .then(async function (response) {
            if (response.data) {
              setuploadfilemodel({ ...uploadfilemodel, row: [] })
              toggle_modal_modelversion()
            }
          })
          .catch(function (error) {
            console.log(error);
    
          }); */


  }




  async function getuploadfile() {

    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/moduleMenu/getuploadfileworkflow`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      }, data: {
        id: project_id,
        ref_id: ModuleDetails.module_id
      }

    })
      .then(async function (response) {
        let oleIdFile = [];
        if (response.data) {
          let listall = uploadfilemodel.row
          let count = uploadfilemodel.row.length + 1

          if (response.data.length > 0) {
            for (let t of response.data) {
              if (t.fd_img_id) {
                oleIdFile.push(t.fd_img_id)
              }
            }

            settest({ ...test, test: '' });
            test.test = '';

            let getWordkFlowe = response.data.filter((e) => { return e.fd_keyfile === 'WORK FLOW' })
            for (let item of getWordkFlowe) {
              let cuttype = item.fd_origin_name.split('.')
              let icon = docicon
              if (cuttype[cuttype.length - 1] === 'pdf') {
                icon = pdficon
              }
              if (cuttype[cuttype.length - 1] === 'jpg' || cuttype[cuttype.length - 1] === 'jpeg' || cuttype[cuttype.length - 1] === 'png') {
                icon = imageicon
              }
              if (cuttype[cuttype.length - 1] === 'doc') {
                icon = docicon
              }
              if (cuttype[cuttype.length - 1] === 'xls' || cuttype[cuttype.length - 1] === 'xlsx') {
                icon = xlsxicon
              }
              if (cuttype[cuttype.length - 1] === 'pptm') {
                icon = ppticon
              }
              if (cuttype[cuttype.length - 1] === 'svg') {
                icon = svgicon
              }


              if (item.fd_img_id) {

                listall.push({
                  file: item,
                  byid: true,
                  fd_img_id: item.fd_img_id,
                  no: count,
                  filename: <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '350px', whiteSpace: 'nowrap', marginLeft: '10px', cursor: 'pointer' }}
                    onClick={() => {
                      downloadFile(item.fd_img_id, item.fd_origin_name);
                      /*   const link = document.createElement('a');
                        const urlBlob = window.URL.createObjectURL(response2.data);
                        link.href = urlBlob;
    
                        link.download = item.fd_origin_name;
                        link.click(); */
                    }}
                  > <img
                    src={`${icon}`}
                    style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                  >
                    </img> {item.fd_origin_name}</div>,
                  date: <>{moment(item.fd_created_date).format('DD-MM-YYYY')}</>,
                  uploadby: `${item.frist_name} ${item.last_name}` || '',
                  mgt: setmgt(count, item.fd_img_id, oleIdFile),
                  typeUpload: item.fd_keyfile
                })

                count += 1
              }

            }
            /*  let filterGetTypeUpload = listall.filter((e) => {return e.typeUpload === 'WORK FLOW'}) */
            console.log('ไฟล์เก่า', oleIdFile);

            setFileUploadList(oleIdFile);
            setuploadfilemodel({ ...uploadfilemodel, row: listall })

            settest({ ...test, test: '' });
            test.test = '';

            /*       let count = uploadfilemodel.row.length + 1
                  for (let i of response.data) {
      
                    let cuttype = i.ul_file_name.split('.')
                    let icon = docicon
      
                    if (cuttype[cuttype.length - 1] === 'pdf') {
                      icon = pdficon
                    }
                    if (cuttype[cuttype.length - 1] === 'jpg' || cuttype[cuttype.length - 1] === 'jpeg' || cuttype[cuttype.length - 1] === 'png') {
                      icon = imageicon
                    }
                    if (cuttype[cuttype.length - 1] === 'doc') {
                      icon = docicon
                    }
                    if (cuttype[cuttype.length - 1] === 'xls' || cuttype[cuttype.length - 1] === 'xlsx') {
                      icon = xlsxicon
                    }
                    if (cuttype[cuttype.length - 1] === 'pptm') {
                      icon = ppticon
                    }
                    if (cuttype[cuttype.length - 1] === 'svg') {
                      icon = svgicon
                    }
      
                    listall.push({
                      file: i,
                      byid: true,
                      no: count,
                      filename: <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '350px', whiteSpace: 'nowrap', marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => {
                          const link = document.createElement('a');
                          link.href = `${Configs.TTT_Game_Service_IMG}${i.ul_file_path}`;
      
                          link.download = i.ul_file_name;
                          link.click();
                        }}
                      > <img
                        src={`${icon}`}
                        style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                      >
                        </img> {i.ul_file_name}</div>,
                      date: <>{moment(i.ul_created_date).format('DD-MM-YYYY')}</>,
                      uploadby: i.ul_created_by_name || '',
                      mgt: setmgt(count)
                    })
      
                    count += 1
                  } */

            /*  setuploadfilemodel({ ...uploadfilemodel, row: listall }) */


          }
          toggle_modal_modelversion()


        }

      })
      .catch(function (error) {

        console.log(error);
        /*   setErrorMasgerList({
              ...errorMasgerList,
              isError: true,
              massger: error.response.data.error[0].errorDis
          })
          toggle_modal_alertKick() */

      });


  }



  async function getMenuType(params) {
    //menuTypeRoute
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/menuType/filterMenuType`,
      headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },

    })
      .then(async function (response) {
        // console.log(response.data);
        let Array = [];

        if (response.data) {
          /*     Array = response.data.sort((a,b) => {return a.mt_name - b.mt_name}) */
          Array = response.data.sort((dataA, dataB) => {
            const a = dataA.mt_name;
            const b = dataB.mt_name;
          /*   
            if (a === 'menu' || a === 'Menu') {
              return +1;
            }
            if (b === 'menu' || b === 'Menu') {
              return -1;
            }
             */return b.localeCompare(a);
          });

        }

        /*  console.log('ArrayArray',Array); */
        seMenutypeList(Array);
      })
      .catch(function (error) {
        console.log(error);
      });
  }





  function listchcekboxinMenu() {
    let datalist = MenuchcekList.dataselect, data = ModuleDetail.menuList

    datalist.sort((a, b) => {
      return a.index - b.index;
    });

    let newsort = 1, lv = 1, arrsort = [], checkdatause = [], beforindex = []
    for (let i of data) {
      /* console.log(i); */
      if (checkdatause.filter((x) => { return x.menu_id === i.menu_id }).length === 0) {
        gumyvara(i)
      }



    }
    function gumyvara(obj) {

      let checkdata = datalist.filter((x) => { return x.menu_id === obj.menu_id });
      if (checkdata.length === 0) {
        /*  console.log(lv,parseFloat(obj.menu_level),lv  >  parseFloat(obj.menu_level),arrsort); */
        if (lv > parseFloat(obj.menu_level)) {
          newsort = parseFloat(data[beforindex[parseFloat(obj.menu_level) - 1]].menu_sort)
        } else if (lv < parseFloat(obj.menu_level)) {
          newsort = 1
        }




        if (arrsort[parseFloat(obj.menu_level) - 1] !== undefined) {
          arrsort[parseFloat(obj.menu_level) - 1] = newsort
          beforindex[parseFloat(obj.menu_level) - 1] = obj.index
        } else {
          arrsort.push(newsort)
          beforindex.push(obj.index)
        }
        /* console.log('d',newsort,arrsort,beforindex,lv,obj.menu_level) */
        if (lv > parseFloat(obj.menu_level)) {
          newsort = arrsort[parseFloat(obj.menu_level) - 1] + 1
        }
        lv = parseFloat(obj.menu_level)


        obj.menu_sort = newsort
        checkdatause.push(obj)
        newsort += 1

        let checkdatalist = data.filter((x) => { return x.menu_parent_id === obj.menu_id });
        if (checkdatalist.length > 0) {
          for (let i of checkdatalist) {
            gumyvara(i)
          }
        }

      } else {
       // console.log(parseFloat(obj.menu_level), lv, obj.menu_id);
        /* console.log(arrsort,parseFloat(obj.menu_level)-1,obj); */
        /* if (parseFloat(obj.menu_level) !== 1) {
        } */
        obj.menu_parent_id = data[beforindex[parseFloat(obj.menu_level) - 1]].menu_id



        /* if (parseFloat(obj.menu_level)+1 >= lv) { */
        obj.menu_level = parseFloat(obj.menu_level) + 1
        /*   } */
        /* console.log(newsort,datalist,arrsort,lv) */
        if (lv < parseFloat(obj.menu_level)) {
          newsort = 1

        }
        if (lv > parseFloat(obj.menu_level)) {
          newsort = data.filter((x) => { return x.menu_parent_id === data[beforindex[parseFloat(obj.menu_level) - 1]].menu_parent_id }).length;

        }


        /* else if (lv > parseFloat(obj.menu_level)) {
          newsort = arrsort[parseFloat(obj.menu_level)-1]+1
        }
       */
        obj.menu_sort = newsort

        if (arrsort[parseFloat(obj.menu_level) - 1] !== undefined) {
          arrsort[parseFloat(obj.menu_level) - 1] = newsort
          beforindex[parseFloat(obj.menu_level) - 1] = obj.index
        } else {
          arrsort.push(newsort)
          beforindex.push(obj.index)
        }
        console.log(newsort, datalist, arrsort, lv)
        lv = parseFloat(obj.menu_level)
        checkdatause.push(obj)
        newsort += 1
      }


    }
    //console.log(data)
    for (let i of data) {
      if (i.menu_mt_id === "fb609df7-2e46-4a3b-83d8-ef6e3e78f35f") {
        let datacheck = data.filter((x) => { return x.menu_parent_id === i.menu_id })
        if (datacheck.length > 0) {
          i.errorMessagetype = 'Features cannot have submenus !!';
        } else {
          i.errorMessagetype = '';
        }
      } else {
        i.errorMessagetype = '';
      }

    }

    setModuleDetail({ ...ModuleDetail, menuList: data });
/*     setMenuchcekList({ ...MenuchcekList, dataselect: [] }); */
  }

  function listchcekboxoutMenu() {
    let datalist = MenuchcekList.dataselect, data = ModuleDetail.menuList

    let cutmaping = []
    for (let i of datalist) {
      if (datalist.filter((x) => { return x.menu_id === i.menu_parent_id }).length === 0) {
        let conut = 0
        let num = checkparent(i, conut)
        console.log(num);
        i.maxindex = num
        cutmaping.push(i)
      }

    }

    function checkparent(obj, conut) {
      console.log(conut);
      conut += 1
      let checkdata = datalist.filter((x) => { return x.menu_parent_id === obj.menu_id });
      if (checkdata.length > 0) {
        for (let i of checkdata) {
          let num = checkparent(i, conut)
          conut = num
        }
      }
      return conut
    }

    console.log(cutmaping);

    datalist.sort((a, b) => {
      return a.index - b.index;
    });

    let newsort = 1, lv = 1, arrsort = [], checkdatause = [], beforindex = [], cut = 0, lvbefor = 1, indexbefor = 0
    for (let i of data) {

      if (checkdatause.filter((x) => { return x.menu_id === i.menu_id }).length === 0) {
        gumyvara(i, cut)
      }


    }
    function gumyvara(obj, count = 0) {

      if (checkdatause.filter((x) => { return x.menu_id === obj.menu_id }).length === 0) {
        let checkdata = cutmaping.filter((x) => { return x.menu_id === obj.menu_id });
        if (checkdata.length === 0) {
          /*  console.log(lv, obj.menu_level, count, checkdatause, lvbefor, newsort, arrsort); */
          console.log('in', obj.menu_level, obj.index, newsort);
          if (lvbefor === obj.menu_level) {
            count = 0
            cut = 0
          }
          obj.menu_level = parseFloat(obj.menu_level) - count
          /*  if (lv !==  parseFloat(obj.menu_level)) {
             newsort = 1
     
           } */
          if (datalist.filter((x) => { return x.menu_id === obj.menu_id }).length === 0) {
            if (lv > parseFloat(obj.menu_level)) {
              newsort = parseFloat(data[beforindex[parseFloat(obj.menu_level) - 1]].menu_sort)
            } else if (lv < parseFloat(obj.menu_level)) {
              newsort = 1
            }

            if (arrsort[parseFloat(obj.menu_level) - 1] !== undefined) {
              arrsort[parseFloat(obj.menu_level) - 1] = newsort
              beforindex[parseFloat(obj.menu_level) - 1] = obj.index
            } else {
              arrsort.push(newsort)
              beforindex.push(obj.index)
            }

            if (lv > parseFloat(obj.menu_level)) {
              newsort = parseFloat(data[beforindex[parseFloat(obj.menu_level) - 1]].menu_sort)
            }
            lv = parseFloat(obj.menu_level)


            obj.menu_sort = newsort
            checkdatause.push(obj)
            newsort += 1

            let checkdatalist = data.filter((x) => { return x.menu_parent_id === obj.menu_id });
            if (checkdatalist.length > 0) {
              for (let i of checkdatalist) {
                gumyvara(i, count)
              }
            }

          }

        } else {
          console.log('out', obj.menu_level, obj.index, newsort);
          /*  console.log(parseFloat(obj.menu_level), lv, obj.menu_id, arrsort); */
          /*  if (lv > parseFloat(obj.menu_level)) {
              newsort = parseFloat(data[beforindex[parseFloat(obj.menu_level) - 1]].menu_sort)
            } else if (lv < parseFloat(obj.menu_level)) {
              newsort = 1
            } */
          let checkindex = data.findIndex((i) => i.menu_id === obj.menu_parent_id)
          if (checkindex !== -1) {
            obj.menu_parent_id = data[checkindex].menu_parent_id
          } else {
            obj.menu_parent_id = null
          }

          obj.menu_level = parseFloat(obj.menu_level) - 1
          /*  let checkindex2 = data.findIndex((i) => i.menu_id === obj.menu_parent_id)
           if (checkindex2 !== -1) {
             newsort = parseFloat(data[checkindex2].menu_sort) + 1
           } else { */
          newsort = parseFloat(data[beforindex[parseFloat(obj.menu_level) - 1]].menu_sort) + 1
          /* } */
          console.log(newsort, datalist, arrsort, lv)

          obj.menu_sort = newsort

          if (arrsort[parseFloat(obj.menu_level) - 1] !== undefined) {
            arrsort[parseFloat(obj.menu_level) - 1] = newsort
            beforindex[parseFloat(obj.menu_level) - 1] = obj.index
          } else {
            arrsort.push(newsort)
            beforindex.push(obj.index)
          }

          console.log(newsort, datalist, arrsort, lv)
          if (data.filter((x) => { return x.menu_parent_id === obj.menu_id }).length > 0) {
            cut = 1
          }

          lv = parseFloat(obj.menu_level)
          lvbefor = parseFloat(obj.menu_level)
          checkdatause.push(obj)
          indexbefor = data.filter((x) => { return x.menu_parent_id === obj.menu_id }).length

          newsort += 1
        }

      }
    }
    console.log(data)

    let masum = []
    for (let i of data) {
      /*   console.log(masum); */
      if (masum.filter((x) => { return x.menu_id === i.menu_id }).length === 0) {
        mapdata(i, 1)
      }

    }
    console.log(masum);
    function mapdata(obj, lv) {
      masum.push(obj)
      /* console.log(masum); */
      let checkdata = data.filter((x) => { return x.menu_parent_id === obj.menu_id });

      checkdata.sort((a, b) => { return a.menu_sort - b.menu_sort; });
      /* console.log(checkdata,lv); */
      if (checkdata.length > 0) {
        for (let i of checkdata) {
          mapdata(i, lv + 1)

        }
      }

    }

    for (let i of masum) {
      if (i.menu_mt_id === "fb609df7-2e46-4a3b-83d8-ef6e3e78f35f") {
        let datacheck = masum.filter((x) => { return x.menu_parent_id === i.menu_id })
        if (datacheck.length > 0) {
          i.errorMessagetype = 'Features cannot have submenus !!';
        } else {
          i.errorMessagetype = '';
        }
      } else {
        i.errorMessagetype = '';
      }

    }




    setModuleDetail({ ...ModuleDetail, menuList: masum });
    /* setMenuchcekList({ ...MenuchcekList, dataselect: [] }); */
  }
  function listchcekboxoutMenux() {

    let datalist = MenuchcekList.dataselect, data = ModuleDetail
    let befordatacut = []
    for (let i of datalist) {
      let datacheck = befordatacut.filter((x) => { return (x.menu_id === i.menu_id) })
      console.log(datacheck);
      if (datacheck.length === 0) {
        loopparent(i, befordatacut)
        let check = ModuleDetail.menuList.filter((x) => { return (x.menu_id === i.menu_parent_id) })
        if (check.length === 0) {
          i.menu_parent_id = null
        } else {
          i.menu_parent_id = check[0].menu_parent_id
        }

        i.menu_level -= 1
        befordatacut.push(i)
      }

    }
    console.log(befordatacut);
    function loopparent(item, arr) {
      console.log(item);
      let list = ModuleDetail.menuList.filter((x) => { return x.menu_parent_id === item.menu_id })
      list.sort((a, b) => { return a.menu_sort - b.menu_sort; });
      for (let i of list) {
        if (i.menu_parent_id !== null) {
          loopparent(i, arr)
        }

        i.menu_level -= 1
        arr.push(i)
      }
    }
    befordatacut.sort((a, b) => {
      return a.index - b.index;
    });
    let arrsort = [1], newsort = 1, lv = 1
    for (let i of data.menuList) {
      let chcek = befordatacut.findIndex((x) => x.menu_id === i.menu_id)
      if (chcek !== -1) {
        i = befordatacut[chcek]
      }

      if (newsort >= parseFloat(i.menu_sort)) {
        if (newsort !== parseFloat(i.menu_sort - 1) && lv === i.menu_level) {
          /*  newsort = parseFloat(i.menu_sort) */
        } else {
          newsort = parseFloat(i.menu_sort)
        }

        /* console.log('hop'); */
      } else {
        if (i.menu_level < lv) {
          newsort = arrsort[i.menu_level - 1] + 1
        }

      }
      if (arrsort[i.menu_level - 1] !== undefined) {
        arrsort[i.menu_level - 1] = newsort
      } else {
        arrsort.push(newsort)
      }
      i.menu_sort = newsort
      lv = i.menu_level
      newsort += 1

    }
    console.log(data.menuList);
    let befordatalist = [], newdata = []
    for (let i of data.menuList) {
      let datacheck = befordatalist.filter((x) => { return (x.menu_id === i.menu_id) })
      console.log(datacheck);
      if (datacheck.length === 0) {
        loopparent(i.menu_id, befordatalist)

        /*   befordatalist.push(...list) */
        newdata.push(i)
      }

    }

    function loopparent(id, arr) {
      let list = data.menuList.filter((x) => { return x.menu_parent_id === id })
      list.sort((a, b) => { return a.menu_sort - b.menu_sort; });
      for (let i of list) {
        if (i.menu_parent_id !== null) {
          loopparent(i.menu_id, arr)
        }
        arr.push(i)
      }
    }
    console.log(newdata);
    sortdataseting(newdata, befordatalist, data)

  }


  function sortUpMenu(index) {
    let tempData = ModuleDetail;
    /*    console.log(tempData); */
    let findindex = ModuleDetail.menuList.findIndex((i) => parseInt(i.menu_sort) === parseInt(tempData.menuList[index].menu_sort) - 1 && i.menu_parent_id === tempData.menuList[index].menu_parent_id)
    /* console.log(index, findindex, tempData.menuList[index].menu_parent_id); */
    tempData.menuList[index].menu_sort = parseInt(tempData.menuList[index].menu_sort || 1) - 1
    tempData.menuList[findindex].menu_sort = parseInt(tempData.menuList[findindex].menu_sort || 1) + 1
    let befordatalist = [], newdata = []
    for (let i of tempData.menuList) {
      let datacheck = befordatalist.filter((x) => { return (x.menu_id === i.menu_id) })
      /* console.log(datacheck); */
      if (datacheck.length === 0) {
        loopparent(i.menu_id, befordatalist)

        /*   befordatalist.push(...list) */
        newdata.push(i)
      }

    }

    function loopparent(id, arr) {
      let list = tempData.menuList.filter((x) => { return x.menu_parent_id === id })
      list.sort((a, b) => { return a.menu_sort - b.menu_sort; });
      for (let i of list) {
        if (i.menu_parent_id !== null) {
          loopparent(i.menu_id, arr)
        }
        arr.push(i)
      }
    }
    console.log(newdata);
    sortdataseting(newdata, befordatalist, tempData)

  }

  function sortDownMenu(index) {
    let tempData = ModuleDetail;
    /*   console.log(tempData); */
    let findindex = ModuleDetail.menuList.findIndex((i) => parseInt(i.menu_sort) === parseInt(tempData.menuList[index].menu_sort) + 1 && i.menu_parent_id === tempData.menuList[index].menu_parent_id)
    /*  console.log(index, findindex, tempData.menuList[index].menu_parent_id); */
    tempData.menuList[index].menu_sort = parseInt(tempData.menuList[index].menu_sort || 1) + 1
    tempData.menuList[findindex].menu_sort = parseInt(tempData.menuList[findindex].menu_sort || 1) - 1
    let befordatalist = [], newdata = []
    for (let i of tempData.menuList) {
      let datacheck = befordatalist.filter((x) => { return (x.menu_id === i.menu_id) })
      console.log(datacheck);
      if (datacheck.length === 0) {
        loopparent(i.menu_id, befordatalist)

        /*   befordatalist.push(...list) */
        newdata.push(i)
      }

    }

    function loopparent(id, arr) {
      let list = tempData.menuList.filter((x) => { return x.menu_parent_id === id })
      list.sort((a, b) => { return a.menu_sort - b.menu_sort; });
      for (let i of list) {
        if (i.menu_parent_id !== null) {
          loopparent(i.menu_id, arr)
        }
        arr.push(i)
      }
    }
    /*  console.log(list); */
    console.log(newdata);
    sortdataseting(newdata, befordatalist, tempData)

  }

  function sortdataseting(newdata, befordatalist, tempData) {
    newdata.sort((a, b) => { return a.menu_sort - b.menu_sort; });

    let sortnew = [], checkinglist = []
    for (let i of newdata) {
      loopseting(i, sortnew)

    }

    function loopseting(data, arr) {
      arr.push(data)
      if (checkinglist.filter((x) => { return (x.menu_id === data.menu_id) }).length === 0) {
        let datacheck = befordatalist.filter((x) => { return (x.menu_parent_id === data.menu_id) })
        if (datacheck.length > 0) {
          datacheck.sort((a, b) => { return a.menu_sort - b.menu_sort; });
          for (let d of datacheck) {
            loopseting(d, arr)
            /*  arr.push(d) */
          }
        }
      }
      checkinglist.push(data)
    }
    console.log(sortnew);
    tempData.menuList = sortnew
    setModuleDetail({ ...ModuleDetail, menuList: tempData.menuList });
    ModuleDetail.menuList = tempData.menuList || [];
  }

  function mapparent(item, arr, mode) {
    console.log(mode);
    let datacheck = arr.filter((x) => { return (x.menu_id === item.menu_id) })
    if (datacheck.length === 0 || mode === 'del') {

      if (mode === 'add') {
        arr.push(item)
      } else {
        arr.splice(arr.findIndex((i) => i.menu_id === item.menu_id), 1)
      }

      let list = ModuleDetail.menuList.filter((x) => { return x.menu_parent_id === item.menu_id })
      if (list.length > 0) {

        for (let i of list) {

          let datain = mapparent(i, arr, mode)
          arr = datain

        }
      }
    }

    return arr
  }



  const downloadFile = async (url, filename) => {
    try {
      const response2 = await axios.get(`https://api.box.com/2.0/files/${url}/content`, {
        headers: {
          Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
        },
        responseType: 'blob',
      });
      const urlBlob = window.URL.createObjectURL(response2.data);
      const link = document.createElement('a');
      link.href = urlBlob;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.URL.revokeObjectURL(urlBlob);
    } catch (error) {
      console.error('Error downloading file', error);
    }
  };


  function removeModule(data) {
    console.log(ModuleDetail);
    let temp = {
      module_id: ModuleDetail.module_id,
      module_project_id: ModuleDetail.module_project_id,
    }
    axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/moduleMenu/DelModule`,
      headers: {
        Authorization: sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp

    })
      .then(function (response) {
        if (response.data) {
          toggle_modal_confirm2()
          toggle_modal_config_module();
          getProjectDetail(project_id)
        }



      })
      .catch(function (error) {
        console.log(error);


      });
  }



  const divRefs = useRef([]);

  useEffect(() => {

    const handleScroll = (index) => {
      const scrollLeft = divRefs.current[index].scrollLeft;
      console.log(scrollLeft);
      divRefs.current.forEach((div, i) => {
        if (i !== index) {
          div.scrollLeft = scrollLeft;
        }
      });
    };

    divRefs.current.forEach((div, index) => {
      div.addEventListener('scroll', () => handleScroll(index));
    });

    return () => {
      divRefs.current.forEach((div, index) => {
        div.removeEventListener('scroll', () => handleScroll(index));
      });
    };
  }, []);


  return (

    <>


      <ConfigProvider
        theme={{
          token: {

            colorInfo: '#85BFFF',
            colorBorder: '#85BFFF',
            colorText: '#FFF',
            // colorPrimary: '85BFFF',
            colorTextDescription: 'White',
            // colorBgContainer: '#16285C',

            zIndexPopupBase: 999999,
            colorPrimaryBg: '#0047FF',
            colorBgBase: '#001D5F',

            colorTextQuaternary: 'White',


            colorFillTertiary: '#0047FF',


            colorTextBase: 'White',




            colorPrimaryBg: '#85BFFF',
            /*       
                   colorBgLayout:'red',
                   colorFillQuaternary:'red', */


          }
        }}
      >

        {modelversion === true ?
          <div className={`backdropModuleMenu ${modelversion ? 'opened' : 'closed'}`} style={{ zIndex: 99999,

            left: `${switModeShowDisplay === true ? '0%' : '50%'}`,
            position: `${switModeShowDisplay === true ? 'absolute' : 'fixed'}`,
            transform: `${switModeShowDisplay === true ? '' : 'translate(-50%, -14%)'}`,
            width: `${switModeShowDisplay === true ? '100%' : '100%'}`,
            height: `${switModeShowDisplay === true ? '83vh' : '100%'}`,
            top: `${switModeShowDisplay === true ? '4%' : '17.5%'}`

           }}>
            <div className='cardvideo' style={{
               width: '70%',
               height: '70%',
               paddingLeft: '20px',
               paddingRight: '20px',
               left: '51%',
               top: '43%',
               position: 'fixed',
               transform: 'translate(-50%,-50%)',
               /* marginTop: '12%' */
            }}>


              <div style={{ position: 'absolute', fontSize: '24px', fontFamily: 'Oxanium', color: '#ffffff', marginTop: '20px', textTransform: 'uppercase' }}>Upload WORK FLOW</div>

              <div className='row' style={{ height: '80%', padding: '5px', marginTop: '65px' }}>

                <div className='col-4' style={{ height: '100%', borderRight: '2px solid #fff', borderTop: '2px solid #fff', paddingTop: '2%' }}>
                  <Dragger {...props} beforeUpload={(file, fileList) => {

                    setFileImportList(fileList)
                    let listall = uploadfilemodel.row
                    let count = uploadfilemodel.row.length + 1
                    let cuttype = file.name.split('.')
                    let icon = docicon
                    console.log(cuttype[cuttype.length - 1]);
                    if (cuttype[cuttype.length - 1] === 'pdf') {
                      icon = pdficon
                    }
                    if (cuttype[cuttype.length - 1] === 'jpg' || cuttype[cuttype.length - 1] === 'jpeg' || cuttype[cuttype.length - 1] === 'png') {
                      icon = imageicon
                    }
                    if (cuttype[cuttype.length - 1] === 'doc') {
                      icon = docicon
                    }
                    if (cuttype[cuttype.length - 1] === 'xls' || cuttype[cuttype.length - 1] === 'xlsx') {
                      icon = xlsxicon
                    }
                    if (cuttype[cuttype.length - 1] === 'pptm') {
                      icon = ppticon
                    }
                    if (cuttype[cuttype.length - 1] === 'svg') {
                      icon = svgicon
                    }
                    listall.push({
                      file: file,
                      is_img_upload: true,
                      no: count,
                      filename: <div style={{ textOverflow: 'ellipsis', overflow: 'hidden', width: '350px', whiteSpace: 'nowrap', marginLeft: '10px', cursor: 'pointer' }}
                        onClick={() => {
                          const link = document.createElement('a');
                          link.href = `${URL.createObjectURL(file)}`;
                          link.download = file.name;
                          link.click();
                        }}> <img
                          src={`${icon}`}
                          style={{ height: '20px', width: '20px', cursor: 'pointer' }}
                        >
                        </img> {file.name}</div>,
                      date: <>{moment(new Date()).format('DD-MM-YYYY')} </>,
                      uploadby: sessionStorage.getItem('user'),
                      mgt: setmgt(count),
                      is_chacge: true
                    })



                    setuploadfilemodel({ ...uploadfilemodel, row: listall })
                    return false;
                  }}>
                    <p className="ant-upload-drag-icon">

                      <img src={uploadicon} style={{ width: '40%' }} />
                    </p>
                    <p className="ant-upload-text" style={{
                      color: '#FFF',
                      fontFamily: 'Oxanium',
                      fontSize: '30px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                    }} >DRAG & DROP FILES HERE</p>
                    <p className="ant-upload-text" style={{
                      color: '#FFF',
                      fontFamily: 'Oxanium',
                      fontSize: '30px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                    }} >OR</p>
                    <p className="ant-upload-text" style={{
                      color: '#FFF',
                      fontFamily: 'Oxanium',
                      fontSize: '24px',
                      fontStyle: 'normal',
                      fontWeight: '700',
                      lineHeight: 'normal',
                      fill: 'linear-gradient(90deg, #051F6C 0%, #16285C 78.79%, #222E51 162.66%)',
                      strokeWidth: '2px',
                      stroke: '#7BD7FF',
                      filter: 'drop-shadow(0px 0px 20px #7BD7FF)',

                    }} >BROWSE FILES</p>


                  </Dragger>



                </div>
                <div className='col-8' style={{ overflow: 'auto', height: '97%', borderTop: '2px solid #fff', padding: 0 }}>

                  <table
                    style={{ border: 'none', borderCollapse: 'separate', borderSpacing: 0 }}
                  >
                    <thead style={{ position: 'sticky', top: 0 }}>
                      <tr />

                      {uploadfilemodel.head.map((head) => {
                        return (
                          <>
                            <th style={{ backgroundColor: '#000000', fontSize: '16px', textAlign: 'center', height: '55px', borderBottom: '2px solid #fff' }}>{head.label}</th>
                          </>
                        );
                      })}



                    </thead>
                    <tbody >
                      {uploadfilemodel.row.map((data_) => {



                        return (
                          <>
                            <tr className='ontableversoin'>
                              {uploadfilemodel.head.map((head, i) => {

                                return (
                                  <>
                                    <td style={{ textAlign: 'left', height: '55px', fontSize: '16px', textAlign: `${head.field === 'filename' ? 'left' : 'center'}`, paddingLeft: '10px', paddingRight: '10px', width: `${head.field === 'filename' ? '350px' : 'auto'}` }} >{data_[head.field]}</td>
                                  </>
                                )





                              })}
                            </tr>
                          </>
                        );
                      })}
                    </tbody>
                    <tbody>
                    </tbody>
                  </table>






                </div>
              </div>


              <div style={{ position: 'absolute', bottom: '15px', right: '15px' }}>
                <button className='mr-2  btn-outline-primary' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => { saveupload(); }}> Save </button> <button className=' btn-outline-danger' style={{ padding: '5px', paddingLeft: '15px', paddingRight: '15px' }} onClick={() => {

                  setuploadfilemodel({ ...uploadfilemodel, row: [] })
                  toggle_modal_modelversion();
                }}> Cancel </button>
              </div>




            </div>

          </div>
          :
          <>
          </>
        }


        <div className={`backdropModuleMenu ${isOpen ? 'opened' : 'closed'}`} onClick={() => { toggle_modal_config_module(); }}>      </div>
        <div className={`detailBoxModuleMenu ${isOpen ? 'opened' : 'closed'}`} style={{/* top:`10%`, */
       
       left: `${switModeShowDisplay === true ? '0%' : '50%'}`,
       position: `${switModeShowDisplay === true ? 'absolute' : 'fixed'}`,
       transform: `${switModeShowDisplay === true ? '' : 'translate(-50%, -14%)'}`,
       width: `${switModeShowDisplay === true ? '100%' : '80%'}`,
       height: `${switModeShowDisplay === true ? '83vh' : '80%'}`,
       top: `${switModeShowDisplay === true ? '4%' : ''}`,
       paddingLeft: '20px',
       paddingRight: '20px',
       }}


        >

          {/*        {isOpen && (<> */}

          <div className='row' style={{ height: '100%', margin: 0, padding: 0 }} >

            <div className='col-12' style={{ height: '3%' }}><h4>Config Module</h4></div>
            <div className='col-12 col-md-4 col-xl-5' style={{ height: '7%' }}>
              Module Name<font color="red">*</font> <font className='ml-4' color="red">{errorMessage}</font>
              <Input style={{ backgroundColor: '#000' }} type='text' placeholder='Module Name...'
                value={ModuleDetail.module_name || ''}
                onChange={(e) => {
                  setErrorMessage('');
                  setModuleDetail({ ...ModuleDetail, module_name: e.target.value })
                }}

              />
            </div>
            <div className='col-12 col-md-4 col-xl-3' style={{ height: '7%' }}>
              Module Period{/* <font color ="red">*</font> */} {/* <font className='ml-4' color ="red">{errorMessage}</font> */}<br />
              <RangePicker format="DD-MM-YYYY" value={[ModuleDetail.module_start_date ? dayjs(ModuleDetail.module_start_date) : '', ModuleDetail.module_end_date ? dayjs(ModuleDetail.module_end_date) : '']}
                onChange={(date) => {
                  if (date && date.length == 2) {
                    setModuleDetail({ ...ModuleDetail, module_start_date: new Date(date[0]), module_end_date: new Date(date[1]) })
                    let tempData = ModuleDetail;
                    let start = parseInt(moment(new Date(date[0])).format("YYYYMMDD") || 0);
                    let end = parseInt(moment(new Date(date[1])).format("YYYYMMDD") || 0);
                    for (var i = 0; i < tempData.menuList.length; i++) {
                      //console.log((parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0),'////', start))
                      if (parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0) < start || parseInt(moment(tempData.menuList[i].menu_end_date).format('YYYYMMDD') || 0) > end) {
                        tempData.menuList[i].errorMessageDate = 'Date Must In Range Module Period !!';
                        //console.log("error DAte")
                      } else {
                        tempData.menuList[i].errorMessageDate = '';
                      }
                    }
                  } else {
                    setModuleDetail({ ...ModuleDetail, module_start_date: null, module_end_date: null })
                    let tempData = ModuleDetail;
                    let start = null; //parseInt(moment(new Date(date[0])).format("YYYYMMDD") || 0);
                    let end = null; //parseInt(moment(new Date(date[1])).format("YYYYMMDD") || 0);
                    for (var i = 0; i < tempData.menuList.length; i++) {
                      //console.log((parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0),'////', start))
                      if (parseInt(moment(tempData.menuList[i].menu_start_date).format('YYYYMMDD') || 0) < start || parseInt(moment(tempData.menuList[i].menu_end_date).format('YYYYMMDD') || 0) > end) {
                        tempData.menuList[i].errorMessageDate = 'Date Must In Range Module Period !!';
                        //console.log("error DAte")
                      } else {
                        tempData.menuList[i].errorMessageDate = '';
                      }
                    }
                  }
                }}
                style={{ width: '100%' }}
              />
            </div>
            <div className='pl-0 box-font col-10 col-md-3 col-xl-3'>
              <div className='boxupload' onClick={() => { getuploadfile() }}>
                UPLOAD WORK FLOW
              </div>
              <div className='ml-2 boxupload' onClick={() => toggle_modalSdd()}>
                UPLOAD SDD
              </div>
            </div>
            <div className='pl-0 box-font col-2 col-md-1 col-xl-1'>
              <div className='boxupload' style={{ width: '40px' }} onClick={() => { /* removeModule() */toggle_modal_confirm2() }}>
                <i class='fa fa-trash' style={{ cursor: 'pointer' }}></i>

              </div>

            </div>
            <div className='col-12' style={{ height: '5%' }}>
              <hr />
            </div>

            <div className='col-12' style={{ height: '85%', whiteSpace: 'nowrap' }} >
              <h5 className='ml-4' style={{ display: 'flex', flexFlow: 'nowrap', marginTop: '10px' }}>Menu In Module
                <a className=' ImgmenuHover BoxIconMenuType' style={{ width: '110px', padding: '5px', marginTop: '-7px', marginLeft: '10px' }} onClick={() => { addMenu(); }} href="javascript:null "><i class="fa fa-plus" aria-hidden="true">Add menu</i></a>
                <div className={`${MenuchcekList.dataselect.filter((e, i) => {
                  if (i === 0) {
                    return e.menu_sort === 1 || e.menu_level === 5
                  }
                  return e.menu_level === 5
                }).length === 0 ? 'BoxIconMenuType' : 'BoxIconMenuTypedis'}`}
                  style={{ width: '35px', marginTop: '-7px', marginLeft: '10px' }}
                  onClick={() => {
                    /*    SelectTypeMenu(e.mt_id, index); */
                 /*    console.log(MenuchcekList.dataselect); */
                    if (MenuchcekList.dataselect.filter((e, i) => {
                      if (i === 0) {
                        return e.menu_sort === 1 || e.menu_level === 5
                      }
                      return e.menu_level === 5
                    }).length === 0) {
                      listchcekboxinMenu()
                    }

                  }}
                >

                  <img
                    /*    className={`ImgmenuHover`} */
                    style={{ width: '25px', height: '25px' }}
                    src={Maskin}
                  />


                </div>
                <div className={`${MenuchcekList.dataselect.filter((e) => { return e.menu_level === 1 }).length === 0 ? 'BoxIconMenuType' : 'BoxIconMenuTypedis'}`}
                  style={{ width: '35px', marginTop: '-7px', marginLeft: '10px' }}
                  onClick={() => {
                    /*    SelectTypeMenu(e.mt_id, index); */
                    if (MenuchcekList.dataselect.filter((e) => { return e.menu_level === 1 }).length === 0) {
                      listchcekboxoutMenu()
                    }

                  }}
                >

                  <img
                    className={`ImgmenuHover`}
                    style={{ width: '25px', height: '25px' }}
                    src={Maskout}
                  />


                </div></h5>

              <div className='table-responsive' style={{ height: '83%', whiteSpace: 'nowrap', width: '100%' }}>

                <div className="row " style={{ textAlign: 'center', padding: '0px', margin: '0px' }}>
                  {/*  <table style={{width:'100%',border:'1px solid red',padding:'2px'}}>
                    <tr>
                      <th style={{textAlign:'center'}}>Type</th>
                      <th style={{textAlign:'center'}}>Menu Name</th>
                      <th style={{textAlign:'center'}}>Menu Remark</th>
                      <th style={{textAlign:'center'}}>Menu Period</th>
                      <th style={{textAlign:'center'}}>Actual Date</th>
                      <th style={{textAlign:'center'}}>Remove</th>
                    </tr>
                    
                  </table>
                  <table style={{width:'100%',border:'1px solid red'}}>
                  {ModuleDetail.menuList && ModuleDetail.menuList.map((menu, index) => {
                    return (<>
                      <tr>
                        <td> {index !== 0 && (<a style={{ cursor: 'pointer' }} onClick={() => { sortUpMenu(index) }}><i class='fas fa-long-arrow-alt-up fa-2xl'></i></a>)}  </td>
                        {index + 1 !== ModuleDetail.menuList.length && (<a style={{ cursor: 'pointer' }} onClick={() => { sortDownMenu(index) }}><i class='fas fa-long-arrow-alt-down fa-2xl'></i></a>)}
                      </tr>
                     </>)
                  })}
           

                  </table> */}
                  <div /* className="col-1" */ style={{ width: '3%', textAlign: 'center' /* ,border:'1px solid red' */ }}> Select</div>
                  <div /* className="col-1" */ style={{ width: '3%', textAlign: 'center' /* ,border:'1px solid red' */ }}> Sort</div>
                  <div /* className="col-1" */ style={{ width: '19%', textAlign: 'center'/* ,border:'1px solid red' */ }}> Type</div>
                  <div /* className="col-2" */ style={{ width: '20%', textAlign: 'center'/* ,border:'1px solid red' */ }}> Menu Name</div>
                  <div /* className="col-2" */ style={{ width: '20%', textAlign: 'center' }}> Menu Description</div>
                  <div /* className="col-2" */ style={{ width: '20%', textAlign: 'center' }}> Menu Period</div>
                  <div /* className="col-2" */ style={{ width: '10%', textAlign: 'center' }}> Actual Date</div>
                  <div /* className="col-2" */ style={{ width: '5%', textAlign: 'center' }}> Remove</div>

                  {ModuleDetail.menuList && ModuleDetail.menuList.map((menu, index) => {
                    menu.index = index
                    /*  console.log(ModuleDetail.menuList.filter((x)=>{return (x.menu_parent_id === menu.menu_parent_id)}).length,index); */
                    return (<>
                      <div style={{ width: '100%', display: 'flex', flexWrap: 'nowrap', backgroundColor: `rgba(123, 215, 255, 0.${42 - (menu.menu_level * 5)})` }}>
                        <div className="mt-2" style={{ width: '3%', textAlign: 'center' }}>
                          {(menu.errorMessage || menu.errorMessageDate || menu.errorMessagetype) && (<br />)}
                          {index > 0 ? <>
                            <Checkbox checked={MenuchcekList.dataselect.findIndex((i) => i.menu_id === menu.menu_id) !== -1 ? true : false}
                              onChange={(e) => {

                                let chcek = MenuchcekList.dataselect.findIndex((i) => i.menu_id === menu.menu_id)
                                if (chcek !== -1) {
                                  let datalist = MenuchcekList.dataselect
                                  let list = mapparent(menu, datalist, 'del')
                                  console.log(list);
                                  list.sort((a, b) => { return a.index - b.index; });
                                  setMenuchcekList({ ...MenuchcekList, dataselect: list })
                                  /*  datalist.splice(chcek, 1)
                                  
                                   setMenuchcekList({ ...MenuchcekList, dataselect: datalist }) */
                                } else {
                                  let datalist = MenuchcekList.dataselect
                                  /*  let datapush = ModuleDetail.menuList.filter((x)=> {return x.menu_parent_id === menu.menu_id})
                                   datapush.push(menu)
                                    datalist.push(...datapush) */
                                  let list = mapparent(menu, datalist, 'add')
                                  list.sort((a, b) => { return a.index - b.index; });
                                  setMenuchcekList({ ...MenuchcekList, dataselect: list })
                                }

                              }}>
                              {/* {menu.menu_sort}-{menu.menu_parent_id} */}
                            </Checkbox>
                          </> : <></>}

                        </div>

                        <div /* className="mt-2 col-1" */ style={{ width: '3%', textAlign: 'center'/* ,border:'1px solid red' */ }}>

                          <table style={{ height: '100%' }}>
                            <tr>
                              {/*   <td style={{ width: '35%' }}></td> */}
                              <td style={{ width: '50%', fontSize: '12px', textAlign: 'right', padding: '4px' }}>
                                {parseFloat(menu.menu_sort) !== 1 && (<a style={{ cursor: 'pointer' }} onClick={() => { sortUpMenu(index) }}><i class='fas fa-long-arrow-alt-up fa-2xl'></i></a>)}
                              </td>
                              <td style={{ width: '50%', fontSize: '12px', textAlign: 'left', padding: '4px' }}>
                                {parseFloat(menu.menu_sort) !== ModuleDetail.menuList.filter((x) => { return (x.menu_level === menu.menu_level && x.menu_parent_id === menu.menu_parent_id) }).length && (<a style={{ cursor: 'pointer' }} onClick={() => { sortDownMenu(index) }}><i class='fas fa-long-arrow-alt-down fa-2xl'></i></a>)}
                              </td>
                              {/*     <td style={{ width: '25%' }}></td> */}
                            </tr>
                          </table>


                        </div>
                        <div className='row boxRowOverflow mt-2'

                          ref={(el) => (divRefs.current[index] = el)}
                          style={{ /* padding: '0px', */ width: `${/* 40 -(menu.menu_level * 5) */19/*  - ((menu.menu_level - 1) * 2.5) */}%`, textAlign: 'center', paddingLeft: `${(menu.menu_level - 1) * 2.5}%`, overflowX: 'auto', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'flex-start' }}>

                          <div>
                            {(menu.errorMessage || menu.errorMessageDate || menu.errorMessagetype) && (<>{menu.errorMessagetype ? (<><font color="red" style={{ fontSize: '12px', position: 'relative', display: 'block', marginTop: '7px' }}>{menu.errorMessagetype}</font></>) : (<><br /></>)}</>)}
                            <div className='divInrow'>

                              {MenutypeList.map((e) => {
                                /*  console.log('Mane',e); */
                                console.log('img', e.mt_img_path);
                                return (<>
                                  &nbsp;
                                  <div className={`BoxIconMenuType${menu.menu_mt_id === e.mt_id ? '-Active' : ''}`}
                                    style={{ width: '100%' }}
                                    onClick={() => {
                                      SelectTypeMenu(e.mt_id, index);

                                    }}
                                  >
                                    <Tooltip title={e.mt_name} color='#051F6C'>
                                      <img
                                        className={`ImgmenuHover${menu.menu_mt_id === e.mt_id ? '-Active' : ''}`}
                                        style={{ width: '25px', height: '25px' }}
                                        src={/* `${Configs.TTT_Game_Service_IMG}${e.mt_img_path}` */e.mt_img_name === "Menu.png" ? menuicon : featureicon}

                                      />
                                    </Tooltip>

                                  </div>
                                  &nbsp;

                                </>)
                              })}
                            </div>

                          </div>
                        </div>
                        <div className="mt-2" style={{ width: '20%', textAlign: 'center'/* ,border:'1px solid red' */ }}>
                          {(menu.errorMessage || menu.errorMessageDate || menu.errorMessagetype) && (<>{menu.errorMessage ? (<><font color="red" style={{ fontSize: '12px', position: 'relative', display: 'block', marginTop: '5px' }}>{menu.errorMessage}</font></>) : (<><br /></>)}</>)}
                          <Input style={{ backgroundColor: '#000', width: '95%' }} type='text' placeholder='Menu Name...' value={menu.menu_name} onChange={(e) => {



                            changeMenuValue('menu_name', e.target.value, menu.menu_id);
                          }} />
                        </div>

                        <div className="mt-2" style={{ width: '20%', textAlign: 'center' }}>
                          {(menu.errorMessage || menu.errorMessageDate || menu.errorMessagetype) && (<>{menu.errorMessageDate ? (<><font color="red" style={{ fontSize: '12px', position: 'relative', display: 'block', marginTop: '5px', visibility: 'hidden' }}>{menu.errorMessageDate}</font></>) : (<><br /></>)}</>)}
                          {/*   <Input style={{ backgroundColor: '#000', width: '95%' }} type='text' placeholder='Menu Description...' value={menu.menu_remark} onChange={(e) => {
                          changeMenuValue('menu_remark', e.target.value, menu.menu_id);
                        }} /> */}
                          <TextArea
                            /*       className="areaTEst rezieznone" */
                            style={{ backgroundColor: '#000', width: '95%' }}
                            autoSize={{
                              minRows: 1
                            }}
                            type='text'
                            placeholder='Menu Description...'
                            value={menu.menu_remark} onChange={(e) => {
                              changeMenuValue('menu_remark', e.target.value, menu.menu_id);
                            }}
                          />
                        </div>
                        <div className="mt-2" style={{ width: '20%', textAlign: 'center' }}>
                          {(menu.errorMessage || menu.errorMessageDate || menu.errorMessagetype) && (<>{menu.errorMessageDate ? (<><font color="red" style={{ fontSize: '12px', position: 'relative', display: 'block', marginTop: '5px', visibility: '' }}> {menu.errorMessageDate}</font></>) : (<><br /></>)}</>)}
                          <RangePicker format="DD-MM-YYYY"
                            disabledDate={(current) => {
                              let start = moment(ModuleDetail.module_start_date).format("YYYY-MM-DD");
                              let end = moment(ModuleDetail.module_end_date).add(1, 'days').format("YYYY-MM-DD");
                              // console.log('current',moment(end, "YYYY-MM-DD"))
                              return current && current < moment(start, "YYYY-MM-DD") || current > moment(end, "YYYY-MM-DD");
                            }}
                            value={[menu.menu_start_date ? dayjs(menu.menu_start_date) : '', menu.menu_end_date ? dayjs(menu.menu_end_date) : '']}
                            onChange={(date) => {
                              if (date && date.length == 2) {

                                changeMenuValue('menu_start_stop_date', date, menu.menu_id);

                              } else {
                                console.log('date', date)
                                changeMenuValue('menu_start_stop_date', date, menu.menu_id);
                              }
                            }}

                          /></div>
                        <div className="mt-2" style={{ width: '10%', textAlign: 'center' }}>
                          {(menu.errorMessage || menu.errorMessageDate || menu.errorMessagetype) && (<br />)}
                          <DatePicker allowClear={false}
                            format="DD-MM-YYYY"
                            value={menu.menu_actual_date ? dayjs(menu.menu_actual_date) : ''}
                            onChange={(date) => {
                              changeMenuValue('menu_actual_date', new Date(date), menu.menu_id);
                            }}
                          /></div>
                        <div className="mt-2" style={{ width: '5%', textAlign: 'center' }}>
                          {ModuleDetail.menuList.filter((x) => { return (x.menu_parent_id === menu.menu_id) }).length === 0 ? <>
                            {(menu.errorMessage || menu.errorMessageDate || menu.errorMessagetype) && (<br />)}
                            <i class='fa fa-trash' style={{ cursor: 'pointer' }} onClick={() => { toggle_modal_confirm(); setMenuIndex(index) }}></i>

                          </> : <></>}
                        </div>

                      </div>
                    </>)
                  })}





                </div>

              </div>

            </div>

          </div>

          <br /><br />
          <div style={{ position: 'absolute', bottom: '5px', right: '5px' }}>
            <button className='mr-2  btn-outline-primary' style={{ padding: '5px' }} onClick={() => { saveConfigModule(); }}> Save </button> <button className=' btn-outline-danger' style={{ padding: '5px' }} onClick={() => { toggle_modal_config_module(); }}> Cancel </button>
          </div>



        </div>

        {loading && (
          <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
            <div className='alertKickBoxShadow'>
              <div className='alertKickBox' style={{ height: 'fit-content' }}>
                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                  <div className='row' style={{ height: '25%' }}>
                    <div className='alertKickHead' style={{ color: 'green' }}>
                      Loading
                    </div>
                  </div>
                  <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                    <div className='alertKickWarning' style={{ border: 'none' }}>
                      <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}



      </ConfigProvider>

      <ModalConfirm isOpen={modalConfirm} toggle_modal_confirm={toggle_modal_confirm} removeMenu={removeMenu} index={menuIndex} />
      <ModalConfirm isOpen={modalConfirm2} toggle_modal_confirm={toggle_modal_confirm2} removeMenu={removeModule} />

      <ModalSdd
        isOpen={modalSdd}
        toggle_modalSdd={toggle_modalSdd}
        ModuleDetails={ModuleDetails}
        project_id={project_id}
      />
      {/*     </>
    
  )} */}
    </>
  )


}
export default ModalConfigModule;


