
import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import moment from "moment";
import Configs from "../../config";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import SpaceShipLogo from './Image/spaceshipLogo.png';
import borderImg from './Image/borderImg.png';
import rocket from './Image/rocket.png';
import defaultImg from './Image/defultUser.png';
import defcon1 from './Image/defcon1.png';
import defcon2 from './Image/defcon2.png';
import defcon3 from './Image/defcon3.png';
import defcon4 from './Image/defcon4.png';
import defcon5 from './Image/defcon5.png';
import './style.css';
import { io } from 'socket.io-client';

function CardFedSpaceShipList({ mode, setmode, fedSpaceShipId, setFedSpaceShipId }) {

    const [test, setTest] = useState({
        test: ''
    });

    const [search, setSearch] = useState({
        text: '',
    });

    const [fedSpaceShipData, setFedSpaceShipData] = useState({
        federation_name: ''
    });

    const [fedSpaceShipCount, setFedSpaceShipCount] = useState(0);

    const [fedSpaceShipListData, setFedSpaceShipList] = useState([]);

    async function GetAccesstokenToken() {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    async function getFedSpaceShipListData() {
        let temp = {
            id: sessionStorage.getItem('fed_id'),
            search: search.text || ''
        };
        await axios({
            method: 'POST',
            url: `${Configs.TTT_Game_Service}/api/federation/getFedSpaceShipListData`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setFedSpaceShipList(response.data.spaceship);
                    setFedSpaceShipData(response.data.federation);
                    setFedSpaceShipCount(response.data.spaceshipCount);

                    if (response.data.spaceship.length > 0) {
                        for (let item of response.data.spaceship) {
                            if (item.fd_img_id_spaceship) {
                                let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id_spaceship}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });
                    
                                item.url = URL.createObjectURL(response2.data);
                            } else if (item.fd_img_id_model) {
                                let response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id_model}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });
                    
                                item.url = URL.createObjectURL(response2.data);
                            }
                            setFedSpaceShipList(response.data.spaceship);
                            setTest({...test, test: ''});
                            test.test = '';
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    };

    useEffect(() => {
        GetAccesstokenToken();
        getFedSpaceShipListData();
    }, [sessionStorage.getItem('fed_id')]);

    const socketRef = useRef(null);
    useEffect(() => {
  
        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                port:Configs.TTT_Game_Socket_Service_Port
              });
        }
    }, [
  
    ])
  
  
    useEffect(() => {
     
      
      const { current: socket } = socketRef;
      const socFnc = (data) => {}
        try {
          socket.open();
          socket.on('locationElement', socFnc)
        } catch (error) {
          console.log(error);
        }
        
      }, []);

    return (
        <>
            <div style={{ position: 'absolute', border: '2px solid #85BFFF', width: '90%', height: 'calc(100% - 325px)', top: '150px', left: '5%', padding: '1% 2%', overflow: 'auto' }}>
                <div className='row' style={{ position: 'relative', margin: 0, padding: '0 15px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <div style={{ position: 'relative', width: '50px', height: '50px', border: '2px solid #85BFFF', borderRadius: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#85BFFF', fontWeight: 700, fontSize: '25px', cursor: 'pointer' }} onClick={() => {
                            // if(sessionStorage.getItem('project_id')){
                            //     socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'ProjectMonitoring', project_id: sessionStorage.getItem('project_id')});
                            // }else{
                                sessionStorage.setItem('pageNow', 'StageMonitoring')
                                sessionStorage.removeItem('project_id')
                                socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'StageMonitoring'});
                            // }
                        setmode({ ...mode, mode: 'all' });
                    }}>{'<'}</div>
                    <span style={{ marginLeft: '25px' }}>
                        <div style={{ fontSize: '45px', fontWeight: 700, lineHeight: 0.8, textTransform: 'uppercase', maxWidth: '600px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{fedSpaceShipData.federation_name}</div>
                        <div style={{ fontSize: '14px', fontWeight: 700, lineHeight: 1 }}>FEDERATION</div>
                    </span>
                    <span style={{ marginLeft: '25px', height: '50px', filter: 'drop-shadow(0 0 10px rgba(255,255,255,0.25))' }}>
                        <div style={{ width: '250px', height: '100%', border: '2px solid #0047FF', clipPath: 'polygon(calc(0% + 15px) 0, 100% 0, 100% calc(100% - 15px), calc(100% - 15px) 100%, 0 100%, 0 calc(0% + 15px))', position: 'relative', background: 'linear-gradient(90deg, rgba(5,31,108,1) 0%, rgba(22,40,92,1) 50%, rgba(34,46,81,1) 100%)' }}>
                            <svg height="100%" width="100%" style={{ position: 'absolute' }}>
                                <line x1="0" y1="calc(0% + 13px)" x2="calc(0% + 13px)" y2="0" style={{ stroke: '#0047FF', strokeWidth: 2 }} />
                                <line x1="100%" y1="calc(100% - 13px)" x2="calc(100% - 13px)" y2="100%" style={{ stroke: '#0047FF', strokeWidth: 2 }} />
                            </svg>
                            <input
                                className='fontStyle inputPlaceholder'
                                style={{ width: '100%', height: '100%', background: 'transparent', padding: '0 15px', fontSize: '18px', border: 'none', color: 'white', position: 'relative', zIndex: 1 }}
                                placeholder='search...'
                                value={search.text}
                                onChange={(e) => {
                                    setSearch({ ...search, text: e.target.value });
                                }}
                                onKeyDown={(e) => {
                                    if (e.keyCode == 13) {
                                        GetAccesstokenToken();
                                        getFedSpaceShipListData();
                                        setSearch({ ...search, text: '' });
                                    }
                                }}
                            />
                        </div>
                    </span>
                    <span style={{ marginLeft: '10px', height: '50px' }}>
                        <div style={{ width: '50px', height: '100%', border: '2px solid #0047FF', borderRadius: '5px', position: 'relative', cursor: 'pointer', zIndex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => {
                            GetAccesstokenToken();
                            getFedSpaceShipListData();
                            setSearch({ ...search, text: '' });
                        }}>
                            <i class="fa fa-search" aria-hidden="true" style={{ fontSize: '22px', color: '#0047FF' }}></i>
                        </div>
                    </span>
                    <span style={{ height: '50px', position: 'absolute', right: '1%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '10px' }}>
                        <img src={rocket} alt="" style={{ height: '60%', transform: 'rotate(-90deg)' }} />
                        <div className='fontOxanium' style={{ fontSize: '25px', color: 'white', fontWeight: 700, marginTop: '2.5px' }}>{fedSpaceShipCount || 0}</div>
                    </span>
                </div>
                <div style={{ borderTop: '1px solid #85BFFF', margin: '10px 0 0 0', padding: '15px', /* width: '1650px', */ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', /* justifyContent: 'space-between', */ gap: '25px 25px' }}>
                    {fedSpaceShipListData && fedSpaceShipListData.length > 0 ? fedSpaceShipListData.map((e) => {
                        return (<>
                            <div className='fedSpaceShipListCard' style={{ width: '300px', height: '475px', position: 'relative', cursor: 'pointer' }} onClick={() => {
                                setmode({ ...mode, mode: 'fedSpaceShip', old: mode.mode });
                                mode.old = mode.mode;
                                mode.mode = 'fedSpaceShip';
                                setFedSpaceShipId({ ...fedSpaceShipId, id: e.spaceship_id });
                            }}>
                                <img src={e.url ? e.url : ''} alt="" style={{ position: 'absolute', width: '82%', height: '72.5%', top: '7%', left: '10%', clipPath: 'polygon(0 0, 100% 0, 100% 88.75%, 77.5% 88.75%, 60% 100%, 0 100%)' }} />

                                <div className='hoverBlack fontStyle' style={{ position: 'absolute', width: '82%', height: '72.5%', top: '7%', left: '10%', clipPath: 'polygon(0 0, 100% 0, 100% 88.75%, 77.5% 88.75%, 60% 100%, 0 100%)', background: 'linear-gradient(180deg, rgba(0,0,0,0) 65%, rgba(0,0,0,1) 100%)', color: 'white' }}>
                                    <div style={{ position: 'absolute', bottom: '14%', left: '11%', width: '73%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>NAME: {e.usr_ttt_id} {e.usr_name}</div>
                                    <div style={{ position: 'absolute', bottom: '8%', left: '11%', width: '55%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>PROJECT: {e.project_name}</div>
                                </div>

                                <div style={{ position: 'absolute', width: '147px', height: '90px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(0 5%, 37.5% 5%, 42.5% 0, 90% 0, 100% 25%, 15% 25%, 15% 100%, 0 85%)' }}></div>

                                <div style={{ position: 'absolute', top: 'calc(7% + 5px)', left: 'calc(10% + 7.5px)', width: '40px', height: '40px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(0 0, 80% 0, 100% 15%, 15% 15%, 15% 100%, 0 80%)' }}></div>
                                <div style={{ position: 'absolute', top: 'calc(7% + 5px)', right: 'calc(10% + 5px)', width: '40px', height: '40px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(20% 0, 100% 0, 100% 80%, 85% 100%, 85% 15%, 0 15%)' }}></div>
                                <div style={{ position: 'absolute', bottom: 'calc(29% + 5px)', right: 'calc(10% + 5px)', width: '40px', height: '40px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(85% 85%, 85% 0, 100% 20%, 100% 100%, 20% 100%, 0 85%)' }}></div>
                                <div style={{ position: 'absolute', bottom: 'calc(21% + 5px)', left: 'calc(10% + 7.5px)', width: '40px', height: '40px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(15% 0, 15% 85%, 100% 85%, 80% 100%, 0 100%, 0 20%)' }}></div>

                                <div style={{ position: 'absolute', top: '8%', left: '23.75%', width: 'fit-content', height: '7.5px', display: 'flex' }}>
                                    <div style={{ width: '10px', marginLeft: '0px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', top: '15.5%', left: '12.25%', width: '7.5px', height: 'fit-content' }}>
                                    <div style={{ width: '5px', marginTop: '0px', height: '35px', background: 'white', clipPath: 'polygon(0 0, 100% 20%, 100% 100%, 0 90%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', top: '8%', right: '23.5%', width: 'fit-content', height: '7.5px', display: 'flex' }}>
                                    <div style={{ width: '10px', marginLeft: '0px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', top: '15.25%', right: '10.5%', width: '7.5px', height: 'fit-content' }}>
                                    <div style={{ width: '5px', marginTop: '0px', height: '35px', background: 'white', clipPath: 'polygon(0 20%, 100% 0, 100% 90%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', bottom: '29.25%', right: '23%', width: 'fit-content', height: '7.5px', display: 'flex' }}>
                                    <div style={{ width: '10px', marginLeft: '0px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', bottom: '37%', right: '10.5%', width: '7.5px', height: 'fit-content' }}>
                                    <div style={{ width: '5px', marginTop: '0px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 0, 100% 50%, 100% 100%, 0 50%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '35px', background: 'white', clipPath: 'polygon(0 0, 100% 10%, 100% 100%, 0 80%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', bottom: '21.5%', left: '23.5%', width: 'fit-content', height: '7.5px', display: 'flex' }}>
                                    <div style={{ width: '10px', marginLeft: '0px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                    <div style={{ width: '10px', marginLeft: '-4px', height: '5px', background: 'white', clipPath: 'polygon(55% 0, 100% 0, 45% 100%, 0% 100%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', bottom: '29%', left: '12.25%', width: '7.5px', height: 'fit-content' }}>
                                    <div style={{ width: '5px', marginTop: '0px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '7.5px', background: 'white', clipPath: 'polygon(0 50%, 100% 0, 100% 50%, 0% 100%)' }}></div>
                                    <div style={{ width: '5px', marginTop: '-1px', height: '35px', background: 'white', clipPath: 'polygon(0 10%, 100% 0, 100% 80%, 0% 100%)' }}></div>
                                </div>

                                <svg height="82.5%" width="100%" style={{ position: 'absolute' }}>
                                    <line x1="0" y1="10" x2="100%" y2="10" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="0" y1="21" x2="96%" y2="21" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="10%" y1="31" x2="92%" y2="31" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="99.75%" y1="10" x2="99.75%" y2="30%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="99.75%" y1="30%" x2="95.75%" y2="35%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="95.75%" y1="21" x2="95.75%" y2="89.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="91.75%" y1="31" x2="91.75%" y2="86.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="73.75%" y1="86.5%" x2="91.75%" y2="86.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="75.75%" y1="89.5%" x2="95.75%" y2="89.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="73.75%" y1="86.5%" x2="59.75%" y2="96.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="75.75%" y1="89.5%" x2="61.75%" y2="99.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="10%" y1="96.5%" x2="59.75%" y2="96.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="6.9%" y1="99.5%" x2="61.75%" y2="99.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="6.9%" y1="10%" x2="6.9%" y2="99.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="10%" y1="31" x2="10%" y2="96.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="14.5%" y1="11%" x2="14.5%" y2="36%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="14.5%" y1="11%" x2="34.5%" y2="11%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="16.5%" y1="13%" x2="calc(16.5% + 10px)" y2="13%" style={{ stroke: 'white', strokeWidth: 1 }} />
                                    <line x1="16.5%" y1="13%" x2="16.5%" y2="calc(13% + 10px)" style={{ stroke: 'white', strokeWidth: 1 }} />

                                    <line x1="66.5%" y1="11%" x2="86.5%" y2="11%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="86.5%" y1="11%" x2="86.5%" y2="36%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="84.5%" y1="13%" x2="calc(84.5% - 10px)" y2="13%" style={{ stroke: 'white', strokeWidth: 1 }} />
                                    <line x1="84.5%" y1="13%" x2="84.5%" y2="calc(13% + 10px)" style={{ stroke: 'white', strokeWidth: 1 }} />

                                    <line x1="66%" y1="83.5%" x2="86.5%" y2="83.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="86.5%" y1="83.5%" x2="86.5%" y2="58.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="84.5%" y1="82%" x2="calc(84.5% - 10px)" y2="82%" style={{ stroke: 'white', strokeWidth: 1 }} />
                                    <line x1="84.5%" y1="82%" x2="84.5%" y2="calc(82% - 10px)" style={{ stroke: 'white', strokeWidth: 1 }} />

                                    <line x1="14.5%" y1="93%" x2="14.5%" y2="68%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="14.5%" y1="93%" x2="34.5%" y2="93%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />

                                    <line x1="16.5%" y1="91.5%" x2="calc(16.5% + 10px)" y2="91.5%" style={{ stroke: 'white', strokeWidth: 1 }} />
                                    <line x1="16.5%" y1="91.5%" x2="16.5%" y2="calc(91.5% - 10px)" style={{ stroke: 'white', strokeWidth: 1 }} />
                                </svg>

                                <div className='fontStyle' style={{ position: 'absolute', bottom: '17.75%', right: '4%', width: '87.5px', height: '30px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(37.5% 0%, 100% 0, 100% 100%, 0% 100%)', padding: '2.5px 5px 5px 30px', fontSize: '18px', color: 'white', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textDecoration: 'uppercase' }}>{e.pros_name}</div>

                                <div style={{ position: 'absolute', top: '83%', right: '2.75%', width: '40px', height: '25px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(20% 0, 100% 0, 100% 65%, 85% 100%, 85% 25%, 0 25%)' }}></div>

                                <svg height="17.5%" width="100%" style={{ position: 'absolute', top: '82.5%', width: '100%', height: '17.5%' }}>
                                    <line x1="17.5%" y1="10%" x2="96%" y2="10%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="19%" y1="16.5%" x2="94%" y2="16.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />

                                    <line x1="97%" y1="10%" x2="97%" y2="60%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />
                                    <line x1="95.5%" y1="10%" x2="95.5%" y2="58%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="94%" y1="16.5%" x2="94%" y2="56%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />

                                    <line x1="97%" y1="60%" x2="85%" y2="86%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />
                                    <line x1="95.5%" y1="58%" x2="84%" y2="82%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="94%" y1="56%" x2="83%" y2="78%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />

                                    <line x1="85%" y1="87%" x2="50%" y2="87%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />
                                    <line x1="85%" y1="82%" x2="7.5%" y2="82%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="84%" y1="77%" x2="9.5%" y2="77%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />

                                    <line x1="7.5%" y1="82%" x2="7.5%" y2="40%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="9.5%" y1="77%" x2="9.5%" y2="44%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />

                                    <line x1="7.5%" y1="40%" x2="17.5%" y2="10%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 3 }} />
                                    <line x1="9.5%" y1="44%" x2="19%" y2="16.5%" style={{ stroke: e.smo_color ? e.smo_color : '#0047FF', strokeWidth: 1 }} />
                                </svg>

                                <div style={{ position: 'absolute', top: '84%', left: '7%', width: '27px', height: '22px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(65% 0, 100% 0, 0 100%, 0 65%)' }}></div>

                                <div style={{ position: 'absolute', top: '96.75%', left: '14%', width: 'fit-content', height: '15px', display: 'flex' }}>
                                    <div style={{ width: '15px', marginLeft: '0px', height: '15px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '15px', marginLeft: '-6px', height: '15px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                    <div style={{ width: '15px', marginLeft: '-6px', height: '15px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(0 0, 45% 0, 100% 100%, 55% 100%)' }}></div>
                                </div>

                                <div style={{ position: 'absolute', top: '96.75%', left: '24%', width: '125px', height: '15px', background: e.smo_color ? e.smo_color : '#0047FF', clipPath: 'polygon(0 0, 100% 0, 95% 50%, 70% 50%, 65% 100%, 7% 100%)' }}></div>
                                <div className='fontStyle' style={{ position: 'absolute', top: '85%', left: '17.5%', width: '68%', height: '11%', padding: '8px 0', fontSize: '26px', color: 'white', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', textAlign: 'center' }}>{e.spaceship_name}</div>
                            </div>
                        </>)
                    }) : (<>
                        <h1 style={{ margin: '25px', fontWeight: 700 }}>Don't have any spaceship.</h1>
                    </>)}
                </div>
            </div>
        </>
    );
}

export default CardFedSpaceShipList;
