import React, { useEffect, useState, useRef } from 'react'
import "./style.css"




import axios from "axios";
import { DatePicker, Input, ConfigProvider, Upload } from 'antd';
import {

    VideoCameraAddOutlined,
    VideoCameraOutlined,
    SearchOutlined,
    LoadingOutlined,
    CheckCircleOutlined,
    CloseOutlined

} from '@ant-design/icons';
import moment from 'moment';
import dayjs from 'dayjs';
import Configs from "../../../config";

import ComeBack from './Image/ComeBack.png';
import ComeBackActive from './Image/ComeBackActive.png';
import DraftTOR from './Image/DraftTOR.png';
import DraftTORActive from './Image/DraftTORActive.png';
import Due from './Image/Due.png';
import DueActive from './Image/DueActive.png';
import Finish from './Image/Finish.png';
import FinishActive from './Image/FinishActive.png';
import Launch from './Image/Launch.png';
import LaunchActive from './Image/LaunchActive.png';
import MissionDone from './Image/MissionDone.png';
import MissionDoneActive from './Image/MissionDoneActive.png';
import Ready from './Image/Ready.png';
import ReadyActive from './Image/ReadyActive.png';
import SetupTeam from './Image/SetupTeam.png';
import SetupTeamActive from './Image/SetupTeamActive.png';
import WaitForLaunch from './Image/WaitForLaunch.png';
import WaitForLaunchActive from './Image/WaitForLaunchActive.png';
import onSpace from './Image/onSpace.png';
import onSpaceActive from './Image/onSpaceActive.png';

import uploadicon from '../../../assets/img/icon/upload.png';
import backIcon from './Image/Icon.png'


const { Dragger } = Upload;

const { RangePicker } = DatePicker;
const { TextArea } = Input;

function VideoTimeline(params) {
    const id = params.project_id
    
    const [projectTimelineDataResult, setProjectTimelineDataResult] = useState([]);
    const [projectTimelineData, setProjectTimelineData] = useState([]);
    const [filterTimeline, setFilterTimeline] = useState({
        search: '',
        start_date: '',
        end_date: ''
    })
    const [fileVideoImport, setFileVideoImport] = useState();
    const [textVideo, setTextVideo] = useState(null)
    const [activeFile, setActiveFile] = useState(null);

    const [mode, setMode] = useState('timeline');


    const [addTextVideo, setAddTextVideo] = useState('');

    const [loading,setIsLoading] = useState(false)
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [errorMasgerList, setErrorMasgerList] = useState({
        isError: false,
        massger: []
      })
    

    const [isLoadingFile, setIsLoadingFile] = useState({
        isLoad: false
    });
    const [fileAddVideo, setFileAddVideo] = useState([]);
    const [test, settest] = useState({
        test: ''
    })

    const [fileUploadList, setFileUploadList] = useState([]);
    const [sendData, setSendData] = useState({text: ''});
    const [saveVideoFile, setSaveVideoFile] = useState([]);
    const [videoFile, setVideoFile] = useState([]);

    async function CheckAndCreateFolderBox(project_id, folder_name, ref_id, callback) {
        let temp = {
            folderLis: ["PROJECT", `PROJECT_${project_id}`, "PROJECT VIDEO", folder_name]
        }
        console.log('PROJECT_ID', id);
        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            }, data: temp,
        })
            .then(async function (response) {
                if (response.data) {
                    let folder_id = response.data.folder_id;
                    if (callback) {
                        let res = {
                            ref_id: ref_id,
                            folder_id: folder_id,
                            folder_name: folder_name,
                        }
                        callback(res)

                    }
                    /* UploadFiles(folder_id,folder_name,fed_id,file); */
                }

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function UploadFiles(folder_id, key, ref_id, file, callback) {
        let image_id_list = fileUploadList;
        let dataVideo = new FormData();


        file.forEach((item, index) => {
            if (item.is_chacge) {
                if (item.file) {
                    dataVideo.append('File', item.file);
                }

            }

        });
        dataVideo.append('folder_id', folder_id);
        dataVideo.append('key', key);
        dataVideo.append('image_old_list', JSON.stringify(image_id_list));
        dataVideo.append('ref_id', id);


        await axios({
            method: "POST",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "multipart/form-data",
            }, data: dataVideo,
        })
            .then(async function (response) {
                let temp = fileUploadList || [];
                for (let item of response.data.image_id_list) {
                    temp.push(item);
                }
                setFileUploadList(temp);
                console.log('test22222', fileUploadList);
                settest({ ...test, test: '' });
                test.test = '';
                if (callback) {
                    callback(true)
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    
    async function SaveVideoFile2Text() {
        setIsLoading(true)
        await CheckAndCreateFolderBox(id, 'PROJECT KICK-OFF', id, async (result) => {
            if (result) {
                UploadFiles(result.folder_id, 'PROJECT TIMELINE', result.ref_id, fileAddVideo, async (result2) => {
                    await axios({
                        method: "POST",
                        url: `${Configs.TTT_Game_Service}/api/project/SaveVideoFile2Text`,
                        headers: {
                            Authorization: sessionStorage.getItem('access_token'),
                            "X-TTT": `${Configs.API_TTT}`,
                            "Content-Type": "application/json",
                        },
                        data: {
                            folder_id: result.folder_id,
                            project_id: id,
                            file: fileUploadList[fileUploadList.length - 1],
                            text_video: addTextVideo,
                        }
                    })
                        .then(async function (response) {
                        setFilterTimeline({...filterTimeline,search:'',start_date:'',end_date:''});
                        filterTimeline.search = '';
                        filterTimeline.start_date = '';
                        filterTimeline.end_date = '';
                        setFileAddVideo([]);
                        setAddTextVideo('');
                        setTextVideo('')
                        setIsLoading(false)
                        toggle_modal_alertKick()
                        setMode('timeline');
                        filterVideoTimeline();                        
                    })
                        .catch(function (error) {
                            console.log(error);

                        });
                })
            }
        })
    };
    
    async function UploadVideo() {
        let temp  = fileAddVideo
        console.log(fileAddVideo);
        
        const dataFile = new FormData();
        
            for (let i = 0; i < temp.length; i++) {
                console.log(temp[i].file) 
                dataFile.append('Profile', temp[i].file);
            }

        await axios({
            method: "POST",
            url:`${Configs.TTT_Game_Service}/api/project/Video2Text`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                'Content-Type': 'multipart/form-data',
            }, data: dataFile,
        })
            .then(async function (response) {
                let temp = response.data
                setAddTextVideo(temp.api_response.text)
                console.log(temp.api_response.text);

                setIsLoadingFile({ ...isLoadingFile, isLoad: false });

                isLoadingFile.isLoad = false;
            })
            .catch(function (error) {
                console.log(error);
            });
        }

    const images = {
            'fb902c5e-d5c1-46b9-91a5-91aa8172925c': [onSpace,onSpaceActive], 
            'eaf639d3-d443-4313-84a5-f03aa13ac36b': [Launch,LaunchActive], 
            'd736a8bf-0b5c-4b38-88d6-cc8de684d58f': [SetupTeam,SetupTeamActive],
            'cb05f95b-51c9-461e-bb50-66c91644e5d6': [WaitForLaunch,WaitForLaunchActive],
            '8598c755-de86-4a8c-b661-859e453547bc': [ComeBack,ComeBack],
            '7f577a42-41fe-4434-aa4f-b92c3c83e76e': [Finish,FinishActive],
            '7acfb2d4-eb27-406d-a0ff-f4dff01c7460': [DraftTOR,DraftTORActive],
            '798520e8-c54c-4e92-b4bf-012d08d2debb': [Due,DueActive],
            '4d45f176-a656-49d9-89dd-69ccb7422274': [MissionDone,MissionDoneActive],
            '20df99c6-a4b1-4f08-8fe3-52a6f33eea6b': [Ready,ReadyActive], 
            null: [Due,DueActive], 
          };

    function getImageStage(even) {
            return images[even] || Due; 
    }

    function filterVideoTimeline(search) {
        setFileVideoImport();
        axios({
            method: "POST",
            url: `${Configs.TTT_Game_Service}/api/project/filterVideoTimeline`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: {
                project_id: params.project_id,
                search: filterTimeline.search.trim(),
                start_date: filterTimeline.start_date,
                end_date: filterTimeline.end_date
            }
        })
            .then(async function (response) {
                let array = [];
                let tempID = fileUploadList || [];
                let index = 0;
                for (let item of response.data) {
                    let temp = {
                        fileId: item.fd_img_id,
                        date: item.fd_created_date,
                        name: item.usr_name + ' ' + item.usr_lastname,
                        fileName: item.fd_origin_name,
                        userId: item.fd_created_by,
                        boxId: item.fd_box_id,
                        textVideo: item.fd_text_description,
                        fd_id: item.fd_id,
                        isSelect: index == 0 ? true : false,
                        pros_name: item.pros_name,
                        pros_color: item.pros_color,
                        ImgStage : getImageStage(item.pros_id)
                    }
                    tempID.push(temp.fileId)
                    array.push(temp)
                    index++;
                }

                setFileUploadList(tempID);
                setProjectTimelineData(array)
                setProjectTimelineDataResult(array)

                if (array.length > 0) {
                    setVideoImport(array[0].fileId)
                    setTextVideo(array[0].textVideo);
                    setActiveFile(array[0].fd_id)

                }

            })
            .catch(function (error) {
                console.log(error);

            });
    };

    function getVideoTimeline() {
        let temp  = {
            id : id
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/project/getVideoTimeline`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
            data: temp
        })
            .then(async function (response) {
                let array = [];
                let tempID = fileUploadList || [];
                console.log("response.data", response.data);
                for (let item of response.data) {
                    let temp = {
                        fileId: item.fd_img_id,
                        date: item.fd_created_date,
                        name: item.usr_name + ' ' + item.usr_lastname,
                        fileName: item.fd_origin_name,
                        userId: item.fd_created_by,
                        boxId: item.fd_box_id,
                        textVideo: item.fd_text_description
                    }
                    tempID.push(temp.fileId)
                    array.push(temp)
                }
                setFileUploadList(tempID);
                console.log('fileUploadList',fileUploadList);
                setProjectTimelineData(array)
                setProjectTimelineDataResult(array)
                console.log(projectTimelineData);
            })
            .catch(function (error) {
                console.log(error);

            });
    };

    useEffect(() => {
        if (params.videoTimelineIsOpen) {
      /*       getVideoTimeline(); */
      setFilterTimeline({...filterTimeline,search:'',start_date:'',end_date:''});
      filterTimeline.search = '';
      filterTimeline.start_date = '';
      filterTimeline.end_date = '';
            filterVideoTimeline();
            setMode('timeline');
            setFileAddVideo([]);
            setAddTextVideo('');
            setIsLoadingFile({ ...isLoadingFile, isLoad: false });
        };

    }, [params.videoTimelineIsOpen]);

    async function setVideoImport(image_id) {
        setTextVideo();
        setFileVideoImport();
        await axios({
            method: "get",
            url: `https://api.box.com/2.0/files/${image_id}/content`,
            headers: {
                Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            },
            responseType: 'blob',

        })
            .then(async function (response) {
                let link = URL.createObjectURL(response.data);
                setFileVideoImport(link);
                console.log('fileVideoImport', fileVideoImport);
            }).catch((err) => {
                console.log('err', err);
            })
    }


    const props = {

        maxCount: 1,
        showUploadList: false,
        name: 'file',
        accept: ".mp4",
        multiple: false,
        method: 'get',
        headers: {
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
        },

        action: Configs.TTT_Game_Service + '/api/upload/fackFile',
        onChange(info) {
            const { status } = info.file;
            info.status = 'done';
            //console.log('status', status)
            if (status !== 'uploading') {
                //console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                /*  setFileImport(info.file); */

                /* message.success(`${info.file.name} file uploaded successfully.`); */
            } else if (status === 'error') {
                /* message.error(`${info.file.name} file upload failed.`); */
            }
        },
        onDrop(e) {
            //console.log('Dropped files', e.dataTransfer.files);
        },
    };

    return (
        <>
            <div className='fedWorkloadBg' style={{ display: `${params.videoTimelineIsOpen ? '' : 'none'}` }}>
                <div className='row fedWorkloadBackBox' style={{ margin: 0, padding: 0 }}>
                    <div className='fedWorkloadBackButton' onClick={() => {
                        setProjectTimelineDataResult([]);
                        setProjectTimelineData([]);
                        setFileVideoImport();
                        params.toggleVideoTimelineIsOpen();

                        /* params.closeElement(); */
                    }}>
                        <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                    </div>
                    <div className='fedWorkloadBackLine'></div>
                    <div className='fedWorkloadBackNameBox'>
                        <div className='fedWorkloadBackNameHead'>Video Timeline</div>
                        <div className='fedWorkloadBackNameText'>{params.project_name}</div>
                    </div>
                </div>

                <ConfigProvider theme={{
                    token: {

                        colorInfo: 'red',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',

                        colorTextDescription: '#FFFFFF',
                        colorBgContainer: '#001D5F',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#FFFFFF',
                        colorBgBase: '#001D5F',//พื้นหลัง datepicker

                        colorTextQuaternary: 'White',//สีตัวอักษร

                        colorFillTertiary: '#0047FF',//สีตอนเมาสชี้

                        colorTextBase: 'White', // สี scroll bar

                        colorPrimaryBg: '#0047FF',// สีตอนเลือก
                        /*        
                               colorBgLayout:'red',
                               colorFillQuaternary:'red', */


                    }
                }} >
                    {mode == 'timeline' && (<>
                        <div className='fedWorkMonthBody'>


                            <div className='fedWorkMonthFilter'>
                                <div className='row' style={{ width: '50%' }}>

                                    <div className='col-4'>Timeline
                                        <RangePicker className='inputMonthFedWorkMonth'
                                            value={[filterTimeline.start_date ? dayjs(filterTimeline.start_date) : null, filterTimeline.end_date ? dayjs(filterTimeline.end_date) : null]}
                                            allowClear={true}
                                            onChange={(e, e2) => {

                                                console.log(e, e2)
                                                setFilterTimeline({
                                                    ...filterTimeline,
                                                    start_date: e2[0],
                                                    end_date: e2[1]

                                                })


                                            }}
                                        />





                                    </div>
                                    <div className='col-4'>
                                        Search
                                        <Input
                                            value={filterTimeline.search}
                                            style={{ height: '36px' }}
                                            onChange={(value) => {
                                                setFilterTimeline({ ...filterTimeline, search: value.target.value });
                                            }}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <br />
                                        <div className='btnGen' style={{ gap: '5px' }}
                                            onClick={async () => {
                                                filterVideoTimeline(filterTimeline.search);

                                            }}
                                        >
                                            <SearchOutlined /> Search</div>

                                    </div>


                                </div>
                                <div style={{ display: 'flex', gap: '15px' }}>


                                    <div className='btnGen'
                                        style={{ gap: '5px' }}
                                        onClick={async () => {
                                            /*    generateDoc(); */
                                            setFileAddVideo([]);
                                            setAddTextVideo('');
                                            setMode('upload video');

                                        }}
                                    >
                                        <VideoCameraAddOutlined />
                                        Upload Video</div>


                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', height: '80%', gap: '15px' }}>
                            <div style={{ display: 'flex', paddingLeft: '10px', marginTop: '15px', height: '50%', overflowX: 'auto', flexDirection: 'column', alignItems: 'center' }}>
                                {!fileVideoImport ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                    {projectTimelineDataResult.length == 0 ? 'No Video' : 'Loading...'}
                                </div>
                                    : <>
                                        <div style={{ display: 'flex', width: "67%", height: '65%' }}>
                                            <video width='100%' height='100%' controls autoPlay>
                                                <source src={fileVideoImport} type="video/mp4" />
                                            </video>
                                        </div>
                                        <div style={{ display: 'flex', width: '67%' }}>
                                            <div style={{ backgroundColor: 'rgba(0, 71, 255, 1)', padding: '5px', borderRadius: '5px 5px 0px 0px' }}>Description</div>

                                        </div>

                                        <div style={{ border: '#0047FF solid 1px', height: '25%', width: '67%', overflow: 'auto' }}>
                                            <div style={{ padding: '10px 10px 0px 10px' }}>{textVideo}</div>
                                        </div>
                                    </>
                                }
                            </div>

                            <div style={{ display: 'flex', borderBottom: '1px solid #0047FF' }}>
                                <div style={{ backgroundColor: 'rgba(0, 71, 255, 1)', padding: '5px', borderRadius: '5px 5px 0px 0px' }}>
                                    <VideoCameraOutlined /> Video Timeline
                                </div>
                            </div>

                            <div className ='videotimelinScroll' style={{ display: 'flex', paddingLeft: '10px', height: '50%', overflowX: 'auto',overflowY:'hidden',scrollbarWidth:'thin' 



}}>


                                {projectTimelineDataResult.map((e, i) => {
                                    // if (e.state == '1') {
                                    //     return <>
                                    //         <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: `${i == 0 ? '50px' : '0'}` }}>
                                    //             <div style={{ color: '#0047FF', fontSize: '16px', fontWeight: 600, width: '250px' }}>KICK-OFF</div>
                                    //             <div style={{ color: '#FFF', fontSize: '16px', fontWeight: 600, width: '250px', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.name}</div>
                                    //             <div className='kickoffTimelineBodyImg' style={{ backgroundImage: `url(${e.isSelect ? stateKickoffActive : stateKickoff})` }} >
                                    //                 <div className='kickoffTimelineBodyImgButton' onClick={(ev) => {
                                    //                     /*       toggle_modeTimeline();
                                    //                           toggle_modeVideoView(); */

                                    //                     setActiveFile(e.fd_id);
                                    //                     let temp = projectTimelineDataResult;
                                    //                     for (let item of temp) {
                                    //                         item.isSelect = false;
                                    //                     }

                                    //                     let index = temp.findIndex((e2) => e2.fd_id == e.fd_id);
                                    //                     if (index > -1) {
                                    //                         temp[index].isSelect = true;
                                    //                     }
                                    //                     setProjectTimelineDataResult(temp);
                                    //                     setVideoImport(e.fileId);

                                    //                     setTextVideo(e.textVideo);
                                    //                     settest({...test,test:''});
                                    //                     test.test = '';
                                    //                 }}></div>
                                    //                 <div style={{ color: '#0047FF', fontSize: '20px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '25px', textTransform: 'uppercase' }}>{moment(e.date).format('D MMM')}</div>
                                    //                 <div style={{ color: '#0047FF', fontSize: '35px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '45px', textTransform: 'uppercase' }}>{moment(e.date).format('YYYY')}</div>
                                    //             </div>

                                    //         </div>
                                    //     </>
                                    // } else if (e.state == '2') {
                                    //     return <>
                                    //         <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: `${i == 0 ? '50px' : '0'}` }}>
                                    //             <div style={{ color: '#0047FF', fontSize: '16px', fontWeight: 600, width: '250px' }}>ON MISSION</div>
                                    //             <div style={{ color: '#FFF', fontSize: '16px', fontWeight: 600, width: '250px', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.name}</div>
                                    //             <div className='kickoffTimelineBodyImg' style={{ backgroundImage: `url(${e.isSelect ? stateKickoffActive : stateKickoff})` }} >
                                    //                 <div className='kickoffTimelineBodyImgButton' onClick={(ev) => {
                                    //                     /*      toggle_modeTimeline();
                                    //                          toggle_modeVideoView(); */
                                    //                     setActiveFile(e.fd_id);
                                    //                     let temp = projectTimelineDataResult;
                                    //                     for (let item of temp) {
                                    //                         item.isSelect = false;
                                    //                     }

                                    //                     let index = temp.findIndex((e2) => e2.fd_id == e.fd_id);
                                    //                     if (index > -1) {
                                    //                         temp[index].isSelect = true;
                                    //                     }
                                    //                     setProjectTimelineDataResult(temp);
                                    //                     setVideoImport(e.fileId);
                                    //                     setTextVideo(e.textVideo);
                                    //                     settest({...test,test:''});
                                    //                     test.test = '';
                                    //                 }}></div>
                                    //                 <div style={{ color: '#0047FF', fontSize: '20px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '25px', textTransform: 'uppercase' }}>{moment(e.date).format('D MMM')}</div>
                                    //                 <div style={{ color: '#0047FF', fontSize: '35px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '45px', textTransform: 'uppercase' }}>{moment(e.date).format('YYYY')}</div>
                                    //             </div>

                                    //         </div>
                                    //     </>
                                    // } else {
                                        return <>
                                            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px', marginLeft: `${i == 0 ? '50px' : '0'}` }}>
                                                <div style={{ color: `${e.pros_color ? e.pros_color : '#0047FF'}`, fontSize: '16px', fontWeight: 600, width: '250px', textTransform: 'uppercase' }}>{e.pros_name ? e.pros_name : 'OTHER'}</div>
                                                <div style={{ color: '#FFF', fontSize: '16px', fontWeight: 600, width: '250px', textTransform: 'uppercase', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{e.name}</div>
                                                <div className='kickoffTimelineBodyImg' style={{ backgroundImage: `url(${e.isSelect ? e.ImgStage[1] : e.ImgStage[0]})` }} >
                                                    <div className='kickoffTimelineBodyImgButton' onClick={(ev) => {
                                                        /*     toggle_modeTimeline();      pros_color
                                                            toggle_modeVideoView(); */
                                                        
                                                        setActiveFile(e.fd_id);
                                                        let temp = projectTimelineDataResult;
                                                        for (let item of temp) {
                                                            item.isSelect = false;
                                                        }

                                                        let index = temp.findIndex((e2) => e2.fd_id == e.fd_id);
                                                        if (index > -1) {
                                                            temp[index].isSelect = true;
                                                        }
                                                    
                                                        setProjectTimelineDataResult(temp);
                                                        setVideoImport(e.fileId);
                                                        setTextVideo(e.textVideo);
                                                        settest({...test,test:''});
                                                        test.test = '';
                                                    }}></div>
                                                    <div style={{ color: `${e.pros_color ? e.pros_color : '#0047FF'}`, fontSize: '20px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '25px', textTransform: 'uppercase' }}>{moment(e.date).format('D MMM')}</div>
                                                    <div style={{ color: `${e.pros_color ? e.pros_color : '#0047FF'}`, fontSize: '35px', fontWeight: 600, position: 'absolute', left: 'calc(0% + 205px)', top: '45px', textTransform: 'uppercase' }}>{moment(e.date).format('YYYY')}</div>
                                                </div>

                                            </div>
                                        </>
                                    // }
                                })}




                            </div>

                        </div>

                    </>)

                    }


                    {mode == 'upload video' && (<>
                        <div className='fedWorkMonthBody' style={{ display: 'flex', flexDirection: 'column', gap: '15px', height: '100%' }}>


                            <div className='fedWorkMonthFilter' style={{ justifyContent: 'flex-end' }}>

                                <div style={{ display: 'flex', gap: '15px' }}>


                                    <div className='btnGen'
                                        style={{ gap: '5px' }}
                                        onClick={async () => {
                                            /*    generateDoc(); */
                                            if(fileAddVideo.length > 0 ){
                                                SaveVideoFile2Text() 
                                            }else{
                                                let temp = {massger: 'Plase input video'}
                                                toggle_modal_alertKick() 
                                                setErrorMasgerList({...errorMasgerList,
                                                    isError: true,
                                                    massger : [{massger:'Video is not defind.'}]
                                                })
                                                console.log(errorMasgerList.massger);
                                                
                                            }
                                        }}
                                    >

                                        Save</div>

                                    <div className='btnGen'
                                        style={{ gap: '5px' }}
                                        onClick={async () => {
                                            /*    generateDoc(); */
                                            setMode('timeline');

                                        }}
                                    >
                                        Cancel
                                    </div>


                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', height: '90%', alignItems: 'center', marginTop: '15px', gap: '20px' }}>
                                <div style={{
                                    display: 'flex', width: '61%', height: '60%'

                                }}>
                                    <div style={{ display: 'flex', width: '100%' }} id='testDiv_'>
                                        <div className='col-12'>
                                            {isLoadingFile.isLoad == true && (<>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '52px' }}>
                                                    <LoadingOutlined />
                                                </div>

                                            </>)}
                                            {isLoadingFile.isLoad == false && fileAddVideo.length > 0 && (<>
                                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', fontSize: '52px', flexDirection: 'column', gap: '10px' }}>
                                                    <CheckCircleOutlined />

                                                    {fileAddVideo.map((e) => {
                                                        return (<>
                                                            <div style={{ display: 'flex', fontSize: '16px', gap: '25px' }}>

                                                                <div>{e.file_name}</div>
                                                                <div><CloseOutlined style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setFileAddVideo([]);
                                                                        setAddTextVideo('');
                                                                    }}
                                                                /></div>
                                                            </div>

                                                        </>)
                                                    })}

                                                </div>

                                            </>)}
                                            {isLoadingFile.isLoad == false && fileAddVideo.length == 0 && (<>
                                                <Dragger {...props}
                                                    style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center' }}
                                                    beforeUpload={(file, fileList) => {
                                                        setIsLoadingFile({ ...isLoadingFile, isLoad: true });

                                                        isLoadingFile.isLoad = true;
                                                        //console.log(file, fileList)

                                                        let temp = fileAddVideo;
                                                        let temp_ = {
                                                            type: 'FILE',
                                                            file: file,
                                                            path: URL.createObjectURL(file),
                                                            url: URL.createObjectURL(file),
                                                            is_chacge: true,
                                                            file_name: file.name,
                                                            image_file: file,
                                                        }
                                                        temp.push(temp_)
                                                        setFileAddVideo(temp);

                                                        UploadVideo();

                                                        

                                                    }}

                                                >
                                                    <div className="ant-upload-drag-icon">

                                                        <img src={uploadicon} style={{ width: '10%' }} />
                                                    </div>
                                                    <div className="ant-upload-text" style={{
                                                        color: '#FFF',
                                                        fontFamily: 'Oxanium',
                                                        fontSize: '30px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: 'normal',
                                                    }} >DRAG & DROP FILES HERE</div>
                                                    <div className="ant-upload-text" style={{
                                                        color: '#FFF',
                                                        fontFamily: 'Oxanium',
                                                        fontSize: '30px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: 'normal',
                                                    }} >OR</div>
                                                    <div className="ant-upload-text" style={{
                                                        color: '#FFF',
                                                        fontFamily: 'Oxanium',
                                                        fontSize: '24px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: 'normal',
                                                        fill: 'linear-gradient(90deg, #051F6C 0%, #16285C 78.79%, #222E51 162.66%)',
                                                        strokeWidth: '2px',
                                                        stroke: '#7BD7FF',
                                                        filter: 'drop-shadow(0px 0px 20px #7BD7FF)',

                                                    }} >BROWSE FILES</div>


                                                </Dragger>
                                            </>)}

                                        </div>

                                    </div>





                                </div>

                                <div style={{ display: 'flex', flexDirection: 'column', width: '60%', alignItems: 'center', height: '35%' }}>
                                    <div style={{ display: 'flex', width: '100%' }}>
                                        <div style={{ backgroundColor: 'rgba(0, 71, 255, 1)', padding: '5px', borderRadius: '5px 5px 0px 0px' }}>Description</div>

                                    </div>

                                    <div style={{ /* border: '#0047FF solid 1px', */ height: '100%', width: '100%', overflow: 'auto' }}>
                                        <TextArea
                                            value={addTextVideo}
                                              /* style={{ height: '100%' }} */
                                            onChange={(value) => {
                                                setAddTextVideo(value.target.value);
                                                /* setFilterTimeline({ ...filterTimeline, search: value.target.value }); */
                                            }}

                                            autoSize={{
                                                minRows: 8,
                                                maxRows: 8,
                                            }}

                                        />

                                    </div>

                                </div>

                            </div>


                        </div>
                    </>)}





                </ConfigProvider>



















                {loading && (
                    <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                        <div className='alertKickBoxShadow'>
                            <div className='alertKickBox' style={{ height: 'fit-content' }}>
                                <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                    <div className='row' style={{ height: '25%' }}>
                                        <div className='alertKickHead' style={{ color: 'green' }}>
                                            Loading
                                        </div>
                                    </div>
                                    <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                        <div className='alertKickWarning' style={{ border: 'none' }}>
                                            <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )} 


                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${errorMasgerList.isError ? 'yellow' : 'green'}` }}>
                                        {errorMasgerList.isError ? '! WARNING !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {errorMasgerList.isError ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>

                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickTextSec'>
                                        {errorMasgerList.massger.map((e) => {
                                            return <div> {e.massger} </div>
                                        })}
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-4'>
                                    <button className='alertKickButton' onClick={() => {

                                      
                                        if (errorMasgerList.isError === false) {
                                       
                                        }
                                        toggle_modal_alertKick()
                                        setErrorMasgerList({
                                            ...errorMasgerList,
                                            isError: false,
                                            massger: []
                                        })
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
}

export default VideoTimeline;
