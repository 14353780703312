import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import moment from "moment";
import Select from 'react-select'
import '../personalInfo/personalInfo.css'
import proResume from "../../assets/img/icon/ProResume.png"
import paperClip from "../../assets/img/icon/paperclip.png"
import ImgCard from "./cardPersonalInfo.js"
import DetailProject from "./components/project/detailProject.js"
import Myfeedback from "./components/myfeedback/index.js"
import DetailSpaceShip from "./components/spaceship/detailSpaceShip.js"
import DetailFederation from "./components/federation/detailFederation.js"
import DetailPayment from "./components/payment/detailPayment.js"
import DetailTimesheet from "./components/timesheet/timesheet.js"
import DetailConnection from "./components/connection/detailConnection.js"
import DetailAchievement from "./components/achievement/detailAchievement.js"
import DetailBackground from "./components/background/detailBackground.js"
import Configs from "../../config";
import PatternFormat from '../../components/customElement/PatternFormat'
import IconSendPic from '../../assets/img/icon/IconSendPic.png'
import IconSave from '../../assets/img/icon/IconSave.png'
import ImgCardDefault from '../../assets/img/images/userImg4.jpg';
import UserTimesheetDay from './components/UserTimesheetDay/index.js';
import Swal from "sweetalert2";
import { Row } from 'react-bootstrap';
import ModalDetail from '../LandingPagestage/Detail/detail.jsx'
import AddMember from '../component/AddMember/addMember.jsx';
import ModelAlertConfrim from "../../components/AlertMassge/AlertConfrim.js";
import { io } from 'socket.io-client';
import CreateSpaceship from '../CreateSpaceship/';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { DatePicker, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
const PersonalInfo = ({
    isOpen,
    setPersonalInfoCheck,
    fncSetProfile,
    submode,
    close,
    sapphireData,
    getAmountSapphire,
    onElement,
    closeElement
}) => {
    const [isOpenCreate, setIsOpenCreate] = useState(false)
    const [modalDetail, setModalDetail] = useState(false)
    const [addMemberModal, setAddMemberModal] = useState(false);
    const toggle_modal_addMember = () => setAddMemberModal(!addMemberModal);
    const [addMemberReload, setAddMemberReload] = useState(false);
    const toggle_modal_addMemberReload = () => setAddMemberReload(!addMemberReload);
    const [memberInSpaceShip, setMemberInSpaceShip] = useState([])
    const [memberSize, setMemberSize] = useState({
        max: 6
    })
    const [displayPassword, setDisplayPassword] = useState(false)
    const [alertKick, setAlertKick] = useState(false);
    const toggle_modal_alertKick = () => setAlertKick(!alertKick);
    const [errorMasgerList, setErrorMasgerList] = useState({
        isError: false,
        massger: []
    })
    const [checkPassword, setCheckPassword] = useState({
        stp_1: false,
        stp_2: false
    });
    const [switModePassword, setSwitModePassword] = useState(true)
    const switTogglePassword = () => setSwitModePassword(!switModePassword)
    const [confrimModal, setConfrimModal] = useState(false);
    const toggle_modal_confrimModal = () => setConfrimModal(!confrimModal);


    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                port: Configs.TTT_Game_Socket_Service_Port
            });
        }
    }, [

    ])
    const [personallTimeSheetDayReport, setpersonallTimeSheetDayReport] = useState(false);
    const togglepersonallTimeSheetDayReport = () => setpersonallTimeSheetDayReport(!personallTimeSheetDayReport);

    useEffect(() => {
        const { current: socket } = socketRef;
        const socFnc = (data) => { }
        try {
            socket.open();
            socket.on('locationElement', socFnc)
        } catch (error) {
            console.log(error);
        }

    }, []);


    useEffect(() => {

        console.log('onElement111', onElement)
        if (onElement == 'f5d5b176-ea78-4ff1-ab08-f148f06f2c78') {
            // Time Sheet Day Report
            togglepersonallTimeSheetDayReport();
        }

    }, [onElement]);


    async function callBackMemberInSpaceShip(value) {
        // let temp = [];
        // for (let item of value) {
        //     temp.push(item.usr_id)
        // }
        setMemberInSpaceShip(value)
    }

    const [menu, setMenu] = useState("PROJECT");
    const [imgProfile, setImgProfile] = useState([])
    const [roleOption, setRoleOption] = useState([])
    const [maritalstOption, setMaritalstOption] = useState([])
    const [genderSOption, setGenderSOption] = useState([])
    const [reFilter, setReFilter] = useState(false)
    const [displayNone, setDisplayNone] = useState({
        status: true,
        check: false
    })
    const [profileImg, setProfileImg] = useState([]);
    let profileIMGMain = profileImg.length !== undefined && profileImg.length > 0 && profileImg[0].ul_file_path !== '' ? `${profileImg[0].url}` : ''
    // console.log('profileIMGMain', profileIMGMain);
    // ${profileImg[0].ul_file_path}
    const [test, settest] = useState({
        test: ''
    })
    const [dataProjectTa, setDataProjectTap] = useState([])

    const [document, setDocument] = useState([])


    const [cardData, setCardData] = useState([
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '6',
            card_name: 'dsaasd',
            card_lastname: 'dsadas',
            card_mvp: '49',
            card_rating: '12',
            card_success: '22',
            card_project: '92'
        },
        {
            card_level: '16',
            card_name: 'ds',
            card_lastname: 'dsadas',
            card_mvp: '249',
            card_rating: '49',
            card_success: '95',
            card_project: '62'
        },
        {
            card_level: '555',
            card_name: 'dsadsa',
            card_lastname: 'dsadas',
            card_mvp: '949',
            card_rating: '15',
            card_success: '45',
            card_project: '69'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
        {
            card_level: '5',
            card_name: 'dsafafas',
            card_lastname: 'dsadas',
            card_mvp: '449',
            card_rating: '45',
            card_success: '65',
            card_project: '12'
        },
    ])

    const [data, setData] = useState({
        usr_id: '',
        role_current_path: '',
        info_name: '',
        info_lastname: '',
        info_phone: '',
        info_email: '',
        info_role: '',
        info_role_label: '',
        info_dob: '',
        info_gender: '',
        info_gender_label: '',
        info_marital: '',
        info_marital_label: '',
        info_national: '',
        usr_username: '',
        usr_password: '',
    })

    let [displayText, setDisplayText] = useState(false);
    const parentRef = useRef(null);
    const childRef = useRef(null);

    const [oldFileList, setOldFileList] = useState([]);
    const [otherFileList, setOtherFileList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [roleList, setRoleList] = useState([]);

    async function getInfomaionUserByID() {
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/userprofile/getUserByID/${user_id}`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response);
                let cutphone = ''

                let check_1 = false;
                let check_2 = false;

                if (response.data.usr_password.length > 7) {
                    check_1 = true
                }
                if (/^(?=.*[a-zA-Z])(?=.*\d).+$/.test(response.data.usr_password) === true) {
                    check_2 = true
                }

                let img_path = '';

                if (response.data.fd_img_id) {
                    const response2 = await axios.get(`https://api.box.com/2.0/files/${response.data.fd_img_id}/content`, {
                        headers: {
                            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                        },
                        responseType: 'blob',
                    });

                    img_path = URL.createObjectURL(response2.data);
                }

                setCheckPassword({
                    ...checkPassword,
                    stp_1: check_1,
                    stp_2: check_2
                })
                setData({
                    ...data,
                    usr_id: user_id,
                    info_role_logo: img_path,
                    info_name: response.data.usr_name,
                    info_lastname: response.data.usr_lastname,
                    info_phone: response.data.usr_phone_number,
                    info_email: response.data.usr_email,
                    info_role: response.data.usr_role_id,
                    info_role_label: response.data.usr_role_name,
                    info_dob: response.data.usr_date_of_birth !== null && response.data.usr_date_of_birth !== undefined && response.data.usr_date_of_birth !== '' ? moment(response.data.usr_date_of_birth).format('YYYY-MM-DD') : '',
                    info_gender: response.data.usr_gender_id,
                    info_gender_label: response.data.usr_gender_name,
                    info_marital: response.data.usr_ms_id,
                    info_marital_label: response.data.usr_ms_name,
                    info_national: response.data.usr_national_id_card_number,
                    usr_username: response.data.usr_username,
                    usr_password: response.data.usr_password,

                })



                // if (response.data.filelist.length > 0) {
                //     let tempfile = [];
                //     let getProfile = response.data.filelist.filter((e) => e.fd_keyfile === 'PROFILE')
                //     let getfile = response.data.filelist.filter((e) => e.fd_keyfile === 'RESUME PROFILE')
                //     if (getProfile.length > 0) {
                //         /*   setFileList(getProfile[0]) */

                //         let elment = getProfile[0];
                //         fncSetProfile(`${Configs.TTT_Game_Service_IMG}${elment.ul_file_path}`);
                //         sessionStorage.setItem('img_profile', `${Configs.TTT_Game_Service_IMG}${elment.ul_file_path}`)
                //         // console.log('รูปในนร้',`${Configs.TTT_Game_Service_IMG}/${elment.ul_file_path}`);
                //         let arra = [];
                //         var list = [{
                //             uid: elment.ul_id,
                //             ul_file_path: elment.ul_file_path,
                //             ul_file_name: elment.ul_file_name,
                //             name: elment.ul_file_name,
                //             path: elment.ul_file_path,
                //             url: `${Configs.TTT_Game_Service_IMG}${elment.ul_file_path}`,
                //             is_save: true,
                //             ul_is_use: true,
                //             ul_file_size: elment.ul_file_size,
                //             ul_type: "img",
                //             ul_group_name: "document user",
                //             ul_menu_name: "document user",
                //             ul_is_profile: true,
                //             status: 'done',
                //             byid: true
                //         }]
                //         arra.push(list)
                //         setProfileImg(list);

                //         /*  console.log('getProfile', getProfile); */
                //     }

                //     for (let item of getfile) {
                //         var list = {
                //             uid: item.ul_id,
                //             ul_file_path: item.ul_file_path,
                //             ul_file_name: item.ul_file_name,
                //             name: item.ul_file_name,
                //             path: item.ul_file_path,
                //             url: `${Configs.TTT_Game_Service_IMG}${item.ul_file_path}`,
                //             is_save: true,
                //             ul_is_use: true,
                //             ul_file_size: item.ul_file_size,
                //             ul_type: "file",
                //             ul_group_name: "document user",
                //             ul_menu_name: "document user",
                //             ul_is_profile: false,
                //             status: 'done',
                //             byid: true
                //         }
                //         tempfile.push(list)
                //     }
                //     setDocument(tempfile);
                // }

                if (response.data.filelist.length > 0) {
                    // let checkProfile = response.data.filelist.filter((e)=>{return e.fd_keyfile == 'PROFILE'})
                    let oldFile = [];
                    let otherFile = [];
                    let tempProfile = [];
                    let tempFile = [];

                    for (let item of response.data.filelist) {
                        if (item.fd_img_id) {
                            let temp = {};
                            const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });
                            temp = {
                                url: URL.createObjectURL(response2.data),
                                ul_file_name: item.fd_origin_name,
                                fd_img_id: item.fd_img_id
                            }
                            if (item.fd_keyfile == 'PROFILE') {
                                fncSetProfile(`${/* Configs.TTT_Game_Service_IMG}${elment.ul_file_path */URL.createObjectURL(response2.data)}`);
                                tempProfile.push(temp);
                                oldFile.push(item.fd_img_id)
                            } else if (item.fd_keyfile == 'RESUME PROFILE') {
                                tempFile.push(temp);
                                oldFile.push(item.fd_img_id);
                            } else {
                                otherFile.push(item.fd_img_id);
                            }
                        };

                    }

                    setOldFileList(oldFile);
                    setOtherFileList(otherFile);
                    setProfileImg(tempProfile);
                    setDocument(tempFile);
                    // let checkResume = response.data.filelist.filter((e)=>{return e.fd_keyfile == 'RESUME PROFILE'})
                    // if (checkResume.length > 0) {
                    //     let tempFile = [];
                    //     for (let item of checkResume) {
                    //         if (item.fd_img_id) {
                    //             let temp = {};
                    //             const response2 = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                    //                 headers: {
                    //                     Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                    //                 },
                    //                 responseType: 'blob',
                    //             });
                    //             temp = {
                    //                 url: URL.createObjectURL(response2.data)
                    //             }
                    //             tempFile.push(temp);
                    //         };
                    //     }
                    //     setDocument(tempFile);
                    // }
                };

                settest({ ...test, test: '' })
                test.test = ''

            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function RoleOption() {
        // let user_id = '51eed4c4-6328-11ee-8c99-0242ac120002';
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/roleOption`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response.data);
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.role_id,
                        label: e.role_name,
                        logo: e.role_current_path,
                        fd_img_id: e.fd_img_id
                    }
                    tem.push(setT)
                })

                setRoleOption(tem);


            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function maritalStatusOption() {
        // let user_id = '51eed4c4-6328-11ee-8c99-0242ac120002';
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/maritalStatusOption`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response);
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.ms_id,
                        label: e.ms_name
                    }
                    tem.push(setT)
                })
                setMaritalstOption(tem)
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    async function optionGender() {
        // let user_id = '51eed4c4-6328-11ee-8c99-0242ac120002';
        let user_id = sessionStorage.getItem('user_id');
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/optionGender`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response);
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.gender_id,
                        label: e.gender_name
                    }
                    tem.push(setT)
                })
                setGenderSOption(tem)
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    async function saveButton(saveData) {
        // console.log(`saveData`,saveData);
        let temp = {
            usr_id: sessionStorage.getItem('user_id'),
            usr_date_of_birth: saveData.info_dob,
            usr_email: saveData.info_email,
            usr_gender_id: saveData.info_gender,
            usr_gender_name: saveData.info_gender_label,
            usr_lastname: saveData.info_lastname,
            usr_ms_id: saveData.info_marital,
            usr_ms_name: saveData.info_marital_label,
            usr_name: saveData.info_name,
            usr_national_id_card_number: saveData.info_national,
            usr_phone_number: saveData.info_phone,
            usr_role_id: saveData.info_role,
            usr_role_name: saveData.info_role_label,
            filelist: []
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/userprofile/editUserProfile`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                // console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });
    }


    async function CheckBefoSave() {
        // console.log('Save', profileImg);
        /* Swal.fire({
            title: "Do you want to save data?",
            text: "You won't be able to revert this!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes, save it!",
            
        }).then(async (result) => {
            if (result.isConfirmed) { */
        const error_list = [];
        if (checkPassword.stp_1 === false) {
            let temp_err = {
                message: "The password must be at least 8 characters long."
            }
            error_list.push(temp_err);
        }
        if (checkPassword.stp_2 === false) {
            let temp_err = {
                message: `The password must contain at least 1 digit and 1 English letter, such as "1234567A".`
            }
            error_list.push(temp_err);
        }

        if (data.info_name.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Name]."
            }
            error_list.push(temp_err);
        }
        if (data.info_lastname.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Lastname]."
            }
            error_list.push(temp_err);
        }
        if (data.info_email.trim() == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Email]."
            }
            error_list.push(temp_err);
        }

        if (data.info_phone == "") {
            let temp_err = {
                message: "Please enter information in the fields >>> [Phone Number]."
            }
            error_list.push(temp_err);
        }

        if (data.info_role == "" || data.info_role == null) {
            let temp_err = {
                message: "Please enter information in the fields >>> [Current Role]."
            }
            error_list.push(temp_err);
        }

        if (data.info_dob == "" || data.info_dob == null) {
            let temp_err = {
                message: "Please enter information in the fields >>> [Date od Birth]."
            }
            error_list.push(temp_err);
        }

        if (data.info_gender == "" || data.info_gender == null) {
            let temp_err = {
                message: "Please enter information in the fields >>> [Gender]."
            }
            error_list.push(temp_err);
        }

        if (data.info_marital == "" || data.info_marital == null) {
            let temp_err = {
                message: "Please enter information in the fields >>> [Marital Status]."
            }
            error_list.push(temp_err);
        }
        if (data.info_national == "" || data.info_national == null) {
            let temp_err = {
                message: "Please enter information in the fields >>> [Naticnal ID Card Number]."
            }
            error_list.push(temp_err);
        }


        if (error_list.length > 0) {
            setErrorMasgerList({
                ...errorMasgerList,
                isError: true,
                massger: error_list
            })
            toggle_modal_alertKick()
            /*   var err_message = "";
              for (var e = 0; e < error_list.length; e++) {
                  err_message += "<br/>" + error_list[e].message;
              }
              Swal.fire("Error", err_message, "error"); */
        } else {
            let ole_file = []

            // if (document.length > 0) {
            //     ole_file = document.filter((e) => e.byid !== undefined && e.byid == true)
            //     // console.log('fileList____1', ole_file);
            //     let newFile = document.filter((e) => e.byid == undefined || e.byid == false)
            //     // console.log('fileList____2', newFile);
            //     if (newFile.length > 0) {
            //         const dataImg = new FormData();
            //         for (let i = 0; i < newFile.length; i++) {

            //             dataImg.append('Profile', newFile[i].originFileObj);
            //         }

            //         dataImg.append('typeFile', "file");
            //         dataImg.append('projectKey', "TTTSPACESHIP");
            //         // console.log(' dataImg', dataImg);
            //         await axios({
            //             method: 'post',
            //             url: `${Configs.TTT_Game_Service}/api/upload/document2`,
            //             headers: {
            //                 /*  Authorization: getToken(), */
            //                 'X-TTT': `${Configs.API_TTT}`,
            //                 'Content-Type': 'application/octet-stream',
            //             },
            //             data: dataImg,
            //         })
            //             .then(async function (response) {
            //                 if (response.data.status == true) {
            //                     response.data.data.forEach((e) => {
            //                         const tmp = {
            //                             name: e.orgin_name,
            //                             path: e.path,
            //                             is_save: true,
            //                             ul_is_use: true,
            //                             ul_file_size: e.size,
            //                             url: e.path,
            //                             ul_type: "IMG",
            //                             ul_group_name: "document user",
            //                             ul_file_name: e.orgin_name,
            //                             ul_file_path: e.path,
            //                             ul_menu_name: "document user",
            //                             ul_is_profile: false,
            //                             status: 'done'

            //                         }
            //                         ole_file.push(tmp);
            //                     })
            //                 }

            //             })
            //             .catch(function (error) {
            //                 console.log(error);
            //             });
            //     }
            // }
            // if (profileImg.length > 0) {
            //     let newProfile = profileImg.filter((e) => e.is_img_upload !== undefined && e.is_img_upload == true)
            //     if (newProfile.length > 0) {
            //         const dataImg = new FormData();
            //         for (let i = 0; i < newProfile.length; i++) {
            //             dataImg.append('Profile', newProfile[i].image_file);
            //         }
            //         dataImg.append('typeFile', "IMG");
            //         dataImg.append('projectKey', "TTTSPACESHIP");

            //         await axios({
            //             method: 'post',
            //             url: `${Configs.TTT_Game_Service}/api/upload/document2`,
            //             headers: {
            //                 /*  Authorization: getToken(), */
            //                 'X-TTT': `${Configs.API_TTT}`,
            //                 'Content-Type': 'application/octet-stream',
            //             },
            //             data: dataImg,
            //         })
            //             .then(async function (response) {
            //                 if (response.data.status == true) {
            //                     response.data.data.forEach((e) => {
            //                         const tmp = {
            //                             name: e.orgin_name,
            //                             path: e.path,
            //                             is_save: true,
            //                             ul_is_use: true,
            //                             ul_file_size: e.size,
            //                             url: e.path,
            //                             ul_type: "IMG",
            //                             ul_group_name: "document user",
            //                             ul_file_name: e.orgin_name,
            //                             ul_file_path: e.path,
            //                             ul_menu_name: "document user",
            //                             ul_is_profile: true,
            //                             status: 'done'

            //                         }
            //                         ole_file.push(tmp);
            //                     })
            //                 }

            //             })
            //             .catch(function (error) {
            //                 console.log(error);
            //             });
            //     } else {
            //         let checkIMG = profileImg.filter((e) => e.byid !== undefined && e.byid == true)
            //         // console.log('TTTTT_checkIMG', checkIMG);
            //         if (checkIMG.length > 0) {
            //             ole_file.push(checkIMG[0])
            //         }
            //     }
            // }
            editSetUser(ole_file)
        }


    }




    async function editSetUser(decment) {
        setLoading(true);
        let cleanedNumber = data.info_phone.replace(/-/g, '');
        let infonational = data.info_national.replace(/-/g, '');

        let fileList = decment || [];
        let dateBride = data.info_dob !== undefined && data.info_dob !== null && data.info_dob !== '' ? new Date(data.info_dob) : new Date()
        let user_id = sessionStorage.getItem('user_id');
        let temp = {
            usr_id: user_id,
            usr_username: data.usr_username,
            usr_password: data.usr_password,
            usr_name: data.info_name.trim(),
            usr_lastname: data.info_lastname.trim(),
            usr_phone_number: cleanedNumber,
            usr_email: data.info_email,
            usr_role_id: data.info_role,
            usr_role_name: data.info_role_label,
            usr_date_of_birth: dateBride,
            usr_gender_id: data.info_gender,
            usr_gender_name: data.info_gender_label,
            usr_ms_id: data.info_marital,
            usr_ms_name: data.info_marital_label,
            usr_national_id_card_number: infonational,
            filelist: fileList
            /*        usr_is_verify: ,
                   usr_created_date: null,
                   usr_updated_date: null,
                   usr_created_by: null,
                   usr_created_by_name: null,
                   usr_updated_by: null,
                   usr_updated_by_name: null, */
        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/userprofile/editUserProfile`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }, data: temp

        })
            .then(async function (response) {
                /*           window.location.href = "/SetUser" */
                if (response.data) {


                    /*       Swal.fire({
                              icon: "success",
                              title: "Save",
                              showConfirmButton: false,
                              timer: 1500,
                          }).then((result) => { */
                    setSwitModePassword(true)

                    let trop = []
                    let temp_succ = {
                        message: "success"
                    }
                    trop.push(temp_succ);
                    setErrorMasgerList({
                        ...errorMasgerList,
                        isError: false,
                        massger: trop
                    })

                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'StageMonitoring' });

                    if (profileImg.length > 0 && profileImg[0].image_file) {
                        CheckAndCreateFolderBox(sessionStorage.getItem('user_id'), 'PROFILE');
                    } else {
                        CheckAndCreateFolderBox(sessionStorage.getItem('user_id'), 'RESUME PROFILE');
                    }
                    // toggle_modal_alertKick()

                    /*   }); */
                }
            })
            .catch(function (error) {
                /*  Swal.fire("Error", error.response.data.error[0].errorDis, "error"); */
                console.log(error);
                setErrorMasgerList({
                    ...errorMasgerList,
                    isError: true,
                    massger: error.response.data.error[0].errorDis
                })
                toggle_modal_alertKick()

            });
    }

    async function CheckAndCreateFolderBox(id, typeList) {
        let temp = {
            folderLis: ["USER", "USER_" + id, typeList]
        }
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    UploadFiles(id, response.data.folder_id, typeList);
                }
            })
            .catch(function (error) {
                Swal.fire("Error", error.response.data.error[0].errorDis, "error");
                console.log(error);
            });
    }

    async function UploadFiles(id, folder_id, typeList) {
        let oldFileList_ = oldFileList.filter((e) => { return e != null });
        let setOldFile = [...oldFileList_, ...otherFileList];
        const dataImg = new FormData();
        if (typeList == 'PROFILE') {
            dataImg.append('File', profileImg[0].image_file);
            dataImg.append('folder_id', folder_id);
            dataImg.append('key', typeList);
            dataImg.append('image_old_list', JSON.stringify(setOldFile));
            dataImg.append('ref_id', id);
        } else if (typeList == 'RESUME PROFILE') {
            for (let item of document) {
                dataImg.append('File', item.image_file);
            }
            dataImg.append('folder_id', folder_id);
            dataImg.append('key', typeList);
            dataImg.append('image_old_list', JSON.stringify(setOldFile));
            dataImg.append('ref_id', id);
        }
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'multipart/form-data'
            },
            data: dataImg
        })
            .then(async function (response) {
                if (response.data) {
                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'StageMonitoring' });

                    if (typeList == 'PROFILE') {
                        oldFileList.push(response.data.image_id_list[0]);
                        CheckAndCreateFolderBox(id, 'RESUME PROFILE');
                    } else if (typeList == 'RESUME PROFILE') {
                        toggle_modal_alertKick();
                        setLoading(false);
                        setOldFileList([]);
                    };
                }
            })
            .catch(function (error) {
                Swal.fire("Error", error.response.data.error[0].errorDis, "error");
                console.log(error);
            });
    }


    const [spacedata, setspacedata] = useState({
        id: null,
        spaceName: ''
    })


    async function callBackspace(id, model, spaceName) {
        setspacedata({
            ...spacedata,
            id: id,
            spaceName: spaceName
        })
        spacedata.id = id
        spacedata.spaceName = spaceName
        socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'SpaceshipInfo', id: id });
        setModalDetail(model)
    }







    useEffect(() => {
        // console.log(submode);
        if (submode !== null) {
            if (submode === 'SPACESHIP') {
                setMenu('SPACESHIP')
            }else if(submode === 'TIMESHEET'){
                setMenu('TIMESHEET')
            }
        }
        const parent = parentRef.current;
        const child = childRef.current;


        const handleScroll = () => {
            const scrollTop = parent.scrollTop;
            const childHeight = child.clientHeight;
            const triggerScroll = childHeight / 2; // Scroll position to trigger the display of text


            let keyCheck = displayText;
            if (scrollTop >= 200) {
                /*      setDisplayText(true)
                     displayText = true */
                keyCheck = true
            } else {
                keyCheck = false
                /*   displayText = false
                  setDisplayText(false) */
            }


            if (keyCheck != displayText) {
                if ((scrollTop >= 200)) {
                    setDisplayText(true)
                    displayText = true

                    setDisplayNone({ ...displayNone, status: false })
                    displayNone.status = false;

                } else {

                    displayText = false
                    setDisplayText(false)
                    let myTimer = setInterval(() => {
                        if (displayText == false) {
                            // console.log("in")
                            setDisplayNone({ ...displayNone, status: true })
                            displayNone.status = true;


                        }
                        clearInterval(myTimer);
                    }, 1000);



                }
                // console.log('displayText', displayText);
            }


            // if (displayText == false) {
            //        if (displayNone.check == true) {
            //     setInterval(displayHello, 3000);
            //        }
            // } else {
            //     if (displayNone.check == false) {
            //         setDisplayNone({...displayNone,status:false,check:true})
            //         displayNone.status = false
            //         displayNone.check = true
            //     }
            // }
            /*    console.log('displayText', scrollTop, displayText, displayNone); */
            //   console.log('scrollTop >= 100', scrollTop >= 100);
        };

        parent.addEventListener('scroll', handleScroll);

        getInfomaionUserByID()
        socketRef.current.emit('join', "locationElement" + sessionStorage.getItem('user_id'));
        if (isOpen == true) {

            RoleOption()
            maritalStatusOption()
            optionGender()

            socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'UserInfomation' });
            socketRef.current.emit('leaveOne', "locationElement:" + sessionStorage.getItem('user_id'))
        } else {
            socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'StageMonitoring' });
            socketRef.current.emit('leaveOne', "locationElement:" + sessionStorage.getItem('user_id'))
        }

        return () => {
            parent.removeEventListener('scroll', handleScroll);
        };
        /*   } */


    }, [isOpen])




    function delfileDocment(index, fd_img_id) {
        /* console.log('index', index); */
        let checkIndex = oldFileList.findIndex((e) => e == fd_img_id);
        if (checkIndex > -1) {
            oldFileList.splice(checkIndex, 1);
        }
        let ole_file = document;
        if (ole_file.length > 0) {
            ole_file.splice(index, 1)
        }
        setDocument(ole_file);
        settest({ ...test, test: '' })
        test.test = ''
    }


    function callBackReFilter(value) {
        if (value == true) {

            setReFilter(true)
            setIsOpenCreate(!isOpenCreate)
        }
    }


    /*  async function getProjectByUserID() {
         let user_id = sessionStorage.getItem('user_id');
         await axios({
             method: 'get',
             url: `${Configs.TTT_Game_Service}/api/project/getProjectByUserID/${user_id}`,
             headers: {
                 Authorization: sessionStorage.getItem('access_token'),
                 'X-TTT': `${Configs.API_TTT}`,
                 'Content-Type': 'application/json',
             },
         })
             .then(async function (response) {
                 console.log('ProjectTIME', response.data);
                 setDataProjectTap(response.data)
             })
             .catch(function (error) {
                 console.log(error);
             });
     } */


    function openCreateship() {
        /* console.log('isOpenCreate', isOpenCreate); */
        console.log('key addSpace', isOpenCreate);

        setIsOpenCreate(!isOpenCreate)
    }

    function callBackColse(value) {

        if (!value) {
            socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'UserInfomation' });
        }
        setIsOpenCreate(value)
    }

    const iconStyle = {
        color: '#fff',    // กำหนดสี
        fontSize: '16px',  // กำหนดขนาด
        textShadow: '-1px 0 rgb(0, 71, 255), 0 1px rgb(0, 71, 255), 1px 0 rgb(0, 71, 255), 0 -1px rgb(0, 71, 255)',
        filter: 'drop-shadow(0px 0px 1.5px rgb(0, 71, 255))',
        marginTop: '2px',
        // เพิ่ม style อื่น ๆ ตามต้องการ
    };
    const iconStyleEye = {
        color: '#fff',    // กำหนดสี
        fontSize: '16px',  // กำหนดขนาด
        textShadow: '-1px 0 rgb(0, 71, 255), 0 1px rgb(0, 71, 255), 1px 0 rgb(0, 71, 255), 0 -1px rgb(0, 71, 255)',
        filter: 'drop-shadow(0px 0px 1.5px rgb(0, 71, 255))',
        margin: '10px 9px 0px',
        /* cursor:'pointer' */
        /*  zIndex:785858, */

        // เพิ่ม style อื่น ๆ ตามต้องการ
    };




    return (
        <>
            <div style={{ visibility: `${isOpen ? 'visible' : 'hidden'}` }}>
                <div className="pi-screenBox">
                    <div className="pi-overFlow" ref={parentRef}>
                        <div className="pi-personalInfoBox" ref={childRef}>
                            <div className="pi-closePersonalInfo" onClick={() => {
                                /*           getInfomaionUserByID(); */
                                socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'StageMonitoring' });

                                setPersonalInfoCheck({
                                    isOpen: false
                                })
                                setMenu('PROJECT')
                                setSwitModePassword(true)
                                setDisplayPassword(false)
                                if (close !== undefined) {
                                    close(false)
                                }
                            }}><i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
                            </div>
                            <div className="row pi-frameTop">

                                <div className="pi-frameTopText">EDIT PERSONAL INFORMATION</div>
                                <button onClick={() => toggle_modal_confrimModal()} type="button" className='pi-saveButton' ><img src={IconSave} style={{ width: "17px", height: "17px", position: "relative", top: "-1.5px", margin: "5px" }}></img>SAVE</button>
                                <button onClick={() =>
                                    setDisplayPassword(!displayPassword)
                                }
                                    type="button" className='pi-editUSerButton' >

                                    <FontAwesomeIcon icon={faPencil} style={iconStyle} /> PASSWORD</button>

                            </div>
                            <div className='row pi-frameHeader' style={{ animation: `${displayText ? 'mymove 1s' : 'returnmove 1s'}`, display: `${displayNone.status ? 'none' : ''}` }}>
                                <div className='col-10 pi-frameHeaderText'>
                                    <div className='pi-imgProfileIn' style={{ backgroundImage: `url(${profileIMGMain !== '' ? profileIMGMain : ImgCardDefault}` }}></div>
                                    <div className='pi-frameHeaderTextIn'>{data.info_name} {data.info_lastname}</div>
                                </div>
                                <div className='col-2 pi-imgProfile'>
                                    <button onClick={() => toggle_modal_confrimModal() /* CheckBefoSave() */} type="button" className='pi-saveButtonSticky'  >
                                        <img src={IconSave} style={{ width: "25px", height: "25px", position: "relative", top: "-1.5px", margin: "5px" }}></img>
                                        <div className='pi-saveButtonStickyText'>SAVE</div>
                                    </button>

                                </div>
                            </div>
                            {displayPassword === true ? <div style={{ width: '90%', margin: '0 5% auto' }}>
                                <div className="row">
                                    <div className="col-4">
                                        <div className="pi-infoText">PASSWORD</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px #5D9BE1)` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <input className="pi-infoBoxTextIn"
                                                        type={switModePassword === true ? `password` : `text`}
                                                        value={data.usr_password}
                                                        onChange={(e) => {
                                                            let check_1 = false;
                                                            let check_2 = false;
                                                            if (e.target.value.length > 7) {
                                                                check_1 = true
                                                            }
                                                            if (/^(?=.*[a-zA-Z])(?=.*\d).+$/.test(e.target.value) === true) {
                                                                check_2 = true
                                                            }


                                                            setCheckPassword({
                                                                ...checkPassword,
                                                                stp_1: check_1,
                                                                stp_2: check_2
                                                            })
                                                            setData({
                                                                ...data,
                                                                usr_password: e.target.value,
                                                            });
                                                        }}

                                                    /* onChange={(e) => { setData({ ...data, usr_password: e.target.value }) }} */
                                                    >
                                                    </input>
                                                    <div style={{ width: '40px', height: '36px', position: 'absolute', top: '5px', right: '44px', backgroundImage: 'linear-gradient(87deg, #021E6F -236.97%, #222E51 81.68%)', cursor: 'pointer' }}
                                                        onClick={() => { switTogglePassword() }}>

                                                        {switModePassword === true ? <>
                                                            <FontAwesomeIcon icon={faEye} style={iconStyleEye} />
                                                        </> : <>
                                                            <FontAwesomeIcon icon={faEyeSlash} style={iconStyleEye} />
                                                        </>}

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        {/*   <div className="pi-infoText" style={{visibility:'hidden'}}>PASSWORD</div> */}


                                        <div className='row' style={{ marginTop: '28px' }}>
                                            <input
                                                type="checkbox"
                                                style={{ display: 'block' }}
                                                checked={checkPassword.stp_1}
                                                id="stp_1"
                                            >
                                            </input><span style={{ marginLeft: '12px', color: '#FFF', fontFamily: 'Oxanium' }}>The password must be at least 8 characters long.</span>
                                        </div>
                                        <div className='row'>
                                            <input
                                                type="checkbox"
                                                style={{ display: 'block' }}
                                                checked={checkPassword.stp_2}
                                                id="stp_2"
                                            >
                                            </input>  <span style={{ marginLeft: '12px', color: '#FFF', fontFamily: 'Oxanium' }}>The password must contain at least 1 digit and 1 English letter, such as "1234567A".</span>
                                        </div>





                                    </div>
                                </div>
                            </div> : <></>}
                            <div className="pi-frameCenter">

                                <div className="row">
                                    <div className="col-4">
                                        <div className="pi-infoText">NAME</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_name !== undefined && data.info_name !== null && data.info_name !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <input className="pi-infoBoxTextIn"
                                                        type='text'
                                                        value={data.info_name}
                                                        onChange={(e) => { setData({ ...data, info_name: e.target.value }) }}
                                                    >
                                                    </input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">PHONE NUMBER</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_phone !== undefined && data.info_phone !== null && data.info_phone !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <PatternFormat
                                                        placeholder={'PHONE NUMBER'}
                                                        value={data.info_phone}
                                                        className="pi-infoBoxTextIn-1"
                                                        onChange={(e) => {

                                                            let cut = e.target.value.split('-')
                                                            let cutphone = ''
                                                            for (let c = 0; c < cut.length; c += 1) {
                                                                cutphone += cut[c]

                                                            }

                                                            setData({
                                                                ...data,
                                                                info_phone: cutphone,
                                                            });

                                                        }}
                                                    />
                                                    {/*  <input className="pi-infoBoxTextIn"
                                                    type='text'
                                                    value={data.info_phone}
                                                    onChange={(e) => { setData({ ...data, info_phone: e.target.value }) }}
                                                >
                                                </input> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">CURRENT ROLE</div>
                                        <div className="pi-infoBoxTextShadow-1" style={{ zIndex: 8889999 }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <div className="pi-infoBoxTextSelect"></div>
                                                </div>
                                            </div>
                                            {isOpen && (

                                                <Select
                                                    className="selectcss"

                                                    options={roleOption}
                                                    value={roleOption.filter((e) => { return e.value === data.info_role })}
                                                    onChange={async (e) => {
                                                        // console.log('ข้อมูลเเล้ว', e);
                                                        let temp = roleList;
                                                        let img_path = '';
                                                        if (e.fd_img_id) {
                                                            let check = temp.filter((el) => { return el.fd_img_id == e.fd_img_id })
                                                            if (check.length > 0) {
                                                                setData({
                                                                    ...data,
                                                                    info_role: e.value,
                                                                    info_role_label: e.label,
                                                                    info_role_logo: check[0].img_path
                                                                })
                                                            } else {
                                                                const response2 = await axios.get(`https://api.box.com/2.0/files/${e.fd_img_id}/content`, {
                                                                    headers: {
                                                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                                                    },
                                                                    responseType: 'blob',
                                                                });

                                                                img_path = URL.createObjectURL(response2.data);
                                                                temp.push({
                                                                    fd_img_id: e.fd_img_id,
                                                                    img_path: img_path
                                                                });

                                                                setRoleList(temp);

                                                                setData({
                                                                    ...data,
                                                                    info_role: e.value,
                                                                    info_role_label: e.label,
                                                                    info_role_logo: img_path
                                                                })
                                                            }
                                                        } else {
                                                            setData({
                                                                ...data,
                                                                info_role: e.value,
                                                                info_role_label: e.label,
                                                                info_role_logo: null
                                                            })
                                                        };
                                                    }}
                                                />
                                            )}

                                        </div>

                                        <div className="pi-infoText">GENDER</div>
                                        <div className="pi-infoBoxTextShadow-1" style={{ zIndex: 8889999 }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <div className="pi-infoBoxTextSelect"></div>
                                                </div>
                                            </div>
                                            {isOpen && (
                                                <Select
                                                    // className="pi-infoBoxTextSelect"
                                                    className="selectcss"

                                                    options={genderSOption}
                                                    value={genderSOption.filter((e) => { return e.value === data.info_gender })}
                                                    onChange={(e) => {
                                                        // console.log('ข้อมูลเเล้ว', e);
                                                        setData({
                                                            ...data,
                                                            info_gender: e.value,
                                                            info_gender_label: e.label
                                                        })
                                                    }}
                                                />
                                            )}

                                        </div>

                                        <div className="pi-infoText">NATIONAL ID CARD NUMBER</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_national !== undefined && data.info_national !== null && data.info_national !== '' /* && data.info_national.toString().trim().length == 17 */ ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <PatternFormat
                                                        placeholder={'CITIZEN ID'}
                                                        value={data.info_national}
                                                        className="pi-infoBoxTextIn-1"
                                                        onChange={(e) => {

                                                            let cut = e.target.value.split('-')
                                                            let cutphone = ''
                                                            for (let c = 0; c < cut.length; c += 1) {
                                                                cutphone += cut[c]

                                                            }
                                                            // console.log(cutphone.length);
                                                            // console.log('ppop',e.target.value.toString().trim().length);
                                                            setData({
                                                                ...data,
                                                                info_national: e.target.value,
                                                            });

                                                        }}
                                                    />
                                                    {/* <input className="pi-infoBoxTextIn"
                                                    type='text'
                                                    value={data.info_national}
                                                    onChange={(e) => { setData({ ...data, info_national: e.target.value }) }}
                                                >
                                                </input> */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="pi-identityBox">
                                            <div className="pi-identityHead">IDENTITY</div>
                                            <div className="pi-identityBoxIn">
                                                {document.map((e, index) => {
                                                    return (
                                                        <div className='row' style={{ margin: '0 0 5px 0 ', padding: '0px', height: '25px' }}>
                                                            <div className='col-2' style={{ textAlign: 'left', margin: '0', height: '32px' }}>
                                                                <img className="pi-identityIcon" src={paperClip}></img>
                                                            </div>

                                                            <div className='col-8 pi-identityText'>
                                                                <a href={`${/* Configs.TTT_Game_Service_IMG}${ */e.url}`}>
                                                                    {e.ul_file_name}
                                                                </a>
                                                            </div>
                                                            <div className='col-2'>
                                                                <a onClick={() => {
                                                                    delfileDocment(index, e.fd_img_id);
                                                                }}>
                                                                    <i class="fas fa-trash-alt" style={{ fontSize: '16px', margin: "4px auto", opacity: '0.8' }} ></i>
                                                                </a>

                                                            </div>
                                                            {/* <dev className="row">
                                                            <div className='col-1'>
                                                                
                                                            </div>
                                                            <div className='col-2' >
                                                                <a
                                                                    style={{ height: '3px', width: '10px' }}
                                                                    className=" btn btn-xs"
                                                                    key={index}
                                                                    onClick={(e) => {
                                                                        delfileDocment(index)
                                                                    }}
                                                                >
                                                                    <i class="fas fa-trash-alt" style={{ color: '#005cfa', fontSize: '6px', height: '3px', width: '10px' }} ></i>{" "}
                                                                </a>
                                                            </div>
                                                        </dev> */}
                                                        </div>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4 center">
                                        <div className="pi-infoText">LASTNAME</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_lastname !== undefined && data.info_lastname !== null && data.info_lastname !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <input className="pi-infoBoxTextIn"
                                                        type='text'
                                                        value={data.info_lastname}
                                                        onChange={(e) => { setData({ ...data, info_lastname: e.target.value }) }}
                                                    >
                                                    </input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">EMAIL</div>
                                        <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${data.info_email !== undefined && data.info_email !== null && data.info_email !== '' ? '#5D9BE1' : 'red'})` }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <input className="pi-infoBoxTextIn"
                                                        type='text'
                                                        value={data.info_email}
                                                        onChange={(e) => { setData({ ...data, info_email: e.target.value }) }}
                                                    >
                                                    </input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">DATE OF BIRTH</div>
                                        <div className="pi-infoBoxTextShadow">
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <ConfigProvider
                                                        theme={{
                                                            token: {
                                                                // fontFamily:'Oxanium',
                                                                fontSize: '17.005px;',

                                                                /*     colorPrimary: '#85BFFF', */
                                                                colorInfo: '#85BFFF',
                                                                colorBorder: '#0047FF',
                                                                colorText: '#FFF',

                                                                colorTextDescription: 'White',
                                                                colorBgContainer: '#222e51',

                                                                zIndexPopupBase: 99999,
                                                                colorPrimaryBg: '#0047FF',//สีตอน Active ใน Select
                                                                colorBgBase: '#001D5F',//พื้นหลัง select ตอนกด down ลงมา

                                                                colorTextQuaternary: 'White',// สีตัวหนังสือของ placeholder


                                                                colorFillTertiary: '#0047FF',// สีตอน hover ใน Select


                                                                colorTextBase: 'White', // ลูกศรใน datepicker & rangepicker




                                                                colorPrimaryBg: '#85BFFF',
                                                                /*        
                                                                       colorBgLayout:'red',
                                                                       colorFillQuaternary:'red', */


                                                            }
                                                        }}
                                                    >
                                                        {isOpen ? <DatePicker
                                                            style={{ color: 'white', fontWeight: 700 }}
                                                            className="pi-infoBoxTextIn"
                                                            value={data.info_dob ? dayjs(data.info_dob) : null}
                                                            placeholder="วว/ดด/ปปปป"
                                                            format={"DD/MM/YYYY"}
                                                            onChange={(date, dateString) => {
                                                                setData({
                                                                    ...data,
                                                                    info_dob: date ? date.$d : null
                                                                })
                                                            }}

                                                        /> : <></>}

                                                    </ConfigProvider>
                                                    {/* <input className="pi-infoBoxTextIn"
                                                        type='date'
                                                        value={data.info_dob}
                                                        /*   max={moment(new Date()).format('YYYY-MM-DD')} 
                                                        onChange={(e) => {
                                                            /* console.log('พี่ธีมั่ว',e.target.value); 
                                                            setData({
                                                                ...data,
                                                                info_dob: e.target.value
                                                            })
                                                        }}
                                                    >
                                                    </input> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pi-infoText">MARITAL STATUS</div>
                                        <div className="pi-infoBoxTextShadow-1" style={{ zIndex: 8889999 }}>
                                            <div className="pi-infoBoxText">
                                                <div className="pi-infoBoxTextCen">
                                                    <div className="pi-infoBoxTextSelect"></div>
                                                </div>
                                            </div>
                                            {isOpen && (
                                                <Select
                                                    // className="pi-infoBoxTextSelect"
                                                    className="selectcss"
                                                    options={maritalstOption}
                                                    value={maritalstOption.filter((e) => { return e.value === data.info_marital })}
                                                    onChange={(e) => {
                                                        /*  console.log('ข้อมูลเเล้ว', e); */
                                                        setData({
                                                            ...data,
                                                            info_marital: e.value,
                                                            info_marital_label: e.label
                                                        })
                                                    }}
                                                />
                                            )}
                                        </div>
                                        <div className="pi-infoText">&nbsp;</div>
                                        <div className="pi-infoResumeTextShadow"   >
                                            <div className="pi-infoResumeText">
                                                <label className="pi-infoResumeTextIn" for="upload-btn-multiple" type="button" ><img src={proResume} style={{ width: "16px", height: "16px", position: "relative", top: "-3px", left: "-10px" }}></img>  PRO RESUME </label>

                                                <input id="upload-btn-multiple" hidden type="file"
                                                    accept="image/*"
                                                    multiple
                                                    onChange={(e) => {
                                                        if (e.target.files.length > 0) {
                                                            // console.log('e.target.files', e.target.files);
                                                            let ole_file = document;
                                                            for (let item of e.target.files) {
                                                                var list = {
                                                                    ul_file_path: URL.createObjectURL(item),
                                                                    ul_file_name: item.name,
                                                                    name: item.name,
                                                                    path: URL.createObjectURL(item),
                                                                    url: URL.createObjectURL(item),
                                                                    is_save: true,
                                                                    ul_is_use: true,
                                                                    ul_file_size: 0,
                                                                    ul_type: "img",
                                                                    ul_group_name: "document user",
                                                                    ul_menu_name: "document user",
                                                                    ul_is_profile: false,
                                                                    status: 'done',
                                                                    image_file: item,
                                                                    is_img_upload: true,
                                                                    originFileObj: item
                                                                }
                                                                ole_file.push(list)

                                                            }
                                                            setDocument(ole_file)

                                                            settest({ ...test, test: '' })
                                                            test.test = ''


                                                        }



                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="pi-setCard">
                                            <ImgCard
                                                setCard={1}
                                                imgProfile={profileImg}
                                                imgName={data.info_name}
                                                imgLastName={data.info_lastname}
                                                imgRolelogo={data.info_role_logo}
                                                usr_id={data.usr_id}
                                                isOpen={isOpen}
                                            ></ImgCard>
                                        </div>
                                        <div className='row pi-picButton'>
                                            <label for="upload-btn-picth" className='col-5 pi-picSend' type='button'><img src={IconSendPic} style={{ width: "17px", height: "17px" }}></img>Send Picture</label>
                                            <input id="upload-btn-picth" hidden type="file"
                                                accept="image/*"
                                                onChange={(e) => {
                                                    // console.log('file more', e.target.files);
                                                    if (e.target.files.length > 0) {

                                                        let check = profileImg.length > 0 ? profileImg[0].fd_img_id : null;
                                                        let checkIndex = oldFileList.findIndex((e) => e == check);
                                                        if (checkIndex > -1) {
                                                            oldFileList.splice(checkIndex, 1);
                                                        };

                                                        const regex = /([\u0E00-\u0E7F]+)/gmu;
                                                        const str = e.target.files[0].name;
                                                        const m = regex.exec(str);
                                                        var list = [{
                                                            ul_file_path: URL.createObjectURL(e.target.files[0]),
                                                            ul_file_name: e.target.files[0].name,
                                                            name: e.target.files[0].name,
                                                            path: URL.createObjectURL(e.target.files[0]),
                                                            url: URL.createObjectURL(e.target.files[0]),
                                                            is_save: true,
                                                            ul_is_use: true,
                                                            ul_file_size: 0,
                                                            ul_type: "img",
                                                            ul_group_name: "document user",
                                                            ul_menu_name: "document user",
                                                            ul_is_profile: true,
                                                            status: 'done',
                                                            image_file: e.target.files[0],
                                                            is_img_upload: true,
                                                            fd_img_id: null
                                                        }]
                                                        // console.log('file ที่อัพโหลดเเล้ว', list);
                                                        setProfileImg(list)
                                                        settest({ ...test, test: '' })
                                                        test.test = ''


                                                    }



                                                }}
                                            />
                                            <button className='col-7 pi-picHis'>Ai Generate History</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="pi-frameBottom">
                                <div className="pi-menuBottom" style={menu === 'PROJECT' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('PROJECT')}>PROJECT</div>
                                <div className="pi-menuBottom" style={menu === 'SPACESHIP' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => {
                                    setMenu('SPACESHIP');
                                    settest({ ...test, test: '' })
                                    test.test = '';
                                }}>SPACESHIP</div>
                                {/*          <div className="pi-menuBottom" style={menu === 'FEDERATION' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('FEDERATION')}>FEDERATION</div> */}
                                <div className="pi-menuBottom" style={menu === 'PAYMENT' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('PAYMENT')}>PAYMENT TRANSACTION</div>
                                <div className="pi-menuBottom" style={menu === 'TIMESHEET' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('TIMESHEET')}>TIME SHEET</div>
                                <div className="pi-menuBottom" style={menu === 'MYFEEDBACK' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('MYFEEDBACK')}>MY FEEDBACK</div>
                                {/*             <div className="pi-menuBottom" style={menu === 'CONNECTION' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('CONNECTION')}>CONNECTION</div>
                                <div className="pi-menuBottom" style={menu === 'ACHIEVEMENT' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('ACHIEVEMENT')}>ACHIEVEMENT</div>
                                <div className="pi-menuBottom" style={menu === 'BACKGROUND' ? { color: '#0047FF', borderBottom: '2px solid #0047FF' } : null} onClick={() => setMenu('BACKGROUND')}>BACKGROUND PROFILE</div> */}
                            </div>
                            <hr className="pi-lineBottom"></hr>
                            <div className="pi-frameDetail">
                                <DetailProject
                                    isOpen={isOpen}
                                    check={menu} /* dataProjectTa= {dataProjectTa} */
                                ></DetailProject>
                                <DetailSpaceShip
                                    isOpen={isOpen}
                                    check={menu}
                                    /*   setModalDetail={setModalDetail} */
                                    callback={callBackspace}
                                    openCreateship={openCreateship}
                                    reFilter={reFilter}
                                ></DetailSpaceShip>
                                <DetailFederation
                                    isOpen={isOpen}
                                    check={menu}
                                ></DetailFederation>
                                <DetailPayment
                                    isOpen={isOpen}
                                    check={menu}
                                ></DetailPayment>
                                <DetailTimesheet
                                    isOpen={isOpen}
                                    check={menu}
                                ></DetailTimesheet>
                                <DetailConnection
                                    isOpen={isOpen}
                                    check={menu}
                                    cardData={cardData}
                                    imgProfile={imgProfile}
                                ></DetailConnection>
                                <DetailAchievement
                                    isOpen={isOpen}
                                    check={menu}
                                ></DetailAchievement>
                                <DetailBackground
                                    isOpen={isOpen}
                                    check={menu}
                                ></DetailBackground>
                                <Myfeedback
                                    isOpen={isOpen}
                                    check={menu}
                                ></Myfeedback>
                            </div>

                            <hr className="pi-lineBottom"></hr>
                        </div>
                    </div>
                </div>

                <div className='personalDetailBox' style={{ display: `${modalDetail ? '' : 'none'}` }}>
                    <AddMember
                        isOpen={addMemberModal}
                        reload={addMemberReload}
                        toggle_modal_addMemberReload={toggle_modal_addMemberReload}
                        toggle_modal_addMember={toggle_modal_addMember}
                        checkData={memberInSpaceShip}
                        spaceShipId={spacedata.id}
                        memberSize={memberSize}
                        spaceShipName={spacedata.spaceName}
                    />
                    <div className='personalDetailBoxIn'>
                        <div className="personalDetailClose"><i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px", cursor: 'pointer' }} onClick={() => {
                            socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'UserInfomation' });
                            setModalDetail(false)
                        }}></i>
                        </div>
                        <ModalDetail
                            isOpen={modalDetail}
                            mode={'1'}
                            reload={addMemberReload}
                            toggle_modal_addMemberReload={toggle_modal_addMemberReload}
                            spaceShipId={spacedata.id}
                            toggle_modal_addMember={toggle_modal_addMember}
                            callBack={callBackMemberInSpaceShip}
                            setMemberSize={setMemberSize}
                            callBackspace={callBackspace}
                        />
                    </div>
                </div>


                <div className='personalDetailBox' style={{ display: `${isOpenCreate ? '' : 'none'}` }} >
                    <CreateSpaceship
                        isOpenCreate={isOpenCreate}
                        callBackColse={callBackColse}
                        sapphireData={sapphireData}
                        getAmountSapphire={() => { getAmountSapphire() }}
                        callBackReFilter={callBackReFilter}
                    />
                </div>


                <div className='alertKick' style={{ display: `${alertKick ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content', width: '429 !important' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content', width: '490px !important' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: `${errorMasgerList.isError ? 'red' : 'green'}` }}>
                                        {errorMasgerList.isError ? '! ERROR !' : 'SUCCESS'}
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        {errorMasgerList.isError ? 'WARNING' : 'SUCCESS'}
                                    </div>
                                </div>

                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickTextSec'>
                                        {errorMasgerList.massger.map((e) => {

                                            return <div> {e.message} </div>

                                        })}
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton mt-4'>
                                    <button className='alertKickButton' onClick={() => {

                                        if (displayPassword === true && errorMasgerList.isError !== true) {
                                            sessionStorage.clear(); window.location.href = '/'
                                        }
                                        if (errorMasgerList.isError === false) {
                                            setPersonalInfoCheck({
                                                isOpen: false
                                            })
                                            getInfomaionUserByID()
                                            setMenu('PROJECT')
                                        }

                                        setDisplayPassword(false)
                                        toggle_modal_alertKick()
                                        setErrorMasgerList({
                                            ...errorMasgerList,
                                            isError: false,
                                            massger: []
                                        })
                                    }}>OK</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <div className='alertKick' style={{ display: `${confrimModal ? '' : 'none'}` }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox'>
                            <div className='alertKickBoxIn'>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: 'orange' }}>
                                        ! warning !
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning'>
                                        WARNING
                                    </div>
                                </div>
                                <div className='row' style={{ height: '12.5%' }}>
                                    <div className='alertKickText'>
                                        Do you want to save data?
                                    </div>
                                </div>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickTextSec'>
                                        You won't be able to revert this!
                                    </div>
                                </div>
                                <div className='row alertKickBoxButton' >


                                    <button className='alertKickConfirm mr-2' onClick={() => {
                                        toggle_modal_confrimModal()
                                        CheckBefoSave()
                                    }}>YES</button>
                                    <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                        toggle_modal_confrimModal()
                                    }}>NO</button>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>



            </div>

            {loading && (
                <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
                    <div className='alertKickBoxShadow'>
                        <div className='alertKickBox' style={{ height: 'fit-content' }}>
                            <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                                <div className='row' style={{ height: '25%' }}>
                                    <div className='alertKickHead' style={{ color: 'green' }}>
                                        Loading
                                    </div>
                                </div>
                                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                    <div className='alertKickWarning' style={{ border: 'none' }}>
                                        <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            <UserTimesheetDay
                isOpen={personallTimeSheetDayReport}
                togglepersonallTimeSheetDayReport={togglepersonallTimeSheetDayReport}
                user_id={sessionStorage.getItem('user_id')}
                federation_name={'wiiu'}
                onElement={onElement}
                closeElement={closeElement}
                check = {"TIMESHEET"}
            />
        </>
    )
}
export default PersonalInfo