import React, { useState, useEffect } from "react";
import "./databaseGen.css";
import { Checkbox, Upload, ConfigProvider, Button, Input, message, Select, Switch, Modal  } from "antd";
import uploadIcon from "../../../assets/img/icon/upload.png";
import Configs from "../../../config";
import styled from "styled-components";
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import backIcon from '../../../components/spaceshipWorkload/Image/Icon.png'
import { label } from "yet-another-react-lightbox";
import { faL } from "@fortawesome/free-solid-svg-icons";

const MyP = styled.p`
  font-family: Oxanium;
  margin: 0;
  font-size: 14px;
  color: #fff;
  line-height: 20px;
`;

const { Dragger } = Upload;
const { TextArea } = Input;


function ElementDatabaseGen({ isOpen, toggle_modal_databaseMgtConfig, openElement_p, onElement_p,closeElement }) {
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [databaseList, setDatabaseList] = useState([]);
  const [tableDataList, setTableDatabaseList] = useState([]);
  const [dataFieldList, setDataFieldList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [test, setTest] = useState({ test: '' });
  const [dsTempId, setdsTempId] = useState({ ds_id: '' });
  const [selectAll, setSelectAll] = useState(false);
  const [originalDatabaseList, setOriginalDatabaseList] = useState([]);
  const [originalDatabaseTableList, setOriginalDatabaseTableList] = useState([]);
  const [isOpenDel, setIsOpenDel] = useState(false);
  const toggle_modal_confrimModalDel = () => {
    setIsOpenDel(!isOpenDel)
  };
  const [delData, setDelData] = useState({
    status:"",
    id:"",
    is_use:"",
    mode:""
  });
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryTable, setSearchQueryTable] = useState('');
  const [isOpenMassage, setIsOpenMassage] = useState(false);
  const toggle_modal_isOpenMassage = () => {

    setSaveSchema((prevState) => ({
      ...prevState,
      mode:"Add",
      dsc_sname:"",
      dsc_description:""
    }));
    setSaveTable((prevState) => ({
      ...prevState,
      mode:"Add",
      ds_table_name:"",
      ds_remark:"",
      // ds_dsc_id:"",
      ds_created_by:sessionStorage.getItem('user_id') || null,
      ds_updated_by:sessionStorage.getItem('user_id') || null,
      ds_project_id:sessionStorage.getItem('project_id'),
    }));
    setIsOpenMassage(!isOpenMassage)
  };
  const [massageData, setMassageData] = useState({
    text:"",
  });



  const [modeDatabaseList, setModeDatabaseList] = useState({
    mode:"schema",
    project_name:"",
    schema_name_show:"",
    table_name_show:""
  });
  const [saveSchema, setSaveSchema] = useState({
    mode:"Add",
    dsc_sname:"",
    dsc_description:"",
    dsc_created_by:sessionStorage.getItem('user_id') || null,
    dsc_updated_by:sessionStorage.getItem('user_id') || null,
    dsc_project_id:sessionStorage.getItem('project_id')
  });

  const [saveTable, setSaveTable] = useState({
    mode:"Add",
    ds_table_name:"",
    ds_remark:"",
    ds_dsc_id:"",
    ds_created_by:sessionStorage.getItem('user_id') || null,
    ds_updated_by:sessionStorage.getItem('user_id') || null,
    ds_project_id:sessionStorage.getItem('project_id'),
  });



// console.log("databaseList: ",databaseList);

const handleSelectRow = (checked, index) => {
  const updatedList = [...databaseList];
  updatedList[index].isSelected = checked;
  
  setDatabaseList(updatedList);
  
  const allSelected = updatedList.every(row => row.isSelected);
  setSelectAll(allSelected); 
};

const handleSearchDatabase = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  const filteredResults = originalDatabaseList.filter(item =>
      (item.dsc_sname || '').toLowerCase().includes(query.trim().toLowerCase())
  );
  setDatabaseList(filteredResults);
};

const handleSearchTable = (e) => {

  const query = e.target.value;
  setSearchQueryTable(query);

  const filteredResults = originalDatabaseTableList.filter(item =>
      (item.ds_table_name || '').toLowerCase().includes(query.trim().toLowerCase())
  );
  setTableDatabaseList(filteredResults);
};


const handleSelectAll = (checked) => {
  const updatedList = databaseList.map(row => ({
    ...row,
    isSelected: checked,
  }));
  
  setDatabaseList(updatedList);
  setSelectAll(checked); 
};

      const [isModalOpen, setIsModalOpen] = useState(false);
      const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setSaveTable((prevState) => ({
          ...prevState,
          mode:"Add",
        }));
        saveSchemafunc();
       
      };
      const handleCancel = () => {
        setSaveSchema({
          mode:"Add",
          dsc_sname:"",
          dsc_description:""
        });
        setIsModalOpen(false);
      };

      console.log("dsTempId: ", dsTempId);
      
      const [isModalOpenTable, setIsModalOpenTable] = useState(false);
      const showModalTable = () => {
        setSaveTable((prevState) => ({
          ...prevState,
          mode:"Add",
        }));
        setIsModalOpenTable(true);
      };
      const handleOkTable = () => {
        saveTablefunc();
      
      };
      const handleCancelTable = () => {
        setSaveTable((prevState) => ({
          ...prevState,
          mode:"Add",
          ds_table_name:"",
          ds_remark:"",
          // ds_dsc_id:"",
          ds_created_by:sessionStorage.getItem('user_id') || null,
          ds_updated_by:sessionStorage.getItem('user_id') || null,
          ds_project_id:sessionStorage.getItem('project_id'),
        }));
        setIsModalOpenTable(false);
      };

// console.log("dsTempId: ",dsTempId);


const cleanIsSelect = () =>{
  const updatedDatabaseList = databaseList.map(({ isSelected, ...rest }) => rest);
setDatabaseList(updatedDatabaseList);
setSelectAll(false);

}


  async function filterSchemaAll() {
    setLoading(true);
    let temp = {
      project_id:sessionStorage.getItem('project_id')
    };

    await axios({
        method: 'post',
        url: `${Configs.TTT_Game_Service}/api/database/filterSchemaAll`,
        headers: {
            'Authorization': sessionStorage.getItem('access_token'),
            'X-TTT': `${Configs.API_TTT}`,
            'Content-Type': 'application/json',
        },
        data: temp
    })
        .then(async function (response) {
            // console.log(response.data);  
            
            setDatabaseList(response.data);
            setOriginalDatabaseList(response.data);
            setTest({ ...test, test: '' });
            test.test = '';
            setLoading(false);
            // console.log(response);

        })
        .catch(function (error) {
            console.log(error);
        });
}

async function getByIdSchema(dsc_id) {
  let temp = {
    dsc_id:dsc_id
  };

  await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/getByIdSchema`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: temp
  })
      .then(async function (response) {
          // console.log("getByIdSchema",response.data);  
          let temp_data = response.data[0];

          setSaveSchema({...saveSchema, 
            mode:"Edit", 
            dsc_id:temp_data.dsc_id,
            dsc_sname:temp_data.dsc_sname,
            dsc_description:temp_data.dsc_description,
            dsc_updated_by:sessionStorage.getItem('user_id')  || null,
            ds_project_id:sessionStorage.getItem('project_id'),
          });
          setTest({ ...test, test: '' });
          test.test = '';

          // console.log(response);

      })
      .catch(function (error) {
          console.log(error);
      });
}

// console.log("saveTable: ",saveTable);

async function getByIdTable(ds_id) {
  let temp = {
    ds_id:ds_id
  };

  await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/getByIdTable`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: temp
  })
      .then(async function (response) {
          // console.log("getByIdSchema",response.data);  
          let temp_data = response.data[0];

          setSaveTable({...saveTable, 
            mode:"Edit", 
            ds_id:temp_data.ds_id,
            ds_dsc_id:temp_data.ds_dsc_id,
            ds_table_name:temp_data.ds_table_name,
            ds_remark:temp_data.ds_remark,
            ds_updated_by:sessionStorage.getItem('user_id')  || null,
            ds_project_id:sessionStorage.getItem('project_id'),
          });
          setTest({ ...test, test: '' });
          test.test = '';

          // console.log(response);

      })
      .catch(function (error) {
          console.log(error);
      });
}

async function getJsonField(ds_id) {
  setLoading(true);
  let temp = {
    ds_id:ds_id
  };
  setdsTempId({...dsTempId, ds_id:ds_id});
  await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/getJsonField`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: temp
  })
      .then(async function (response) {
          // console.log("getByIdSchema",response.data);  
          let temp_data = response.data[0];
          // console.log("temp_data.ds_table_json",temp_data.ds_table_json); 
          if(temp_data.ds_table_json){
            // console.log("temp_data: ",temp_data.ds_table_json[0]);
            setDataFieldList(temp_data.ds_table_json)
          }
       
       
          setTest({ ...test, test: '' });
          test.test = '';
          setLoading(false);
          // console.log(response);

      })
      .catch(function (error) {
          console.log(error);
      });
}

async function saveJsonField() {
  let temp = {
    ds_id:dsTempId.ds_id,
    data_json:dataFieldList||{}
  };

  await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/saveJsonField`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: temp
  })
      .then(async function (response) {

       
          getJsonField(dsTempId.ds_id);
          setTest({ ...test, test: '' });
          test.test = '';

      })
      .catch(function (error) {
          console.log(error);
      });
}

async function genDatabase() {
  const hasSelected = databaseList.some(item => item.isSelected === true);

  if(hasSelected){
  setLoading(true);
  await axios({
    method: 'post',
    url: `${Configs.TTT_Game_Service}/api/database/genDatabase`,
    headers: {
      'Authorization': sessionStorage.getItem('access_token'),
      'X-TTT': `${Configs.API_TTT}`,
      'Content-Type': 'application/json',
    },
    data: databaseList,
    responseType: 'blob' // บอก Axios ว่าผลลัพธ์เป็นไฟล์ (blob)
  })
    .then(function (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'databases.zip'); // ตั้งชื่อไฟล์เป็น ZIP
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link); // ลบลิงก์หลังจากดาวน์โหลด
      setLoading(false);
    })
    .catch(function (error) {
      console.log(error);
    });
  }else{
    toggle_modal_isOpenMassage();
    setMassageData({...massageData, text:"The database has not been selected."})
  }
}


async function saveTablefunc(mode, ds_id){
  
  if(mode === "Del"){
    setLoading(true);
    let temp = {
      mode:"Del", 
      ds_id:ds_id
    }
    
    setSaveTable({...saveTable, mode:"Del", ds_id:ds_id});
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/saveTable`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: temp
  })
      .then(async function (response) {
        setSaveTable((prevState) => ({
          ...prevState,
          mode:"",
          ds_table_name:"",
          ds_remark:"",
          // ds_dsc_id:"",
          ds_created_by:sessionStorage.getItem('user_id') || null,
          ds_updated_by:sessionStorage.getItem('user_id') || null,
          ds_project_id:sessionStorage.getItem('project_id'),
        }));
        filterDatabaseGenTable(saveTable.ds_dsc_id);
          setTest({ ...test, test: '' });
          test.test = '';

          setDelData({...delData,     
            status:"",
            id:"",
            is_use:"",
            mode:""
          });
          setLoading(false);
          // console.log(response);

      })
      .catch(function (error) {
          console.log(error);
      });
  }else{

    const error_list = [];
     
    if (saveTable.ds_table_name.trim() === '' || saveTable.ds_table_name == null) {
        let temp_err = {
            message: "You must enter Table Name."
        }
        error_list.push(temp_err);
    }


    if (error_list.length > 0) {
      setIsModalOpenTable(false);
      var err_message = [];
      for (var e = 0; e < error_list.length; e++) {
          err_message.push(error_list[e].message);
      }

     setMassageData({...massageData, text:err_message});
     toggle_modal_isOpenMassage();
  }else {
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/saveTable`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: saveTable
  })
      .then(async function (response) {
        if(response.data.status === false){
          setIsModalOpenTable(false);
          setMassageData({...massageData, text:response.data.text});
          toggle_modal_isOpenMassage();
        }else{
        setSaveTable((prevState) => ({
          ...prevState,
          mode:"",
          ds_table_name:"",
          ds_remark:"",
          // ds_dsc_id:"",
          ds_created_by:sessionStorage.getItem('user_id') || null,
          ds_updated_by:sessionStorage.getItem('user_id') || null,
          ds_project_id:sessionStorage.getItem('project_id'),
        }));
        setIsModalOpenTable(false);
        filterDatabaseGenTable(saveTable.ds_dsc_id);
          setTest({ ...test, test: '' });
          test.test = '';
      }
          // console.log(response);

      })
      .catch(function (error) {
          console.log(error);
      });
    }
  }

}

async function saveSchemafunc(mode, dsc_id){
  
  if(mode === "Del"){
    setLoading(true);
    let temp = {
      mode:"Del", 
      dsc_id:dsc_id
    }
    
    setSaveSchema({...saveSchema, mode:"Del", dsc_id:dsc_id});
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/saveSchema`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: temp
  })
      .then(async function (response) {
        setSaveSchema({
          mode:"Add",
          dsc_sname:"",
          dsc_description:"",
          dsc_created_by:sessionStorage.getItem('user_id') || null,
          dsc_updated_by:sessionStorage.getItem('user_id') || null,
          dsc_project_id:sessionStorage.getItem('project_id')
        });
        filterSchemaAll();
          setTest({ ...test, test: '' });
          test.test = '';

          setDelData({...delData,     
            status:"",
            id:"",
            is_use:"",
            mode:""
          });
          setLoading(false);
          // console.log(response);

      })
      .catch(function (error) {
          console.log(error);
      });
  }else{

    const error_list = [];
     
    if (saveSchema.dsc_sname.trim() === '' || saveSchema.dsc_sname == null) {
        let temp_err = {
            message: "You must enter Database Name."
        }
        error_list.push(temp_err);
    }


    if (error_list.length > 0) {
      setIsModalOpen(false);
      var err_message = [];
      for (var e = 0; e < error_list.length; e++) {
          err_message.push(error_list[e].message);
      }

     setMassageData({...massageData, text:err_message});
     toggle_modal_isOpenMassage();
  }else {
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/database/saveSchema`,
      headers: {
          'Authorization': sessionStorage.getItem('access_token'),
          'X-TTT': `${Configs.API_TTT}`,
          'Content-Type': 'application/json',
      },
      data: saveSchema
  })
      .then(async function (response) {
        if(response.data.status === false){
          setIsModalOpen(false);
          setMassageData({...massageData, text:response.data.text});
          toggle_modal_isOpenMassage();
        }else{
        setSaveSchema({
          mode:"Add",
          dsc_sname:"",
          dsc_description:"",
          dsc_created_by:sessionStorage.getItem('user_id') || null,
          dsc_updated_by:sessionStorage.getItem('user_id') || null,
          dsc_project_id:sessionStorage.getItem('project_id')
        });
        setIsModalOpen(false);
        filterSchemaAll();
          setTest({ ...test, test: '' });
          test.test = '';
      }
          // console.log(response);

      })
      .catch(function (error) {
          console.log(error);
      });
    }
  }

}

        let OptionSelectType = [
          {
            label: "Integer",
            value: "integer"
          },
          {
            label: "Big Integer",
            value: "bigint"
          },
          {
            label: "Serial",
            value: "serial"
          },
          {
            label: "Numeric",
            value: "numeric"
          },
          {
            label: "Boolean",
            value: "boolean"
          },
          {
            label: "Text",
            value: "text"
          },
          {
            label: "Varchar",
            value: "varchar"
          },
          {
            label: "Date",
            value: "date"
          },
          {
            label: "Timestamp",
            value: "timestamp"
          },
          {
            label: "Time",
            value: "time"
          },
          {
            label: "JSON",
            value: "json"
          },
          {
            label: "JSONB",
            value: "jsonb"
          },
          {
            label: "Array",
            value: "array"
          },
          {
            label: "UUID",
            value: "uuid"
          }
        ];

        // console.log("dataFieldList: ", dataFieldList);
        
      async function AddField() {
        let tempFieldData = {
          field_name:"",
          data_type:"",
          length:"",
          scale:"",
          not_null:false,
          primary_key:false,
          remark:"",
          status:"add"
        };
        setDataFieldList((prevState) => ([...prevState, tempFieldData]));
      }

      async function RemoveField(indexToRemove) {

        const updatedList = dataFieldList.filter((_, index) => index !== indexToRemove);
        setDataFieldList(updatedList);
      }

async function filterDatabaseGenTable(dsc_id, dsc_sname) {
  setLoading(true);
  let temp = {
    dsc_id:dsc_id
  };
  await axios({
    method: 'post',
    url: `${Configs.TTT_Game_Service}/api/database/filterDatabaseGenTable`,
    headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
    },
    data: temp
})
    .then(async function (response) {
        console.log(response.data);  

     
        setTableDatabaseList(response.data);
        setOriginalDatabaseTableList(response.data);
        setTest({ ...test, test: '' });
        test.test = '';
        setLoading(false);
        // console.log(response);

    })
    .catch(function (error) {
        console.log(error);
    });
}
//  console.log("modeDatabaseList: ", modeDatabaseList);
 
async function checkProject() {
  let temp = {
    project_id:sessionStorage.getItem('project_id')
  };
  await axios({
    method: 'post',
    url: `${Configs.TTT_Game_Service}/api/database/checkProject`,
    headers: {
        'Authorization': sessionStorage.getItem('access_token'),
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
    },
    data: temp
})
    .then(async function (response) {

      // console.log("response.data: ", response.data);
      
        setModeDatabaseList({...modeDatabaseList, project_name:response.data[0].project_name})
        setTest({ ...test, test: '' });
        test.test = '';

        // console.log(response);

    })
    .catch(function (error) {
        console.log(error);
    });
}


  useEffect(()=>{
    if(isOpen === true){
      checkProject();
      filterSchemaAll();
    }else{
      setModeDatabaseList({...modeDatabaseList, 
        mode:"schema",
        project_name:"",
        schema_name_show:"",
        table_name_show:""
      })
    }
  
    // console.log("DB:>>", isOpen);
  },[isOpen])

  return (
    <>
    <ConfigProvider
                theme={{
                    token: {
                        colorInfo: '#85BFFF',
                        colorBorder: '#0047FF',
                        colorText: '#FFF',
                        // colorTextQuaternary: 'White',
                        colorTextDescription: 'White',
                        colorBgContainer: '#102456',
                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#0047FF',
                        colorBgBase: '#051F6C',
                        // colorTextQuaternary: '#FFFFFF',
                        // colorFillTertiary: 'blue',
                        colorTextBase: 'White',
                        // colorPrimaryBg: '#85BFFF',

                    }
                }}
            >
        <div className='fedDatabaseBg2' style={{ display: `${isOpen ? '' : 'none'}` }}>
        <div className='row fedWorkloadBackBox' style={{ margin: 0, padding: 0 }}>
                    <div className='fedWorkloadBackButton' onClick={() => {
                      if(modeDatabaseList.mode === 'table'){
                        setDataFieldList([]);
                        setModeDatabaseList((prevState) => ({
                          ...prevState,
                          mode: "schema",
                          schema_name_show:'',
                          table_name_show: '',
                        }));
                      }else if(modeDatabaseList.mode === 'field'){
                        setDataFieldList([]);
                        setModeDatabaseList((prevState) => ({
                          ...prevState,
                          mode: "table",
                          table_name_show: '',
                        }));
                      }else{
                        closeElement()
                        toggle_modal_databaseMgtConfig()
                      }
                
                    }}>
                        <img src={backIcon} style={{ width: '100%', height: '100%' }}></img>
                    </div>
                    <div className='fedDatabaseGenBackLine'></div>
                    <div style={{display:'flex', flexDirection:"column"}}>
                        <div className='fedDatabaseGenBackNameHead'>Database Config & Generate Code SQL File</div>
                    <div style={{overflow:"auto"}}>
                    <span className='databaseGenBackNameText' style={{padding:"10px"}}
                            onClick={()=>{
                              setDataFieldList([]);
                              setModeDatabaseList((prevState) => ({
                                ...prevState,
                                mode: "schema",
                                schema_name_show:'',
                                table_name_show: '',
                              }));
                            }}
                    >{modeDatabaseList.project_name || ""}</span>  
                    {modeDatabaseList.schema_name_show !== '' ? <span className="databaseGenNameTextProject">{' -> '}</span> : ''}

                        <span className="databaseGenBackNameText"
                        onClick={()=>{
                          setDataFieldList([]);
                          setModeDatabaseList((prevState) => ({
                            ...prevState,
                            mode: "table",
                            table_name_show: '',
                          }));
                        }}
                        > {modeDatabaseList.schema_name_show !== ''? modeDatabaseList.schema_name_show : ""}</span>

                        <span className="databaseGenNameTextProject"
                    
                        >{modeDatabaseList.table_name_show !== '' ? ' -> '+ modeDatabaseList.table_name_show : ""}</span>
                    </div>
                     
                    </div>
                </div>

                <div className="" style={{ height: "6%", padding: 0, display:"flex", gap:"12px", flexDirection:"column" }}>
                <hr style={{ margin: 0, borderColor: "#0047FF" }} />
                <div style={{display:"flex", justifyContent:"space-between", width:"100%"}}>
                {modeDatabaseList.mode === 'schema' ? 
                        <div className='InputSearchDatabaseGen' style={{ width: '300px' }}>
                                
                        <input
                            className='inputSearch'
                            placeholder='search'
                            type='text'
                            value={searchQuery}
                            onChange={handleSearchDatabase}
                        />
                  </div> :
                  modeDatabaseList.mode === 'table' ?
                  <div className='InputSearchDatabaseGen' style={{ width: '300px' }}>
                                
                  <input
                      className='inputSearch'
                      placeholder='search'
                      type='text'
                      value={searchQueryTable}
                      onChange={handleSearchTable}
                  />
                  </div> :<div></div>
              }
        
                
                  <div
                    className=""
                    style={{ padding: 0, display: "flex", justifyContent: "flex-end" }}
                  >
                    {modeDatabaseList.mode === 'schema' ?
                                <div style={{display:'flex', gap:"10px"}}>

                                <button className="btn-Gen-Schema-DatabaseGen" 
                                onClick={()=>{
                                  genDatabase();
                                  cleanIsSelect();
                                  }}
                                >
                                  <span style={{ fontSize: "18px", fontFamily: "Oxanium", color: "white" }}>
                                  Generation Database
                                  </span>
                                </button>
                                  <button className="btn-Add-Schema-DatabaseGen" 
                                  onClick={()=>{
                                      showModal()
                                    }}
                                  >
                                    <span style={{ fontSize: "18px", fontFamily: "Oxanium", color: "white" }}>
                                      + Add Database
                                    </span>
                                  </button>
                                  </div>
                                   : 
                    modeDatabaseList.mode === 'table' ? 
                            <button className="btn-Add-Schema-DatabaseGen" 
                            onClick={()=>{showModalTable()}}
                            >
                              <span style={{ fontSize: "18px", fontFamily: "Oxanium", color: "white" }}>
                                + Add Table
                              </span>
                            </button> : 
                    modeDatabaseList.mode === 'field' ? 
                    <div style={{display:"flex", gap:"10px"}}>
                  <button className="btn-Add-Schema-DatabaseGen" 
                    onClick={()=>{
                      AddField();
                    }}
                    >
                      <span style={{ fontSize: "18px", fontFamily: "Oxanium", color: "white" }}>
                        Add Field
                      </span>
                    </button> 

                    <button className="btn-Add-Schema-DatabaseGen" 
                    onClick={()=>{
                      saveJsonField();
                    }}
                    >
                      <span style={{ fontSize: "18px", fontFamily: "Oxanium", color: "white" }}>
                        Save Field
                      </span>
                    </button> 
             
                    </div>
            
                    : <div></div>
                  }

      


                  </div>
                </div>
              </div>

   
          <div style={{overflow:"auto"}}>
            {modeDatabaseList.mode === 'schema' ? 
                <div>
                  {/* mode  schema*/}
                  <table className="custom-table-databasegen">
                  <thead >
                    <tr>
                      <th style={{ width: "100px", zIndex:100000, fontSize:"14px"}}>
                        <div style={{display:"flex", justifyContent:"space-evenly"}}>
                        <Checkbox
                          checked={selectAll}
                          onChange={(e) => handleSelectAll(e.target.checked)}
                        />
                        <div style={{paddingTop:"5px"}}>No.</div> 
                        </div>
                        </th>
                      <th style={{zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Database Name</div></th>
                      <th style={{zIndex:100000, fontSize:"14px"}}> <div style={{textAlign:"center"}}>Descriptions</div></th>
                      <th style={{ width: "80px", zIndex:100000, fontSize:"14px" }}><div style={{textAlign:"center"}}>MGT</div></th>
                    </tr>
                </thead>
                <tbody>
                  {databaseList.map((row, index) => (
                    <tr key={index}>
                      <td style={{fontSize:"14px"}}><div style={{textAlign:"center", display:"flex", justifyContent:"space-evenly"}}>
                      <Checkbox
                        checked={row.isSelected || false}
                        onChange={(e) => handleSelectRow(e.target.checked, index)}
                      />
                       <div style={{paddingTop:"4px"}}>{index+1}</div> 
                        </div></td>
                      <td style={{fontSize:"14px"}}>
                        <div className="schema-name-class"
                          onClick={()=> {
                            setSaveTable({...saveTable, ds_dsc_id:row.dsc_id})
                            setModeDatabaseList((prevState) => ({
                              ...prevState,
                              mode: "table",
                              schema_name_show: row.dsc_sname,
                            }));
                            filterDatabaseGenTable(row.dsc_id, row.dsc_sname);
                           
                          }}
                        >{row.dsc_sname || ""}</div>
                      </td>
                      <td style={{fontSize:"14px"}}><span>{row.dsc_description || ""}</span></td>
                      <td style={{fontSize:"14px"}}>
                      <div style={{display:'flex', gap: '10px', justifyContent:"space-evenly"}}>
                        <span class="icon-container-risk">
                          <i class="fa fa-eye" style={{fontSize:"14px"}}
                            onClick={()=> {
                              setSaveTable({...saveTable, ds_dsc_id:row.dsc_id})
                              setModeDatabaseList((prevState) => ({
                                ...prevState,
                                mode: "table",
                                schema_name_show: row.dsc_sname,
                              }));
                              filterDatabaseGenTable(row.dsc_id, row.dsc_sname);
                             
                            }}
                          ></i>
                          </span>
                          <span class="icon-container-risk">
                            <i class="fa fa-pen" style={{fontSize:"14px"}}
                                 onClick={()=>{
                                  getByIdSchema(row.dsc_id);
                                  setSaveSchema({...saveSchema, mode:"Edit", dsc_project_id:sessionStorage.getItem('project_id'),});
                                  showModal();
                                }}
                            ></i>
                          </span>
                          <span class="icon-container-risk">
                            <i class="fas fa-trash" style={{fontSize:"14px"}}
                              onClick={()=>{
                                setDelData({...delData, status:"Del", id:row.dsc_id, mode:"schema"});
                                toggle_modal_confrimModalDel();
                              //  saveSchemafunc("Del", row.dsc_id);
                              }}
                            ></i>
                            </span></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
                  </table>
                </div> : 
            modeDatabaseList.mode === 'table' ?
                <div>
                  {/* mode  table*/}
                  <table className="custom-table-databasegen">
              <thead >
                <tr>
                  <th style={{ width: "50px", fontSize:"14px"}}> 
                
                    <div>No.</div> 
                    </th>
                  <th style={{zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Table Name</div></th>
                  <th style={{zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Remark</div></th>
                  <th style={{ width: "80px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>MGT</div></th>
                </tr>
            </thead>
            <tbody>
              {tableDataList.map((row2, index2) => (
                <tr key={index2}>
                  <td style={{fontSize:"14px"}}><div style={{textAlign:"center"}}>{index2+1}</div></td>
                  <td style={{fontSize:"14px"}}>
                  <div className="schema-name-class"
                          onClick={()=> {
                            getJsonField(row2.ds_id);
                            setModeDatabaseList((prevState) => ({
                              ...prevState,
                              mode: "field",
                              table_name_show: row2.ds_table_name,
                            }));
                          }}
                        >{row2.ds_table_name || ""}</div>
                 </td>
                  <td style={{fontSize:"14px"}}><span>{row2.ds_remark || ""}</span></td>
                  <td style={{fontSize:"14px"}}>
                  <div style={{display:'flex', gap: '10px', justifyContent:"space-evenly"}}>
                    <span class="icon-container-risk">
                      <i class="fa fa-eye" style={{fontSize:"14px"}}
                                            onClick={()=> {
                                              getJsonField(row2.ds_id);
                                              setModeDatabaseList((prevState) => ({
                                                ...prevState,
                                                mode: "field",
                                                table_name_show: row2.ds_table_name,
                                              }));
                                            }}
                      ></i>
                      </span>
                      <span class="icon-container-risk">
                        <i class="fa fa-pen" style={{fontSize:"14px"}}
                        onClick={()=>{
                          getByIdTable(row2.ds_id);
                          showModalTable();
                        }}
                        ></i>
                      </span>
                      <span class="icon-container-risk">
                        <i class="fas fa-trash" style={{fontSize:"14px"}}
                        onClick={()=>{
                          setDelData({...delData, status:"Del", id:row2.ds_id, mode:"table"});
                          toggle_modal_confrimModalDel();
                          // saveTablefunc("Del", row2.ds_id);
                        }}
                        ></i>
                        </span></div>
                  </td>
                </tr>
              ))}
            </tbody>
                  </table>
                </div> : 
            modeDatabaseList.mode === 'field' ?
                <div>
                  {/* mode  field*/}
                  <table className="custom-table-databasegen">
                  <thead >
                    <tr >
                      <th style={{ width: "50px", zIndex:100000, fontSize:"14px" }}>No. </th>
                      <th style={{ width: "400px", zIndex:100000, fontSize:"14px" }}><div style={{textAlign:"center"}}>Field Name</div></th>
                      <th style={{ width: "300px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Data Type</div></th>
                      <th style={{ width: "150px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Length</div></th>
                      <th style={{ width: "150px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Scale</div></th>
                      <th style={{ width: "150px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Not Null</div></th>
                      <th style={{ width: "150px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Primary Key</div></th>
                      {/* <th><div style={{textAlign:"center"}}></div></th> */}
                      <th style={{ width: "250px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>Remark</div></th>
                      <th style={{ width: "150px", zIndex:100000, fontSize:"14px"}}><div style={{textAlign:"center"}}>MGT</div></th>
                    </tr>
                </thead>
         
                <tbody >
                  {dataFieldList.map((row3, index) => (
                    <tr key={index}>
                      <td><div style={{textAlign:"center"}}>{index+1}</div></td>
                      <td><div>     
                        <TextArea
                          value={row3.field_name}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            const updatedList = [...dataFieldList];
                            updatedList[index].field_name = e.target.value;
                            setDataFieldList(updatedList);
                          }}
                          ></TextArea>
                        </div>
                      </td>
                      <td>
                        <div>
                        <Select
                          className=''
                          style={{ 
                            fontFamily: "Oxanium",
                            width: '100%', 
                            color: '#FFFFFF',                      
                          }}
                          options={OptionSelectType}  
                          value={row3.data_type} 
                          disabled={row3.status !== "add"} 
                          onChange={(value) => { 
                            const updatedList = [...dataFieldList];
                            updatedList[index].data_type = value;  
                            setDataFieldList(updatedList); 
                          }}
                        />
                        </div>
                      </td>
                      <td>
                        <div>
                        <TextArea
                          value={row3.length}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            const inputValue = e.target.value.replace(/[^0-9]/g, '');
                            const limitedValue = inputValue.slice(0, 3); 
                            const updatedList = [...dataFieldList];
                            updatedList[index].length = limitedValue; 
                            setDataFieldList(updatedList);
                          }}
                          ></TextArea>
                        </div>
                      </td>
                      <td>
                        <div>
                        <TextArea
                          value={row3.scale}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            const inputValue = e.target.value.replace(/[^0-9]/g, '');
                            const limitedValue = inputValue.slice(0, 3); 
                            const updatedList = [...dataFieldList];
                            updatedList[index].scale = limitedValue; 
                            setDataFieldList(updatedList);
                          }}
                          ></TextArea>
                        </div>
                      </td>
                      <td>
                        <div style={{textAlign:"center"}}>
                        <Switch checked={row3.not_null} 
                          onChange={(e) => {
                            const updatedList = [...dataFieldList];
                            updatedList[index].not_null = e;
                            setDataFieldList(updatedList);
                          }}
                          />
                        </div>
                      </td>
                      <td>
                      <div style={{textAlign:"center"}}>
                        <Switch checked={row3.primary_key} 
                          onChange={(e) => {
                            const updatedList = [...dataFieldList];
                            updatedList[index].primary_key = e;
                            setDataFieldList(updatedList);
                          }}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                        <TextArea
                          value={row3.remark}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            const updatedList = [...dataFieldList];
                            updatedList[index].remark = e.target.value;
                            setDataFieldList(updatedList);
                          }}
                          ></TextArea>
                        </div>
                      </td>
                      <td>
                      <div style={{display:'flex', gap: '10px', justifyContent:"space-around"}}>
                     
                          {/* <span class="icon-container-risk">
                            <i class="fa fa-pen" style={{fontSize:"14px"}}></i>
                          </span> */}
                          <span class="icon-container-risk">
                            <i class="fas fa-trash" style={{fontSize:"14px"}}
                            onClick={() => RemoveField(index)}
                            ></i>
                            </span></div>
                      </td>
                    </tr>
                  ))}
                </tbody>
  
           
                  </table>
                </div> : <div></div>
              }
       

          </div>


        </div>

        <Modal title={saveSchema.mode+" Database"} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
           className="custom-modal-gendatabase"
           footer={[
            <Button key="submit" type="primary" onClick={handleOk}>
              OK
            </Button>,
            <Button key="cancel" onClick={handleCancel}>
              Cancel
            </Button>,
          ]}
        >
          <div style={{display:"flex", flexDirection:"column"}}>
            <div>Database Name</div>
          <TextArea
                          value={saveSchema.dsc_sname}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            setSaveSchema({...saveSchema, dsc_sname:e.target.value});
                          }}
                          ></TextArea>
       <div>Description</div>
          <TextArea
                          value={saveSchema.dsc_description}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            setSaveSchema({...saveSchema, dsc_description:e.target.value});
                          }}
              ></TextArea>
          </div>
      </Modal>

      <Modal title={saveTable.mode+" Table"} open={isModalOpenTable} onOk={handleOkTable} onCancel={handleCancelTable}
           className="custom-modal-gendatabase"
        footer={[
          <Button key="submit" type="primary" onClick={handleOkTable}>
            OK
          </Button>,
          <Button key="cancel" onClick={handleCancelTable}>
            Cancel
          </Button>,
        ]}
      >
          <div style={{display:"flex", flexDirection:"column"}}>
            <div>Table Name</div>
          <TextArea
                          value={saveTable.ds_table_name}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            setSaveTable({...saveTable, ds_table_name:e.target.value});
                          }}
                          ></TextArea>
       <div>Remark</div>
          <TextArea
                          value={saveTable.ds_remark}
                          style={{ 
                            fontFamily: "Oxanium",
                            backgroundColor: '#102456', 
                            width: '100%', 
                            border: '1px solid #0047FF', 
                            color: '#FFFFFF',     
                            borderRadius:"5px"                 
                          }}
                          autoSize={{ minRows: 1 }}
                          type='text'
                          onChange={(e) => {
                            setSaveTable({...saveTable, ds_remark:e.target.value});
                          }}
              ></TextArea>
          </div>
      </Modal>

      <div className='alertKick' style={{ display: `${isOpenDel ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' >
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: 'orange' }}>
                                    ! warning !
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    WARNING
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    Do you want to Delete data?
                                </div>
                            </div>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickTextSec'>
                                    You won't be able to revert this!
                                </div>
                            </div>
                            <div className='row alertKickBoxButton' >
                                <button className='alertKickConfirm mr-2' onClick={() => {
                                    if(delData.mode === "schema"){
                                      saveSchemafunc(delData.status, delData.id);
                                    }
                                    else if(delData.mode === "table"){
                                      saveTablefunc(delData.status, delData.id);
                                    }
                                    toggle_modal_confrimModalDel();
                                }}>YES</button>

                                <button className='alertKickConcel ' style={{ border: '1px solid red !important' }} onClick={() => {
                                 toggle_modal_confrimModalDel();
                                }}>NO</button>


                            </div>

                        </div>
                    </div>
                </div>
            </div>


            <div className='alertKick' style={{ display: `${isOpenMassage ? '' : 'none'}` }}>
                <div className='alertKickBoxShadow' >
                    <div className='alertKickBox' >
                        <div className='alertKickBoxIn'>
                            <div className='row' style={{ height: '25%' }}>
                                <div className='alertKickHead' style={{ color: 'orange' }}>
                                    ! warning !
                                </div>
                            </div>
                            <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                                <div className='alertKickWarning'>
                                    WARNING
                                </div>
                            </div>
                            <div className='row' style={{ height: '12.5%' }}>
                                <div className='alertKickText'>
                                    {massageData.text}
                                </div>
                            </div>
                            <div className='row' style={{ height: '25%' }}>
                                {/* <div className='alertKickTextSec'>
                                    You won't be able to revert this!
                                </div> */}
                            </div>
                            <div className='row alertKickBoxButton' >
                                <button className='alertKickConfirm mr-2' onClick={() => {
                           toggle_modal_isOpenMassage()
                                }}>OK</button>


                            </div>

                        </div>
                    </div>
                </div>
            </div>




      {loading && (
        <div className='alertKick' style={{ position: 'fixed', zIndex: 9999999999 }}>
          <div className='alertKickBoxShadow'>
            <div className='alertKickBox' style={{ height: 'fit-content' }}>
              <div className='alertKickBoxIn' style={{ height: 'fit-content' }}>
                <div className='row' style={{ height: '25%' }}>
                  <div className='alertKickHead' style={{ color: 'green' }}>
                    Loading
                  </div>
                </div>
                <div className='row' style={{ height: '7.5%', display: 'flex', justifyContent: 'center' }}>
                  <div className='alertKickWarning' style={{ border: 'none' }}>
                    <div className="loadingspinnerna" style={{ width: '120px', height: '120px', marginBottom: '20px' }}></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

        </ConfigProvider>
    </>
  );
}

export default ElementDatabaseGen;