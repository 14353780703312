import React from "react";
import { useState, useEffect,useRef } from 'react'

import axios from "axios";
import moment from "moment";
import { io } from 'socket.io-client';
import logo_pointer from '../../../assets/img/iconSpaceShip/mouse-pointer.png';
import Configs from "../../../config";


import "./modalSelect.css";

function SelectProject(params) {

  const [openModal, setOpenModal] = useState('close')
  const [labelProject, setLabelProject] = useState('')
  const [searchLabel, setSearchLabel] = useState({
    value: ''
  })
  const [test, settest] = useState({ test: '' })
  const [projectTempm, setProjectTemp] = useState({
    pf_project_id: '',
    project_name: '',
    project_progress: '',
    spaceship_size: '',
    spaceship_name: '',
    pp_start_date: '',
    pp_finish_date: '',
    federation_name: '',
    pp_team_member: '',
    ul_file_name: '',
    ul_file_path: '',
    defcon_id: '',
    defcon_color: '',
    defcon_level: '',
    isLeader: '',
    riskCount: '',
    issueCount: '',
  })
  const [projectList, setProjectList] = useState([/* 
    { project_id: '1', project_name: 'project thai' },
    { project_id: '2', project_name: 'project eng' },
    { project_id: '3', project_name: 'project cha' },
    { project_id: '4', project_name: 'project game' },
    { project_id: '5', project_name: 'project mochi' },
    { project_id: '6', project_name: 'project anther' },
    { project_id: '7', project_name: 'project yyot' },
    { project_id: '8', project_name: 'project toiyi' },
    { project_id: '9', project_name: 'project xxoi' },
    { project_id: '10', project_name: 'project ppol' },
    { project_id: '11', project_name: 'project asds' },
   */])

  let [oleProjectList, setOleProjectList] = useState([])
  let [projectName,setProjectName] = useState({
    name:''
  })

  const [imgData, setImgData] = useState([]);

  async function getPtojectByUser() {
    let getUser_id = sessionStorage.getItem('user_id');
    var temp = {
      user_id: getUser_id,
      fed_id:sessionStorage.getItem('fed_id'),
      page: 1,
      enties: 99999,
      keySearch: ''
    }
    await axios({
      method: 'post',
      url: `${Configs.TTT_Game_Service}/api/userprofile/getProjecyAllByUser`,
      headers: {
        /*  Authorization: getToken(), */
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp
    })
      .then(async function (response) {


        let list = [];
        let project_id_main = projectTempm.pf_project_id || '';
        let switUSe = false
        if (response.data.length > 0) {
          response.data.map((e) => {
            var tem = {
              project_id: e.pf_project_id,
              project_name: e.project_name,
              project_progress: e.project_progress,
              spaceship_id: e.spaceship_id,
              spaceship_size: e.spaceship_size,
              spaceship_name: e.spaceship_name,
              pp_start_date: e.pp_start_date !== null && e.pp_start_date !== null ? moment(e.pp_start_date).format('DD MMM YYYY') : '',
              pp_finish_date: e.pp_finish_date !== null && e.pp_finish_date !== null ? moment(e.pp_finish_date).format('DD MMM YYYY') : '',
              federation_name: e.federation_name,
              pp_team_member: e.pp_team_member || 0,
              ul_file_name: e.ul_file_name,
              ul_file_path: e.ul_file_path,
              defcon_id: e.defcon_id,
              defcon_color: e.defcon_color,
              defcon_level: e.defcon_level,
              isLeader: e.isLeader,
              fd_img_id_model: e.fd_img_id_model,
              fd_img_id_spaceship: e.fd_img_id_spaceship
            }
            list.push(tem)
          })

          let getPRoject = localStorage.getItem(`projectAct`)

          let url = '';

          if (getPRoject !== null) {
            let pranJson = JSON.parse(getPRoject)
            if (pranJson.user_on_project == getUser_id) {
              project_id_main = pranJson.project_id
              switUSe = false
              /* console.log('test 00',project_id_main); */
            } else {
              localStorage.removeItem("projectAct");

              switUSe = true
            }

            // if (pranJson.project_id) {
            //   let checkImg = response.data.filter((e) => { return e.pf_project_id == pranJson.project_id });
            //   if (checkImg.length > 0) {
            //     if (checkImg[0].fd_img_id_spaceship) {
            //       let checkIsImg = imgData.filter((e)=>{return e.fd_img_id == checkImg[0].fd_img_id_spaceship});
            //       if (checkIsImg.length > 0) {
            //         url = checkIsImg[0].url;
            //       } else {
            //         let response2 = await axios.get(`https://api.box.com/2.0/files/${checkImg[0].fd_img_id_spaceship}/content`, {
            //           headers: {
            //             Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            //           },
            //           responseType: 'blob',
            //         });
            //         url = URL.createObjectURL(response2.data);
                    
            //         let temp = imgData;
            //         temp.push({
            //           fd_img_id: checkImg[0].fd_img_id_spaceship,
            //           url: URL.createObjectURL(response2.data)
            //         });
            //         setImgData(temp);
            //       }
            //     } else if ((checkImg[0].fd_img_id_model)) {
            //       let checkIsImg = imgData.filter((e) => { return e.fd_img_id == checkImg[0].fd_img_id_model });
            //       if (checkIsImg.length > 0) {
            //         url = checkIsImg[0].url;
            //       } else {
            //         let response2 = await axios.get(`https://api.box.com/2.0/files/${checkImg[0].fd_img_id_model}/content`, {
            //           headers: {
            //             Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
            //           },
            //           responseType: 'blob',
            //         });
            //         url = URL.createObjectURL(response2.data);

            //         let temp = imgData;
            //         temp.push({
            //           fd_img_id: checkImg[0].fd_img_id_spaceship,
            //           url: URL.createObjectURL(response2.data)
            //         });
            //         setImgData(temp);
            //       }
            //     }
            //   };
            // };
          } else {
            localStorage.removeItem("projectAct");
            switUSe = true
          }

          if (project_id_main !== "" || switUSe == false) {
            let check_ = response.data.filter((e) => { return e.pf_project_id === project_id_main });

            setProjectTemp({
              ...projectTempm,
              pf_project_id: project_id_main,
              project_name: check_.length > 0 ? check_[0].project_name : "",
              project_progress: check_.length > 0 ? check_[0].project_progress : '',
              spaceship_size: check_.length > 0 ? check_[0].spaceship_size : "",
              spaceship_name: check_.length > 0 ? check_[0].spaceship_name : "",
              pp_start_date: check_.length > 0 ? check_[0].pp_start_date !== null && check_.length > 0 && check_[0].pp_start_date !== null ? moment(check_[0].pp_start_date).format('DD MMM YYYY') : '' : "",
              pp_finish_date: check_.length > 0 ? check_[0].pp_finish_date !== null && check_.length > 0 && check_[0].pp_finish_date !== null ? moment(check_[0].pp_finish_date).format('DD MMM YYYY') : '' : "",
              federation_name: check_.length > 0 ? check_[0].federation_name : "",
              pp_team_member: check_.length > 0 ? check_[0].pp_team_member : 0,
              ul_file_name: check_.length > 0 ? check_[0].ul_file_name : '',
              ul_file_path: check_.length > 0 ? check_[0].ul_file_path : '',
              defcon_id: check_.length > 0 ? check_[0].defcon_id : '',
              defcon_color: check_.length > 0 ? check_[0].defcon_color : '',
              defcon_level: check_.length > 0 ? check_[0].defcon_level : '',
              isLeader: check_.length > 0 ? check_[0].isLeader : '',
              riskCount: check_.length > 0 ? check_[0].riskCount : '',
              issueCount: check_.length > 0 ? check_[0].issueCount : '',
            })

            params.callBack({
              pf_project_id: project_id_main,
              project_id: project_id_main,
              project_name: check_.length > 0 ? check_[0].project_name : "",
              project_progress: check_.length > 0 ? check_[0].project_progress : '',
              spaceship_id: check_.length > 0 ? check_[0].spaceship_id : "",
              spaceship_size: check_.length > 0 ? check_[0].spaceship_size : "",
              spaceship_name: check_.length > 0 ? check_[0].spaceship_name : "",
              pp_start_date: check_.length > 0 ? check_[0].pp_start_date !== null && check_.length > 0 && check_[0].pp_start_date !== null ? moment(check_[0].pp_start_date).format('DD MMM YYYY') : '' : "",
              pp_finish_date: check_.length > 0 ? check_[0].pp_finish_date !== null && check_.length > 0 && check_[0].pp_finish_date !== null ? moment(check_[0].pp_finish_date).format('DD MMM YYYY') : '' : "",
              federation_name: check_.length > 0 ? check_[0].federation_name : "",
              pp_team_member: check_.length > 0 ? check_[0].pp_team_member : 0,
              ul_file_name: check_.length > 0 ? check_[0].ul_file_name : '',
              ul_file_path: check_.length > 0 ? check_[0].ul_file_path : '',
              defcon_id: check_.length > 0 ? check_[0].defcon_id : '',
              defcon_color: check_.length > 0 ? check_[0].defcon_color : '',
              defcon_level: check_.length > 0 ? check_[0].defcon_level : '',
              isLeader: check_.length > 0 ? check_[0].isLeader : '',
              riskCount: check_.length > 0 ? check_[0].riskCount : '',
              issueCount: check_.length > 0 ? check_[0].issueCount : '',
              url: url
            })
            
            settest({ ...test, test: '' })
            test.test = '';
          } else {
            setProjectTemp({
              ...projectTempm,
              pf_project_id: response.data[0].pf_project_id,
              project_name: response.data[0].project_name,
              project_progress: response.data[0].project_progress,
              spaceship_id: response.data[0].spaceship_id,
              spaceship_size: response.data[0].spaceship_size,
              spaceship_name: response.data[0].spaceship_name,
              pp_start_date: response.data[0].pp_start_date !== null && response.data[0].pp_start_date !== null ? moment(response.data[0].pp_start_date).format('DD MMM YYYY') : '',
              pp_finish_date: response.data[0].pp_finish_date !== null && response.data[0].pp_finish_date !== null ? moment(response.data[0].pp_finish_date).format('DD MMM YYYY') : '',
              federation_name: response.data[0].federation_name,
              pp_team_member: response.data[0].pp_team_member || 0,
              ul_file_name: response.data[0].ul_file_name,
              ul_file_path: response.data[0].ul_file_path,
              defcon_id: response.data[0].defcon_id,
              defcon_color: response.data[0].defcon_color,
              defcon_level: response.data[0].defcon_level,
              isLeader: response.data[0].isLeader,
              riskCount: response.data[0].riskCount,
              issueCount: response.data[0].issueCount
            })
            let getUser_id = sessionStorage.getItem('user_id');
            localStorage.setItem("projectAct", JSON.stringify({ project_id: response.data[0].pf_project_id, user_on_project: getUser_id,spaceship_id: response.data[0].spaceship_id || null}));

            params.callBack({
              pf_project_id: response.data[0].pf_project_id,
              project_id: response.data[0].pf_project_id,
              project_name: response.data[0].project_name,
              project_progress: response.data[0].project_progress,
              spaceship_id: response.data[0].spaceship_id,
              spaceship_size: response.data[0].spaceship_size,
              spaceship_name: response.data[0].spaceship_name,
              pp_start_date: response.data[0].pp_start_date !== null && response.data[0].pp_start_date !== null ? moment(response.data[0].pp_start_date).format('DD MMM YYYY') : '',
              pp_finish_date: response.data[0].pp_finish_date !== null && response.data[0].pp_finish_date !== null ? moment(response.data[0].pp_finish_date).format('DD MMM YYYY') : '',
              federation_name: response.data[0].federation_name,
              pp_team_member: response.data[0].pp_team_member || 0,
              ul_file_name: response.data[0].ul_file_name,
              ul_file_path: response.data[0].ul_file_path,
              defcon_id: response.data[0].defcon_id,
              defcon_color: response.data[0].defcon_color,
              defcon_level: response.data[0].defcon_level,
              isLeader: response.data[0].isLeader,
              riskCount: response.data[0].riskCount,
              issueCount: response.data[0].issueCount,
              url: url
            })
          }


        } else {
          localStorage.removeItem("projectAct");
        }




        //console.log('ดูตวละคร',projectTempm);
        if(list.length > 0){
         /*  localStorage.setItem("projectAct", JSON.stringify({ project_id: response.data[0].pf_project_id, user_on_project: getUser_id })); */
         let project_id_main_1 = projectTempm.pf_project_id;
         let getPRojectAct = localStorage.getItem(`projectAct`)
         if(getPRojectAct != undefined && getPRojectAct != null){
          let pranJson = JSON.parse(getPRojectAct)
          if(pranJson != null){
            project_id_main_1 = pranJson.project_id
          }
         }
    
          let getproject = list.filter((e) => {return e.project_id == project_id_main_1})
          if(getproject.length == 0){
            localStorage.setItem("projectAct", JSON.stringify({ project_id: response.data[0].pf_project_id, user_on_project: getUser_id,spaceship_id: response.data[0].spaceship_id || null }));
            setProjectTemp({...projectTempm,
              pf_project_id: response.data[0].pf_project_id
              })
              projectTempm.pf_project_id = response.data[0].pf_project_id;
          }
        }
  /*       setProjectName({...projectName,
          name:''
        }) */
        setProjectList(list)
        setOleProjectList(list);
        settest({ ...test, test: '' })
        test.test = ''


        let getPRoject1 = localStorage.getItem(`projectAct`);
        let pranJson1 = JSON.parse(getPRoject1);
        let url = '';
        if (pranJson1.project_id) {
          let checkImg = response.data.filter((e) => { return e.pf_project_id == pranJson1.project_id });
          if (checkImg.length > 0) {
            if (checkImg[0].fd_img_id_spaceship) {
              let checkIsImg = imgData.filter((e) => { return e.fd_img_id == checkImg[0].fd_img_id_spaceship });
              if (checkIsImg.length > 0) {
                url = checkIsImg[0].url;
              } else {
                let response2 = await axios.get(`https://api.box.com/2.0/files/${checkImg[0].fd_img_id_spaceship}/content`, {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                  },
                  responseType: 'blob',
                });
                url = URL.createObjectURL(response2.data);

                let temp = imgData;
                temp.push({
                  fd_img_id: checkImg[0].fd_img_id_spaceship,
                  url: URL.createObjectURL(response2.data)
                });
                setImgData(temp);
              }
            } else if ((checkImg[0].fd_img_id_model)) {
              let checkIsImg = imgData.filter((e) => { return e.fd_img_id == checkImg[0].fd_img_id_model });
              if (checkIsImg.length > 0) {
                url = checkIsImg[0].url;
              } else {
                let response2 = await axios.get(`https://api.box.com/2.0/files/${checkImg[0].fd_img_id_model}/content`, {
                  headers: {
                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                  },
                  responseType: 'blob',
                });
                url = URL.createObjectURL(response2.data);

                let temp = imgData;
                temp.push({
                  fd_img_id: checkImg[0].fd_img_id_spaceship,
                  url: URL.createObjectURL(response2.data)
                });
                setImgData(temp);
              }
            }

            if (project_id_main !== "" || switUSe == false) {
              let check_ = response.data.filter((e) => { return e.pf_project_id === project_id_main });
              params.callBack({
                pf_project_id: project_id_main,
                project_id: project_id_main,
                project_name: check_.length > 0 ? check_[0].project_name : "",
                project_progress: check_.length > 0 ? check_[0].project_progress : '',
                spaceship_id: check_.length > 0 ? check_[0].spaceship_id : "",
                spaceship_size: check_.length > 0 ? check_[0].spaceship_size : "",
                spaceship_name: check_.length > 0 ? check_[0].spaceship_name : "",
                pp_start_date: check_.length > 0 ? check_[0].pp_start_date !== null && check_.length > 0 && check_[0].pp_start_date !== null ? moment(check_[0].pp_start_date).format('DD MMM YYYY') : '' : "",
                pp_finish_date: check_.length > 0 ? check_[0].pp_finish_date !== null && check_.length > 0 && check_[0].pp_finish_date !== null ? moment(check_[0].pp_finish_date).format('DD MMM YYYY') : '' : "",
                federation_name: check_.length > 0 ? check_[0].federation_name : "",
                pp_team_member: check_.length > 0 ? check_[0].pp_team_member : 0,
                ul_file_name: check_.length > 0 ? check_[0].ul_file_name : '',
                ul_file_path: check_.length > 0 ? check_[0].ul_file_path : '',
                defcon_id: check_.length > 0 ? check_[0].defcon_id : '',
                defcon_color: check_.length > 0 ? check_[0].defcon_color : '',
                defcon_level: check_.length > 0 ? check_[0].defcon_level : '',
                isLeader: check_.length > 0 ? check_[0].isLeader : '',
                riskCount: check_.length > 0 ? check_[0].riskCount : '',
                issueCount: check_.length > 0 ? check_[0].issueCount : '',
                url: url
              })
            } else {
              params.callBack({
                pf_project_id: response.data[0].pf_project_id,
                project_id: response.data[0].pf_project_id,
                project_name: response.data[0].project_name,
                project_progress: response.data[0].project_progress,
                spaceship_id: response.data[0].spaceship_id,
                spaceship_size: response.data[0].spaceship_size,
                spaceship_name: response.data[0].spaceship_name,
                pp_start_date: response.data[0].pp_start_date !== null && response.data[0].pp_start_date !== null ? moment(response.data[0].pp_start_date).format('DD MMM YYYY') : '',
                pp_finish_date: response.data[0].pp_finish_date !== null && response.data[0].pp_finish_date !== null ? moment(response.data[0].pp_finish_date).format('DD MMM YYYY') : '',
                federation_name: response.data[0].federation_name,
                pp_team_member: response.data[0].pp_team_member || 0,
                ul_file_name: response.data[0].ul_file_name,
                ul_file_path: response.data[0].ul_file_path,
                defcon_id: response.data[0].defcon_id,
                defcon_color: response.data[0].defcon_color,
                defcon_level: response.data[0].defcon_level,
                isLeader: response.data[0].isLeader,
                riskCount: response.data[0].riskCount,
                issueCount: response.data[0].issueCount,
                url: url
              })
            }

            setProjectList(list);
            setOleProjectList(list);
            settest({ ...test, test: '' })
            test.test = ''

          };
        };

      })
      .catch(function (error) {
        console.log(error);
      });
  }



  const socketRef = useRef(null);
  
  useEffect(() => {

    if (socketRef.current == null) {
      socketRef.current = io(Configs.TTT_Game_Socket_Service, {
        //path: '/Socket-service/socket.io'
        port: Configs.TTT_Game_Socket_Service_Port
      });
    }
  }, [

  ])

  
  useEffect(() => {

    const { current: socket } = socketRef;

    const socFncBan = (data) => {
      console.log('onRoomBan', data)
      getPtojectByUser(1,999);
    
   }

   const socFnc = (data) => {
    console.log('dataMode', data)
    if(data.message === 'banmode'){
      console.log('dataMode', data)
      getPtojectByUser(1,999);
    }
    
  }
   

   try {
    socket.open();

    socket.emit('join', "BanMember" +  sessionStorage.getItem('user_id') );
    socket.emit('online', sessionStorage.getItem('user_id'));
    socket.on('BanMember', socFncBan)
    socket.on('mailbox', socFnc)


    console.log('เช็คห้อง',socket);
  } catch (error) {
    console.log('ตรงเเบนมา',error);
  }



/*   return () => {
    socket.off("chat", socFnc);

    socket.close();
   
  }; */

  
  }, [])

  function openModals(value) {
    params.toggleSelectProject();
    switch (value) {
      case 'close':
        setSearchLabel({ ...searchLabel, value: '' })
        searchLabel.value = '';
        setProjectList(oleProjectList)
        setOpenModal('open');
        if (params.setMenuValue != undefined) {
          params.setMenuValue(true)
        }
        break;
      default:
        setSearchLabel({ ...searchLabel, value: '' })
        searchLabel.value = '';
        setOpenModal('close');
        if (params.setMenuValue != undefined) {
          params.setMenuValue(true)
        }
        break;
    }

  }


  async function InputProject(value) {
    setProjectTemp({
      ...projectTempm,
      pf_project_id: value.project_id,
      project_progress: value.project_progress || 0,
      spaceship_size: value.spaceship_size,
      spaceship_name: value.spaceship_name,
      pp_start_date: value.pp_start_date,
      pp_finish_date: value.pp_finish_date,
      federation_name: value.federation_name,
      pp_team_member: value.pp_team_member,
      ul_file_name: value.ul_file_name,
      ul_file_path: value.ul_file_path,
      defcon_id: value.defcon_id,
      defcon_color: value.defcon_color,
      defcon_level: value.defcon_level,
      isLeader: value.isLeader,
      riskCount: value.riskCount,
      issueCount: value.issueCount,
      url: null
    })

    /*  sessionStorage.setItem('project_id', value.project_id) */
    let getUser_id = sessionStorage.getItem('user_id');
    localStorage.setItem("projectAct", JSON.stringify({ project_id: value.project_id, user_on_project: getUser_id,spaceship_id: value.spaceship_id || null }));
    params.callBack(value)
    params.chcekusermode(value.project_id)
    setOpenModal('close');
    settest({ ...test, test: '' })
    test.test = '';

    if (value.fd_img_id_spaceship) {
      let checkIsImg = imgData.filter((e)=>{return e.fd_img_id == value.fd_img_id_spaceship});
      if (checkIsImg.length > 0) {
        value.url = checkIsImg[0].url;
        params.callBack(value);
      } else {
        let response2 = await axios.get(`https://api.box.com/2.0/files/${value.fd_img_id_spaceship}/content`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
        });
        value.url = URL.createObjectURL(response2.data);
        params.callBack(value);

        let temp = imgData;
        temp.push({
          fd_img_id: value.fd_img_id_spaceship,
          url: URL.createObjectURL(response2.data)
        });
        setImgData(temp);
      };

    } else if (value.fd_img_id_model) {
      let checkIsImg = imgData.filter((e)=>{return e.fd_img_id == value.fd_img_id_model});
      if (checkIsImg.length > 0) {
        value.url = checkIsImg[0].url;
        params.callBack(value);
      } else {
        let response2 = await axios.get(`https://api.box.com/2.0/files/${value.fd_img_id_model}/content`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
          },
          responseType: 'blob',
        });
        value.url = URL.createObjectURL(response2.data);
        params.callBack(value);

        let temp = imgData;
        temp.push({
          fd_img_id: value.fd_img_id_model,
          url: URL.createObjectURL(response2.data)
        });
        setImgData(temp);
      };
    }

    settest({ ...test, test: '' })
    test.test = '';
  }



  function searchValue(label) {
    /*  console.log('valuesss', label); */
    /*  callBack.callBack(label) */
    let searchResult = [];
    /*   console.log('ดูผังชั่น',params); */
    searchResult.push(
      oleProjectList.filter((item) => {

        return (item.project_name === undefined || item.project_name === null)
          ? ''
          : item.project_name.toLowerCase().indexOf(label.toLowerCase().trim()) > -1;
      })
    );

    const searchItem = [];
    searchResult.forEach((round) => {
      round.forEach((value) => {
        if (!searchItem.find((item) => item.project_id === value.project_id)) {
          searchItem.push(value);
        }
      });
    });

    setProjectList(searchItem);
    settest({ ...test, test: '' });
    test.test = '';
  }

  useEffect(() => {

    /*   if(params.checng === true){
        console.log('paramsparams',params); */
    getPtojectByUser();
    /*   } */


  }, [params.checng, params.freshSelectProjectByFed])




  return (
    <>
      {params.displays !== undefined && params.displays === false ? (<>

      </>) : (
        <>
          <div className="fream-1" onClick={() => {
            openModals(openModal);

          }}>
            <div className="row">
              <div className="col-12 pro1">
                {oleProjectList.length > 0 ? oleProjectList.filter((l) => { return l.project_id === projectTempm.pf_project_id })[0].project_name : 'PROJECT MONITORING'}
              </div>
            </div>
          </div>
          <div className={`fream-2-${openModal}`} >
            {projectList.map((e) => {
              /*  console.log('projectTempm.pf_project_id',projectTempm.pf_project_id); */
              let active = ''
              if (projectTempm.pf_project_id === e.project_id) {
                active = '-active'
              }
              return (
                <div className="row">
                  <div className={`item-rows${active}`} onClick={() => { InputProject(e) }} >
                    {
                      e.project_name
                    }
                  </div>

                </div>
              )
            })}
          </div>

          <input type="text"
            style={{ visibility: `${openModal == 'open' ? "visible" : "hidden"}`,textAlign:"left",paddingLeft:"5px"}}
            value={searchLabel.value}
            placeholder="SEARCH PROJECT"
            onChange={(e) => {
              setSearchLabel({
                ...searchLabel,
                value: e.target.value
              })
              searchValue(e.target.value)
            }}
            className={`search-input-${openModal}   search-input2-${openModal}`} >

          </input>
        </>

      )}

    </>
  );
}

export default SelectProject;
