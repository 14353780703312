import React from "react";
import { useState, useEffect, useRef } from 'react'

import axios from "axios";
import moment from "moment";
import Swal from "sweetalert2";
import { NumericFormat } from 'react-number-format';
import logo_pointer from '../../../assets/img/iconSpaceShip/mouse-pointer.png';
import Configs from "../../../config";
import PatternFormat from '../../../components/customElement/patterZrotUse'
import IconSave from '../../../assets/img/icon/IconSave.png'
import "./modalProject.css";
import ModelAlertConfrim from "../../../components/AlertMassge/AlertConfrim";
import ModelAlertMassge from "../../../components/AlertMassge/AlertMassge";
import PopupSaveversion from "./component/PupupSummaryOfCheng";

import { Dropdown, Menu, ConfigProvider, Tooltip, Input, DatePicker } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import TabCompanentSrs from './component/tabSrs'
import TabCompanentMOM from './component/tabMom'
import TabTestResult from './component/tabTestResult'
import dayjs from "dayjs";
import TabFinalSummary from './component/tabFinalSummary'
import TabAcceptance from './component/tabAcceptance'
import TabSdd from './component/tabSdd'
import TabChange from './component/tabChange'
import IconSvg from "./iconSvg.svg"
import IconSvgShare from "./svgIconShare.svg"
import { faL } from "@fortawesome/free-solid-svg-icons";
import { io } from 'socket.io-client';
import load from '../../../assets/img/icon/load.png'
import { isString } from "lodash";
import Next_02 from '../../../assets/img/icon/Next-02.png';
import Previous_02 from '../../../assets/img/icon/Previous-02.png';
import styled from 'styled-components';

const { TextArea } = Input;

const StyledDatePicker = styled(DatePicker)`
.ant-picker-clear {
    padding-Right: 10px; 
}
`;

function ModalProject(params) {


    const socketRef = useRef(null);
    useEffect(() => {

        if (socketRef.current == null) {
            socketRef.current = io(Configs.TTT_Game_Socket_Service, {
                port: Configs.TTT_Game_Socket_Service_Port
            });
        }
    }, [

    ])


    useEffect(() => {
        const { current: socket } = socketRef;
        const socFnc = (data) => { }
        try {
            socket.open();
            socket.on('locationElement', socFnc)
        } catch (error) {
            console.log(error);
        }

    }, []);

    const [textAreaId, setTextAreaId] = useState({})
    const [userismode, setuserismode] = useState(true)
    const [openModal, setOpenModal] = useState('close')
    let [displayText, setDisplayText] = useState(false);
    const [test, settest] = useState({ test: '' })
    const parentRef = useRef(null);
    const childRef = useRef(null);
    const [roleOption, setRoleOption] = useState([])
    const [displayNone, setDisplayNone] = useState({
        status: true,
        check: false
    })
    const [messageBox, setMessageBox] = useState({
        message: [],
        isError: false,
        menuTitle: ''
    })

    const [copyText, setCopyText] = useState('copy');

    const [modelversion, setmodelversion] = useState(false);
    const toggle_modal_modelversion = () => setmodelversion(!modelversion);
    const [alertKickMassge, setAlertKickMassge] = useState(false);
    const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

    const [displaySwal, setDisplaySwal] = useState(false);
    const toggle_modal_confrimModal = () => setDisplaySwal(!displaySwal);

    const [PopupSaveVerSummart, setPopupSaveVerSummart] = useState(false);
    const toggle_modal_PopupSaveVerSummart = () => setPopupSaveVerSummart(!PopupSaveVerSummart);
    let [loadinddisplay, setloadinddisplay] = useState(false);
    const [menu, setMenu] = useState('PROJECT INFORMATION')
    const [showLastUpdate, setShowLastUpdate] = useState('')
    const [projectSave, setProjectSave] = useState({
        project_id: '',
        project_name: '',
        customer_code: '',
        customer_name: '',
        team_member: '',
        project_start: '',
        project_finish: '',
        objective: '',
        description: '',
        dev_tools: '',
        dev_language: '',
        solution: '',
        sdlc: '',
        delivery_item: [],
        project_formation: [],
        customer_contact: [],
        lastupdate: '',
        tabProject: []

    })
    const [projectSaveFack, setProjectSaveFack] = useState({
        project_id: '',
        project_name: '',
        customer_code: '',
        customer_name: '',
        team_member: '',
        project_start: '',
        project_finish: '',
        objective: '',
        description: '',
        dev_tools: '',
        dev_language: '',
        solution: '',
        sdlc: '',
        delivery_item: [],
        project_formation: [],
        customer_contact: [],
        lastupdate: '',
        tabProject: []

    })

    const [lastUpdateValue, setLastUpdateValue] = useState({
        tab_1: '',
        tab_2: '',
        tab_3: '',
        tab_4: '',
        tab_5: '',
        tab_6: '',
        tab_7: '',
        tab_8: '',
        tab_9: '',
    })


    const [tempSrsSave, setTempSrsSave] = useState(
        {
            prepared_sp_name: '',
            prepared_sp_position_id: '',
            prepared_sp_position: '',
            prepared_sp_operation: "prepared_sp_operation",
            prepared_sp_date_sign: '',
            prepared_sp_operation: '',

            verified_sp_name: '',
            verified_sp_position_id: '',
            verified_sp_position: '',
            verified_sp_operation: 'verified_sp_operation',
            verified_sp_date_sign: '',
            verified_sp_operation: '',

            approved_sp_name: '',
            approved_sp_position_id: '',
            approved_sp_position: '',
            approved_sp_operation: 'approved_sp_operation',
            approved_sp_date_sign: '',
            approved_sp_operation: '',

            customer_approved_sp_name: '',
            customer_approved_sp_position_id: '',
            customer_approved_sp_position: '',
            customer_approved_sp_operation: 'customer_approved_sp_operation',
            customer_approved_sp_date_sign: '',
            customer_approved_sp_operation: '',


        },
    )

    const [tempSrsSaveFack, setTempSrsSaveFack] = useState(
        {
            prepared_sp_name: '',
            prepared_sp_position_id: '',
            prepared_sp_position: '',
            prepared_sp_operation: 'prepared_sp_operation',
            prepared_sp_date_sign: '',
            prepared_sp_operation: '',

            verified_sp_name: '',
            verified_sp_position_id: '',
            verified_sp_position: '',
            verified_sp_operation: 'verified_sp_operation',
            verified_sp_date_sign: '',
            verified_sp_operation: '',

            approved_sp_name: '',
            approved_sp_position_id: '',
            approved_sp_position: '',
            approved_sp_operation: 'approved_sp_operation',
            approved_sp_date_sign: '',
            approved_sp_operation: '',

            customer_approved_sp_name: '',
            customer_approved_sp_position_id: '',
            customer_approved_sp_position: '',
            customer_approved_sp_operation: 'customer_approved_sp_operation',
            customer_approved_sp_date_sign: '',
            customer_approved_sp_operation: '',


        },
    )
    const disabledEndDate = (endValue) => {
        if (!projectSave.project_start) {
            return false;
        }
        return endValue && endValue.isBefore(projectSave.project_start);
    };
    const disabledStartDate = (endValue) => {
        if (!projectSave.project_finish) {
            return false;
        }
        return endValue > (projectSave.project_finish);
    };


    const [isGetBySRS, setISgetbySRS] = useState({
        is_fass: false
    })


    const [editModalTab, setEditModeTab] = useState([
        { hi_id: '88f4b950-6451-44a0-a0f8-9ea5d9bed03f', tab: 'PROJECT INFORMATION', hi_sort: '1' },
        { hi_id: '71a5bf6e-e088-4e00-91cf-d962a89047f4', tab: 'PROJECT SCHEDULE', hi_sort: '2' },
        { hi_id: 'SRS', tab: 'SRS', hi_sort: '3' },
        { hi_id: 'MOM (MINUTE OF MEETING)', tab: 'MOM (MINUTE OF MEETING)', hi_sort: '4' },
        { hi_id: 'TestResult', tab: 'Test Result', hi_sort: '10' },

        { hi_id: 'Final Summary', tab: 'Final Summary', hi_sort: '6' },
        { hi_id: 'UAT-ACCEPTANCE RECORD', tab: 'UAT-ACCEPTANCE RECORD', hi_sort: '7' },
        { hi_id: 'SDD', tab: 'SDD', hi_sort: '8' },
        { hi_id: 'Change Request', tab: 'Change Request', hi_sort: '9' },

    ])

    const [deliveryItem, setDeliveryItem] = useState([])
    const [deliveryItemFack, setDeliveryItemFack] = useState([])

    const [projectFormation, setProjectFormation] = useState([])
    const [projectFormationFack, setProjectFormationFack] = useState([])

    const [customerContact, setcustomerContact] = useState([])
    const [customerContactFack, setcustomerContactFack] = useState([])

    const [projectSchedule, setProjectSchedule] = useState([])
    const [projectScheduleFack, setProjectScheduleFack] = useState([])

    const [momSpecificField, setMomSpecificField] = useState([]);
    const [momDelSpecificField, setMomDelSpecificField] = useState([]);

    const [srsSpecificField, setSrsSpecificField] = useState([]);
    const [srsSpecificFieldValue, setSrsSpecificFieldValue] = useState([]);

    const [srsSpecificFieldTestResult, setSrsSpecificFieldTestResult] = useState([]);
    const [srsSpecificFieldTestResultValue, setSrsSpecificFieldTestResultValue] = useState([]);

    const [srsSpecificFieldUat, setSrsSpecificFieldUat] = useState([]);
    const [srsSpecificFieldUatValue, setSrsSpecificFieldUatValue] = useState([]);

    const [srsSpecificFieldFinal, setSrsSpecificFieldFinal] = useState([]);
    const [srsSpecificFieldFinalValue, setSrsSpecificFieldFinalValue] = useState([]);

    const [srsSpecificFieldAcceptancerecord, setSrsSpecificFieldAcceptancerecord] = useState([]);
    const [srsSpecificFieldAcceptancerecordValue, setSrsSpecificFieldAcceptancerecordValue] = useState([]);

    const [srsSpecificFieldSDD, setSrsSpecificFieldSDD] = useState([]);
    const [srsSpecificFieldSDDValue, setSrsSpecificFieldSDDValue] = useState([]);

    const [changeSpecificField, setChangeSpecificField] = useState([]);
    const [changeSpecificFieldValue, setChangeSpecificFieldValue] = useState([]);


    const [findTTTUser, setFindTTTUser] = useState({ ttt_id: '' });
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
        if (params.openModal === 'open') {
            RoleOption();
            setProjectSave({
                ...projectSave,
                project_id: params.projectTemp.pf_project_id
            });
            getProjectByID(params.projectTemp.pf_project_id);
        }

        if (params.openModal === 'close') {
            setTempSrsSave({
                prepared_sp_name: '',
                prepared_sp_position_id: '',
                prepared_sp_position: '',
                prepared_sp_operation: '',
                prepared_sp_date_sign: '',

                verified_sp_name: '',
                verified_sp_position_id: '',
                verified_sp_position: '',
                verified_sp_operation: '',
                verified_sp_date_sign: '',

                approved_sp_name: '',
                approved_sp_position_id: '',
                approved_sp_position: '',
                approved_sp_operation: '',
                approved_sp_date_sign: '',

                customer_approved_sp_name: '',
                customer_approved_sp_position_id: '',
                customer_approved_sp_position: '',
                customer_approved_sp_operation: '',
                customer_approved_sp_date_sign: '',

            });
            setProjectSave({
                project_id: '',
                project_name: '',
                customer_code: '',
                customer_name: '',
                team_member: '',
                project_start: '',
                project_finish: '',
                objective: '',
                description: '',
                dev_tools: '',
                dev_language: '',
                solution: '',
                sdlc: '',
                delivery_item: [],
                project_formation: [],
                customer_contact: [],
            });
            setDeliveryItem([]);
            setProjectFormation([]);
            setcustomerContact([]);
            setProjectSchedule([]);
            setMenu('PROJECT INFORMATION');
            settest({ ...test, test: '' });
            test.test = '';
        }

        const parent = parentRef.current;
        const child = childRef.current;

        const handleScroll = () => {
            const scrollTop = parent.scrollTop;
            const childHeight = child.clientHeight;
            const triggerScroll = childHeight / 2;

            console.log('scrollTop', scrollTop);
            let keyCheck = displayText;
            if (scrollTop >= 100) {
                keyCheck = true;
            } else {
                keyCheck = false;
            }

            if (keyCheck !== displayText) {
                if (scrollTop >= 100) {
                    setDisplayText(true);
                    setDisplayNone({ ...displayNone, status: false });
                } else {
                    setDisplayText(false);
                    let myTimer = setInterval(() => {
                        if (!displayText) {
                            setDisplayNone({ ...displayNone, status: true });
                        }
                        clearInterval(myTimer);
                    }, 1000);
                }
            }
        };

        parent.addEventListener('scroll', handleScroll);

        return () => {
            parent.removeEventListener('scroll', handleScroll);
        };
    }, [params.openModal]);


    useEffect(() => {
        if (params.eventChangeSaveVersion === true) {
            getversionreport(dropdownreport.mode, dropdownreport.name);
        }
    }, [params.eventChangeSaveVersion])


    function InputDeviveryItem(value) {
        let ole = deliveryItem;
        var temp = {
            pd_id: null,
            pd_due_date: null,
            pd_delivery_item_list: null,
            pd_remark: null
        }
        ole.push(temp);
        setDeliveryItem(ole)
        settest({ ...test, test: '' })
        test.test = '';
    }

    function RemoveDeviveryItem(index_1) {
        let ole = deliveryItem;
        ole.splice(index_1, 1)

        setDeliveryItem(ole)
        ChckStageChanTabgInfo(projectSave)
        settest({ ...test, test: '' })
        test.test = '';
    }

    function InputProjectFormation(value) {
        let ole = projectFormation;
        var temp = {
            pf_id: '',
            pf_fullname: '',
            pf_position: '',
            potional: "",
            pf_short_responsibility: '',
            pf_email: '',
            pf_tel: ''
        }
        ole.push(temp);
        setProjectFormation(ole)
        settest({ ...test, test: '' })
        test.test = '';
    }

    function RemoveProjectFormation(index_2) {
        let ole = projectFormation;
        ole.splice(index_2, 1)

        setProjectFormation(ole)
        settest({ ...test, test: '' })
        test.test = '';
    }
    function InputCustomerContact(value) {
        let ole = customerContact;
        var temp = {
            pc_id: '',
            pc_fullname: '',
            pc_position: '',
            pc_short_responsibility: "",
            pc_email: '',
            pc_tel: ''
        }
        ole.push(temp);
        setcustomerContact(ole)

        ChckStageChanTabgInfo(projectSave);
        settest({ ...test, test: '' })
        test.test = '';
    }

    function RemoveCustomerContact(index_2) {
        let ole = customerContact;
        ole.splice(index_2, 1)

        setcustomerContact(ole)
        ChckStageChanTabgInfo(projectSave);
        settest({ ...test, test: '' })
        test.test = '';
    }


    function InputSchedule(value) {

        let ole = projectSchedule;

        var temp = {
            ps_id: '',
            ps_task_no: '',
            ps_task_name: '',
            ps_start: null,
            ps_finish: null,
            ps_predecessors: '',
            ps_sort: projectSchedule.length + 1,
            duration: ''
        }
        ole.push(temp);
        setProjectSchedule(ole)
        ChckStageChanTabAdv()
        settest({ ...test, test: '' })
        test.test = '';
    }

    function RemoveSchedule(index_4) {
        let ole = projectSchedule;
        ole.splice(index_4, 1)

        setProjectSchedule(ole)
        ChckStageChanTabAdv()
        settest({ ...test, test: '' })
        test.test = '';
    }

    async function getProjectByID(userid) {


        let project_id = 'f8d7beab-7cb7-4a25-9422-1cf42d00f0a1';
        await axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/project/getProjectByID/${params.projectTemp.pf_project_id}`,
            headers: {
                /*  Authorization: getToken(), */
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                console.log('updateDataSrs', response.data);
                if (response.data) {

                    let checkuser = sessionStorage.getItem('user_id')
                    if (checkuser === response.data.project_created_by) {
                        setuserismode(false)
                    } else if (response.data.project_formation.length > 0) {
                        let checkformation = response.data.project_formation.filter((e) => { return e.pf_usr_id === checkuser });
                        if (checkformation.length > 0) {
                            setuserismode(false)
                        } else {
                            setuserismode(true)
                        }
                    } else {
                        setuserismode(true)
                    }
                    setProjectSave({
                        ...projectSave,
                        project_id: response.data.project_id,
                        project_name: response.data.project_name,
                        customer_code: response.data.pp_customer_code,
                        customer_name: response.data.pp_customer_name,
                        team_member: response.data.pp_team_member,
                        project_start: response.data.pp_start_date,
                        project_finish: response.data.pp_finish_date,
                        objective: response.data.pp_objective,
                        description: response.data.project_description,
                        dev_tools: response.data.pp_dev_tool,
                        dev_language: response.data.pp_dev_language,
                        solution: response.data.pp_solution_technology,
                        sdlc: response.data.pp_sdlc,
                        lastupdate: response.data.project_updated_date ? moment(response.data.project_updated_date).format('DD/MM/YYYY HH:mm') : ''
                    })

                    setProjectSaveFack({
                        ...projectSaveFack,
                        project_id: response.data.project_id,
                        project_name: response.data.project_name,
                        customer_code: response.data.pp_customer_code,
                        customer_name: response.data.pp_customer_name,
                        team_member: response.data.pp_team_member,
                        project_start: response.data.pp_start_date,
                        project_finish: response.data.pp_finish_date,
                        objective: response.data.pp_objective,
                        description: response.data.project_description,
                        dev_tools: response.data.pp_dev_tool,
                        dev_language: response.data.pp_dev_language,
                        solution: response.data.pp_solution_technology,
                        sdlc: response.data.pp_sdlc,
                        lastupdate: response.data.project_updated_date ? moment(response.data.project_updated_date).format('DD/MM/YYYY HH:mm') : ''
                    })


                    console.log('Zrot', response.data.specific_accecptance);
                    setSrsSpecificFieldValue(response.data.srs_specific);
                    setSrsSpecificFieldTestResultValue(response.data.specific_test_result);
                    setSrsSpecificFieldUatValue(response.data.specific_uat);
                    setSrsSpecificFieldFinalValue(response.data.specific_summary);
                    setSrsSpecificFieldAcceptancerecordValue(response.data.specific_accecptance);
                    setSrsSpecificFieldSDDValue(response.data.specific_sdd);
                    setChangeSpecificFieldValue(response.data.specific_change);
                    setTempSrsSave({
                        ...tempSrsSave,
                        prepared_sp_name: response.data.editprepared.prepared_sp_name || null,
                        prepared_sp_position_id: response.data.editprepared.prepared_sp_position_id || null,
                        prepared_sp_position: response.data.editprepared.prepared_sp_position || null,
                        prepared_sp_operation: "prepared_sp_operation" || null,
                        prepared_sp_date_sign: response.data.editprepared.prepared_sp_date_sign || null,
                        prepared_sp_operation: response.data.editprepared.prepared_sp_operation || null,

                        verified_sp_name: response.data.editverified_.verified_sp_name || null,
                        verified_sp_position_id: response.data.editverified_.verified_sp_position_id || null,
                        verified_sp_position: response.data.editverified_.verified_sp_position || null,
                        verified_sp_operation: 'verified_sp_operation' || null,
                        verified_sp_date_sign: response.data.editverified_.verified_sp_date_sign || null,
                        verified_sp_operation: response.data.editverified_.verified_sp_operation || null,

                        approved_sp_name: response.data.editApproved.approved_sp_name || null,
                        approved_sp_position_id: response.data.editApproved.approved_sp_position_id || null,
                        approved_sp_position: response.data.editApproved.approved_sp_position || null,
                        approved_sp_operation: 'approved_sp_operation' || null,
                        approved_sp_date_sign: response.data.editApproved.approved_sp_date_sign || null,
                        approved_sp_operation: response.data.editApproved.approved_sp_operation || null,

                        customer_approved_sp_name: response.data.editcustomerApprov.customer_approved_sp_name || null,
                        customer_approved_sp_position_id: response.data.editcustomerApprov.customer_approved_sp_position_id || null,
                        customer_approved_sp_position: response.data.editcustomerApprov.customer_approved_sp_position || null,
                        customer_approved_sp_operation: 'customer_approved_sp_operation' || null,
                        customer_approved_sp_date_sign: response.data.editcustomerApprov.customer_approved_sp_date_sign || null,
                        customer_approved_sp_operation: response.data.editcustomerApprov.customer_approved_sp_operation || null,




                    })

                    setTempSrsSaveFack({
                        ...tempSrsSaveFack,
                        prepared_sp_name: response.data.editprepared.prepared_sp_name || null,
                        prepared_sp_position_id: response.data.editprepared.prepared_sp_position_id || null,
                        prepared_sp_position: response.data.editprepared.prepared_sp_position || null,
                        prepared_sp_operation: "prepared_sp_operation" || null,
                        prepared_sp_date_sign: response.data.editprepared.prepared_sp_date_sign || null,
                        prepared_sp_operation: response.data.editprepared.prepared_sp_operation || null,

                        verified_sp_name: response.data.editverified_.verified_sp_name || null,
                        verified_sp_position_id: response.data.editverified_.verified_sp_position_id || null,
                        verified_sp_position: response.data.editverified_.verified_sp_position || null,
                        verified_sp_operation: 'verified_sp_operation' || null,
                        verified_sp_date_sign: response.data.editverified_.verified_sp_date_sign || null,
                        verified_sp_operation: response.data.editverified_.verified_sp_operation || null,

                        approved_sp_name: response.data.editApproved.approved_sp_name || null,
                        approved_sp_position_id: response.data.editApproved.approved_sp_position_id || null,
                        approved_sp_position: response.data.editApproved.approved_sp_position || null,
                        approved_sp_operation: 'approved_sp_operation' || null,
                        approved_sp_date_sign: response.data.editApproved.approved_sp_date_sign || null,
                        approved_sp_operation: response.data.editApproved.approved_sp_operation || null,

                        customer_approved_sp_name: response.data.editcustomerApprov.customer_approved_sp_name || null,
                        customer_approved_sp_position_id: response.data.editcustomerApprov.customer_approved_sp_position_id || null,
                        customer_approved_sp_position: response.data.editcustomerApprov.customer_approved_sp_position || null,
                        customer_approved_sp_operation: 'customer_approved_sp_operation' || null,
                        customer_approved_sp_date_sign: response.data.editcustomerApprov.customer_approved_sp_date_sign || null,
                        customer_approved_sp_operation: response.data.editcustomerApprov.customer_approved_sp_operation || null,




                    })
                    setISgetbySRS({
                        ...isGetBySRS,
                        is_fass: true
                    })
                    isGetBySRS.is_fass = true


                    if (response.data.delivery_item.length > 0) {
                        // setDeliveryItem(response.data.delivery_item)
                        let editArrayDel = [];
                        for (let item of response.data.delivery_item) {
                            let keys_2 = Object.keys(item);
                            let temp = {}
                            keys_2.map((e) => {
                                temp[`edit_${e}`] = item[e]
                            })
                            editArrayDel.push(temp);
                        }
                        setDeliveryItemFack(editArrayDel)
                        let datalist = []
                        response.data.delivery_item.map((e) => {
                            /*  deliveryItem.push(e) */
                            /*  deliveryItemFack.push(e) */
                            datalist.push(e)
                        })
                        setDeliveryItem(datalist)
                    }
                    if (response.data.project_formation.length > 0) {
                        // setProjectFormation(response.data.project_formation)
                        let editArrayFormat = [];
                        for (let item of response.data.project_formation) {
                            let keys_2 = Object.keys(item);
                            let temp = {}
                            keys_2.map((e) => {
                                temp[`edit_${e}`] = item[e]
                            })
                            editArrayFormat.push(temp);
                        }
                        setProjectFormationFack(editArrayFormat)
                        let datalist = []
                        response.data.project_formation.map((e) => {
                            /*   projectFormation.push(e) */
                            /*   projectFormationFack.push(e) */
                            datalist.push(e)
                        })
                        setProjectFormation(datalist)
                    }
                    if (response.data.customer_contact.length > 0) {
                        // setcustomerContact(response.data.customer_contact)
                        let datalist = []
                        response.data.customer_contact.map((e) => {
                            /* customerContact.push(e) */
                            /*    customerContactFack.push(e) */
                            datalist.push(e)
                        })
                        setcustomerContact(datalist)
                        let editArrayCustomer = [];
                        for (let item of response.data.customer_contact) {
                            let keys_2 = Object.keys(item);
                            let temp = {}
                            keys_2.map((e) => {
                                temp[`edit_${e}`] = item[e]
                            })
                            editArrayCustomer.push(temp);
                        }
                        setcustomerContactFack(editArrayCustomer)
                    }
                    let tabSched = '';

                    if (response.data.project_schedule.length > 0) {
                        let sortTemp = response.data.project_schedule.sort((a, b) => parseInt(a.ps_sort || 0) - parseInt(b.ps_sort || 0))
                        tabSched = sortTemp.length > 0 ? sortTemp[0].ps_updated_date : ''
                        setProjectSchedule(sortTemp)
                        let editArray = [];
                        for (let item of sortTemp) {
                            let keys_2 = Object.keys(item);
                            let temp = {}
                            keys_2.map((e) => {
                                temp[`edit_${e}`] = item[e]
                            })
                            editArray.push(temp);
                        }

                        console.log('editArray', editArray);
                        setProjectScheduleFack(editArray)
                    }

                    await setAutoResize()
                    let lastUpdateSRS = '';
                    if (response.data.documentTemplateValue.length > 0) {
                        lastUpdateSRS = response.data.documentTemplateValue[0].dtv_updated_date
                    }


                    setLastUpdateValue({
                        ...lastUpdateValue,
                        tab_1: response.data.project_updated_date ? moment(response.data.project_updated_date).format('DD/MM/YYYY HH:mm') : '',
                        tab_2: tabSched ? moment(tabSched).format('DD/MM/YYYY HH:mm') : '',
                        tab_3: response.data.updateDataSrs ? moment(response.data.updateDataSrs).format('DD/MM/YYYY HH:mm') : '',
                        tab_4: response.data.updateDataTestResult ? moment(response.data.updateDataTestResult).format('DD/MM/YYYY HH:mm') : '',
                        tab_5: response.data.updateDataUAT ? moment(response.data.updateDataUAT).format('DD/MM/YYYY HH:mm') : '',
                        tab_6: response.data.updateDataFinal ? moment(response.data.updateDataFinal).format('DD/MM/YYYY HH:mm') : '',
                        tab_7: response.data.updateDatAaccecptance ? moment(response.data.updateDatAaccecptance).format('DD/MM/YYYY HH:mm') : '',
                        tab_8: response.data.updateDatsdd ? moment(response.data.updateDatsdd).format('DD/MM/YYYY HH:mm') : '',
                        tab_9: response.data.updateDateChange ? moment(response.data.updateDateChange).format('DD/MM/YYYY HH:mm') : '',
                        tab_10: response.data.updateDataSrs ? moment(response.data.updateDataSrs).format('DD/MM/YYYY HH:mm') : '',

                    })
                    lastUpdateValue.tab_1 = response.data.project_updated_date ? moment(response.data.project_updated_date).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_2 = tabSched ? moment(tabSched).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_3 = response.data.updateDataSrs ? moment(response.data.updateDataSrs).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_4 = response.data.updateDataTestResult ? moment(response.data.updateDataTestResult).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_5 = response.data.updateDataUAT ? moment(response.data.updateDataUAT).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_6 = response.data.updateDataFinal ? moment(response.data.updateDataFinal).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_7 = response.data.updateDatAaccecptance ? moment(response.data.updateDatAaccecptance).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_8 = response.data.updateDatsdd ? moment(response.data.updateDatsdd).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_9 = response.data.updateDateChange ? moment(response.data.updateDateChange).format('DD/MM/YYYY HH:mm') : '';
                    lastUpdateValue.tab_10 = response.data.updateDataSrs ? moment(response.data.updateDataSrs).format('DD/MM/YYYY HH:mm') : '';


                    if (menu === "PROJECT INFORMATION") {
                        setShowLastUpdate(response.data.project_updated_date ? moment(response.data.project_updated_date).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "PROJECT SCHEDULE") {
                        setShowLastUpdate(tabSched ? moment(tabSched).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "SRS") {
                        setShowLastUpdate(response.data.updateDataSrs ? moment(response.data.updateDataSrs).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "Test Result") {
                        setShowLastUpdate(response.data.updateDataTestResult ? moment(response.data.updateDataTestResult).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "UAT") {
                        setShowLastUpdate(response.data.updateDataUAT ? moment(response.data.updateDataUAT).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "Final Summary") {
                        setShowLastUpdate(response.data.updateDataFinal ? moment(response.data.updateDataFinal).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "UAT-ACCEPTANCE RECORD") {
                        setShowLastUpdate(response.data.updateDatAaccecptance ? moment(response.data.updateDatAaccecptance).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "SDD") {
                        setShowLastUpdate(response.data.updateDatsdd ? moment(response.data.updateDatsdd).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "Change Request") {
                        setShowLastUpdate(response.data.updateDateChange ? moment(response.data.updateDateChange).format('DD/MM/YYYY HH:mm') : '')
                    } else if (menu === "MOM (MINUTE OF MEETING)") {
                        setShowLastUpdate(response.data.updateDataSrs ? moment(response.data.updateDataSrs).format('DD/MM/YYYY HH:mm') : '')
                    }




                }



                /*  lastUpdateValue */
                settest({ ...test, test: '' })
                test.test = ''

            })
            .catch(function (error) {
                console.log(error);
            });
    }

    function onChangeDeviveryItem(key, value, index) {
        let ole = deliveryItem;
        ole[index][key] = value
        setDeliveryItem(ole);

        ChckStageChanTabgInfo(projectSave);
        settest({ ...test, test: '' })
        test.test = '';


    }

    function onChangeProjectFormation(key, value, index) {
        let ole = projectFormation;
        ole[index][key] = value
        setProjectFormation(ole);
        ChckStageChanTabgInfo(projectSave)
        settest({ ...test, test: '' })
        test.test = '';
    }

    function onChangeCustomerContact(key, value, index) {
        let ole = customerContact;
        ole[index][key] = value
        setcustomerContact(ole);
        ChckStageChanTabgInfo(projectSave);
        settest({ ...test, test: '' })
        test.test = '';
    }


    function onChangeSchedule(key, value, index) {
        let ole = projectSchedule;
        ole[index][key] = value
        if (key === "ps_start" || key === "ps_finish") {
            let duic = moment(ole[index].ps_finish).diff(moment(ole[index].ps_start), 'days');
            if (isNaN(duic) == false) {
                ole[index].duration = duic + 1
            } else {
                ole[index].duration = duic + 0
            }

        }
        setProjectSchedule(ole);
        ChckStageChanTabAdv()
        settest({ ...test, test: '' })
        test.test = '';
    }


    function SortScheduleUP(index_4) {
        let ole = projectSchedule;
        let getUpder = ole[index_4 - 1].ps_sort;
        let getUpderOn = ole[index_4].ps_sort;
        ole[index_4 - 1].ps_sort = getUpderOn;
        ole[index_4].ps_sort = getUpder

        console.log('getUpder', ole);
        ole.sort((a, b) => parseInt(a.ps_sort || 0) - parseInt(b.ps_sort || 0))
        setProjectSchedule(ole);
        settest({ ...test, test: '' })
        test.test = '';

    }

    function SortScheduleDow(index_4) {
        let ole = projectSchedule;
        let getUpder = ole[index_4 + 1].ps_sort;
        let getUpderOn = ole[index_4].ps_sort;
        ole[index_4 + 1].ps_sort = getUpderOn;
        ole[index_4].ps_sort = getUpder

        console.log('getUpder', ole);
        ole.sort((a, b) => parseInt(a.ps_sort || 0) - parseInt(b.ps_sort || 0))
        setProjectSchedule(ole);
        settest({ ...test, test: '' })
        test.test = '';
    }


    async function saveProject() {
        toggle_modal_confrimModal()


        if (menu === "SRS") {

            const error_list = [];

            if ((tempSrsSave.prepared_sp_name === null || (tempSrsSave.prepared_sp_name || "").trim() === "") && tempSrsSave.prepared_sp_position_id === null && tempSrsSave.verified_sp_name === null && tempSrsSave.verified_sp_position_id === null && tempSrsSave.approved_sp_name === null && tempSrsSave.customer_approved_sp_position_id === null) {
                let temp_err = {
                    message: "Please fill in information such as at least 1 field that is not a date field. "
                }
                error_list.push(temp_err);
            }

            /*    
               console.log('error_list',error_list);
               console.log('tempSrsSave',tempSrsSave); */

            if (error_list.length > 0) {

                var err_message = [];
                for (var e = 0; e < error_list.length; e++) {
                    err_message.push(error_list[e].message);
                }
                setMessageBox({
                    ...messageBox,
                    message: err_message || [],
                    isError: null,
                    menuTitle: 'WARNING'
                })
                setDisplaySwal(false)
                setAlertKickMassge(true)
            } else {
                setcustomerContact([]);
                setProjectFormationFack([])
                SaveTabSrs()
            }

        } else if (menu === "MOM (MINUTE OF MEETING)") {
            validateMOM()
        } else if (menu === "PROJECT INFORMATION") {
            const error_list = [];

            if ((projectSave.project_name || '').trim() == "") {
                let temp_err = {
                    message: "Please enter information in the fields >>> [Project Name]."
                }
                error_list.push(temp_err);
            }

            if (projectSave.project_start == "" || projectSave.project_start == null) {
                let temp_err = {
                    message: "Please enter information in the fields >>> [PROJECT START DATE]."
                }
                error_list.push(temp_err);
            }
            if (projectSave.project_finish == "" || projectSave.project_finish == null) {
                let temp_err = {
                    message: "Please enter information in the fields >>> [PROJECT FINISH DATE]."
                }
                error_list.push(temp_err);
            }

            if (deliveryItem.length > 0) {
                let getCheck = deliveryItem.filter((e) => (e.pd_due_date || '') === '')
                let getChecklist = deliveryItem.filter((e) => (e.pd_delivery_item_list || '').trim() === '')
                if (getCheck.length > 0) {
                    let temp_err = {
                        message: "Please enter information in DELIVERY ITEMS the fields >>> [DUE DATE]."
                    }
                    error_list.push(temp_err);
                }
                if (getChecklist.length > 0) {
                    let temp_err = {
                        message: "Please enter information in DELIVERY ITEMS the fields >>> [DELIVERY ITEMS LIST]."
                    }
                    error_list.push(temp_err);
                }

            }

            if (projectFormation.length > 0) {
                let getCheck = projectFormation.filter((e) => (e.pf_fullname || '').toString().trim() === '')
                /* let getChecklist1 = projectFormation.filter((e) => (e.pf_short_responsibility || '').toString().trim() === '') */
                let getChecklist2 = projectFormation.filter((e) => (e.pf_tel || '').toString().trim() === '')
                let getChecklist3 = projectFormation.filter((e) => (e.pf_email || '').toString().trim() === '')
                let getChecklist4 = projectFormation.filter((e) => (e.pf_position || '').toString().trim() === '')
                if (getCheck.length > 0) {
                    let temp_err = {
                        message: "Please enter information in PROJECT FORMATION the fields >>> [FULLNAME]."
                    }
                    error_list.push(temp_err);
                }

                /*    if (getChecklist1.length > 0) {
                       let temp_err = {
                           message: "Please enter information in PROJECT FORMATION the fields >>> [SHORT RESPONSIBILITY (OPTIONAL)]."
                       }
                       error_list.push(temp_err);
                   } */

                if (getChecklist2.length > 0) {
                    let temp_err = {
                        message: "Please enter information in PROJECT FORMATION the fields >>> [TEL]."
                    }
                    error_list.push(temp_err);
                }
                if (getChecklist3.length > 0) {
                    let temp_err = {
                        message: "Please enter information in PROJECT FORMATION the fields >>> [EMAIL]."
                    }
                    error_list.push(temp_err);
                }
                if (getChecklist4.length > 0) {
                    let temp_err = {
                        message: "Please enter information in PROJECT FORMATION the fields >>> [POSITION]."
                    }
                    error_list.push(temp_err);
                }
            }

            if (customerContact.length > 0) {
                let getCheck = customerContact.filter((e) => (e.pc_fullname || '').trim() === '')
                /* let getChecklist = customerContact.filter((e) => (e.pc_short_responsibility || '').trim() === '') */
                if (getCheck.length > 0) {
                    let temp_err = {
                        message: "Please enter information in CUSTOMER CONTACT POINT the fields >>> [FULLNAME]."
                    }
                    error_list.push(temp_err);
                }

                /* if (getChecklist.length > 0) {
                    let temp_err = {
                        message: "Please enter information in CUSTOMER CONTACT POINT the fields >>> [SHORT RESPONSIBILITY (OPTIONAL)]."
                    }
                    error_list.push(temp_err);
                }
 */

            }



            if (error_list.length > 0) {

                var err_message = [];
                for (var e = 0; e < error_list.length; e++) {
                    err_message.push(error_list[e].message);
                }
                setMessageBox({
                    ...messageBox,
                    message: err_message || [],
                    isError: null,
                    menuTitle: 'WARNING'
                })
                setDisplaySwal(false)
                setAlertKickMassge(true)
            } else {
                setcustomerContact([]);
                setProjectFormationFack([])
                SaveDataProject();
                console.log('ล้าง');

            }
        } else if (menu === "PROJECT SCHEDULE") {
            const error_list = [];

            if (projectSchedule.length > 0) {
                let getCheck = projectSchedule.filter((e) => (e.ps_task_no || '').trim() === '')
                let getChecklist = projectSchedule.filter((e) => (e.ps_task_name || '').trim() === '')
                // let getChecStart = projectSchedule.filter((e) => e.ps_start === '' || e.ps_start === null)
                // let getCheckFinght = projectSchedule.filter((e) => e.ps_finish === '' || e.ps_finish === null)

                if (getCheck.length > 0) {
                    let temp_err = {
                        message: "Please enter information in PROJECT SCHEDULE the fields >>> [TASK NO]."
                    }
                    error_list.push(temp_err);
                }

                if (getChecklist.length > 0) {
                    let temp_err = {
                        message: "Please enter information in PROJECT SCHEDULE the fields >>> [TASK NAME]."
                    }
                    error_list.push(temp_err);
                }
                // if (getChecStart.length > 0) {
                //     let temp_err = {
                //         message: "Please enter information in PROJECT SCHEDULE the fields >>> [START]."
                //     }
                //     error_list.push(temp_err);
                // }
                // if (getCheckFinght.length > 0) {
                //     let temp_err = {
                //         message: "Please enter information in PROJECT SCHEDULE the fields >>> [FINISH]."
                //     }
                //     error_list.push(temp_err);
                // }

            } else if (projectSchedule.length === 0) {
                let temp_err = {
                    message: "Please enter information in tab project schedule more 1 fild"
                }
                error_list.push(temp_err);
            }

            if (error_list.length > 0) {

                var err_message = [];
                for (var e = 0; e < error_list.length; e++) {
                    err_message.push(error_list[e].message);
                }
                setMessageBox({
                    ...messageBox,
                    message: err_message || [],
                    isError: null,
                    menuTitle: 'WARNING'
                })
                setDisplaySwal(false)
                setAlertKickMassge(true)
            } else {
                console.log('ล้าง');
                setcustomerContact([]);
                setProjectFormationFack([])
                SaveDataProjectTapAdv()
                /*  SaveDataProject(); */
            }


        } else if (menu === "Test Result") {
            SaveTabTestResult();
        } else if (menu === "UAT") {
            SaveTabUAT()
        } else if (menu === "Final Summary") {
            SaveTabFinalSummary()
        } else if (menu == "UAT-ACCEPTANCE RECORD") {
            SaveTabAcceptance()
        } else if (menu == "SDD") {
            SaveTabSDD()
        } else if (menu == "Change Request") {
            SaveTabChange();
            // SaveTabSDD()
        }


    }

    const validateMOM = () => {
        const isMomList = momSpecificField.filter(w => w.tag == "mom")
        let message = "Please fill out the information completely. "
        let isError = false, num_error = [];
        isMomList.forEach((e) => {
            e.mom_project_id = projectSave.project_id;
            for (const [key, value] of Object.entries(e)) {
                if (isString(e[value])) {
                    e[key] = (e[value]).trim()
                }
            }

            if (
                (!e.mom_location || e.mom_location == "") ||
                (!e.mom_header || e.mom_header == "") ||
                (!e.mom_start_date || e.mom_start_date == "") ||
                (!e.mom_end_date || e.mom_end_date == "") ||
                (!e.mom_record_by || e.mom_record_by == "")
            ) {
                isError = true
                num_error.push(e.index)
                console.log('1 :>> ', 1);
            } else {
                e.mom_group.forEach(i => {
                    i.group_name = (i.group_name ?? "").trim()
                    if (
                        (!i.group_name || i.group_name == "")
                    ) {
                        isError = true
                        num_error.push(e.index)
                        console.log('2 :>> ', 2);
                    } else {
                        i.person.forEach(x => {
                            x.name = (x.name ?? "").trim()
                            x.position = (x.position ?? "").trim()
                            if (
                                (!x.name || x.name == "") ||
                                (!x.position || x.position == "")
                            ) {
                                isError = true
                                num_error.push(e.index)
                                console.log('3 :>> ', 3);
                            }
                        });
                    }
                });
            }

        });

        if (isError && num_error.length > 0) {
            const err_message = [];
            err_message.push(`${message} [${num_error.toString()}]`);
            setMessageBox({
                ...messageBox,
                message: err_message || [],
                isError: null,
                menuTitle: 'WARNING'
            })
            setDisplaySwal(false)
            setAlertKickMassge(true)
        } else {
            isMomList.sort((a, b) => {
                return new Date(a.mom_start_date) - new Date(b.mom_start_date);
            });

            let index = 1;
            isMomList.forEach(e => {
                if (e.tag == "mom") {
                    e.index = index;
                    index++
                }
            })
            isMomList.sort((a, b) => {
                return new Date(b.mom_start_date) - new Date(a.mom_start_date);
            });
            saveMOM(isMomList)
        }
    }

    const saveMOM = (isMomList) => {
        // console.log('isMomList :>> ', isMomList);
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectTabMOM`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
                minute_of_meeting: isMomList,
                minute_of_meeting_del: momDelSpecificField ?? [],
            }
        }).then(async (response) => {
            if (response.data) {
                // setCheckTabEdit({
                //     ...checkTabEdit,
                //     isTab_3: false,
                // })
                // checkTabEdit.isTab_3 = false
                await getMOM()

                setMessageBox({
                    ...messageBox,
                    message: 'success',
                    isError: false,
                    menuTitle: 'Save'
                })
                setDisplaySwal(false)
                toggle_modal_alertKickMassge()
            }
        }).catch(function (error) {
            console.log(error);
        });
    }

    const getMOM = async () => {
        try {
            const res = await axios({
                method: "GET",
                url: `${Configs.TTT_Game_Service}/api/project/getProjectTabMOM/${projectSave.project_id}`,
                headers: {
                    Authorization: sessionStorage.getItem('access_token'),
                    "X-TTT": `${Configs.API_TTT}`,
                    "Content-Type": "application/json",
                },
            })
            let _momSpecificField = [];
            if (res.data.code == 200) {
                _momSpecificField = res.data.data
            }
            callbackMomSpecificField(_momSpecificField);
            setMomDelSpecificField([])
            return _momSpecificField
        } catch (error) {
            return []
        }
    }

    async function setAutoResize(mode) {
        let temp = {
            textAreaObj: 'textAreaObj',
            textAreaSco: 'textAreaSco',
            textAreaDevTool: 'textAreaDevTool',
            textAreaDevLang: 'textAreaDevLang',
            textAreaSol: 'textAreaSol',
            textAreaSdlc: 'textAreaSdlc',
            textAreaProjectname: 'textAreaProjectname',
            textAreaProjectnCustomerCode: 'textAreaProjectnCustomerCode',
            textAreaProjectCustomername: 'textAreaProjectCustomername',
            textAreaProjectteamMember: 'textAreaProjectteamMember',
        };
        if (mode === 'PROJECT SCHEDULE') {
            projectSchedule.map((e, index) => {
                temp[`schedule_id${index}`] = `schedule_id${index}`;
                temp[`project_schedule_task${index}`] = `project_schedule_task${index}`;
                temp[`project_schedule_durarion${index}`] = `project_schedule_durarion${index}`;
                temp[`project_schedule_predecessors${index}`] = `project_schedule_predecessors${index}`;
            })
        } else {
            deliveryItem.map((e, index) => {
                temp[`deli_id${index}`] = `deli_id${index}`;
                temp[`remark_id${index}`] = `remark_id${index}`;
            })
            projectFormation.map((e, index) => {
                temp[`project_id${index}`] = `project_id${index}`;
                temp[`project_Formation_name${index}`] = `project_Formation_name${index}`;
                temp[`project_Formation_position${index}`] = `project_Formation_position${index}`;
                temp[`project_Formation_email${index}`] = `project_Formation_email${index}`;
                temp[`project_Formation_respon${index}`] = `project_Formation_respon${index}`;
            })
            customerContact.map((e, index) => {
                temp[`customer_id${index}`] = `customer_id${index}`;
                temp[`project_customer_fullname${index}`] = `project_customer_fullname${index}`;
                temp[`project_customer_position${index}`] = `project_customer_position${index}`;
                temp[`project_customer_email${index}`] = `project_customer_email${index}`;
            })
        }
        await setTextAreaId(temp);

        const sortedStrings = Object.values(temp).sort();
        await sortedStrings.map(async (e, index) => {
            const textarea = document.getElementById(e);
            if (textarea != null || textarea != undefined) {
                textarea.style.height = '35px'; // รีเซ็ตความสูงก่อนที่จะปรับขนาด
                textarea.style.height = textarea.scrollHeight + 5 + 'px'; // ปรับความสูงใหม่ตามข้อความที่ป้อนเข้าไป
            }
        })
    }

    function autoResize(value) {
        const textarea = document.getElementById(value);
        if (textarea != null || textarea != undefined) {
            textarea.style.height = '35px'; // รีเซ็ตความสูงก่อนที่จะปรับขนาด
            textarea.style.height = textarea.scrollHeight + 5 + 'px'; // ปรับความสูงใหม่ตามข้อความที่ป้อนเข้าไป
        }
    }



    function searchUserByTTTID() {
        let temp = {
            ttt_id: findTTTUser.ttt_id.trim()
        }
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/userProfile/getUserByTTTID`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp

        })
            .then(function (response) {
                if (response.data.length == 0) {
                    setFindTTTUser({ ...findTTTUser, err_message: 'Not Found !!!' });

                    findTTTUser.err_message = 'Not Found !!!';
                } else {

                    let ole = customerContact;

                    var temp = {
                        pc_id: '',
                        pc_fullname: response.data[0].usr_name + " " + response.data[0].usr_lastname,
                        pc_position: response.data[0].usr_role_name || "",
                        pc_short_responsibility: "",
                        pc_email: response.data[0].usr_email || '',
                        pc_tel: response.data[0].usr_phone_number || '',
                        pc_user_id: response.data[0].usr_id,
                        pc_ttt_id: response.data[0].usr_ttt_id,
                    }
                    let checkHave = ole.filter((e) => { return e.pc_user_id == temp.pc_user_id });
                    if (checkHave.length == 0) {
                        ole.push(temp);
                        setcustomerContact(ole)
                    }


                    setFindTTTUser({ ...findTTTUser, ttt_id: '', err_message: '' });
                    findTTTUser.ttt_id = '';
                    findTTTUser.err_message = '';
                }


            })
            .catch(function (error) {
                console.log(error);


            });

    }



    function closeModalEditByAlert(value) {
        setAlertKickMassge(false)
        params.callBackModalOp('close')
        if (params.setMenuValue != undefined) {
            params.setMenuValue(false)
        }

    }

    const [dropdownreport, setdropdownreport] = useState({
        name: 'Select Report',
        mode: '',
        tagversion: '',
        versionlist: []
    })

    const [versionlistreport, setversionlistreport] = useState({
        head: [],
        row: [],

    })

    function getversionreport(mode, name) {
        // console.log('In getversionreport()');
        // console.log('mode : ', mode);
        // console.log('name : ', name);
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/getversionreport`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
                project_id: projectSave.project_id,
                template_type: mode
            }
        })
            .then(function (response) {
                console.log('res data getversionreport() : ', response.data);
                if (response.data) {
                    if (response.data.length > 0) {
                        let data = response.data[0];
                        setdropdownreport({
                            ...dropdownreport,
                            tagversion: `Version ${data.dv_version} (${moment(data.dv_date).format('DD/MM/YYYY, HH:mm')})`,
                            name: name,
                            mode: mode
                        });
                    } else {
                        setdropdownreport({
                            ...dropdownreport,
                            tagversion: `none Version`,
                            name: name,
                            mode: mode
                        });
                    }
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        getversionreport();
    }, [])

    function getallversionreport() {
        setloadinddisplay(true)
        axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/report/getallversionreport`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: {
                project_id: projectSave.project_id,
                template_type: dropdownreport.mode

            }

        })
            .then(async function (response) {
                if (response.data) {
                    // console.log(response.data);

                    let headTable = [

                        {
                            label: "version",
                            field: "version",
                        },
                        {
                            label: "date",
                            field: "date",
                        },
                        {
                            label: "summary of change",
                            field: "summary_of_change",
                        },
                        {
                            label: "version by",
                            field: "user",
                        },
                        {
                            label: "download",
                            field: "mgt",
                        },

                    ]
                    let rowsData = [];
                    for (let i of response.data) {
                        let json = {}
                        json['version'] = i.dv_version
                        json['date'] = moment(i.dv_date).format('DD/MM/YYYY')
                        let textName = i.dv_summary_of_change;

                        if (i.dv_summary_of_change !== null && i.dv_summary_of_change !== '') {
                            if (i.dv_summary_of_change.toString().length > 20) {
                                textName = i.dv_summary_of_change.toString().substring(0, 20) + "...";
                            }
                        }
                        json['summary_of_change'] = (<Tooltip color='#051F6C' placement="right" title={`${i.dv_summary_of_change}`}> {textName} </Tooltip>)
                        json['user'] = `${i.usr_name} ${i.usr_lastname}`
                        let response2 = { data: '' }
                        if (i.ul_file_path !== null) {
                            response2 = await axios.get(`https://api.box.com/2.0/files/${i.ul_file_path}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                responseType: 'blob',
                            });
                        }

                        console.log(response2.data);

                        json['mgt'] = <><button style={{ marginLeft: ' 10px', background: '#0000', padding: 0, margin: 0 }} type="button" className='btn-report1' onClick={(e) => {
                            /*      let buff = atob((`${i.ul_file_path}` || '').toString());
                                 console.log(buff); */
                            /*  const blob = new Blob([`${Configs.TTT_Game_Service_IMG}${i.ul_file_path}`], { type: 'application/pdf' });
                             console.log(blob);
                            
                             const blobUrl = URL.createObjectURL(blob); */
                            const link = document.createElement('a');
                            link.href = URL.createObjectURL(response2.data);

                            link.download = i.ul_file_name;
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);


                        }} ><b > download </b></button></>

                        rowsData.push(json)
                    }

                    setversionlistreport({ ...versionlistreport, head: headTable, row: rowsData, old: response.data })
                    setloadinddisplay(false)
                }



            })
            .catch(function (error) {
                console.log(error);

            });
    }



    const menudrop = (
        <Menu style={{ marginTop: '-5px' }}>
            <Menu.Item key="1" onClick={() => {
                getversionreport('project_plan', 'PROJECT & TIMELINE')
                /*    setdropdownreport({...dropdownreport,name:'PROJECT & TIMELINE',mode:'project_plan'}) */
                // params.callBackFucEditModal({ id: projectSave.project_id, mode: 'project_plan', on: 'download' })
            }}>PROJECT & TIMELINE</Menu.Item>
            <Menu.Item key="2" onClick={() => {
                getversionreport('user_map', 'USER MAPPING')
                // params.callBackFucEditModal({ id: projectSave.project_id, mode: 'user_map', on: 'download' })
            }}>USER MAPPING</Menu.Item>
            <Menu.Item key="3" onClick={() => {
                console.log('In SRS By -> EARTH');
                getversionreport('srs', 'SRS')
                setdropdownreport({ ...dropdownreport, name: 'SRS', mode: 'srs' })
                // params.callBackFucEditModal({ id: projectSave.project_id, mode: 'srs', on: 'download' })
            }}>SRS</Menu.Item>
            <Menu.Item key="4" onClick={() => {
                getversionreport('TEST RESULT', 'TEST RESULT')
                setdropdownreport({ ...dropdownreport, name: 'TEST RESULT', mode: 'TEST RESULT' })
              /*   params.callBackFucEditModal({ id: projectSave.project_id, mode: 'TEST RESULT', on: 'download' }) */
            }}>TEST RESULT</Menu.Item>
            <Menu.Item key="5" onClick={() => {
                getversionreport('UAT', 'UAT')
                setdropdownreport({ ...dropdownreport, name: 'UAT', mode: 'UAT' })
                // params.callBackFucEditModal({ id: projectSave.project_id, mode: 'UAT', on: 'download' })
            }}>UAT</Menu.Item>
            <Menu.Item key="6" onClick={() => {
                getversionreport('FINAL SUMMARY', 'FINAL SUMMARY PROJECT')
                setdropdownreport({ ...dropdownreport, name: 'FINAL SUMMARY PROJECT', mode: 'FINAL SUMMARY' })
                /* params.callBackFucEditModal({ id: projectSave.project_id, mode: 'FINAL SUMMARY', on: 'download' }) */
            }}>FINAL SUMMARY PROJECT</Menu.Item>
            <Menu.Item key="7" onClick={() => {
                  getversionreport('USER MANUAL', 'USER MANUAL PROJECT')
                setdropdownreport({ ...dropdownreport, name: 'USER MANUAL PROJECT', mode: 'USER MANUAL' })
                /*   params.callBackFucEditModal({ id: projectSave.project_id, mode: 'USER MANUAL', on: 'download' }) */
            }}>USER MANUAL PROJECT</Menu.Item>
        </Menu>
    );



    const [dragging, setDragging] = useState(null);

    const handleDragOver = (event) => {

        event.preventDefault();
    };


    const [checkTabEdit, setCheckTabEdit] = useState({
        isCheck: false,
        isTab_2: false,
        isTab_3: false,
        isTab_4: false,
        isTab_5: false,
        isTab_6: false,
        isTab_7: false,
        isTab_8: false,
        isTab_9: false,
    })



    function ChckStageChanTabgInfo(value, key) {
        console.log('ลองดู', deliveryItem);
        const keys_1 = Object.keys(value);
        let arrayFalse = [];
        let arrayTrue = [];
        for (let item of keys_1) {
            let ttt_2 = [];
            if (((projectSave[item] || '').toString().trim() === (projectSaveFack[item] || '').toString().trim()) === false) {
                arrayFalse.push('true');
            } else if (((projectSave[item] || '').toString().trim() === (projectSaveFack[item] || '').toString().trim()) === true) {
                ttt_2.push('false');
            }
            arrayTrue = ttt_2;
        }


        let arrayFalseDel = [];

        if (deliveryItem.length !== deliveryItemFack.length) {
            arrayFalseDel.push('true')
        } else {
            let faccc = []
            let indexs = 0;

            for (let item2 of deliveryItem) {
                const keys_2 = Object.keys(item2);




                let index_4 = 0;
                for (let item3 of keys_2) {
                    console.log('item3item3', item2[item3], deliveryItem[indexs][item3]);

                    if (((item2[item3] || '').toString().trim() === (deliveryItemFack[indexs][`edit_${item3}`] || '').toString().trim()) === false) {
                        arrayFalseDel.push('true');
                    } else if (((item2[item3] || '').toString().trim() === (deliveryItemFack[indexs][`edit_${item3}`] || '').toString().trim()) === true) {
                        faccc.push('false');
                    }

                    index_4++
                }

                indexs++
            }

        }


        let arrayFalsFormation = [];
        if (projectFormation.length !== projectFormationFack.length) {
            arrayFalseDel.push('true')
        } else {
            let faccc2 = []
            let indexs2 = 0;

            for (let item2 of projectFormation) {
                const keys_2 = Object.keys(item2);




                let index_5 = 0;
                for (let item3 of keys_2) {

                    if (((projectFormation[indexs2][item3] || '').toString().trim() === (projectFormationFack[indexs2][`edit_${item3}`] || '').toString().trim()) === false) {
                        arrayFalsFormation.push('true');
                    } else if (((projectFormation[indexs2][item3] || '').toString().trim() === (projectFormationFack[indexs2][`edit_${item3}`] || '').toString().trim()) === true) {
                        faccc2.push('false');
                    }

                    index_5++
                }

                indexs2++
            }

        }




        let arrayFalsCustomer = [];
        if (customerContact.length !== customerContactFack.length) {
            arrayFalseDel.push('true')
        } else {
            let faccc3 = []
            let indexs6 = 0;

            for (let item6 of customerContact) {
                const keys_2_1 = Object.keys(item6);




                let index_6 = 0;
                for (let item3 of keys_2_1) {

                    if (((customerContact[indexs6][item3] || '').toString().trim() === (customerContactFack[indexs6][`edit_${item3}`] || '').toString().trim()) === false) {
                        arrayFalsCustomer.push('true');
                    } else if (((customerContact[indexs6][item3] || '').toString().trim() === (customerContactFack[indexs6][`edit_${item3}`] || '').toString().trim()) === true) {
                        faccc3.push('false');
                    }

                    index_6++
                }

                indexs6++
            }

        }





        if (arrayFalse.length > 0 || arrayFalseDel.length > 0 || arrayFalsFormation.length > 0 || arrayFalsCustomer.length > 0) {
            setCheckTabEdit({ ...checkTabEdit, isCheck: true })
            checkTabEdit.isCheck = true
        }
        else {
            setCheckTabEdit({ ...checkTabEdit, isCheck: false })
            checkTabEdit.isCheck = false
        }

        settest({ ...test, test: '' })
        test.test = ''


    }



    function ChckStageChanTabAdv(value) {
        let arrayFalseDel = [];

        if (projectSchedule.length !== projectScheduleFack.length) {
            arrayFalseDel.push('true')
        } else {
            console.log('เท่ากัน', projectScheduleFack);
            let faccc = []
            let indexs = 0;

            for (let item2 of projectSchedule) {
                const keys_2 = Object.keys(item2);

                let index_4 = 0;
                for (let item3 of keys_2) {
                    console.log('item3', projectScheduleFack[indexs][item3], projectSchedule[indexs][item3]);

                    if (((projectSchedule[indexs][item3] || '').toString().trim() === (projectScheduleFack[indexs][`edit_${item3}`] || '').toString().trim()) === false) {
                        arrayFalseDel.push('true');
                    } else if (((projectSchedule[indexs][item3] || '').toString().trim() === (projectScheduleFack[indexs][`edit_${item3}`] || '').toString().trim()) === true) {
                        faccc.push('false');
                    }

                    index_4++
                }

                indexs++
            }

        }


        if (arrayFalseDel.length > 0) {
            setCheckTabEdit({ ...checkTabEdit, isTab_2: true })
            checkTabEdit.isTab_2 = true
        }
        else {
            setCheckTabEdit({ ...checkTabEdit, isTab_2: false })
            checkTabEdit.isTab_2 = false
        }

        settest({ ...test, test: '' })
        test.test = ''



    }







    async function RoleOption() {
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/master/roleOption`,
            headers: {
                Authorization: sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },

        })
            .then(async function (response) {
                // console.log(response.data);
                let tem = []
                response.data.map((e) => {

                    var setT = {
                        value: e.role_id,
                        label: e.role_name,
                        logo: e.role_current_path
                    }
                    tem.push(setT)
                })

                setRoleOption(tem);


            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function ChckStageChanTabSRS(value) {
        const keys_1 = Object.keys(value);
        let arrayFalse = [];
        let arrayTrue = [];
        for (let item of keys_1) {
            let ttt_2 = [];
            if (((tempSrsSave[item] || '').toString().trim() === (tempSrsSaveFack[item] || '').toString().trim()) === false) {
                arrayFalse.push('true');
            } else if (((tempSrsSave[item] || '').toString().trim() === (tempSrsSaveFack[item] || '').toString().trim()) === true) {
                ttt_2.push('false');
            }
            arrayTrue = ttt_2;
        }



        if (arrayFalse.length > 0) {
            setCheckTabEdit({ ...checkTabEdit, isTab_3: true })
            checkTabEdit.isTab_3 = true
        }
        else {
            setCheckTabEdit({ ...checkTabEdit, isTab_3: false })
            checkTabEdit.isTab_3 = false
        }

        settest({ ...test, test: '' })
        test.test = ''
    }


    function callBackSrsSave(value, key, value2, key2) {

        console.log('เช็คค่ากลับมา', value, key, value2, key2);
        if (key2) {
            setTempSrsSave({
                ...tempSrsSave,
                [key]: value,
                [key2]: value2,
            })
            tempSrsSave[key] = value
            tempSrsSave[key2] = value2

        } else if (key === 'approved_sp_date_sign' || key === 'verified_sp_date_sign' || key === 'prepared_sp_date_sign' || key === 'customer_approved_sp_date_sign') {
            console.log('เช็ค', key, value);
            setTempSrsSave({
                ...tempSrsSave,
                [key]: value
            })
            tempSrsSave[key] = value;


        } else {
            setTempSrsSave({
                ...tempSrsSave,
                [key]: value !== null && value.trim() !== '' ? value : null
            })
            tempSrsSave[key] = value !== null && value.trim() !== '' ? value.trim() : null;
        }



        ChckStageChanTabSRS(tempSrsSave)

        settest({ ...test, test: '' });
        test.test = '';
    }




    async function SaveTabSrs() {

        var temp = {
            project_id: projectSave.project_id,
            prepared_sp_name: tempSrsSave.prepared_sp_name || null,
            prepared_sp_position_id: tempSrsSave.prepared_sp_position_id || null,
            prepared_sp_position: tempSrsSave.prepared_sp_position || null,
            prepared_sp_operation: tempSrsSave.prepared_sp_operation || null,
            prepared_sp_date_sign: tempSrsSave.prepared_sp_date_sign || null,
            prepared_sp_operation: tempSrsSave.prepared_sp_operation || null,
            verified_sp_name: tempSrsSave.verified_sp_name || null,
            verified_sp_position_id: tempSrsSave.verified_sp_position_id || null,
            verified_sp_position: tempSrsSave.verified_sp_position || null,
            verified_sp_operation: tempSrsSave.verified_sp_operation || null,
            verified_sp_date_sign: tempSrsSave.verified_sp_date_sign || null,
            verified_sp_operation: tempSrsSave.verified_sp_operation || null,
            approved_sp_name: tempSrsSave.approved_sp_name || null,
            approved_sp_position_id: tempSrsSave.approved_sp_position_id || null,
            approved_sp_position: tempSrsSave.approved_sp_position || null,
            approved_sp_operation: tempSrsSave.approved_sp_operation || null,
            approved_sp_date_sign: tempSrsSave.approved_sp_date_sign || null,
            approved_sp_operation: tempSrsSave.approved_sp_operation || null,
            customer_approved_sp_name: tempSrsSave.customer_approved_sp_name || null,
            customer_approved_sp_position_id: tempSrsSave.customer_approved_sp_position_id || null,
            customer_approved_sp_position: tempSrsSave.customer_approved_sp_position || null,
            customer_approved_sp_operation: tempSrsSave.customer_approved_sp_operation || null,
            customer_approved_sp_date_sign: tempSrsSave.customer_approved_sp_date_sign || null,
            customer_approved_sp_operation: tempSrsSave.customer_approved_sp_operation || null,


            srs_specific: srsSpecificField,

        }

        /*    console.log('SaveSRS',temp); */

        console.log(srsSpecificField);
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectTabSRS`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_3: false,
                    })
                    checkTabEdit.isTab_3 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });




    }

    async function SaveTabTestResult() {

        var temp = {
            project_id: projectSave.project_id,
            srs_specific: srsSpecificFieldTestResult,

        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectTabTestResult`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_4: false,
                    })
                    checkTabEdit.isTab_4 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });




    }
    async function SaveTabUAT() {

        var temp = {
            project_id: projectSave.project_id,
            srs_specific: srsSpecificFieldUat,

        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectTabUAT`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_5: false,
                    })
                    checkTabEdit.isTab_5 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });




    }
    async function SaveTabFinalSummary() {

        var temp = {
            project_id: projectSave.project_id,
            srs_specific: srsSpecificFieldFinal,

        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectFinalSummary`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_6: false,
                    })
                    checkTabEdit.isTab_6 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });




    }
    async function SaveTabAcceptance() {

        var temp = {
            project_id: projectSave.project_id,
            srs_specific: srsSpecificFieldAcceptancerecord,

        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectAcceptance`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_7: false,
                    })
                    checkTabEdit.isTab_7 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });




    }

    async function SaveTabSDD() {

        var temp = {
            project_id: projectSave.project_id,
            srs_specific: srsSpecificFieldSDD,

        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectSdd`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_7: false,
                    })
                    checkTabEdit.isTab_7 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });




    }

    async function SaveTabChange() {

        var temp = {
            project_id: projectSave.project_id,
            srs_specific: changeSpecificField,

        }

        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectTabChange`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_8: false,
                    })
                    checkTabEdit.isTab_8 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });




    }



    async function SaveDataProject(params) {
        var temp = {
            project_id: projectSave.project_id,
            project_name: projectSave.project_name,
            pp_customer_code: projectSave.customer_code,
            pp_customer_name: projectSave.customer_name,
            pp_team_member: projectSave.team_member,
            pp_start_date: new Date(projectSave.project_start),
            pp_finish_date: new Date(projectSave.project_finish),
            pp_objective: projectSave.objective,
            project_description: projectSave.description,
            pp_dev_tool: projectSave.dev_tools,
            pp_dev_language: projectSave.dev_language,
            pp_solution_technology: projectSave.solution,
            pp_sdlc: projectSave.sdlc,
            delivery_item: deliveryItem || [],
            project_formation: projectFormation || [],
            customer_contact: customerContact || [],
            /*   project_Schedule: projectSchedule || [] */
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProject`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isCheck: false,
                    })
                    checkTabEdit.isCheck = false

                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    /* setDeliveryItem([])
                    setDeliveryItemFack([])
                    setProjectFormation([])
                    setProjectFormationFack([]) */
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }



    async function SaveDataProjectTapAdv(params) {
        var temp = {
            project_id: projectSave.project_id,
            project_Schedule: projectSchedule || []
        }
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/project/updateProjectTabSCHEDULE`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    setCheckTabEdit({
                        ...checkTabEdit,
                        isTab_2: false,
                    })
                    checkTabEdit.isTab_2 = false
                    setMessageBox({
                        ...messageBox,
                        message: 'success',
                        isError: false,
                        menuTitle: 'Save'
                    })
                    setDisplaySwal(false)
                    toggle_modal_alertKickMassge()
                }


            })
            .catch(function (error) {
                console.log(error);
            });
    }



    function CallBackSaveVersionport(value, stage) {
        // console.log('value 11212121', value, stage);
        if (value === 'save') {
            // const newVersion = stage.newVersion || 'defaultVersion'; // Replace with the actual logic to get the new version

            params.callBackFucEditModal({
                id: projectSave.project_id,
                mode: dropdownreport.mode,
                on: 'version',
                dv_summary_of_change: stage.dv_summary_of_change || null
            });

            toggle_modal_PopupSaveVerSummart();
            // Call getversionreport to fetch and update the latest version
            getversionreport(dropdownreport.mode, dropdownreport.name);

        } else {
            toggle_modal_PopupSaveVerSummart();
            settest({ ...test, test: '' });
            test.test = '';
        }
    }


    function callbackSrsSpecificField(dataJson) {
        setSrsSpecificField(dataJson);
    }
    function callbackMomSpecificField(dataJson) {
        setMomSpecificField(dataJson);
    }
    function callbackMomDelSpecificField(dataJson) {
        const del = momDelSpecificField;
        del.push(dataJson)
        setMomDelSpecificField(del);
    }
    function callbackSpecificFieldTestResult(dataJson) {
        setSrsSpecificFieldTestResult(dataJson);
    }
    function callbackSpecificFieldUAT(dataJson) {
        console.log('UAT Culum', dataJson);
        setSrsSpecificFieldUat(dataJson);
    }
    function callbackSpecificFieldFinalSummary(dataJson) {
        setSrsSpecificFieldFinal(dataJson);
    }
    function callbackSpecificFieldAcceptancerecord(dataJson) {
        setSrsSpecificFieldAcceptancerecord(dataJson);
    }
    function callbackSpecificFieldSDD(dataJson) {
        setSrsSpecificFieldSDD(dataJson);
    }

    function callbackChangeSpecificField(dataJson) {
        setChangeSpecificField(dataJson);
    }

    const handlePrevClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex > 0 ? prevIndex - 1 : editModalTab.length - 1;
            updateMenuAndLastUpdate(newIndex);
            return newIndex;
        });
    };

    const handleNextClick = () => {
        setCurrentIndex((prevIndex) => {
            const newIndex = prevIndex < editModalTab.length - 1 ? prevIndex + 1 : 0;
            updateMenuAndLastUpdate(newIndex);
            return newIndex;
        });
    };
    const updateMenuAndLastUpdate = (index) => {
        const e = editModalTab[index];
        if (e.tab === "PROJECT INFORMATION") {
            setShowLastUpdate(lastUpdateValue.tab_1);
        } else if (e.tab === "PROJECT SCHEDULE") {
            setShowLastUpdate(lastUpdateValue.tab_2);
        } else if (e.tab === "SRS") {
            setShowLastUpdate(lastUpdateValue.tab_3);
        } else if (e.tab === "Test Result") {
            setShowLastUpdate(lastUpdateValue.tab_10);
        } else if (e.tab === "UAT") {
            setShowLastUpdate(lastUpdateValue.tab_5);
        } else if (e.tab === "Final Summary") {
            setShowLastUpdate(lastUpdateValue.tab_6);
        } else if (e.tab === "UAT-ACCEPTANCE RECORD") {
            setShowLastUpdate(lastUpdateValue.tab_7);
        } else if (e.tab === "SDD") {
            setShowLastUpdate(lastUpdateValue.tab_8);
        } else if (e.tab === "Change Request") {
            setShowLastUpdate(lastUpdateValue.tab_9);
        } else if (e.tab === "MOM (MINUTE OF MEETING)") {
            setShowLastUpdate(lastUpdateValue.tab_4);
        }
        setMenu(e.tab);
    };

    const handleClickTab = (e, index) => {
        updateMenuAndLastUpdate(index);
        setCurrentIndex(index);
    };



    //------------------------------------------------------------------------------
    const [slideIntervals, setSlideIntervals] = useState(null);
    const tabAreaRef = useRef(null);

    const startSliding = (direction) => {
        if (slideIntervals) return;

        const interval = setInterval(() => {
            if (tabAreaRef.current) {
                if (direction === 'prev') {
                    tabAreaRef.current.scrollLeft -= 10;
                } else if (direction === 'next') {
                    tabAreaRef.current.scrollLeft += 10;
                }
            }
        }, 50);

        setSlideIntervals(interval);
    };

    const stopSliding = () => {
        clearInterval(slideIntervals);
        setSlideIntervals(null);
    };

    useEffect(() => {
        return () => {
            if (slideIntervals) {
                clearInterval(slideIntervals);
            }
        };
    }, [slideIntervals]);



    return (
        <>
            <ConfigProvider
                theme={{
                    token: {


                        colorInfo: '#85BFFF',
                        colorBorder: '#85BFFF',
                        colorText: '#FFF',
                        colorTextQuaternary: 'White',//สีตัวอักษร
                        colorTextDescription: 'White',
                        colorBgContainer: '#16285C',

                        zIndexPopupBase: 999999,
                        colorPrimaryBg: '#0047FF',
                        colorBgBase: '#051F6C',

                        colorTextQuaternary: '#FFFFFF',


                        colorFillTertiary: 'blue',


                        colorTextBase: 'White',




                        colorPrimaryBg: '#85BFFF',


                    }
                }}
            >



                {modelversion === true ?
                    <div style={{ position: 'absolute', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.8)', zIndex: '999', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <div className='cardvideo' style={{ width: '50%', height: '50%', paddingLeft: '20px', paddingRight: '20px' }}>
                            <div className='row' style={{ margin: 0, padding: '0 5px', height: '5%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', zIndex: '10', marginTop: '15px' }}>
                                <div className="pi-closePersonalInfo" onClick={() => {
                               
                                    toggle_modal_modelversion()
                                }}>
                                    <i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
                                </div>
                            </div>
                            <div style={{ position: 'absolute', fontSize: '38px', fontFamily: 'Oxanium', color: '#ffffff', marginTop: '-20px', textTransform: 'uppercase' }}>All Version {dropdownreport.name}</div>
                            {loadinddisplay === true ? <>
                                <div style={{ height: '80%', marginTop: '15%', padding: '5px', display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <div class="loadingspinnerna" style={{ width: '180px', height: '180px' }}></div>
                                </div>
                            </>
                                :
                                <>
                                    <div style={{ overflow: 'auto', height: '80%', marginTop: '45px', padding: '5px' }}>
                                        <table

                                            style={{ border: 'none' }}
                                        >
                                            <thead >
                                                <tr class="red" style={{ border: 'none' }} />

                                                {versionlistreport.head.map((head) => {
                                                    return (
                                                        <>
                                                            <th style={{ backgroundColor: '#000000', fontSize: '16px' }}>{head.label}</th>
                                                        </>
                                                    );
                                                })}



                                            </thead>
                                            <tbody >
                                                {versionlistreport.row.map((data_) => {

                                                    return (
                                                        <>
                                                            <tr className='ontableversoin'>
                                                                {versionlistreport.head.map((head, i) => {

                                                                    return (
                                                                        <>
                                                                            <td style={{ textAlign: 'left', height: '55px', fontSize: '16px' }} >{data_[head.field]}</td>
                                                                        </>
                                                                    )





                                                                })}
                                                            </tr>
                                                        </>
                                                    );
                                                })}
                                            </tbody>
                                            <tbody>
                                            </tbody>
                                        </table>

                                    </div>
                                </>}


                        </div>

                    </div>

                    :
                    <></>
                }
                <div className={`fream-project1-${params.openModal}`}>
                    <div className="boxModal-overFlow" ref={parentRef}>

                        <div className="boxModal-under" ref={childRef}>
                            <div className="col-12 btn-closePersonalInfo" style={{ padding: '0px', margin: '0px' }} onClick={() => {
                                console.log("locationElement params",params.projectTemp.pf_project_id);
                                if(sessionStorage.getItem('project_id')){
                                    socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'ProjectMonitoring', project_id: sessionStorage.getItem('project_id')});
                                }else{
                                     socketRef.current.emit("locationElement", { room: 'locationElement' + sessionStorage.getItem('user_id'), message: 'StageMonitoring'});
                                }
                              
                              
                                params.callBackModalOp('close')
                                setdropdownreport({
                                    ...dropdownreport,
                                    name: 'Select Report',
                                    mode: '',
                                    tagversion: '',
                                    versionlist: []
                                })
                                setCheckTabEdit({
                                    ...checkTabEdit,
                                    isCheck: false,
                                    isTab_2: false,
                                    isTab_3: false,
                                    isTab_4: false,
                                    isTab_5: false,
                                    isTab_6: false,
                                    isTab_7: false,
                                    isTab_8: false,
                                    isTab_9: false

                                })
                                checkTabEdit.isCheck = false
                                checkTabEdit.isTab_2 = false
                                checkTabEdit.isTab_3 = false
                                checkTabEdit.isTab_4 = false
                                checkTabEdit.isTab_5 = false
                                checkTabEdit.isTab_6 = false
                                checkTabEdit.isTab_7 = false
                                checkTabEdit.isTab_8 = false
                                checkTabEdit.isTab_9 = false
                                if (params.setMenuValue != undefined) {
                                    params.setMenuValue(false)
                                }

                            }}><i class="fa fa-times" aria-hidden="true" style={{ fontSize: "30px" }}></i>
                            </div>
                            <div className="sticky-head">
                                <div className="row header-rows" >
                                    <div className="col-3" style={{ padding: '10px' }}>
                                        <Tooltip title={projectSave.project_name || ''} placement="topLeft" color={"#0047FF"} >
                                            <div className="mt-2 text-hd1">
                                                {projectSave.project_name || ''}
                                            </div>
                                        </Tooltip>


                                    </div>

                                    <div className="col-2" style={{ padding: '15px' }}>

                                        <div style={{ textAlign: 'center' }} >
                                            <Dropdown overlay={menudrop}>
                                                <button style={{ marginLeft: '48px' }} type="button" className='btn-report1' ><b style={{}}> {dropdownreport.name} </b></button>
                                            </Dropdown>
                                        </div>
                                        <div className="textversion" style={{ marginLeft: '48px' }} onClick={() => {
                                            getallversionreport()
                                            toggle_modal_modelversion()
                                        }}>{dropdownreport.tagversion}</div>
                                    </div>


                                    <div className="col-2" style={{ padding: '15px' }}>
                                        <div style={{ textAlign: 'center' }} onClick={() => params.callBackFucEditModal({ id: projectSave.project_id, mode: 'all' })}>
                                            <button style={{ marginLeft: ' 35px' }} type="button" className='btn-report1' ><b style={{}}> Download All Report </b></button>

                                        </div>
                                    </div>

                                    <div className="col-2" style={{ padding: '15px' }}>
                                        {dropdownreport.mode !== '' ?
                                            <>
                                                <div style={{ textAlign: 'center' }} onClick={() => {
                                                    if (userismode === false) {
                                                        toggle_modal_PopupSaveVerSummart()
                                                    }

                                                    /*  params.callBackFucEditModal({ id: projectSave.project_id, mode: dropdownreport.mode, on: 'version' })
                                                     setdropdownreport({ ...dropdownreport, tagversion: ``, name: 'Select Report', mode: '' }) */
                                                }}>
                                                    <button style={{ marginLeft: '30px' }} type="button" className='btn-report1' ><b style={{}}> save version </b></button>

                                                </div>
                                            </>
                                            : <></>}

                                    </div>

                                    <div className="col-1" style={{ padding: '15px' }}>
                                        {dropdownreport.mode !== '' ?
                                            <>
                                                <div style={{ /* textAlign: 'center' */ }} onClick={() => {
                                                    /* if (userismode === false) { */
                                                    // toggle_modal_PopupSaveVerSummart()
                                                    if (dropdownreport.mode == 'project_plan') {
                                                        params.callBackFucEditModal({ id: projectSave.project_id, mode: 'project_plan', on: 'download' })
                                                    } else if (dropdownreport.mode == 'user_map') {
                                                        params.callBackFucEditModal({ id: projectSave.project_id, mode: 'user_map', on: 'download' })
                                                    } else if (dropdownreport.mode == 'srs') {
                                                        params.callBackFucEditModal({ id: projectSave.project_id, mode: 'srs', on: 'download' })
                                                    } else if (dropdownreport.mode == 'UAT') {
                                                        params.callBackFucEditModal({ id: projectSave.project_id, mode: 'UAT', on: 'download' })
                                                    } else if (dropdownreport.mode == 'TEST RESULT') {
                                                        params.callBackFucEditModal({ id: projectSave.project_id, mode: 'TEST RESULT', on: 'download' })
                                                    } else if (dropdownreport.mode == 'FINAL SUMMARY') {
                                                        params.callBackFucEditModal({ id: projectSave.project_id, mode: 'FINAL SUMMARY', on: 'download' })
                                                    } else if (dropdownreport.mode == 'USER MANUAL') {
                                                        params.callBackFucEditModal({ id: projectSave.project_id, mode: 'USER MANUAL', on: 'download' })
                                                    }
                                                  
                                                   
                                                    /*  } */
                                                    /*  params.callBackFucEditModal({ id: projectSave.project_id, mode: dropdownreport.mode, on: 'version' })
                                                     setdropdownreport({ ...dropdownreport, tagversion: ``, name: 'Select Report', mode: '' }) */
                                                }}>
                                                    <button style={{ marginLeft: ' 10px', width: '50px' }} type="button" className='btn-report1' >
                                                        <img
                                                            src={load}
                                                            alt="description"
                                                        // className="icon-hover"
                                                        />
                                                    </button>

                                                </div>
                                            </>
                                            : <></>}

                                    </div>


                                    <div className="col-2" style={{ padding: '15px' }}>
                                        <div style={{ textAlign: 'center', marginRight: '10px' }}>
                                            <button style={{ marginLeft: ' 10px' }} onClick={() => {
                                                if (userismode === false) {
                                                    toggle_modal_confrimModal()
                                                }
                                            }} type="button" className='btn-saveButton' ><img src={IconSave} style={{ width: "17px", height: "17px", position: "relative", top: "-1.5px", margin: "5px" }}></img>SAVE</button>
                                            <br />
                                            <span style={{ fontSize: '11px', position: 'relative', top: '5px', fontWeight: '700', color: 'white', whiteSpace: 'nowrap' }}> Last Update: {showLastUpdate/* projectSave.lastupdate */} </span>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className="boxCrad">
                                <div className="row" style={{ padding: '0px', margin: '0px', width: '100%', justifyContent: "center" }} >
                                    <div className="col-11 row" style={{ padding: '0px', margin: '0px', width: '100%', justifyContent: "center" }}>
                                        <div className='clickSlide'>
                                            {/* <img src={Previous_02} className='iconPrevious' onClick={handlePrevClick}></img>
                                            <img src={Next_02}  className='iconNext' onClick={handleNextClick}></img> */}
                                            <img
                                                src={Previous_02}
                                                className='iconPrevious'
                                                onMouseDown={() => startSliding('prev')}
                                                onMouseUp={stopSliding}
                                                onMouseLeave={stopSliding}
                                            />
                                            <img
                                                src={Next_02}
                                                className='iconNext'
                                                onMouseDown={() => startSliding('next')}
                                                onMouseUp={stopSliding}
                                                onMouseLeave={stopSliding}
                                            />
                                        </div>

                                        <div className="tabAarea" id='tabAarea' style={{ position: "relative", zIndex: "1" }} ref={tabAreaRef}>
                                            {editModalTab.map((e, index) => (
                                                <div
                                                    key={index}
                                                    className={`tablist ${currentIndex === index ? 'active' : ''}`}
                                                    onClick={() => handleClickTab(e, index)}
                                                >
                                                    <div className="" style={{ borderBottom: `${menu === e.tab ? '2px solid #0047FF' : ''}`, color: `${menu === e.tab ? '#0047FF' : ''}` }}>
                                                        {e.tab}
                                                        {e.tab === "PROJECT INFORMATION" && checkTabEdit.isCheck ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "PROJECT SCHEDULE" && checkTabEdit.isTab_2 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "SRS" && checkTabEdit.isTab_3 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "Test Result" && checkTabEdit.isTab_10 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "UAT" && checkTabEdit.isTab_5 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "Final Summary" && checkTabEdit.isTab_6 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "UAT-ACCEPTANCE RECORD" && checkTabEdit.isTab_7 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "Change Request" && checkTabEdit.isTab_9 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                        {e.tab === "MOM (MINUTE OF MEETING)" && checkTabEdit.isTab_4 ? (<span style={{ color: 'red' }}> * </span>) : null}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {/* ปุ่ม + */}
                                        {/* <div className="areaPush" style={{ padding: '3px 0px 0px 0px' }}>
                                            <i class="fa fa-plus-circle btn-plue iconpushClass" style={{ marginLeft: '0px', fontSize: '20px', padding: '2px 1px 6px 10px' }} onClick={() => {
                                            }}></i>
                                        </div> */}

                                    </div>
                                    {/* <div className="col-1" style={{ width: '100%', padding: '0px', display: 'grid', justifyItems: 'end' }}>
                                        <div className="iconRightProjectInfo row">
                                            <img src={IconSvg} style={{ cursor: 'pointer', border: '3px solid #0047FF', width: '40px', height: '40px', marginRight: '5px', borderRadius: '10px', backgroundColor: 'rgba(0, 0, 0,0.9)' }} />
                                            <img src={IconSvgShare} style={{ cursor: 'pointer', border: '3px solid #0047FF', width: '40px', height: '40px', borderRadius: '10px', backgroundColor: 'rgba(0, 0, 0,0.9)' }} />
                                        </div>
                                    </div> */}

                                </div>




                                {menu === 'PROJECT INFORMATION' ? (<>

                                    <div className="box-content-evens1">

                                        <div className="row delpm" >
                                            <div className="col-11" >
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Project Code</div>
                                                <div className="pi-infoBoxTextShadow" >
                                                    <div className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaProjectname}
                                                        type='text'
                                                        placeholder='Menu Description...'
                                                        value={projectSave.project_id}
                                                        disabled={userismode}
                                                    >
                                                        {projectSave.project_id}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-1" style={{ display: 'flex', alignItems: 'flex-end' }} >
                                                <button className="copyButton"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(projectSave.project_id).then(() => {
                                                            setCopyText('Copied');
                                                            setTimeout(() => { setCopyText('Copy'); }, 500);
                                                        })
                                                    }}>{copyText}</button>
                                            </div>
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Project Name</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px ${projectSave.project_name !== undefined && projectSave.project_name !== null && projectSave.project_name.trim() !== '' ? '#5D9BE1' : 'red'})` }}>

                                                    {/*  <textarea className="areaTEst"
                                                        id={textAreaId.textAreaProjectname}
                                                        type='text'
                                                        value={projectSave.project_name}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaProjectname)
                                                            setProjectSave({ ...projectSave, project_name: e.target.value })
                                                            projectSave.project_name = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'project_name')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaProjectname}
                                                        type='text'
                                                        placeholder='Menu Description...'
                                                        value={projectSave.project_name}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaProjectname)
                                                            setProjectSave({ ...projectSave, project_name: e.target.value })
                                                            projectSave.project_name = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'project_name')
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-6">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Customer Code</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }} >

                                                    {/*   <textarea className="areaTEst"
                                                        id={textAreaId.textAreaProjectnCustomerCode}
                                                        type='text'
                                                        value={projectSave.customer_code}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaProjectnCustomerCode)
                                                            setProjectSave({ ...projectSave, customer_code: e.target.value })
                                                            projectSave.customer_code = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'customer_code')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaProjectnCustomerCode}
                                                        type='text'
                                                        value={projectSave.customer_code}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaProjectnCustomerCode)
                                                            setProjectSave({ ...projectSave, customer_code: e.target.value })
                                                            projectSave.customer_code = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'customer_code')
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Customer Name</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                    {/*   <textarea className="areaTEst"
                                                        id={textAreaId.textAreaProjectCustomername}
                                                        type='text'
                                                        value={projectSave.customer_name}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaProjectCustomername)
                                                            setProjectSave({ ...projectSave, customer_name: e.target.value })
                                                            projectSave.customer_name = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'customer_name')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaProjectCustomername}
                                                        type='text'
                                                        value={projectSave.customer_name}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaProjectCustomername)
                                                            setProjectSave({ ...projectSave, customer_name: e.target.value })
                                                            projectSave.customer_name = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'customer_name')
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-3">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Team Member</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                    {/*  <textarea className="areaTEst"
                                                        id={textAreaId.textAreaProjectteamMember}
                                                        type='text'
                                                        value={projectSave.team_member}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaProjectteamMember)
                                                            setProjectSave({ ...projectSave, team_member: e.target.value })
                                                            projectSave.team_member = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'team_member')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaProjectteamMember}
                                                        type='text'
                                                        value={projectSave.team_member}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaProjectteamMember)
                                                            setProjectSave({ ...projectSave, team_member: e.target.value })
                                                            projectSave.team_member = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'team_member')
                                                        }}
                                                    />

                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Project Start Date</div>
                                                <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${projectSave.project_start !== undefined && projectSave.project_start !== null && projectSave.project_start !== '' ? '#5D9BE1' : 'red'})` }} >
                                                    <StyledDatePicker
                                                        className="dateText2 "
                                                        value={projectSave.project_start ? dayjs(projectSave.project_start) : null}
                                                        disabled={userismode}
                                                        placeholder="วว/ดด/ปปปป"

                                                        format={"DD/MM/YYYY"}
                                                        onChange={(date, dateString) => {
                                                            setProjectSave({ ...projectSave, project_start: date ? date.$d : null })
                                                            projectSave.project_start = date ? date.$d : null
                                                            ChckStageChanTabgInfo(projectSave, 'project_start')
                                                        }}
                                                        disabledDate={disabledStartDate}
                                                    />


                                                    {/* <input className="dateText datepicker-input"

                                                        type='date'
                                                        max={moment(projectSave.project_finish).format('YYYY-MM-DD')}
                                                        value={moment(projectSave.project_start).format('YYYY-MM-DD')}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            setProjectSave({ ...projectSave, project_start: new Date(e.target.value) })
                                                            projectSave.project_start = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'project_start')
                                                        }}
                                                    >
                                                    </input> */}
                                                </div>
                                            </div>
                                            <div className="col-3">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Project Finish Date</div>
                                                <div className="pi-infoBoxTextShadow" style={{ filter: `drop-shadow(0px 0px 3px ${projectSave.project_finish !== undefined && projectSave.project_finish !== null && projectSave.project_finish !== '' ? '#5D9BE1' : 'red'})` }}>
                                                    <StyledDatePicker
                                                        className="dateText2"
                                                        value={projectSave.project_finish ? dayjs(projectSave.project_finish) : null}
                                                        disabled={userismode}
                                                        placeholder="วว/ดด/ปปปป"
                                                        format={"DD/MM/YYYY"}
                                                        onChange={(date, dateString) => {
                                                            setProjectSave({ ...projectSave, project_finish: date ? date.$d : null })
                                                            projectSave.project_finish = date ? date.$d : null
                                                            ChckStageChanTabgInfo(projectSave, 'project_finish')
                                                        }}
                                                        disabledDate={disabledEndDate}
                                                    />
                                                    {/* <input className="dateText"
                                                        type='date'
                                                        min={ moment(projectSave.project_start).format('YYYY-MM-DD')
                                                        }
                                                        value={moment(projectSave.project_finish).format('YYYY-MM-DD')}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            setProjectSave({ ...projectSave, project_finish: new Date(e.target.value) })
                                                            projectSave.project_finish = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'project_finish')
                                                        }}
                                                    >
                                                    </input> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Objective</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                    {/*  <textarea className="areaTEst"
                                                        id={textAreaId.textAreaObj}
                                                        type='text'
                                                        value={projectSave.objective}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaObj)
                                                            setProjectSave({ ...projectSave, objective: e.target.value })
                                                            projectSave.objective = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'objective')
                                                        }}
                                                    >
                                                    </textarea> */}

                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaObj}
                                                        type='text'
                                                        value={projectSave.objective}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaObj)
                                                            setProjectSave({ ...projectSave, objective: e.target.value })
                                                            projectSave.objective = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'objective')
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Scope/ Short Description</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                    {/* <textarea className="areaTEst"
                                                        id={textAreaId.textAreaSco}
                                                        type='text'
                                                        value={projectSave.description}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaSco)
                                                            setProjectSave({ ...projectSave, description: e.target.value })
                                                            projectSave.description = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'description')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaSco}
                                                        type='text'
                                                        value={projectSave.description}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaSco)
                                                            setProjectSave({ ...projectSave, description: e.target.value })
                                                            projectSave.description = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'description')
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Development Tools</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                    {/*  <textarea className="areaTEst"
                                                        id={textAreaId.textAreaDevTool}
                                                        type='text'
                                                        value={projectSave.dev_tools}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaDevTool)
                                                            setProjectSave({ ...projectSave, dev_tools: e.target.value })
                                                            projectSave.dev_tools = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'dev_tools')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaDevTool}
                                                        type='text'
                                                        value={projectSave.dev_tools}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaDevTool)
                                                            setProjectSave({ ...projectSave, dev_tools: e.target.value })
                                                            projectSave.dev_tools = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'dev_tools')
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Development Language</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                    {/* <textarea className="areaTEst"
                                                        id={textAreaId.textAreaDevLang}
                                                        type='text'
                                                        value={projectSave.dev_language}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaDevLang)
                                                            setProjectSave({ ...projectSave, dev_language: e.target.value })
                                                            projectSave.dev_language = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'dev_language')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaDevLang}
                                                        type='text'
                                                        value={projectSave.dev_language}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaDevLang)
                                                            setProjectSave({ ...projectSave, dev_language: e.target.value })
                                                            projectSave.dev_language = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'dev_language')
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Solution / Technology</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                    {/* <textarea className="areaTEst"
                                                        id={textAreaId.textAreaSol}
                                                        type='text'
                                                        value={projectSave.solution}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaSol)
                                                            setProjectSave({ ...projectSave, solution: e.target.value })
                                                            projectSave.solution = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'solution')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaSol}
                                                        type='text'
                                                        value={projectSave.solution}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaSol)
                                                            setProjectSave({ ...projectSave, solution: e.target.value })
                                                            projectSave.solution = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'solution')
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>
                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px' }}>SDLC</div>
                                                <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }} >

                                                    {/* <textarea className="areaTEst"
                                                        id={textAreaId.textAreaSdlc}
                                                        type='text'
                                                        value={projectSave.sdlc}
                                                        onChange={(e) => {
                                                            autoResize(textAreaId.textAreaSdlc)
                                                            setProjectSave({ ...projectSave, sdlc: e.target.value })
                                                            projectSave.sdlc = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'sdlc')
                                                        }}
                                                    >
                                                    </textarea> */}
                                                    <TextArea
                                                        className="areaTEst rezieznone"
                                                        style={{ borderRadius: '0px' }}
                                                        autoSize={{
                                                            minRows: 1
                                                        }}
                                                        id={textAreaId.textAreaSdlc}
                                                        type='text'
                                                        value={projectSave.sdlc}
                                                        disabled={userismode}
                                                        onChange={(e) => {
                                                            //autoResize(textAreaId.textAreaSdlc)
                                                            setProjectSave({ ...projectSave, sdlc: e.target.value })
                                                            projectSave.sdlc = e.target.value
                                                            ChckStageChanTabgInfo(projectSave, 'sdlc')
                                                        }}
                                                    />

                                                </div>
                                            </div>


                                        </div>

                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px', textDecoration: 'underline' }}>Delivery Items
                                                    {userismode === false ? (
                                                        <i class="fa fa-plus-circle btn-plue " style={{ marginLeft: '0px', fontSize: '20px', padding: '2px 1px 6px 10px' }} onClick={() => {
                                                            InputDeviveryItem(projectSave)
                                                            ChckStageChanTabgInfo(projectSave)
                                                            setAutoResize('info')
                                                        }}></i>
                                                    ) : <></>}

                                                </div>
                                                {deliveryItem.length > 0 ? (<>
                                                    {deliveryItem.map((item_1, index_1) => {

                                                        return (
                                                            <div className="mt-4 row crad-box-rows" style={{ padding: '35px 0px 35px 0px' }}>
                                                                <div className="col-1" style={{ margin: '0', padding: '0 10px' }}>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', textAlign: 'center', marginTop: '2px', marginBottom: '10px' }}>{index_1 === 0 ? 'No.' : ''}</div>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', textAlign: 'center', marginTop: '16px' }}>{index_1 + 1}</div>
                                                                </div>
                                                                <div className="col-2" style={{ margin: '0 0 10px 0', padding: '0 10px' }}>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginBottom: '10px' }}>{index_1 === 0 ? 'Due Date' : ''}</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>
                                                                        <StyledDatePicker
                                                                            className="dateText2"
                                                                            value={item_1.pd_due_date ? dayjs(item_1.pd_due_date) : null}
                                                                            disabled={userismode}
                                                                            placeholder="วว/ดด/ปปปป"
                                                                            format={"DD/MM/YYYY"}
                                                                            onChange={(date, dateString) => {
                                                                                onChangeDeviveryItem('pd_due_date', date ? date.$d : null, index_1)
                                                                            }}
                                                                            disabledDate={disabledStartDate}
                                                                        />
                                                                        {/* <input className="dateText"

                                                                            type='date'
                                                                            value={moment(item_1.pd_due_date).format('YYYY-MM-DD')}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {
                                                                                onChangeDeviveryItem('pd_due_date', new Date(e.target.value), index_1)
                                                                            }}
                                                                        >
                                                                        </input> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-5" style={{ margin: '0', padding: '0 10px' }}>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '3px', marginBottom: '13px' }}>{index_1 === 0 ? 'Delivery Items List' : ''}</div>

                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>
                                                                        {/* <textarea className="areaTEst"
                                                                            id={textAreaId[`deli_id${index_1}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '-2px' }}
                                                                            value={item_1.pd_delivery_item_list}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`deli_id${index_1}`])
                                                                                onChangeDeviveryItem('pd_delivery_item_list', e.target.value, index_1)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '-2px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`deli_id${index_1}`]}
                                                                            type='text'
                                                                            value={item_1.pd_delivery_item_list}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {
                                                                                //autoResize(textAreaId[`deli_id${index_1}`])
                                                                                onChangeDeviveryItem('pd_delivery_item_list', e.target.value, index_1)
                                                                            }}
                                                                        />


                                                                    </div>
                                                                </div>
                                                                <div className="col-3" style={{ margin: '0', padding: '0 10px' }}>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '3px', marginBottom: '10px' }}>{index_1 === 0 ? 'Remark' : ''}</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                                        {/*  <textarea className="areaTEst"
                                                                            id={textAreaId[`remark_id${index_1}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '1px' }}
                                                                            value={item_1.pd_remark}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`remark_id${index_1}`])
                                                                                onChangeDeviveryItem('pd_remark', e.target.value, index_1)

                                                                            }}
                                                                        >
                                                                        </textarea> */}

                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '1px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`remark_id${index_1}`]}
                                                                            type='text'
                                                                            value={item_1.pd_remark}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {
                                                                                //autoResize(textAreaId[`remark_id${index_1}`])
                                                                                onChangeDeviveryItem('pd_remark', e.target.value, index_1)

                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                {userismode === false ? (
                                                                    <div className="col-1" style={{ margin: '0', padding: '0 10px' }}>
                                                                        <div className="pi-infoText" style={{ fontSize: '17.005px', textAlign: 'center', marginTop: '2px' }}>{index_1 === 0 ? 'REMOVE' : ''}</div>

                                                                        <div className="btn-closePersonalInfo" style={{ padding: '8px 58px 55px 28px', margin: '0px', textAlign: 'center', }}
                                                                            onClick={() => {
                                                                                RemoveDeviveryItem(index_1)

                                                                            }}> <i class="fa fa-trash detailIcon" aria-hidden="true" style={{ fontSize: "20px", marginTop: '10px' }}></i>

                                                                        </div>
                                                                    </div>) : <></>}
                                                            </div>
                                                        )
                                                    })}


                                                </>) : (<></>)}
                                            </div>

                                        </div>
                                        <hr style={{ textDecoration: 'underline' }} />

                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px', textDecoration: 'underline' }}>Project formation

                                                </div>
                                                {projectFormation.length > 0 ? (<>
                                                    {projectFormation.map((item_2, index_2) => {
                                                        return (
                                                            <div className="mt-4 row crad-box-rows" style={{ padding: '35px 0px 35px 0px' }}>

                                                                <div className="col-1" style={{ marginTop: '9px' }}>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', textAlign: 'center', marginTop: '-1px' }}>No.</div>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '19px', textAlign: 'center' }}>{index_2 + 1}</div>

                                                                </div>
                                                                <div className="col-5">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '8px' }}>Fullname</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                                        {/*  <textarea className="areaTEst"
                                                                            id={textAreaId[`project_Formation_name${index_2}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '-2px' }}
                                                                            value={item_2.pf_fullname}
                                                                            disabled
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_Formation_name${index_2}`])
                                                                                onChangeProjectFormation('pf_fullname', e.target.value, index_2)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '-2px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_Formation_name${index_2}`]}
                                                                            type='text'

                                                                            value={item_2.pf_fullname}
                                                                            disabled
                                                                            onChange={(e) => {

                                                                                onChangeProjectFormation('pf_fullname', e.target.value, index_2)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '7.8px' }}>Position</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                                        {/*  <textarea className="areaTEst"
                                                                            id={textAreaId[`project_Formation_position${index_2}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '2px' }}
                                                                            value={item_2.pf_position}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_Formation_position${index_2}`])
                                                                                onChangeProjectFormation('pf_position', e.target.value, index_2)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '2px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_Formation_position${index_2}`]}
                                                                            type='text'
                                                                            disabled={userismode}
                                                                            value={item_2.pf_position}
                                                                            onChange={(e) => {

                                                                                onChangeProjectFormation('pf_position', e.target.value, index_2)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="mt-1 col-3">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginBottom: '6px' }}>Tel</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1`, marginBottom: '0px' }}>
                                                                        {userismode === false ? (<>
                                                                            <PatternFormat
                                                                                placeholder={'PHONE NUMBER'}
                                                                                className="areaTEst"
                                                                                disabled

                                                                                type='text'
                                                                                value={item_2.pf_tel}
                                                                                onChange={(e) => {
                                                                                    onChangeProjectFormation('pf_tel', e.target.value, index_2)

                                                                                }}
                                                                            >
                                                                            </PatternFormat>
                                                                        </>) : (<>
                                                                            <TextArea
                                                                                className="areaTEst rezieznone"
                                                                                style={{ borderRadius: '0px', marginTop: '2px' }}
                                                                                autoSize={{
                                                                                    minRows: 1
                                                                                }}
                                                                                type='text'
                                                                                disabled={userismode}
                                                                                value={item_2.pf_tel != null ? item_2.pf_tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : ''}

                                                                            /></>)}

                                                                    </div>
                                                                </div>


                                                                <div className="mt-1 col-1">

                                                                </div>
                                                                <div className="mt-1 col-7">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Short Responsibility (Optional)</div>
                                                                    {/*   <textarea className="areaTEst"
                                                                        id={textAreaId[`project_Formation_respon${index_2}`]}
                                                                        type='text'
                                                                        value={item_2.pf_short_responsibility}
                                                                        onChange={(e) => {
                                                                            autoResize(textAreaId[`project_Formation_respon${index_2}`])
                                                                            onChangeProjectFormation('pf_short_responsibility', e.target.value, index_2)

                                                                        }}
                                                                    >
                                                                    </textarea> */}

                                                                    <TextArea
                                                                        className="areaTEst rezieznone"
                                                                        style={{ borderRadius: '0px', marginTop: '2px' }}
                                                                        autoSize={{
                                                                            minRows: 1
                                                                        }}
                                                                        id={textAreaId[`project_Formation_respon${index_2}`]}
                                                                        type='text'
                                                                        value={item_2.pf_short_responsibility}
                                                                        disabled={userismode}
                                                                        onChange={(e) => {

                                                                            onChangeProjectFormation('pf_short_responsibility', e.target.value, index_2)

                                                                        }}
                                                                    />

                                                                </div>
                                                                <div className="col-4" style={{ marginTop: '9.10px' }}>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px',/*  marginTop: '9px' */ }}>Email</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                                        {/*  <textarea className="areaTEst"
                                                                            id={textAreaId[`project_Formation_email${index_2}`]}
                                                                            type='text'
                                                                            // disabled 
                                                                            style={{ marginTop: '5px' }}
                                                                            value={item_2.pf_email}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_Formation_email${index_2}`])
                                                                                onChangeProjectFormation('pf_email', e.target.value, index_2)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '5px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_Formation_email${index_2}`]}
                                                                            type='text'
                                                                            value={item_2.pf_email}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeProjectFormation('pf_email', e.target.value, index_2)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>



                                                            </div>

                                                        )
                                                    })}

                                                </>) : (<></>)}
                                            </div>
                                        </div>
                                        <hr style={{ textDecoration: 'underline' }} />
                                        <div className="mt-4 row delpm">
                                            <div className="col-12">
                                                <div className="pi-infoText" style={{ fontSize: '17.005px', textDecoration: 'underline' }}>Customer Contact Point
                                                    {userismode === false ? (
                                                        <i class="fa fa-plus-circle btn-plue " style={{ marginLeft: '0px', fontSize: '20px', padding: '2px 1px 6px 10px' }} onClick={() => {
                                                            InputCustomerContact()
                                                            setAutoResize('info')
                                                        }}></i>
                                                    ) : <></>}




                                                </div>
                                                <div className='row'>
                                                    <div className="col-2">
                                                        <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '7.8px' }}>TTT ID <font color="red">{findTTTUser.err_message}</font></div>
                                                        <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>
                                                            <div className="project-infoBoxText3" style={{ marginTop: '3.8px' }} >
                                                                <div className="project-infoBoxTextCen3">
                                                                    <input className="project-infoBoxTextIn3"

                                                                        type='text'
                                                                        value={findTTTUser.ttt_id}
                                                                        disabled={userismode}
                                                                        onChange={(e) => {
                                                                            setFindTTTUser({ ...findTTTUser, ttt_id: e.target.value });
                                                                        }}

                                                                    >
                                                                    </input>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="col-1">
                                                        <div
                                                            onClick={() => {
                                                                searchUserByTTTID();

                                                                ChckStageChanTabgInfo(projectSave);
                                                            }}><i class="fa fa-search" aria-hidden="true" style={{ fontSize: '25px', position: 'absolute', left: '-5px', top: '40px' }}></i>
                                                        </div>
                                                    </div>
                                                </div>

                                                {customerContact.length > 0 ? (<>
                                                    {customerContact.map((item_3, index_3) => {
                                                        return (
                                                            <div className="mt-4 row crad-box-rows" style={{ padding: '35px 0px 35px 0px' }}>

                                                                <div className="col-1" style={{ marginTop: '9px' }}>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', textAlign: 'center', marginTop: '-1px' }}>No.</div>
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '19px', textAlign: 'center' }}>{index_3 + 1}</div>

                                                                </div>
                                                                <div className="col-5">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '8px' }}>Fullname</div>
                                                                    <div className="pi-infoBoxTextShadow" >

                                                                        {/* <textarea className="areaTEst"
                                                                            id={textAreaId[`project_customer_fullname${index_3}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '-2px' }}
                                                                            value={item_3.pc_fullname}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_customer_fullname${index_3}`])
                                                                                onChangeCustomerContact('pc_fullname', e.target.value, index_3)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '-2px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_customer_fullname${index_3}`]}
                                                                            type='text'
                                                                            value={item_3.pc_fullname}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeCustomerContact('pc_fullname', e.target.value, index_3)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '7.8px' }}>Position</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                                        {/*  <textarea className="areaTEst "
                                                                            id={textAreaId[`project_customer_position${index_3}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '1px' }}
                                                                            value={item_3.pc_position}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_customer_position${index_3}`])
                                                                                onChangeCustomerContact('pc_position', e.target.value, index_3)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '1px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_customer_position${index_3}`]}
                                                                            type='text'
                                                                            value={item_3.pc_position}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeCustomerContact('pc_position', e.target.value, index_3)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="mt-1 col-3">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginBottom: '6px' }}>Tel</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1`, marginBottom: '0px' }}>

                                                                        {userismode === false ? (<>

                                                                            <PatternFormat
                                                                                placeholder={'PHONE NUMBER'}
                                                                                className="project-infoBoxTextIn-1"

                                                                                type='text'
                                                                                value={item_3.pc_tel}
                                                                                disabled={userismode}
                                                                                onChange={(e) => {
                                                                                    onChangeCustomerContact('pc_tel', e.target.value, index_3)
                                                                                }}
                                                                            >
                                                                            </PatternFormat>
                                                                        </>) : (<>
                                                                            <TextArea
                                                                                className="areaTEst rezieznone"
                                                                                style={{ borderRadius: '0px', marginTop: '2px' }}
                                                                                autoSize={{
                                                                                    minRows: 1
                                                                                }}

                                                                                type='text'
                                                                                disabled={userismode}
                                                                                value={item_3.pc_tel != null ? item_3.pc_tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : ''}

                                                                            /></>)}

                                                                    </div>
                                                                </div>
                                                                <div className="mt-1 col-1">

                                                                </div>

                                                                <div className="col-6">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px' }}>Short Responsibility (Optional)</div>
                                                                    {/*       <textarea className="areaTEst"
                                                                        id={textAreaId[`customer_id${index_3}`]}
                                                                        type='text'

                                                                        value={item_3.pc_short_responsibility}
                                                                        onChange={(e) => {
                                                                            autoResize(textAreaId[`customer_id${index_3}`])
                                                                            onChangeCustomerContact('pc_short_responsibility', e.target.value, index_3)
                                                                        }}
                                                                    >
                                                                    </textarea> */}

                                                                    <TextArea
                                                                        className="areaTEst rezieznone"
                                                                        style={{ borderRadius: '0px' }}
                                                                        autoSize={{
                                                                            minRows: 1
                                                                        }}
                                                                        id={textAreaId[`customer_id${index_3}`]}
                                                                        type='text'
                                                                        value={item_3.pc_short_responsibility}
                                                                        disabled={userismode}
                                                                        onChange={(e) => {

                                                                            onChangeCustomerContact('pc_short_responsibility', e.target.value, index_3)
                                                                        }}
                                                                    />


                                                                </div>
                                                                <div className="col-3">
                                                                    <div className="pi-infoText" style={{ fontSize: '17.005px', marginTop: '9px' }}>Email</div>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }}>

                                                                        {/*  <textarea className="areaTEst"
                                                                            id={textAreaId[`project_customer_email${index_3}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '5px' }}
                                                                            value={item_3.pc_email}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_customer_email${index_3}`])
                                                                                onChangeCustomerContact('pc_email', e.target.value, index_3)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '5px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_customer_email${index_3}`]}
                                                                            type='text'
                                                                            value={item_3.pc_email}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeCustomerContact('pc_email', e.target.value, index_3)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                {userismode === false ? (
                                                                    <div className="col-1" style={{ marginTop: '10px', }}>
                                                                        <div className="pi-infoText" style={{ fontSize: '17.005px', textAlign: 'center', visibility: 'hidden' }}>Remove</div>

                                                                        <div className="btn-closePersonalInfo"
                                                                            onClick={() => {
                                                                                RemoveCustomerContact(index_3)
                                                                            }}>  <i class="fa fa-trash detailIcon" aria-hidden="true" style={{ fontSize: '24px', position: 'absolute', left: '-40px', top: '10px' }}></i>
                                                                        </div>


                                                                    </div>) : <></>}


                                                            </div>

                                                        )
                                                    })}

                                                </>) : (<></>)}
                                            </div>
                                        </div>

                                        <hr style={{ textDecoration: 'underline' }} />
                                    </div>


                                </>) : (<></>)}
                                {menu === 'PROJECT SCHEDULE' ? (<>

                                    <div className="box-content-evens2">
                                        <div className="row delpm">
                                            <div className="col-12" style={{ height: '580px', overflowY: 'auto', overflowX: 'hidden' }}>
                                                <div className="pi-infoText" style={{ fontSize: '17.005px', textDecoration: 'underline', position: 'sticky', top: '0px', backgroundColor: '#1E2026', zIndex: '120' }}>Project Schedule
                                                    {userismode === false ? (
                                                        <i class="fa fa-plus-circle btn-plue " style={{ marginLeft: '0px', fontSize: '20px', padding: '2px 1px 6px 10px' }} onClick={() => {
                                                            InputSchedule()
                                                            setAutoResize('adv')
                                                        }}></i>
                                                    ) : <></>}

                                                </div>
                                                <div className="herdSchecBox">
                                                    <div className="row delpm" >
                                                        <div className="w1 schec-label" >
                                                            Sort
                                                        </div>
                                                        <div className="w2 schec-label">
                                                            Task No.
                                                        </div>
                                                        <div className="mr-2 w3 schec-label">
                                                            Task Name
                                                        </div>
                                                        <div className="w2 schec-label">
                                                            Duration
                                                        </div>
                                                        <div className="w2 schec-label">
                                                            Start
                                                        </div>
                                                        <div className="mr-2 w2 schec-label">
                                                            Finish
                                                        </div>
                                                        <div className="w2 schec-label">
                                                            Predecessors
                                                        </div>
                                                    </div>

                                                </div>
                                                {projectSchedule.length > 0 ? (<>
                                                    {projectSchedule.map((item_4, index_4) => {
                                                        return (

                                                            <div className="mt-4 row delpm" style={{ textAlign: 'center' }}>
                                                                <div className="w1" >
                                                                    {index_4 == 0 ? (<></>) : (<>
                                                                        <i class="fa fa-arrow-up" style={{ marginLeft: '0px', fontSize: '12px', padding: '2px 1px 6px 10px', cursor: 'pointer' }} onClick={() => { SortScheduleUP(index_4) }}></i>
                                                                    </>)}
                                                                    {index_4 == (projectSchedule.length - 1) ? (<></>) : (<>    <i class="fa fa-arrow-down" style={{ marginLeft: '0px', fontSize: '12px', padding: '2px 1px 6px 10px', cursor: 'pointer' }} onClick={() => { SortScheduleDow(index_4) }}></i></>)}

                                                                </div>
                                                                <div className="w2" >
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px ${item_4.ps_task_no !== undefined && item_4.ps_task_no !== null && item_4.ps_task_no !== '' ? '#5D9BE1' : 'red'})` }} >

                                                                        {/*  <textarea className="areaTEst "
                                                                            id={textAreaId[`project_schedule_task${index_4}`]}
                                                                            type='text'
                                                                            style={{ marginTop: '1px' }}
                                                                            value={item_4.ps_task_no}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_schedule_task${index_4}`])
                                                                                onChangeSchedule('ps_task_no', e.target.value, index_4)
                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '1px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_schedule_task${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.ps_task_no}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeSchedule('ps_task_no', e.target.value, index_4)
                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="ml-3 mr-2 w3" >
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px ${item_4.ps_task_name !== undefined && item_4.ps_task_name !== null && item_4.ps_task_name !== '' ? '#5D9BE1' : 'red'})` }} >

                                                                        {/*    <textarea className="areaTEst"
                                                                            id={textAreaId[`schedule_id${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.ps_task_name}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`schedule_id${index_4}`])
                                                                                onChangeSchedule('ps_task_name', e.target.value, index_4)

                                                                            }}
                                                                        >
                                                                        </textarea> */}

                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`schedule_id${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.ps_task_name}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeSchedule('ps_task_name', e.target.value, index_4)

                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="ml-2 mr-1 w2" style={{ width: '120px' }} >
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }} >

                                                                        {/* <textarea className="areaTEst rezieznone"
                                                                            id={textAreaId[`project_schedule_durarion${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.duration === undefined || item_4.duration === '' ? isNaN(parseInt(moment(item_4.ps_finish).diff(moment(item_4.ps_start), 'days')) + 1) == true ? 0 : parseInt(moment(item_4.ps_finish).diff(moment(item_4.ps_start), 'days')) + 1 : isNaN(item_4.duration) == true ? 0 : item_4.duration}
                                                                            disabled
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_schedule_durarion${index_4}`])
                                                                                onChangeSchedule('duration', e.target.value, index_4)

                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_schedule_durarion${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.duration === undefined || item_4.duration === '' ? isNaN(parseInt(moment(item_4.ps_finish).diff(moment(item_4.ps_start), 'days')) + 1) == true ? 0 : parseInt(moment(item_4.ps_finish).diff(moment(item_4.ps_start), 'days')) + 1 : isNaN(item_4.duration) == true ? 0 : item_4.duration}
                                                                            disabled
                                                                            onChange={(e) => {

                                                                                onChangeSchedule('duration', e.target.value, index_4)

                                                                            }}
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className="ml-4 mr-4 w2" style={{ width: '130px' }} >
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1)` /* filter: `drop-shadow(0px 0px 3px ${item_4.ps_start !== undefined && item_4.ps_start !== null && item_4.ps_start !== '' ? '#5D9BE1' : 'red'})` */ }} >

                                                                        <StyledDatePicker
                                                                            className="dateText4"
                                                                            value={item_4.ps_start ? dayjs(item_4.ps_start) : null}
                                                                            disabled={userismode}
                                                                            placeholder="วว/ดด/ปปปป"
                                                                            format={"DD/MM/YYYY"}
                                                                            onChange={(date, dateString) => {
                                                                                onChangeSchedule('ps_start', date ? date.$d : null, index_4)
                                                                            }}
                                                                            disabledDate={(endValue) => {
                                                                                if (!item_4.ps_finish) {
                                                                                    return false;
                                                                                }
                                                                                return endValue > (item_4.ps_finish);
                                                                            }}
                                                                        />
                                                                        {/* <input className="dateText"

                                                                            type='date'
                                                                            max={moment(item_4.ps_finish).format('YYYY-MM-DD')}
                                                                            value={moment(item_4.ps_start).format('YYYY-MM-DD')}
                                                                            disabled={userismode}
                                                                            onChange={(e) => { onChangeSchedule('ps_start', new Date(e.target.value), index_4) }}
                                                                        >
                                                                        </input> */}

                                                                    </div>
                                                                </div>
                                                                <div className="mr-4 w2" style={{ width: '130px' }}>
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1)`   /* filter: `drop-shadow(0px 0px 3px ${item_4.ps_finish !== undefined && item_4.ps_finish !== null && item_4.ps_finish !== '' ? '#5D9BE1' : 'red'})` */ }} >

                                                                        <StyledDatePicker
                                                                            className="dateText4"
                                                                            value={item_4.ps_finish ? dayjs(item_4.ps_finish) : null}
                                                                            disabled={userismode}
                                                                            placeholder="วว/ดด/ปปปป"
                                                                            format={"DD/MM/YYYY"}
                                                                            onChange={(date, dateString) => {
                                                                                onChangeSchedule('ps_finish', date ? date.$d : null, index_4)
                                                                            }}
                                                                            disabledDate={(endValue) => {
                                                                                if (!item_4.ps_start) {
                                                                                    return false;
                                                                                }
                                                                                return endValue && endValue.isBefore(item_4.ps_start);
                                                                            }}
                                                                        />
                                                                        {/* <input className="dateText"

                                                                            type='date'
                                                                            min={moment(item_4.ps_start).format('YYYY-MM-DD')}
                                                                            value={moment(item_4.ps_finish).format('YYYY-MM-DD')}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {
                                                                                onChangeSchedule('ps_finish', new Date(e.target.value), index_4)
                                                                            }}
                                                                        >
                                                                        </input> */}

                                                                    </div>
                                                                </div>
                                                                <div className="w2" >
                                                                    <div className="pi-infoBoxTextShadow" style={{ textAlign: '-webkit-center', filter: `drop-shadow(0px 0px 3px #5D9BE1` }} >

                                                                        {/* <textarea className="areaTEst"
                                                                            id={textAreaId[`project_schedule_predecessors${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.ps_predecessors}
                                                                            onChange={(e) => {
                                                                                autoResize(textAreaId[`project_schedule_predecessors${index_4}`])
                                                                                onChangeSchedule('ps_predecessors', e.target.value, index_4)

                                                                            }}
                                                                        >
                                                                        </textarea> */}
                                                                        <NumericFormat
                                                                            className="areaTEst2 rezieznone"
                                                                            style={{ borderRadius: '0px', marginTop: '-1px', height: '-5px' }}
                                                                            id={textAreaId[`project_schedule_predecessors${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.ps_predecessors}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeSchedule('ps_predecessors', e.target.value, index_4)

                                                                            }}


                                                                        />                                              {/*  <TextArea
                                                                            className="areaTEst rezieznone"
                                                                            style={{ borderRadius: '0px' }}
                                                                            autoSize={{
                                                                                minRows: 1
                                                                            }}
                                                                            id={textAreaId[`project_schedule_predecessors${index_4}`]}
                                                                            type='text'
                                                                            value={item_4.ps_predecessors}
                                                                            disabled={userismode}
                                                                            onChange={(e) => {

                                                                                onChangeSchedule('ps_predecessors', e.target.value, index_4)

                                                                            }}
                                                                        /> */}

                                                                    </div>
                                                                </div>
                                                                {userismode === false ? (
                                                                    <div className="w1" style={{ textAlign: '-webkit-center' }}>
                                                                        <div className="btn-remove"
                                                                            onClick={() => {
                                                                                RemoveSchedule(index_4)
                                                                            }}> <i class="fa fa-trash detailIcon" aria-hidden="true" style={{ fontSize: "20px", marginTop: '10px' }}></i>
                                                                        </div>
                                                                    </div>) : <></>}
                                                            </div>

                                                        )
                                                    })}

                                                </>) : (<></>)}

                                            </div>
                                        </div>
                                    </div>


                                </>) : (<></>)}
                                {menu === 'SRS' ? (<>
                                    <TabCompanentSrs
                                        roleOptionData={roleOption}
                                        callBackSrsSave={callBackSrsSave}
                                        isGetBySRS={isGetBySRS}
                                        tempSrsSaveFackData={tempSrsSaveFack}
                                        callbackSrsSpecificField={callbackSrsSpecificField}
                                        srsSpecificField={srsSpecificFieldValue}
                                        userismode={userismode}
                                        project_id={projectSave.project_id}
                                        getMOM={getMOM}
                                        callbackMomSpecificField={callbackMomSpecificField}
                                    />
                                </>) : (<></>)}
                                {menu === 'MOM (MINUTE OF MEETING)' ? (<>
                                    <TabCompanentMOM
                                        project_id={projectSave.project_id}
                                        roleOptionData={roleOption}
                                        isGetBySRS={isGetBySRS}
                                        tempSrsSaveFackData={tempSrsSaveFack}
                                        callbackMomSpecificField={callbackMomSpecificField}
                                        callbackMomDelSpecificField={callbackMomDelSpecificField}
                                        srsSpecificField={srsSpecificFieldValue}
                                        momSpecificField={momSpecificField}
                                        userismode={userismode}
                                        getMOM={getMOM}
                                    />
                                </>) : (<></>)}
                                {menu === 'Test Result' ? (<>
                                    <TabTestResult
                                        isGetBySRS={isGetBySRS}
                                        /*  tempSrsSaveFackData={tempSrsSaveFack} */
                                        callbackSrsSpecificField={callbackSpecificFieldTestResult}
                                        srsSpecificField={srsSpecificFieldTestResultValue}
                                        userismode={userismode}

                                    />
                                </>) : (<></>)}

                                {menu === 'Final Summary' ? (<>
                                    <TabFinalSummary

                                        isGetBySRS={isGetBySRS}
                                        /*   tempSrsSaveFackData={tempSrsSaveFack} */
                                        callbackSrsSpecificField={callbackSpecificFieldFinalSummary}
                                        srsSpecificField={srsSpecificFieldFinalValue}
                                        userismode={userismode}
                                    />
                                </>) : (<></>)}
                                {/* {console.log('มาเเล้ว', menu)} */}
                                {menu == 'UAT-ACCEPTANCE RECORD' ? (<>
                                    {/* {console.log('มาเเล้ว-2', menu)} */}
                                    <TabAcceptance

                                        isGetBySRS={isGetBySRS}
                                        /*   tempSrsSaveFackData={tempSrsSaveFack} */
                                        callbackSrsSpecificField={callbackSpecificFieldAcceptancerecord}
                                        srsSpecificField={srsSpecificFieldAcceptancerecordValue}
                                        userismode={userismode}
                                    />
                                </>) : (<>          {/* {console.log('มาเเล้ว-3', menu)} */}</>)}

                                {menu === 'SDD' ? (<>
                                    <TabSdd

                                        isGetBySRS={isGetBySRS}
                                        /*   tempSrsSaveFackData={tempSrsSaveFack} */
                                        callbackSrsSpecificField={callbackSpecificFieldSDD}
                                        srsSpecificField={srsSpecificFieldSDDValue}
                                        userismode={userismode}
                                    />
                                </>) : (<></>)}


                                {menu === 'Change Request' ? (<>
                                    <TabChange
                                        project_id={projectSave.project_id}
                                        /*   roleOptionData={roleOption}
                                          callBackSrsSave={callBackSrsSave}
                                          isGetBySRS={isGetBySRS} */
                                        /*  tempSrsSaveFackData={tempSrsSaveFack} */
                                        callbackChangeSpecificField={callbackChangeSpecificField}
                                        changeSpecificFieldValue={changeSpecificFieldValue}
                                        userismode={userismode}
                                    />
                                </>) : (<></>)}


                            </div>
                        </div>

                    </div>

                </div>
            </ConfigProvider>
            <ModelAlertConfrim
                isOpen={displaySwal}
                mainFuc={() => saveProject()}
                toggle_alertKick={toggle_modal_confrimModal}
                toggle_confrim={() => toggle_modal_confrimModal()}
            />

            <ModelAlertMassge
                isOpen={alertKickMassge}
                toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
                message={messageBox}
                mainFuc={() => getProjectByID()}
            />

            <PopupSaveversion
                isOpen={PopupSaveVerSummart}
                mainFuc={CallBackSaveVersionport}
            />

        </>
    );
}

export default ModalProject;
