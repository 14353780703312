import React, { useState,useRef, useEffect } from 'react'
import "./SpaceshipConsolCSS.css";
import { Link } from 'react-router-dom'
import SpaceShipLogo from './img/SpaceShip_Logo_2.png';
import SpaceShip from './img/image_output_path.png';
import axios from "axios";
import Configs from "../../config";
import {Row,Col  } from "antd";
import html2canvas from 'html2canvas';
import TooltipPortal from './TooltipPortal';
import Tooltipcopy from './Tooltipcopy';
import LogoFigma from './img/Frame 238657.png'
import logo2 from './img/Group 237750.png'
import logo3 from './img/Group 237754.png'
import logo4 from './img/Group 237769.png'
import logo5 from './img/Group 237770.png'
import logo6 from './img/Group 237817.png'
import logo7 from './img/Group 237959.png'
import logo8 from './img/Group 238545.png'
import logo9 from './img/Group 238658.png'
import logo10 from './img/Group 238620.png'
import logoLeft from './img/Vector.png'
import LogoCap from './img/Vector_cap.png'
import logo11 from './img/Frame 239201 (2).png'
import { PlusCircleFilled } from '@ant-design/icons';
import { Space } from 'antd';
import { LoadingOutlined} from '@ant-design/icons';
import AddElement from "../SpaceshipConsole/components/addElement/index";
import img_banner from '../../assets/img/slider/banner_magazine_thumb01.jpg';
import moment from 'moment';

function Spaceshipconsole(isOpen) {
    const urlParams = new URLSearchParams(window.location.search);
    const spid = urlParams.get('spid');
    const oldPageMode = urlParams.get('mode');
    const fedSpsId = urlParams.get('fedSpsId');
    const spaceship_id = urlParams.get('spaceship_id');
    const onAdd = urlParams.get('onAdd');
    const user_id  = sessionStorage.getItem('user_id')
    const fed_id  = sessionStorage.getItem('fed_id')
    /* console.log('user_id',user_id);
    console.log('spid',spid);
    console.log('mode',oldPageMode);
    console.log('urlParams',urlParams); */ 
    const [test, setTest] = useState({
        test: ''
    });
    const [imgSP,setimgSP] = useState(false);
    const [dataSP,setDataSP] = useState([]);
    const [dataAllElement,setDataAllElement] = useState([]);
    const [dataUseElement,setDataUseElement] = useState([]);
    const [elementName,setElementName] =useState({});
    const [elementDetail,setElementDetail] =useState({});
    const [isLeader, setIsLeader] = useState(false);

    const [addElementIsOpen, setAddElementIsOpen] = useState(false);
    const toggleAddElementIsOpen = () => { setAddElementIsOpen(!addElementIsOpen) };

    const screenshotRef = useRef(null);

    const handleScreenshot = () => {
    const element = screenshotRef.current;
    html2canvas(element, { useCORS: true, scrollY: -window.scrollY }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.href = imgData;
      link.download = 'screenshot.png';
      link.click();
    });
  };

  const [isMouseInside, setIsMouseInside] = useState(false);
/* 
  const [positionMouse, setPositionMouse] = useState({
    mouseX: '',
    mouseY: ''
    });
     */
    const [isTracking, setIsTracking] = useState(false);
    const [isTrackingcopy, setIsTrackingcopy] = useState(false);
    const [positionTooltip, setPositionTooltip] = useState({ top: 0, left: 0 });
    const tooltipRef = useRef(null);
    const stopFunpositionMousecopy = () => {
        setIsTrackingcopy(false);
    };

    const stopFunpositionMouse = () => {
        setIsTracking(false);
    };

    function CallBack(even){
        if(even == true){
            checkIsLeader();
            GetAccesstokenToken();
            getelepaceShip();
        }
    }

    useEffect(() => {
        if (isTracking) {
          const handleMouseMove = (event) => {
            setPositionTooltip({
              top: event.clientY,
              left: event.clientX,
            });
          };
    
          window.addEventListener('mousemove', handleMouseMove);
    
          return () => {
            window.removeEventListener('mousemove', handleMouseMove);
          };
        }
      }, [isTracking]);

      useEffect(() => {
        if (isTrackingcopy) {
          const handleMouseMove = (event) => {
            setPositionTooltip({
              top: event.clientY,
              left: event.clientX,
            });
          };
    
          window.addEventListener('mousemove', handleMouseMove);
    
          return () => {
            window.removeEventListener('mousemove', handleMouseMove);
          };
        }
      }, [isTrackingcopy]);

    async function checkIsLeader() {
        axios({
            method: "GET",
            url: `${Configs.TTT_Game_Service}/api/spaceship/checkIsLeader/${spid}`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            }
          })
            .then(async function (response) {
                if (response.data) {
                    setIsLeader(response.data);
                    setTest({...test, test: ''});
                    test.test = '';
                }
            })
            .catch(function (error) {
              console.log(error);
            });
    }

    async function getelepaceShip() {
        let temp = {
            spaceship_id : spid,
            usr_id : user_id,
            fed_id : fed_id
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/spaceship/dataSpaceShip`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
            data: temp
          })
            .then(async function (response) {
                if(response.data){
                    let temp_el = response.data.element_data || [];
                    let temp_u_el = response.data.element_use || [];
                    setDataAllElement(temp_el);
                    setDataUseElement(temp_u_el)
                    setTest({ ...test, test: '' });
                    test.test = '';
                    if(temp_u_el.length > 0){
                        for (let item of temp_u_el) {
                            if (item.fd_img_id) {
                                const response_el = await axios.get(`https://api.box.com/2.0/files/${item.fd_img_id}/content`, {
                                    headers: {
                                        Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                    },
                                    responseType: 'blob',
                                });
                                item.ul_file_path = URL.createObjectURL(response_el.data);
                            }
                            setDataAllElement(temp_el);
                            setDataUseElement(temp_u_el)
                            setTest({ ...test, test: '' });
                            test.test = '';
                        }
                    }
                 }

            })
            .catch(function (error) {
              console.log(error);
            });
    }

    async function getdataSpaceShip() {
        let temp = {
            spaceship_id : spid,
            usr_id : user_id,
            fed_id : fed_id
        }
        axios({
            method: "post",
            url: `${Configs.TTT_Game_Service}/api/spaceship/dataSpaceShip`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
            data: temp
          })
            .then(async function (response) {
                if(response.data){ 
                    let temp_spimg = response.data.spaceship_img || {};
                    let temp_sp = response.data.spaceship_data || {};
                    setDataSP(temp_sp);
                    setTest({ ...test, test: '' });
                    test.test = '';
                    if (temp_spimg) {
                        const response2 = await axios.get(`https://api.box.com/2.0/files/${temp_spimg.fd_img_id}/content`, {
                                headers: {
                                    Authorization: `Bearer ${sessionStorage.getItem('tokenBox')}`,
                                },
                                 responseType: 'blob',
                                });
                                const dataFile = new FormData();
                                dataFile.append('Profile',response2.data,'Spaceship_Profile.png');
                                axios({
                                    method: "post",
                                    headers: {
                                        Authorization: sessionStorage.getItem('access_token'),
                                        "X-TTT": `${Configs.API_TTT}`,
                                        'Content-Type': 'multipart/form-data'},
                                    /* url: `${Configs.TTT_Game_Ai_Service}/api/RemoveBG/`, */
                                    url: `${Configs.TTT_Game_Service}/api/project/RemoveBG`,
                                    data: dataFile,
                                    responseType: 'blob',
                                }).then(async function (response) {
                                    temp_sp.ul_file_path = URL.createObjectURL(response.data);
                                    setimgSP(true)
                                })                  
                        }else{
                            setimgSP(true)
                            
                        }
                        setDataSP(temp_sp);
                        setTest({ ...test, test: '' });
                        test.test = ''            
                }
            })
            .catch(function (error) {
              console.log(error);
              setimgSP(true)
            });
    }

    async function GetAccesstokenToken(callback) {
        axios({
            method: "post",
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/accesstoken`,
            headers: {
                "X-TTT": `${Configs.API_TTT}`,
                "Content-Type": "application/json",
            },
        })
            .then(async function (response) {

                if (callback) {
                    callback(response.data.tokenBox);
                }
                sessionStorage.setItem('tokenBox', response.data.tokenBox)
            })
            .catch(function (error) {
                console.log(error);
            });

    }

    useEffect(() => {
        checkIsLeader();
        GetAccesstokenToken();
        getdataSpaceShip();
        getelepaceShip();
        
    }, []);

   /*  useEffect(() => {
        if(dataAllElement.length > 0){
            
        }
    }, []); */

  

  return (
    <div className='Area'  ref={screenshotRef}>
      <div className='BoxTopLeft'>
          <div style={{ height:'100%',width:'54% ',transform: 'rotate(-5deg)',marginTop:'10%',marginLeft:'20%'}}>
            <div style={{display: 'flex',justifyContent: 'flex-end'}}>
                <div className='TextHearder'>Planning</div>
            </div>
            <div className='EvenTop'>
                {dataUseElement.filter(item => item.pp_name === 'Planning').length > 7 ? (
                    dataUseElement.filter(item => item.pp_name === 'Planning').map((e, index)=>{
                        return (
                            <div className='Box'
                                style={{
                                    height: '50px',
                                    width: '50px',
                                }}
                                onMouseEnter={() => {
                                    setElementName(e.elm_name);
                                    setElementDetail(e.elm_detail);
                                    setIsTracking(true);
                                }}
                                onMouseLeave={stopFunpositionMouse}
                                
                            > 
                                {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                                {isTracking && (
                                <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                    {elementName}
                                </TooltipPortal>
                                )}
                            </div>
                        ) 
                    })
                    ): Array.from({ length: 7 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Planning')[index];
                     return (
                    <div
                        className='Box'
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || '');
                            setElementDetail(element?.elm_name || '');
                            setIsTracking(true);
                        }}
                        onMouseLeave={stopFunpositionMouse}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div style={{ height: '46px', width: '46px'  }}></div> 
                        )}
                         {isTracking && (
                            <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </TooltipPortal>
                        )}
                    </div>
                    );
                })}
            
            </div> 
          </div>
      </div>
      <div className='BoxCenterLeft'style={{zIndex:'6'}}>
          <div style={{display:'flex',marginLeft:'5%',marginTop:'2%',height:'100%',width:'100%',transform: 'rotate(-5deg)'}}>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder' >Requirement</div>
                <div className='EvenCenter' style={{zIndex: '10'}}> 
                {dataUseElement.filter(item => item.pp_name === 'Requirement').length > 10 ? (
                    dataUseElement.filter(item => item.pp_name === 'Requirement').map((e, index)=>{
                        return (
                            <div className='Box'
                                style={{
                                    height: '50px',
                                    width: '50px',
                                }}
                                onMouseEnter={() => {
                                    setElementName(e.elm_name);
                                    setElementDetail(e.elm_detail);
                                    setIsTracking(true);
                                }}
                                onMouseLeave={stopFunpositionMouse}
                                
                            > 
                                {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                                {isTracking && (
                                <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                    {elementName}
                                </TooltipPortal>
                                )}
                            </div>
                        ) 
                    })
                    ) : Array.from({ length: 10 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Requirement')[index];
                     return (
                    <div
                        className='Box'
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || '');
                            setElementDetail(element?.elm_name || '');
                            setIsTracking(true);
                        }}
                        onMouseLeave={stopFunpositionMouse}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div  onMouseEnter={stopFunpositionMouse}  onMouseLeave={stopFunpositionMouse} style={{ height: '46px', width: '46px'  }}></div> 
                        )}
                        {isTracking && (
                            <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </TooltipPortal>
                        )}
                    </div>
                    );
                })}
            </div>
            </div>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder'>Analysis and Design</div>
                <div className='EvenCenter'> 
                {dataUseElement.filter(item => item.pp_name === 'Analyst and Design').length > 10 ? (
                    dataUseElement.filter(item => item.pp_name === 'Analyst and Design').map((e, index)=>{
                        return (
                            <div className='Box'
                                style={{
                                    height: '50px',
                                    width: '50px',
                                }}
                                onMouseEnter={() => {
                                    setElementName(e.elm_name);
                                    setElementDetail(e.elm_detail);
                                    setIsTracking(true);
                                }}
                                onMouseLeave={stopFunpositionMouse}
                            > 
                                {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                                {isTracking && (
                                <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                    {elementName}
                                </TooltipPortal>
                                )}
                            </div>
                        ) 
                    })
                    ) : Array.from({ length: 10 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Analyst and Design')[index];
                     return (
                    <div
                        className='Box'
                        key={index} 
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || null);
                            setElementDetail(element?.elm_name || null);
                            setIsTracking(true);
                        }}
                        onMouseLeave={stopFunpositionMouse}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div onMouseEnter={stopFunpositionMouse}  onMouseLeave={stopFunpositionMouse}
                        style={{ height: '46px', width: '46px'  }}></div>
                        )}
                        {isTracking &&  (
                            <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </TooltipPortal>
                        )}
                    </div>
                    );
                })}
                </div>
            </div>
          </div>
      </div>
      <div className='BoxBottomLeft'>
        <div style={{marginLeft:'35%',marginTop:'5%',height:'90%',width:'100%',transform: 'rotate(-5deg)',zIndex:'3'}}>
            <div  style={{width:'100%',height:'100%',display: 'flex',flexDirection: 'column',gap:'5%',zIndex:'3'}}>
                <div className='TextHearder'>Coding and Unit Test</div>
                <div className='EvenBottom'> 
                {dataUseElement.filter(item => item.pp_name === 'Coding and Unit Test').length > 8 ? (
                    dataUseElement.filter(item => item.pp_name === 'Coding and Unit Test').map((e, index)=>{
                        return (
                            <div className='Box'
                                style={{
                                    height: '50px',
                                    width: '50px',
                                }}
                                onMouseEnter={() => {
                                    setElementName(e.elm_name);
                                    setElementDetail(e.elm_detail);
                                    setIsTracking(true);
                                }}
                                onMouseLeave={stopFunpositionMouse}
                            > 
                                {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                                {isTracking && (
                                <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                    {elementName}
                                </TooltipPortal>
                                )}
                            </div>
                        ) 
                    })
                    ) : Array.from({ length: 8 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Coding and Unit Test')[index];
                     return (
                    <div
                        className='Box'
                        key={index}
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || '');
                            setElementDetail(element?.elm_detail || '');
                            setIsTracking(true);
                        }}
                        onMouseLeave={stopFunpositionMouse}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div onMouseEnter={stopFunpositionMouse}  onMouseLeave={stopFunpositionMouse} style={{ height: '46px', width: '46px'  }}></div>
                        )}
                        {isTracking && (
                            <TooltipPortal elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </TooltipPortal>
                        )}
                    </div>
                    );
                })}
                </div>
            </div>
        </div>
      </div>
      <div className='BoxTopCenter'>
        <div style={{background: '#131313'/* ,background: 'linear-gradient(90deg, rgba(19,19,19,1) 0%, rgba(35,35,35,1) 100%)' */,
            width:'100%',height:'44.5%',display: 'flex',justifyContent:'space-around',borderBottom:' 3px solid #0047FF'}}>
            <img style={{width:'40%',height:'70%',marginTop:'3%'}} src={SpaceShipLogo}></img>
        </div>
        <div style={{width:'100%',height:'55.5%',display: 'flex',justifyContent:'space-around',alignItems: 'center'}}>
            <div style={{width:'50%',height:'75%',border:' 2px solid #002B99',backgroundColor:' rgba(32,32,32)',backgroundColor:' rgba(255,255,255, 0.1)',borderRadius:'10px',display:'flex'}}>
                <div onClick={()=>{window.location.href = '/StageMonitoring'}} /* to={`/StageMonitoring?key=${oldPageMode}${oldPageMode == 'fedSpaceShip' ? `&fedSpsId=${fedSpsId}` : ''}${oldPageMode == 'all' && spaceship_id != '' ? `&spaceship_id=${spaceship_id}` : ''}`} */ className='evenTopCenter'>
                    <div style={{height:'52%',width: '34%',borderRadius: '5px',border:'1px solid #ff0000',backgroundColor:'#920000',display: 'flex',justifyContent:'space-around',alignItems: 'center',cursor:' pointer'}}>
                        <img style={{width:'65%',color:'#fff'}} src={logoLeft}></img>
                    </div>
                    <div  style={{color:'#fff'}}    >
                        BACK TO SPACE
                    </div>
                </div>
                {isLeader && <div className='evenTopCenter'>
                    <div style={{height:'52%',width: '34%',border:'2px dashed #fff',borderRadius: '5px',backgroundColor:'rgba(255,255,255,0.1)',display: 'flex',justifyContent:'space-around',alignItems: 'center',cursor:' pointer'}}
                        onClick={() => {
                        toggleAddElementIsOpen();
                      }}>
                        <PlusCircleFilled   style={{fontSize: '25px',color:'#fff'}}/>
                    </div>
                    <div style={{color:'#fff'}}>ADD ELEMENT</div>
                </div>}
            </div>
        </div>
      </div>
      <div className='BoxCenter'>
        <div style={{width:'100%',height:'100%',display: 'flex',justifyContent:'space-around',alignItems: 'center'}}>
            {imgSP ? (
                dataSP.ul_file_path ? <img style={{width:'25%',height:'60%',position: 'absolute',zIndex:'-1',filter:'drop-shadow(1px 1px 20px #000)',animation:'bounce 4s infinite'}} src={dataSP.ul_file_path}></img>  :
                <div style={{fontSize: '25px',color:'#fff'}}>NO MODEL</div>
            ) : (
                <div style={{display:'flex'}}>
                <div class="spinner"></div>
                <div style={{padding:'15px 15px',color:'#fff'}}>Loading image...</div>
                </div>
                
            )}
           
        </div>
      </div>
      <div className='BoxBottomCenter'>
        <div style={{height:'50%',width:'50%',display:'flex',justifyContent:'space-around',alignItems: 'center'}}>
            <div style={{display:'flex',justifyContent:'space-around',alignItems: 'center',flexDirection: 'column',rowGap:'7px'}}>
                <div className='evenNumBottomCerter' style={{color:'yellow'}} >{dataSP.spaceship_rank && dataSP.spaceship_rank || '0'}</div>
                <div style={{color:'#fff'}}>RANK</div>
            </div>
            <div style={{display:'flex',justifyContent:'space-around',alignItems: 'center',flexDirection: 'column',rowGap:'7px'}}>
                <div className='evenNumBottomCerter' style={{color:'purple'}} >{dataAllElement.length || '0'}</div>
                <div style={{color:'#fff'}}>ELEMENT</div>
            </div>
            <div style={{display:'flex',justifyContent:'space-around',alignItems: 'center',flexDirection: 'column',rowGap:'7px'}}>
                <div className='evenNumBottomCerter' style={{color:'red'}} >{dataSP.spaceship_member && dataSP.spaceship_member || '0'}</div>
                <div style={{color:'#fff'}}>SEATS</div>
            </div>
        </div>
        <div style={{height:'6%',width:'42%',border: '3px solid #0047FF',background: 'rgb(0, 43, 153)',}}></div>
        <div style={{height:'40%',width:'100%',borderTop: '3px solid #0047FF',display:'flex',alignItems: 'center',flexDirection: 'column',rowGap:'7%' }}>
          <div style={{
            background: 'linear-gradient(180deg, rgba(0, 71, 255, 1) 10%, rgba(0, 43, 153, 1) 80%)',
            borderRadius:' 40px',
            width: '25%',
            display:'flex',
            alignItems: 'center',
            justifyContent:'space-around'
            ,marginTop:'2%',
            color: '#fff'
          }}>SPACESHIP NAME</div>
          <div style={{
                        fontSize:'30px',
                        width:'50%',
                        height:' 30%',
                        display:'flex',
                        alignItems: 'center',
                        justifyContent:'space-around',
                        color:'#fff',
                       }}
            >{dataSP.spaceship_name || ''}</div>
          <div style={{
            background: 'linear-gradient(180deg, rgba(0, 71, 255, 1) 10%, rgba(0, 43, 153, 1) 80%)',
            width:'50%',
            display:'flex',
            alignItems: 'center',
            justifyContent:'space-around',
            marginTop: '2%',
            border: '2px solid #002B99',
             color: '#fff',
            clipPath:'polygon(0 100%, 10% 0%, 90% 0, 100% 100%)'}}
          >EXPIRE DATE : { dataSP.spaceship_expire_date ? moment(dataSP.spaceship_expire_date).format('DD MMMM yyyy') : '-- -- ----' }</div>
        </div>
      </div>
      <div className='BoxTopRight'>
            <div style={{  height:'100%',width:'54% ',transform: 'rotate(5deg)',marginTop:'10%',marginLeft:'20%'}}>
                <div className='TextHearder'>Testing</div>
                <div className='EvenTop'>
                {dataUseElement.filter(item => item.pp_name === 'Testing').length > 7 ?(
                    dataUseElement.filter(item => item.pp_name === 'Testing').map((e, index)=>{
                        return (
                            <div className='Box'
                                style={{
                                    height: '50px',
                                    width: '50px',
                                }}
                                onMouseEnter={() => {
                                    setElementName(e.elm_name);
                                    setElementDetail(e.elm_detail);
                                    setIsTrackingcopy(true);
                                }}
                                onMouseLeave={stopFunpositionMousecopy}
                            > 
                                {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                                {isTrackingcopy && (
                                <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                    {elementName}
                                </Tooltipcopy>
                                )}
                            </div>
                        ) 
                    })
                ): Array.from({ length: 7 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Testing')[index];
                     return (
                    <div
                        className='Box'
                        key={index}
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || '');
                            setElementDetail(element?.elm_detail || '');
                            setIsTrackingcopy(true);
                        }}
                        onMouseLeave={stopFunpositionMousecopy}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div onMouseEnter={stopFunpositionMousecopy}  onMouseLeave={stopFunpositionMousecopy} style={{ height: '46px', width: '46px'  }}></div>
                        )}
                        {isTrackingcopy && (
                            <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </Tooltipcopy>
                        )}
                    </div>
                    );
                })}
            
            </div> 
            </div>
      </div>
      <div className='BoxCenterRight'>
        <div style={{display:'flex',marginLeft:'10%',marginTop: '2%',transform: 'rotate(5deg)',height:'100%',width:'100%'}}>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder' >Installation</div>
                <div className='EvenCenter'> 
                {dataUseElement.filter(item => item.pp_name === 'Installation').length > 10 ? (
                    dataUseElement.filter(item => item.pp_name === 'Installation').map((e, index)=>{
                        return (
                            <div className='Box'
                                style={{
                                    height: '50px',
                                    width: '50px',
                                }}
                                onMouseEnter={() => {
                                    setElementName(e.elm_name);
                                    setElementDetail(e.elm_detail);
                                    setIsTrackingcopy(true);
                                }}
                                onMouseLeave={stopFunpositionMousecopy}
                            > 
                                {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                                {isTrackingcopy && (
                                <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                    {elementName}
                                </Tooltipcopy>
                                )}
                            </div>
                        ) 
                    })
                    ) : Array.from({ length: 10 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Installation')[index];
                    return (
                    <div
                        className='Box'
                        key={index}
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || '');
                            setElementDetail(element?.elm_detail || '');
                            setIsTrackingcopy(true);
                        }}
                        onMouseLeave={stopFunpositionMousecopy}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div onMouseEnter={stopFunpositionMousecopy}  onMouseLeave={stopFunpositionMousecopy} style={{ height: '46px', width: '46px'  }}></div>
                        )}
                        {isTrackingcopy && (
                            <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </Tooltipcopy>
                        )}
                    </div>
                    );
                })}
                </div>
            </div>
            <div style={{width:'40%',height:'100%',display: 'flex',flexDirection: 'column',alignItems: 'center'}}>
                <div className='TextHearder'>Trainning</div>
                <div className='EvenCenter'> 
                {dataUseElement.filter(item => item.pp_name === 'Trainning').length > 10 ? (
                    dataUseElement.filter(item => item.pp_name === 'Trainning').map((e, index)=>{
                        return (
                            <div className='Box'
                            style={{
                                height: '50px',
                                width: '50px',
                            }}
                            onMouseEnter={() => {
                                setElementName(e.elm_name);
                                setElementDetail(e.elm_detail);
                                setIsTrackingcopy(true);
                            }}
                            onMouseLeave={stopFunpositionMousecopy}
                        > 
                            {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                            {isTrackingcopy && (
                            <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </Tooltipcopy>
                            )}
                        </div>
                        ) 
                    })
                    ) : Array.from({ length: 8 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Trainning')[index];
                    return (
                    <div
                        className='Box'
                        key={index}
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || '');
                            setElementDetail(element?.elm_detail || '');
                            setIsTrackingcopy(true);
                        }}
                        onMouseLeave={stopFunpositionMousecopy}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div onMouseEnter={stopFunpositionMousecopy}  onMouseLeave={stopFunpositionMousecopy} style={{ height: '46px', width: '46px'  }}></div>
                        )}
                        {isTrackingcopy && (
                            <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </Tooltipcopy>
                        )}
                    </div>
                    );
                })}
                </div>
            </div>
        </div>
      </div>
      <div className='BoxBottomRight'>
      <div style={{marginLeft:'26%',marginTop:'11%',height:'90%',width:'100%',transform: 'rotate(5deg)'}}>
            <div  style={{width:'100%',height:'100%',display: 'flex',flexDirection: 'column',gap:'5%'}}>
                <div className='TextHearder'>Extra</div>
                <div className='EvenBottom'> 
                {dataUseElement.filter(item => item.pp_name === 'Extra').length > 8 ? (
                    dataUseElement.filter(item => item.pp_name === 'Extra').map((e, index)=>{
                        return (
                            <div className='Box'
                                style={{
                                    height: '50px',
                                    width: '50px',
                                }}
                                onMouseEnter={() => {
                                    setElementName(e.elm_name);
                                    setElementDetail(e.elm_detail);
                                    setIsTrackingcopy(true);
                                }}
                                onMouseLeave={stopFunpositionMousecopy}
                            > 
                                {e.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={e.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>}
                                {isTrackingcopy && (
                                <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                    {elementName}
                                </Tooltipcopy>
                                )}
                            </div>
                        ) 
                    })
                    ) : Array.from({ length: 8 }).map((item, index) => {
                    const element = dataUseElement.filter(item => item.pp_name === 'Extra')[index];
                     return (
                    <div
                        className='Box'
                        key={index}
                        style={{
                        height: '50px',
                        width: '50px',
                        }}
                        onMouseEnter={() => {
                            setElementName(element?.elm_name || '');
                            setElementDetail(element?.elm_detail || '');
                            setIsTrackingcopy(true);
                        }}
                        onMouseLeave={stopFunpositionMousecopy}
                    >
                        {element ? (
                            element.ul_file_path ? <img style={{ height: '46px', width: '46px', padding: '5px' }} src={element.ul_file_path}/> : <div style={{height: '46px',width: '46px'}}></div>
                        ) : (
                        <div onMouseEnter={stopFunpositionMousecopy}  onMouseLeave={stopFunpositionMousecopy} style={{ height: '46px', width: '46px'  }}></div>
                        )}
                        {isTrackingcopy && (
                            <Tooltipcopy elementDetail={elementDetail} positionTooltip={positionTooltip}>
                                {elementName}
                            </Tooltipcopy>
                        )}
                    </div>
                    );
                })}
                </div>
            </div>
        </div>
      </div>
        <div className='Boxcap' onClick={ handleScreenshot } >
            <img style={{width:'50%',height:'50%'}} src={LogoCap}></img>
        </div>
        <div className='boxTopLeft'></div>
        <div className='LinerTopLeft'></div>
        <div className='BottomboxTopLeft'></div>
        <div className='leftbuttomboxTopLeft'></div>
        <div className='lefttopboxTopLeft'></div>
        <div className='rightbuttomboxTopLeft'></div>
        <div className='righttopboxTopLeft'></div>
        <div className='boxTopRight'></div>
        <div className='LinerTopRight'></div>
        <div className='BottomboxTopRight'></div>
        <div className='leftbuttomboxTopRight'></div>
        <div className='lefttopboxTopRight'></div>
        <div className='rightbuttomboxTopRight'></div>
        <div className='righttopboxTopRight'></div>
        <div className='LinerCenterBottomLeft'></div>
        <div className='LinerCenterBottomRight'></div>    
        <div className='LinerCenterLeft'></div>
        <div className='LinerCenterRight'></div>
        <div className='LinerBottomLeft'></div>
        <div className='LinerBottomRight'></div>
        <div className='LinerBottomleftBottom'>
            <div className='LinerBottomleftBottom0_1'></div>
            <div className='LinerBottomleftBottom0_2'></div>
            <div className='LinerBottomleftBottom0_3'></div>
            <div className='LinerBottomleftBottom0_4'></div>
            <div className='LinerBottomleftBottom0_5'></div>
            <div className='LinerBottomleftBottom0_6'></div>
            <div className='LinerBottomleftBottom0_7'></div>
        </div>
        <div className='LinerBottomRightBottom'>
            <div className='LinerBottomleftBottom0_1'></div>
            <div className='LinerBottomleftBottom0_2'></div>
            <div className='LinerBottomleftBottom0_3'></div>
            <div className='LinerBottomleftBottom0_4'></div>
            <div className='LinerBottomleftBottom0_5'></div>
            <div className='LinerBottomleftBottom0_6'></div>
            <div className='LinerBottomleftBottom0_7'></div>
        </div>
        <div className='backgLeft'></div>
        <div className='backgRight'></div>
        <div className='bgBottom'></div>
        <div className='bgBottom2'></div>
        <div className='bgCenterLeft'></div>
        <div className='bgCenterRight'></div>
        <div className='bgCenterBottom'></div>
        <AddElement
        callback={CallBack}
        isOpen={onAdd || addElementIsOpen}
        toggleIsOpen={toggleAddElementIsOpen}
        />
    </div>
  ) 
}

export default Spaceshipconsole;