

import jsPDF from "jspdf";
import { Get_font_pdf_th1, Get_font_pdf_th2 } from "../../../assets/fonts/pdf_font";
import moment from "moment";
import 'moment/locale/th';
import Configs from "../../../config";
import iconMember from "../../../assets/img/report/Icon Spaceship Member_V2 (1).png";
import noimg from "../../../assets/img/report/Report_Header_NoImage.png"
import axios from "axios";
import html2canvas from "html2canvas";
import { logDOM } from "@testing-library/react";
import clipPaperIcon from './img/clip paper.png';
import { after, before } from "lodash";
import { v4 as uuidv4 } from 'uuid';
/* import QRCode from "qrcode"; */

async function timesheertreport (data, id=null, mode, callback) {
    moment.locale('th');
    const compressImage = (imageUrl,maxWidth = 1920 ,maxHeight = 1080) => {
        /*  const mmToPx = (mm) => (mm * 96) / 25.4; */
 
         return new Promise((resolve, reject) => {
             const img = new Image();
             img.crossOrigin = 'Anonymous'; 
             img.src = imageUrl;
             img.onload = () => {
                 const canvas = document.createElement('canvas');
                 const ctx = canvas.getContext('2d');
 
                 let wpx = img.width;
                 let hpx = img.height;
                 const mwpx = /* mmToPx(maxWidth) */maxWidth;
                 const mhpx = /* mmToPx(maxHeight) */maxHeight;
                /*  console.log('w',mwpx,wpx);
                 console.log('h',mhpx,hpx); */
 
     if (wpx > hpx) {
         if (wpx > mwpx) {
             hpx = Math.round((hpx * mwpx) / wpx);
             wpx = mwpx;
         }
     } else {
         if (hpx > mhpx) {
             wpx = Math.round((wpx * mhpx) / hpx);
             hpx = mhpx;
         }
     }
 
     canvas.width = wpx;
     canvas.height = hpx;
     ctx.fillStyle = '#FFFFFF';
     ctx.fillRect(0, 0, wpx, hpx);
     ctx.drawImage(img, 0, 0, wpx, hpx);
 
     resolve(canvas.toDataURL('image/jpeg', 0.8)); 
 
                
             };
 
             img.onerror = () => {
                 reject(new Error('Failed to load image.'));
             };
         });
     };
 
    let imghead = noimg
 
    const doc = new jsPDF("p", "mm", "a4");

    doc.addFileToVFS("THSarabunNew.ttf", Get_font_pdf_th2());
    doc.addFont("THSarabunNew.ttf", "THSarabunNew", "bold");
    doc.setFont("THSarabunNew", 'bold');
    ///////////กำหนดตั้งค่าหน้าแนวตั้ง
    let padding = 15////เว้นขอบข้าง
        , wpage = 210///กว้าง
        , hpage = 297///สูง
        , page = 1
        , r = 222
        , g = 222
        , b = 222
        , endpage = 260,
        his = [],
        img = {}
    let heightpage = 34

    if (data.img.length > 0) {
        img = data.img[0]
    }
  
     /* console.log(data.project); */
     let colordrop = [
        {
            before:'#676767',
            after:'#DEDEDE'
        },
        {
            before:'#FF00E5',
            after:'#FBDCFF'
        },
        {
            before:'#BA84FF',
            after:'#CCD9FA'
        },
        {
            before:'#00A5FF',
            after:'#CDEEFF'
        },
        {
            before:'#259B24',
            after:'#D3FFCF'
        },
        {
            before:'#FFEB3B',
            after:'#FFFDCF'
        },
        {
            before:'#FF8000',
            after:'#FFE0C1'
        },
        {
            before:'#FF0000',
            after:'#FFD9D9'
        },
     ]
    if (data.project.federation_color !== null) {
        let selectcolor = colordrop.findIndex((e) => e.before == data.project.federation_color || e.after == data.project.federation_color)
        console.log(selectcolor);
        if (selectcolor !== -1) {
            let hex = colordrop[selectcolor].after.replace(/^#/, '');
            let bigint = parseInt(hex, 16);
    
            r = (bigint >> 16) & 255;
            g = (bigint >> 8) & 255;
            b = bigint & 255;
        }
    
    }
  

    heightpage+=7

    Shot('fs', 20) 
    Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายงานสรุปผลการทำงาน`), 'c')
    Shot('t', CutPadding('w', 50), heightpage, TexttoString(`รายงานสรุปผลการทำงาน`), 'c')


    heightpage+=14
    Shot('fs', 16) 

    Shot('t', CutPadding('w', 100), heightpage, TexttoString(`ประจำวันที่ ${  moment(data.date.s).diff(moment(data.date.e), 'days') !== 0 ? `${moment(data.date.s).add('years', 543).format('DD MMMM yyyy')} – ${moment(data.date.e).add('years', 543).format('DD MMMM yyyy')}` : `${moment(data.date.s).add('years', 543).format('DD MMMM yyyy')}`}`), 'r')
    heightpage+=7
    Shot('t', CutPadding('w', 0), heightpage, TexttoString(`โดย`), 'l')
    Shot('t', CutPadding('w', 8), heightpage, TexttoString(`คุณ ${data.user.namef} ${data.user.namel}`), 'l')
    heightpage+=7
    Shot('t', CutPadding('w', 0), heightpage, TexttoString(`เรียน`), 'l')
    Shot('t', CutPadding('w', 8), heightpage, TexttoString(`${'-'}`), 'l')
    heightpage+=14
let alldaycount=[]
    for (let i of data.data) {
        let check = alldaycount.filter((e)=>{return e.date === moment(i.ts_date).add('years', 543).format('DD/MM/yyyy')})
        if (check.length === 0) {
            alldaycount.push({
                date: moment(i.ts_date).add('years', 543).format('DD/MM/yyyy')
            })
        }
    }
    console.log('alldaycount',alldaycount,data.data);
    
    let countdaytime = calculateTime(parseFloat(data.count.time),parseFloat(data.count.hours));
    console.log(countdaytime);
    let namecut = doc.splitTextToSize(`        ข้าพเจ้าได้ใช้ทักษะตามหน้างานที่ได้รับมอบหมายตามส่วนงานที่รับผิดชอบ ซึ่งได้จัดทำเอกสารรายละเอียดการสรุป ผลการทำงาน โดยมีชั่วโมงการทำงานทั้งสิ้น ${alldaycount.length} วัน ${isNaN(countdaytime.sumh) === true ? 0 : countdaytime.sumh} ชั่วโมง ${isNaN(countdaytime.sumt) === true ? 0 : countdaytime.sumt} นาที โครงการทั้งหมดที่ข้าพเจ้าได้ดำเนินการมีราย ละเอียดดังนี้`, CutPadding('w', 100, - padding));
console.log(namecut);

for (let nc of namecut) {
    
    Shot('t', CutPadding('w', 0),heightpage, TexttoString(nc), 'l');    
    heightpage+=7
}

let countdataonproj = 0,countlistproj = 0,befor = 0,countprojin = 0,dataonproj = [],isdefor= 0
for (let i of data.data) {


    if (countdataonproj === 0) {
        if (countlistproj > 0 ) {
            Shot("add");
            page += 1
            heightpage = 38
        }
        countprojin= 0
        dataonproj = data.data.filter((e) => {
            if (e.ts_project_id !== null) {
              return  e.ts_project_id == i.ts_project_id
            } else {
                return  e.ts_project_name == i.ts_project_name
            }
          })
        console.log(dataonproj);
        countdataonproj = dataonproj.length;
        let tall = 0 , hall = 0,daycount=[]

        for (let ic of dataonproj) { 
            let check = daycount.filter((e)=>{return moment(e.date).add('years', 543).format('dd/mm/yyyy') === moment(ic.ts_date).add('years', 543).format('dd/mm/yyyy')})
            if (check.length === 0) {
                daycount.push({
                    date:ic.ts_date
                })
            }
            tall+= parseFloat(ic.ts_time)
            hall+= parseFloat(ic.ts_hours)
        }
      
      
        countdaytime = calculateTime(parseFloat(tall),parseFloat(hall));
        console.log(hall,tall,countdaytime);
        let namecut = doc.splitTextToSize(`${countlistproj+1}. โครงการ ${i.ts_project_name || 'อื่นๆ'} ระยะเวลาที่ดำเนินการ ดังนี้ :   ${daycount.length} วัน ${isNaN(countdaytime.sumh) === true ? 0 : countdaytime.sumh} ชั่วโมง ${isNaN(countdaytime.sumt) === true ? 0 : countdaytime.sumt} นาที`, CutPadding('w', 100, - (padding+4)));
        if ((namecut.length * 7) + heightpage > endpage-7) {
            heightpage+=namecut.length * 7
            newpage(endpage-7)
        }
        Shot('fc', r, g, b);
        Shot('dc', 0, 0, 0);
        Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding), namecut.length * 7, 'fd')
        for (let nc of namecut) {
    
            Shot('t', CutPadding('w', 0,2),heightpage+5, TexttoString(nc), 'l');    
            heightpage+=7
        }
         namecut = doc.splitTextToSize(`${i.project_description || ''}`, CutPadding('w', 70, - (padding+4)));
         if ((namecut.length * 7) + heightpage > endpage-7) {
            heightpage+=namecut.length * 7
            newpage(endpage-7)
        }
        Shot('fc', r, g, b);
        Shot('dc', 0, 0, 0);
        Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 25, -padding), namecut.length * 7, 'fd')
        Shot('r', CutPadding('w', 25), heightpage, CutPadding('w', 75, -padding), namecut.length * 7)
        Shot('t', CutPadding('w', 0,2),heightpage+5, TexttoString('รายละเอียดโครงการ'), 'l'); 
        for (let nc of namecut) {
    
            Shot('t', CutPadding('w', 25,2),heightpage+5, TexttoString(nc), 'l');    
            heightpage+=7
        }
        countlistproj+=1
       /*  heightpage+=7 */
    }
   if (countdataonproj > 0) {
    if (24 + heightpage > endpage-7) {
        heightpage+= 24
        newpage(endpage-7)
    }
    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 100, -padding),  7, 'fd')
    Shot('t', CutPadding('w', 0,2),heightpage+5, TexttoString(`ส่วนที่ดำเนินการ : ${countprojin+1}`), 'l'); 
    heightpage+= 7

    namecut = doc.splitTextToSize(`${i.module_name || '-'}`, CutPadding('w', 20, - (padding+4)));
   let namecutsub = doc.splitTextToSize(`${i.menu_name || '-'}`, CutPadding('w', 30, - (padding+4)));
   let countsumname = namecut.length
   if (countsumname < namecutsub.length) {
    countsumname = namecutsub.length
   }

   
    if (countsumname * 7 + heightpage > endpage-7) {
        heightpage+= countsumname * 7
        newpage(endpage-7)
    }
    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 25, -padding), countsumname * 7, 'fd')
    Shot('t', CutPadding('w', 0,2),heightpage+5, TexttoString('Module'), 'l'); 
    Shot('r', CutPadding('w', 25), heightpage, CutPadding('w', 20, -padding), countsumname * 7)
 /*    Shot('t', CutPadding('w', 25,2),heightpage+5, TexttoString(i.module_name || '-'), 'l');  */
    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('r', CutPadding('w', 45), heightpage, CutPadding('w', 25, -padding), countsumname * 7, 'fd')
    Shot('t', CutPadding('w', 45,2),heightpage+5, TexttoString('Menu'), 'l'); 
    Shot('r', CutPadding('w', 70), heightpage, CutPadding('w', 30, -padding), countsumname * 7)
   /*  Shot('t', CutPadding('w', 70,2),heightpage+5, TexttoString(i.menu_name || '-'), 'l'); */ 
    for (let i = 0; i < countsumname; i += 1) {
        if (i <= namecut.length - 1) {
            Shot('t', CutPadding('w', 25,2),heightpage+5, TexttoString(namecut[i]), 'l'); 
        }
        if (i <= namecutsub.length - 1) {
            Shot('t', CutPadding('w', 70,2),heightpage+5, TexttoString(namecutsub[i]), 'l'); 
        }
        heightpage+=7
    }


  if (14 + heightpage > endpage-7) {
    heightpage+= 14
    newpage(endpage-7)
}

Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('r', CutPadding('w', 0), heightpage, CutPadding('w', 25, -padding), 14, 'fd')
    Shot('t', CutPadding('w', 0,2),heightpage+5, TexttoString('Process'), 'l'); 
    Shot('r', CutPadding('w', 25), heightpage, CutPadding('w', 20, -padding), 14)
    Shot('t', CutPadding('w', 25,2),heightpage+5, TexttoString(i.process_name || '-'), 'l'); 
    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('r', CutPadding('w', 45), heightpage, CutPadding('w', 25, -padding), 14, 'fd')
    Shot('t', CutPadding('w', 45,2),heightpage+5, TexttoString('จำนวนระยะเวลาดำเนินการ'), 'l'); 
    Shot('r', CutPadding('w', 70), heightpage, CutPadding('w', 30, -padding), 14)
    Shot('t', CutPadding('w', 70,2),heightpage+5, TexttoString(moment(i.ts_date).add('years', 543).format('DD/MM/yyyy')), 'l'); 
    Shot('t', CutPadding('w', 70,2),heightpage+12, TexttoString(`(${i.ts_hours.toString()} ชั่วโมง ${i.ts_time.toString()} นาที)`), 'l'); 
    heightpage+= 14

  /*   Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0); */
    if (14 + heightpage > endpage-7) {
        heightpage+= 14
        newpage(endpage-7)
    }
    

    namecut = doc.splitTextToSize(`${i.ts_project_detail || ''}`, CutPadding('w', 75, -(padding+4)));
    befor =heightpage
    isdefor = namecut.length
    for (let c of namecut) {
        if (7 + heightpage > endpage-7) {
            Shot('fc', r, g, b);
            Shot('dc', 0, 0, 0);
            Shot('r', CutPadding('w', 0), befor, CutPadding('w', 25, -padding), heightpage-befor, 'fd')
            Shot('t', CutPadding('w', 0,2),befor+6, TexttoString('รายละเอียดการดำเนินการ '), 'l'); 
            Shot('r', CutPadding('w', 25), befor, CutPadding('w', 75, -padding), heightpage-befor)
            heightpage+= 7
            newpage(endpage-7)
            befor =heightpage
            
        }
        Shot('t', CutPadding('w', 25,2),heightpage+6, TexttoString(c), 'l'); 
        heightpage+= 7
        
    }
    heightpage+=2
    Shot('fc', r, g, b);
    Shot('dc', 0, 0, 0);
    Shot('r', CutPadding('w', 0), befor, CutPadding('w', 25, -padding), heightpage-befor, 'fd')
    Shot('t', CutPadding('w', 0,2),befor+6, TexttoString('รายละเอียดการดำเนินการ '), 'l'); 
    Shot('r', CutPadding('w', 25), befor, CutPadding('w', 75, -padding), heightpage-befor)
/*     heightpage+= 14 */
   }
   
   countprojin+=1
   countdataonproj-=1
}
heightpage+=7
if (32 + heightpage > endpage-7) {
    heightpage+= 32
    newpage(endpage-7)
}

Shot('t', CutPadding('w', 80,2),heightpage+7, TexttoString('จึงเรียนมาเพื่อทราบ '), 'c'); 
Shot('t', CutPadding('w', 80,2),heightpage+15, TexttoString('ลงชื่อ...........................................ผู้จัดทำ'), 'c'); 
Shot('t', CutPadding('w', 80,2),heightpage+23, TexttoString('(                                  )'), 'c'); 
Shot('t', CutPadding('w', 80,2),heightpage+32, TexttoString(`ตำแหน่ง ${data.user.role}`), 'c'); 
    const mmToPx = (mm) => (mm * 96) / 25.4;
    if (data.img.length > 0) {
        img = data.img[0]
        imghead = /* await compressImage(Configs.TTT_Game_Service_IMG + img.ul_file_path ,mmToPx(wpage + 1), mmToPx(27)) */Configs.TTT_Game_Service_IMG + img.ul_file_path
    }else{
        imghead = /* await compressImage(noimg,mmToPx(wpage + 1), mmToPx(27)) */noimg

    }
    Shot('fs', 12)
    for (let i = 1; i <= page; i += 1) {
        
            doc.setPage(i)
            sethead()
            setfooter(i, page)
        

    }

    moment.locale('th');
    if (mode === 'version') {
        const dataUri = doc.output('datauristring');
        const binaryData = atob(dataUri.split(',')[1]);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
            uint8Array[i] = binaryData.charCodeAt(i);
        }

        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const file = new File([blob], `TimeSheet_${moment(data.date.s).diff(moment(data.date.e), 'days') !== 0 ? `${moment(data.date.s).add('years', 543).format('YYMMDD')}_${moment(data.date.e).add('years', 543).format('YYMMDD')}` : `${moment(data.date.s).add('years', 543).format('YYMMDD')}`}${data.version > 0 ? `_V${data.version+1}`: `` }.pdf`, { type: 'application/pdf' });
        console.log(file);
        const ref_id = uuidv4();
        CheckAndCreateFolderBox({id:ref_id},'',file)

       /*  const dataImg = new FormData();
        dataImg.append('Profile', file);    
        dataImg.append('typeFile', 'TimeSheetReport');
        dataImg.append('projectKey', "tttspaceship");
        await axios({
            method: 'post',
            url: `${Configs.TTT_Game_Service}/api/upload/document2`,
            headers: {
                
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/octet-stream',
            },
            data: dataImg,
        })
            .then(async function (response) {
             
           
                   if(callback){
                    callback(response.data)
                   }
                

            })
            .catch(function (error) {
                console.log(error);
            }); */








    } else {
        Shot('save', `TimeSheet_${moment(data.date.s).diff(moment(data.date.e), 'days') !== 0 ? `${moment(data.date.s).add('years', 543).format('YYMMDD')}_${moment(data.date.e).add('years', 543).format('YYMMDD')}` : `${moment(data.date.s).add('years', 543).format('YYMMDD')}`}${data.version > 0 ? `_V${data.version+1}`: `` }.pdf`);

        return 'test'
    }

    async function CheckAndCreateFolderBox(datalist,olddata,file) {
        let temp = {
            folderLis: ["USER",`USER_${sessionStorage.getItem('user_id')}`,"REPORT TIMESHEET"]
        }
       /*  temp.folderLis.push(folder_name) */
        await axios({
            method: 'post',
            url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/CheckAndCreateFolderBox`,
            headers: {
              Authorization: sessionStorage.getItem('access_token'),
              "X-TTT": `${Configs.API_TTT}`,
              "Content-Type": "application/json",
            },
            data: temp
        })
            .then(async function (response) {
                if (response.data) {
                    datalist.files = file
                    datalist.file_name =file.name
                    await UploadFiles(datalist, response.data.folder_id,olddata)
             
                }
            })
            .catch(function (error) {
               
                console.log(error);
            });
    }
    
    
    
    async function UploadFiles(item, folder_id,olddata) {
      console.log(item);
      const dataImg = new FormData();
      dataImg.append('File', item.files);
      dataImg.append('folder_id', folder_id);
      dataImg.append('key', "REPORT TIMESHEET");
      dataImg.append('image_old_list', JSON.stringify(olddata));
      dataImg.append('ref_id', item.id);
    
      await axios({
          method: 'post',
          url: `${Configs.API_URL_BOX_SERVICE}/api/uploadBoxService/UploadFiles`,
          headers: {
            Authorization: sessionStorage.getItem('access_token'),
            "X-TTT": `${Configs.API_TTT}`,
            "Content-Type": "multipart/form-data",
          },
          data: dataImg
      })
          .then(async function (response) {
              if (response.data) {
                if (response.data.image_id_list.length > 0) {
                    let as = {
                        ref_id: item.id,
                        pathimg: response.data.image_id_list[0],
                        file_name: item.file_name

                    }
                    callback(as)
                
                }
               
              }
          })
          .catch(function (error) {
            
              console.log(error);
          });
    }
function calculateTime(ts_time, ts_hours) {
    
    let additionalHours = Math.floor(ts_time / 60);
    let remainingMinutes = ts_time % 60;

   
    let totalHours = ts_hours + additionalHours;

    /* let totalDays = Math.floor(totalHours / 8); */
    let remainingHours = totalHours /* % 8; */

    return {
       /*  sumd: totalDays, */
        sumh: remainingHours,
        sumt: remainingMinutes
    };
}


 


    function newpage(count) {
        if (heightpage > count) {
            Shot("add");
            page += 1
            heightpage = 38

        }
    }

    function cutText(text, maxLength,fontSize) {
        let words =  text.match(/[\u0E00-\u0E7F]+|\w+|\s+|[^\w\s\u0E00-\u0E7F]/g);

        var result = [];
        var currentLine = "";
        console.log(words);
      /*   function getTextWidth(text) {
          
            var textWidth = doc.getStringUnitWidth(text) * fontSize / doc.internal.scaleFactor;
            return textWidth;
        }
        
        words.forEach(function(word) {
            var tempLine = currentLine + word;
            if (getTextWidth(tempLine) > maxLength) {
             
                result.push(currentLine.trim());
                currentLine = word;
            } else {
               
                currentLine = tempLine;
            }
        });
  
        if (currentLine) {
            result.push(currentLine.trim());
        }
return result */
    }

    function sethead() {
        Shot('i', /* img.ul_file_path !== undefined ? Configs.TTT_Game_Service_IMG + img.ul_file_path : */ imghead, 0, 0, wpage + 1, 27)

    }
    function setfooter(on, max) {

        Shot('t', CutPadding('w', 100,), 283, TexttoString(`All Rights Reserved, Copyright ${'TTT Brother Co., Ltd.'} | หน้า ${on}`), 'r')
        Shot('t', CutPadding('w', 100,), 288, TexttoString(`Template Version 1.0 - TTT Spaceship`), 'r')

    }
    /* --------------------------------------------------------------------------------------------------------------------------------------------------------------- */


    function setDetethai(mode, date, ps, cut, length) {
        let currentDate = new Date(date);
        let day = currentDate.getDate();
        let monthNamesShort = [
            "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.",
            "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."
        ];
        let monthIndex = currentDate.getMonth();
        let thaiYear = (currentDate.getFullYear() + 543).toString().slice(-2); // แปลงเป็น ปีไทยย่อ
        let dayNameShort = ['อา.', 'จ.', 'อ.', 'พ.', 'พฤ.', 'ศ.', 'ส.'][currentDate.getDay()];

        let formattedDate = ''
        if (mode === 'day') {
            formattedDate = `${dayNameShort} ${day}/${monthNamesShort[monthIndex]}/${thaiYear}`;
        }

        /*  Shot('t', Colposition('t', tableschedule, ps, 'r', 15), inpage + (cut - (length * 5) + 2), TexttoString(formattedDate), 'r'); */
    }



    async function Shot(mode, c1, c2, c3, c4, c5, c6, c7) {
        if (mode === 'add') {
            if (c1 !== undefined) {
                return doc.addPage(c1);
            } return doc.addPage()
        }
        if (mode === 'new') {
            window.open(doc.output('bloburl'));
        }
        if (mode === 'save') {

            if (c1 !== undefined) {
                return doc.output('save', c1);
            } return doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY'));

        }
        if (mode === 'newsave') {
            if (c1 !== undefined) {
                return window.open(doc.output('bloburl'), doc.output('save', c1));

            } return window.open(doc.output('bloburl'), doc.output('save', moment(new Date()).add('year', 543).format('DD/MM/YY')));

        }

        /*  if (mode === 'qr') {
           let imgqr = ''
         QRCode.toDataURL(c1, function (err, url) {
           imgqr = url;
         });
   
         return doc.addImage(imgqr, 'JPEG', c2, c3, c4, c4);
       }
      */
        if (mode === 'fs') {
            doc.setFontSize(c1)
        }
        if (mode === 'dc') {
            doc.setDrawColor(c1, c2, c3);
        }
        if (mode === 'tc') {
            doc.setTextColor(c1, c2, c3);
        }
        if (mode === 'fc') {
            doc.setFillColor(c1, c2, c3);
        }
        if (mode === 'lw') {
            doc.setLineWidth(c1);
        }


        if (mode === 'i' && c1 !== null) {
            if (c5 !== undefined) {
                if (c6 !== undefined) {

                    if (c6 === 'fit') {
                     

                       /*  console.log(c2 + x, c3 + y, nw, nh); */
                      
                       let Width = c7.width? c7.width : 1920;
                       let Height = c7.height? c7.height : 1080; 
                    
                      const imgar = Width / Height;
                      const reactar = c4 / c5;

                      let scale = 1;
                      if (imgar > reactar) {
                          scale = c4 / Width;
                      } else {
                          scale = c5 / Height;
                      }
                      const nw = Width * scale;
                      const nh = Height * scale;

                      const x = (c4 - nw) / 2;
                      const y = (c5 - nh) / 2;


                      const compressedImgData = await compressImage(c1);


                       return doc.addImage(compressedImgData, 'JPEG', c2 + x, c3 + y, nw, nh, undefined, 'FAST')
                    }
                } return doc.addImage(c1, 'JPEG', c2, c3, c4, c5);
            } return doc.addImage(c1, 'JPEG', c2, c3, c4, c4);

        }
        if (mode === 'r') {
            if (c5 !== undefined) {
                if (c5 === 'd') {
                    return doc.rect(c1, c2, c3, c4, 'D');
                }
                if (c5 === 'fd') {
                    return doc.rect(c1, c2, c3, c4, 'FD');
                }
                if (c5 === 'f') {
                    return doc.rect(c1, c2, c3, c4, 'F');
                }
            } else return doc.rect(c1, c2, c3, c4);

        }
        if (mode === 't') {

            if (c4 !== undefined) {
                if (c4 === 'c') {
                    return doc.text(c1, c2, c3, 'center');
                }
                if (c4 === 'r') {
                    return doc.text(c1, c2, c3, 'right');
                }
                if (c4 === 'l') {
                    return doc.text(c1, c2, c3, 'left');
                }
            } else return doc.text(c1, c2, c3);
        }

    }


    function CutPadding(mode, percent, num) {

        if (mode === 'h') {
            if (num !== undefined) {
                return (((hpage/*  - (padding * 2) */) * percent) / 100)/*  + padding */ + num;
            } return (((hpage/*  - (padding * 2) */) * percent) / 100) /* + padding */;

        }
        if (mode === 'w') {
            if (num !== undefined) {
                return (((wpage - (padding * 2)) * percent) / 100) + padding + num;
            } return (((wpage - (padding * 2)) * percent) / 100) + padding;


        }

    }

    function TexttoString(text) {
        if (text === null) {
            return ''.toString();
        } return text.toString();

    }
    function NumtoString(num, fix) {
        if (num !== null) {
            if (fix !== undefined) {
                return parseFloat(num).toFixed(fix).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            } return parseFloat(num).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } return parseFloat(0).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    function ShotTable(mode, head, pos, col, coll, loop, color, hig, link = [], header) {
        /* console.log(mode, head, pos, col, coll, loop, color); */
        let collengthsum = coll === undefined ? 5 : coll
        let loopsum = loop === undefined ? 10 : loop
        if (mode === 'ht') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum, color);
                if (head[`name${t}`] !== undefined) {
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                    Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum / 1.6), TexttoString(head[`name${t}`]), 'c');
                }


            }
        }

        if (mode === 'lt') {
            let lengthloop = (loopsum * collengthsum) + collengthsum
            for (let t = 1; t <= col; t += 1) {

                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), lengthloop);


            }

        }

        if (mode === 'st') {
            let lengthloop = collengthsum
            for (let l = 0; l < loopsum; l += 1) {
                for (let t = 1; t <= col; t += 1) {
                    Shot('r', Colposition('r', head, t), pos + lengthloop, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum);

                }
                lengthloop += collengthsum
            }
        }

        if (mode === 'htc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig, color);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        Shot('t', Colposition('t', head, t, 'c'), pos + (collengthsum * 0.8), TexttoString(c), 'c');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += collengthsum * hig
        }
        if (mode === 'ltc') {
            let befor = 0, higbefor = pos, maxhig = 0
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4); */

                if (head[`name${t}`] !== undefined) {
                    for (let d = befor; d < head[`name${t}`].length; d += 1) {
                        let c = head[`name${t}`][d]
                        if (heightpage + ((d - befor) * 6) > 250) {
                            if (t < col) {
                                for (let t2 = t + 1; t2 <= col; t2 += 1) {
                                    let fakecolsum = coll
                                    for (let dx = befor; dx <= d; dx += 1) {
                                        if (head[`name${t2}`].length > dx) {
                                            let cx = head[`name${t2}`][dx]

                                            let chackrow = link.filter((e) => { return e.n === cx })
                                            console.log(chackrow, cx, 'in');
                                            if (chackrow.length > 0) {
                                                /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                                               */
                                                let y = higbefor + (fakecolsum), x = Colposition('t', head, t2, 'l', 3)
                                                let width = doc.getTextWidth(chackrow[0].n);
                                                let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                                                y += height * 0.2;
                                                doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                                            } /* else { */
                                            Shot('t', Colposition('t', head, t2, t2 === 1 ? 'c' : 'l', 3), higbefor + (fakecolsum), TexttoString(cx), t2 === 1 ? 'c' : 'l')
                                            /* } */
                                            fakecolsum += coll
                                        } else {
                                            break
                                        }

                                    }

                                }

                            }
                            for (let al = 1; al <= col; al += 1) {
                                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), collengthsum + 2);
                            }

                            heightpage += ((d - befor) * 6)
                            newpage(250)
                            higbefor = heightpage
                            collengthsum = col
                            befor = d
                            maxhig = col
                            ShotTable('htc', header, heightpage, header.loop, 7, '', 'fd', header.height)
                            higbefor += 7


                        }


                        let chackrow = link.filter((e) => { return e.n === c })
                        if (chackrow.length > 0) {
                            /*   doc.textWithLink(chackrow[0].n,  Colposition('t', head, t2, 'l',3),  higbefor + (fakecolsum), {url: chackrow[0].l , target: '_blank'});
                           */
                            let y = higbefor + (collengthsum), x = Colposition('t', head, t, 'l', 3)
                            let width = doc.getTextWidth(chackrow[0].n);
                            let height = doc.internal.getLineHeight() / doc.internal.scaleFactor;

                            y += height * .2;
                            doc.link(x, y - height, width, height, { url: chackrow[0].l, target: '_blank' });
                        }
                        Shot('t', Colposition('t', head, t, t === 1 ? 'c' : 'l', 3), higbefor + (collengthsum), TexttoString(c), t === 1 ? 'c' : 'l');
                        collengthsum += coll

                    }
                    if (collengthsum > maxhig) {
                        maxhig = collengthsum
                    }

                    collengthsum = coll

                    /*   Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), befor * higl + 4); */
                }
                /* befor+=1 */


            }
            for (let al = 1; al <= col; al += 1) {
                Shot('r', Colposition('r', head, al), higbefor, CutPadding('w', 0, head[`col${al}`] - padding), maxhig);
            }
            heightpage += maxhig
            /*  heightpage += (collengthsum * hig) + 4 */
        }

        if (mode === 'stc') {
            for (let t = 1; t <= col; t += 1) {
                Shot('fc', r, g, b);
                Shot('r', Colposition('r', head, t), pos, CutPadding('w', 0, head[`col${t}`] - padding), collengthsum * hig + 4);

                if (head[`name${t}`] !== undefined) {
                    for (let c of head[`name${t}`]) {
                        Shot('t', Colposition('t', head, t, 'l'), pos + (collengthsum /* / 1.6 */), TexttoString(c), 'l');
                        collengthsum += coll
                    }
                    collengthsum = coll
                }



            }
            heightpage += (collengthsum * hig) + 4
        }



    }
    function Colposition(mode, name, col, pos, pad) {


        if (mode === 'r') {
            const poscol = pos !== undefined ? pos : 0
            const colcount = col - 1
            let colsum = 0

            for (let i = colcount - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }

            if (colcount === 0) {
                return CutPadding('w', 0)
            } return CutPadding('w', 0, name[`col${colcount}`] + poscol) + colsum


        }
        if (mode === 't') {
            let colsum = 0
            const paddingcol = pad !== undefined ? pad : 0
            for (let i = col - 1; i > 0; i -= 1) {
                colsum += name[`col${i}`]
            }


            if (col === 0) {
                return CutPadding('w', 0)
            }

            if (pos !== undefined) {
                if (pos === 'c') {
                    return CutPadding('w', 0, (name[`col${col}`] * (50 + paddingcol)) / 100) + colsum
                }
                if (pos === 'l') {
                    return CutPadding('w', 0, (name[`col${col}`] * (1 + paddingcol)) / 100) + colsum
                }
                if (pos === 'r') {
                    return CutPadding('w', 0, (name[`col${col}`] * (99 - paddingcol)) / 100) + colsum
                }
            } return CutPadding('w', 0, name[`col${col}`]) + colsum



        }



    }



}






export default timesheertreport;
