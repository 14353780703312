import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import './HeaderTTTMobile.css';
import Configs from "../../config";
import spaceShipLogo from './image/spaceShipLogo.png'
import axios from "axios";
import icon_google from '../../assets/img/logo/image google.png';
import icon_apple from '../../assets/img/logo/image apple.png';
import QrCode from "../../assets/img/qrCode/qrCodePay.jpg";
import ModelAlertMassge from "../AlertMassge/AlertMassge";

const HeaderTTTMobile = (params) => {
  const location = useLocation();

  const [activeMenu, setActiveMenu] = useState('');

  const [userForm, setUserForm] = useState({
    username: '',
    password: ''
  });

  const [messageAleart, setMessageAleart] = useState({
    message: '',
    isError: false,
    menuTitle: ''
  })

  const [mobileMenuTutorail, setMobileMenuTutorail] = useState(false);
  const toggle_mobileMenuTutorail = () => { setMobileMenuTutorail(!mobileMenuTutorail) };

  const [mobileMenuLeaderBoard, setMobileMenuLeaderBoard] = useState(false);
  const toggle_mobileMenuLeaderBoard = () => { setMobileMenuLeaderBoard(!mobileMenuLeaderBoard) };

  const [alertKickMassge, setAlertKickMassge] = useState(false);
  const toggle_modal_alertKickMassge = () => setAlertKickMassge(!alertKickMassge);

  const [messageError, setMessageError] = useState({
    message: '',
    isError: false,
    menuTitle: ''
  })

  async function getAuth() {

    let temp = {
      user: userForm.username || '',
      password: userForm.password || '',
    }
    setMessageAleart({ ...messageAleart, message: '', isError: false });
    await axios({
      method: 'POST',
      url: `${Configs.TTT_Game_Permission_Service}/api/auth/getAuth`,
      headers: {
        'X-TTT': `${Configs.API_TTT}`,
        'Content-Type': 'application/json',
      },
      data: temp,

    })
      .then(async function (response) {
        if (response.data.token) {
          sessionStorage.setItem('access_token', response.data.token);
          sessionStorage.setItem('user_id', response.data.fup);
          sessionStorage.setItem('user', response.data.user);
          sessionStorage.setItem('ttt_id', response.data.ttt_id);
          sessionStorage.setItem('is_skill', true);
          setMessageAleart({ ...messageAleart, message: ' Welcome to TTT SPACESHIP', isError: false, menuTitle: 'Login successfully' })
          params.mobileLogin && params.toggle_mobileLogin();
        } else {
          if (response.data.message) {
            setMessageAleart({ ...messageAleart, message: response.data.message, isError: true, menuTitle: 'LOGIN FAIL' })
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const [isRegister, setIsRegister] = useState(false)
  const [test, settest] = useState({ test: '' }
  )
  async function getConfigMasterRegis() {
    let code = 'IsRegis';
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/configMgt/getConfigByCode/${code}`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        // console.log('เช็คหน่อย', response.data);
        if (response.data.length > 0) {
          let is_ = response.data[0].gc_value == "true" ? true : false;
          setIsRegister(is_);
          settest({ ...test, test: '' });
          test.test = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    console.log('testlog location.pathname', location.pathname);
    
    if (location.pathname == '/tutorial') {
      setActiveMenu('tutorial')
    } else if (location.pathname == '/LeaderBoard') {
      setActiveMenu('leaderboard')
    } else if (location.pathname == '/contactus') {
      setActiveMenu('contactus')
    } else if (location.pathname == '/News') {
      setActiveMenu('news')
    } else if (location.pathname == '/Infodetail') {
      setActiveMenu('infodetail')
    } else if (location.pathname == '/ShopTTT') {
      setActiveMenu('shop')
    } else if (location.pathname == '/Portal') {
      setActiveMenu('portal')
    } else {
      setActiveMenu('home')
    }
    getConfigMasterRegis();
  }, [])

  console.log('testlog ac', activeMenu);
  

  return (
    <>
      <div className='mob-headerTTTMobileBg'>
        <div className='mob-headerTTTMobileSetLeft'>
          <img className='mob-headerTTTMobileLogo' src={spaceShipLogo} />
        </div>
        <div className='mob-headerTTTMobileSetLeftBlue'></div>
        <div className='mob-headerTTTMobileSetBlue'></div>
        <div className='mob-headerTTTMobileSet'>
          <div className='mob-headerTTTMobileSetNameBox' style={{ display: `${sessionStorage.getItem('access_token') ? '' : 'none'}` }}>
            <div className='mob-headerTTTMobileSetEdit'>
              <i className="far fa-edit" style={{ width: '100%', height: '100%', fontSize: '12px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
            </div>
            <div className='mob-headerTTTMobileSetName'>{sessionStorage.getItem('user')}</div>

          </div>
          <i class="fas fa-sign-out-alt" aria-hidden="true" style={{ fontSize: '16px', display: `${sessionStorage.getItem('access_token') ? '' : 'none'}` }} onClick={() => { sessionStorage.clear(); window.location.reload(); }}></i>
          <i class="fa fa-ellipsis-v" aria-hidden="true" onClick={() => {
            params.toggle_mobileMenu();
          }}></i>
        </div>

        <div className='mob-headerTTTMobileMenuBox' style={{ display: `${params.mobileMenu ? '' : 'none'}` }}>
          <div><Link className='mob-headerTTTMobileMenuText' style={{ color: `${activeMenu == 'home' ? '#0047FF' : '#FFF'}` }} to="/">Home</Link></div>
          <div className='mob-headerTTTMobileMenuText' onClick={() => toggle_mobileMenuTutorail()}>TUTORAIL</div>

          <div><Link className='mob-headerTTTMobileMenuText ml-2' style={{ display: `${mobileMenuTutorail ? '' : 'none'}`, color: `${activeMenu == 'Achievement' ? '#0047FF' : '#FFF'}` }} to="/Achievement">ACHIEVEMENT</Link></div>
          <div><Link className='mob-headerTTTMobileMenuText ml-2' style={{ display: `${mobileMenuTutorail ? '' : 'none'}`, color: `${activeMenu == 'howToPlay' ? '#0047FF' : '#FFF'}` }} to="/howToPlay">HOW TO PLAY</Link></div>
          <div><Link className='mob-headerTTTMobileMenuText ml-2' style={{ display: `${mobileMenuTutorail ? '' : 'none'}`, color: `${activeMenu == 'talentGroup' ? '#0047FF' : '#FFF'}` }} to="/talentGroup">TALENT GROUP</Link></div>

          <div className='mob-headerTTTMobileMenuText' onClick={() => toggle_mobileMenuLeaderBoard()}>LEADERBOARD</div>

          <div><Link className='mob-headerTTTMobileMenuText ml-2' style={{ display: `${mobileMenuLeaderBoard ? '' : 'none'}`, color: `${activeMenu == 'UniversalBoard' ? '#0047FF' : '#FFF'}` }} to="/UniversalBoard">UNIVERSAL BOARD</Link></div>
          <div>{sessionStorage.getItem('user_id') ? <Link className='mob-headerTTTMobileMenuText ml-2' style={{ display: `${mobileMenuLeaderBoard ? '' : 'none'}`, color: `${activeMenu == 'FederationBoard' ? '#0047FF' : '#FFF'}` }} to="/FederationBoard">FEDERATION BOARD</Link> : <Link className='mob-headerTTTMobileMenuText ml-2' style={{ display: `${mobileMenuLeaderBoard ? '' : 'none'}`, color: `${activeMenu == '/FederationBoard' ? '#0047FF' : '#FFF'}` }} to="#" onClick={()=>{
            setMessageError({...messageError, message: 'You will need to log in first.', isError: true, menuTitle: 'ERROR'});
            messageError.message = 'You will need to log in first.';
            messageError.isError = true;
            messageError.menuTitle = 'ERROR';
            toggle_modal_alertKickMassge();
          }}>FEDERATION BOARD</Link>}</div>
  
          {/* <div><Link className='mob-headerTTTMobileMenuText' style={{ color: `${activeMenu == '/leaderboard' ? '#0047FF' : '#FFF'}` }} to="/LeaderBoard">Leaderboard</Link></div> */}
          <div><Link className='mob-headerTTTMobileMenuText' style={{ color: `${activeMenu == 'contactus' ? '#0047FF' : '#FFF'}` }} to="/contactus"> CONTACT US</Link></div>
          {sessionStorage.getItem('access_token') && <div><Link className='mob-headerTTTMobileMenuText' style={{ color: `${activeMenu == 'portal' ? '#0047FF' : '#FFF'}` }} to="/Portal"> PORTAL</Link></div>}
          <div><Link className='mob-headerTTTMobileMenuText' style={{ color: `${activeMenu == 'news' ? '#0047FF' : '#FFF'}` }} to="/News">News</Link></div>
          <div><Link className='mob-headerTTTMobileMenuText' style={{ color: `${activeMenu == 'shop' ? '#0047FF' : '#FFF'}` }} to="/ShopTTT">Store</Link></div>
          <div className='mob-headerTTTMobileMenuText' onClick={() => {
            if (sessionStorage.getItem('access_token')) {
              window.location.href = "/StageMonitoring";
            } else {
              params.toggle_mobileLogin();
              params.mobileMenu && params.toggle_mobileMenu();
              // document.body.classList.add('login-menu-visible');
            }
          }}>PLAY</div>
        </div>

      </div>
      <div className='mob-headerTTTMobileLogin' style={{ display: `${params.mobileLogin ? '' : 'none'}` }}>
        <div className='mob-headerTTTMobileLoginClose' onClick={() => {
          params.mobileLogin && params.toggle_mobileLogin();
          setUserForm({
            username: '',
            password: ''
          })
        }}>{'<'}</div>
        <div className='mob-headerTTTMobileLoginHeader'>LOGIN</div>
        <div className='mob-headerTTTMobileLoginBody'>
          <img className='mob-headerTTTMobileLoginBodyLogo mt-4' src={spaceShipLogo} />

          <div className='mob-headerTTTMobileLoginBodyUsername' style={{ marginTop: '7.5%' }}>
            <svg width="100%" height="100%">
              <line x1="0" y1="25%" x2="2.5%" y2="0" style={{ stroke: 'rgba(93, 155, 225, 1)', strokeWidth: '1' }} />
              <line x1="100%" y1="75%" x2="97%" y2="99%" style={{ stroke: 'rgba(93, 155, 225, 1)', strokeWidth: '1' }} />
            </svg>
            <input
              className='mob-headerTTTMobileLoginBodyUsernameInput'
              type='text'
              placeholder='TTT ID'
              value={userForm.username}
              onChange={(e) => {
                setUserForm({ ...userForm, username: e.target.value });
              }}
            />
          </div>

          <div className='mob-headerTTTMobileLoginBodyUsername' style={{ marginTop: '7.5%' }}>
            <svg width="100%" height="100%">
              <line x1="0" y1="25%" x2="2.5%" y2="0" style={{ stroke: 'rgba(93, 155, 225, 1)', strokeWidth: '1' }} />
              <line x1="100%" y1="75%" x2="97.5%" y2="100%" style={{ stroke: 'rgba(93, 155, 225, 1)', strokeWidth: '1' }} />
            </svg>
            <input
              className='mob-headerTTTMobileLoginBodyUsernameInput'
              type='password'
              placeholder='PASSWORD'
              value={userForm.password}
              onChange={(e) => {
                setUserForm({ ...userForm, password: e.target.value });
              }}
            />
          </div>
          {messageAleart.isError == true ? <>
            <font className='mob-headerTTTMobileLoginBodyErroeMessage'>
              {messageAleart.message}
            </font>
          </> : <></>}

          <div className='mob-headerTTTMobileLoginBodyLogin' style={{ marginTop: '7.5%', height: '45px' }}>
            <button
              className='mob-headerTTTMobileLoginBodyLoginButton'
              type="button"
              onClick={() => {
                getAuth();
              }}
            >LOGIN</button>
          </div>
          <div className='mob-headerTTTMobileLoginBodyForgot'>
            <div onClick={() => {
              // ตอนกด FORGOT PASSWORD
            }}>FORGOT PASSWORD?</div>
          </div>

          <div className='mob-headerTTTMobileLoginBodyOr'>
            <div className='mob-headerTTTMobileLoginBodyOrLine'></div>
            <div style={{ lineHeight: 1 }}>OR</div>
            <div className='mob-headerTTTMobileLoginBodyOrLine'></div>
          </div>

          <div className='mob-headerTTTMobileLoginBodyIconBox'>
            <div className='mob-headerTTTMobileLoginBodyIcon'>
              <div className='mob-headerTTTMobileLoginBodyIconButton' onClick={() => {
                // ตอนกด ICON
              }}>
                <img className='mob-headerTTTMobileLoginBodyIconImg' src={icon_google} alt="" />
              </div>
            </div>
            <div className='mob-headerTTTMobileLoginBodyIcon'>
              <div className='mob-headerTTTMobileLoginBodyIconButton' onClick={() => {
                // ตอนกด ICON
              }}>
                <img className='mob-headerTTTMobileLoginBodyIconImg' src={icon_apple} alt="" />
              </div>
            </div>
          </div>

          <div className='mob-headerTTTMobileLoginBodyRegis'>
            <div onClick={() => {
              if(isRegister == true){
                window.location.href = "/Register"
              }else{
                params.toggle_mobileRegister();
              }
             
            }}>Don’t have any account? request now</div>
          </div>
        </div>
      </div>

      <div className='mob-headerTTTMobileLogin' style={{ display: `${params.mobileRegister ? '' : 'none'}` }}>
        <div className='mob-headerTTTMobileLoginClose' onClick={() => {
          params.mobileRegister && params.toggle_mobileRegister()
        }}>{'<'}</div>
        <div className='mob-headerTTTMobileLoginHeader'>REGISTER</div>
        <div className='mob-headerTTTMobileLoginBody'>
          <div className='mob-headerTTTMobileLoginBodyHead'>ATTENTION</div>
          <div className='mob-headerTTTMobileLoginBodyCenter'>PLEASE CONTACT VIA LINE ADMIN</div>
          <div className='mob-headerTTTMobileLoginBodyBottom'>CONTACT LINE ADMIN TO REQUEST A SOFT OPENING PASSWORD TO RECEIVE SPECIAL PRIVILEGES<br />FOR YOU.</div>

          <div className='mob-headerTTTMobileLoginBodyQr'>
            <img style={{ width: '100%', borderRadius: '5%' }} src={QrCode} />
          </div>

          <div className='mob-headerTTTMobileLoginBodyConfirmRegister' style={{ marginTop: '7.5%', height: '45px' }}>
            <button
              className='mob-headerTTTMobileLoginBodyConfirmRegisterButton'
              type="button"
              onClick={() => {
                params.mobileRegister && params.toggle_mobileRegister()
              }}
            >OK</button>
          </div>
        </div>
      </div>
      <ModelAlertMassge
          isOpen={alertKickMassge}
          toggle_alertKick={() => { toggle_modal_alertKickMassge() }}
          message={messageError}
          mode={'mobile'}
        />
    </>


  )
}

export default HeaderTTTMobile