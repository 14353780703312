import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";
import IMGQRCODE from "../../../assets/img/qrCode/QRLINETTTADMIN.png";
import ModelAlertRegis from "../../../components/AlertMassge/AlertRegis";
import PageRegis from "../../../view/register/index";
import $ from 'jquery';
import './LandingpageTTT.css';
import Bg from '../../../assets/img/slider/imgmian.jpg'
import axios from 'axios';
import Configs from "../../../config";

function LandingpageTTT() {
  const [alertKick, setAlertKick] = useState(false);
  const toggle_modal_alertKick = () => setAlertKick(!alertKick);

  const settings = {
    dots: false,
    infinite: true,
    speed: 100,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    center: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          SlidesToScroll: 1,
        }
      },
      {
        breakpoint: 575,
        settings: {
          sidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        }
      }
    ]
  }

  function openFullscreen() {
    /*     var elem = document.documentElement;
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) { 
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) { 
          elem.msRequestFullscreen();
        }
     */

    let token = sessionStorage.getItem('access_token');
    if (token !== '' && token !== null) {
      window.location.href = './StageMonitoring'
    }
  }


  const [isRegister, setIsRegister] = useState(false)
  const [test, settest] = useState({ test: '' }
  )
  async function getConfigMasterRegis() {
    let code = 'IsRegis';
    axios({
      method: "GET",
      url: `${Configs.TTT_Game_Service}/api/configMgt/getConfigByCode/${code}`,
      headers: {
        /*     Authorization: getToken(), */
        "X-TTT": `${Configs.API_TTT}`,
        "Content-Type": "application/json",
      },
      /* data: temp, */
    })
      .then(async function (response) {
        // console.log('เช็คหน่อย', response.data);
        if (response.data.length > 0) {
          let is_ = response.data[0].gc_value == "true" ? true : false;
          setIsRegister(is_);
          settest({ ...test, test: '' });
          test.test = '';
        }
      })
      .catch(function (error) {
        console.log(error);
      });
  }


  useEffect(() => {
    getConfigMasterRegis();
  }, [])


  return (
    <>

      <section className="third-banner-bg">
        {/*  <Fade  delay={1800}> */}
        {/*   <div className="slider-circle-shape-1" ><img src="assets/img/slider/Layer_1-No opa.png" alt="" className="rotateme" /></div> */}
        {/*  </Fade> */}
        <div className='blurmian1' />
        <div className='blurmian2' />
        <div className='blurmian3' />

        <div className="container-fluid container-full-padding">


          <div className="row" style={{ paddingLeft: '3%', paddingRight: '3%' }}>
            <div className="col-xl-6 col-lg-7 col-md-12">
              <div className="slider-content" style={{ marginTop: '300px' }}>
                <Fade left delay={1800}>
                  <div style={{ height: '20px', width: '5px', backgroundColor: '#0047FF', whiteSpace: 'nowrap' }}><h5 style={{ marginLeft: '13px' }}>TTT BROTHER</h5> </div>
                </Fade>
                <Fade left delay={500}>

                  <h5 title="TTT SPACESHIP" style={{ fontSize: '75px', fontWeight: 'bold' }} className='textanime1'>TTT SPACESHIP</h5>
                </Fade>
                <Fade left delay={800}>
                  <h5 style={{ fontSize: '37px', fontWeight: 'bold', fontStyle: 'italic', marginBottom: '20px', marginTop: '-16px', whiteSpace: 'nowrap' }}>HOW THE IT PROJECTS
                    <span class="neon"> SIMPLIFYING</span>
                  </h5>
                </Fade>
                <Fade delay={1800}>
                  <p style={{ width: '100%', fontSize: '14px', fontWeight: 'normal' }}>Lorem ipsum dolor sit amet consectetur. Id morbi in suspendisse ante cursus magna. Porta fermentum dis amet dignissim cursus nam facilisi sed. Rutrum cursus amet sagittis eget ut dictumst. Neque nunc tincidunt ac ipsum hac lorem morbi.</p>
                </Fade>
                <Fade up delay={1800}>
                  <a href="#" className="btn btn-style-two" onClick={
                    () => {
                      if (sessionStorage.getItem('access_token')) {
                        window.location.href = "/StageMonitoring";
                      } else {
                        document.body.classList.add('login-menu-visible');
                      }
                    }
                  }>PLAY </a>
                  {isRegister == true
                    ? <a href="/Register" onClick={() => { }}
                    
                    className="btn btn-style-two-1 " style={{ marginLeft: '43px' }}>REGISTER</a> :
                    <a onClick={() => {
                        toggle_modal_alertKick()
                    }} className="btn btn-style-two-1 " style={{ marginLeft: '43px' }}>REGISTER</a>
                  }


                </Fade>

                <ModelAlertRegis
                  isOpen={alertKick}
                  toggle_alertKick={() => { toggle_modal_alertKick() }}
                />

              </div>
            </div>
          </div>
        </div>

      </section>

    </>




  )
}

export default LandingpageTTT