import React, { useEffect, useState } from 'react';
import './portalStyle.css';
import axios from "axios";
import Configs from "../../config";

import HeaderTTT from "../../components/header/HeaderTTT";
import HeaderTTTMobile from '../../components/header/HeaderTTTMobile';

import galaxyBg from '../../assets/img/slider/imgmian.jpg';
import spaceshipAcademyLogo from './image/spaceshipAcademyLogo.png'

function Portal() {
    const [data, setData] = useState([])

    const [modeMobile, setModeMobile] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const toggle_mobileMenu = () => { setMobileMenu(!mobileMenu) };
    const [mobileLogin, setMobileLogin] = useState(false);
    const toggle_mobileLogin = () => { setMobileLogin(!mobileLogin) };
    const [mobileRegister, setMobileRegister] = useState(false);
    const toggle_mobileRegister = () => { setMobileRegister(!mobileRegister) };

    async function getAllPortal() {
        axios({
            method: 'get',
            url: `${Configs.TTT_Game_Service}/api/portal/getAllPortal`,
            headers: {
                'Authorization': sessionStorage.getItem('access_token'),
                'X-TTT': `${Configs.API_TTT}`,
                'Content-Type': 'application/json',
            }
        })
            .then(function (response) {
                if (response.data) {
                    setData(response.data);
                }
            })
            .catch(function (error) {
                console.log(error);
            });
    }

    useEffect(() => {
        if (!sessionStorage.getItem('access_token')) {
            return window.location.href = '/'
        }

        if (navigator.userAgent.match(/Android/i)
            || navigator.userAgent.match(/webOS/i)
            || navigator.userAgent.match(/iPhone/i)
            || navigator.userAgent.match(/iPad/i)
            || navigator.userAgent.match(/iPod/i)
            || navigator.userAgent.match(/BlackBerry/i)
            || navigator.userAgent.match(/Windows Phone/i)) {
            setModeMobile(true);
        } else {
            setModeMobile(false);
        }

        getAllPortal();
    }, []);

    return (
        <>
            {modeMobile ? (<>
                <HeaderTTTMobile
                    mobileMenu={mobileMenu}
                    toggle_mobileMenu={toggle_mobileMenu}
                    mobileLogin={mobileLogin}
                    toggle_mobileLogin={toggle_mobileLogin}
                    mobileRegister={mobileRegister}
                    toggle_mobileRegister={toggle_mobileRegister}
                />
                <div className='mob-LandingPageTTTBg' style={{ backgroundImage: `url(${galaxyBg})` }}></div>
                <div className='portalBgSet' style={{ marginTop: '50px', height: '95%' }}>
                    <div className='portalBox'>
                        <div style={{ width: '30%', height: '10px', background: '#0047FF', clipPath: 'polygon(0 0, 100% 0, calc(100% - 10px) 100%, calc(0% + 10px) 100%)' }}></div>
                        <div style={{ flexGrow: '1', width: '100%', height: '0px', padding: '20px 30px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <div className='portalHeader'>PORTAL</div>
                            <div className='row' style={{ overflow: 'auto', }}>
                                {data && data.map((e, i) => {
                                    return <>
                                        <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3 portalCardBox'>
                                            <a href={e.ap_url + `?access_token=${sessionStorage.getItem('access_token') || null}&refresh_token=${sessionStorage.getItem('refresh_token') || null}`} className='portalCard'>
                                                <img src={e.ap_image_path ? Configs.TTT_Game_Service_IMG + e.ap_image_path : spaceshipAcademyLogo} className='portalLogo' />
                                            </a>
                                            <a href={e.ap_url + `?access_token=${sessionStorage.getItem('access_token') || null}&refresh_token=${sessionStorage.getItem('refresh_token') || null}`} className='portalName'>{e.ap_name}</a>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>
                        <div style={{ width: '30%', height: '10px', background: '#0047FF', clipPath: 'polygon(calc(0% + 10px) 0, calc(100% - 10px) 0, 100% 100%, 0% 100%)' }}></div>
                    </div>
                </div>
            </>) : (<>
                <HeaderTTT />
                <img className='bg-information' src={galaxyBg} alt="" />
                <div className='portalBgSet'>
                    <div className='portalBox'>
                        <div style={{ width: '500px', height: '20px', background: '#0047FF', clipPath: 'polygon(0 0, 100% 0, calc(100% - 10px) 100%, calc(0% + 10px) 100%)' }}></div>
                        <div style={{ flexGrow: '1', width: '100%', padding: '20px 30px', display: 'flex', flexDirection: 'column', gap: '15px' }}>
                            <div className='portalHeader'>PORTAL</div>
                            <div className='row'>
                                {data && data.map((e, i) => {
                                    return <>
                                        <div className='col-sm-6 col-md-6 col-lg-3 col-xl-3 portalCardBox'>
                                            <a href={e.ap_url + `?access_token=${sessionStorage.getItem('access_token') || null}&refresh_token=${sessionStorage.getItem('refresh_token') || null}`} className='portalCard'>
                                                <img src={e.ap_image_path ? Configs.TTT_Game_Service_IMG + e.ap_image_path : spaceshipAcademyLogo} className='portalLogo' />
                                            </a>
                                            <a href={e.ap_url + `?access_token=${sessionStorage.getItem('access_token') || null}&refresh_token=${sessionStorage.getItem('refresh_token') || null}`} className='portalName'>{e.ap_name}</a>
                                        </div>
                                    </>
                                })}
                            </div>
                        </div>
                        <div style={{ width: '500px', height: '20px', background: '#0047FF', clipPath: 'polygon(calc(0% + 10px) 0, calc(100% - 10px) 0, 100% 100%, 0% 100%)' }}></div>
                    </div>
                </div>
            </>)}
        </>
    )
}

export default Portal